import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  Colors,
  ScriptableContext,
} from "chart.js";
import { Bar, Chart } from "react-chartjs-2";
import styles from "./classPerformanceChart.module.scss";
import pointImg from "../../assets/images/pointerIcon.svg";
import prev from "../../assets/images/prevTopicIcon.svg";
import next from "../../assets/images/nextContentIcon.svg";
import classPerformanceChartCalendar from "../../assets/images/classPerformanceChartCalendar.svg";
import Dropdown from "../dropdown/dropdown";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  Colors
);

var yLabels: any = {
  2: "20%",
  4: "40%",
  6: "60%",
  8: "80%",
  10: "100%",
};

interface IAnalyticsChart {
  analyticsHeading?: string;
  data?: any;
  isLinePlot?: boolean;
  showDropDownFilter?: boolean;
  chartFilterOptions?: { name: string; id: number }[];
  chartFilterDefaultLabel?: string;
  filteredSelectedValues?: any;
  chartFilterWidth?: string;
  comparisonsInX_Axis?: number;
}

const ClassPerformanceChart = ({
  analyticsHeading = "",
  data,
  isLinePlot,
  showDropDownFilter = false,
  chartFilterOptions = [],
  chartFilterDefaultLabel,
  filteredSelectedValues,
  chartFilterWidth,
  comparisonsInX_Axis = 4,
}: IAnalyticsChart) => {
  const [scroll, setScroll] = useState(0);
  const [scrollState, setScrollState] = useState({ left: false, right: false });

  const [selectedValues, setSelectedValues] = useState([
    { index: 0, value: "" },
  ]);
  useEffect(() => {
    //comparisonsInX_Axis to show a max of comparisons default is 4
    if (data.labels.length <= comparisonsInX_Axis) {
      setScrollState({ ...scrollState, left: false, right: false });
    } else if (scroll === 0) {
      setScrollState({ ...scrollState, left: false, right: true });
    } else if (scroll + comparisonsInX_Axis >= data.labels.length) {
      setScrollState({ ...scrollState, left: true, right: false });
    }
  }, [scroll]);

  const handleScrollLeft = () => {
    if (scroll === 0) {
      return;
    }
    setScroll(scroll - 1);
    setScrollState({ ...scrollState, left: true, right: true });
  };
  const handleScrollRight = () => {
    if (scroll + comparisonsInX_Axis >= data.labels.length) {
      setScroll(data.labels.length - comparisonsInX_Axis);
    } else {
      setScroll(scroll + 1);
      setScrollState({ ...scrollState, left: true, right: true });
    }
  };
  const options = {
    maintainAspectRatio: false,
    plugins: {
      colors: {
        enabled: false,
        forceOverride: true,
      },
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
        },
      },
    },

    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },

    scales: {
      x: {
        border: {
          display: true,
          width: 3,
        },
        min: scroll,
        max: scroll + (comparisonsInX_Axis - 1),
        ticks: {
          count: 1,
          callback: function (value: any) {
            return labels[value].length > 8
              ? labels[value].substr(0, 8) + ".."
              : labels[value];
          },
        },
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        title: {
          display: true,
          text: data.xTitle,
        },
      },

      y: {
        border: {
          display: false,
        },
        grid: {
          display: true,
          drawBorder: false,
        },
        title: {
          display: true,
          text: data.yTitle,
        },

        stacked: true,
        ticks: {
          callback: function (value: any, index: any, ticks: any) {
            return yLabels[value];
          },
        },
      },
    },
  };
  const labels = data.labels;
  const [dropdownSelectedValueWithId, setDropdownSelectedValueWithId] =
    useState<any>({});

  const graphData = {
    labels,
    datasets: [
      {
        data: data.lineData,
        backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "rgba(186, 166, 255, 1)");
          gradient.addColorStop(0.5, "rgba(41, 55, 167, 1)");
          gradient.addColorStop(1, "rgba(41, 55, 167, 1)");
          return gradient;
        },
        borderColor: "rgba(104, 98, 224, 1)",
        borderWidth: 1.5,
        fill: true,
        type: "line" as const,
        label: "Line Dataset",
        pointStyle: "circle",
        pointRadius: 3.5,
        pointBorderColor: "rgba(41, 55, 167, 1)",
      },
      {
        label: "",
        data: data.xData,
        backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "rgba(64, 180, 172, 1)");
          gradient.addColorStop(0.5, "rgba(64, 180, 172, 1)");
          gradient.addColorStop(1, "rgba(57, 135, 130, 1)");
          return gradient;
        },
        stack: "Stack 0",
        barPercentage: 1,
        borderWidth: 0.5,
        borderRadius: 5.5,
        categoryPercentage: 0.1,
      },
      {
        label: "",
        data: data.xData.map((each: any) => 10 - each),
        backgroundColor: "#D0D8EF",
        borderRadius: 2,
        stack: "Stack 0",
        barPercentage: 1,
        categoryPercentage: 0.1,
      },
    ],
  };

  return (
    <div
      className={`${styles.analyticChartContainer} ${styles.classPerformenceChart}`}
    >
      <div className={styles.analyticsHeader}>
        <div className={styles.analyticsText}>{analyticsHeading || ""}</div>
        <div className={styles.optionSection}>
          <div className={styles.legendSection}>
            {!isLinePlot && (
              <>
                <div className={styles.eachLegend}>
                  <div className={styles.legendImg}>
                    <img src={pointImg} />
                  </div>
                  <div className={styles.legendLabel}>Average Score</div>
                </div>
                <div className={styles.eachLegend}>
                  <div
                    className={`${styles.legendIcon} ${styles.submitted}`}
                  ></div>
                  <div className={styles.legendLabel}>Submitted</div>
                </div>
                <div className={styles.eachLegend}>
                  <div
                    className={`${styles.legendIcon} ${styles.missed}`}
                  ></div>
                  <div className={styles.legendLabel}>Missed</div>
                </div>
              </>
            )}
            {!isLinePlot && showDropDownFilter && (
              <div className={styles.seperator}></div>
            )}
            {showDropDownFilter && (
              <div className={styles.calenderDropdownWrapper}>
                <div className={styles.calenderIcon}>
                  <img src={classPerformanceChartCalendar} />
                </div>
                <div className={styles.chartDropdown}>
                  {/*for single dropdown usage we need to pass patternIndex as 0 */}
                  {/* <Dropdown
                    isDropdownOutlineRequired={false}
                    minWidth={chartFilterWidth ? chartFilterWidth : "150px"}
                    key={"chart_classPerformance_chart"}
                    options={chartFilterOptions}
                    defaultLabel={chartFilterDefaultLabel || "Select option"}
                    patternIndex={0}
                    initialSelectedPatternsValues={selectedValues}
                    setUpdatedSelectedPatternValues={setUpdatesSelectedValues}
                  /> */}
                  <Dropdown
                    options={chartFilterOptions}
                    defaultLabel="Last 15 days"
                    minWidth={chartFilterWidth ? chartFilterWidth : "150px"}
                    selectedValue="Last 15 days"
                    setDropdownSelectedValueWithId={
                      setDropdownSelectedValueWithId
                    }
                    key={"chart_classPerformance_chart"}
                    isDropdownOutlineRequired={false}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.bar}>
        <Chart type="bar" options={options} data={graphData} />
      </div>
      <div className={styles.scrollButtonContainer}>
        <div
          className={
            scrollState.left
              ? `${styles.scrollButtonsActive} ${styles.scrollButtonsLeft}`
              : `${styles.scrollButtonsInActive} ${styles.scrollButtonsLeft}`
          }
        >
          <img src={prev} alt="prev" onClick={handleScrollLeft} />
        </div>
        <div
          className={
            scrollState.right
              ? `${styles.scrollButtonsActive} ${styles.scrollButtonsRight}`
              : `${styles.scrollButtonsInActive} ${styles.scrollButtonsRight}`
          }
        >
          <img src={next} alt="next" onClick={handleScrollRight} />
        </div>
      </div>
    </div>
  );
};

export default ClassPerformanceChart;
