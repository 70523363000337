import React from "react";
import styles from "./studentPerformanceIconBox.module.scss";

interface IStudentPerformanceIconBox {
  performance: {
    icon: any;
    value: string;
    title: string;
    type?: string;
  };
}

const StudentPerformanceIconBox = ({
  performance,
}: IStudentPerformanceIconBox) => {
  return (
    <div className={styles.studentPerformance}>
      <div className={styles.performanceIcon}>
        <img src={performance?.icon} />
      </div>
      <div className={styles.valueTitleContainer}>
        <div className={styles.valContainer}>
          <div className={styles.value}>{performance?.value}</div>
          {performance?.type === "time" && (
            <div className={styles.val}>hrs</div>
          )}
          {performance?.type === "score" && (
            <div className={styles.val}>%</div>
          )}
        </div>
        <div className={styles.title}>{performance?.title}</div>
      </div>
    </div>
  );
};

export default StudentPerformanceIconBox;
