import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { makeAxiosRequest } from "aie_common";
import styles from "./myAssignments.module.scss";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { constants } from "../../constants/constants";
import StatusContext from "../../context/loaderContext";
import ApiEdpoints, { HTTPMethods } from "../../constants/api_endpoints";
import TestList from "./testList";
import { useParams } from "react-router-dom";

const TestPage = ({ istabbedPage = false, isForReveiw = false }: any) => {
  const detailedViewRef = useRef(null);
  const [assignmentsData, setAssignmentsData] = useState<object[]>([]);
  const [testDates, setTestDates] = useState<any>([]);
  const [selectedTestDate, setSelectedTestDate] = useState<any>("");
  const params = useParams();

  const loaderStatus: any = useContext(StatusContext);
  const courseId = useSelector(
    (state: any) => state.userReducer.registeredCourseId
  );
  const studentSection = useSelector(
    (state: any) => state.userReducer.studentSection
  );
  const fetchAssignmentsList = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.GENERATETESTPAPER}/?course_id=${courseId}&section=${studentSection}`,
      data: {},

      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);
      if (response && response.status === 200) {
        const assignmentsDataRes = response?.data?.data?.test_paper_data;
        setAssignmentsData(assignmentsDataRes);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const fetchTestDate = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `get_test_dates/?course_id=${courseId}&section=${
        studentSection || "A"
      }`,
      data: {},

      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);
      if (response && response.status === 200) {
        if (response.data.homework_dates.length) {
          setTestDates(response.data.homework_dates);
          setSelectedTestDate(response.data.homework_dates[0]);
        }
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const fetchTestData = async (date: any) => {
    if (!params.studentId || !date || !courseId) {
      return;
    }
    loaderStatus.setState({ show: true });
    const payload = {
      url: `testpapercorrection/?student_id=${
        params.studentId
      }&date=${date}&course_id=${courseId}&section=${studentSection || "A"}`,
      // url: `homeworkcorrection/?student_id=3055&date=${date}&course_id=${courseId}`,
      data: {},

      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);
      if (response && response.status === 200) {
        setAssignmentsData(response.data.results.data);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    if (isForReveiw) {
      fetchTestData(selectedTestDate);
    }
  }, [selectedTestDate]);

  useEffect(() => {
    if (isForReveiw) {
      fetchTestDate();
    } else {
      fetchAssignmentsList();
    }
  }, []);

  return (
    <div
      className={`${styles.myAssignmentsMainContainer} ${
        istabbedPage ? styles.allAssignmentsMainContainerTabbed : ""
      }`}
      ref={detailedViewRef}
    >
      {!istabbedPage && (
        <Breadcrumb
          breadcrumbDetails={[
            {
              label: "Home",
              // link: "/home",
              selected: false,
            },
            {
              label: "My Tests",
              link: "/tests",
              selected: true,
            },
          ]}
        />
      )}

      <TestList
        detailedViewRef={detailedViewRef}
        assignmentsData={assignmentsData}
        istabbedPage={istabbedPage}
        isForReveiw={isForReveiw}
        testDates={testDates}
        selectedTestDate={selectedTestDate}
        setSelectedTestDate={setSelectedTestDate}
      />
    </div>
  );
};

export default React.memo(TestPage);
