export const adminStudentEditFormTemplate = [
  {
    groupName: "Student Details",
    fields: [
      [
        {
          label: "First Name",
          placeholder: "First Name",
          type: "text",
          name: "firstName",
          id: "firstName",
          required: true,
          rules: [],
        },
        {
          label: "Last Name",
          placeholder: "Last Name",
          type: "text",
          name: "lastName",
          id: "lastName",
          required: true,
          rules: [],
        },
      ],
      [
        {
          label: "Gender",
          placeholder: "Gender",
          type: "radio",
          name: "gender",
          id: "gender",
          options: [
            {
              name: "Male",
              value: "male",
              id: "1",
            },
            {
              name: "Female",
              value: "female",
              id: "2",
            },
            {
              name: "others",
              value: "other",
              id: "3",
            },
          ],
          required: true,
          rules: [],
        },

        {
          label: "Roll Number",
          placeholder: "Roll Number",
          type: "number",
          name: "rollNumber",
          id: "rollNumber",
          required: true,
          rules: [],
        },
      ],
      [
        {
          label: "Phone Number*",
          placeholder: "Phone",
          type: "tel",
          required: true,
          rules: [],
          name: "phone",
          id: "phone",
        },
      ],

      [
        {
          label: "Select Class",
          placeholder: "Select Class",
          type: "select",
          name: "class",
          id: "class",
          options: [
            {
              name: "12",
              value: "12",
              id: "12",
            },
            {
              name: "11",
              value: "11",
              id: "11",
            },
            {
              name: "10",
              value: "10",
              id: "10",
            },
            {
              name: "9",
              value: "9",
              id: "9",
            },
            {
              name: "8",
              value: "8",
              id: "8",
            },
            {
              name: "7",
              value: "7",
              id: "7",
            },
            {
              name: "6",
              value: "6",
              id: "6",
            },
          ],
          required: true,
          rules: [],
        },
        {
          label: "Select Section",
          placeholder: "Select Section",
          type: "select",
          name: "section",
          id: "section",
          options: [
            {
              name: "A",
              value: "A",
              id: "A",
            },
            {
              name: "B",
              value: "B",
              id: "B",
            },
            {
              name: "C",
              value: "C",
              id: "C",
            },
            {
              name: "D",
              value: "D",
              id: "D",
            },
            {
              name: "E",
              value: "E",
              id: "E",
            },
          ],
          required: true,
          rules: [],
        },
      ],
    ],
  },
  {
    groupName: "Parent/Guardian Details",
    fields: [
      [
        {
          label: "First Name",
          placeholder: "First Name",
          type: "text",
          name: "pfirstName",
          id: "pfirstName",
          required: true,
          rules: [],
        },
        {
          label: "Last Name",
          placeholder: "Last Name",
          type: "text",
          name: "plastName",
          id: "plastName",
          required: true,
          rules: [],
        },
      ],
      [
        {
          label: "Primary Email ID*",
          placeholder: "Email",
          type: "email",
          required: true,
          rules: [],
          name: "pprimaryEmail",
          id: "pprimaryEmail",
        },

        {
          label: "Primary Phone Number*",
          placeholder: "Phone",
          type: "tel",
          required: true,
          rules: [],
          name: "pprimaryPhone",
          id: "pprimaryPhone",
        },
      ],
      [
        {
          label: "Relationship",
          placeholder: "Relationship",
          type: "radio",
          name: "relationship",
          id: "relationship",
          options: [
            {
              name: "Father",
              value: "father",
              id: "father",
            },
            {
              name: "Mother",
              value: "Mother",
              id: "Mother",
            },
            {
              name: "Others",
              value: "other",
              id: "other",
            },
          ],
          required: true,
          rules: [],
        },
      ],
    ],
  },
];

export const adminStudentAddFormTemplate = [
  {
    groupName: "Fill the following details to add new student",
    fields: [
      [
        {
          label: "First Name",
          placeholder: "First Name",
          type: "text",
          name: "firstName",
          id: "firstName",
          required: true,
          rules: [],
        },
        {
          label: "Last Name",
          placeholder: "Last Name",
          type: "text",
          name: "lastName",
          id: "lastName",
          required: true,
          rules: [],
        },
      ],
      [
        {
          label: "Email ID*",
          placeholder: "Email",
          type: "email",
          required: true,
          rules: [],
          name: "email",
          id: "email",
        },

        {
          label: "Phone Number*",
          placeholder: "Phone",
          type: "tel",
          required: true,
          rules: [],
          name: "phone",
          id: "phone",
        },
      ],
      [
        {
          label: "Roll Number",
          placeholder: "Roll Number",
          type: "number",
          name: "rollNumber",
          id: "rollNumber",
          required: true,
          rules: [],
        },
      ],

      [
        {
          label: "Gender",
          placeholder: "Gender",
          type: "radio",
          name: "gender",
          id: "gender",
          options: [
            {
              name: "Male",
              value: "male",
              id: "1",
            },
            {
              name: "Female",
              value: "female",
              id: "2",
            },
            {
              name: "others",
              value: "others",
              id: "3",
            },
          ],
          required: true,
          rules: [],
        },
      ],
    ],
  },
  {
    groupName: "Parent/Guardian Details",
    fields: [
      [
        {
          label: "First Name",
          placeholder: "First Name",
          type: "text",
          name: "pfirstName",
          id: "pfirstName",
          required: true,
          rules: [],
        },
        {
          label: "Last Name",
          placeholder: "Last Name",
          type: "text",
          name: "plastName",
          id: "plastName",
          required: true,
          rules: [],
        },
      ],
      [
        {
          label: "Primary Email ID*",
          placeholder: "Email",
          type: "email",
          required: true,
          rules: [],
          name: "pprimaryEmail",
          id: "pprimaryEmail",
        },

        {
          label: "Primary Phone Number*",
          placeholder: "Phone",
          type: "tel",
          required: true,
          rules: [],
          name: "pprimaryPhone",
          id: "pprimaryPhone",
        },
      ],
      [
        {
          label: "Relationship",
          placeholder: "Relationship",
          type: "radio",
          name: "relationship",
          id: "relationship",
          options: [
            {
              name: "Father",
              value: "Father",
              id: "1",
            },
            {
              name: "Mother",
              value: "Mother",
              id: "2",
            },
            {
              name: "Others",
              value: "others",
              id: "3",
            },
          ],
          required: true,
          rules: [],
        },
      ],
    ],
  },
];
