import { createContext } from "react";

const initValue = {
    show: false,
    taskId: ''
};

const StatusContext = createContext({});

export default StatusContext;
export { initValue };