import React from "react";
import closeIcon from "../../../../../assets/images/closeIcon.svg";
import Modal from "@mui/material/Modal";
import styles from "./deleteQuestionModal.module.scss";
import trashIconForModal from "../../../../../assets/images/trashIconForModal.svg";

const DeleteQuestionModal = ({
  deleteQuestionHandler,
  deleteQuestionModalOpen,
}: any) => {
  return (
    <Modal
      open={deleteQuestionModalOpen}
      onClose={deleteQuestionHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.deleteQuestionModal}>
        <div className={styles.deleteQuestionHeader}>
          <div className={styles.deleteQuestion}>Delete Question</div>
          <div onClick={deleteQuestionHandler}>
            <img src={closeIcon} />
          </div>
        </div>
        <div className={styles.deleteQuestionBody}>
          <div className={styles.trashIconAndTextContainer}>
            <img src={trashIconForModal} />
            <div className={styles.deleteText}>
              The question will be deleted permanently. Do you want to continue?
            </div>
          </div>
          <div className={styles.optionsButton}>
            <button className={styles.noButton} onClick={deleteQuestionHandler}>
              no
            </button>
            <button
              className={styles.yesButton}
              onClick={deleteQuestionHandler}
            >
              yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default DeleteQuestionModal;
