import React from "react";
import styles from "./question.module.scss";
import { QuestionAndOptionsElement } from "../../../../../../common/questionsAndOptions/questionAndOptions";

interface IQuestionWrapper {
  question: any;
  activeQuestionIndex: any;
}

export const Question = (props: IQuestionWrapper) => {
  const { question, activeQuestionIndex } = props;

  return (
    <>
      <div className={styles.questionWrapperContainer}>
        <div className={`${styles.questionSection} ${styles.inter20}`}>
          <p>Q{question[activeQuestionIndex]?.id}.</p>
          <div className={styles.questionContainer}>
            <QuestionAndOptionsElement
              metaData={question[activeQuestionIndex]?.question}
              size="question"
            />
            {question[activeQuestionIndex]?.questionImg && (
              <img
                alt=""
                className={styles.questionImage}
                src={question[activeQuestionIndex]?.questionImg}
              />
            )}
          </div>
        </div>
        <div className={styles.textScore}>
          {question[activeQuestionIndex]?.marks_alloted ||
          question[activeQuestionIndex]?.score
            ? question[activeQuestionIndex]?.marks_alloted ||
              question[activeQuestionIndex]?.score
            : 0}{" "}
          Marks
        </div>
      </div>
    </>
  );
};
