import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { makeAxiosRequest } from "aie_common";
import { constants } from "../../../constants/constants";
import styles from "./exercises.module.scss";
import StatusContext from "../../../context/loaderContext";
import ApiEdpoints, { HTTPMethods } from "../../../constants/api_endpoints";
import Breadcrumb from "../../../common/breadcrumb/breadcrumb";
import ExerciseList from "./exerciseList";

const Exercises = () => {
  const detailedViewRef = useRef(null);
  const [assignmentsData, setAssignmentsData] = useState<object[]>([]);
  const courseId = useSelector(
    (state: any) => state.userReducer?.registeredCourseId
  );
  const loaderStatus: any = useContext(StatusContext);
  const [chapterDetailsData, setChapterDetailsData] = useState<any>();
  const [patternOptions, setPatternOptions] = useState<any>([]);

  const fetchAssignmentsList = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.LIST_OF_ASSIGNMENTS}?class_id=${courseId}`,
      data: {},

      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);
      if (response && response.status === 200) {
        const assignmentsDataRes = response?.data?.data;

        assignmentsDataRes.sort((a: any, b: any) => {
          const numberA = parseInt(a.name.match(/\d+/)[0]);
          const numberB = parseInt(b.name.match(/\d+/)[0]);

          return numberA - numberB;
        });

        setAssignmentsData(assignmentsDataRes);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  // not integrated with ui but the api call is working fine but data is coming as empty array
  useEffect(() => {
    fetchAssignmentsList();
  }, []);

  return (
    <div className={styles.myAssignmentsMainContainer} ref={detailedViewRef}>
      <Breadcrumb
        breadcrumbDetails={[
          {
            label: "Home",
            link: "/home",
            selected: false,
          },
          {
            label: "Practice",
            link: "/practice/exercises",
            selected: false,
          },
          {
            label: "Exercises",
            link: "/practice/exercises",
            selected: true,
          },
        ]}
      />
      <ExerciseList
        detailedViewRef={detailedViewRef}
        assignmentsData={assignmentsData}
        patternOptions={patternOptions}
      />
    </div>
  );
};

export default React.memo(Exercises);
