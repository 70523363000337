import React, { useState, useEffect, useContext } from "react";
import styles from "./MCQResult.module.scss";
import { makeAxiosRequest } from "aie_common";
import ApiEdpoints, {
  HTTPMethods,
} from "../../../../../constants/api_endpoints";
import { QuestionSection } from "../questionSection/questionSection";
import StatusContext from "../../../../../context/loaderContext";
import arrowback from "../../../../../assets/images/arrowback.svg";
import ResultSection from "./resultSection/resultSection";
import ReviewResultSection from "./reviewResultSection/reviewResultSection";
import { useNavigate } from "react-router-dom";
interface IAssignmentMetadata {
  totalScore: number;
  totalMarks: number;
  attemptNumber: string;
  lastAttempt: string;
  timetaken: string;
  totalQuestions: number;
  attemptedCount: number;
  correctCount: number;
  incorrectCount: number;
}

const MCQResult = () => {
  const loaderStatus: any = useContext(StatusContext);
  const [questionsData, setQuestionsData]: any = useState([]);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<any>(0);
  const [assignmentMetadata, setAssignmentMetadata] =
    useState<IAssignmentMetadata>();
  const [isReview, setIsReview]: any = useState(true);
  const navigate = useNavigate();

  const fetchAssignmentSubmissionData = async () => {
    loaderStatus.setState({ show: true });
    const paths = window.location?.pathname.split("/");
    const topicId = paths[paths?.length - 1];
    const pageType = paths[paths?.length - 2];
    setIsReview(pageType === "assignmentReview");
    const payload = {
      url: ApiEdpoints.MCQ_RESULTS + `/${topicId}`,
      //url: `assignment_question_list/?topic_id=${topicId}`,

      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      loaderStatus.setState({ show: false });
      if (res.data && res.data.status === "success") {
        let score = 0;
        let marks = 0;
        res.data.data.forEach((ele: any, index: number) => {
          marks += ele.marks_alloted;
          score += ele.score;
          ele["Answers"] = ele.answers;
          ele["id"] = index + 1;
          ele["selectedAnswer"] = Number(ele.ans_submit);
          ele["questionImg"] = ele["question_img"];
        });
        let metaData = {
          totalScore: score,
          totalMarks: marks,
          attemptNumber: "",
          lastAttempt: "",
          timetaken: "",
          totalQuestions: res.data.data.length,
          attemptedCount: res.data.data.length - 3,
          correctCount: 2,
          incorrectCount: 1,
        };
        setAssignmentMetadata(metaData);
        res.data.data = res.data.data.map((item: any) => {
          return {
            Answers: item.answers,
            ...item,
          };
        });
        setQuestionsData(res.data.data);
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAssignmentSubmissionData();
  }, []);

  return (
    <>
      {questionsData.length > 0 ? (
        <div className={styles.solveAssignmentWrap}>
          <div
            className={styles.headingStyle}
            onClick={() => {
              navigate(-1);
            }}
          >
            <img className={styles.arrowStyle} src={arrowback} />
            {questionsData[0]?.subtopicname} MCQs
          </div>
          <div className={styles.horizontalLine}></div>
          <div className={styles.assignmentPageContainer}>
            <QuestionSection
              question={questionsData}
              setQuestion={setQuestionsData}
              activeQuestionIndex={activeQuestionIndex}
              setActiveQuestionIndex={setActiveQuestionIndex}
              saveQuestion={() => {}}
              activeAssignmentType={"submittedMCQ"}
              activeScreen={"submittedMCQ"}
            />
            {!isReview ? (
              <ResultSection
                question={questionsData}
                activeQuestionIndex={activeQuestionIndex}
                resultData={assignmentMetadata}
              />
            ) : (
              <ReviewResultSection
                question={questionsData}
                activeQuestionIndex={activeQuestionIndex}
                resultData={assignmentMetadata}
              />
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MCQResult;
