import React, { useContext, useEffect, useState } from "react";
import styles from "./adminReports.module.scss";
import Breadcrumb from "../../../common/breadcrumb/breadcrumb";
import DashboardStatsCard from "../../../common/admin/dashboardStatsCard/dashboardStatsCard";
import TotalEnrollment from "../../../common/admin/totalEnrollment/totalEnrollment";
import AverageLevelCard from "../../../common/admin/averageLevelCard/averageLevelCard";
import StudentsLevel from "../../../common/admin/studentsLevel/studentsLevel";
import AnalyticsChart from "../../learn/components/analyticsChart/analyticsChart";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Dropdown from "../../../common/dropdown/dropdown";
import StatusContext from "../../../context/loaderContext";
import { HTTPMethods, makeAxiosRequest } from "../../../utils/httpService";
import ClassPerformanceChart from "../../../common/classPerformanceChart/classPerformanceChart";
import AdminReportChart from "./adminReportChart";
const AdminReports = () => {
  const loaderStatus: any = useContext(StatusContext);
  const [studentClasses, setStudentClasses] = useState<any>([
    { name: "All Classes", id: -1 },
  ]);
  const [selectedClass, setSelectedClass] = useState<any>(null);
  const [classSection, setClassSection] = useState<any>([
    { name: "All Section", id: -1 },
  ]);
  const [selectedSection, setSelectedSection] = useState<any>(null);

  const getStudentClasses = async () => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: "school-admin/class/",
      method: HTTPMethods.GET,
      headers: {},
    };

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data.length) {
        const classList = data.map((item: any) => {
          return { name: String(item.name), id: item.code };
        });
        setStudentClasses(classList);
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });

      console.log(error);
    }
  };
  useEffect(() => {
    getStudentClasses();
  }, []);

  const getClassSections = async () => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: "school-admin/section/",
      method: HTTPMethods.GET,
      headers: {},
    };

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data.length) {
        const sectionList = data.map((item: any) => {
          return { name: String(item.name), id: item.id };
        });
        setClassSection([{ name: "All", id: -1 }, ...sectionList]);
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });

      console.log(error);
    }
  };

  const chartData = {
    analyticsHeading: "Admin Report",
    data: {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      xTitle: "Months",
      yTitle: "Performance",
      xData: [8, 7, 9, 6, 8, 7], // Example data for submitted questions
      lineData: [6, 5, 7, 4, 6, 5], // Example data for reviewed questions
    },
    showDropDownFilter: true,
    chartFilterOptions: [
      { name: "Months", id: 1 },
      { name: "Last 30 days", id: 2 },
    ],
    chartFilterDefaultLabel: "Select Filter",
    chartFilterWidth: "120px",
    comparisonsInX_Axis: 4,
  };

  return (
    <div className={styles.learnMainContainer}>
      <div className={styles.greetingsSearchBarContainer}>
        <div className={styles.greetings}>
          <Breadcrumb
            breadcrumbDetails={[
              {
                label: "Home",
                link: "/admin",
                selected: true,
              },
            ]}
          />
        </div>
      </div>
      <div className={styles.homeContainer}>
        <div className={styles.homeContainer_info}>
          <Dropdown
            options={studentClasses}
            defaultLabel={"Select A Class"}
            minWidth={"225px"}
            overlayWidth={"230px"}
            id={"selectClass"}
            selectedValue={(item: string) => console.log("Test2")}
            isMenuItemTypeText={true}
            setDropdownSelectedValueWithId={(item: any) => {
              setSelectedClass(item.id);
              getClassSections();
            }}
          />
          {selectedClass && (
            <div className={styles.classDetails_sections}>
              <RadioGroup
                row
                value={selectedSection || -1}
                onChange={(e) => {
                  console.log();
                  setSelectedSection(e.target.value);
                }}
              >
                {!!classSection.length &&
                  classSection.map((option: any) => {
                    return (
                      <FormControlLabel
                        value={option.id}
                        control={<Radio className={styles.radioEle} />}
                        label={
                          option.id === -1
                            ? "All Section"
                            : "Section " + option.name
                        }
                      />
                    );
                  })}
              </RadioGroup>
            </div>
          )}

          <div className={styles.homeContainer_data}>
            <AverageLevelCard levelCardData={"L3"} />
            <TotalEnrollment
              totalEnrollment={{
                total: "2799",
              }}
            />
            <StudentsLevel
              levels={{
                l1: "30%",
                l2: "20%",
                l3: "40%",
                l4: "5%",
                l5: "5%",
              }}
            />
            <TotalEnrollment
              totalEnrollment={{
                total: "90%",
              }}
              heading="Syllabus Covered"
            />
          </div>

          <AdminReportChart {...chartData} />
        </div>
      </div>
    </div>
  );
};

export default AdminReports;

const analyticsData = {
  labels: [
    "Revisiting Irrational numbers",
    "The Fundamental Theorem of Arithmetic",
    "Euclid's Division Lemma",
    "Revisiting Rational numbers and their Decimal expansions",
    "Exponential and Logarithims (SSC)",
    null,
  ],
  datasets: [
    {
      label: "",
      data: [4, 4, 2, 4, 4, 1],
      backgroundColor: "#5A66C8",
      stack: "Stack 0",
      borderRadius: 4,
      categoryPercentage: 0.2,
      barPercentage: 1,
      maxBarThickness: 20,
    },
    {
      label: "",
      data: [],
      backgroundColor: "#9AAAFF",
      stack: "Stack 1",
      borderRadius: 4,
      categoryPercentage: 0.2,
      barPercentage: 1,
      maxBarThickness: 20,
    },
  ],
};
