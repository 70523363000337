import { useEffect, useState } from "react";
import Circle from "./circle";
import classes from "./progress.module.scss";
import smallRocketForProgressBar from "../../assets/images/smallRocketForProgressBar.svg";
import rocketForProgressBar from "../../assets/images/rocketForProgressBar.svg";

/* 
  progress - to set the progress from 0 to 100
  noOflevels - to set the no of levels  
  
  to show milestone only when reached pass false to prop levelsVisible 
  <Progressbar progress={100} noOflevels={5} levelsVisible={false} />

  to shows all milestones pass true to prop levelsVisible
  <Progressbar progress={100} noOflevels={5} levelsVisible={true} />

  width of the progressbar will adjust based on it's parent width
*/

const Progressbar = ({
  progress,
  noOflevels,
  levelsVisible,
  isRocketVisible = true,
  enableProgressBGColor,
  width = '100%'
}: any) => {
  const [circle, setCircle] = useState(noOflevels);
  const [accomplished, setAccomplished] = useState(0);
  const percentage = 100;
  let percent = Math.floor(progress);
  const progressbarHeight = !levelsVisible ? "10px" : "18px";

  const levelnumber = percentage / noOflevels;
  useEffect(() => {
    setAccomplished(Math.floor(percent / levelnumber));
    setCircle(noOflevels);
  }, [percent, circle, noOflevels]);

  const levels = [];

  if (!levelsVisible) {
    for (let i = 1; i <= circle; i++) {
      levels.push(
        <Circle
          className={`${
            percent == Math.ceil(levelnumber * accomplished) &&
            i == accomplished
              ? `${classes.smallCircle_mileStoneReached} ${
                  classes.smallLevelNumber_mileStoneReached
                } ${
                  enableProgressBGColor
                    ? classes.pinkSmallCircle_mileStoneReached
                    : classes.defaultSmallCircle_mileStoneReached
                }`
              : i > accomplished
              ? `${classes.smallCircle} ${
                  enableProgressBGColor
                    ? classes.blueSmallCircleBG
                    : classes.defaultSmallCircleBG
                }`
              : `${classes.smallCircle_accomplished} ${
                  enableProgressBGColor
                    ? classes.pinkSmallCircle_accomplished
                    : classes.defaultSmallCircle_accomplished
                }`
          }`}
          key={i}
        >
          {percent == Math.ceil(levelnumber * accomplished) &&
            i == accomplished && <span key={i}>L{i}</span>}
        </Circle>
      );
    }
  } else {
    for (let i = 1; i <= circle; i++) {
      levels.push(
        <Circle
          className={`${
            i > accomplished ? classes.circle : classes.circle_accomplished
          }`}
          key={i}
        >
          <span
            key={i}
            className={`${
              i > accomplished
                ? classes.levelNumber
                : classes.levelNumber_accomplished
            }`}
          >
            L{i}
          </span>
        </Circle>
      );
    }
  }

  return (
    <>
      <div
        className={`${classes.progressbar} ${
          enableProgressBGColor
            ? classes.blueProgressBarBg
            : classes.defaultProgressBarBG
        }`}
        style={{ height: `${progressbarHeight}`, width: `${width}` }}
      >
        <div
          className={`${classes.progress} ${
            enableProgressBGColor
              ? classes.pinkProgressBGColor
              : classes.defaultProgressBGColor
          }`}
          style={{ width: `${percent}%`, height: `${progressbarHeight}` }}
        >
          {!levelsVisible ? (
            <span className={classes.smallRocketSvg}>
              {isRocketVisible && <img src={smallRocketForProgressBar} />}
            </span>
          ) : (
            <span className={classes.rocketSvg}>
              {isRocketVisible && <img src={rocketForProgressBar} />}
            </span>
          )}
        </div>
        {levels}
      </div>
    </>
  );
};

export default Progressbar;
