import React from "react";
import styles from "./StudentPerformanceIconBox.module.scss";

interface IStudentPerformanceIconBox {
  performance: {
    icon: any;
    value: string;
    title: string;
  };
}

const StudentPerformanceIconBox = ({
  performance,
}: IStudentPerformanceIconBox) => {
  return (
    <div className={styles.studentPerformance}>
      <div className={styles.performanceIcon}>
        <img src={performance.icon} />
      </div>
      <div className={styles.valueTitleContainer}>
        <div className={styles.value}>{performance?.value}</div>
        <div className={styles.title}>{performance?.title}</div>
      </div>
    </div>
  );
};

export default StudentPerformanceIconBox;