import React, { useContext, useEffect, useState } from "react";
import styles from "./superAdminHome.module.scss";
import SearchBar from "../../../common/searchBar/searchBar";
import DashboardStatsCard from "../../../common/admin/dashboardStatsCard/dashboardStatsCard";
import DashboardNotification from "../../../common/admin/dashboardNotification/dashboardNotification";
import Breadcrumb from "../../../common/breadcrumb/breadcrumb";
import TopPerformingSchools from "../../../common/admin/topPerfomingSchools/topPerfomingSchools";
import TopTeachers from "../../../common/admin/topTeachers/topTeachers";
import { HTTPMethods, makeAxiosRequest } from "../../../utils/httpService";
import StatusContext from "../../../context/loaderContext";

const SuperAdminHome = () => {
  const loaderStatus: any = useContext(StatusContext);
  const [dashboardCountData, setDashboardCountData] = useState<any>([]);

  const getDashboardData = async () => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: "super-admin/total_schools_teachers_and_students",
      method: HTTPMethods.GET,
      headers: {},
    };

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        const ddata = [
          {
            label1: "No. of Schools",

            count1: data.schools_count,
          },
          {
            label1: "No. of Teachers",

            count1: data.teachers_count,
          },
          {
            label1: "No. of Students",

            count1: data.students_count,
          },
        ];

        setDashboardCountData(ddata);
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });

      console.log(error);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);
  return (
    <div className={styles.learnMainContainer}>
      <div className={styles.greetingsSearchBarContainer}>
        <div className={styles.greetings}>
          <Breadcrumb
            breadcrumbDetails={[
              {
                label: "Home",
                link: "/super-admin",
                selected: true,
              },
            ]}
          />
        </div>
      </div>
      <div className={styles.homeContainer}>
        <h2>Hello Rahul, Welcome to your dashboard!</h2>
        <div className={styles.homeContainer_info}>
          <div className={styles.homeContainer_data}>
            {dashboardCountData.length &&
              dashboardCountData.map((data: any, index: any) => (
                <DashboardStatsCard key={index} dataObj={data} />
              ))}
          </div>
          <div className={styles.homeContainer_notification}>
            <DashboardNotification
              data={notifications}
              link={"/admin/notifications"}
            />
          </div>
        </div>
        <div className={styles.homeContainer_top}>
          <TopPerformingSchools />
          <TopTeachers />
        </div>
      </div>
    </div>
  );
};

export default SuperAdminHome;

const dashBoardData = [
  {
    label1: "No. of Students",

    count1: "2799",
    url: "www.google.com",
  },
  {
    label1: "No. of Teachers",

    count1: "110",

    url: "www.google.com",
  },
  {
    label1: "No. of Classes",

    count1: "05",

    url: "www.google.com",
  },
];
let notifications = [
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempora.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempora dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempora dsadsdsadsaadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
];
