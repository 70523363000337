import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import PatternCard from "../../../../../learn/components/patternListing/patternCard/patternCard";
import styles from "./patternOverall.module.scss";
import carryingBooksAvatar from "../../../../../../assets/images/carryingBooksAvatar.svg";
import StatusContext from "../../../../../../context/loaderContext";
import ApiEdpoints, {
  HTTPMethods,
} from "../../../../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import { useParams } from "react-router-dom";
import PatternVideoOverlayContainer from "../../../../../../common/patternVideoOverlayContainer/patternVideoOverlayContainer";
import useOverlayDetails from "../../../../../../utils/patternDetailsApi";

const PatternOverall = () => {
  const { classId } = useParams();
  const [screenWidth, setScreenWidth] = useState(0);
  const loaderStatus: any = useContext(StatusContext);
  const [patternTobeFocused, setPatternTobeFocused] = useState<any[]>([]);
  const [massagedPatternFocusedData, setMassagedPatternFocusedData] = useState<
    any[]
  >([]);
  const [videoNotesOverlay, setVideoNotesOverlay] = useState(false);
  const isTabOpen = useSelector(
    (state: any) => state?.tabReducer?.tabOpen ?? true
  );
  const [videoNotesOverlayContent, setVideoNotesOverlayContent] =
    useState("pattern");
  const handlePatternVideoOverlayContent = (
    resource: "viewDocument" | "pattern" | "patternMap"
  ) => {
    setVideoNotesOverlayContent(resource);
  };
  const { patternOverlayData, getOverlayDetails }: any = useOverlayDetails();
  const handlePatternVideoOverlay = (type: any) => {
    setVideoNotesOverlay(!videoNotesOverlay);
    setVideoNotesOverlayContent("pattern");
  };
  useLayoutEffect(() => {
    function updateSize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const fetchPatternsTobeFocused = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.PATTERNS_TO_FOCUS_ON}/?class_id=${classId}`,
      method: HTTPMethods.GET,
      data: {},
    };
    try {
      const res = await makeAxiosRequest(payload);
      if (res?.status === 200) {
        loaderStatus.setState({ show: false });
        setPatternTobeFocused(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
      loaderStatus.setState({ show: false });
    }
  };

  useEffect(() => {
    fetchPatternsTobeFocused();
  }, [classId]);

  const dataMassaging = (data: any) => {
    const patternTobeFocused = data?.map((item: any) => {
      return {
        id: item?.id,
        level:
          item?.level && item?.level === 1
            ? "Primary"
            : item?.level === 2
            ? "Secondary"
            : item?.level === 3
            ? "Tertiary"
            : "",
        patternName: item?.name,
        patternDescription: item?.description,
        patternStrength: item?.pattern_mastery,
      };
    });
    return patternTobeFocused;
  };

  useEffect(() => {
    if (patternTobeFocused?.length > 0) {
      const data = dataMassaging(patternTobeFocused);
      setMassagedPatternFocusedData(data);
    }
  }, [patternTobeFocused]);

  return (
    <div className={styles.overallMainContaner}>
      {massagedPatternFocusedData.map((item: any, ind: number) => (
        <PatternCard
          handlePatternVideoOverlay={handlePatternVideoOverlay}
          patternItem={item}
          fromComponent="patternListing"
          key={`card_${ind}`}
          isClass={true}
          getOverlayDetails={getOverlayDetails}
        />
      ))}
      {!isTabOpen && screenWidth === 1440 && (
        <div className={styles.carryingBooksAvatarIcon}>
          <img src={carryingBooksAvatar} alt="carryingBooksAvatar" />
        </div>
      )}
      {videoNotesOverlay && (
        <PatternVideoOverlayContainer
          videoUrl={patternOverlayData?.video}
          docName={patternOverlayData?.patternname}
          patternCardData={{
            descriptionText: patternOverlayData?.description,
            patternHeadingText: patternOverlayData?.patternname,
          }}
          handlePatternVideoOverlayContent={handlePatternVideoOverlayContent}
          handlePatternOverlay={handlePatternVideoOverlay}
          videoNotesOverlayContent={videoNotesOverlayContent}
          pptUrl={patternOverlayData?.ppt}
          patternExamples={patternOverlayData?.pattern_examples}
        />
      )}
    </div>
  );
};

export default PatternOverall;
