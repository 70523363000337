import React, { useState } from "react";
import styles from "./dashboardPage.module.scss";
import TestCard from "../../common/cards/testCard";
import HomeworkCard from "../../common/cards/homeworkCard";
import PracticeTestChapterCard from "../../common/cards/practiceTestChapterCard";
import ExerciseCard from "../../common/cards/exerciseCard";

import DashboardStatsCard from "../../common/admin/dashboardStatsCard/dashboardStatsCard";

import DashboardNotification from "../../common/admin/dashboardNotification/dashboardNotification";

import ClassesBlock from "../../common/admin/classesBlock/classesBlock";

import StudentDetailsSideBar from "../../common/admin/studentDetailsSideBar/studentDetailsSideBar";

import TeachersDetailsSideBar from "../../common/admin/teachersDetailsSideBar/teachersDetailsSideBar";

import AverageCGPA from "../../common/admin/averageCGPA/averageCGPA";

import TotalEnrollment from "../../common/admin/totalEnrollment/totalEnrollment";

import StudentsLevel from "../../common/admin/studentsLevel/studentsLevel";

import Curriculum from "../../common/admin/curriculum/curriculum";

import StatsBlock from "../../common/admin/statsBlock/statsBlock";

import TeachersDetailsCard from "../../common/admin/teachersDetailsCard/teachersDetailsCard";

import StudentDetailsCard from "../../common/admin/studentDetailsCard/studentDetailsCard";

import AverageScoreCard from "../../common/admin/averageScoreCard/averageScoreCard";

import AverageLevelCard from "../../common/admin/averageLevelCard/averageLevelCard";

import AdminSideBar from "../../common/admin/adminSideBar/adminSideBar";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField
} from "@mui/material";
import RenderForm from "../../common/admin/renderForm/renderForm";
import SubmitAndCancelButton from "../../common/admin/submitAndCancelButton/submitAndCancelButton";
import RenderFormTwoTabs from "../../common/admin/renderFormTwoTabs/TwoTabsSideBar";
import TwoTabsSideBar from "../../common/admin/renderFormTwoTabs/TwoTabsSideBar";
import AdminTable from "../../common/admin/adminTable/adminTable";
import TopPerfomingSchools from "../../common/admin/topPerfomingSchools/topPerfomingSchools";
import TopPerformingSchools from "../../common/admin/topPerfomingSchools/topPerfomingSchools";
import TopTeachers from "../../common/admin/topTeachers/topTeachers";

const UIPreview = () => {
    const dashBoardData = [
        {
            label1: "No. of Students",
            label2: "Tests Submitted",
            label3: "Tests Attempted",
            count1: "2799",
            count2: "77%",
            count3: "92%",
            url: "www.google.com"
        },
        {
            label1: "No. of Teachers",
            label2: "Assigned Tests",
            label3: "Syllabus Covered",
            count1: "110",
            count2: "97%",
            count3: "86%",
            url: "www.google.com"
        },
        {
            label1: "No. of Classes",
            label2: "Overall Average Score",
            label3: "Best Performing Class",
            count1: "05",
            count2: "8.5/10",
            count3: "Class 8",
            url: "www.google.com"
        }
    ];

    let notifications = [
        {
            notification:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempora."
        },
        {
            notification:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempora dsadsadsdasdsa."
        },
        {
            notification:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempora dsadsdsadsaadsdasdsa."
        },
        {
            notification:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa."
        },
        {
            notification:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa."
        },
        {
            notification:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa."
        },
        {
            notification:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa."
        },
        {
            notification:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa."
        },
        {
            notification:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa."
        },
        {
            notification:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa."
        },
        {
            notification:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa."
        }
    ];

    const classData = [
        {
            class: "06",
            totalStudent: "250",
            url: "google.com"
        },
        {
            class: "07",
            totalStudent: "220",
            url: "google.com"
        },
        {
            class: "08",
            totalStudent: "50",
            url: "google.com"
        }
    ];

    const slideBarStudentData = {
        name: "Hanshitha Incal",
        class: "Std 08 Section B",
        rollNo: "03",
        email: "Incal@aieducator.com",
        contact: "+91 90000 45454",
        gender: "Female",
        photo: ""
    };

    const slideBarParentsData = {
        name: "Pushpa Incal",
        relationship: "Mother",
        email: "Incal@aieducator.com",
        contact: "+91 90000 45454"
    };

    const teacherData = {
        name: "Hanshitha Incal",
        class: "Std 08 Section B",
        teachersRoll: "Class Teacher",
        email: "Incal@aieducator.com",
        contact: "+91 90000 45454",
        gender: "Female",
        photo: ""
    };
    const mathsTeacherData = {
        name: "Hanshitha Incal",
        class: "Std 08 Section B",
        teachersRoll: "Maths Teacher",
        email: "Incal@aieducator.com",
        contact: "+91 90000 45454",
        gender: "Female",
        photo: ""
    };

    const avgCGPAData = {
        cgpa: "7"
    };
    const totalEnrollmentData = {
        total: "2799"
    };

    const studentsLevelData = {
        l1: "30%",
        l2: "20%",
        l3: "40%",
        l4: "5%",
        l5: "5%"
    };

    const curriculumData = {
        assignedTests: "97%",
        syllabusCovered: "86%"
    };

    const statsBlockData = [
        {
            label1: "No. of Classes",
            label2: "Overall Average Score",
            label3: "Best Performing Class",
            num1: "05",
            num2: "8.5/10",
            num3: "92%"
        },
        {
            label1: "No. of Students",
            label2: "Tests Submitted",
            label3: "Tests Attempted",
            num1: "2799",
            num2: "77%",
            num3: "92%"
        },
        {
            label1: "No. of Teachers",
            label2: "Class Teacher",
            label3: "Maths Teacher",
            num1: "50",
            num2: "25",
            num3: "25"
        }
    ];

    const teachersDetailsCardData = {
        classTeacher: "Gatik More",
        mathsTeacher: " Mahant Kapoor"
    };

    const studentDetailsCardData = {
        studentCount: "50",
        testAttempted: "90%",
        testSubmitted: "96%"
    };

    const averageScoreCardData = {
        averageScore: "7"
    };

    //  !=============================================================
    // student
    const [showStudentDetails, setShowStudentDetails] = useState(false);

    function showStudentSlideBar() {
        setShowStudentDetails(!showStudentDetails);
    }
    // teacher

    const [showTeachersDetails, setShowTeachersDetails] = useState(false);

    function showTeachersSlideBar() {
        setShowTeachersDetails(!showTeachersDetails);
    }

    // !show EditDetails SideBar

    const [showEditDetails, setShowEditDetails] = useState(false);

    function showEditDetailsSideBar() {
        setShowEditDetails(!showEditDetails);
    }

    // !editDetails Data
    const studentFormTemplate = [
        {
            groupName: "Student Details",
            fields: [
                [
                    {
                        label: "First Name",
                        placeholder: "First Name",
                        type: "text",
                        required: true,
                        rules: []
                    },

                    {
                        label: "Last Name",
                        placeholder: "Last Name",
                        type: "text",
                        required: true,
                        rules: []
                    }
                ],

                [
                    {
                        label: "Gender",
                        placeholder: "Gender",
                        type: "radio",
                        options: [
                            {
                                name: "Male",
                                value: "Male",
                                id: "1"
                            },
                            {
                                name: "Female",
                                value: "Female",
                                id: "2"
                            },
                            {
                                name: "others",
                                value: "others",
                                id: "3"
                            }
                        ],
                        required: true,
                        rules: []
                    },
                    {
                        label: "Select Roll Number",

                        placeholder: "Select Roll Number",
                        type: "select",
                        options: [
                            {
                                name: "rollNo",
                                value: "1",
                                id: "1"
                            },
                            {
                                name: "rollNo",
                                value: "2",
                                id: "2"
                            },
                            {
                                name: "rollNo",
                                value: "3",
                                id: "3"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Select Class",

                        placeholder: "Select Class",
                        type: "select",
                        options: [
                            {
                                name: "class",
                                value: "1",
                                id: "1"
                            },
                            {
                                name: "class",
                                value: "2",
                                id: "2"
                            },
                            {
                                name: "class",
                                value: "3",
                                id: "3"
                            }
                        ],
                        required: true,
                        rules: []
                    },
                    {
                        label: "Select Section",

                        placeholder: "Select Section",
                        type: "select",
                        options: [
                            {
                                name: "section",
                                value: "A",
                                id: "1"
                            },
                            {
                                name: "section",
                                value: "B",
                                id: "2"
                            },
                            {
                                name: "section",
                                value: "C",
                                id: "3"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ]
            ]
        }
    ];

    const parentsFormTemplate = [
        {
            groupName: "Parent/Guardian Details",
            fields: [
                [
                    {
                        label: "First Name",
                        placeholder: "First Name",
                        type: "text",
                        required: true,
                        rules: []
                    },
                    {
                        label: "Last Name",
                        placeholder: "Last Name",
                        type: "text",
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Mobile Number ",
                        placeholder: "Mobile Number",
                        type: "tel",
                        required: true,
                        rules: []
                    },
                    {
                        label: "Email ID ",
                        placeholder: "Email address",
                        type: "email",
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Relationship",
                        placeholder: "Relationship",
                        type: "radio",
                        options: [
                            {
                                name: "Father",
                                value: "Father",
                                id: "1"
                            },
                            {
                                name: "Mother",
                                value: "Mother",
                                id: "2"
                            },
                            {
                                name: "others",
                                value: "others",
                                id: "3"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ]
            ]
        }
    ];

    // !create New class SideBar

    const [addNewClassSidebar, setAddNewClassSidebar] = useState(false);

    function showNewClassSidebar() {
        setAddNewClassSidebar(!addNewClassSidebar);
    }

    const createNewClassTemplate = [
        {
            groupName: "Fill the following details to create a new Class",
            fields: [
                [
                    {
                        label: "Select the class standard",
                        placeholder: "First Name",
                        type: "radio",
                        required: true,
                        rules: [],
                        options: [
                            {
                                name: "6th",
                                value: "6th",
                                id: "1"
                            },
                            {
                                name: "7th",
                                value: "7th",
                                id: "2"
                            },
                            {
                                name: "8th",
                                value: "8th",
                                id: "3"
                            },
                            {
                                name: "9th",
                                value: "9th",
                                id: "4"
                            },
                            {
                                name: "10th",
                                value: "10th",
                                id: "5"
                            },
                            {
                                name: "11th",
                                value: "11th",
                                id: "6"
                            },
                            {
                                name: "12th",
                                value: "12th",
                                id: "7"
                            }
                        ]
                    }
                ],
                [
                    {
                        label: "Select Section",

                        placeholder: "Select Section",
                        type: "select",
                        options: [
                            {
                                name: "Section",
                                value: "1",
                                id: "1"
                            },
                            {
                                name: "Section",
                                value: "2",
                                id: "2"
                            },
                            {
                                name: "Section",
                                value: "3",
                                id: "3"
                            }
                        ],
                        required: true,
                        rules: []
                    },
                    {
                        label: "Select Batch",

                        placeholder: "Select Batch",
                        type: "select",
                        options: [
                            {
                                name: "Batch",
                                value: "2022/23",
                                id: "1"
                            },
                            {
                                name: "Batch",
                                value: "2021/22",
                                id: "2"
                            },
                            {
                                name: "Batch",
                                value: "2020/21",
                                id: "3"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Assign Class Teacher",

                        placeholder: "Assign Class Teacher",
                        type: "select",
                        options: [
                            {
                                name: "Class Teacher",
                                value: "Teacher 1",
                                id: "1"
                            },
                            {
                                name: "Class Teacher",
                                value: "Teacher 2",
                                id: "2"
                            },
                            {
                                name: "Class Teacher",
                                value: "Teacher 3",
                                id: "3"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Assign Maths Teacher",

                        placeholder: "Assign Maths Teacher",
                        type: "select",
                        options: [
                            {
                                name: "Maths Teacher",
                                value: " Maths Teacher 1",
                                id: "1"
                            },
                            {
                                name: "Maths Teacher",
                                value: " Maths Teacher 2",
                                id: "2"
                            },
                            {
                                name: "Maths Teacher",
                                value: " Maths Teacher 3",
                                id: "3"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ]
            ]
        }
    ];

    // !Add New Student

    const [addNewStudent, setAddNewStudent] = useState(false);

    function showAddNewStudent() {
        setAddNewStudent(!addNewStudent);
    }

    const addNewStudentFormTemplate = [
        {
            groupName: "Fill the following details to add new student",
            subHead: "Student Details",

            fields: [
                [
                    {
                        label: "First Name",
                        placeholder: "First Name",
                        type: "text",
                        required: true,
                        rules: []
                    },
                    {
                        label: "Last Name",
                        placeholder: "Last Name",
                        type: "text",
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Email ID*",
                        placeholder: "Enter your email id",
                        type: "email",
                        required: true,
                        rules: []
                    },
                    {
                        label: "Phone Number*",
                        placeholder: "Enter your phone number",
                        type: "tel",
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Select Roll Number",

                        placeholder: "Select Roll No.",
                        type: "select",
                        options: [
                            {
                                name: "Roll No",
                                value: "1",
                                id: "1"
                            },
                            {
                                name: "Roll No",
                                value: "2",
                                id: "2"
                            },
                            {
                                name: "Roll No",
                                value: "3",
                                id: "3"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Gender",
                        placeholder: "Gender",
                        type: "radio",
                        options: [
                            {
                                name: "Male",
                                value: "Male",
                                id: "1"
                            },
                            {
                                name: "Female",
                                value: "Female",
                                id: "2"
                            },
                            {
                                name: "others",
                                value: "others",
                                id: "3"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ]
            ]
        }
    ];
    const addNewParentFormTemplate = [
        {
            groupName: "",
            subHead: "Parent/Guardian Details",
            fields: [
                [
                    {
                        label: "First Name",
                        placeholder: "First Name",
                        type: "text",
                        required: true,
                        rules: []
                    },
                    {
                        label: "Last Name",
                        placeholder: "Last Name",
                        type: "text",
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Email ID*",
                        placeholder: "Enter your email id",
                        type: "email",
                        required: true,
                        rules: []
                    },
                    {
                        label: "Phone Number*",
                        placeholder: "Enter your phone number",
                        type: "tel",
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Relationship",
                        placeholder: "Relationship",
                        type: "radio",
                        options: [
                            {
                                name: "Father",
                                value: "Father",
                                id: "1"
                            },
                            {
                                name: "Mother",
                                value: "Mother",
                                id: "2"
                            },
                            {
                                name: "others",
                                value: "others",
                                id: "3"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ]
            ]
        }
    ];

    // !Add New Teacher SideBar

    const [addNewTeacherSidebar, setAddNewTeacherSidebar] = useState(false);

    function showNewTeacherSidebar() {
        setAddNewTeacherSidebar(!addNewTeacherSidebar);
    }
    const addNewTeacherFormTemplate = [
        {
            groupName: "Fill the following details to add new teacher",
            subHead: "",

            fields: [
                [
                    {
                        label: "First Name*",
                        placeholder: "First Name",
                        type: "text",
                        required: true,
                        rules: []
                    },
                    {
                        label: "Last Name*",
                        placeholder: "Last Name",
                        type: "text",
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Email ID*",
                        placeholder: "Enter your email id",
                        type: "email",
                        required: true,
                        rules: []
                    },
                    {
                        label: "Phone Number*",
                        placeholder: "Enter your phone number",
                        type: "tel",
                        required: true,
                        rules: []
                    }
                ],

                [
                    {
                        label: "Gender",
                        placeholder: "Gender",
                        type: "radio",
                        options: [
                            {
                                name: "Male",
                                value: "Male",
                                id: "1"
                            },
                            {
                                name: "Female",
                                value: "Female",
                                id: "2"
                            },
                            {
                                name: "others",
                                value: "others",
                                id: "3"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Teacher Role",
                        placeholder: "Teacher Role",
                        type: "radio",
                        options: [
                            {
                                name: "Class Teacher",
                                value: "Class Teacher",
                                id: "1"
                            },
                            {
                                name: "Maths Teacher",
                                value: "Maths Teacher",
                                id: "2"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Assign Class  ",

                        placeholder: "Assign Class ",
                        type: "select",
                        options: [
                            {
                                name: "A",
                                value: "A",
                                id: "1"
                            },
                            {
                                name: "B",
                                value: "B",
                                id: "2"
                            },
                            {
                                name: "C",
                                value: "C",
                                id: "2"
                            }
                        ],
                        required: true,
                        rules: []
                    },
                    {
                        label: "Assign Section ",

                        placeholder: "Assign Section",
                        type: "select",
                        options: [
                            {
                                name: "1",
                                value: "1",
                                id: "1"
                            },
                            {
                                name: "2",
                                value: "2",
                                id: "2"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ]
            ]
        }
    ];

    const [editDetails2TabsSidebar, setEditDetails2TabsSidebar] =
        useState(false);

    function showEditDetails2TabsSidebar() {
        setEditDetails2TabsSidebar(!editDetails2TabsSidebar);
    }

    const editDetailsClassTeacherFormTemplate = [
        {
            groupName: "",
            fields: [
                [
                    {
                        label: "First Name*",
                        placeholder: "First Name",
                        type: "text",
                        required: true,
                        rules: []
                    },
                    {
                        label: "Last Name*",
                        placeholder: "Last Name",
                        type: "text",
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Email ID*",
                        placeholder: "Enter your email id",
                        type: "email",
                        required: true,
                        rules: []
                    },
                    {
                        label: "Phone Number*",
                        placeholder: "Enter your phone number",
                        type: "tel",
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Gender",
                        placeholder: "Gender",
                        type: "radio",
                        options: [
                            {
                                name: "Male",
                                value: "Male",
                                id: "1"
                            },
                            {
                                name: "Female",
                                value: "Female",
                                id: "2"
                            },
                            {
                                name: "others",
                                value: "others",
                                id: "3"
                            }
                        ],
                        required: true,
                        rules: []
                    },
                    {
                        label: "Teacher Role",

                        placeholder: "Teacher Role",
                        type: "select",
                        options: [
                            {
                                name: "Class Teacher",
                                value: "Class Teacher",
                                id: "1"
                            },
                            {
                                name: "Maths Teacher",
                                value: "Maths Teacher",
                                id: "2"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Select Class  ",

                        placeholder: "Select Class ",
                        type: "select",
                        options: [
                            {
                                name: "1",
                                value: "1",
                                id: "1"
                            },
                            {
                                name: "2",
                                value: "2",
                                id: "2"
                            }
                        ],
                        required: true,
                        rules: []
                    },
                    {
                        label: "Select Section ",

                        placeholder: "Select Section",
                        type: "select",
                        options: [
                            {
                                name: "A",
                                value: "A",
                                id: "1"
                            },
                            {
                                name: "B",
                                value: "B",
                                id: "2"
                            },
                            {
                                name: "C",
                                value: "C",
                                id: "2"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ]
            ]
        }
    ];

    const editDetailsMathsTeacherFormTemplate = [
        {
            groupName: "",
            fields: [
                [
                    {
                        label: "First Name*",
                        placeholder: "First Name",
                        type: "text",
                        required: true,
                        rules: []
                    },
                    {
                        label: "Last Name*",
                        placeholder: "Last Name",
                        type: "text",
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Email ID*",
                        placeholder: "Enter your email id",
                        type: "email",
                        required: true,
                        rules: []
                    },
                    {
                        label: "Phone Number*",
                        placeholder: "Enter your phone number",
                        type: "tel",
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Gender",
                        placeholder: "Gender",
                        type: "radio",
                        options: [
                            {
                                name: "Male",
                                value: "Male",
                                id: "1"
                            },
                            {
                                name: "Female",
                                value: "Female",
                                id: "2"
                            },
                            {
                                name: "others",
                                value: "others",
                                id: "3"
                            }
                        ],
                        required: true,
                        rules: []
                    },
                    {
                        label: "Teacher Role",

                        placeholder: "Teacher Role",
                        type: "select",
                        options: [
                            {
                                name: "Class Teacher",
                                value: "Class Teacher",
                                id: "1"
                            },
                            {
                                name: "Maths Teacher",
                                value: "Maths Teacher",
                                id: "2"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ],
                [
                    {
                        label: "Select Class  ",

                        placeholder: "Select Class ",
                        type: "select",
                        options: [
                            {
                                name: "1",
                                value: "1",
                                id: "1"
                            },
                            {
                                name: "2",
                                value: "2",
                                id: "2"
                            }
                        ],
                        required: true,
                        rules: []
                    },
                    {
                        label: "Select Section ",

                        placeholder: "Select Section",
                        type: "select",
                        options: [
                            {
                                name: "A",
                                value: "A",
                                id: "1"
                            },
                            {
                                name: "B",
                                value: "B",
                                id: "2"
                            },
                            {
                                name: "C",
                                value: "C",
                                id: "2"
                            }
                        ],
                        required: true,
                        rules: []
                    }
                ]
            ]
        }
    ];

    // !===========================================================
    // !=============================================================

    return (
        <div className={styles.UIPreviewContainer}>
            <div>
                {dashBoardData.map((data) => (
                    <DashboardStatsCard dataObj={data} />
                ))}
            </div>
            <DashboardNotification data={notifications} />
            <ClassesBlock listData={classData} />

            <button
                style={{
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    textTransform: "uppercase",
                    borderRadius: "0.3125rem",
                    marginRight: "1rem",
                    transition: "all 0.3s ease-in-out",
                    padding: "0.72rem 1.56rem",
                    marginBottom: "1.5rem",
                    color: "#fff",
                    background:
                        "linear-gradient(312deg, #5a66c8 -1.31%, #896af3 111.54%)",
                    border: "1.5px solid #6c57ef"
                }}
                onClick={showStudentSlideBar}
            >
                SHOW STUDENT SIDEBAR
            </button>

            <button
                style={{
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    textTransform: "uppercase",
                    borderRadius: "0.3125rem",

                    transition: "all 0.3s ease-in-out",
                    padding: "0.72rem 1.56rem",
                    marginBottom: "1.5rem",
                    color: "#fff",
                    background:
                        "linear-gradient(312deg, #5a66c8 -1.31%, #896af3 111.54%)",
                    border: "1.5px solid #6c57ef"
                }}
                onClick={showTeachersSlideBar}
            >
                SHOW TEACHERS SIDEBAR
            </button>

            {showTeachersSlideBar && (
                <AdminSideBar
                    changeStatus={showTeachersSlideBar}
                    status={showTeachersDetails}
                    title={"Teachers Details"}
                    children={
                        <TeachersDetailsSideBar
                            classTeacher={teacherData}
                            mathsTeacher={mathsTeacherData}
                        />
                    }
                />
            )}

            {showStudentDetails && (
                <AdminSideBar
                    changeStatus={showStudentSlideBar}
                    status={showStudentDetails}
                    title={"Student Details"}
                    children={
                        <StudentDetailsSideBar
                            studentData={slideBarStudentData}
                            parentsData={slideBarParentsData}
                        />
                    }
                />
            )}

            {/* {showTeachersDetails && (
                <TeachersDetailsSideBar
                    classTeacher={teacherData}
                    mathsTeacher={mathsTeacherData}
                    status={showTeachersDetails}
                    changeStatus={showTeachersSlideBar}
                />
            )} */}

            <AverageCGPA avgCGPA={avgCGPAData} />

            <TotalEnrollment totalEnrollment={totalEnrollmentData} />

            <StudentsLevel levels={studentsLevelData} />

            <Curriculum curriculum={curriculumData} />

            {statsBlockData.map((statsData) => (
                <StatsBlock stats={statsData} />
            ))}

            <TeachersDetailsCard teachersName={teachersDetailsCardData} />

            <StudentDetailsCard cardData={studentDetailsCardData} />

            <AverageScoreCard scoreCardData={averageScoreCardData} />

            <AverageLevelCard levelCardData={"L3"} />

            {/* !showEditDetails  */}
            <button
                style={{
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    textTransform: "uppercase",
                    borderRadius: "0.3125rem",

                    transition: "all 0.3s ease-in-out",
                    padding: "0.72rem 1.56rem",
                    marginBottom: "1.5rem",
                    color: "#fff",
                    background:
                        "linear-gradient(312deg, #5a66c8 -1.31%, #896af3 111.54%)",
                    border: "1.5px solid #6c57ef"
                }}
                onClick={showEditDetailsSideBar}
            >
                Edit Details
            </button>

            {showEditDetails && (
                <AdminSideBar
                    changeStatus={showEditDetailsSideBar}
                    status={showEditDetails}
                    title={"Edit Details"}
                    children={
                        <>
                            <RenderForm formData={studentFormTemplate} />
                            <RenderForm formData={parentsFormTemplate} />
                            <SubmitAndCancelButton
                                changeStatus={showEditDetailsSideBar}
                                status={showEditDetails}
                            />
                        </>
                    }
                />
            )}

            {/* ! showNewClassSidebar  */}
            <div></div>

            <button
                style={{
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    textTransform: "uppercase",
                    borderRadius: "0.3125rem",

                    transition: "all 0.3s ease-in-out",
                    padding: "0.72rem 1.56rem",
                    marginBottom: "1.5rem",

                    color: "#fff",
                    background:
                        "linear-gradient(312deg, #5a66c8 -1.31%, #896af3 111.54%)",
                    border: "1.5px solid #6c57ef"
                }}
                onClick={showNewClassSidebar}
            >
                Create New Class
            </button>

            {addNewClassSidebar && (
                <AdminSideBar
                    changeStatus={showNewClassSidebar}
                    status={addNewClassSidebar}
                    title={"Create New Class"}
                    children={
                        <>
                            <RenderForm formData={createNewClassTemplate} />

                            <SubmitAndCancelButton
                                changeStatus={showNewClassSidebar}
                                status={addNewClassSidebar}
                            />
                        </>
                    }
                />
            )}

            {/* ===================== */}

            {/* ! showAddNewStudent  */}
            <div></div>

            <button
                style={{
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    textTransform: "uppercase",
                    borderRadius: "0.3125rem",

                    transition: "all 0.3s ease-in-out",
                    padding: "0.72rem 1.56rem",
                    marginBottom: "1.5rem",

                    color: "#fff",
                    background:
                        "linear-gradient(312deg, #5a66c8 -1.31%, #896af3 111.54%)",
                    border: "1.5px solid #6c57ef"
                }}
                onClick={showAddNewStudent}
            >
                Add New Student
            </button>

            {addNewStudent && (
                <AdminSideBar
                    changeStatus={showAddNewStudent}
                    status={addNewStudent}
                    title={"Add New Student"}
                    children={
                        <>
                            <RenderForm formData={addNewStudentFormTemplate} />
                            <RenderForm formData={addNewParentFormTemplate} />

                            <SubmitAndCancelButton
                                changeStatus={showAddNewStudent}
                                status={addNewStudent}
                            />
                        </>
                    }
                />
            )}

            {/* ! showAddNewStudent  */}
            <div></div>

            <button
                style={{
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    textTransform: "uppercase",
                    borderRadius: "0.3125rem",

                    transition: "all 0.3s ease-in-out",
                    padding: "0.72rem 1.56rem",
                    marginBottom: "1.5rem",

                    color: "#fff",
                    background:
                        "linear-gradient(312deg, #5a66c8 -1.31%, #896af3 111.54%)",
                    border: "1.5px solid #6c57ef"
                }}
                onClick={showNewTeacherSidebar}
            >
                Add New Teacher
            </button>

            {addNewTeacherSidebar && (
                <AdminSideBar
                    changeStatus={showNewTeacherSidebar}
                    status={addNewTeacherSidebar}
                    title={"Add New Student"}
                    children={
                        <>
                            <RenderForm formData={addNewTeacherFormTemplate} />

                            <SubmitAndCancelButton
                                changeStatus={showNewTeacherSidebar}
                                status={addNewTeacherSidebar}
                            />
                        </>
                    }
                />
            )}

            <div></div>

            <button
                style={{
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    textTransform: "uppercase",
                    borderRadius: "0.3125rem",

                    transition: "all 0.3s ease-in-out",
                    padding: "0.72rem 1.56rem",
                    marginBottom: "1.5rem",

                    color: "#fff",
                    background:
                        "linear-gradient(312deg, #5a66c8 -1.31%, #896af3 111.54%)",
                    border: "1.5px solid #6c57ef"
                }}
                onClick={showEditDetails2TabsSidebar}
            >
                Edit Details 2 Tabs
            </button>

            {editDetails2TabsSidebar && (
                <AdminSideBar
                    changeStatus={showEditDetails2TabsSidebar}
                    status={editDetails2TabsSidebar}
                    title={"Edit Details"}
                    children={
                        <>
                            <TwoTabsSideBar
                                tab1Label={"Class Teacher Details"}
                                tab2Label={"Maths Teacher Details"}
                                tab1={
                                    <RenderForm
                                        formData={
                                            editDetailsClassTeacherFormTemplate
                                        }
                                    />
                                }
                                tab2={
                                    <RenderForm
                                        formData={
                                            editDetailsMathsTeacherFormTemplate
                                        }
                                    />
                                }
                            />

                            <SubmitAndCancelButton
                                changeStatus={showEditDetails2TabsSidebar}
                                status={editDetails2TabsSidebar}
                            />
                        </>
                    }
                />
            )}

            <AdminTable />

            <TopPerformingSchools />
            <TopTeachers />
        </div>
    );
};

export default UIPreview;
