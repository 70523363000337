import Login from "../common/login/login";
import { Dashboard } from "../modules/dashboard/dashboardPage";
import TopicPdfView from "../modules/learn/components/topicPdfView/topicPdfView";
import { Learn } from "../modules/learn/learnPage";
import ChapterDetails from "../modules/learn/pages/chapterDetails/chapterDetails";
import TopicVideoPage from "../modules/learn/pages/topicVideoPage/topicVideoPage";
import QuestionAndAnswersPage from "../modules/learn/pages/questionAndAnswersPage/questionAndAnswersPage";
import MyWork from "../modules/myWork/myWork";
import MyNotes from "../modules/learn/pages/myNotes/myNotes";
import PatternDetails from "../modules/learn/components/chapterDetails/ChapterDetailspatterns/patternDetails.tsx/patternDetails";
import { SolveMCQ } from "../modules/learn/pages/assignmentPage/solveMcq";
import MyStatsPatternAndQuestion from "../modules/myStats/components/myStatsPatternAndQuestion/myStatsPatternAndQuestion";
import MyStatsChapterAndQuestion from "../modules/myStats/components/myStatsChapterAndQuestion/myStatsChapterAndQuestion";
import ClassListing from "../modules/teacher/myClass/pages/classListingPage/classListingPage";
import MyClassDetails from "../modules/teacher/myClassDetails/myClassDetails";
import MyAssignments from "../modules/myAssignments/myAssignments";
import MyStats from "../modules/myStats/myStats";
import ClassAnalyticsPage from "../modules/teacher/myClass/pages/classAnalyticsPage/classAnalyticsPage";
import ChapterProgressPage from "../modules/teacher/myClass/pages/chapterProgressPage/chapterProgressPage";
import StudentDetails from "../modules/teacher/myClass/pages/studentDetails";
import { SolveAssignmentPage } from "../modules/learn/pages/solveAssignmentPage/solveAssignmentPage";
import DescriptiveQuestionReviewPage from "../modules/teacher/descriptiveQuestionReviewPage/descriptiveQuestionReviewPage";
import AssignmentsListing from "../common/assignmentDescriptiveQuestionList/assignmentDescriptiveQuestionList";
import MCQResult from "../modules/learn/pages/assignmentPage/assignmentResultWrapper/MCQResult";
import UIPreview from "../modules/dashboard/UIPreview";
import MyHomework from "../modules/myAssignments/myHomework";
import TestPage from "../modules/tests/tests";
import Exercises from "../modules/practice/exercises/exercises";
import ExercisesTopics from "../modules/practice/exercises/exercisesTopics";
import Notifications from "../modules/notifications/notifications";
import ReviewCreateHomeworkTest from "../modules/teacher/myClass/pages/createHomeworkTest/reviewCreateHomeworkTest";
import SolveExercisePage from "../modules/learn/pages/solveAssignmentPage/solveExercisePage";
import ExerciseReportPage from "../modules/learn/pages/solveAssignmentPage/exerciseReportCardPage";
import ReviewHomeworkTest from "../modules/teacher/myClass/pages/reviewHomeTestPage/reviewHomeworkTest";
import MentorReviewTestHomeowork from "../modules/teacher/myClass/pages/reviewHomeTestPage/mentorReviewTestHomeowork";
import AdminClasses from "../modules/schoolAdmin/adminClasses/adminClasses";
import AdminHome from "../modules/schoolAdmin/adminHome/adminHome";
import AdminStudents from "../modules/schoolAdmin/adminStudents/adminStudents";
import AdminTeachers from "../modules/schoolAdmin/adminTeachers/adminTeachers";
import SuperAdminHome from "../modules/superAdmin/superAdminHome/superAdminHome";
import SuperAdminSchool from "../modules/superAdmin/superAdminSchools/superAdminSchool";
import SuperAdminReports from "../modules/superAdmin/superAdminReports/superAdminReports";
import SuperAdminNotification from "../modules/superAdmin/superAdminNotifications/superAdminNotification";
import AdminClassDetails from "../modules/schoolAdmin/adminClasses/adminClassDetails";
import AdminReports from "../modules/schoolAdmin/adminReports/adminReports";

export const routeConstants = Object.freeze({
  login: "login",
  dashboard: "dashboard",
  learn: "learn",
  chapterDetails: "chapterDetails",
});
export const routePaths = Object.freeze({
  default: "/",
  login: "/login",
  dashboard: "/home",
  learn: "/learn",
  learnVideoPlayer: "/learn/topic/:topicId",
  learnPdfView: "/learn/pdf",
  chapterDetails: "/learn/chapterDetails/:chapterId",
  assignmentMcq: "/learn/assignmentMcq",
  solveAssignment: "/learn/solveAssignmentPage/:topicId",
  solveAssignmentLevel: "/learn/solveAssignmentPage/:topicId/:level",
  practice: "/practice",
  homework: "/practice/homeworks",
  solveHomework: "/practice/homeworks/solveHomework/:topicId",
  practiceTest: "/practice/practiceTest",
  exercises: "/practice/exercises",
  exercisesReport: "/practice/exercises/exerciseReport/:topicId/:level",
  exercisesTopic: "/practice/exercises/:topicId",
  solveExercise: "/practice/exercises/solveExercise/:topicId",
  solveExerciseLevel: "/practice/exercises/solveExercise/:topicId/:level",
  solveExerciseFromLearn: "/learn/solveExercise/:topicId",
  solveAssignmentFromAssignment: "/assignments/solveAssignmentPage/:topicId",
  solveAssignmentLevelFromAssignment:
    "/assignments/solveAssignmentPage/:topicId/:level",
  solveMCQ: "/learn/solveMCQ/:testId",
  MCQResult: "/learn/MCQResult/:topicId",
  myWork: "/my-work",
  questionAndAnswers: "/learn/qa-hub",
  myNotes: "/learn/myNotes",
  test: "/learn/test",
  patternDetails: "/learn/patternDetails",
  assignmentReview: "/learn/assignmentReview/:topicId",
  patternByTopic: "/learn/patternTopic/:topicId",
  refectory: "/learn/refectory",
  myAssignments: "/assignments",
  myStats: "/my-stats",
  patternStats: "/my-stats/pattern-stats/:statsId",
  chapterStats: "/my-stats/chapter-stats/:statsId",
  mentor: "/mentor",
  mentorMyClass: "/mentor/my-class",
  mentorCreateHomeworkTest: "/mentor/create/:type/:for",
  mentorMyClassDetails: "/mentor/my-class/:classId",
  mentorMyWork: "/mentor/my-work",
  tests: "/tests",
  solveTest: "/tests/solveTest/:topicId",
  notifications: "/notifications",
  help: "/help",
  assignmentQuestionList:
    "/mentor/my-class/:classId/assignmentQuestions/:topicId",
  assignmentQuestionListLevel:
    "/mentor/my-class/:classId/assignmentQuestions/:chapterId/:level",
  descriptiveQuestionReviewPage:
    "/mentor/my-class/:classId/descriptiveQuestionReviewPage",
  reviewHomework: "/mentor/reviewHomework/:studentId/:id",
  reviewHomeworkQuestion: "/mentor/reviewHomework/:studentId/:id/review",
  reviewTest: "/mentor/reviewTest/:studentId/:id",
  reviewTestQuestion: "/mentor/reviewTest/:studentId/:id/review",
  mentorChapterProgress: "/my-class/:classId/chapter-progress/:chapterId",
  classAnalytics: "/my-class/classAnalytics/:classId",
  chapterProgress: "/my-class/chapterProgress/:chapterId",
  studentDetails: "/my-class/:classId/studentDetails/:studentId",
});

export interface IRouteComponents {
  path: string;
  element: JSX.Element;
}

export const authenticationRoutes: IRouteComponents[] = [
  {
    path: routePaths.login,
    element: (
      <>
        <Login />
      </>
    ),
  },
  {
    path: "/",
    element: (
      <>
        <Login />
      </>
    ),
  },
];

export const componentRoutes: IRouteComponents[] = [
  {
    path: routePaths.dashboard,
    element: (
      <div>
        <Dashboard />
      </div>
    ),
  },
  {
    path: routePaths.learn,
    element: (
      <>
        <Learn />
      </>
    ),
  },
  {
    path: routePaths.learnVideoPlayer,
    element: (
      <>
        <TopicVideoPage />
      </>
    ),
  },
  {
    path: routePaths.learnPdfView,
    element: (
      <>
        <TopicPdfView />
      </>
    ),
  },
  {
    path: routePaths.chapterDetails,
    element: (
      <>
        <ChapterDetails />
      </>
    ),
  },
  {
    path: routePaths.questionAndAnswers,
    element: (
      <>
        <QuestionAndAnswersPage />
      </>
    ),
  },
  {
    path: routePaths.myNotes,
    element: (
      <>
        <MyNotes />
      </>
    ),
  },
  {
    path: routePaths.assignmentMcq,
    element: (
      <>
        <TopicVideoPage />
      </>
    ),
  },
  {
    path: routePaths.solveAssignment,
    element: (
      <>
        <SolveAssignmentPage />
      </>
    ),
  },
  {
    path: routePaths.solveAssignmentFromAssignment,
    element: (
      <>
        <SolveAssignmentPage />
      </>
    ),
  },
  {
    path: routePaths.practice,
    element: <>practce</>,
  },
  {
    path: routePaths.homework,
    element: <MyHomework />,
  },
  {
    path: routePaths.solveHomework,
    element: (
      <>
        <SolveAssignmentPage
          type="homework"
          breadcrumbList={[
            {
              label: "Home",
              link: "/home",
              selected: false,
            },
            {
              label: "Practice",
              link: "/practice/homeworks",
              selected: false,
            },
            {
              label: "Homeworks",
              link: "/practice/homeworks",
              selected: false,
            },
          ]}
        />
      </>
    ),
  },
  {
    path: routePaths.practiceTest,
    element: <>Practice Test</>,
  },
  {
    path: routePaths.exercises,
    element: (
      <>
        <Exercises />
      </>
    ),
  },
  {
    path: routePaths.exercisesTopic,
    element: <ExercisesTopics></ExercisesTopics>,
  },
  {
    path: routePaths.exercisesReport,
    element: <ExerciseReportPage />,
  },
  {
    path: routePaths.solveExerciseFromLearn,
    element: (
      <>
        <SolveAssignmentPage
          type={"exercises"}
          breadcrumbList={[
            {
              label: "Home",
              link: "/home",
              selected: false,
            },
            {
              label: "Learn",
              link: "/learn",
              selected: false,
            },
          ]}
        />
      </>
    ),
  },
  {
    path: routePaths.solveExercise,
    element: (
      <>
        <SolveExercisePage
          type={"exercises"}
          breadcrumbList={[
            {
              label: "Home",
              link: "/home",
              selected: false,
            },
            {
              label: "Practice",
              link: "/practice/exercises",
              selected: false,
            },
            {
              label: "Exercises",
              link: "/practice/exercises",
              selected: false,
            },
          ]}
        />
      </>
    ),
  },
  {
    path: routePaths.solveAssignmentLevelFromAssignment,
    element: (
      <>
        <SolveAssignmentPage />
      </>
    ),
  },
  {
    path: routePaths.solveMCQ,
    element: (
      <>
        <SolveMCQ />
      </>
    ),
  },
  {
    path: routePaths.MCQResult,
    element: (
      <>
        <MCQResult />
      </>
    ),
  },

  // my work module
  {
    path: routePaths.myWork,
    element: (
      <>
        <MyWork />
      </>
    ),
  },
  {
    path: routePaths.patternDetails,
    element: (
      <>
        <PatternDetails />
      </>
    ),
  },
  {
    path: routePaths.patternByTopic,
    element: (
      <>
        <TopicVideoPage />
      </>
    ),
  },

  {
    path: routePaths.myAssignments,
    element: (
      <>
        <MyAssignments />
      </>
    ),
  },
  // my stats module
  {
    path: routePaths.myStats,
    element: (
      <>
        <MyStats />
      </>
    ),
  },
  // teachers modules
  {
    path: routePaths.mentorMyClass,
    element: (
      <>
        <ClassListing />
      </>
    ),
  },
  {
    path: routePaths.mentorCreateHomeworkTest,
    element: (
      <>
        <ReviewCreateHomeworkTest />
      </>
    ),
  },
  {
    path: routePaths.mentorMyClassDetails,
    element: (
      <>
        <MyClassDetails />
      </>
    ),
  },
  {
    path: routePaths.mentorChapterProgress,
    element: (
      <>
        <ChapterProgressPage />
      </>
    ),
  },
  {
    path: routePaths.patternStats,
    element: (
      <>
        <MyStatsPatternAndQuestion />
      </>
    ),
  },
  {
    path: routePaths.mentorMyWork,
    element: <>WorkListingPage</>,
  },
  {
    path: routePaths.chapterStats,
    element: (
      <>
        <MyStatsChapterAndQuestion />
      </>
    ),
  },
  {
    path: routePaths.tests,
    element: (
      <>
        <TestPage />
      </>
    ),
  },
  {
    path: routePaths.solveTest,
    element: (
      <>
        <SolveAssignmentPage
          type="test"
          breadcrumbList={[
            {
              label: "Home",
              link: "/home",
              selected: false,
            },
            {
              label: "Tests",
              link: "/tests",
              selected: false,
            },
          ]}
        />
      </>
    ),
  },
  {
    path: routePaths.notifications,
    element: <Notifications />,
  },
  {
    path: routePaths.help,
    element: <>HelpPage</>,
  },
  {
    path: routePaths.classAnalytics,
    element: (
      <>
        <ClassAnalyticsPage />
      </>
    ),
  },
  {
    path: routePaths.chapterProgress,
    element: (
      <>
        <ChapterProgressPage />
      </>
    ),
  },
  {
    path: routePaths.studentDetails,
    element: (
      <>
        <StudentDetails />
      </>
    ),
  },
  // {
  //   path: routePaths.solveAssignmentPage,
  //   element:
  //     <>
  //       <SolveAssignmentPage />
  //     </>
  // },
  {
    path: routePaths.assignmentQuestionList,
    element: (
      <>
        <AssignmentsListing />
      </>
    ),
  },
  {
    path: routePaths.assignmentQuestionListLevel,
    element: (
      <>
        <AssignmentsListing />
      </>
    ),
  },
  {
    path: routePaths.descriptiveQuestionReviewPage,
    element: (
      <>
        <DescriptiveQuestionReviewPage />
      </>
    ),
  },
  {
    path: routePaths.reviewHomework,
    element: (
      <>
        <ReviewHomeworkTest type="Homework" />
      </>
    ),
  },
  {
    path: routePaths.reviewHomeworkQuestion,
    element: (
      <>
        <MentorReviewTestHomeowork type="Homework" />
      </>
    ),
  },
  {
    path: routePaths.reviewTest,
    element: (
      <>
        <ReviewHomeworkTest type="Test" />
      </>
    ),
  },
  {
    path: routePaths.reviewTestQuestion,
    element: (
      <>
        <MentorReviewTestHomeowork type="Test" />
      </>
    ),
  },
  {
    path: routePaths.solveAssignmentLevel,
    element: (
      <>
        <SolveAssignmentPage />
      </>
    ),
  },
  {
    path: routePaths.solveExerciseLevel,
    element: (
      <>
        <SolveExercisePage
          type={"exercises"}
          breadcrumbList={[
            {
              label: "Home",
              link: "/home",
              selected: false,
            },
            {
              label: "Practice",
              link: "/practice/exercises",
              selected: false,
            },
            {
              label: "Exercises",
              link: "/practice/exercises",
              selected: false,
            },
          ]}
        />
      </>
    ),
  },
  {
    path: "/UI",
    element: <UIPreview />,
  },

  {
    path: "/super-admin",
    element: <SuperAdminHome />,
  },
  {
    path: "/super-admin/schools",
    element: <SuperAdminSchool />,
  },
  // {
  //   path: "/super-admin/teachers",
  //   element: <AdminTeachers />,
  // },
  {
    path: "/super-admin/reports",
    element: <AdminReports />,
  },
  {
    path: "/super-admin/notifications",
    element: <Notifications from="super-admin" />,
  },

  {
    path: "/admin",
    element: <AdminHome />,
  },
  {
    path: "/admin/classes",
    element: <AdminClasses />,
  },
  {
    path: "/admin/classes/:school/:class",
    element: <AdminClassDetails />,
  },
  {
    path: "/admin/students",
    element: <AdminStudents />,
  },
  {
    path: "/admin/teacher",
    element: <AdminTeachers />,
  },
  {
    path: "/admin/reports",
    element: <AdminReports />,
  },
  {
    path: "/admin/notifications",
    element: <Notifications from="admin" />,
  },
];
