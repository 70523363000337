import React from "react";

const Circle = ({ className, children }: any) => {
  return (
    <>
      <div className={className}>{children}</div>
    </>
  );
};

export default Circle;
