import React, { useEffect } from "react";
import { constants } from "../../constants/constants";
import questionIcon from "../../assets/images/strongQuestion.svg";
import aiHelpIcon from "../../assets/images/strongAiHelp.svg";
import aiReadingBook from "../../assets/images/aiReadingImg.svg";
import styles from "./chapterProgressCard.module.scss";
import { levelPercentage } from "../../utils/masteryLevel";

const ChapterProgressCard = ({ chapterProgressDetails }: any) => {
  const [masteryLevel, setMasteryLevel] = React.useState("L1");
  const { name, attemptedQuestions, totalQuestions, aiCount, competenceLevel } =
    chapterProgressDetails;
  useEffect(() => {
    if (competenceLevel?.masteryStage) {
      const masteryStage = competenceLevel.masteryStage;
      setMasteryLevel(`Level ${masteryStage}`);
    }
  }, [competenceLevel]);

  return (
    <div className={styles.questionHeadingMainContainer}>
      <div className={styles.container}>
        <div className={styles.chapterHeadingDataContainer}>
          <div className={styles.chapterHeadingTextContainer}>
            {name && (
              <div
                className={styles.chapterHeadingText}
                title={name?.split(".")[1]}
              >
                {`Chapter  ${name.split(" ")[0].split(".")[0]} | ${
                  name.split(".")[1]
                }`}{" "}
              </div>
            )}
          </div>
          <div className={styles.chapterHeadHorizontalLine}></div>
          <div className={styles.chapterDetailsContainer}>
            <div className={styles.chapterHeadMasteryContaner}>
              <div>
                <img src={questionIcon} alt={questionIcon} />
              </div>
              <div>
                <div
                  className={styles.chapterHeadMasteryName}
                >{`${attemptedQuestions}/${totalQuestions}`}</div>
                <div className={styles.chapterHeadMasteryNameLow}>
                  {constants.questionsAttempted}
                </div>
              </div>
            </div>
            <div className={styles.chapterHeadMasteryContaner}>
              <div>
                <img src={aiHelpIcon} alt={aiHelpIcon} />
              </div>
              <div>
                <div className={styles.chapterHeadMasteryName}>{aiCount}</div>
                <div className={styles.chapterHeadMasteryNameLow}>
                  {constants.aiHelpUsed}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.avatarProgressContainer}>
          <div className={styles.questionHeadingProgressContainer}>
            <div className={styles.myStatPatternCardProgressNameContainer}>
              <div className={styles.myStatPatternCardProgressText}>
                {constants.mastery}
              </div>
              <div className={styles.myStatPatternCardProgressText}>
                {masteryLevel}
              </div>
            </div>
            <div className={styles.myStatPatternCardProgressWrap}>
              <div
                className={styles.myStatPatternCardProgressMastery}
                style={{
                  width: levelPercentage(
                    `L${masteryLevel[masteryLevel.length - 1]}`
                  ),
                }}
              ></div>
            </div>
            {/* <div className={styles.myStatPatternCardProgressNameContainer}>
              <div className={styles.myStatPatternCardProgressText}>
                {constants.competency}
              </div>
              <div className={styles.myStatPatternCardProgressText}>
                {getLevelText(competenceLevel?.competencyInPercentage)}
              </div>
            </div> */}
            {/* <div className={styles.myStatPatternCardProgressWrap}>
              <div
                className={styles.myStatPatternCardProgressCompetency}
                style={{
                  width: `${competenceLevel?.competencyInPercentage}%`,
                }}
              ></div>
            </div> */}
          </div>
          <div className={styles.questionHeadingImageContainer}>
            <img src={aiReadingBook} alt={aiHelpIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChapterProgressCard;
