export const adminEditFormTemplate = [
  {
    groupName: "Fill the following details to add new school admin",
    fields: [
      [
        {
          label: "Select Region",
          placeholder: "Select Region",
          type: "select",
          name: "region",
          id: "region",
          options: [
            {
              name: "Raipur",
              value: "raipur",
              id: "1",
            },
            {
              name: "Jaipur",
              value: "jaipur",
              id: "2",
            },
          ],
          required: true,
          rules: [],
        },
        {
          label: "Select School",

          placeholder: "Select School",
          type: "select",
          name: "school",
          id: "school",
          options: [
            {
              name: "St. Xaviers High School 1",
              value: "St. Xaviers High School 1",
              id: "1",
            },
            {
              name: "St. Xaviers High School 2",
              value: "St. Xaviers High School 2",
              id: "2",
            },
            {
              name: "St. Xaviers High School 3",
              value: "St. Xaviers High School 3",
              id: "3",
            },
          ],
          required: true,
          rules: [],
        },
      ],

      [
        {
          label: "First Name",
          placeholder: "First Name",
          type: "text",
          required: true,
          rules: [],
          name: "firstName",
          id: "firstName",
        },

        {
          label: "Last Name",
          placeholder: "Last Name",
          type: "text",
          required: true,
          rules: [],
          name: "lastName",
          id: "lastName",
        },
      ],
      [
        {
          label: "Primary Email ID*",
          placeholder: "Email",
          type: "email",
          required: true,
          rules: [],
          name: "primaryEmail",
          id: "primaryEmail",
        },

        {
          label: "Primary Phone Number*",
          placeholder: "Phone",
          type: "tel",
          required: true,
          rules: [],
          name: "primaryPhone",
          id: "primaryPhone",
        },
      ],
      [
        {
          label: "Alternative Email ID*",
          placeholder: "Email",
          type: "email",
          required: false,
          rules: [],
          name: "alternativeEmail",
          id: "alternativeEmail",
        },

        {
          label: "Alternative Phone Number*",
          placeholder: "Phone",
          type: "tel",
          required: false,
          rules: [],
          name: "alternativePhone",
          id: "alternativePhone",
        },
      ],

      [
        {
          label: "Gender",
          placeholder: "Gender",
          type: "radio",
          name: "gender",
          id: "gender",
          options: [
            {
              name: "Male",
              value: "Male",
              id: "1",
            },
            {
              name: "Female",
              value: "Female",
              id: "2",
            },
            {
              name: "others",
              value: "others",
              id: "3",
            },
          ],
          required: true,
          rules: [],
        },
      ],
    ],
  },
];

export const adminAddFormTemplate = (states, onStateChange, cityList) => [
  {
    groupName: "Fill the following details to add new school admin",
    fields: [
      [
        {
          label: "Select State",
          placeholder: "Select State",
          type: "select",
          name: "state",
          id: "state",
          onChange: onStateChange,
          options: states || [
            {
              name: "Raipur",
              value: "Raipur",
              id: "1",
            },
            {
              name: "Jaipur",
              value: "Jaipur",
              id: "2",
            },
          ],
          required: true,
          rules: [],
        },
        {
          label: "Select City",

          placeholder: "Select City",
          type: "select",
          name: "city",
          id: "city",
          options: cityList || [
            {
              name: "Select a State",
              value: "Select a State",
              id: -1,
            },
          ],
          required: true,
          rules: [],
        },
        {
          label: "School Code",

          placeholder: "School Code",
          type: "text",
          name: "schoolCode",
          id: "schoolCode",

          required: true,
          rules: [],
        },
        {
          label: "School Name",

          placeholder: "School Name",
          type: "text",
          name: "schoolName",
          id: "schoolName",

          required: true,
          rules: [],
        },
      ],

      [
        {
          label: "First Name",
          placeholder: "First Name",
          type: "text",
          required: true,
          rules: [],
          name: "firstName",
          id: "firstName",
        },

        {
          label: "Last Name",
          placeholder: "Last Name",
          type: "text",
          required: true,
          rules: [],
          name: "lastName",
          id: "lastName",
        },
      ],
      [
        {
          label: "Primary Email ID*",
          placeholder: "Email",
          type: "email",
          required: true,
          rules: [],
          name: "primaryEmail",
          id: "primaryEmail",
        },

        {
          label: "Primary Phone Number*",
          placeholder: "Phone",
          type: "tel",
          required: true,
          rules: [],
          name: "primaryPhone",
          id: "primaryPhone",
        },
      ],
      [
        {
          label: "Alternative Email ID*",
          placeholder: "Email",
          type: "email",
          required: true,
          rules: [],
          name: "alternativeEmail",
          id: "alternativeEmail",
        },

        {
          label: "Alternative Phone Number*",
          placeholder: "Phone",
          type: "tel",
          required: true,
          rules: [],
          name: "alternativePhone",
          id: "alternativePhone",
        },
      ],
      [
        {
          label: "Address",
          placeholder: "Address",
          type: "text",
          required: true,
          rules: [],
          name: "address",
          id: "address",
        },

        {
          label: "Pincode",
          placeholder: "Pincode",
          type: "number",
          required: true,
          rules: [],
          name: "pincode",
          id: "pincode",
        },
      ],

      // [
      //   {
      //     label: "Gender",
      //     placeholder: "Gender",
      //     type: "radio",
      //     name: "gender",
      //     id: "gender",
      //     options: [
      //       {
      //         name: "Male",
      //         value: "Male",
      //         id: "1",
      //       },
      //       {
      //         name: "Female",
      //         value: "Female",
      //         id: "2",
      //       },
      //       {
      //         name: "others",
      //         value: "others",
      //         id: "3",
      //       },
      //     ],
      //     required: true,
      //     rules: [],
      //   },
      // ],
    ],
  },
];
