import React from "react";
import styles from "./topPerformingSchools.module.scss";

import schoolAdminImage from "../../../assets/images/profilePic.png";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor:
            "linear-gradient(105deg, #F9FBFD 11.37%, #F8FAFF 87.96%)",
        color: "#4B5468",
        fontFamily: "inherit",
        border: "none",
        fontSize: 18,
        fontWeight: 500
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        backgroundColor:
            "linear-gradient(105deg, #F9FBFD 11.37%, #F8FAFF 87.96%)",
        color: "#4B5468",
        fontFamily: "inherit",
        border: "none"
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
}));

function createData(
    rank?: any,
    schoolName?: any,
    schoolAdmin?: any,
    schoolAdminImage?: any,
    contactNumber?: any,
    contactEmail?: any
) {
    return {
        rank,
        schoolName,
        schoolAdmin,
        schoolAdminImage,
        contactNumber,
        contactEmail
    };
}

const rows = [
    createData(
        "#1",
        "St. Xaviers High School",
        "Chandra Kurhade",
        schoolAdminImage,
        `+91 9876543210`,
        `dummyemail@mail.com`
    ),
    createData(
        "#2",
        "St. Xaviers High School",
        "Chandra Kurhade",
        schoolAdminImage,
        `+91 9876543210`,
        `dummyemail@mail.com`
    ),
    createData(
        "#3",
        "St. Xaviers High School",
        "Chandra Kurhade",
        schoolAdminImage,
        `+91 9876543210`,
        `dummyemail@mail.com`
    )
];

const TopPerformingSchools = () => {
    return (
        <TableContainer
            component={Paper}
            sx={{ maxWidth: 1000 }}
        >
            <h1 className={styles.head}>Top Performing School</h1>

            <Table
                sx={{ minWidth: 700 }}
                aria-label="customized table"
            >
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Rank</StyledTableCell>
                        <StyledTableCell>School Name</StyledTableCell>
                        <StyledTableCell>School Admin</StyledTableCell>
                        <StyledTableCell>Contact Info.</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.rank}>
                            <StyledTableCell
                                component="th"
                                scope="row"
                            >
                                <span
                                    className={`${styles.rank}
                                   
                                   
                                
                                `}
                                >
                                    {row.rank}
                                </span>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                {row.schoolName}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <span className={styles.schoolAdmin}>
                                    <img
                                        src={row.schoolAdminImage}
                                        alt=""
                                    />
                                    {row.schoolAdmin}
                                </span>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <div className={styles.contact}>
                                    <span>{row.contactNumber}</span>
                                    <span>{row.contactEmail}</span>
                                </div>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
            <span className={styles.viewAll}>
                <a href="#">View all</a>
            </span>
        </TableContainer>
    );
};

export default TopPerformingSchools;
