import React, { useContext, useEffect, useState } from "react";
import styles from "./createHomeworkTest.module.scss";
import Breadcrumb from "../../../../../common/breadcrumb/breadcrumb";
import { CloseRounded, Widgets } from "@mui/icons-material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { CircularProgress, Dialog, Switch } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from "../../../../../common/dropdown/dropdown";
import Easy from "../../../../../assets/images/easyStar.svg";
import Medium from "../../../../../assets/images/mediumStart.svg";
import Hard from "../../../../../assets/images/hardStar.svg";
import Mascot from "../../../../../assets/images/mascotVSign.svg";
import { QuestionAndOptionsElement } from "../../../../../common/questionsAndOptions/questionAndOptions";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import { routePaths } from "../../../../../constants/routes";
import { useSelector } from "react-redux";
import CreateHomeworkTestDataSelectionModal from "./createHomeworkTestDataSelectionModal";
import { HTTPMethods } from "../../../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import StatusContext from "../../../../../context/loaderContext";

const ReviewCreateHomeworkTest = () => {
  const params = useParams();
  const navigate = useNavigate();
  const loaderStatus: any = useContext(StatusContext);
  const createType: any = params.type;
  const createFor: any = params.for;
  const titleCaseType = createType[0].toUpperCase() + createType.slice(1);
  const titleCaseFor = createFor[0].toUpperCase() + createFor.slice(1);
  const [createModal, setCreateModal] = useState(false);
  const [createModalState, setCreateModalState] = useState("initial");
  const homeworkTestState = useSelector(
    (state: any) => state.createHomeworkTestReducer
  );
  const [reviewData, setReviewData] = useState(homeworkTestState);
  const [homeworkTestCreated, setHomeworkTestCreated] = useState<any>(false);
  const [testQuestionPaper, setTestQuestionPaper] = useState<any>(false);
  const [testQuestionPaperID, setTestQuestionPaperID] = useState<any>(null);
  const [homeworkID, setHomeworkID] = useState<any>(null);
  const [selectedTestQuestionPaper, setSelectedTestQuestionPaper] =
    useState<any>([]);
  const [testDifficultyLevel, setTestDifficultyLevel] = useState<null | number>(
    -1
  );

  const [studentList, setStudentList] = useState<any>([]);
  const [classHomeworkData, setClassHomeworkData] = useState<any>();
  useEffect(() => {
    setReviewData(homeworkTestState);
  }, [homeworkTestState]);

  const createHomeworkTest = async () => {
    loaderStatus.setState({ show: true });
    let payload: any = {
      url: "generatetestpaper/",
      data: {
        chapter_id: reviewData.chapterName.value,
        section: reviewData.section.value,
        //testpaper_id: 0,
        level: "easy",
        question_count: reviewData.questionCount.value,
      },
      method: HTTPMethods.POST,
      headers: {},
    };

    if (createType === "homework") {
      payload.url = "homework/";
      payload.data = {
        topic_id: reviewData.chapterName.value,
        subtopic_id: reviewData.topicName.value,
        ques_count: reviewData.questionCount.value,
        section: reviewData.section.value,
        ai_support: reviewData.aiSupport,
        user_id: [],
      };
      if (reviewData?.studentId?.value) {
        payload.data.user_id = [reviewData?.studentId?.value];
      }
    }
    try {
      const { data }: any = await makeAxiosRequest(payload);

      if (data.status === "success") {
        if (createType === "homework") {
          if (createFor === "class") {
            const students = data.students_data.map((student: any) => {
              return {
                name: student.student_name,
                id: student.student_id,
              };
            });
            setStudentList(students);
            setClassHomeworkData(data.students_data);
            setHomeworkTestCreated(data.students_data[0]);

            const studentsHomeworkIds = data.students_data.map(
              (student: any) => {
                return student.homework_id;
              }
            );

            setHomeworkID(studentsHomeworkIds);
          } else {
            setHomeworkTestCreated(data);
            setHomeworkID(data.homework_id);
          }
        }
        if (createType === "test") {
          setTestQuestionPaperID(data.testpaper_id);
          setTestQuestionPaper(data.test_paper.otherquestion);
          setTestDifficultyLevel(12);
        }
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const updateQuestionToTest = async (id: any, type: any) => {
    if (!testQuestionPaperID) return;
    loaderStatus.setState({ show: true });
    let payload: any = {
      url: "generatetestpaper/",
      data: {
        testpaper_id: testQuestionPaperID,
        question_id: id,
      },
      method: HTTPMethods.DELETE,
      headers: {},
    };
    if (type === "ADD") {
      payload.method = HTTPMethods.PATCH;
    }
    try {
      const { data }: any = await makeAxiosRequest(payload);

      console.log("DELETED", data);

      if (data.status === "success") {
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      reviewData.chapterName.value &&
      reviewData.section.value &&
      reviewData.questionCount.value
    ) {
      createHomeworkTest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [submissionDate, setSubmissionDate] = useState<any>(null);
  const handleCreateSubmission = async () => {
    const formatedDate = dayjs(submissionDate).format("YYYY-MM-DD HH:mm:ss");
    loaderStatus.setState({ show: true });
    let payload: any = {
      url: "generatetestpaper/",
      data: {
        testpaper_id: testQuestionPaperID,
        submission_date: String(formatedDate),
      },
      method: HTTPMethods.PUT,
      headers: {},
    };
    if (createType === "homework") {
      payload.url = "homework/";

      if (createFor === "class") {
        payload.data = {
          homework_id: homeworkID,
          submission_date: formatedDate,
        };
      } else {
        payload.data = {
          homework_id: [homeworkID],
          submission_date: formatedDate,
        };
      }
    }
    try {
      const { data }: any = await makeAxiosRequest(payload);
      console.log(data);
      setCreateModalState("successfullyCreated");

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const handleTestQuestionUpdate = async (level: any) => {
    // loaderStatus.setState({ show: true });
    // let payload: any = {
    //   url: "generatetestpaper/",
    //   data: {
    //     testpaper_id: testQuestionPaperID,
    //     level: level,
    //     question_count: reviewData.questionCount.value,
    //   },
    //   method: HTTPMethods.POST,
    //   headers: {},
    // };
    // try {
    //   const { data }: any = await makeAxiosRequest(payload);
    //   setTestQuestionPaper(data.test_paper.questions);
    //   loaderStatus.setState({ show: false });
    // } catch (error) {
    //   loaderStatus.setState({ show: false });
    //   console.log(error);
    // }
  };

  return (
    <div
      className={`${styles.myAssignmentsMainContainer} 
  }`}
    >
      <Breadcrumb
        breadcrumbDetails={[
          {
            label: "My Class",
            link: "/mentor/my-class",
            selected: false,
          },
          {
            label: titleCaseType,
            link: `/mentor/create/${createType}`,
            selected: false,
          },
        ]}
      />
      <div className={styles.reveiwCreateWrap}>
        <div className={styles.reveiwCreateDetails}>
          <div className={styles.reveiwCreateDetailsItem}>
            <span>
              <strong>Class:</strong>
            </span>
            <span> {reviewData.class.name}</span>
          </div>
          <div className={styles.reveiwCreateDetailsItem}>
            <span>
              <strong>Section:</strong>{" "}
            </span>
            <span> {reviewData.section.name}</span>
          </div>
          <div
            className={styles.reveiwCreateDetailsItem}
            style={{ gridColumn: "span 2" }}
          >
            <span>
              <strong>Chapter Name: </strong>{" "}
            </span>
            <span>{reviewData.chapterName.name}</span>
          </div>
          <div
            className={styles.reveiwCreateDetailsItem}
            style={{ gridColumn: "span 2" }}
          >
            <span>
              <strong>Topic Name:</strong>
            </span>
            <span> {reviewData.topicName.name}</span>
          </div>
          <div
            className={styles.reveiwCreateDetailsItem}
            style={{ gridColumn: "span 2" }}
          >
            <span>
              <strong>No. of Questions:</strong>
            </span>
            <span>{reviewData.questionCount.name}</span>
          </div>
          {reviewData?.studentId?.name && (
            <div
              className={styles.reveiwCreateDetailsItem}
              style={{ gridColumn: "span 2" }}
            >
              <span>
                <strong>Student Name:</strong>
              </span>
              <span>{reviewData.studentId.name}</span>
            </div>
          )}

          <div className={styles.reveiwCreateDetailsItem}>
            <span>
              <strong>AI Support:</strong>
            </span>
            <Switch checked={reviewData.aiSupport} />
          </div>
        </div>

        {homeworkTestCreated || testQuestionPaper ? (
          <>
            {createFor === "class" && createType === "homework" && (
              <div className={styles.reveiwCreateSelectStudents}>
                <p>Students List</p>
                <Dropdown
                  options={studentList}
                  defaultLabel={"Select A Class"}
                  minWidth={"225px"}
                  overlayWidth={"230px"}
                  id={"selectClass"}
                  selectedValue={(item: string) => console.log("Test2")}
                  setDropdownSelectedValueWithId={(item: any) => {
                    loaderStatus.setState({ show: true });
                    setTimeout(() => {
                      const updateQuestionBasedOnStudent =
                        classHomeworkData.filter(
                          (i: any) => i.student_id === item.id
                        );
                      setHomeworkTestCreated(updateQuestionBasedOnStudent[0]);
                      loaderStatus.setState({ show: false });
                    }, 1000);
                  }}
                />
              </div>
            )}
            {createType === "test" && (
              <div className={styles.reveiwCreateSelectLevel}>
                <div className={styles.reveiwCreateSelectLevelButtons}>
                  <button
                    className={
                      testDifficultyLevel === 12
                        ? styles.reveiwCreateSelectLevelButtonActive
                        : ""
                    }
                    onClick={() => {
                      setTestDifficultyLevel(12);
                    }}
                  >
                    <img src={Easy} alt="" />
                    Easy
                  </button>
                  <button
                    className={
                      testDifficultyLevel === 3
                        ? styles.reveiwCreateSelectLevelButtonActive
                        : ""
                    }
                    onClick={() => {
                      setTestDifficultyLevel(3);
                    }}
                  >
                    <img src={Medium} alt="" />
                    Medium
                  </button>
                  <button
                    className={
                      testDifficultyLevel === 4
                        ? styles.reveiwCreateSelectLevelButtonActive
                        : ""
                    }
                    onClick={() => {
                      setTestDifficultyLevel(4);
                    }}
                  >
                    <img src={Hard} alt="" />
                    Hard
                  </button>
                </div>
              </div>
            )}

            <div className={styles.reveiwCreateQuestionList}>
              {createType === "homework" && (
                <>
                  <div className={styles.reveiwCreateQuestionListHeader}>
                    <p>AI generated questions</p>
                    <div className={styles.reveiwCreateSelectLevelButtons}>
                      <button disabled>
                        <img src={Easy} alt="" />
                        Easy
                      </button>
                      <button disabled>
                        <img src={Medium} alt="" />
                        Medium
                      </button>
                      <button disabled>
                        <img src={Hard} alt="" />
                        Hard
                      </button>
                    </div>
                  </div>
                  <ul>
                    {homeworkTestCreated?.questions_data?.questions?.map(
                      (question: any, ind: any) => {
                        return (
                          <li key={question}>
                            <div className={`${styles.popUpCardItem}`}>
                              <div className={styles.questionAndImg}>
                                <div className={styles.questionNumAndText}>
                                  <div className={styles.questionNum}>{`Q${
                                    ind + 1
                                  }.`}</div>
                                  <div className={styles.questionText}>
                                    <QuestionAndOptionsElement
                                      metaData={question?.question}
                                      size="mcqOption"
                                    />
                                  </div>
                                </div>
                                <div className={styles.questionMarksAndLevel}>
                                  <CheckCircleOutlineRoundedIcon /> Marks
                                  <span>
                                    {question.level === 1 && (
                                      <img src={Easy} alt="" />
                                    )}
                                    {question.level === 2 && (
                                      <img src={Medium} alt="" />
                                    )}
                                    {question.level === 3 && (
                                      <img src={Hard} alt="" />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </>
              )}
              {createType === "test" && (
                <>
                  <p style={{ marginTop: "28px", marginBottom: "20px" }}>
                    Question Paper
                  </p>
                  {testDifficultyLevel ? (
                    <ul>
                      {!!selectedTestQuestionPaper.length &&
                        selectedTestQuestionPaper.map(
                          (question: any, ind: any) => {
                            return (
                              <li key={ind}>
                                <div className={`${styles.popUpCardItem}`}>
                                  <div className={styles.questionAndImg}>
                                    <div className={styles.questionNumAndText}>
                                      <div className={styles.questionNum}>{`Q${
                                        ind + 1
                                      }.`}</div>
                                      <div className={styles.questionText}>
                                        <QuestionAndOptionsElement
                                          metaData={question.question}
                                          size="mcqOption"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className={styles.questionMarksAndLevel}
                                    >
                                      <CheckCircleOutlineRoundedIcon /> Marks
                                      <span>
                                        <img
                                          src={
                                            question.level === 1
                                              ? Easy
                                              : question.level === 2
                                              ? Easy
                                              : question.level === 3
                                              ? Medium
                                              : question.level === 4
                                              ? Hard
                                              : Easy
                                          }
                                          alt=""
                                        />
                                      </span>
                                      {createType === "test" && (
                                        <>
                                          <button
                                            className={styles.ghost}
                                            style={{
                                              padding: "8px 12px",
                                              minWidth: "80px",
                                              fontSize: "12px",
                                              background: "transparent",
                                            }}
                                            onClick={() => {
                                              setSelectedTestQuestionPaper(
                                                (state: any) => {
                                                  // Check if the question is already in the array
                                                  const questionIndex =
                                                    state.findIndex(
                                                      (item: any) =>
                                                        item.question_id ===
                                                        question.question_id
                                                    );

                                                  if (questionIndex === -1) {
                                                    // If the question is not in the array, add it

                                                    return [...state, question];
                                                  } else {
                                                    // If the question is in the array, remove it
                                                    const newState = [...state];
                                                    newState.splice(
                                                      questionIndex,
                                                      1
                                                    );
                                                    return newState;
                                                  }
                                                }
                                              );
                                              updateQuestionToTest(
                                                question.question_id,
                                                "REMOVE"
                                              );
                                            }}
                                          >
                                            Remove
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          }
                        )}
                    </ul>
                  ) : (
                    " Please select the difficulty level to add questions for the test."
                  )}

                  <p style={{ marginBottom: "20px" }}>
                    Add Question{" "}
                    <span>
                      ({selectedTestQuestionPaper.length}/
                      {reviewData.questionCount.value}) selected
                    </span>
                  </p>

                  {testDifficultyLevel ? (
                    <ul>
                      {!!testQuestionPaper.length &&
                        testQuestionPaper
                          .filter((item: any) =>
                            String(testDifficultyLevel)
                              .split("")
                              .includes(String(item.level))
                          )
                          .map((question: any, ind: any) => {
                            return (
                              <li key={ind}>
                                <div className={`${styles.popUpCardItem}`}>
                                  <div className={styles.questionAndImg}>
                                    <div className={styles.questionNumAndText}>
                                      <div className={styles.questionNum}>{`Q${
                                        ind + 1
                                      }.`}</div>
                                      <div className={styles.questionText}>
                                        <QuestionAndOptionsElement
                                          metaData={question.question}
                                          size="mcqOption"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className={styles.questionMarksAndLevel}
                                    >
                                      <CheckCircleOutlineRoundedIcon /> Marks
                                      <span>
                                        <img
                                          src={
                                            question.level === 1
                                              ? Easy
                                              : question.level === 2
                                              ? Easy
                                              : question.level === 3
                                              ? Medium
                                              : question.level === 4
                                              ? Hard
                                              : Easy
                                          }
                                          alt=""
                                        />
                                      </span>
                                      {createType === "test" && (
                                        <>
                                          <button
                                            disabled={
                                              selectedTestQuestionPaper.length >=
                                              reviewData.questionCount.value
                                            }
                                            className={styles.ghost}
                                            style={{
                                              padding: "8px 12px",
                                              minWidth: "80px",
                                              fontSize: "12px",
                                              background: "transparent",
                                            }}
                                            onClick={() => {
                                              const alreadyAdded =
                                                selectedTestQuestionPaper.findIndex(
                                                  (item: any) =>
                                                    item.question_id ===
                                                    question.question_id
                                                );

                                              if (
                                                selectedTestQuestionPaper.length >=
                                                reviewData.questionCount.value
                                              ) {
                                                return;
                                              }

                                              if (alreadyAdded >= 0) return;
                                              setSelectedTestQuestionPaper(
                                                (state: any) => [
                                                  ...state,
                                                  question,
                                                ]
                                              );
                                              updateQuestionToTest(
                                                question.question_id,
                                                "ADD"
                                              );
                                            }}
                                          >
                                            {selectedTestQuestionPaper.findIndex(
                                              (item: any) =>
                                                item.question_id ===
                                                question.question_id
                                            ) >= 0
                                              ? "Added"
                                              : "Add"}
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                      {/* {!testQuestionPaper.filter(
                        (item: any) => item.level === testDifficultyLevel
                      ).length && "No question available"} */}
                    </ul>
                  ) : (
                    " Please select the difficulty level to add questions for the test."
                  )}
                </>
              )}
            </div>
            <div className={styles.reveiwCreateButton}>
              <button
                onClick={() => {
                  setCreateModal(true);
                }}
              >
                Create {titleCaseType}
              </button>
            </div>
          </>
        ) : (
          <div className={styles.reveiwCreateWrapLoader}>
            <CircularProgress />
          </div>
        )}
      </div>
      <Dialog
        open={createModal}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className={styles.createModalWrap}>
          <div className={styles.createSelection}>
            {createModalState === "edit" && (
              <>
                <CreateHomeworkTestDataSelectionModal
                  type={titleCaseType}
                  modalFor={titleCaseFor}
                  callback={() => {
                    setCreateModal(false);
                    setCreateModalState("initial");
                  }}
                />
              </>
            )}
            {(createModalState === "initial" ||
              createModalState === "schedule") && <p>Create {titleCaseType}</p>}

            {createModalState === "initial" && (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en"
              >
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Submission Date"
                    value={submissionDate}
                    disablePast
                    onChange={(newValue) => {
                      setSubmissionDate(newValue);
                    }}
                    sx={{ width: "100%" }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            )}
            {createModalState === "schedule" && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "TimePicker"]}>
                  <DateTimePicker
                    value={submissionDate}
                    onChange={(newValue) => {
                      setSubmissionDate(newValue);
                    }}
                    disablePast
                    views={["year", "month", "day", "hours", "minutes"]}
                  />
                </DemoContainer>
              </LocalizationProvider>
            )}

            {createModalState === "successfullyCreated" && (
              <div className={styles.successfullyCreated}>
                <img src={Mascot} alt="" />
                <p>{titleCaseType} has been successfully assigned.</p>
              </div>
            )}

            <div className={styles.createSelectionDateButton}>
              {createModalState === "initial" && (
                <>
                  <button
                    onClick={() => {
                      setCreateModalState("schedule");
                    }}
                    className={styles.ghost}
                  >
                    Schedule for later
                  </button>
                  <button
                    disabled={!submissionDate}
                    className={styles.primary}
                    onClick={handleCreateSubmission}
                  >
                    Assign now
                  </button>
                </>
              )}
              {createModalState === "schedule" && (
                <>
                  <button
                    className={styles.primary}
                    onClick={handleCreateSubmission}
                  >
                    Schedule
                  </button>
                </>
              )}
              {createModalState === "successfullyCreated" && (
                <>
                  <button
                    className={styles.primary}
                    onClick={() => {
                      navigate(routePaths.mentorMyClass);
                    }}
                  >
                    Back to Home
                  </button>
                </>
              )}
            </div>
          </div>

          <button
            onClick={() => {
              setCreateModal(false);
              setCreateModalState("initial");
              setSubmissionDate(null);
            }}
          >
            <CloseRounded />
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default ReviewCreateHomeworkTest;
