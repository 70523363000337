import { combineReducers, createStore } from "redux";
import loginReducer from "./reducers/loginReducer";
import userReducer from "./reducers/userReducer";
import topicVideoPageContentsReducer from "./reducers/topicVideoPageContentsReducer";
import tabReducer from "./reducers/tabReducer";
import mcqQuestionReducer from "./reducers/mcqQestionsReducer";
import studyPreferenceReducer from "./reducers/studyPreferencesReducer";
import selectedTabReducer from "./reducers/selectedTabReducer";
import solveAssignmentReducer from './reducers/solveAssingmentReducer';
import createHomeworkTestReducer from './reducers/createHomeworkTestReducer';

const reducer = combineReducers({
  loginReducer,
  userReducer,
  topicVideoPageContentsReducer,
  tabReducer,
  mcqQuestionReducer,
  studyPreferenceReducer,
  selectedTabReducer,
  solveAssignmentReducer,
  createHomeworkTestReducer
});

const store = createStore(reducer);

const configureStore = () => {
  return store;
};

export default configureStore;
