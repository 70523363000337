import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import VideoPlayer from "../../../../common/videoPlayerSection/videoPlayer";
import questionCardImg from "../../../../assets/images/questionCardImg.svg";
import classes from "./topicVideoPage.module.scss";
import viewDocIcon from "../../../../assets/images/viewDocIcon.svg";
import mcqsHelperIcon from "../../../../assets/images/mcqsHelperIcon.svg";
import profileIcon from "../../../../assets/images/profileIcon.svg";
import assignmentIcon from "../../../../assets/images/assignmentIcon.svg";
import deleteIcon from "../../../../assets/images/deleteIcon.svg";
import confusedAvatar from "../../../../assets/images/confusedAvatar.svg";
import likeIcon from "../../../../assets/images/likeIcon.svg";
import dislikeIcon from "../../../../assets/images/dislikeIcon.svg";
import { constants } from "../../../../constants/constants";
import Tab from "../../../../common/tab/tab";
import PatternListing from "../../components/patternListing/patternListing";
import SideBar from "../../../../common/sidebar/sidebar";
import { AntSwitch } from "../questionAndAnswersPage/questionAndAnswersPage";
import NewQuestionOverlay from "../../components/newQuestionOverlay/newQuestionOverlay";
import { useNavigate } from "react-router-dom";
import PPTOverlay from "../../../../common/pptOverlay/pptOverlay";
import SolveExerciseButton from "../../../../common/solveExerciseButton/solveExerciseButton";

const TopicVideoPageDescription = ({
  subTopicsListData,
  patternsListTData,
  handlePatternVideoOverlay,
  handleMCQAssignmentOverlays,
  getOverlayDetails,
  getPatternDetailsId,
}: any) => {
  const isSideNavOpen = useSelector((state: any) => state.tabReducer.tabOpen);
  const navigate = useNavigate();
  const [isAskQuestionOverlayOpen, setIsAskQuestionOverlayOpen] =
    useState(false);
  const [indexToViewAllTabsNumber, setIndexToViewAllTabsNumber] = useState(0);
  const newQuestionOverlayHandler = () => {
    setIsAskQuestionOverlayOpen((prev) => !prev);
  };
  const data = [
    {
      question:
        "Can you please explain this closure properties for the question below?",
      questionImg: questionCardImg,
      studentUserDetails: { name: "you", time: "24 Dec 2022, 6:00 pm" },
      facultyDetails: {
        name: "Manoj Kumar (Faculty)",
        time: "26 jan 2022, 6:00 pm",
        comment:
          "The problem can be solved by using Rational Number Concept of closure. You first need to divide the equation in 2 parts and then combine them in the final step",
      },
    },
  ];
  const [isSideNavOpenAssignment, setIsSideNavOpenAssignment] = useState(false);
  const patternsListData = patternsListTData?.patterns
    ? patternsListTData?.patterns?.map((x: any, i: number) => ({
        patternName: `${x?.name}`,
        patternDescription: x?.description,
        patternStrength: `${x?.pattern_mastery
          .charAt(0)
          .toUpperCase()}${x?.pattern_mastery.slice(1)}`,
        level: x?.pattern_level,
        background: "linear-gradient(4.53deg, #9E5BB8 5.46%, #DC95F5 105.81%)",
        id: x?.id,
      }))
    : [];

  const titles = [
    constants.PATTERN,
    // NOT IN MVP
    //  constants.SAMPLE_QUESTIONS,
    // constants.QA
  ];

  const viewAllQuestionsHandler = () => {
    navigate("/learn/qa-hub");
  };

  const contents = [
    <div className={classes.patternContainerWrapper}>
      <div className={classes.videoReportDescriptionAndAvatar360p}>
        <div className={classes.videoReportDescriptionWrapper360p}>
          <div className={classes.videoReportDescriptionText360p}>
            {constants.VIDEO_REPORT_DESCRIPTION_360}
          </div>
          <div className={classes.videoReportDescriptionBtn360p}>Know more</div>
        </div>
        <div className={classes.videoReportDescriptionAvatar360p}>
          <img src={confusedAvatar} alt="confused avatar" />
        </div>
      </div>

      <div className={`${classes.topicListHeader}`}>
        {constants.HERE_ARE_SOME_PATTERNS}
      </div>
      <PatternListing
        getPatternDetailsId={getPatternDetailsId}
        isChapterPatternList={false}
        patternList={patternsListData?.slice(0, 3)}
        handlePatternVideoOverlay={handlePatternVideoOverlay}
        fromVideoPage={true}
        getOverlayDetails={getOverlayDetails}
      />
      {/* NOT MVP TASK */}
      {/* <div className={classes.videoReportIssueContainer}>
        <div className={classes.videoReportDescriptionAndAvatar}>
          <div className={classes.videoReportDescriptionWrapper}>
            <div className={classes.videoReportDescriptionText}>
              You are great at solving Commutative pattern problems. But you
              struggle to solve 50% of problems of Commutative + Addition
              pattern combination. Click here to take a quick assignment that
              will help you solve more problems of this pattern combination.
            </div>
            <div className={classes.videoReportDescriptionBtn}>Know more</div>
          </div>
          <div className={classes.videoReportDescriptionAvatar}>
            <img src={confusedAvatar} alt="confused avatar" />
          </div>
        </div>

        <div className={classes.videoFeedbackContainer}>
          <div className={classes.videoFeedbackIconsWrapper}>
            <div className={classes.videoFeedbackIconAndText}>
              <div className={classes.videoFeedbackIcon}>
                <img src={likeIcon} alt="like" />
              </div>
              <div className={classes.videoFeedbackText}>Like</div>
            </div>
            <div className={classes.videoFeedbackIconAndText}>
              <div className={classes.videoFeedbackIcon}>
                <img src={dislikeIcon} alt="dislike" />
              </div>
              <div className={classes.videoFeedbackText}>Dislike</div>
            </div>
          </div>
          <div className={classes.raiseReport}>
            <div className={classes.raiseReportText}>Facing some problem?</div>
            <div className={classes.raiseReportLink}>Report Issue</div>
          </div>
        </div>
      </div> */}
    </div>,
    "This is the content of Sample questions",
    <>
      <div className={classes.topAskedQuestionsContainer}>
        <div className={classes.topAskedQuestionsHeader}>
          <div className={classes.topAskedQuestionsText}>
            Top Questions Asked
          </div>
          <div className={classes.topAskedQuestionsOptionsContainer}>
            <div className={classes.viewAll} onClick={viewAllQuestionsHandler}>
              {constants.VIEW_ALL}
            </div>
            <div className={classes.askQuestionSection}>
              <div className={classes.switchWrapper}>
                <div className={classes.switchIcon}>
                  <AntSwitch
                    defaultChecked
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </div>
                <div className={classes.switchText}>
                  {constants.SHOW_ONLY_MY_QUESTIONS}
                </div>
              </div>
              <div className={classes.askQuestionBtnWrapper}>
                <button
                  className={classes.askQuestionBtn}
                  onClick={newQuestionOverlayHandler}
                >
                  {constants.ASK_A_QUESTION}
                </button>
              </div>
            </div>
          </div>
        </div>
        {data?.map((item, ind) => (
          <div
            key={`${item.question}_${ind}`}
            className={classes.questionAndAnswersCardContainer}
          >
            <div className={classes.questionHeading}>{item?.question}</div>
            {item.questionImg && (
              <div className={classes.questionImage}>
                <img src={questionCardImg} alt="question image" />
              </div>
            )}

            <div className={classes.profileDetailsSection}>
              <div className={classes.profileAndTimeSection}>
                <div className={classes.profileImgandText}>
                  <div className={classes.profileImg}>
                    <img src={profileIcon} />
                  </div>
                  <div className={classes.profileText}>
                    {item?.studentUserDetails?.name}
                  </div>
                </div>
                <div className={classes.profileTime}>
                  {item?.studentUserDetails?.time}
                </div>
              </div>

              <div className={classes.profileActionsSection}>
                {item?.studentUserDetails?.name === "you" && (
                  <div className={classes.deleteImgAndText}>
                    <div className={classes.deleteImg}>
                      <img src={deleteIcon} alt="delete icon" />
                    </div>
                    <div className={classes.deleteText}>{constants.DELETE}</div>
                  </div>
                )}
                <div className={classes.upVoteSection}>
                  <div className={classes.upVoteText}>{constants.UPVOTE}</div>
                  <div className={classes.likeAndDislikeSection}>
                    <img src={likeIcon} alt="like" />
                    <div className={classes.upVoteCount}>12</div>
                    <img src={dislikeIcon} alt="dis-like" />
                  </div>
                </div>
              </div>
            </div>

            {item?.facultyDetails && (
              <div className={classes.questionAndAnswerCommentsContainer}>
                <div className={classes.commentsCardItem}>
                  <p className={classes.commentText}>
                    <span className={classes.commentTextUserId}>@Hemanth</span>
                    {item?.facultyDetails?.comment}
                  </p>
                  <div className={classes.commentedFacultySection}>
                    <div className={classes.commentedFacultyImgandText}>
                      <div className={classes.commentedFacultyImg}>
                        <img src={profileIcon} />
                      </div>
                      <div className={classes.commentedFacultyText}>
                        {item?.facultyDetails?.name}
                      </div>
                    </div>
                    <div className={classes.commentedFacultyTime}>
                      {item?.facultyDetails?.time}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      {isAskQuestionOverlayOpen && (
        <SideBar
          content={
            <NewQuestionOverlay
              newQuestionOverlayHandler={newQuestionOverlayHandler}
            />
          }
          width="442px"
        />
      )}
    </>,
  ];

  const tabSectionRef: any = useRef(null);
  const urlPathArr = window.location?.pathname.split("/");
  const [topicViewDocument, setTopicViewDocument] = useState(false);

  useEffect(() => {
    const isPatternTopic = urlPathArr.includes("patternTopic");
    if (isPatternTopic && tabSectionRef) {
      tabSectionRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [tabSectionRef, urlPathArr]);

  const handleTopicViewDocument = () => {
    setTopicViewDocument((prev) => !prev);
  };

  return (
    <>
      <div className={classes.videoPlayerSection}>
        {subTopicsListData?.video_url &&
          subTopicsListData?.video_url[0]?.video_name && (
            <div className={classes.videoPlayerWrapper}>
              <VideoPlayer
                videoUrl={
                  (subTopicsListData?.video_url &&
                    subTopicsListData?.video_url[0]?.video_name) ||
                  ""
                }
              />
            </div>
          )}
        <div
          className={
            isSideNavOpen
              ? `${classes.videoSectionExercise}`
              : `${classes.videoSectionExerciseBySideBar}`
          }
        >
          {subTopicsListData?.ppt_url &&
            subTopicsListData?.ppt_url?.document && (
              <div
                className={classes.videoViewDocWrapper}
                onClick={() => handleTopicViewDocument()}
              >
                <div className={classes.videoViewImg}>
                  <img src={viewDocIcon} />
                </div>
                <div className={classes.videoViewDoc}>
                  <div
                    className={`${classes.videoViewDocName} ${classes.inter16}`}
                  >
                    {constants.VIEW_DOCUMENT}
                  </div>
                  <div
                    className={`${classes.videoViewDocDesc} ${classes.inter14}`}
                  >
                    {constants.REFERENCE_PPT_ACCOMPANYING_VIDEO}
                  </div>
                </div>
              </div>
            )}

          {subTopicsListData?.mcqsPresent && (
            <div
              className={classes.videoViewDocWrapper}
              onClick={() =>
                handleMCQAssignmentOverlays("mcq", {
                  name: subTopicsListData?.name,
                  id: subTopicsListData?.id,
                  topicid: subTopicsListData?.topicid,
                  topicSerialNumber: subTopicsListData?.sl_no,
                })
              }
            >
              <div className={classes.videoViewImg}>
                <img src={mcqsHelperIcon} />
              </div>
              <div className={classes.videoViewDoc}>
                <div
                  className={`${classes.videoViewDocName} ${classes.inter16}`}
                >
                  {constants.SOLVE_MCQ}
                </div>
                <div
                  className={`${classes.videoViewDocDesc} ${classes.inter14}`}
                >
                  test your topic understanding
                </div>
              </div>
            </div>
          )}
          <div
            className={classes.videoViewDocWrapper}
            // onClick={() =>
            //   handleMCQAssignmentOverlays("assignment", {
            //     name: subTopicsListData?.name,
            //     id: subTopicsListData?.id,
            //     topicid: subTopicsListData?.topicid,
            //     topicSerialNumber: subTopicsListData?.sl_no,
            //   })
            // }
          >
            <SolveExerciseButton
              status={subTopicsListData.level || "pending"}
              level={subTopicsListData.levle}
              id={subTopicsListData?.id}
            >
              <div className={classes.videoViewImg}>
                <img src={assignmentIcon} />
              </div>
              <div className={classes.videoViewDoc}>
                <div
                  className={`${classes.videoViewDocName} ${classes.inter16}`}
                >
                  {constants.ASSIGNMENT}
                </div>
                <div
                  className={`${classes.videoViewDocDesc} ${classes.inter14}`}
                >
                  <p style={{ whiteSpace: "break-spaces" }}>
                    {constants.SOLVE_THIS_ASSIGNMENT_TO_INCREASE_YOUR_MASTERY}
                  </p>
                </div>
              </div>
            </SolveExerciseButton>
          </div>
        </div>
      </div>

      <div
        className={classes.videoSectionDescription}
        id="videoSectionDescription"
        ref={tabSectionRef}
      >
        <Tab
          indexToViewAllTabsNumber={indexToViewAllTabsNumber}
          setIndexToViewAllTabsNumber={setIndexToViewAllTabsNumber}
          titles={titles}
          contents={contents}
          tabClass="tabStart"
        />
      </div>
      {topicViewDocument && (
        <SideBar
          content={
            <PPTOverlay
              closeOverlay={handleTopicViewDocument}
              pptOverlayData={{
                name: subTopicsListData?.name,
                pptUrl: subTopicsListData?.ppt_url?.document,
              }}
            />
          }
          width="826px"
        />
      )}
    </>
  );
};

export default React.memo(TopicVideoPageDescription);
