import React, { useContext, useEffect, useState } from "react";
import styles from "./superAdminSchool.module.scss";
import styled from "@emotion/styled";
import {
  Alert,
  Box,
  IconButton,
  Paper,
  Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  tableCellClasses,
} from "@mui/material";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import optionButton from "../../../assets/images/optionButton.svg";
import AdminSideBar from "../../../common/admin/adminSideBar/adminSideBar";
import TeachersDetailsSideBar from "../../../common/admin/teachersDetailsSideBar/teachersDetailsSideBar";
import SuperAdminDetailSidebar from "./superAdminDetailSidebar";
import ConfirmationPopup from "../../../common/admin/confirmationPopup/confirmationPopup";
import RenderForm from "../../../common/admin/renderForm/renderForm";
import SubmitAndCancelButton from "../../../common/admin/submitAndCancelButton/submitAndCancelButton";
import { HTTPMethods, makeAxiosRequest } from "../../../utils/httpService";
import StatusContext from "../../../context/loaderContext";
import {
  adminAddFormTemplate,
  adminEditFormTemplate,
} from "./superAdminFormTemplates";

// !popoverIcon

import view from "../../../assets/images/view.svg";
import edit from "../../../assets/images/edit.svg";
import contact from "../../../assets/images/contact.svg";
import inactive from "../../../assets/images/inactive.svg";
import deleteBtn from "../../../assets/images/delete.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "linear-gradient(105deg, #F9FBFD 11.37%, #F8FAFF 87.96%)",
    color: "#4B5468",
    fontFamily: "inherit",
    border: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "linear-gradient(105deg, #F9FBFD 11.37%, #F8FAFF 87.96%)",
    color: "#4B5468",
    fontFamily: "inherit",
    border: "none",
  },
}));

function TablePaginationActions(props: any) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={styles.paginationContainer}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {<FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {<KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {<KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {<LastPageIcon />}
      </IconButton>
    </div>
  );
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
}));
function createData(
  status?: any,
  schoolName?: any,
  schoolAdmin?: any,
  contactInfo?: any,
  teachers?: any,
  students?: any,
  batch?: any,
  id?: any
) {
  return {
    status,
    schoolName,
    schoolAdmin,
    contactInfo,
    teachers,
    students,
    batch,
    id,
  };
}

const SuperAdminTabTable = ({
  selectedState,
  selectedCity,
  stateList,
}: any) => {
  const loaderStatus: any = useContext(StatusContext);
  const [adminList, setAdminList] = useState<any>([]);
  const [activeTableContext, setActiveTableContext] = useState<any>(null);
  const [defaultEditValues, setDefaultEditValues] = useState<any>(null);
  const [adminData, setAdminData] = useState<any>({});
  const [viewAdminDetailSidebar, setViewAdminDetailSidebar] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showEditDetails, setShowhowEditDetails] = useState(false);
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationData, setNotificationData] = useState<any>({
    type: "",
    message: ">",
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //   // Avoid a layout jump when reaching the last page with empty rows.
  //   const emptyRows =
  //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  async function deleteDetails(id?: any, isInternalPopUp?: any) {
    if (!id) {
      !isInternalPopUp && setShowDeletePopup(!showDeletePopup);
      return;
    }

    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: `user/school_admin/${id}/`,
      method: HTTPMethods.DELETE,
      headers: {},
    };
    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);

      setShowNotification(true);
      setNotificationData({
        type: "success",
        message: "Deleted Student Successfully",
      });
      getAdminList();
      setViewAdminDetailSidebar(false);
      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      setShowNotification(true);
      setNotificationData({
        type: "error",
        message: "Error while deleting. Please call support",
      });
      console.log(error);
    }
    !isInternalPopUp && setShowDeletePopup(!showDeletePopup);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setAnchorEl(event.currentTarget);
    setActiveTableContext(id);
    setDefaultEditValues({
      region: "1",
      school: "1",
      firstName: id?.user?.first_name || "",
      lastName: id?.user?.last_name || "",
      primaryEmail: id?.user?.email || "",
      primaryPhone: id?.contact_number || "",
      alternativeEmail: "",
      alternativePhone: "",
      gender: "",
      id: id?.id || null,
    });
    setAdminData({
      name: id?.user?.first_name + " " + id?.user?.last_name || "",
      school: id?.school_name || "",
      email: id?.user?.email || "",
      contact: id?.contact_number || "",
      gender: "Female",
      photo: "",
      region: "Raipur",
      id: id.id,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getAdminList = async () => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: "user/school_admin/",
      method: HTTPMethods.GET,
      headers: {},
    };
    if (selectedState) {
      payload.url = `super-admin/schools_in_state/${selectedState}/`;
    }
    if (selectedCity) {
      payload.url = `super-admin/schools_in_city/${selectedCity}/`;
    }

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        const list = data.map((item: any) => {
          return {
            ...item,
            ...createData(
              "Active",
              item.school_name,
              item.user.first_name,
              item.user.email + " " + item.contact_number,
              "50",
              "3000",
              "2023-24",
              item.id
            ),
          };
        });
        setAdminList(list);
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });

      console.log(error);
    }
  };

  useEffect(() => {
    getAdminList();
  }, [selectedState, selectedCity]);

  const handleCreateSchoolAdmin = async (data: any) => {
    const params = {
      user: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.primaryEmail,
      },
      school_name: data.schoolName,
      address: data.address,
      contact_number: data.primaryPhone,
      code: data.schoolCode,
      pincode: Number(data.pincode),
      city: data.city,
    };
    // console.log(params);
    // return;

    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: "user/school_admin/",
      data: params,
      method: HTTPMethods.POST,
      headers: {},
    };

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        setShowNotification(true);
        setNotificationData({
          type: "success",
          message: "New School Admin is added.",
        });
        setShowAddAdmin(false);
        getAdminList();
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      const errorObject: any = error;

      let errorMessage = "Validation Error:\n";

      Object.keys(errorObject)?.forEach((field) => {
        errorMessage += `${field}: ${errorObject[field]?.join(", ")}\n\n`;
      });

      console.log(errorMessage);
      setShowNotification(true);
      setNotificationData({
        type: "error",
        message: errorMessage || "Something went wrong",
      });
      console.log(error);
    }
  };
  const handleEditSchoolAdmin = async (data: any) => {
    if (!data.id) {
      return;
    }

    const params = {
      user: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.primaryEmail,
      },
      school_name: "Test",
      address: "123 Street",
      contact_number: data.primaryPhone,
      code: "TEST113",
      pincode: 989898,
      city: 1,
    };
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: "user/school_admin/" + data.id + "/",
      data: params,
      method: HTTPMethods.PUT,
      headers: {},
    };

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        setShowNotification(true);
        setNotificationData({
          type: "success",
          message: "New School Admin is added.",
        });
        setShowAddAdmin(false);
        getAdminList();
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      const errorObject: any = error;

      let errorMessage = "Validation Error:\n";

      if (typeof errorObject == "object") {
        Object.keys(errorObject)?.forEach((field) => {
          errorMessage += `${field}: ${errorObject[field]?.join(", ")}\n\n`;
        });
      } else {
        errorMessage = "Something went wrong";
      }

      setShowNotification(true);
      setNotificationData({
        type: "error",
        message: errorMessage || "Something went wrong",
      });
      console.log(error);
    }
  };
  const [cityList, setCityList] = useState<any>([]);
  const getCityList = async (state_id: any) => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: `super-admin/cities_in_state/${state_id}/`,
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        setCityList(
          data.map((city: any) => {
            return {
              name: city.name,
              value: city.name,
              id: city.id,
            };
          })
        );
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });

      console.log(error);
    }
  };
  return (
    <>
      <div className={styles.adminTableWrap}>
        <h2>
          School Admins{" "}
          <button
            onClick={() => {
              setShowAddAdmin(true);
            }}
          >
            Add NEW School Admin
          </button>
        </h2>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="admin table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <span style={{ whiteSpace: "nowrap" }}>Status</span>
                </StyledTableCell>
                <StyledTableCell>
                  <span style={{ whiteSpace: "nowrap" }}>School Name</span>
                </StyledTableCell>
                <StyledTableCell>
                  <span style={{ whiteSpace: "nowrap" }}>School Admin</span>
                </StyledTableCell>
                <StyledTableCell>
                  <span style={{ whiteSpace: "nowrap" }}>Contact Info</span>
                </StyledTableCell>
                <StyledTableCell>
                  <span style={{ whiteSpace: "nowrap" }}>Teachers</span>
                </StyledTableCell>
                <StyledTableCell>
                  <span style={{ whiteSpace: "nowrap" }}>Students</span>
                </StyledTableCell>

                <StyledTableCell>
                  <span style={{ whiteSpace: "nowrap" }}>Batch</span>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? adminList?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : adminList
              ).map((row: any) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    <span
                      className={`${styles.studentStatus}
                                    ${
                                      row.status.toLowerCase() === "active"
                                        ? styles.active
                                        : ""
                                    }
                                    ${
                                      row.status.toLowerCase() === "inactive"
                                        ? styles.inactive
                                        : ""
                                    }
                                    ${
                                      row.status.toLowerCase() === "pending"
                                        ? styles.pending
                                        : ""
                                    }
                                `}
                    >
                      <DotSvg />
                      {row.status}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <span style={{ whiteSpace: "nowrap" }}>
                      {row.schoolName}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.schoolAdmin}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <span>{row.contactInfo}</span>
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.teachers}</StyledTableCell>
                  <StyledTableCell align="left">
                    <span className={styles.teacher}>{row.students}</span>
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    <span className={styles.buttonContainer}>
                      <span style={{ whiteSpace: "nowrap" }}>{row.batch}</span>
                      <span className={styles.optionsButton}></span>
                      <IconButton onClick={(e) => handleClick(e, row)}>
                        <img src={optionButton} alt="" />
                      </IconButton>
                    </span>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              count={adminList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              sx={{
                "& .MuiTablePagination-spacer": {
                  flex: "unset !important",
                },
                "& p": {
                  marginBottom: 0,
                },
              }}
            />
          </Table>
        </TableContainer>
      </div>
      <Popover
        id={open ? "simple-popover" : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={styles.popoverWrap}>
          <button
            onClick={() => {
              setViewAdminDetailSidebar(true);
              handleClose();
            }}
          >
            <img src={view} alt="" />
            View
          </button>
          <button
            onClick={() => {
              setShowhowEditDetails(true);
              handleClose();
            }}
          >
            <img src={edit} alt="" />
            Edit
          </button>
          <button
            onClick={() => {
              setViewAdminDetailSidebar(true);
              handleClose();
            }}
          >
            <img src={contact} alt="" />
            Contact
          </button>
          <button
            onClick={() => {
              deleteDetails();
              handleClose();
            }}
          >
            <img src={deleteBtn} alt="" />
            Delete
          </button>
        </div>
      </Popover>
      <Snackbar
        open={showNotification}
        autoHideDuration={6000}
        onClose={() => {
          setShowNotification(false);
        }}
      >
        <Alert
          onClose={handleClose}
          severity={notificationData.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {notificationData.message}
        </Alert>
      </Snackbar>
      {viewAdminDetailSidebar && (
        <AdminSideBar
          changeStatus={() => {
            setViewAdminDetailSidebar(false);
          }}
          status={true}
          title={"Admin Details"}
          children={
            <SuperAdminDetailSidebar
              viewData={adminData}
              onEdit={() => {
                setShowhowEditDetails(true);
              }}
              onDelete={deleteDetails}
            />
          }
        />
      )}
      {showDeletePopup && (
        <ConfirmationPopup
          title=" Are you sure you want to delete
                                         the Admin details
                                               permanently?"
          confirmText="Yes"
          declineText="No"
          maxWidth="540px"
          onConfirm={() => {
            deleteDetails(activeTableContext?.id);
          }}
          onDecline={() => {
            deleteDetails();
          }}
        />
      )}
      {showEditDetails && (
        <AdminSideBar
          changeStatus={() => {
            setShowhowEditDetails(false);
          }}
          status={true}
          title={"Edit Details"}
          children={
            <>
              <RenderForm
                formData={adminEditFormTemplate}
                defaultValues={defaultEditValues}
                onSubmit={handleEditSchoolAdmin}
                submitControls={
                  <SubmitAndCancelButton
                    onCancel={() => {
                      setShowhowEditDetails(false);
                    }}
                  />
                }
              />
            </>
          }
        />
      )}
      {showAddAdmin && (
        <AdminSideBar
          changeStatus={() => {
            setShowAddAdmin(false);
          }}
          status={true}
          title={"Add New School Admin"}
          children={
            <>
              <RenderForm
                formData={adminAddFormTemplate(
                  stateList.map((state: any) => {
                    return {
                      name: state.name,
                      value: state.name,
                      id: state.id,
                    };
                  }),
                  (e: any) => {
                    getCityList(e.value);
                  },
                  cityList
                )}
                defaultValues={{
                  state: "1",
                  city: "1",
                  schoolCode: "",
                  schoolName: "",
                  firstName: "",
                  lastName: "",
                  primaryEmail: "",
                  primaryPhone: "",
                  alternativeEmail: "",
                  alternativePhone: "",
                  address: "",
                  pincode: "",
                  // gender: ""
                }}
                onSubmit={handleCreateSchoolAdmin}
                submitControls={
                  <SubmitAndCancelButton
                    onCancel={() => {
                      setShowAddAdmin(false);
                    }}
                  />
                }
              />
            </>
          }
        />
      )}
    </>
  );
};

export default SuperAdminTabTable;

const DotSvg = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6.5C1 3.18629 3.68629 0.5 7 0.5C10.3137 0.5 13 3.18629 13 6.5C13 9.81371 10.3137 12.5 7 12.5C3.68629 12.5 1 9.81371 1 6.5Z"
        fill="url(#paint0_linear_2403_18166)"
        stroke="#109289"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2403_18166"
          x1="3.25"
          y1="3.25"
          x2="14"
          y2="9.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="" />
          <stop offset="1" stopColor="" />
        </linearGradient>
      </defs>
    </svg>
  );
};
