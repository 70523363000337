import React, { useEffect, useState } from "react";
import styles from "./myStatsQuestionCard.module.scss";
import difficultyIcon from "../../../../assets/images/statsDifficulty.svg";
import accomplishIcon from "../../../../assets/images/accomplishIconSmall.svg";
import verticalLine from "../../../../assets/images/statVerticalLine.svg";
import questionIcon from "../../../../assets/images/questionsAssignment.svg";
import aiHelp from "../../../../assets/images/aiHelp.svg";
import solvedIcon from "../../../../assets/images/statsSolved.svg";
import topicIcon from "../../../../assets/images/topicIconSmall.svg";
import { constants } from "../../../../constants/constants";
import { QuestionAndOptionsElement } from "../../../../common/questionsAndOptions/questionAndOptions";
import { BasicModal } from "../../../../common/modalWrapper/modalWrapper";
import { convertMathMLToText } from "../../../../utils/convertMathToString";

interface IMyStatsQuestionCardProps {
  data: IQuestion;
  statsCoveredName: string;
  index?: number;
}
interface IQuestion {
  question: string;
  pattens_included: string[];
  AI_help_used_count: string[] | number[];
  Attempted_count: number;
  Capability: number;
  id: number;
  level: number;
  question_id: number;
  question_img: null;
  solved_count: number;
  subtopic_id: number;
  time_taken: number;
  timegiven: number;
  topic_id: number;
  topicNumber?: string | number;
  isDiagram?: boolean;
}
enum levels {
  level1 = "Easy",
  level2 = "Medium",
  level3 = "Hard",
}
const MyStatsQuestionCard = (props: IMyStatsQuestionCardProps) => {
  const {
    index,
    aiHelpCount,
    AttemptedCount,
    Capability,
    id,
    level,
    patternsIncluded,
    question,
    questionImg,
    solvedCount,
    subtopicId,
    time_taken,
    timeGiven,
    topicId,
    isDiagram,
    topicNumber,
  }: any = props.data;
  const [levelValue, setLevelValue] = React.useState("1");
  const [aiHelpCountNum, setAiHelpCountNum] = React.useState<any>(0);
  const [openModal, setOpenModal] = useState(false);
  const getLevel = (level: string) => {
    switch (level.toString()) {
      case "1":
        return levels.level1;
      case "2":
        return levels.level2;
      case "3":
        return levels.level3;
      default:
        return levels.level1;
    }
  };

  const getAiHelpCount = (arr: any) => {
    let count = 0;
    Array.isArray(arr)
      ? arr.forEach((item: any) => {
          if (item.count) {
            count = count + item.count;
          }
        })
      : (count = arr);
    setAiHelpCountNum(count < 10 ? `0${count}` : count);
  };

  useEffect(() => {
    if (level) {
      setLevelValue(level);
    }
    if (aiHelpCount) {
      getAiHelpCount(aiHelpCount);
    }
  }, [level, aiHelpCount, patternsIncluded]);

  const showQuestionImage = () => {
    setOpenModal(true);
  };
  return (
    <div className={styles.statsQuestionCardMainContainer}>
      <div className={styles.statsQuestionContainer}>
        <div className={styles.statsQuestionName}>
          <div style={{ marginRight: "2px" }}> {`Q${index + 1}.`}</div>
          <QuestionAndOptionsElement metaData={question} size="question" />
        </div>
        <div className={styles.statsQuestionDataContainer}>
          {topicNumber && (
            <div className={styles.statsQuestionDifficultyContainer}>
              <div className={styles.statsQuestionDifficulty}>
                <div className={styles.questionItemContainer}>
                  <img src={topicIcon} alt={difficultyIcon} />
                  <div>{`Topics ${topicNumber}`}</div>
                </div>
                <div className={styles.verticalLine}>
                  <img src={verticalLine} alt="vertical-line" />
                </div>
              </div>
            </div>
          )}
          <div className={styles.statsQuestionDifficultyContainer}>
            <div className={styles.statsQuestionDifficulty}>
              <div className={styles.questionItemContainer}>
                <img src={difficultyIcon} alt={difficultyIcon} />
                <div>{getLevel(level)}</div>
              </div>
              <div className={styles.questionItemContainer}>
                <img src={accomplishIcon} alt={accomplishIcon} />
                <div>{Capability}</div>
              </div>
              <div className={styles.verticalLine}>
                <img src={verticalLine} alt="vertical-line" />
              </div>
            </div>
          </div>
          <div className={styles.statsQuestionViewContainer}>
            <div className={styles.statsQuestionAiContainer}>
              <div className={styles.questionItemContainer}>
                <img src={questionIcon} alt={questionIcon} />
                <div>
                  {AttemptedCount && AttemptedCount > 0
                    ? `${AttemptedCount}`
                    : 0}{" "}
                  {constants.timesAttempted}
                </div>
              </div>
              <div className={styles.questionItemContainer}>
                <img src={solvedIcon} alt="" />
                <div>
                  {solvedCount} {constants.timesSolved}
                </div>
              </div>
              <div className={styles.questionItemContainer}>
                <img src={aiHelp} alt="" />
                <div>{aiHelpCountNum} Times AI Help used</div>
              </div>
            </div>
          </div>
          {props.data.question_img && (
            <div
              className={styles.statsQuestionViewDiagramContainer}
              onClick={showQuestionImage}
            >
              <div className={styles.cancelButton}>{constants.viewDiagram}</div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.statsPatternCardHorizontalLine}></div>
      <div className={styles.statsQuestionCardBotttomContainer}>
        <div className={styles.statsQuestionChapterCovered}>
          <div className={styles.coveredHeading}>
            {props.statsCoveredName} {constants.covered} : <span></span>
          </div>
          {patternsIncluded &&
            patternsIncluded.map((item: any, index: number) => (
              <>
                <div className={styles.patternCardCls}>
                  <QuestionAndOptionsElement
                    metaData={item}
                    size="patternDes"
                  />
                </div>
                {index < patternsIncluded.length - 1 &&
                  patternsIncluded.length > 1 &&
                  ", "}
              </>
            ))}
        </div>
        <div className={styles.statsQuestionTimeTaken}>
          <div className={styles.statsQuestionChapterCovered}>
            {constants.timeTaken}
            <span>{time_taken}</span>
          </div>
          <div>
            <img src={verticalLine} alt="vertical-line" />
          </div>
          <div className={styles.statsQuestionChapterCovered}>
            {constants.timeGiven} <span>{timeGiven}</span>
          </div>
        </div>
      </div>
      {openModal && (
        <BasicModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          question={{
            ansimage:
              "https://storage-aieducator.s3.ap-south-1.amazonaws.com/mnist/Articles/P2.jpeg",
            id: `Q${props.index && props?.index}`,
          }}
          index={0}
        />
      )}
    </div>
  );
};

export default MyStatsQuestionCard;
