import { useEffect, useState } from "react";

//Close the Sort Dropdown when clicked outside it
const useOutsideAlerter = (ref: any) => {
  const [showSortOverlay, setShowSortOverlay] = useState(false);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowSortOverlay(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return {
    showSortOverlay,
    setShowSortOverlay,
  };
};

export default useOutsideAlerter;
