
const initialState = {
    topicName: "",
    section: "",
    chapterName: "",
    class: "",
    questionCount: 0,
    aiSupport: false,
};

const createHomeworkTestReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case "UPDATE_CREATE_DATA":
      return {...action.payload};
    default:
      return state;
  }
};
export default createHomeworkTestReducer;
