import { useState } from "react";
import lightningIcon from "../../../../../assets/images/lightningIcon.svg";
import patternCardImage from "../../../../../assets/images/patternCardImage.svg";
import weakIcon from "../../../../../assets/images/weakIcon.svg";
import { QuestionAndOptionsElement } from "../../../../../common/questionsAndOptions/questionAndOptions";
import { constants } from "../../../../../constants/constants";
import isNumber from "../../../../../utils/checkIsNumber";
import styles from "./patternCard.module.scss";
interface IPatternCard {
  patternItem: IPatternItem;
  fromComponent: "carousal" | "patternListing";
  handlePatternVideoOverlay?: any;
  fromVideoPage?: boolean;
  getOverlayDetails?: any;
  isClass?: boolean;
  handle?: any;
}

interface IPatternItem {
  patternDescription: string;
  patternName: string;
  background: string;
  level: string;
  patternStrength: string;
  id: number;
  getOverlayDetails: any;
  handle: any;
  patternColor: boolean;
}

const PatternCard = ({
  patternItem,
  fromComponent,
  handlePatternVideoOverlay,
  fromVideoPage = false,
  getOverlayDetails,
  isClass,
  handle,
}: IPatternCard) => {
  const [patternDetailsId, setPatternDetailsId] = useState(patternItem?.id);
  const backgroundGenerator = (level: string) => {
    switch (level) {
      case "Primary":
        return "linear-gradient(304.25deg, #5A66C8 12.81%, #896AF3 109.93%)";
      case "Secondary":
        return "linear-gradient(4.53deg, #9E5BB8 5.46%, #DC95F5 105.81%)";
      case "Teritary":
        return "linear-gradient(52.48deg, #53909C 17.21%, #87D9BA 78.7%)";
      default:
        return "linear-gradient(304.25deg, #5A66C8 12.81%, #896AF3 109.93%)";
    }
  };

  
  function patternColorGenerator(patternColor: boolean): string {
    if (patternColor === true) {
      return "green";
    } else if (patternColor === false) {
      return "red";
    } else {
      return "white";
    }
  }

  return (
    <div
      className={
        fromComponent === "carousal"
          ? `${styles.patternCard}`
          : `${styles.patternCard} ${styles.patternCardPatternListing}`
      }
      style={{
        maxWidth: fromVideoPage
          ? "356px"
          : fromComponent === "carousal"
          ? "369px"
          : "356px",
      }}
      onClick={() => {
        if (handle) handle();
        if (fromComponent === "patternListing" && handlePatternVideoOverlay) {
          handlePatternVideoOverlay();
          getOverlayDetails(patternItem && patternItem?.id);
        }
      }}
    >
      <div className={styles.patternImageContainer}>
        <img
          src={patternCardImage}
          alt={patternCardImage}
          className={styles.patternImage}
        />
        {patternItem?.level ? (
          <span
            className={styles.level}
            style={{ background: `${backgroundGenerator(patternItem?.level)}` }}
          >
            {patternItem?.level}
          </span>
        ) : (
          <></>
        )}
      </div>

      <div className={styles.patternContent}>
        <div className={styles.patternName}>
          <QuestionAndOptionsElement
            metaData={patternItem?.patternName}
            size="question"
          />
        </div>
        <div className={styles.patternDescription}>
          <QuestionAndOptionsElement
            metaData={patternItem?.patternDescription}
            size="mcqOption"
          />
        </div>
      </div>
      <div className={styles.patternImageContainer1}>
        <span
          className={styles.level1}
          style={{
            backgroundColor: patternColorGenerator(patternItem?.patternColor),
          }}
        >

        </span>
      </div>
      <div className={styles.masteryContainer}>
        <div className={styles.hr}></div>
        {/* If patternItem.patternStrength is a number  */}
        {isNumber(patternItem?.patternStrength) === true ? (
          <div className={styles.masteryIconAndText}>
            {patternItem?.patternStrength === "0" ? (
              <div className={styles.masteryicon}>
                <img src={weakIcon} alt={weakIcon} />
              </div>
            ) : (
              <div className={styles.masteryicon}>
                <img src={lightningIcon} alt={lightningIcon} />
              </div>
            )}
            <div className={styles.masteryText}>
              {`${
                !isClass ? constants.masteryIsText : constants.classMastery
              } ${
                Number(patternItem?.patternStrength) < 4 ? "Weak" : "Strong"
              }`}
            </div>
          </div>
        ) : (
          /* If patternItem.patternStrength is a string   */
          <div className={styles.masteryIconAndText}>
            {patternItem?.patternStrength === "weak" ? (
              <div className={styles.masteryicon}>
                <img src={weakIcon} alt={weakIcon} />
              </div>
            ) : (
              <div className={styles.masteryicon}>
                <img src={lightningIcon} alt={lightningIcon} />
              </div>
            )}
            <div className={styles.masteryText}>
              {`${
                !isClass ? constants.masteryIsText : constants.classMastery
              } ${patternItem?.patternStrength === "weak" ? "Weak" : "Strong"}`}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatternCard;
