const sortStudentProgressData = (response: any) => {
  const sortedData = response.sort(function (result1: any, result2: any) {
    result1 = Number(result1.chapter_name.split(".")[0]);
    result2 = Number(result2.chapter_name.split(".")[0]);
    if (result1 < result2) {
      return -1;
    }
    if (result1 > result2) {
      return 1;
    }
    return 0;
  });
  return sortedData;
};

export default sortStudentProgressData;
