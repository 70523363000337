export const getAnalyticsChartConfig = (numberOfBarPairs: number) => {
  let categoryPercentage = 0;
  let barPercentage = 0;
  //   Based on the number of bar pairs having category and bar Percentage
  switch (numberOfBarPairs) {
    case 1:
      categoryPercentage = 0.05;
      barPercentage = 1.0;
      break;
    case 2:
      categoryPercentage = 0.11;
      barPercentage = 1.0;
      break;
    case 3:
      categoryPercentage = 0.16;
      barPercentage = 1.0;
      break;
    case 4:
      categoryPercentage = 0.2;
      barPercentage = 1.0;
      break;
    default:
      categoryPercentage = 0.05;
      barPercentage = 1.0;
      break;
  }

  const analyticsChartConfig = {
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: "#5A66C8",
        stack: "Stack 0",
        borderRadius: 4,
        categoryPercentage,
        barPercentage,
        maxBarThickness: 20,
      },

      {
        label: "",
        data: [],
        backgroundColor: "#9AAAFF",
        stack: "Stack 1",
        borderRadius: 4,
        categoryPercentage,
        barPercentage,
        maxBarThickness: 20,
      },
    ],
  };
  return analyticsChartConfig;
};
