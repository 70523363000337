import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./renderForm.module.scss";
import BulkUpload from "../bulkUpload/bulkUpload";

const RenderForm = ({
  formData,
  submitControls,
  onSubmit,
  defaultValues,
  hasBulkUpload,
  sampleBulkUploadTemplate,
}: any) => {
  const [formValues, setFormValues] = useState(defaultValues);
  const [file, setFile] = useState<any>([]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (file.length) {
      onSubmit(file, "FILE");
      return;
    }

    onSubmit(formValues);
  };
  return (
    <div style={{ width: "500px" }}>
      <form onSubmit={handleSubmit} noValidate={file.length ? true : false}>
        {formData.map((group: any) => {
          return (
            <div className={styles.formContainer}>
              {!!group.groupName && <h1>{group.groupName}</h1>}

              {!!group.subHead && <h2>{group.subHead}</h2>}

              {group.fields.map((field: any) => {
                return (
                  <div className={styles.fields}>
                    {field.map((column: any) => {
                      if (column.type == "text" || column.type == "number") {
                        return (
                          <div className={`${styles.block} ${styles.text}`}>
                            <FormControl>
                              <FormLabel>{column.label}</FormLabel>
                              <TextField
                                {...column}
                                id={column.label}
                                variant="outlined"
                                label={""}
                                type={column.type}
                                InputLabelProps={{
                                  shrink: false,
                                }}
                                value={formValues[column.name]}
                                onChange={handleInputChange}
                              />
                            </FormControl>
                          </div>
                        );
                      }

                      if (column.type == "tel") {
                        return (
                          <div className={`${styles.block} ${styles.text}`}>
                            <FormControl>
                              <FormLabel>{column.label}</FormLabel>
                              <TextField
                                {...column}
                                id={column.label}
                                variant="outlined"
                                label={""}
                                type={column.type}
                                InputLabelProps={{
                                  shrink: false,
                                }}
                                value={formValues[column.name]}
                                onChange={handleInputChange}
                              />
                            </FormControl>
                          </div>
                        );
                      }

                      if (column.type == "email") {
                        return (
                          <div className={`${styles.block} ${styles.text}`}>
                            <FormControl>
                              <FormLabel>{column.label}</FormLabel>
                              <TextField
                                {...column}
                                id={column.label}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: false,
                                }}
                                label={""}
                                type={column.type}
                                value={formValues[column.name]}
                                onChange={handleInputChange}
                              />
                            </FormControl>
                          </div>
                        );
                      }

                      if (column.type == "radio") {
                        return (
                          <>
                            <div
                              className={
                                [...field].length !== 1
                                  ? `${styles.block} ${styles.radio}`
                                  : `${styles.blockSpan} ${styles.radio}`
                              }
                            >
                              <FormLabel>{column.label}</FormLabel>
                              <FormControl>
                                <RadioGroup
                                  row
                                  {...column}
                                  value={formValues[column.name]}
                                  onChange={handleInputChange}
                                >
                                  {!!column.options.length &&
                                    column.options.map((option: any) => {
                                      return (
                                        <FormControlLabel
                                          value={option.value}
                                          control={
                                            <Radio
                                              className={styles.radioEle}
                                            />
                                          }
                                          label={option.name}
                                        />
                                      );
                                    })}
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </>
                        );
                      }

                      if (column.type == "select") {
                        return (
                          <div
                            // if field is less than 2 then different class
                            className={
                              [...field].length !== 1
                                ? `${styles.block} ${styles.select}`
                                : `${styles.blockSpan} ${styles.select}`
                            }
                          >
                            <FormControl fullWidth>
                              <FormLabel>{column.label}</FormLabel>
                              <Select
                                {...column}
                                label={""}
                                defaultValue=""
                                value={formValues[column.name] || ""}
                                onChange={
                                  column.onChange
                                    ? (e) => {
                                        column.onChange({
                                          name: e.target.name,
                                          value: e.target.value,
                                        });
                                        handleInputChange(e);
                                      }
                                    : handleInputChange
                                }
                              >
                                {/* //{!!column.options?.length ? ( */}

                                {column.options.map((option: any) => {
                                  return (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.value}
                                    </MenuItem>
                                  );
                                })}

                                {/* // ) : (
                                //   <>
                                //     <MenuItem value="">
                                //       <em>None</em>
                                //     </MenuItem>
                                //   </>
                                // )} */}
                              </Select>
                            </FormControl>
                          </div>
                        );
                      }
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}
        {hasBulkUpload && (
          <>
            <BulkUpload
              file={file}
              sampleBulkUploadTemplate={sampleBulkUploadTemplate}
              onUpdateInputFiles={(file: any) => {
                setFile(file);
              }}
            />
          </>
        )}
        {submitControls}
      </form>
    </div>
  );
};

export default RenderForm;
