import React from 'react'
import DescriptiveQuestion from './descriptiveQuestion/descriptiveQuestion';
import { Options } from './options/options';

interface IAssignmentOptions {
    optionType: string;
    question: any;
    setQuestion: any;
    activeQuestionIndex: any;
    setOptionClicked?: any;
    activeAssigmentType?: any;
    saveQuestion?: any;

}
export const AssignmentOptions = (props: IAssignmentOptions
) => {
    const {
        optionType,
        question,
        setQuestion,
        activeQuestionIndex,
        setOptionClicked,
        activeAssigmentType,
        saveQuestion
    } = props;

    return (
        <>
            {
                optionType === 'DESCRIPTIVE' ?
                    <>
                        <DescriptiveQuestion
                            question={question}
                            activeQuestionIndex={activeQuestionIndex}
                            setQuestion={setQuestion}
                        />
                    </>
                    :
                    <>
                        <Options
                            question={question}
                            activeQuestionIndex={activeQuestionIndex}
                            setQuestion={setQuestion}
                            activeAssigmentType={activeAssigmentType}
                            setOptionClicked={setOptionClicked}
                            saveQuestion={saveQuestion}
                        />
                    </>
            }

        </>
    )
}

