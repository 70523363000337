import React, { useRef, useState } from "react";
import { constants } from "../../../../../constants/constants";
import styles from "./chapterDetailsHomework.module.scss";
import submitedByIcon from "../../../../../assets/images/submitedByIcon.svg";
import questionsIcon from "../../../../../assets/images/questionsIcon.svg";
import timerLoadingIcon from "../../../../../assets/images/timerLoadingIcon.svg";
import inReviewIcon from "../../../../../assets/images/inReviewIcon.svg";
import skippedIcon from "../../../../../assets/images/skippedIcon.svg";
import completedIcon from "../../../../../assets/images/completedIcon.svg";
import sideArrowIcon from "../../../../../assets/images/sideArrowIcon.svg";
import resultCalender from "../../../../../assets/images/resultCalender.svg";
import FilterBar from "../../../../../common/filterBar/filterBar";
import starIcon from "../../../../../assets/images/starIcon.svg";
import overdueIcon from "../../../../../assets/images/overdueIcon.svg";
import { CustomPagination } from "../../../../../common/pagination/customPagination";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../../../../constants/routes";
import SearchBar from "../../../../../common/searchBar/searchBar";
import MyHomework from "../../../../myAssignments/myHomework";

const HomeWorkCard = ({
  title,
  icon,
  questions,
  date,
  status,
  homeWorkStatus,
  suggestion,
}: any) => {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.homeWorkCard}>
        <div className={styles.iconTitleDescriptionContainer}>
          <div className={styles.iconStatusContainer}>
            <div className={styles.statusIcon}>
              <img src={icon} className={styles.img} />
            </div>
            <div className={styles.status}>{status}</div>
          </div>

          <div className={styles.titleDescriptionStatusContainer}>
            <div className={styles.titleDescriptionContainer}>
              <div className={styles.homeworkTitle}>{title}</div>
              <div className={styles.questionsDateContainer}>
                {questions && (
                  <div className={styles.questions}>
                    <img src={questionsIcon} />
                    <div className={styles.questionsText}>
                      {questions} {constants.QUESTIONS}
                    </div>
                  </div>
                )}
                {date.includes("Submit") ? (
                  <div className={styles.date}>
                    <img src={submitedByIcon} />
                    <div className={styles.dateText}>{date}</div>
                  </div>
                ) : (
                  <div className={styles.date}>
                    <img src={overdueIcon} />
                    <div className={styles.dateText}>{date}</div>
                  </div>
                )}
              </div>
            </div>

            {status === "In Review" && (
              <div className={styles.inReviewContainer}>
                <div className={styles.resultDateContainer}>
                  <div className={styles.resultDateIcon}>
                    <img src={resultCalender} />
                  </div>
                  <div className={styles.resultDateText}>
                    Result Date 24 Jan ‘23
                  </div>
                </div>
                <div className={styles.homeWorkStatusContainer}>
                  <div
                    className={styles.homeWorkStatusText}
                    onClick={() => {
                      navigate(
                        `${routePaths.solveAssignment}?type=reviewAssignment`
                      );
                    }}
                  >
                    {homeWorkStatus}
                  </div>
                  {/* <div className={styles.homeWorkStatusIcon}>
                    <img src={sideArrowIcon} />
                  </div> */}
                </div>
              </div>
            )}
            {/* {status === "Completed" && suggestion && (
              <div className={styles.suggestionContainer}>
                <div className={styles.suggestion}>
                  {constants.SUGGESTION_TEXT}
                </div>
                <div className={styles.suggestionIcon}>
                  <img src={sittingInAirplane} />
                </div>
              </div>
            )} */}
            {status === "Completed" && (
              <div className={styles.completedContainer}>
                <div className={styles.resultDateContainer}>
                  <div className={styles.resultDateIcon}>
                    <img src={starIcon} />
                  </div>
                  <div className={styles.resultDateText}>17/20 scored</div>
                </div>
                <div className={styles.resultStatusContainer}>
                  <div className={styles.retake}>
                    <div className={styles.homeWorkStatusText}>
                      {homeWorkStatus}
                    </div>
                    <div className={styles.homeWorkStatusIcon}>
                      <img src={sideArrowIcon} />
                    </div>
                  </div>
                  <div className={styles.homeWorkStatusContainer}>
                    <div className={styles.homeWorkStatusText}>
                      {constants.VIEW_RESULT}
                    </div>
                    {/* <div className={styles.homeWorkStatusIcon}>
                      <img src={sideArrowIcon} />
                    </div> */}
                  </div>
                </div>
              </div>
            )}
            {status === "Pending" && (
              <div
                className={`${styles.pendingActionSection} ${
                  homeWorkStatus != "Continue" && styles.start
                }`}
              >
                {/* progress bar */}
                {homeWorkStatus == "Continue" && (
                  <div className={styles.progressSection}>
                    <div className={styles.progressWrap}>
                      <div
                        className={styles.progress}
                        style={{ width: "60%" }}
                      ></div>
                    </div>
                    <div className={styles.progressText}>60%</div>
                  </div>
                )}
                <div className={styles.homeWorkStatusContainer}>
                  <div className={styles.homeWorkStatusText}>
                    {homeWorkStatus}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const ChapterDetailsHomework = () => {
  const [filtersData, setfiltersData] = useState<string[]>([]);
  const [sortData, setSortData] = useState<string>("");
  const homeworkList = [...constants.HOMEWORK_LIST_DUMMYDATA];
  const getStatusIcon = (status: string) => {
    let url = "";
    switch (status) {
      case "Pending":
        url = timerLoadingIcon;
        break;
      case "In Review":
        url = inReviewIcon;
        break;
      case "Skipped":
        url = skippedIcon;
        break;
      case "Completed":
        url = completedIcon;
        break;

      default:
        url = url;
        break;
    }
    return url;
  };
  const sortAscFn = (a: any, b: any) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  };
  const sortDescFn = (a: any, b: any) => {
    if (b.name > a.name) {
      return 1;
    }
    if (b.name < a.name) {
      return -1;
    }
    return 0;
  };
  const initialAllData = filtersData?.every((x) => x === "All");
  const filteredHomeworkData =
    filtersData?.length > 0 && !initialAllData
      ? homeworkList?.filter((item) => filtersData.includes(item.status))
      : homeworkList;

  const sortedHomeworkData =
    sortData !== ""
      ? sortData === "asc"
        ? filteredHomeworkData.sort(sortAscFn)
        : filteredHomeworkData.sort(sortDescFn)
      : filteredHomeworkData;

  // const sortedHomeworkData = sortData
  //   ? sortData === "asc"
  //     ? filteredHomeworkData.sort(sortAscFn)
  //     : filteredHomeworkData.sort(sortDescFn)
  //   : filteredHomeworkData;

  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationEnd, setPaginationEnd] = useState(10);

  const range = 10; //Number of Items displayed per page for Pagination

  const [page, setPage] = useState(1);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    const finalValue = Math.ceil(homeworkList.length / range);
    if (finalValue === value) {
      setPaginationStart(range * (value - 1));
      setPaginationEnd(homeworkList.length);
    } else {
      setPaginationStart(range * (value - 1));
      setPaginationEnd(range * value);
    }
  };
  const detailedViewRef: any = useRef(null);
  return (
    <>
      {/* <div
        className={styles.chapterDetailsHomeworkMainContainer}
        ref={detailedViewRef}
      >
        <div className={styles.homeworkTitleSearchbarContainer}>
          <div className={styles.homeworkTitle}>{constants.HOMEWORK_TITLE}</div>
          <SearchBar />
        </div>

        <div className={styles.separator}></div>

        <FilterBar
          statuses={["All", "Pending", "In Review", "Completed"]}
          selectedStatus={(selectedStatuses: string[]) =>
            setfiltersData(selectedStatuses)
          }
          sortFunc={(setSort: string) => {
            setSortData(setSort);
          }}
          patternsData={[]}
        />

        {sortedHomeworkData?.length &&
          sortedHomeworkData
            ?.slice(paginationStart, paginationEnd)
            .map((item, index) => (
              <HomeWorkCard
                key={index + 1}
                title={item.name}
                status={item.status}
                icon={getStatusIcon(item.status)}
                questions={item.questions}
                date={item.date}
                homeWorkStatus={item.homeWorkStatus}
                suggestion={item.suggestion}
              />
            ))}

        <CustomPagination
          detailedViewRef={detailedViewRef}
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(homeworkList?.length / range)}
        />
      </div> */}
      <MyHomework istabbedPage={true} />
    </>
  );
};

export default ChapterDetailsHomework;
