import React, { useContext, useEffect, useState } from "react";
import styles from "./adminTeachers.module.scss";
import Breadcrumb from "../../../common/breadcrumb/breadcrumb";
import SearchBar from "../../../common/searchBar/searchBar";
import Dropdown from "../../../common/dropdown/dropdown";
import StatusContext from "../../../context/loaderContext";
import { HTTPMethods } from "../../../constants/api_endpoints";
import { makeAxiosRequest } from "../../../utils/httpService";
import AdminTeacherTable from "./adminTeacherTable";

const AdminTeacher = () => {
  const loaderStatus: any = useContext(StatusContext);
  const [studentClasses, setStudentClasses] = useState<any>([
    { name: "", id: "" },
  ]);
  const [selectedClass, setSelectedClass] = useState<any>(null);

  const getStudentClasses = async () => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: "school-admin/class/",
      method: HTTPMethods.GET,
      headers: {},
    };

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data.length) {
        const classList = data.map((item: any) => {
          return { name: String(item.name), id: item.code };
        });
        setStudentClasses(classList);
        setSelectedClass(classList[0].id);
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });

      console.log(error);
    }
  };
  useEffect(() => {
    getStudentClasses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.learnMainContainer}>
      <div className={styles.greetingsSearchBarContainer}>
        <div className={styles.greetings}>
          <Breadcrumb
            breadcrumbDetails={[
              {
                label: "Home",
                link: "/admin",
                selected: false,
              },
              {
                label: "Teacher",
                link: "/admin/teacher",
                selected: true,
              },
            ]}
          />
        </div>
        <SearchBar searchText={""} setSearchText={() => {}} />
      </div>
      <Dropdown
        options={studentClasses}
        defaultLabel={"Select A Class"}
        minWidth={"225px"}
        overlayWidth={"230px"}
        id={"selectClass"}
        selectedValue={(item: string) => console.log("Test2")}
        isMenuItemTypeText={true}
        setDropdownSelectedValueWithId={(item: any) => {
          setSelectedClass(item.id);
        }}
      />
      <div className={styles.adminTableWrap}>
        <AdminTeacherTable selectedClass={selectedClass} />
      </div>
    </div>
  );
};

export default AdminTeacher;
