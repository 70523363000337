import React, { useState, useEffect, useContext } from "react";
import styles from "./solveAssignmentPage.module.scss";
import { makeAxiosRequest } from "aie_common";
import arrowback from "../../../../assets/images/arrowback.svg";
import reportIcon from "../../../../assets/images/reportIcon.svg";
import conffeti from "../../../../assets/images/confetti.svg";
import mascotFlying from "../../../../assets/images/MascotFlying.svg";
import correctSubmit from "../../../../assets/images/correctSubmit.svg";
import wrongSubmit from "../../../../assets/images/wrongSubmit.svg";
import unselected from "../../../../assets/images/unChecked.svg";
import inReview from "../../../../assets/images/pendingIcon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ApiEdpoints, { HTTPMethods } from "../../../../constants/api_endpoints";
import Breadcrumb from "../../../../common/breadcrumb/breadcrumb";
import { QuestionSection } from "../assignmentPage/questionSection/questionSection";
import StatusContext from "../../../../context/loaderContext";
import { routePaths } from "../../../../constants/routes";
import Progressbar from "../../../../common/progressbar/progressbar";
import dayjs from "dayjs";

const ExerciseReportPage = () => {
  const { topicId } = useParams();
  let { level } = useParams() || 1;

  const [assignmentLev, setAssignmentLev] = useState<any>(
    level === undefined ? 1 : level
  );
  useEffect(() => {
    dispatch({
      type: "UPDATE_ASSIGNMENT_LEVEL",
      payload: level === undefined ? 1 : level,
    });
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeAssignmentType = "assignment";
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [reportData, setReportData] = useState<any>(null);

  const [initialApiCall, setInitialApiCall] = useState(true);
  const [initialApiCalls, setInitialApiCalls] = useState(true);
  const loaderStatus: any = useContext(StatusContext);
  const [optionClicked, setOptionClicked] = useState(false);

  const [question, setQuestion] = useState<any>([]);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<any>(0);
  const questionData = useSelector(
    (state: any) => state.solveAssignmentReducer
  );

  useEffect(() => {
    setActiveQuestionIndex(questionData.activeQuestionIndex || 0);
  }, [questionData]);

  const [assignmentList, setAssignmentList] = useState<any>([]);
  //Api call to fetch assignment questions
  const fetchAssignmentQuestions = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url:
        assignmentLev < 2
          ? ApiEdpoints.ASSIGNMENT_MCQ_QUESTION +
            `${topicId}/${activeQuestionIndex}`
          : ApiEdpoints.ASSIGNMENT_MCQ_QUESTION_NEW +
            `${topicId}/${assignmentLev}/${activeQuestionIndex}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      loaderStatus.setState({ show: false });

      const newData = question?.map((item: any, index: any) => {
        if (item?.Questionid === res?.data?.data?.Questionid) {
          const finalAnswer =
            res?.data?.data?.Answers?.filter(
              (each: any) => each?.id === Number(item?.answer_id)
            ) || [];
          return {
            id: index + 1,
            question: res?.data?.data?.Question,
            Answers: res?.data?.data?.Answers,
            isCorrect:
              item.status === "wrong"
                ? false
                : item.status === "correct"
                ? true
                : item.status === "in_review"
                ? false
                : false,
            selectedAnswer: item?.selectedAnswer
              ? item?.selectedAnswer
              : finalAnswer[0]?.ans || "",
            correctAnswer: "",
            visited:
              item.status === "wrong"
                ? "Wrong"
                : item.status === "correct"
                ? "Completed"
                : item.status === "in_review"
                ? "InReview"
                : "",
            score: item.marks_alloted,
            marks_scored: item.marks_scored,
            questionImg: res.data.data.Question_img,
            totalTime: item.totalTime || 0,
            open: false,
            subTopics: res.data.data.subtopic_Name,
            topicNumber: res.data.data.topicNumber,
            Questionid: item.Questionid,
            code: item.answer_id,
            descriptive: res.data.data.descriptive,
            tag: res.data.data.tag,
            uploadedImages: [],
            mcqResult: item.status === "in_review" ? false : true,
            isAnswered:
              item.status === "wrong"
                ? true
                : item.status === "correct"
                ? true
                : item.status === "in_review"
                ? true
                : false,
            student_comment: item.student_comments || "",
            mentor_comments: item.mentor_comments || "",
            subtopicNumber: res.data.data.subtopicNumber,
            topic_name: res.data.data.topic_name,
            solutions: item?.ans_img.length > 0 ? JSON.parse(item.ans_img) : [],
          };
        } else {
          if (item.Questionid && initialApiCalls) {
            setInitialApiCalls(false);
            return {
              id: index + 1,
              question: item.question_text,
              Answers: [],
              isCorrect:
                item.status === "wrong"
                  ? false
                  : item.status === "correct"
                  ? true
                  : item.statuss === "in_review"
                  ? false
                  : false,
              selectedAnswer: "",
              correctAnswer: "",
              visited:
                item.status === "wrong"
                  ? "Wrong"
                  : item.status === "correct"
                  ? "Completed"
                  : item.status === "in_review"
                  ? "InReview"
                  : "",
              score: item.marks_alloted,
              questionImg: res.data.data.Question_img,
              totalTime: item.totalTime || 0,
              open:
                item.answer_id !== null || item?.ans_img.length > 0
                  ? true
                  : false,
              subTopics: res.data.data.subtopic_Name,
              topic_name: res.data.data.topic_name,
              topicNumber: res.data.data.topicNumber,
              Questionid: item.Questionid,
              code: item.answer_id || "",
              descriptive: item.question_type === "descriptive" ? true : false,
              tag: "",
              uploadedImages: [],
              mcqResult: item.status === "in_review" ? false : true,
              isAnswered:
                item.status === "wrong"
                  ? true
                  : item.status === "correct"
                  ? true
                  : item.status === "in_review"
                  ? true
                  : false,
              student_comment: item.student_comments || "",
              mentor_comments: item.mentor_comments || "",
              subtopicNumber: res.data.data.subtopicNumber,
              ...item,
              solutions:
                item?.ans_img.length > 0 ? JSON.parse(item.ans_img) : [],
            };
          } else {
            return {
              ...item,
            };
          }
        }
      });
      setQuestion(newData);
      dispatch({ type: "UPDATE_QUESTION", payload: newData });
      setIsDataUpdated(!true);
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAssignmentList = async () => {
    const payload = {
      url:
        assignmentLev < 2
          ? `assignment_question_list/?topic_id=${topicId}`
          : `assignment_question_list/?chapter_id=${topicId}&level=${assignmentLev}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      if (!res.data.data) return;
      setReportData(res.data.data);
      setAssignmentList(res.data.data.questions);
      let newData = res.data.data.questions?.map((item: any, index: any) => {
        return {
          ...item,
          Answers: [],
          Questionid: item.question_id,
          uploadedImages: [],
          descriptive: item.question_type === "descriptive" ? true : false,
          totalTime: item.timetaken || 0,
        };
      });
      if (questionData?.isRetake) {
        newData = res.data.data.questions?.map((item: any, index: any) => {
          return {
            ...item,
            Answers: [],
            ans_img: [],
            answer_id: "",
            Questionid: item.question_id,
            uploadedImages: [],
            marks_scored: 0,
            mentor_comments: "",
            mentor_image: [],
            status: "",
            student_comments: "",
            descriptive: item.question_type === "descriptive" ? true : false,
            totalTime: item.timetaken || 0,
          };
        });
      }

      setQuestion(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRetake = () => {
    dispatch({ type: "ENABLE_RETAKE", payload: true });
    dispatch({ type: "UPDATE_QUESTION_INDEX", payload: 0 });
    let path = routePaths.solveExercise;
    navigate(path.replace(":topicId", `${topicId}`));
  };

  useEffect(() => {
    if (initialApiCall) {
      fetchAssignmentList();
      setInitialApiCall(false);
    } else if (question && question[activeQuestionIndex]?.descriptive) {
      if (
        question[activeQuestionIndex]?.uploadedImages.length < 1 &&
        question[activeQuestionIndex]?.student_comment !== ""
      ) {
        fetchAssignmentQuestions();
      }
    } else if (question && question[activeQuestionIndex]?.Answers.length < 1) {
      fetchAssignmentQuestions();
    }
  }, [activeQuestionIndex, assignmentList]);

  function formatTime(seconds: any) {
    if (seconds < 60) {
      return `${seconds} sec${seconds !== 1 ? "(s)" : ""}`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      if (remainingSeconds === 0) {
        return `${minutes} min${minutes !== 1 ? "(s)" : ""}`;
      } else {
        return `${minutes} min${
          minutes !== 1 ? "s" : ""
        } and ${remainingSeconds} sec${remainingSeconds !== 1 ? "(s)" : ""}`;
      }
    } else {
      const hours = Math.floor(seconds / 3600);
      const remainingMinutes = Math.floor((seconds % 3600) / 60);
      let formattedTime = `${hours} hour${hours !== 1 ? "(s)" : ""}`;
      if (remainingMinutes > 0) {
        formattedTime += ` and ${remainingMinutes} min${
          remainingMinutes !== 1 ? "(s)" : ""
        }`;
      }
      return formattedTime;
    }
  }

  return (
    <section className={styles.assignmentWrapper}>
      {question && question.length != 0 ? (
        <>
          <div className={styles.headingStyle}>
            <div onClick={() => navigate(-1)}>
              <img className={styles.arrowStyle} alt="arrow" src={arrowback} />
            </div>

            <Breadcrumb
              breadcrumbDetails={[
                {
                  label: "Home",
                  link: "/home",
                  selected: false,
                },
                {
                  label: "Practice",
                  link: "/practice/exercises",
                  selected: false,
                },
                {
                  label: "Exercises",
                  link: "/practice/exercises",
                  selected: false,
                },
                {
                  label: "Report Card",
                  link: `/practice/exercises/exerciseReport/${topicId}`,
                  selected: false,
                },
              ]}
            />
          </div>

          <div className={styles.assignmentPageContainer}>
            <div className={styles.reportCardHeading}>
              <img src={reportIcon} alt="" /> Report Card
            </div>
            <div className={styles.reportCardContainer}>
              <div className={styles.reportCardAttempt}>
                <p>Attempt {reportData?.total_attempts}</p>
                <div>
                  <p>
                    <span>
                      {dayjs(reportData?.last_attempted_date).format(
                        "DD MMM YYYY"
                      )}
                    </span>
                    <span> Attempt Date</span>
                  </p>
                  <p>
                    <span>{formatTime(reportData?.total_time_taken)}</span>
                    <span>Time Taken</span>
                  </p>
                </div>
                <div className={styles.reportCardSummary}>
                  <p>
                    <img src={correctSubmit} alt="" />
                    {reportData?.total_correct}
                    <span> Correct</span>
                  </p>
                  <p>
                    <img src={wrongSubmit} alt="" />
                    {reportData?.total_wrong}
                    <span> Incorrect</span>
                  </p>
                  <p>
                    <img src={inReview} alt="" />
                    {reportData?.total_in_review}
                    <span> In Review</span>
                  </p>
                  <p>
                    <img src={unselected} alt="" />
                    {reportData?.total_not_attempted}
                    <span> N/A</span>
                  </p>
                </div>
                <div className={styles.questionDivisionGraph}>
                  <div
                    className={styles.correct}
                    style={{
                      width: `${
                        (reportData?.total_correct /
                          reportData?.total_questions) *
                        100
                      }%`,
                    }}
                  ></div>

                  <div
                    className={styles.incorrect}
                    style={{
                      width: `${
                        (reportData?.total_correct /
                          reportData?.total_questions) *
                          100 +
                        (reportData?.total_wrong /
                          reportData?.total_questions) *
                          100
                      }%`,
                    }}
                  ></div>
                  <div
                    className={styles.inReview}
                    style={{
                      width: `${
                        (reportData?.total_correct /
                          reportData?.total_questions) *
                          100 +
                        (reportData?.total_wrong /
                          reportData?.total_questions) *
                          100 +
                        (reportData?.total_in_review /
                          reportData?.total_questions) *
                          100
                      }%`,
                    }}
                  ></div>
                  <div
                    className={styles.notAnswered}
                    style={{
                      width: `${100}%`,
                    }}
                  ></div>
                </div>
              </div>
              <div className={styles.reportCardMarks}>
                <div>
                  <img src={conffeti} alt="" />
                  <p>
                    <strong>{reportData?.total_marks_scored}/</strong>
                    {reportData?.total_marks_alloted}
                    <span>Marks scored</span>
                  </p>
                </div>
                <h3>Intermediate</h3>
                <span>
                  You are one step closer to becoming a Math Genius! 🎉
                </span>
              </div>
              <div className={styles.reportCardLevel}>
                <div>
                  <h2>Levels</h2>
                  <img src={mascotFlying} alt="" />
                </div>
                <p>Topic Level</p>
                <Progressbar
                  progress={reportData?.student_progress}
                  noOflevels={5}
                  levelsVisible={false}
                  width="90%"
                  enableProgressBGColor={true}
                />
              </div>
            </div>

            <QuestionSection
              question={question}
              setQuestion={setQuestion}
              activeQuestionIndex={activeQuestionIndex}
              setActiveQuestionIndex={setActiveQuestionIndex}
              saveQuestion={() => {}}
              activeAssignmentType={activeAssignmentType}
              activeScreen={"exerciseReport"}
              setActiveScreen={() => {}}
              setOptionClicked={setOptionClicked}
              fromExercisePage={true}
            />
            <div className={styles.reportCardNavigation}>
              <button className={styles.secondary} onClick={handleRetake}>
                retake exercise
              </button>
              {(reportData?.next_chapter_id || reportData?.next_topic_id) && (
                <>
                  <button
                    className={styles.primary}
                    onClick={() => {
                      if (reportData?.next_chapter_id) {
                        navigate(
                          routePaths.chapterDetails.replace(
                            ":chapterId",
                            reportData.next_chapter_id
                          )
                        );
                        return;
                      }
                      if (reportData?.next_topic_id) {
                        navigate(
                          routePaths.learnVideoPlayer.replace(
                            ":topicId",
                            reportData.next_topic_id
                          )
                        );
                      }
                    }}
                  >
                    {reportData?.next_chapter_id && "Go to next Chapter"}
                    {reportData?.next_topic_id && "Go to next Topic"}
                  </button>
                </>
              )}
            </div>

            {/* <div className={styles.reportCardConcepts}>
              <p> Concepts you need to focus on</p>

              <div>
                <div>
                  <img src={mascotFlying} alt="" />
                  <p>Commutative</p>
                  <span>
                    Under subtraction, addition, division & multiplication.
                  </span>
                </div>
                <div>
                  <img src={mascotFlying} alt="" />
                  <p>Real Numbers</p>
                  <span>
                    Under subtraction, addition, division & multiplication.
                  </span>
                </div>
              </div>
            </div> */}
          </div>
        </>
      ) : (
        <div className={styles.noDataFound}>
          No Questions Data in this assignment.
        </div>
      )}
    </section>
  );
};

export default ExerciseReportPage;
