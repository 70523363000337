import React, { useContext, useEffect, useState } from "react";
import styles from "./adminTeachers.module.scss";
import styled from "@emotion/styled";
import {
  Alert,
  Box,
  IconButton,
  Paper,
  Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  tableCellClasses,
} from "@mui/material";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import optionButton from "../../../assets/images/optionButton.svg";
import AdminSideBar from "../../../common/admin/adminSideBar/adminSideBar";
//import SuperAdminDetailSidebar from "./superAdminDetailSidebar";
import ConfirmationPopup from "../../../common/admin/confirmationPopup/confirmationPopup";
import RenderForm from "../../../common/admin/renderForm/renderForm";
import SubmitAndCancelButton from "../../../common/admin/submitAndCancelButton/submitAndCancelButton";
import { HTTPMethods, makeAxiosRequest } from "../../../utils/httpService";
import StatusContext from "../../../context/loaderContext";
import StudentDetailsSideBar from "../../../common/admin/studentDetailsSideBar/studentDetailsSideBar";
import {
  addTeacherFormTemplate,
  adminStudentEditFormTemplate,
  editMathTeacherFormTemplate,
  editTeacherFormTemplate,
} from "./adminFormTemplates";
import TeachersDetailsSideBar from "../../../common/admin/teachersDetailsSideBar/teachersDetailsSideBar";

// !popoverIcon

import view from "../../../assets/images/view.svg";
import edit from "../../../assets/images/edit.svg";
import contact from "../../../assets/images/contact.svg";
import inactive from "../../../assets/images/inactive.svg";
import deleteBtn from "../../../assets/images/delete.svg";
import { useSelector } from "react-redux";

// import {
//   adminAddFormTemplate,
//   adminEditFormTemplate,
// } from "./superAdminFormTemplates";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "linear-gradient(105deg, #F9FBFD 11.37%, #F8FAFF 87.96%)",
    color: "#4B5468",
    fontFamily: "inherit",
    border: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "linear-gradient(105deg, #F9FBFD 11.37%, #F8FAFF 87.96%)",
    color: "#4B5468",
    fontFamily: "inherit",
    border: "none",
  },
}));

function TablePaginationActions(props: any) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={styles.paginationContainer}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {<FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {<KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {<KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {<LastPageIcon />}
      </IconButton>
    </div>
  );
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
}));
function createData(
  status?: any,
  classInfo?: any,
  section?: any,
  classTeacher?: any,
  students?: any,
  mathTeacher?: any,
  batch?: any,
  id?: any
) {
  return {
    status,
    classInfo,
    section,
    classTeacher,
    students,
    mathTeacher,
    batch,
    id,
  };
}

const AdminTeacherTable = ({ selectedClass }: any) => {
  const loaderStatus: any = useContext(StatusContext);
  const [adminList, setAdminList] = useState<any>([]);
  const [activeTableContext, setActiveTableContext] = useState<any>(null);
  const [defaultTeacherEditValues, setDefaultTeacherEditValues] =
    useState<any>(null);
  const [defaultMathTeacherEditValues, setDefaultMathTeacherEditValues] =
    useState<any>(null);
  const [adminData, setAdminData] = useState<any>({});
  const [viewAdminDetailSidebar, setViewAdminDetailSidebar] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showEditTeacherDetails, setShowhowEditTeacherDetails] =
    useState(false);
  const [showEditMathTeacherDetails, setShowhowEditMathTeacherDetails] =
    useState(false);
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationData, setNotificationData] = useState<any>({
    type: "",
    message: ">",
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //   // Avoid a layout jump when reaching the last page with empty rows.
  //   const emptyRows =
  //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function deleteDetails(id?: any, isInternalPopUp?: any) {
    if (!id) {
      !isInternalPopUp && setShowDeletePopup(!showDeletePopup);
      return;
    }

    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: `user/teacher/${id}/`,
      method: HTTPMethods.DELETE,
      headers: {},
    };
    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        setShowNotification(true);
        setNotificationData({
          type: "success",
          message: "Deleted Student Successfully",
        });
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      setShowNotification(true);
      setNotificationData({
        type: "error",
        message: "Error while deleting. Please call support",
      });
      console.log(error);
    }
    !isInternalPopUp && setShowDeletePopup(!showDeletePopup);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setAnchorEl(event.currentTarget);
    setActiveTableContext(id);
    setDefaultTeacherEditValues({
      firstName: id?.user?.first_name || "",
      lastName: id?.user?.last_name || "",
      gender: id?.gender,
      //teacherRole: id?.teacher_role,
      class: id?.class_info,
      section: id?.section,

      primaryEmail: id?.user.email || "",
      primaryPhone: id?.contact_number || "",
      school: id?.school,
      id: id?.id || null,
    });
    setDefaultMathTeacherEditValues({
      mfirstName: id?.user?.first_name || "",
      mlastName: id?.user?.last_name || "",
      mgender: id?.gender,
      mteacherRole: id?.math_teacher_role,
      mclass: id?.class_info,
      msection: id?.section,
      mprimaryEmail: id?.parent_email || "",
      mprimaryPhone: id?.parent_contact_number || "",
      school: id?.school,
      id: id?.id || null,
    });
    setAdminData({
      name: id?.user?.first_name + " " + id?.user?.last_name || "",
      school: id?.school_name || "",
      email: id?.user?.email || "",
      contact: id?.contact_number || "",
      gender: "Female",
      photo: "",
      region: "Raipur",
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    //setActiveTableContext(null);
  };

  const open = Boolean(anchorEl);
  const { userData } = useSelector((state: any) => state.userReducer.userData);

  const getAdminList = async () => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: `school-admin/teachers_in_school/${userData.id}/`,
      method: HTTPMethods.GET,
      headers: {},
    };

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        const list = data.map((item: any) => {
          return {
            ...item,
            ...createData(
              item.status ? "Active" : "Pending",
              item.class_info,
              item.section,
              item.user.first_name + " " + item.user.last_name,
              item.student_count || "-",
              item.teacher_name || "-",
              item.batch,
              item.id
            ),
          };
        });
        setAdminList(list);
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });

      console.log(error);
    }
  };

  useEffect(() => {
    if (userData?.id) {
      getAdminList();
      getSampleFileUrl();
    }
  }, [userData?.id]);

  const handleCreateTeacher = async (data: any, type: any) => {
    let params = {
      user: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.primaryEmail,
      },
      gender: data.gender,
      alternate_email: "",
      contact_number: data.primaryPhone,
      alternate_contact_number: "",
      status: true,
      school: userData.id,
    };
    let formData;
    if (type == "FILE") {
      const file = data[0];

      formData = new FormData();
      formData.append("teacher_details", file);
    }

    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url:
        type === "FILE"
          ? `user/upload_teachers_in_bulk/${userData.id}/`
          : "user/teacher/",
      data: type === "FILE" ? formData : params,
      method: HTTPMethods.POST,
      headers: {},
    };

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        setShowNotification(true);
        setNotificationData({
          type: "success",
          message: "New Teacher is added.",
        });
        setShowAddAdmin(false);
        getAdminList();
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      const errorObject: any = error;

      let errorMessage = "Validation Error:\n";

      if (typeof errorObject == "object") {
        Object.keys(errorObject)?.forEach((field) => {
          if (Array.isArray(errorObject[field])) {
            errorMessage += `${field}: ${errorObject[field].join(", ")}\n\n`;
          } else {
            errorMessage += `${field}: ${errorObject[field]}\n\n`;
          }
        });
      } else {
        errorMessage = "Something went wrong";
      }

      setShowNotification(true);
      setNotificationData({
        type: "error",
        message: errorMessage || "Something went wrong",
      });
    }
  };
  const handleEditStudentDetails = async (data: any) => {
    if (!data.id) {
      return;
    }

    const params = {
      user: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.primaryEmail,
      },
      gender: data.gender,
      contact_number: activeTableContext?.contact_number,
      school: userData.id,
    };
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: "user/teacher/" + data.id + "/",
      data: params,
      method: HTTPMethods.PUT,
      headers: {},
    };

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        setShowNotification(true);
        setNotificationData({
          type: "success",
          message: "Student details successsfully updated",
        });
        setShowAddAdmin(false);
        getAdminList();
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      const errorObject: any = error;

      let errorMessage = "Validation Error:\n";

      if (typeof errorObject == "object") {
        Object.keys(errorObject)?.forEach((field) => {
          errorMessage += `${field}: ${errorObject[field]?.join(", ")}\n\n`;
        });
      } else {
        errorMessage = "Something went wrong";
      }

      setShowNotification(true);
      setNotificationData({
        type: "error",
        message: errorMessage || "Something went wrong",
      });
      console.log(error);
    }
  };

  const teacherData = {
    name: activeTableContext?.classTeacher,
    class: "Std 08 Section B",
    teacherRole: activeTableContext?.teacher_role || "Class Teacher",
    email: activeTableContext?.user?.email,
    contact: activeTableContext?.contact_number,
    gender: activeTableContext?.gender,
    photo: activeTableContext?.photo,
  };
  const [sampleSheetUrl, setSampleSheetUrl] = useState<any>("");
  const getSampleFileUrl = async () => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: "user/get_teacher_bulk_upload_excel_format/",
      method: HTTPMethods.GET,
      headers: {},
      responseType: "arraybuffer",
    };

    try {
      const data: any = await makeAxiosRequest(payload, baseUrl);
      // Create a Blob URL for the Excel file
      const blob = new Blob([data.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const blobURL = URL.createObjectURL(blob);
      setSampleSheetUrl({ url: blobURL, filename: "teacher_details.xlsx" });

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      const errorObject: any = error;

      let errorMessage = "Validation Error:\n";

      if (typeof errorObject == "object") {
        Object.keys(errorObject)?.forEach((field) => {
          if (Array.isArray(errorObject[field])) {
            errorMessage += `${field}: ${errorObject[field].join(", ")}\n\n`;
          } else {
            errorMessage += `${field}: ${errorObject[field]}\n\n`;
          }
        });
      } else {
        errorMessage = "Something went wrong";
      }

      setShowNotification(true);
      setNotificationData({
        type: "error",
        message: errorMessage || "Something went wrong",
      });
    }
  };

  return (
    <>
      <div className={styles.adminTableWrap}>
        <TableContainer component={Paper}>
          <h2>
            Teacher{" "}
            <button
              onClick={() => {
                setShowAddAdmin(true);
              }}
            >
              Add NEW Teacher
            </button>
          </h2>
          <Table sx={{ minWidth: 700 }} aria-label="admin table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <span style={{ whiteSpace: "nowrap" }}>Status</span>
                </StyledTableCell>
                <StyledTableCell>
                  <span style={{ whiteSpace: "nowrap" }}>Class</span>
                </StyledTableCell>
                <StyledTableCell>
                  <span style={{ whiteSpace: "nowrap" }}>Section</span>
                </StyledTableCell>
                <StyledTableCell>
                  <span style={{ whiteSpace: "nowrap" }}>Class Teacher</span>
                </StyledTableCell>
                <StyledTableCell>
                  <span style={{ whiteSpace: "nowrap" }}>Student</span>
                </StyledTableCell>
                <StyledTableCell>
                  <span style={{ whiteSpace: "nowrap" }}>Maths Teacher</span>
                </StyledTableCell>
                <StyledTableCell>
                  <span style={{ whiteSpace: "nowrap" }}>Batch</span>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? adminList?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : adminList
              )
                // .filter((id: any) => {
                //   return id.class_info == Number(selectedClass);
                // })
                .map((row: any) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      <span
                        className={`${styles.studentStatus}
                                    ${
                                      row.status.toLowerCase() === "active"
                                        ? styles.active
                                        : ""
                                    }
                                    ${
                                      row.status.toLowerCase() === "inactive"
                                        ? styles.inactive
                                        : ""
                                    }
                                    ${
                                      row.status.toLowerCase() === "pending"
                                        ? styles.pending
                                        : ""
                                    }
                                `}
                      >
                        <DotSvg />
                        {row.status}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <span style={{ whiteSpace: "nowrap" }}>
                        {row.classInfo}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.section}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <span>{row.classTeacher}</span>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.students}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <span className={styles.mathTeacher}>{row.teacher}</span>
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <span className={styles.buttonContainer}>
                        <span
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row.batch}
                        </span>
                        <span className={styles.optionsButton}></span>
                        <IconButton onClick={(e) => handleClick(e, row)}>
                          <img src={optionButton} alt="" />
                        </IconButton>
                      </span>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              count={adminList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              sx={{
                "& .MuiTablePagination-spacer": {
                  flex: "unset !important",
                },
                "& p": {
                  marginBottom: 0,
                },
              }}
            />
          </Table>
        </TableContainer>
      </div>
      <Popover
        id={open ? "simple-popover" : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={styles.popoverWrap}>
          <button
            onClick={() => {
              setViewAdminDetailSidebar(true);
              handleClose();
            }}
          >
            <img src={view} alt="" />
            View
          </button>
          <button
            onClick={() => {
              setShowhowEditTeacherDetails(true);
              handleClose();
            }}
          >
            <img src={edit} alt="" />
            Edit
          </button>
          <button
            onClick={() => {
              setViewAdminDetailSidebar(true);
              handleClose();
            }}
          >
            <img src={contact} alt="" />
            Contact
          </button>
          <button
            onClick={() => {
              setViewAdminDetailSidebar(true);
              handleClose();
            }}
          >
            <img src={inactive} alt="" />
            Mark Inactive
          </button>
          <button
            onClick={() => {
              deleteDetails();
              handleClose();
            }}
          >
            <img src={deleteBtn} alt="" />
            Delete
          </button>
        </div>
      </Popover>
      <Snackbar
        open={showNotification}
        autoHideDuration={6000}
        onClose={() => {
          setShowNotification(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={notificationData.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {notificationData.message}
        </Alert>
      </Snackbar>
      {viewAdminDetailSidebar && (
        <AdminSideBar
          changeStatus={() => {
            setViewAdminDetailSidebar(false);
          }}
          status={true}
          title={"Assigned Teacher Details"}
          children={
            <TeachersDetailsSideBar
              classTeacher={teacherData}
              onTeacherEdit={() => {
                setShowhowEditTeacherDetails(true);
              }}
              onTeacherDelete={() => {
                deleteDetails(activeTableContext.id, true);
              }}
              onMathTeacherEdit={() => {
                setShowhowEditMathTeacherDetails(true);
              }}
              onMathTeacherDelete={() => {
                deleteDetails(activeTableContext.id, true);
              }}
            />
          }
        />
      )}

      {showEditTeacherDetails && (
        <AdminSideBar
          changeStatus={() => {
            setShowhowEditTeacherDetails(false);
          }}
          status={true}
          title={"Edit Teacher Details"}
          children={
            <>
              <RenderForm
                formData={editTeacherFormTemplate}
                defaultValues={defaultTeacherEditValues}
                onSubmit={handleEditStudentDetails}
                submitControls={
                  <SubmitAndCancelButton
                    onCancel={() => {
                      setShowhowEditTeacherDetails(false);
                    }}
                  />
                }
              />
            </>
          }
        />
      )}
      {showEditMathTeacherDetails && (
        <AdminSideBar
          changeStatus={() => {
            setShowhowEditTeacherDetails(false);
          }}
          status={true}
          title={"Edit Math Teacher Details"}
          children={
            <>
              <RenderForm
                formData={editMathTeacherFormTemplate}
                defaultValues={defaultMathTeacherEditValues}
                onSubmit={handleEditStudentDetails}
                submitControls={
                  <SubmitAndCancelButton
                    onCancel={() => {
                      setShowhowEditMathTeacherDetails(false);
                    }}
                  />
                }
              />
            </>
          }
        />
      )}

      {showAddAdmin && (
        <AdminSideBar
          changeStatus={() => {
            setShowAddAdmin(false);
          }}
          status={true}
          title={"Add New Teacher"}
          children={
            <>
              <RenderForm
                formData={addTeacherFormTemplate}
                defaultValues={{
                  firstName: "",
                  lastName: "",
                  gender: "",
                  class: "",
                  section: "",
                  primaryEmail: "",
                  primaryPhone: "",
                }}
                hasBulkUpload
                sampleBulkUploadTemplate={sampleSheetUrl}
                onSubmit={handleCreateTeacher}
                submitControls={
                  <SubmitAndCancelButton
                    onCancel={() => {
                      setShowAddAdmin(false);
                    }}
                  />
                }
              />
            </>
          }
        />
      )}
      {showDeletePopup && (
        <ConfirmationPopup
          title=" Are you sure you want to delete
                                         student
                                               permanently? "
          confirmText="Yes"
          declineText="No"
          maxWidth="540px"
          onConfirm={() => {
            deleteDetails(activeTableContext?.id);
          }}
          onDecline={() => {
            deleteDetails();
          }}
        />
      )}
    </>
  );
};

export default AdminTeacherTable;

const DotSvg = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6.5C1 3.18629 3.68629 0.5 7 0.5C10.3137 0.5 13 3.18629 13 6.5C13 9.81371 10.3137 12.5 7 12.5C3.68629 12.5 1 9.81371 1 6.5Z"
        fill="url(#paint0_linear_2403_18166)"
        stroke="#109289"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2403_18166"
          x1="3.25"
          y1="3.25"
          x2="14"
          y2="9.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="" />
          <stop offset="1" stopColor="" />
        </linearGradient>
      </defs>
    </svg>
  );
};
