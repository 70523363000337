import React, { useContext, useEffect, useState } from "react";
import Dropdown from "../../../common/dropdown/dropdown";
import styles from "./superAdminSchool.module.scss";
import StatusContext from "../../../context/loaderContext";
import { HTTPMethods, makeAxiosRequest } from "../../../utils/httpService";

import SuperAdminTabTable from "./superAdminTabTable";

const SuperAdminAdminTab = () => {
  const loaderStatus: any = useContext(StatusContext);
  const [stateList, setStateList] = useState<any>([
    { name: "Select State", id: "" },
  ]);
  const [cityList, setCityList] = useState<any>([
    { name: "Select City", id: "" },
  ]);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [selectedCity, setSelectedCity] = useState<any>(null);
  const getSchoolData = async (type: any, count?: any) => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: "super-admin/" + type + "/",
      method: HTTPMethods.GET,
      headers: {},
    };
    if (count) {
      payload.url = payload.url + count + "/";
    }

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        if (type == "states" && data.length) {
          setStateList([{ name: "Select a City", id: -1 }, ...data]);
        }
        if (type == "cities") {
          setCityList((state: any) => [
            { name: "Select a City", id: -1 },
            { name: data.name, id: data.id },
          ]);
        }
      }
      if (type == "schools_in_city") {
        // console.log(data);
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });

      console.log(error);
    }
  };

  useEffect(() => {
    getSchoolData("states");
  }, []);

  return (
    <div className={styles.schoolTab}>
      <div className={styles.schoolTabDropdowns}>
        <Dropdown
          options={stateList}
          defaultLabel={"Select A Class"}
          minWidth={"225px"}
          overlayWidth={"230px"}
          id={"selectClass"}
          selectedValue={(item: string) => console.log("Test2")}
          setDropdownSelectedValueWithId={(item: any) => {
            if (item.id === -1) return;
            getSchoolData("cities", item.id);
            setSelectedCity(null);
            setSelectedState(item.id);
          }}
          isMenuItemTypeText={true}
        />
        <Dropdown
          options={cityList}
          defaultLabel={"Select A Class"}
          minWidth={"225px"}
          overlayWidth={"230px"}
          id={"selectClass"}
          selectedValue={(item: string) => console.log("Test2")}
          setDropdownSelectedValueWithId={(item: any) => {
            if (item.id === -1) return;
            getSchoolData("schools_in_city", item.id);
            setSelectedState(null);
            setSelectedCity(item.id);
          }}
          disabled={!(cityList.filter((item: any) => item.id).length > 0)}
          isMenuItemTypeText={true}
        />
      </div>
      <SuperAdminTabTable
        selectedState={selectedState}
        selectedCity={selectedCity}
        stateList={stateList}
      />
    </div>
  );
};

export default SuperAdminAdminTab;
