import React, { useCallback, useEffect, useState } from "react";
import styles from "./newQuestionOverlay.module.scss";
import fileUploadIcon from "../../../../assets/images/fileUploadIcon.svg";
import fileUploadImageTypeIcon from "../../../../assets/images/fileUploadImageTypeIcon.svg";
import LinearProgressBar from "./linearProgress/linearProgressBar";
import closeIconForNewNoteOverlay from "../../../../assets/images/closeIconForNewNoteOverlay.svg";
import TextField from "@mui/material/TextField";
import Dropdown from "../../../../common/dropdown/dropdown";
import Dropzone from "react-dropzone";

const NewQuestionOverlay = ({ newQuestionOverlayHandler }: any) => {
  const [uploadedFilesList, setUploadedFilesList] = useState<any>([]);

  const [fileSize, setFileSize] = useState<any>();

  const onDrop = useCallback((acceptedFiles: any) => {
    const file = acceptedFiles[0] || "";
    const fileName = file.name || "";
    let fileSizeMb = file.size / 1024 / 1024 || 0;

    fileSizeMb =
      fileSizeMb < 1
        ? Math.ceil(fileSizeMb * 10000) / 10
        : Math.ceil(fileSizeMb * 10) / 10;

    setFileSize(fileSizeMb);

    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // const binaryStr = reader.result;
      };
      reader.readAsArrayBuffer(file);
    });
    acceptedFiles.fileSize = fileSizeMb;
    setUploadedFilesList((prev: any) => [...prev, acceptedFiles]);
  }, []);

  const uploadedFileDeleteHandler = (index: number) => {
    var array = [...uploadedFilesList]; // make a separate copy of the array

    if (index !== -1) {
      array.splice(index, 1);
      setUploadedFilesList(array);
    }
  };

  return (
    <>
      <div className={styles.videoOverlayHeader}>
        <div className={styles.newNoteText}>New Question</div>
        <div role="button" onClick={newQuestionOverlayHandler}>
          <img src={closeIconForNewNoteOverlay} />
        </div>
      </div>
      <div className={styles.newNoteMainContainer}>
        <div className={styles.selectContainer}>
          <div className={styles.label}>Select a Chapter</div>
          <Dropdown
            options={["videoNotesChapter"]?.map((x: any, ind: number) => ({
              name: x,
              id: ind + 1,
            }))}
            defaultLabel={"Select a Chapter"}
            selectedValue={["selectedValue"]}
            minWidth={"177px"}
          />
        </div>
        <div className={styles.selectContainer}>
          <div className={styles.label}>Select a Topic (Optional)</div>
          <Dropdown
            options={["chapterTopics"]?.map((x: any, ind: number) => ({
              name: x,
              id: ind + 1,
            }))}
            defaultLabel={"Select a Topic"}
            selectedValue={["selectedValue"]}
            minWidth={"177px"}
          />
        </div>
        <div className={styles.selectContainer}>
          <div className={styles.label}>What's your question?</div>
          <div>
            <TextField
              multiline
              rows={2}
              // maxRows={3}
              className={styles.textField}
              sx={{
                "& .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root": {
                  minHeight: "93px",
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.newQuestionOverlayContainer}>
        <div className={styles.fileUploadContainer}>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className={styles.fileUpload}>
                    <div>
                      <img src={fileUploadIcon} />
                    </div>
                    <div>
                      <div className={styles.fileDragAndDropText}>
                        Select a file or drag and drop here
                      </div>
                      <div className={styles.criteriaAndSelectFileContainer}>
                        <div className={styles.fileTypeText}>
                          JPG, PNG, file size no more than 10 MB
                        </div>
                        <div className={styles.selectFile}>Select File</div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        <div className={styles.uploadedFilesContainer}>
          {uploadedFilesList.map((item: any, key: number) => {
            return (
              <div className={styles.uploadedFile} key={key}>
                <div className={styles.fileImageIcon}>
                  <img src={fileUploadImageTypeIcon} />
                </div>
                <div className={styles.filename}>{item[0].name}</div>
                <div className={styles.progressBar}>
                  <LinearProgressBar
                    uploadedFile={item}
                    index={key}
                    uploadedFileDeleteHandler={uploadedFileDeleteHandler}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default NewQuestionOverlay;
