import { constants } from "../../constants/constants";

const initialState = {
  learn: 0,
  stats: 0,
  myClass: 0,
  studentDetails: 0,
};

const selectedTabReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case constants.LEARN:
      return {
        ...state,
        learn: action.index,
        stats: state.stats,
        myClass: state.myClass,
        studentDetails: state.studentDetails,
      };
    case constants.STATS:
      return {
        ...state,
        learn: state.learn,
        stats: action.index,
        myClass: state.myClass,
        studentDetails: state.studentDetails,
      };
    case constants.MYCLASS:
      return {
        ...state,
        learn: state.learn,
        stats: state.stats,
        myClass: action.index,
        studentDetails: state.studentDetails,
      };
    case constants.STUDENT_DETAILS:
      return {
        ...state,
        learn: state.learn,
        stats: state.stats,
        myClass: state.myClass,
        studentDetails: action.index,
      };
    default:
      return state;
  }
};

export default selectedTabReducer;
