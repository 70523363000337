import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import cancelIcon from "../../../assets/images/cross.svg";
import flyingIcon from "../../../assets/images/flyingIcon.svg";
import styles from "./submitPrefrencesModal.module.scss";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 513,
  bgcolor: "red",
  border: "none",
  boxShadow: 2,
  background: "linear-gradient(104.87deg, #F9FBFD 11.37%, #F8FAFF 87.96%)",
  borderRadius: "16px",
  outline: "none",
};

export const SubmitPrefrencesModal = (props: any) => {
  const { openModal, setOpenModal } = props;
  const [open, setOpen] = React.useState(openModal);
  const handleClose = () => {
    setOpen(false);
    setOpenModal(false);
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.container}>
            <div className={styles.headerContainer}>
              <div className={styles.heading}>Set Up Completed</div>
              <img
                onClick={handleClose}
                src={cancelIcon}
                style={{ cursor: "pointer" }}
                alt={cancelIcon}
              />
            </div>
            <div className={styles.verticleLine}></div>
            <div className={styles.bodyContainer}>
              <div className={styles.leftContainer}>
                <img src={flyingIcon} alt={flyingIcon} />
              </div>
              <div className={styles.rightContainer}>
                <div className={styles.heading}>
                  Great job! You are now ready.
                </div>
                <div className={styles.desc}>
                  Your study preferences have been successfully saved. You can
                  always update them from your profile
                </div>
                <div className={styles.primaryButtonWrap}>
                  <button onClick={handleClose} className={styles.primaryButton}>
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
