import React from "react";
import styles from "./topTeachers.module.scss";

import schoolAdminImage from "../../../assets/images/profilePic.png";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor:
            "linear-gradient(105deg, #F9FBFD 11.37%, #F8FAFF 87.96%)",
        color: "#4B5468",
        fontFamily: "inherit",
        border: "none",
        fontSize: 18,
        fontWeight: 500
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        backgroundColor:
            "linear-gradient(105deg, #F9FBFD 11.37%, #F8FAFF 87.96%)",
        color: "#4B5468",
        fontFamily: "inherit",
        border: "none"
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
}));

function createData(
    rank?: any,

    schoolAdmin?: any,
    schoolAdminImage?: any
) {
    return {
        rank,

        schoolAdmin,
        schoolAdminImage
    };
}

const rows = [
    createData(
        "#1",

        "Chandra Kurhade",
        schoolAdminImage
    ),
    createData(
        "#1",

        "Chandra Kurhade",
        schoolAdminImage
    ),
    createData(
        "#1",

        "Chandra Kurhade",
        schoolAdminImage
    )
];

const TopTeachers = () => {
    return (
        <div className={styles.table}>
            <TableContainer
                component={Paper}
                sx={{ maxWidth: 400 }}
            >
                <h1 className={styles.head}>Top Teachers</h1>

                <Table
                    sx={{ minWidth: 400 }}
                    aria-label="customized table"
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Rank</StyledTableCell>
                            <StyledTableCell>School Admin</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.rank}>
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                >
                                    <span
                                        className={`${styles.rank}
                                   
                                   
                                
                                `}
                                    >
                                        {row.rank}
                                    </span>
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                    <span className={styles.schoolAdmin}>
                                        <img
                                            src={row.schoolAdminImage}
                                            alt=""
                                        />
                                        {row.schoolAdmin}
                                    </span>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                <span className={styles.viewAll}>
                    <a href="#">View all</a>
                </span>
            </TableContainer>
        </div>
    );
};

export default TopTeachers;
