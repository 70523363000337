import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../../../common/breadcrumb/breadcrumb";
import styles from "./adminHome.module.scss";
import DashboardStatsCard from "../../../common/admin/dashboardStatsCard/dashboardStatsCard";
import DashboardNotification from "../../../common/admin/dashboardNotification/dashboardNotification";
import ClassesBlock from "../../../common/admin/classesBlock/classesBlock";
import StatusContext from "../../../context/loaderContext";
import { HTTPMethods, makeAxiosRequest } from "../../../utils/httpService";
import { useSelector } from "react-redux";

const AdminHome = () => {
  const loaderStatus: any = useContext(StatusContext);
  const [dashboardCountData, setDashboardCountData] = useState<any>([]);
  const [classData, setClassData] = useState<any>([]);
  const { userData } = useSelector((state: any) => state.userReducer.userData);
  const getDashboardData = async () => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: `school-admin/count_of_teachers_and_students/${userData.id}/`,
      method: HTTPMethods.GET,
      headers: {},
    };

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        const ddata = [
          {
            label1: "No. of Teachers",
            count1: data.teachers_count,
          },
          {
            label1: "No. of Students",
            count1: data.students_count,
          },
        ];

        setDashboardCountData(ddata);
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });

      console.log(error);
    }
  };
  const getDashboardClassData = async () => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: `super-admin/school_classes_info/${userData.id}/`,
      method: HTTPMethods.GET,
      headers: {},
    };

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        const classList: any = data.map((item: any) => {
          return {
            class: item.class_name,
            totalStudent: item.student_count,
            sections: item.sections,
          };
        });
        setClassData(classList);
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });

      console.log(error);
    }
  };

  useEffect(() => {
    if (userData?.id) {
      getDashboardData();
      getDashboardClassData();
    }
  }, [userData?.id]);
  return (
    <div className={styles.learnMainContainer}>
      <div className={styles.greetingsSearchBarContainer}>
        <div className={styles.greetings}>
          <Breadcrumb
            breadcrumbDetails={[
              {
                label: "Home",
                link: "/admin",
                selected: true,
              },
            ]}
          />
        </div>
      </div>
      <div className={styles.homeContainer}>
        <h2>Hello Rahul, Welcome to your dashboard!</h2>
        <div className={styles.homeContainer_info}>
          <div className={styles.homeContainer_data}>
            {!!dashboardCountData.length &&
              dashboardCountData.map((data: any, index: any) => (
                <DashboardStatsCard key={index} dataObj={data} />
              ))}
          </div>
          <div className={styles.homeContainer_notification}>
            <DashboardNotification
              data={notifications}
              link={"/admin/notifications"}
            />
          </div>
        </div>
        <div className={styles.homeContainer_classInfo}>
          <ClassesBlock listData={classData} link={"/admin/classes"} />
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
let notifications = [
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempora.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempora dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempora dsadsdsadsaadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
  {
    notification:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
  },
];
// const classData = [
//   {
//     class: "06",
//     totalStudent: "250",
//     url: "google.com",
//   },
//   {
//     class: "07",
//     totalStudent: "220",
//     url: "google.com",
//   },
//   {
//     class: "08",
//     totalStudent: "50",
//     url: "google.com",
//   },
//   {
//     class: "07",
//     totalStudent: "50",
//     url: "google.com",
//   },
// ];
