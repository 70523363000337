import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
} from "chart.js";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import styles from "./analyticsChart.module.scss";
import prev from "../../../../assets/images/prevTopicIcon.svg";
import next from "../../../../assets/images/nextContentIcon.svg";
import classPerformanceChartCalendar from "../../../../assets/images/classPerformanceChartCalendar.svg";
import separatorTestPerformance from "../../../../assets/images/separatorTestPerformance.svg";
import Dropdown from "../../../../common/dropdown/dropdown";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

var yLabels: any = {
  0: "",
  1: "L1",
  2: "L2",
  3: "L3",
  4: "L4",
  5: "L5",
};

var yLabelsTest: any = {
  0: "",
  1: "20%",
  2: "40%",
  3: "60%",
  4: "80%",
  5: "100%",
};

const labels = [
  "Knowing our numbers and key theorems",
  "Estimations",
  "Properties of Whole Numbers",
  "Patterns in Whole Numbers",
  "Knowing our numbers and key theorems ",
];

export const data = {
  labels,
  datasets: [
    {
      label: "",
      data: [3, 2, 1, 4, 2],
      backgroundColor: "#5A66C8",
      stack: "Stack 0",
      barPercentage: 1,
      borderWidth: 0.5,
      borderRadius: 5.5,
      categoryPercentage: 0.08,
    },

    // {
    //   label: "",
    //   data: [1, 3, 4, 2, 4],
    //   backgroundColor: "#9AAAFF",
    //   stack: "Stack 1",
    //   borderRadius: 4,
    //   categoryPercentage: 0.2,
    //   barPercentage: 1.0,
    // },
  ],
};

interface IAnalyticsChart {
  analyticsHeading?: string;
  analyticsData?: any;
  showDropDownFilter?: boolean;
  chartFilterWidth?: string;
  chartFilterOptions?: { name: string; id: number }[];
  chartFilterDefaultLabel?: string;
  filteredSelectedValues?: any;
  onclickfunc?: any;
  isClick?: boolean;
  height?: any;
  isTestPerformanceChart?: boolean;
  axisLabels?: {
    xAxis: string;
    yAxis: string;
  };
  maxItems?: number;
  type?: string;
}

const AnalyticsChart = ({
  analyticsHeading = "",
  analyticsData,
  showDropDownFilter = false,
  chartFilterOptions = [],
  chartFilterDefaultLabel,
  filteredSelectedValues,
  chartFilterWidth,
  onclickfunc,
  isClick,
  height,
  axisLabels,
  isTestPerformanceChart = false,
  maxItems = 4,
  type = "progress",
}: IAnalyticsChart) => {
  const [scroll, setScroll] = useState(0);
  const [scrollState, setScrollState] = useState({ left: false, right: false });

  const options = {
    maintainAspectRatio: false,
    tooltips: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        border: {
          width: 3,
        },
        min: 0,
        max: maxItems,
        ticks: {
          count: 1,
          callback: function (value: any) {
            const labelText =
              analyticsData && Object.keys(analyticsData).length !== 0
                ? analyticsData.labels[value]
                : labels[value];
            function getSubstrings() {
              const maxLength = 30;
              const substrings = [];
              for (let i = 0; i < labelText?.length; i += maxLength) {
                const substring = labelText?.substring(i, i + maxLength);
                substrings.push(substring);
              }
              return substrings;
            }
            return labelText?.length > 30 ? getSubstrings() : [labelText, ""];
          },
        },
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        title: {
          display: true,
          text: axisLabels?.xAxis,
        },
      },

      y: {
        border: {
          width: 0,
        },
        grid: {
          display: true,
          drawBorder: false,
        },
        title: {
          display: true,
          text: axisLabels?.yAxis,
        },
        stacked: false,
        max: 5,

        ticks: {
          callback: function (value: any, index: any, ticks: any) {
            return type == "tests" ? yLabelsTest[value] : yLabels[value];
          },
        },
      },
    },
  };

  useEffect(() => {
    // to show a max of 4 comparisons
    // if (!analyticsData?.labels?.length) return;
    let graphData = analyticsData ? analyticsData : data;
    if (graphData?.labels?.length <= maxItems) {
      setScrollState({ ...scrollState, left: false, right: false });
    } else if (scroll === 0) {
      setScrollState({ ...scrollState, left: false, right: true });
    } else if (scroll + maxItems >= graphData?.labels?.length) {
      setScrollState({ ...scrollState, left: true, right: false });
    }
  }, [scroll, analyticsData]);

  const handleScrollLeft = () => {
    if (scroll === 0) {
      return;
    }
    setScroll(scroll - 1);
    setScrollState({ ...scrollState, left: true, right: true });
  };

  const handleScrollRight = () => {
    if (!scrollState.right) return;
    if (
      scroll + maxItems >= analyticsData?.labels?.length ??
      data?.labels?.length
    ) {
      setScroll(
        analyticsData?.labels?.length ?? data?.labels?.length - maxItems
      );
    } else {
      setScroll(scroll + 1);
      setScrollState({ ...scrollState, left: true, right: true });
    }
  };

  const scrollableOptions = {
    ...options,
    scales: {
      ...options.scales,
      x: {
        ...options.scales.x,
        min: scroll,
        max: scroll + maxItems - 1,
      },
    },
  };
  const [selectedValues, setSelectedValues] = useState([
    { index: 0, value: "" },
  ]);
  const setUpdatesSelectedValues = (updatedArrValues: any) => {
    setSelectedValues(updatedArrValues);
    if (filteredSelectedValues) filteredSelectedValues(updatedArrValues);
  };
  const chartRef = useRef<any>(null);
  const handleBarClick = (event: any) => {
    if (isClick) {
      let ops = getElementAtEvent(chartRef.current, event);

      if (ops.length > 0) {
        onclickfunc(ops[0]?.index);
      }
    }
  };

  useEffect(() => {
    setScroll(0);
  }, [analyticsData]);

  return (
    <>
      <div className={styles.analyticChartContainer}>
        <div className={styles.analyticsHeader}>
          <div className={styles.analyticsText}>{analyticsHeading || ""}</div>
          <div className={styles.optionSection}>
            <div className={styles.legendSection}>
              {isTestPerformanceChart ? (
                <div className={styles.eachLegendSubmitted}>
                  <div
                    className={`${styles.legendIcon} ${styles.submitted}`}
                  ></div>
                  <div className={styles.legendLabel}>Submitted</div>
                </div>
              ) : (
                <>
                  <div className={styles.eachLegend}>
                    <div
                      className={`${styles.legendIcon} ${styles.mastery}`}
                    ></div>
                    <div className={styles.legendLabel}>Mastery</div>
                  </div>
                  {/* <div className={styles.eachLegend}>
                    <div
                      className={`${styles.legendIcon} ${styles.competency}`}
                    ></div>
                    <div className={styles.legendLabel}>Competency</div>
                  </div>{" "} */}
                </>
              )}
              {showDropDownFilter && (
                <div className={styles.separator}>
                  <img src={separatorTestPerformance} />
                </div>
              )}
              {showDropDownFilter && (
                <div className={styles.calenderDropdownWrapper}>
                  <div className={styles.calenderIcon}>
                    <img src={classPerformanceChartCalendar} />
                  </div>
                  <div className={styles.chartDropdown}>
                    {/*for single dropdown usage we need to pass patternIndex as 0 */}
                    <Dropdown
                      isDropdownOutlineRequired={false}
                      minWidth={chartFilterWidth ? chartFilterWidth : "150px"}
                      key={"analytics_chart_"}
                      options={chartFilterOptions}
                      defaultLabel={chartFilterDefaultLabel || "Select option"}
                      patternIndex={0}
                      initialSelectedPatternsValues={selectedValues}
                      setUpdatedSelectedPatternValues={setUpdatesSelectedValues}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.bar}>
          <Bar
            style={{ height: `${height}px` }}
            options={scrollableOptions}
            data={
              analyticsData && Object.keys(analyticsData).length !== 0
                ? analyticsData
                : data
            }
            onClick={
              axisLabels?.xAxis === "Chapters" ? handleBarClick : () => {}
            }
            ref={chartRef}
          />
        </div>
        <div className={styles.scrollButtonContainer}>
          <div
            className={
              scrollState.left
                ? `${styles.scrollButtonsActive} ${styles.scrollButtonsLeft}`
                : `${styles.scrollButtonsInActive} ${styles.scrollButtonsLeft}`
            }
          >
            <img src={prev} alt="prev" onClick={handleScrollLeft} />
          </div>
          <div
            className={
              scrollState.right
                ? `${styles.scrollButtonsActive} ${styles.scrollButtonsRight}`
                : `${styles.scrollButtonsInActive} ${styles.scrollButtonsRight}`
            }
          >
            <img src={next} alt="next" onClick={handleScrollRight} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsChart;
