import React from "react";
import styles from "./adminTable.module.scss";

import studentImage from "../../../assets/images/profilePic.png";
import teacherImage from "../../../assets/images/englishTeacher.png";
import mathsTeacherImage from "../../../assets/images/mathsTeacher.png";
import optionButton from "../../../assets/images/optionButton.svg";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "linear-gradient(105deg, #F9FBFD 11.37%, #F8FAFF 87.96%)",
    color: "#4B5468",
    fontFamily: "inherit",
    border: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "linear-gradient(105deg, #F9FBFD 11.37%, #F8FAFF 87.96%)",
    color: "#4B5468",
    fontFamily: "inherit",
    border: "none",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
}));

function createData(
  studentStatus?: any,
  section?: any,
  rollNo?: any,
  studentName?: any,
  studentImage?: any,
  level?: any,
  teacherName?: any,
  teacherImage?: any,
  mathsTeacherName?: any,
  mathsTeacherImage?: any,
  batch?: any
) {
  return {
    studentStatus,
    section,
    rollNo,
    studentName,
    studentImage,
    level,
    teacherName,
    teacherImage,
    mathsTeacherName,
    mathsTeacherImage,
    batch,
  };
}

const rows = [
  createData(
    "Active",
    "10 | C",
    "01",
    "Chandra Kurhade",
    studentImage,
    "L1",
    "Mahaaveer Talwar",
    teacherImage,
    "Mahaaveer Talwar",
    mathsTeacherImage,
    "2023-24"
  ),
  createData(
    "Inactive",
    "10 | C",
    "01",
    "Chandra Kurhade",
    studentImage,
    "L1",
    "Mahaaveer Talwar",
    teacherImage,
    "Mahaaveer Talwar",
    mathsTeacherImage,
    "2023-24"
  ),
  createData(
    "Pending",
    "10 | C",
    "01",
    "Chandra Kurhade",
    studentImage,
    "L1",
    "Mahaaveer Talwar",
    teacherImage,
    "Mahaaveer Talwar",
    mathsTeacherImage,
    "2023-24"
  ),
];

const AdminTable = () => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <span style={{ whiteSpace: "nowrap" }}>Status</span>
            </StyledTableCell>
            <StyledTableCell>
              <span style={{ whiteSpace: "nowrap" }}>Class</span>
            </StyledTableCell>
            <StyledTableCell>
              <span style={{ whiteSpace: "nowrap" }}>Roll No</span>
            </StyledTableCell>
            <StyledTableCell>
              <span style={{ whiteSpace: "nowrap" }}>Students</span>
            </StyledTableCell>
            <StyledTableCell>
              <span style={{ whiteSpace: "nowrap" }}>Level</span>
            </StyledTableCell>
            <StyledTableCell>
              <span style={{ whiteSpace: "nowrap" }}>Teacher</span>
            </StyledTableCell>
            <StyledTableCell>
              <span style={{ whiteSpace: "nowrap" }}>Maths Teacher</span>
            </StyledTableCell>
            <StyledTableCell>
              <span style={{ whiteSpace: "nowrap" }}>Batch</span>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.studentStatus}>
              <StyledTableCell component="th" scope="row">
                <span
                  className={`${styles.studentStatus}
                                    ${
                                      row.studentStatus.toLowerCase() ===
                                      "active"
                                        ? styles.active
                                        : ""
                                    }
                                    ${
                                      row.studentStatus.toLowerCase() ===
                                      "inactive"
                                        ? styles.inactive
                                        : ""
                                    }
                                    ${
                                      row.studentStatus.toLowerCase() ===
                                      "pending"
                                        ? styles.pending
                                        : ""
                                    }
                                   
                                
                                `}
                >
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 6.5C1 3.18629 3.68629 0.5 7 0.5C10.3137 0.5 13 3.18629 13 6.5C13 9.81371 10.3137 12.5 7 12.5C3.68629 12.5 1 9.81371 1 6.5Z"
                      fill="url(#paint0_linear_2403_18166)"
                      stroke="#109289"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2403_18166"
                        x1="3.25"
                        y1="3.25"
                        x2="14"
                        y2="9.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="" />
                        <stop offset="1" stopColor="" />
                      </linearGradient>
                    </defs>
                  </svg>
                  {row.studentStatus}
                </span>
              </StyledTableCell>
              <StyledTableCell align="left">
                <span style={{ whiteSpace: "nowrap" }}>{row.section}</span>
              </StyledTableCell>
              <StyledTableCell align="left">{row.rollNo}</StyledTableCell>
              <StyledTableCell align="left">
                <span className={styles.student}>
                  <img src={row.studentImage} alt="" />
                  {row.studentName}
                </span>
              </StyledTableCell>
              <StyledTableCell align="left">{row.level}</StyledTableCell>
              <StyledTableCell align="left">
                <span className={styles.teacher}>
                  <img src={teacherImage} alt="" />
                  {row.teacherName}
                </span>
              </StyledTableCell>
              <StyledTableCell align="left">
                <span className={styles.mathsTeacher}>
                  <img src={mathsTeacherImage} alt="" />
                  {row.mathsTeacherName}
                </span>
              </StyledTableCell>
              <StyledTableCell align="left">
                <span className={styles.buttonContainer}>
                  <span style={{ whiteSpace: "nowrap" }}>{row.batch}</span>
                  <span className={styles.optionsButton}>
                    <img src={optionButton} alt="" />
                  </span>
                </span>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdminTable;
