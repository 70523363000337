import React from "react";
import styles from "./solveAssignmentQuestionPopUp.module.scss";
import arrowGreen from "../../../../../../assets/images/arrowGreen.svg";
import { SolveAssignmentQuestionListViewAll } from "./questionListViewAll/solveAssignmentQuestionListViewAll";
import { useSelector } from "react-redux";
import solveAssignmentReducer from "../../../../../../store/reducers/solveAssingmentReducer";
import { useParams } from "react-router-dom";
interface IQuestionViewAllProps {
  question: any;
  setQuestionAnswerPopup: any;
  questionAnswerPopup: any;
  activeQuestionIndex: number;
  setActiveQuestionIndex: any;
  fromExercisePage?: boolean;
  aiSupport?: boolean;
}

export const SolveAssignmentQuestionPopUp = (props: IQuestionViewAllProps) => {
  const {
    question,
    setQuestionAnswerPopup,
    questionAnswerPopup,
    activeQuestionIndex,
    setActiveQuestionIndex,
    fromExercisePage,
    aiSupport,
  } = props;
  const questiondata = useSelector(
    (state: any) => state.solveAssignmentReducer
  );
  const { topicId } = useParams();

  return (
    <>
      <div className={styles.headerQuestionViewAll}>
        <div className={styles.questionText}>Questions</div>
        <div
          onClick={() => setQuestionAnswerPopup(!questionAnswerPopup)}
          className={styles.viewAll}
        >
          View All{" "}
          <span>
            <img
              className={styles.greenArrow}
              src={arrowGreen}
              alt="arrowGreen"
            />
          </span>
        </div>
        {questionAnswerPopup &&
          (questiondata.assigmentLevel >= 2 ? (
            <SolveAssignmentQuestionListViewAll
              showTestOverlay={false}
              title={`Exercise Level ${questiondata.assigmentLevel}`}
              closePopUp={() => setQuestionAnswerPopup(!questionAnswerPopup)}
              content={question}
              activeQuestionIndex={activeQuestionIndex}
              setActiveQuestionIndex={setActiveQuestionIndex}
              topicIdProp={topicId}
              fromExercisePage={fromExercisePage}
              aiSupport={aiSupport}
              fromViewAll={true}
              assignmentLevel={questiondata.assigmentLevel}
            />
          ) : (
            <SolveAssignmentQuestionListViewAll
              content={question}
              title={`Exercise ${question[activeQuestionIndex].topicNumber}.${question[activeQuestionIndex].subtopicNumber}`}
              activeQuestionIndex={activeQuestionIndex}
              setActiveQuestionIndex={setActiveQuestionIndex}
              closePopUp={() => setQuestionAnswerPopup(!questionAnswerPopup)}
              assignmentLevel={questiondata.assigmentLevel}
              topicIdProp={topicId}
              fromExercisePage={fromExercisePage}
              aiSupport={aiSupport}
              fromViewAll={true}
            />
          ))}
      </div>
    </>
  );
};
