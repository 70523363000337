import React from "react";
import styles from "./uploadReference.module.scss";
import thinkingMascot from "../../../../../../../../../assets/images/thinkingMascot.svg";
import closeIcon from "../../../../../../../../../assets/images/closeIcon.svg";
import questionCardImg from "../../../../../../../../../assets/images/questionCardImg.svg";
import SideBar from "../../../../../../../../../common/sidebar/sidebar";

const UploadReference = (props: any) => {
  return (
    <SideBar
      width="826px"
      content={
        <section className={styles.answerSheetStartWrap}>
          <div className={styles.headerSection}>
            <div className={`${styles.overlayHeaderText} ${styles.inter20}`}>
              {props?.data?.name}
            </div>
            <div className={styles.closeIcon} onClick={props.closeOverlay}>
              <img src={closeIcon} alt="close" />
            </div>
          </div>

          <div className={styles.bodySection}>
            {/* Instructions section */}
            <div className={styles.instructionsSection}>
              <div className={`${styles.header} ${styles.inter16}`}>
                Instructions
              </div>
              <div className={styles.instructions}>
                <ul>
                  {/* <li>Please upload descriptive answers in PDF format.</li> */}
                  <li>Maximum file upload size is 20 MB.</li>
                  {/* <li>
                    Use the CamScanner app to scan the answer sheet and convert
                    to PDF.{" "}
                  </li> */}
                  {/* <li>Use PDF compressors to compress your answer sheet.</li> */}
                  <li>Write page numbers on each sheet.</li>
                  <li>Upload images in the same sequence as the solution.</li>
                  <li>Refer to below document for answering format.</li>
                </ul>
                <div className={styles.iconSection}>
                  <img src={thinkingMascot} alt="icon" />
                </div>
              </div>
            </div>
            {[0, 1].map((x) => (
              <div className={styles.answerSheetImg}>
                <img src={questionCardImg} alt="image" />
              </div>
            ))}
          </div>
          {/* button section */}
          <div className={styles.buttonSection}>
            <div className={styles.cancelButton} onClick={props.closeOverlay}>
              CLOSE
            </div>
          </div>
        </section>
      }
    />
  );
};

export default React.memo(UploadReference);
