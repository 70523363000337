import React, { useState } from "react";
import styles from "./exampleAccordion.module.scss";
import exapandIconForPatternVideoOverlay from "../../../assets/images/exapandIconForPatternVideoOverlay.svg";
import mockResourceImageForPatternVideoOverlay from "../../../assets/images/mockResourceImageForPatternVideoOverlay.svg";
import { PPTViewer } from "../../pptViewer/pptViewer";

interface IExampleAccordion {
  accordionItem: {
    exampleName: string;
    ppt: string;
  };
}

const ExampleAccordion = ({ accordionItem }: IExampleAccordion) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <>
      <div className={styles.accordionContainer}>
        <div onClick={handleAccordionToggle} className={styles.accordionTitle}>
          <div className={styles.exampleName}> {accordionItem.exampleName}</div>
          <div>
            <img
              src={exapandIconForPatternVideoOverlay}
              className={isAccordionOpen ? styles.rotatedIcon : styles.icon}
              alt=""
            />
          </div>
        </div>

        {isAccordionOpen && (
          <div className={styles.accordionContent}>
            {/* <img src={mockResourceImageForPatternVideoOverlay} alt="" /> */}
            <PPTViewer source={accordionItem?.ppt} />
          </div>
        )}
      </div>
    </>
  );
};

export default ExampleAccordion;
