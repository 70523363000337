import * as React from "react";
import {useEffect } from "react";
import { useSelector } from "react-redux";
import AuthenticatedApp from "./AuthenticatedApp";
import configureStore from "./store";
import UnAuthenticatedApp from "./UnAuthenticatedApp";
import { constants } from "./constants/constants";

const Auth = () => {
  const { dispatch } = configureStore();
  let isLoggedIn = useSelector((state: any) => state.loginReducer.loggedIn); //Getting the isLoggedIn status from the Redux store
  useEffect(() => {
    const session = localStorage.getItem(constants.LOGGEDIN);
    if (session === "1") {
      //Check if the session is active
      dispatch({ type: constants.LOGIN });
    }
  }, []);

  isLoggedIn = localStorage.getItem(constants.LOGGEDIN) == "1";
  return isLoggedIn ? <AuthenticatedApp /> : <UnAuthenticatedApp />;
};

export default Auth;
