import { makeAxiosRequest } from "aie_common";
import React, { useContext, useEffect, useState } from "react";
import styles from "./mentorReviewTestHomeowork.module.scss";
import { useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ApiEdpoints, {
  HTTPMethods,
} from "../../../../../constants/api_endpoints";
import StatusContext from "../../../../../context/loaderContext";
import { convertToFormData } from "../../../../../utils/createFormData";
import { DescriptiveQuestionSubmissionModal } from "../../../descriptiveQuestionReviewPage/descriptiveQuestionSubmissionModal/descriptiveQuestionSubmissionModal";
import GoBackPage from "../../../../../common/goBackPage/goBackPage";
import { Question } from "../../../../learn/pages/assignmentPage/questionSection/question/question";
import { constants } from "../../../../../constants/constants";
import { QuestionNavigationWrapper } from "../../../descriptiveQuestionReviewPage/questionNavigationWrapper/questionNavigationWrapper";
import { MentorResultOption } from "./mentorResultOption";
import QuestionInputText from "../../../../learn/pages/assignmentPage/questionSection/questionOptions/assignmentOptions/descriptiveQuestion/questionTextInput/questionTextInput";
import QuestionFileInput from "../../../../learn/pages/assignmentPage/questionSection/questionOptions/assignmentOptions/descriptiveQuestion//questionFileInput/questionFileInput";
import QuestionUploadedFiles from "../../../../learn/pages/assignmentPage/questionSection/questionOptions/assignmentOptions/descriptiveQuestion//questionUploadedFiles/questionUploadedFiles";
import { routePaths } from "../../../../../constants/routes";
import { Dialog } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

interface ITopicInfo {
  assignment_name: string;
  chapter_index: string;
  submission_list: any[];
  topic_id: number;
  topic_index: number;
  topic_name: string;
  assignment_index: string;
}

interface IStudentDetails {
  roll_number: number;
  student_DP: string;
  student_level: number;
  student_name: string;
}

function moveArrayElement(array: any, fromIndex: number, toIndex: null) {
  let numberOfDeletedElm = 1;
  const elm = array.splice(fromIndex, numberOfDeletedElm)[0];
  numberOfDeletedElm = 0;
  array.splice(toIndex, numberOfDeletedElm, elm);
}

const MentorReviewTestHomeowork = ({ type }: any) => {
  const [question, setQuestion]: any = React.useState([]);
  const preSelectedIndex = useLocation();
  const navigate = useNavigate();

  const [inputText, setInputText] = useState<any>();
  const [marks, setMarks] = useState<number>();
  const [marksValid, setMarksValid] = useState<string>("");
  const [publishResultModal, setPublishModal] = useState(false);
  const [topicInfo, setTopicInfo] = React.useState<ITopicInfo>();
  const [canPublishResult, setCanPublihsResult] = useState(false);

  const [saveQuestionResponse, setSaveQuestionResponse] = useState(false);
  const loaderStatus: any = useContext(StatusContext);
  const params = useParams();

  const [activeQuestionIndex, setActiveQuestionIndex] = useState<any>(0);
  const [openModal, setOpenModal] = useState(false);
  const questionData = useSelector(
    (state: any) => state.solveAssignmentReducer
  );

  const fetchHomeworkTestQuestionsData = async (
    params: any,
    responseSaved?: any
  ) => {
    const payload: any = {
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };

    if (type == "Homework") {
      payload.url = `get_homework_questionsAnswer/?homework_id=${499}&student_id=${3055}`;
    }
    if (type == "Test") {
      payload.url = `get_testpaper_questionsAnswer/?testpaper_id=${26}&student_id=${3055}`;
    }
    try {
      !loaderStatus.show && loaderStatus.setState({ show: true });
      const assignmentsData: any = await makeAxiosRequest(payload);
      if (assignmentsData.status !== 200) {
        throw new Error("Error!");
      }
      let data = assignmentsData.data.data.student_test_data;
      if (type == "Homework") {
        data = assignmentsData.data.data.student_homework_data;
      }
      data = data.map((ele: any, index: number) => {
        let item = {
          ...ele,
          id: index + 1,
        };
        if (!item.options.length) {
          item.descriptive = true;
        }
        return item;
      });
      setQuestion(data);
      if (data.every((item: any) => item.verified == "Reviewed")) {
        setCanPublihsResult(true);
      }
      let redirectIndex = preSelectedIndex.search.split("?question=")[1];
      if (responseSaved) {
        if (question.length - 1 != activeQuestionIndex) {
          setActiveQuestionIndex((state: any) => state + 1);
        }
      } else {
        if (redirectIndex) {
          setActiveQuestionIndex(Number(redirectIndex));
        } else {
          setActiveQuestionIndex(0);
        }
      }

      setTopicInfo(assignmentsData?.data?.data);
      loaderStatus.setState({ show: false });
    } catch (err) {
      loaderStatus.setState({ show: false });
      console.log(err);
    }
  };

  const onMarksInput = (event: any) => {
    let value = event.target.value;
    typeof Number(value) != "number" ||
    value > question[activeQuestionIndex]?.question_marks ||
    value < 0
      ? setMarksValid("Invalid input")
      : setMarksValid("");
    setMarks(value);
  };

  useEffect(() => {
    setQuestion(() => {
      return question?.map((item: any) => {
        if (item.id === question[activeQuestionIndex]?.mocktest_id) {
          item.marks_scored = marks;
        }
        return item;
      });
    });
  }, [marks]);

  useEffect(() => {
    if (saveQuestionResponse) {
      saveMentorResponse();
    }
  }, [saveQuestionResponse]);

  const saveMentorResponse = async () => {
    const dataObject: any = {
      mocktest_id: question[activeQuestionIndex].mocktest_id,
      comment: String(inputText || ""),
      score: marks,
    };
    if (!question[activeQuestionIndex].descriptive) {
      dataObject.score = question[activeQuestionIndex].scored_marks;
    }

    let formData = convertToFormData(dataObject);
    if (uploadedFiles?.length) {
      uploadedFiles.forEach((element: any) => {
        formData.append("mentor_image", element);
      });
    }

    let payload = {
      url: ApiEdpoints.SUBMIT_MENTOR_RESPONSE,
      data: formData,
      method: HTTPMethods.POST,
      headers: {
        "Content-Type": "multipart/form-data", // Set the Content-Type header to multipart/form-data
      },
    };
    payload.url = `reviewquestion/`;

    try {
      const res: any = await makeAxiosRequest(payload);
      saveAndNext();
    } catch (error) {
      loaderStatus.setState({ show: false });
      saveAndNext();
      console.log(error);
    }
  };

  const saveAndNext = () => {
    setUploadedFiles([]);
    setMarks(undefined);
    setInputText("");
    fetchHomeworkTestQuestionsData(params, true);
  };

  useEffect(() => {
    fetchHomeworkTestQuestionsData(params);
  }, []);

  useEffect(() => {
    setMarks(question[activeQuestionIndex]?.marks_scored);
  }, [activeQuestionIndex]);

  useEffect(() => {
    let selectedQuestionId = questionData?.activeQuestionIndexMentor;
    let index = question?.findIndex(
      (ele: any) => ele.question_id === selectedQuestionId
    );
    setActiveQuestionIndex(index || 0);
  }, [questionData]);

  const onChangeInputText = (e: any) => {
    let value = e.target.value;
    setInputText(value);
  };
  const [uploadedFiles, setUploadedFiles] = useState<any>(
    question[activeQuestionIndex]?.uploadedImages || []
  );
  const [dragId, setDragId] = useState();

  const onUpdateInputFiles = (file: any) => {
    setUploadedFiles((prev: any) => [...prev, ...file]);
  };
  const uploadedFilesCount = uploadedFiles.length;
  const onDeleteFile = (indexToRemove: number) => {
    const uploadedFilesAfterDeletion = uploadedFiles.filter(
      (data: any, index: number) => index !== indexToRemove
    );
    setUploadedFiles(uploadedFilesAfterDeletion);
  };
  const handleDrag = (ev: any) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev: any) => {
    const dragBoxIndex = uploadedFiles.findIndex(
      (file: any) => file.id === dragId
    );
    const dropBoxIndex = uploadedFiles.findIndex(
      (file: any) => file.id === ev.currentTarget.id
    );
    const uploadedFilesCopy = [...uploadedFiles];
    moveArrayElement(uploadedFilesCopy, dragBoxIndex, dropBoxIndex);
    setUploadedFiles(uploadedFilesCopy);
  };
  return (
    <div className={styles.mainContainer}>
      {openModal ? (
        <DescriptiveQuestionSubmissionModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          setSaveQuestionResponse={setSaveQuestionResponse}
          name={question[activeQuestionIndex]?.student_name}
          correctQuestions={marks}
          totalQuestions={question[activeQuestionIndex]?.marks_alloted}
        />
      ) : null}
      {question && question.length ? (
        <>
          <GoBackPage heading={type} />
          <div className={styles.bodyContainer}>
            <div className={styles.leftContainer}>
              <div className={styles.leftUpperContainer}>
                <div className={styles.reviewQuestionContainer}>
                  <Question
                    question={question}
                    activeQuestionIndex={activeQuestionIndex}
                  />
                  <div>
                    <MentorResultOption
                      optionType={
                        question[activeQuestionIndex]?.descriptive
                          ? "desc"
                          : "option"
                      }
                      question={question}
                      activeQuestionIndex={activeQuestionIndex}
                    />
                  </div>
                  <div>
                    <div className={styles.leftDownContainer}>
                      <div>
                        <div className={styles.marksBox}>
                          <label>{constants.MARKS_SCORED_BY_STUDENT}</label>
                          <div className={styles.inputBoxWrap}>
                            <div className={styles.inputBox}>
                              <input
                                type="number"
                                value={
                                  marks ||
                                  question[activeQuestionIndex]?.scored_marks
                                }
                                onChange={onMarksInput}
                                min={0}
                                max={20}
                                disabled={
                                  !question[activeQuestionIndex]?.descriptive
                                }
                              />
                              <span>
                                of{" "}
                                {question[activeQuestionIndex]?.question_marks}
                              </span>
                            </div>
                            {marksValid !== "" && (
                              <div className={styles.errorMessage}>
                                {marksValid}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <QuestionInputText
                          inputText={
                            question[activeQuestionIndex]?.mentor_comment ||
                            inputText
                          }
                          onChangeInputText={onChangeInputText}
                        />
                        {question[activeQuestionIndex]?.descriptive && (
                          <>
                            <QuestionFileInput
                              onUpdateInputFiles={onUpdateInputFiles}
                              uploadedFilesCount={uploadedFilesCount}
                            />
                            <QuestionUploadedFiles
                              uploadedFiles={uploadedFiles}
                              setOptionClicked={() => {
                                //setOptionClicked
                              }}
                              onDeleteFile={onDeleteFile}
                              handleDrag={handleDrag}
                              handleDrop={handleDrop}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.navigationWrapper}>
                <button
                  onClick={() => {
                    return (
                      activeQuestionIndex + 1 > 1 &&
                      (setUploadedFiles([]),
                      setMarks(undefined),
                      setInputText(""),
                      setActiveQuestionIndex(activeQuestionIndex - 1))
                    );
                  }}
                  className={
                    activeQuestionIndex !== 0
                      ? styles.cancelButton
                      : styles.prevButtonDisable
                  }
                >
                  Previous
                </button>
                <button
                  onClick={() => {
                    return (
                      activeQuestionIndex < question.length - 1 &&
                      question.length - 1 !== activeQuestionIndex &&
                      (setUploadedFiles([]),
                      setMarks(undefined),
                      setInputText(""),
                      setActiveQuestionIndex(activeQuestionIndex + 1))
                    );
                  }}
                  className={
                    question.length - 1 !== activeQuestionIndex
                      ? styles.cancelButton
                      : styles.nextButtonDisable
                  }
                  disabled={!(question.length - 1 !== activeQuestionIndex)}
                >
                  Next
                </button>
                <button
                  className={styles.prevNextButton}
                  onClick={saveMentorResponse}
                  disabled={
                    question[activeQuestionIndex]?.descriptive
                      ? marks
                        ? false
                        : true
                      : inputText
                      ? false
                      : true
                  }
                >
                  Save & Next
                </button>
                <button
                  className={styles.prevNextButton}
                  disabled={!canPublishResult}
                  onClick={() => {
                    setPublishModal(true);
                  }}
                >
                  Publish Result
                </button>
              </div>
            </div>
          </div>
          <Dialog
            open={publishResultModal}
            fullScreen
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
          >
            <div className={styles.instructionWrap}>
              <div className={styles.instructionHeader}>
                <h3>Publish Result</h3>
                <button
                  onClick={() => {
                    setPublishModal(false);
                  }}
                >
                  <CloseRounded />
                </button>
              </div>
              <div className={styles.instructionBody}>
                <Plane />
                <ul>
                  <li>Do you want publish this result?</li>
                  <li>
                    <QuestionMark /> {question?.length} Questions reviewed and
                    evaluated
                  </li>
                  <li>
                    <CheckMark />{" "}
                    {
                      question?.filter((que: any) => {
                        return !que.descriptive && que.verified == "Reviewed";
                      })?.length
                    }{" "}
                    MCQs reviewed
                  </li>
                  <li>
                    <CheckMark />{" "}
                    {
                      question?.filter((que: any) => {
                        return que.descriptive && que.verified == "Reviewed";
                      })?.length
                    }{" "}
                    Subjective evaluated
                  </li>
                </ul>

                <div className={styles.instructionButtons}>
                  <button
                    onClick={() => {
                      setPublishModal(false);
                    }}
                  >
                    No
                  </button>
                  <button
                    onClick={() => {
                      //setCanPublihsResult(false);
                      navigate("mentor/my-class");
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default MentorReviewTestHomeowork;

const QuestionMark = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
        stroke="#6862E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 17.668C11.6904 17.668 12.25 17.1083 12.25 16.418C12.25 15.7276 11.6904 15.168 11 15.168C10.3096 15.168 9.75 15.7276 9.75 16.418C9.75 17.1083 10.3096 17.668 11 17.668Z"
        fill="#6862E0"
      />
      <path
        d="M11.0026 12.6667V11.8333C11.5795 11.8333 12.1434 11.6623 12.623 11.3418C13.1027 11.0213 13.4765 10.5658 13.6973 10.0328C13.918 9.49988 13.9758 8.91343 13.8632 8.34765C13.7507 7.78188 13.4729 7.26218 13.065 6.85427C12.6571 6.44637 12.1374 6.16858 11.5716 6.05604C11.0058 5.9435 10.4194 6.00126 9.88644 6.22202C9.35349 6.44277 8.89797 6.81661 8.57748 7.29625C8.257 7.7759 8.08594 8.33981 8.08594 8.91667"
        stroke="#6862E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CheckMark = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5Z"
        fill="url(#paint0_linear_13_1058)"
        stroke="#109289"
      />
      <path
        d="M13.7497 7.02002L8.55228 11.9784L5.95801 9.49919"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13_1058"
          x1="4.1875"
          y1="4.89583"
          x2="19.4167"
          y2="13.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#23B1A8" />
          <stop offset="1" stop-color="#398782" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Plane = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.5598 2.99998C67.3504 2.97787 67.1392 2.97787 66.9298 2.99998L3.92981 39C3.67698 39.1432 3.47118 39.3567 3.3374 39.6146C3.20362 39.8725 3.14763 40.1637 3.17623 40.4529C3.20483 40.742 3.31678 41.0166 3.49849 41.2433C3.6802 41.47 3.92384 41.6391 4.19981 41.73L23.0098 48L29.9998 68.01C30.0869 68.2422 30.2314 68.4486 30.4198 68.61C30.6784 68.8067 30.9949 68.9122 31.3198 68.91H31.9198C32.1029 68.8243 32.2663 68.7017 32.3998 68.55L43.0498 55.14L58.0498 60H58.5298C58.8842 59.9975 59.2262 59.8695 59.4953 59.6389C59.7644 59.4083 59.9431 59.0898 59.9998 58.74L68.9998 5.15998C69.0498 4.94283 69.0498 4.71714 68.9998 4.49998C69.0001 4.11234 68.8503 3.73963 68.5819 3.45998C68.3134 3.18034 67.9472 3.01549 67.5598 2.99998ZM32.4298 49.32C32.3341 49.5133 32.2538 49.7139 32.1898 49.92L30.8398 61.02L25.7698 47.4L55.0798 18.75L32.4298 49.32Z"
        fill="url(#paint0_linear_1_750)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_750"
          x1="24.61"
          y1="9.92689"
          x2="73.2786"
          y2="57.0335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B1B5FF" />
          <stop offset="0.965084" stop-color="#8A7AFE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
