import React from "react";
import { useSelector } from "react-redux";
import classes from "./patternDetails.module.scss";
import VideoPlayer from "../../../../../../common/videoPlayerSection/videoPlayer";
import viewDocIcon from "../../../../../../assets/images/viewDocIcon.svg";
import Tab from "../../../../../../common/tab/tab";
import { constants } from "../../../../../../constants/constants";

interface IPatternDetailsVideoDescription {
  patternListItem: any;
}
const PatternDetailsVideoDescription = ({
  patternListItem,
}: IPatternDetailsVideoDescription) => {
  const isSideNavOpen = useSelector((state: any) => state.tabReducer.tabOpen);
  const tabTitles = [
    constants.PATTERN,
    constants.EXAMPLE_QUESTIONS,
    constants.QA,
  ];
  const tabContents = [<TranscriptText />];

  return (
    <>
      <div className={classes.videoPlayerSection}>
        <div className={classes.videoPlayerWrapper}>
          <VideoPlayer videoUrl={patternListItem?.videoUrl || ""} />
        </div>

        <div
          className={
            isSideNavOpen
              ? `${classes.videoSectionExercise}`
              : `${classes.videoSectionExerciseBySideBar}`
          }
        >
          <div className={classes.videoViewDocWrapper}>
            <div className={classes.videoViewImg}>
              <img src={viewDocIcon} />
            </div>
            <div className={classes.videoViewDoc}>
              <div className={`${classes.videoViewDocName} ${classes.inter16}`}>
                View Document
              </div>
              <div className={`${classes.videoViewDocDesc} ${classes.inter14}`}>
                refernce ppt accompning video{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.videoSectionDescription}>
        <Tab titles={tabTitles} contents={tabContents} tabClass="tabStart" />
      </div>
    </>
  );
};

export default React.memo(PatternDetailsVideoDescription);

const TranscriptText = () => {
  return (
    <div className={classes.pattenMapAndDescriptionWrap}>
      <div>
        Rational numbers are commutative under addition and multiplication. If p
        and q are two rational numbers, then: Commutative law under addition
        says- p + q = q + p.Commutative law under multiplication says p x q = q
        x p.
      </div>
      <div className={classes.viewMapText}>{`view map ->`}</div>
    </div>
  );
};
