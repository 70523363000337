import React, { useState, useEffect, useContext } from "react";
import { constants } from "../../../constants/constants";
import styles from "./exerciseList.module.scss";
import FilterBar from "../../../common/filterBar/filterBar";
import { CustomPagination } from "../../../common/pagination/customPagination";
import SearchBar from "../../../common/searchBar/searchBar";
import useSorting from "../../../utils/useSorting";
import Dropdown from "../../../common/dropdown/dropdown";
import { formatDate } from "../../../utils/dateFormatter";
import useSortingByDate from "../../../utils/useSortingByDate";
import StatusContext from "../../../context/loaderContext";
import ExerciseCard from "../../../common/cards/exerciseCard";
import { log } from "console";

interface IExerciseList {
  assignmentsData: object[];
  patternOptions: string[];
  detailedViewRef: any;
}
const ExerciseList = ({
  assignmentsData,
  patternOptions,
  detailedViewRef,
}: IExerciseList) => {
  const [patternProps, setPatternProps] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const loaderStatus: any = useContext(StatusContext);

  const [chapterDropDownValueWithId, setChapterDropDownValueWithId] =
    useState<any>({});
  //using useeffect to update the data if not we're facing issue when we cleared catch
  function removeOutsideId(originalArray: any) {
    const newArray: any = [{ name: "All", id: 0 }];
    originalArray?.forEach((item: any, index: number) => {
      if (index === 0) return;
      const newItem = {
        name: item?.name?.name,
        id: item?.name?.id,
        chapterId: item?.name?.id,
      };
      newArray.push(newItem);
    });
    return newArray;
  }
  //1 CHECK
  const dataMassaging = (data: any) => {
    return data;
  };
  const [searchText, setSearchText] = useState("");
  const [dropdownArray, setDropdownArray] = React.useState<any>([]);
  const {
    dropdownSelectedValueWithId,
    setDropdownSelectedValueWithId,
    filteredTopics,
    page,
    paginationStart,
    paginationEnd,
    range,
    handlePaginationChange,
  } = useSorting(
    assignmentsData,
    selectedStatus,
    searchText,
    "studentsMyAssignment", // search filter key
    dataMassaging,
    3, // range
    dropdownArray
  );

  useEffect(() => {
    if (dropdownSelectedValueWithId) {
      if (dropdownSelectedValueWithId?.defaultLabel === "All Questions") {
        setChapterDropDownValueWithId(dropdownSelectedValueWithId);
      }
    }
  }, [dropdownSelectedValueWithId]);

  useEffect(() => {
    let arr: any = [];
    if (patternProps && patternProps.length > 0) {
      patternProps.map((x: any) => {
        if (x?.defaultLabel === chapterDropDownValueWithId?.defaultLabel) {
          arr.push({ ...x, chapterDropDownValueWithId });
        }
      });
    }
    setDropdownArray(arr);
  }, [chapterDropDownValueWithId, patternOptions, patternProps]);
  const { sortedByDateArr, handleSorting } = useSortingByDate(filteredTopics);

  useEffect(() => {
    handleSorting("desc");
  }, [filteredTopics]);

  useEffect(() => {
    if (patternOptions && patternOptions.length > 0) {
      const abc = removeOutsideId(patternOptions);
      setPatternProps([{ data: abc, defaultLabel: "All Questions" }]);
    }
  }, [patternOptions]);

  return (
    <div className={styles.allAssignmentsMainContainer}>
      <div className={styles.assignmnetsTitleSearchbarContainer}>
        <div className={styles.assignmentHeaderTitle}>Exercises</div>
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
      </div>

      <div className={styles.assignmentsItemsContainer}>
        {sortedByDateArr.length > 0 &&
          sortedByDateArr
            ?.slice(paginationStart, paginationEnd)
            .map((item: any, ind: number) => (
              <ExerciseCard i={ind} data={item} />
            ))}
        {filteredTopics.length === 0 && loaderStatus.show === false && (
          <div className={styles.noResultFound}>{constants.NO_DATA_FOUND}</div>
        )}
      </div>

      {filteredTopics?.length > 0 && (
        <CustomPagination
          detailedViewRef={detailedViewRef}
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(filteredTopics?.length / range)}
        />
      )}
    </div>
  );
};

export default React.memo(ExerciseList);
