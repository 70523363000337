import React from "react";
import styles from "./searchBar.module.scss";
import searchIcon from "../../assets/images/searchIcon.svg";
import { constants } from "../../constants/constants";

const SearchBar = ({ searchText, setSearchText }: any) => {
  return (
    <div className={styles.searchBar}>
      <div className={styles.searchIcon}>
        <img src={searchIcon} />
      </div>
      <input
        type="text"
        value={searchText}
        className={styles.searchText}
        placeholder={constants.LOOKING_FOR_SOMETHING}
        onChange={(e) => setSearchText(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
