import { makeAxiosRequest } from "aie_common";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import topicIcon from "../../../../../../assets/images/chapterDetailsTopicIcon.svg";
import ChapterCard from "../../../../../../common/chapterCard/chapterCard";
import ApiEdpoints, {
  HTTPMethods,
} from "../../../../../../constants/api_endpoints";
import StatusContext from "../../../../../../context/loaderContext";
import { getUpdatedStudentTopics } from "../../../../../../utils/teacherPersonalMassagedData";
import styles from "./studentTopics.module.scss";

interface IUpdatedStudentTopics {
  icon: any;
  chapter: {
    chapterDetails: {
      chapterName: string;
      chapterNumber: string | number;
    };
    chips: string[];
  };
}

const StudentTopics = () => {
  const [studentTopics, setStudentTopics] = useState<any>([]);
  const [updatedStudentTopics, setUpdatedStudentTopics] =
    useState<IUpdatedStudentTopics>();
  const loaderStatus: any = useContext(StatusContext);
  const [classId, setClassId] = useState<any>();
  const params = useParams();

  const fetchTopicsToBeFocused = async (courseId: any) => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.TOPIC_TO_FOCUS_ON}/?course_id=${courseId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      if (res) {
        loaderStatus.setState({ show: false });
        setStudentTopics(res?.data?.data);
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    setUpdatedStudentTopics(getUpdatedStudentTopics(studentTopics));
  }, [studentTopics]);

  useEffect(() => {
    classId && fetchTopicsToBeFocused(classId);
  }, [classId]);

  useEffect(() => {
    setClassId(Number(params?.classId));
  }, []);

  return (
    <div className={styles.studentTopicMainContainer}>
      {updatedStudentTopics &&
        Array.isArray(updatedStudentTopics) &&
        updatedStudentTopics
          .slice(0, 3)
          .map((studentTopics: any, index: any) => (
            <ChapterCard chapter={studentTopics} icon={topicIcon} />
          ))}
    </div>
  );
};

export default StudentTopics;
