import React, { useState } from "react";
import TabSection from "../../../../../common/tab/tab";
import { constants } from "../../../../../constants/constants";
import PatternOverall from "./patternOverall/patternOverall";
import StudentChapters from "./studentChapters/studentChapters";
import styles from "./studentPatterns.module.scss";
import StudentTopics from "./studentTopics/studentTopics";

const StudentPatterns = () => {
  const [indexToViewAllTabsNumber, setIndexToViewAllTabsNumber] = useState(0);
  const classDetailsTitles = [
    constants.OVERALL,
    constants.CHAPTERS,
    constants.TOPICS,
  ];
  const classDetailsContents = [
    <PatternOverall />,
    <StudentChapters />,
    <StudentTopics />,
  ];
  return (
    <div className={styles.stdPatternContainer}>
      <div className={styles.stdPatternHeadingContainer}>
        <div className={styles.stdPatternHeading}>{constants.FocusText}</div>

        <TabSection
          indexToViewAllTabsNumber={indexToViewAllTabsNumber}
          setIndexToViewAllTabsNumber={setIndexToViewAllTabsNumber}
          titles={classDetailsTitles}
          contents={classDetailsContents}
          //tabClass = "tabStart"
        />
      </div>
    </div>
  );
};

export default StudentPatterns;
