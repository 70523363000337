import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styles from "./overview.module.scss";
import { constants } from "../../../../../constants/constants";
import chapterImage from "../../../../../assets/images/chapterImage.svg";
import astronautImage from "../../../../../assets/images/astronautImage.svg";
import greenRightArrowIcon from "../../../../../assets/images/greenRightArrowIcon.svg";
import chapterDetailsTopicIcon from "../../../../../assets/images/chapterDetailsTopicIcon.svg";
import marksIcon from "../../../../../assets/images/marksIcon.svg";
import minutesIcon from "../../../../../assets/images/minutesIcon.svg";
import questionsIcon from "../../../../../assets/images/questionsIcon.svg";
import ModalBg from "../../../../../assets/images/aiSupportModalBg.svg";
import MascotRocket from "../../../../../assets/images/mascotRocket.svg";
import Progressbar from "../../../../../common/progressbar/progressbar";
import PatternListing from "../../patternListing/patternListing";
import AnalyticsChart from "../../analyticsChart/analyticsChart";
import { routePaths } from "../../../../../constants/routes";
import useOverlayDetails from "../../../../../utils/patternDetailsApi";
import PatternVideoOverlayContainer from "../../../../../common/patternVideoOverlayContainer/patternVideoOverlayContainer";
import { useAnalyticsGraphData } from "../../../../../utils/analyticsGraphApi";
import solveAssignmentReducer from "../../../../../store/reducers/solveAssingmentReducer";
import { useDispatch, useSelector } from "react-redux";
import { SolveAssignmentQuestionListViewAll } from "../../../pages/solveAssignmentPage/solveAssignmentMetaData/solveAssignmentQuestionPopUp/questionListViewAll/solveAssignmentQuestionListViewAll";
import ApiEdpoints, {
  HTTPMethods,
} from "../../../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import HomeworkCard from "../../../../../common/cards/homeworkCard";
import TestCard from "../../../../../common/cards/testCard";
import { Dialog, Switch } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import SolveExerciseButton from "../../../../../common/solveExerciseButton/solveExerciseButton";

/*
  PROPS TO PASS TO THIS CARD COMPONENT IN CHAPTER DETAILS PAGE

  title - title of the card
  icon - icon to show right side of the card
  questions - no of questions 
  marks - no of marks
  minutes - no of minutes
*/

const TopicCard = ({
  title,
  icon,
  topicNumber,
  topicId,
  chapterNumber,
  level,
  status,
}: any) => {
  const navigate = useNavigate();
  const watchVideo = (id: number) => {
    navigate(routePaths.learnVideoPlayer.replace(":topicId", String(id)));
  };
  const questionData = useSelector(
    (state: any) => state.solveAssignmentReducer
  );

  const [acticveQuestionIndex, setActicveQuestionIndex] = useState(0);

  useEffect(() => {
    setActicveQuestionIndex(questionData.activeQuestionIndex);
  }, [questionData.activeQuestionIndex]);

  return (
    <>
      <div className={styles.eachTopic}>
        <div className={styles.iconTitleDescriptionContainer}>
          <div className={styles.topicTitleResponsiveContainer}>
            <div className={styles.iconTopicTitleContainer}>
              <div className={styles.topicIcon}>
                <img src={icon} />
              </div>
              <div className={styles.topicWithVersion}>
                Topic {chapterNumber}.{topicNumber}
              </div>
            </div>
            <div className={styles.responsiveTitle}>{title}</div>
          </div>
          <div className={styles.titleAssignmentVideoContainer}>
            <div className={styles.titleDescriptionContainer}>
              <div className={styles.topicTitle} title={title}>
                {title}
              </div>
            </div>
            <div className={styles.assignmentWatchVideoContainer}>
              <div
                className={styles.watchVideoText}
                onClick={() => watchVideo(topicId)}
              >
                {constants.WATCH_VIDEO}
              </div>
              <SolveExerciseButton
                level={level}
                id={topicId}
                status={status || "pending"}
              >
                <button className={styles.solveNowButton}>
                  {constants.EXERCISE}
                </button>
              </SolveExerciseButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const FullWidthCard = ({ title, icon, questions, marks, minutes }: any) => {
  return (
    <>
      <div className={styles.fullWidthAssessment}>
        <div className={styles.iconTitleDescriptionContainer}>
          <div className={styles.assesmentIcon}>
            <img src={icon} />
          </div>
          <div className={styles.titleDescriptionGetStartedContainer}>
            <div className={styles.titleDescriptionContainer}>
              <div className={styles.assesmentTitle}>{title}</div>
              <div className={styles.questionsMarksMinutesContainer}>
                {questions && (
                  <div className={styles.questions}>
                    <img src={questionsIcon} />
                    <div className={styles.questionsText}>
                      {questions} {constants.QUESTIONS}
                    </div>
                  </div>
                )}
                {marks && (
                  <div className={styles.marks}>
                    <img src={marksIcon} />
                    <div className={styles.marksText}>
                      {marks} {constants.MARKS}
                    </div>
                  </div>
                )}
                {minutes && (
                  <div className={styles.minutes}>
                    <img src={minutesIcon} />
                    <div className={styles.minutesText}>
                      {minutes} {constants.MINUTES}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.getStarted}>{constants.GET_STARTED}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const Card = ({ title, icon, questions, marks, minutes }: any) => {
  return (
    <>
      <div className={styles.eachAssessment}>
        <div className={styles.iconTitleDescriptionContainer}>
          <div className={styles.assesmentIcon}>
            <img src={icon} />
          </div>
          <div className={styles.titleDescriptionContainer}>
            <div className={styles.assesmentTitle}>{title}</div>
            <div className={styles.questionsMarksMinutesContainer}>
              {questions && (
                <div className={styles.questions}>
                  <img src={questionsIcon} />
                  <div className={styles.questionsText}>
                    {questions} {constants.QUESTIONS}
                  </div>
                </div>
              )}
              {marks && (
                <div className={styles.marks}>
                  <img src={marksIcon} />
                  <div className={styles.marksText}>
                    {marks} {constants.MARKS}
                  </div>
                </div>
              )}
              {minutes && (
                <div className={styles.minutes}>
                  <img src={minutesIcon} />
                  <div className={styles.minutesText}>
                    {minutes} {constants.MINUTES}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.horizontalLineSolveNowContainer}>
          <div className={styles.horizontalLine}></div>
          <div className={styles.solveNow}>
            <div className={styles.solveNowText}>{constants.SOLVE_NOW}</div>
            <img src={greenRightArrowIcon} />
          </div>
        </div>
      </div>
    </>
  );
};

const initialAssessment = [1];
const Assessments = [1, 2];
const Homeworks = [1, 2];

export interface IChapterData {
  completedTopics: number;
  courseid: number;
  id: number;
  name: string;
  sl_no: number;
  totalTopics: number;
  assignments_data: {
    assignment_progress_: number;
    completed_assignments: number;
    total_assignments: number;
  };
  student_progress: number;
}
const OverviewSection = ({
  indexToViewAllTabsData,
  chaptersOverViewTData,
  patternsTData,
  chapterTopicsCompleted,
  getPatternDetailsId,
}: any) => {
  const chapterData = chaptersOverViewTData?.chapter_data
    ? (JSON.parse(
        JSON.stringify(chaptersOverViewTData?.chapter_data)
      ) as IChapterData)
    : undefined;
  const patternsData: any = patternsTData?.patterns
    ? [...patternsTData?.patterns].slice(0, 3)
    : [];
  const chaptersTopicsData = chaptersOverViewTData?.topics_data
    ? [...chaptersOverViewTData?.topics_data].slice(0, 2)
    : [];
  const [patternVideoOverlay, setPatternVideoOverlay] = useState(false);
  const [videoNotesOverlayContent, setVideoNotesOverlayContent] =
    useState("pattern");
  const navigate = useNavigate();
  const alteredPatternsData = patternsData?.map((x: any, i: number) => ({
    patternName: `${x?.name}`,
    patternDescription: x?.description,
    patternStrength: i % 2 === 0 ? "Weak" : "Strong",
    background:
      i % 2 === 0
        ? "linear-gradient(304.25deg, #5A66C8 12.81%, #896AF3 109.93%);"
        : "linear-gradient(4.53deg, #9E5BB8 5.46%, #DC95F5 105.81%)",
    level: i % 2 === 0 ? "Primary" : "Secondary",
    id: x?.id,
  }));
  const { patternOverlayData, getOverlayDetails }: any = useOverlayDetails();
  const { graphData, fetchAnalyticsChartData } = useAnalyticsGraphData();
  // const [chapterData, setChapterData] = useState<IChapterData>(JSON.parse(JSON.stringify(chaptersOverViewTData?.chapter_data)))
  const [videoNotesOverlay, setVideoNotesOverlay] = useState(false);
  const handlePatternVideoOverlay = (type: any) => {
    setVideoNotesOverlay(!videoNotesOverlay);
    setVideoNotesOverlayContent("pattern");
  };
  const handlePatternVideoOverlayContent = (
    resource: "viewDocument" | "pattern" | "patternMap"
  ) => {
    setVideoNotesOverlayContent(resource);
  };

  const handleViewPatternMap = async (topicId: number, courseId: number) => {
    const payload = {
      url: ApiEdpoints.MIND_MAP + `?course_id=${courseId}&topic_id=${topicId}`,
      data: {},
      method: HTTPMethods.GET,
    };
    try {
      const res = await makeAxiosRequest(payload);
      if (res.status === 200) {
        const patternMapUrl = res?.data?.data[0]?.mindmap;
        window.open(patternMapUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAnalyticsChartData(chapterData?.id);
  }, []);

  const dispatch = useDispatch();
  const navigateToChapterStats = () => {
    dispatch({
      type: "stats",
      index: 1,
    });
    navigate(
      routePaths.chapterStats.replace(
        ":statsId",
        chapterData?.id ? chapterData?.id.toString() : ""
      )
    );
  };

  let latestHomework: any = [];
  if (chaptersOverViewTData.test_homework_data.homework_data) {
    latestHomework = [chaptersOverViewTData.test_homework_data.homework_data];
  }

  let latestTest: any = [];
  if (chaptersOverViewTData.test_homework_data.test_paper_data) {
    latestTest = [chaptersOverViewTData.test_homework_data.test_paper_data];
  }

  return (
    <>
      {/* Chapters Section */}
      <div className={styles.chapterDetailsStartedMainChapters}>
        <div className={styles.chapterDetailsContainer}>
          <div className={styles.chapterDetailsBody}>
            <div className={styles.chapterCardAndChapterNameContainer}>
              <div className={styles.chapterCard}>
                <div className={styles.chapterWithNumber}>
                  Chapter {chapterData?.name.split(".")[0]}
                </div>
                <img src={chapterImage} className={styles.chapterImageSvg} />
              </div>
              <div className={styles.responsiveChapterName}>
                {chapterData?.name}
              </div>
            </div>
            <div className={styles.chapterTitleProgressContainer}>
              <div className={styles.titleExamRedayContainer}>
                <div className={styles.chapterTitle}>{chapterData?.name}</div>
                <div className={styles.chapterActions}>
                  {chapterData?.student_progress &&
                  chapterData?.student_progress >= 80 ? (
                    <div className={styles.examReady}>
                      {constants.YOUR_EXAM_READY}
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    className={styles.viewSummary}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleViewPatternMap(
                        Number(chaptersOverViewTData.chapter_data.id),
                        chaptersOverViewTData.chapter_data.courseid
                      );
                    }}
                  >
                    View Summary
                  </button>
                </div>
              </div>
              <div className={styles.chapterProgress}>
                <Progressbar
                  progress={
                    chapterData?.student_progress
                      ? chapterData?.student_progress
                      : 0
                  }
                  noOflevels={5}
                  levelsVisible={true}
                />
              </div>
            </div>
          </div>
          {/* NOT MVP TASK */}
          {/* <div className={styles.chapterSeparator}></div> */}
          {chapterData?.student_progress &&
          chapterData?.student_progress > 1 ? (
            <div className={styles.chapterSeparator}></div>
          ) : null}

          {chapterData?.student_progress &&
          chapterData?.student_progress > 1 ? (
            <>
              <div className={styles.footerKnowMoreContainer}>
                <div className={styles.chapterDetailsFooter}>
                  <div className={styles.chapterAssignmentsContainer}>
                    <div className={styles.completedAssignments}>
                      <div className={styles.assignmentNumber}>
                        {chapterData?.assignments_data?.completed_assignments}
                      </div>
                      <div className={styles.topicDetails}>
                        {constants.COMPLETED_ASSIGNMENTS}
                      </div>
                    </div>
                  </div>
                  <div className={styles.chapterTopicsContainer}>
                    <div className={styles.completedTopics}>
                      <div className={styles.topicNumber}>
                        {chapterData?.completedTopics}/
                        {chapterData?.totalTopics}
                      </div>
                      <div className={styles.topicDetails}>
                        {constants.COMPLETED_TOPICS}
                      </div>
                    </div>
                  </div>
                  {/* <div className={styles.chapterSuggestionContainer}>
                    <div className={styles.chapterSuggestionTitle}>
                      {constants.SUGGESTION_FOR_YOU}
                    </div>
                    <div className={styles.chapterSuggestionDescription }>
                      You have now grasped the basics of real numbers. Next you
                      need to focus on understanding the properties of square
                      roots and exponents for solving equations
                    </div>
                  </div> */}
                </div>
                {/* <div
                  className={
                    chapterTopicsCompleted
                      ? `${styles.chapterKnowMore}`
                      : `${styles.chapterKnowMoreWhenTopicsNotCompleted}`
                  }
                >
                  <div className={styles.knowMoreText}>
                    {constants.KNOW_MORE}
                  </div>
                  <div className={styles.knowMoreRightArrow}>
                    <img src={greenRightArrowIcon} />
                  </div>
                </div> */}
              </div>
            </>
          ) : (
            <>
              {/* NOT MVP TASK */}
              {/* <div className={styles.suggestionContainerResponsive}>
                <div className={styles.suggestionHeader}>
                  Suggestion for you
                </div>
                <div className={styles.suggestionText}>
                  Hey! great to have you here, let's get you started. Click
                  below to answer few questions that will help us curate the
                  content effectively.
                  <span className={styles.knowMoreText}>
                    Know More <img src={greenRightArrowIcon} />
                  </span>
                </div>
                <div className={styles.knowMoreTextResponsive}>
                  Know More <img src={greenRightArrowIcon} />
                </div>
              </div> */}
            </>
          )}
        </div>
        <div
          className={`${styles.chapterAstronaut} ${
            !chapterData?.student_progress && styles.smallIcon
          }`}
        >
          <img src={astronautImage} />
        </div>
      </div>

      {/* Assessments and Homework Section */}

      {/* NOT MVP TASK */}
      {/* <div className={styles.chapterDetailsStartedAssessments}>
          <div className={styles.assessmentTitleDescriptionContainer}>
            <div className={styles.assessmentTitle}>
              {constants.TESTS_FOR_YOU}
            </div>
            {chapterTopicsCompleted && (
              <div className={styles.assessmentDescription}>
                {constants.HERE_ARE_THINGS_THAT_YOU_NEED_TO_SOLVE_TODAY}
              </div>
            )}
          </div>
          {chapterTopicsCompleted && (
            <div className={styles.viewAllAssessments}>
              {constants.VIEW_ALL_TESTS}
            </div>
          )}
        </div> */}
      {/* NOT MVP TASK */}
      {/* <div className={styles.assessmentContainer}>
          {chapterTopicsCompleted
            ? Assessments.map((assessment, ind) => (
                <Card
                  key={`Card_${ind}`}
                  title={"Polynomials Basics-1"}
                  icon={taskStatusIcon}
                  questions={15}
                  marks={50}
                  minutes={90}
                />
              ))
            : initialAssessment.map((assessment, ind) => (
                <FullWidthCard
                  key={`fullwidthcard_${ind}`}
                  title={"Fundamentals of numbers and their usage"}
                  icon={assessmentIcon}
                  questions={15}
                  marks={null}
                  minutes={90}
                />
              ))}
        </div> */}
      {/* NOT IN MVP */}
      {/* <div className={styles.pendingHomeWorkContainer}>
          <div className={styles.pendingHomeWorkTitle}>
            {chapterTopicsCompleted
              ? constants.COMPLETE_AND_SUBMIT_THE_PENDING_WORK
              : "Topics for you to  start mastering this chapter"}
          </div>
          <div
            className={styles.viewlAllHomework}
            onClick={() => indexToViewAllTabsData(1)}
          >
            {chapterTopicsCompleted
              ? constants.VIEW_ALL_HOMEWORK
              : "Watch Video"}
          </div>
        </div> */}
      {chapterData?.student_progress && chapterData?.student_progress > 1 ? (
        <>
          {/* NOT IN MVP -  ADD THE pendingHomeWorkContainer here based on requirements */}
        </>
      ) : (
        <>
          <div className={styles.chapterDetailsStartedMainAssessments}>
            <div className={styles.pendingTopicContainer}>
              <div className={styles.pendingTopicTitle}>
                {constants.TOPICS_TO_MASTER}
              </div>
              <div
                className={styles.viewlAllTopics}
                onClick={() => indexToViewAllTabsData(1)}
              >
                {constants.VIEW_ALL_TOPICS}
              </div>
            </div>
            <div className={styles.assessmentContainer}>
              {chapterData?.student_progress &&
              chapterData?.student_progress > 1 ? (
                Homeworks.map((homework) => (
                  <></>
                  // <Card
                  //   title={"Real Numbers 01"}
                  //   icon={chapterDetailsHomeworkIcon}
                  //   questions={15}
                  //   marks={50}
                  //   minutes={null}
                  // />
                ))
              ) : (
                <>
                  <div className={styles.topicsHeaderResponsive}>Topic</div>
                  {chaptersTopicsData?.map((topic, ind) => (
                    <>
                      <TopicCard
                        key={`TopicCard_${ind}`}
                        title={topic?.name}
                        icon={chapterDetailsTopicIcon}
                        topicNumber={ind + 1}
                        topicId={topic?.id}
                        chapterNumber={chapterData?.name.split(".")[0]}
                        level={topic.level}
                        status={topic.status}
                      />
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
        </>
      )}
      <div className={styles.viewAllButton}>
        <button onClick={() => indexToViewAllTabsData(1)}>
          {constants.VIEW_ALL_TOPICS}
        </button>
      </div>
      {/* Patterns Section */}
      {/* <div className={styles.chapterDetailsStartedMainPatterns}>
        <div className={styles.patternsTitleViewPatternsContainer}>
          <div className={styles.patternTitle}>{constants.PATTERNS}</div>
          <div
            className={styles.viewAllPattern}
            onClick={() => indexToViewAllTabsData(2)}
          >
            {constants.VIEW_ALL_PATTERNS}
          </div>
        </div>
        <div className={styles.patternCardContainer}>
          {dummyPatternData.map((item, index) => {
            return <PatternCard patternItem={item} key={index} />;
          })}
        </div>
        <PatternListing
          patternList={alteredPatternsData}
          getOverlayDetails={getOverlayDetails}
          handlePatternVideoOverlay={handlePatternVideoOverlay}
          getPatternDetailsId={getPatternDetailsId}
        />
      </div> */}
      {!!latestTest.length && (
        <>
          <div className={styles.overviewTestHomeworkContainer}>
            <div className={styles.overviewTestHomeworkTitle}>
              Tests for you
              <button onClick={() => indexToViewAllTabsData(2)}>
                View all tests
              </button>
            </div>
            {latestTest.map((item: any, ind: number) => (
              <TestCard
                data={{
                  ...latestTest[0],
                  name: latestTest[0]?.topic?.chapter_name,
                  status: latestTest[0]?.status_of_test?.status,
                }}
                status={latestTest[0]?.status_of_test?.status}
              />
            ))}
          </div>
        </>
      )}

      {!!latestHomework.length && (
        <>
          <div className={styles.overviewTestHomeworkContainer}>
            <div className={styles.overviewTestHomeworkTitle}>
              Homework for you
              <button onClick={() => indexToViewAllTabsData(3)}>
                View all homework
              </button>
            </div>
            {latestHomework.map((item: any, ind: number) => (
              <HomeworkCard
                data={{
                  id: latestHomework[0].chapter.chapterid,
                  homeworkTitle: latestHomework[0].chapter.chaptername,
                  name: latestHomework[0].chapter.chaptername,
                  numberOfQuestion: latestHomework[0].question_count,
                  dueDate: latestHomework[0].date,
                  status: latestHomework[0].status_of_homework?.status,
                  topicId: latestHomework[0].chapter.chapterid,
                  progress: latestHomework[0].status_of_homework?.percentage,
                }}
                status={latestHomework[0].status_of_homework?.status}
              />
            ))}
          </div>
        </>
      )}

      {videoNotesOverlay && (
        <PatternVideoOverlayContainer
          videoUrl={patternOverlayData?.video}
          docName={patternOverlayData?.patternname}
          patternCardData={{
            descriptionText: patternOverlayData?.description,
            patternHeadingText: patternOverlayData?.patternname,
          }}
          handlePatternVideoOverlayContent={handlePatternVideoOverlayContent}
          handlePatternOverlay={handlePatternVideoOverlay}
          videoNotesOverlayContent={videoNotesOverlayContent}
          pptUrl={patternOverlayData?.ppt}
          patternExamples={patternOverlayData?.pattern_examples}
        />
      )}
      {/* Analytics Section */}

      {chapterData?.student_progress !== 0 &&
        graphData?.labels?.length != 0 && (
          <>
            <div className={styles.analyticsHeader}>
              <div className={styles.analyticsTitle}>My Performance</div>
              <div
                style={{
                  cursor: "pointer",
                }}
                className={styles.viewAnalytics}
                onClick={() => navigateToChapterStats()}
              >
                View Performance
              </div>
            </div>
            {graphData && (
              <AnalyticsChart
                analyticsData={graphData}
                axisLabels={{
                  xAxis: constants.TOPICS,
                  yAxis: constants.LEVELS,
                }}
              />
            )}
          </>
        )}
    </>
  );
};

export default OverviewSection;
