import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./studentDetails.module.scss";
import Tab from "../../../../common/tab/tab";
import StudentDetailsOverView from "../components/studentDetailsOverview/studentDetailsoverview";
import StudentDetailsAssignments from "../components/studentDetailsAssignments/studentDetailsAssignments";
import GoBackPage from "../../../../common/goBackPage/goBackPage";
import { constants } from "../../../../constants/constants";
import { useSelector } from "react-redux";
import StatusContext from "../../../../context/loaderContext";
import ApiEdpoints, { HTTPMethods } from "../../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import { formatDate } from "../../../../utils/dateFormatter";
import { calculateTimeTaken } from "../../../../utils/convertTime";
import { useParams } from "react-router-dom";
import TestPage from "../../../../modules/tests/tests";
import MyHomework from "../../../myAssignments/myHomework";

const StudentDetails = () => {
  const [indexToViewAllTabsNumber, setIndexToViewAllTabsNumber] = useState(0);
  const loaderStatus: any = useContext(StatusContext);
  const [studentsAssignmentsData, setStudentsAssignmentsData] = useState<any>();
  const [recentSubmissions, setRecentSubmissions] = useState<any>([]);
  const getIndexToViewAllAssignments = (index: number) => {
    setIndexToViewAllTabsNumber(index);
  };
  const studentDetailsRef: any = useRef();
  const params = useParams();
  const selectedIndexData = useSelector(
    (state: any) => state.selectedTabReducer
  );

  const titles = ["Overview", "Exercises", "Tests", "Homework"];

  const contents = [
    <StudentDetailsOverView
      recentSubmissions={recentSubmissions}
      getIndexToViewAllAssignments={getIndexToViewAllAssignments}
    />,
    <StudentDetailsAssignments
      studentsAssignmentsData={studentsAssignmentsData}
    />,
    <TestPage istabbedPage={true} isForReveiw={true} />,

    <MyHomework istabbedPage={true} isForReveiw={true} />,
  ];

  useEffect(() => {
    selectedIndexData &&
      setIndexToViewAllTabsNumber(selectedIndexData[constants.STUDENT_DETAILS]);
  }, []);

  useEffect(() => {
    studentDetailsRef?.current?.scrollIntoView({ behavior: "instant" });
  }, [studentDetailsRef]);

  const fetchStudentsAssignmentsData = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.ASSIGNED_QUESTION_LIST}/?student_id=${params.studentId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const assignmentsData: any = await makeAxiosRequest(payload);
      if (assignmentsData.status !== 200) {
        throw new Error("Error!");
      }
      if (assignmentsData) {
        loaderStatus.setState({ show: false });
        setStudentsAssignmentsData(assignmentsData?.data?.data);
      }
    } catch (err) {
      loaderStatus.setState({ show: false });
      console.log(err);
    }
  };
  useEffect(() => {
    fetchStudentsAssignmentsData();
  }, []);
  useEffect(() => {
    if (studentsAssignmentsData) {
      studentsAssignmentsData?.submission_list?.map((item: any) => {
        if (item?.status.toLowerCase() === "pending") {
          const massagedData = {
            assignmentIndex: item?.assignment_index,
            name: item?.assignment_name,
            status: item?.status,
            question: item?.question,
            submittedOn: formatDate(item?.submitted_on),
            timeTaken: calculateTimeTaken(item?.time_taken),
          };
          setRecentSubmissions((prev: any) => [...prev, massagedData]);
        }
      });
    }
  }, [studentsAssignmentsData]);

  return (
    <div
      className={styles.studentDetailsMainContainerWrap}
      ref={studentDetailsRef}
    >
      <GoBackPage heading="Student Details" />

      <Tab
        titles={titles}
        contents={contents}
        indexToViewAllTabsNumber={indexToViewAllTabsNumber}
        setIndexToViewAllTabsNumber={setIndexToViewAllTabsNumber}
        id={constants.STUDENT_DETAILS}
      />
    </div>
  );
};

export default React.memo(StudentDetails);
