import React from "react";
import styles from "./studentAssignmentCardItem.module.scss";
import timerLoadingIcon from "../../../../../../../assets/images/timerLoadingIcon.svg";
import submitedByIcon from "../../../../../../../assets/images/submitedByIcon.svg";
import completedIcon from "../../../../../../../assets/images/completedIcon.svg";
import { constants } from "../../../../../../../constants/constants";
import CardDetailsIconBox from "../../../../../../../common/cardDetailsIconBox/cardDetailsIconBox";
import { useNavigate } from "react-router-dom";
import minutesIcon from "../../../../../../../assets/images/minutesIcon.svg";
import { QuestionAndOptionsElement } from "../../../../../../../common/questionsAndOptions/questionAndOptions";
import testIcon from "../../../../../../../assets/images/test.svg";
import { useDispatch } from "react-redux";

const StudentAssignmentCardItem = ({ item, routePath, index }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToReviewPage = () => {
    dispatch({
      type: "UPDATE_QUESTION_INDEX_MENTOR",
      payload: item?.question_id,
    });
    navigate(routePath);
  };
  return (
    <div
      className={styles.studentAssignmnetCardItem}
      onClick={navigateToReviewPage}
    >
      <div className={styles.iconStatusContainer}>
        <div className={styles.statusIcon}>
          <img
            src={
              item?.status.toLowerCase() === "pending"
                ? timerLoadingIcon
                : completedIcon
            }
            className={styles.img}
            alt=""
          />
        </div>
        <div className={styles.status}>{item?.status}</div>
      </div>

      <div className={styles.titleDescriptionStatusContainer}>
        <div className={styles.titleDescriptionContainer}>
          <div className={styles.assignmentTitle}>
            <QuestionAndOptionsElement
              metaData={item?.questionName}
              size="question"
            />
          </div>
          <div className={styles.assignmentDateContainer}>
            <div className={styles.questionDateComponentWrapper}>
              <CardDetailsIconBox
                icon={testIcon}
                text={item?.assignment_index}
                gapInBetween={"6px"}
              />
            </div>
            <div className={styles.questionDateComponentWrapper}>
              <CardDetailsIconBox
                icon={submitedByIcon}
                text={`Submitted on ${item?.submittedOn}`}
                gapInBetween={"6px"}
              />
            </div>
            <div className={styles.questionDateComponentWrapper}>
              <CardDetailsIconBox
                icon={minutesIcon}
                text={`Time taken ${item?.timeTaken}`}
                gapInBetween={"6px"}
              />
            </div>
          </div>
        </div>
        {/* completed */}
        {item?.status.toLowerCase() !== "completed" && (
          <div className={styles.completedContainer}>
            <div className={styles.cancelButton}>{constants.REVIEW_NOW}</div>
          </div>
        )}

        {/* pending submitted  */}
        {item?.status.toLowerCase() === "pending" && (
          <div className={styles.pendingContainer}>
            <div className={styles.progressSection}>
              {item.completePercentage && (
                <>
                  <div className={styles.progressWrap}>
                    <div
                      className={styles.progress}
                      style={{ width: "60%" }}
                    ></div>
                  </div>
                  {/* missing in api */}
                  <div className={styles.progressText}>
                    {item.completePercentage}%
                  </div>
                </>
              )}
            </div>
            <div className={styles.pendingReviewNow}>
              {constants.REVIEW_NOW}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(StudentAssignmentCardItem);
