import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styles from './modalWrapper.module.scss';
import timer from '../../assets/images/submittingAssignment.svg';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'red',
    padding: 0,
    backgroundColor: "rgba(0, 0, 0, 0.2) !important",
    background: "linear-gradient(104.87deg, #F9FBFD 11.37%, #F8FAFF 87.96%)",
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: "16px",
    outline: 'unset'
};

export const ModalWrapperSubmitAssignment = (props: any) => {
    const { openModal, setOpenModal, activeQuestionType } = props;
    const [open, setOpen] = React.useState(openModal);
    const handleOpen = () => {
        setOpen(true);
        setOpenModal(true);
    }
    const handleClose = () => {
        setOpen(false);
        setOpenModal(false);
    };

    return (
        <div >
            <Modal
                open={true}
            // aria-labelledby="modal-modal-title"
            // aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={styles.container}>
                        <div >
                            {activeQuestionType === 'test' ? "Submitting MCQ" : "Submitting Assignment"}
                        </div>
                        {/* <img onClick={handleClose} src={cancelIcon} /> */}
                    </div>
                    {/* <div className={styles.verticleLineSubmitting}></div> */}
                    <div className={styles.imageContainer}>
                    <img className={styles.imageStylesSubmit} src={timer} alt="questionImage" />
                        <div className={styles.containerBox}>
                            <div className={styles.textSubmitHeader}>Your allocated time is up! </div>
                            <div className={styles.textSubmit}>Do Please wait your MCQ is being submitted... </div>
                        </div>

                    </div>

                </Box>
            </Modal>
        </div >
    );
}
