import React, { useState } from 'react'
import { AssignmentOptions } from './assignmentOptions/assignmentOptions';
import { SubmittedOptions } from './submittedOptions/submittedOptions';
import { ReviewOptions } from './reviewOption/reviewOptions';
import { SolveAssignmentResultOption } from './solveAssignmentResultOption/solveAssignmentResultOption';
import DescriptiveQuestion from './assignmentOptions/descriptiveQuestion/descriptiveQuestion';


interface IQuestionOption {
    question: any;
    setQuestion: any;
    activeQuestionIndex: any;
    activeAssignmentType?: string;
    setOptionClicked?: any;
    activeScreen?: any;
    userType?: any;
    saveQuestion?: any;

}

export const QuestionOption = (props: IQuestionOption) => {
    const {
        question,
        setQuestion,
        activeQuestionIndex,
        activeAssignmentType,
        setOptionClicked,
        activeScreen,
        userType,
        saveQuestion
    } = props;
    return (
        <div>
            {
                (activeScreen === 'selection')
                && <>
                    <AssignmentOptions
                        optionType={question[activeQuestionIndex]?.descriptive ? 'DESCRIPTIVE' : 'OPTION'}
                        question={question}
                        activeQuestionIndex={activeQuestionIndex}
                        setQuestion={setQuestion}
                        setOptionClicked={setOptionClicked}
                        activeAssigmentType={activeAssignmentType}
                    />
                </>
            }
            {
                activeAssignmentType === 'test'
                && <>
                    <AssignmentOptions
                        optionType={'OPTION'}
                        question={question}
                        activeQuestionIndex={activeQuestionIndex}
                        setQuestion={setQuestion}
                        setOptionClicked={setOptionClicked}
                        activeAssigmentType={activeAssignmentType}
                        saveQuestion={saveQuestion}
                    />
                </>
            }
            {
                activeAssignmentType === 'reviewAssignment'
                && <>
                    <ReviewOptions
                        question={question}
                        optionType={'OPTION'}
                        activeQuestionIndex={activeQuestionIndex}
                        userType={userType}
                    />
                </>
            }
            {
                (activeScreen === 'submittedMCQ')
                && <>
                    <SubmittedOptions
                        optionType={question[activeQuestionIndex]?.answers?.length ? 'option' : 'option'}
                        question={question}
                        activeQuestionIndex={activeQuestionIndex}
                    />
                </>
            }
            {
                (activeScreen === 'result' && question[activeQuestionIndex].descriptive) &&
                <>
                    <ReviewOptions
                        question={question}
                        optionType={activeScreen}
                        activeQuestionIndex={activeQuestionIndex}
                        userType={userType}
                    />
                </>
            }
            {
                (activeScreen === 'result' && !question[activeQuestionIndex].descriptive) &&
                <SolveAssignmentResultOption
                    optionType={question[activeQuestionIndex]?.answers?.length ? 'option' : 'option'}
                    question={question}
                    activeQuestionIndex={activeQuestionIndex}
                />

            }


        </div>
    )
}

