import React, { useState } from "react";
import styles from "./mentorResultOption.module.scss";

import disabled from "../../../../../assets/images/notSelectable.svg";
import correctSubmit from "../../../../../assets/images/correctSubmit.svg";
import wrongSubmit from "../../../../../assets/images/wrongSubmit.svg";
import { DescriptiveReviewQuestion } from "../../../../learn/pages/assignmentPage/questionSection/questionOptions/reviewOption/descriptiveReviewQuestion/descriptiveReviewQuestion";
import { BasicModal } from "../../../../../common/modalWrapper/modalWrapper";
import { QuestionAndOptionsElement } from "../../../../../common/questionsAndOptions/questionAndOptions";
import UploadedFilesDisplayModal from "../../../../learn/pages/assignmentPage/questionSection/questionOptions/assignmentOptions/descriptiveQuestion/uploadedFilesDisplayModal/uploadedFilesDisplayModal";

export const MentorResultOption = (props: any) => {
  const { question, activeQuestionIndex, optionType } = props;
  const [openModal, setOpenModal] = useState(false);
  const solutions = question[activeQuestionIndex]?.student_submitted_answer[0];
  const [isStudentImagesPopUp, setIsStudentImagesPopUp] = useState(false);
  const [activeFileIndex, setActiveFileIndex] = React.useState(0);
  const handleCloseModalStudent = () => {
    setIsStudentImagesPopUp(false);
  };

  return (
    <>
      {optionType === "option" ? (
        <div className={styles.reviewOptionContainer}>
          {question[activeQuestionIndex]?.options.map(
            (item: any, index: any) => {
              return (
                <div
                  className={
                    item.ansimage === ""
                      ? styles.containerOld
                      : styles.containerNew
                  }
                  key={index}
                >
                  <div
                    key={index}
                    className={`${styles.optionInside} 
                    ${item.correct ? styles.examSubmitCorrectAnswer : ""}
                    ${
                      item.id ==
                        question[activeQuestionIndex].student_submitted_answer
                          .answer_id &&
                      !question[activeQuestionIndex].student_submitted_answer
                        .correct
                        ? styles.examSubmitWrongAnswer
                        : ""
                    }
                    
                    `}
                  >
                    <div className={styles.containerOption}>
                      <div className={styles.optionInsideUnSelect}>
                        <div className={styles.optionIcon}>
                          {
                            <img
                              className={styles.radioButtonStyle}
                              src={disabled}
                              alt="selected"
                            />
                          }
                        </div>
                        <div>
                          <label className={styles.labelText}>
                            <QuestionAndOptionsElement metaData={item.ans} />
                          </label>
                        </div>
                      </div>
                      {/* Right and Wrong Icon In result options  */}
                      <div className={styles.containerSelectImage}>
                        {question[activeQuestionIndex].selectedAnswer && (
                          <>
                            {question[activeQuestionIndex].selectedAnswer ===
                              item.ans && (
                              <img
                                className={styles.correctImageStyle}
                                src={item.correct ? correctSubmit : wrongSubmit}
                                alt="selected"
                              />
                            )}
                          </>
                        )}
                        {item.correct && (
                          <img
                            className={styles.correctImageStyle}
                            src={correctSubmit}
                            alt="selected"
                          />
                        )}
                        {item.id ==
                          question[activeQuestionIndex].student_submitted_answer
                            .answer_id &&
                          !question[activeQuestionIndex]
                            .student_submitted_answer.correct && (
                            <img
                              className={styles.correctImageStyle}
                              src={wrongSubmit}
                              alt="selected"
                            />
                          )}
                      </div>
                    </div>

                    {item.ansimage ? (
                      <div className={styles.containerPopUp}>
                        <div className={styles.optionImageOnHoverContainer}>
                          <div className={styles.optionImageContainer}>
                            <img
                              src={item.ansimage}
                              alt="rectangle"
                              onClick={() => {
                                setOpenModal(true);
                              }}
                            />
                            {openModal ? (
                              <BasicModal
                                openModal={openModal}
                                setOpenModal={setOpenModal}
                                question={
                                  question[activeQuestionIndex].Answers[index]
                                }
                                index={index}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            }
          )}
        </div>
      ) : (
        // <DescriptiveReviewQuestion
        //   question={question}
        //   activeQuestionIndex={activeQuestionIndex}
        //   type={"result"}
        // />
        <>
          {solutions && solutions?.length && (
            <>
              <div className={styles.containerOption2}>
                {solutions &&
                  solutions?.map((item: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className={styles.options}
                        onClick={() => {
                          setIsStudentImagesPopUp(!isStudentImagesPopUp);
                          setActiveFileIndex(index);
                        }}
                      >
                        <div className={styles.pageIndex}>
                          Page {index + 1 > 9 ? "" : "0"}
                          {index + 1}
                        </div>
                        <div className={styles.uploadedImage}>
                          <img src={item} alt="upload" />
                        </div>
                      </div>
                    );
                  })}
              </div>
              <UploadedFilesDisplayModal
                key={1}
                open={isStudentImagesPopUp}
                handleClose={handleCloseModalStudent}
                activeFileIndex={activeFileIndex}
                setActiveFileIndex={setActiveFileIndex}
                uploadedFiles={solutions}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
