import { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Footer from "./common/footer/footer";
import SideNavBar from "./common/sideNavBar/sideNavBar";
import ApiEdpoints, { HTTPMethods } from "./constants/api_endpoints";
import { constants } from "./constants/constants";
import {
  componentRoutes,
  IRouteComponents,
  routePaths,
} from "./constants/routes";
import StatusContext from "./context/loaderContext";
import {
  fetchNotificationCount,
  fetchPersonalInfo,
} from "./utils/personalInfo";
import { useSelector } from "react-redux";
import { makeAxiosRequest } from "./utils/httpService";
import configureStore from "./store";

const getSideNavData = (type: string) => {
  if (type == "student") return constants.SIDE_NAV_DATA;
  if (type == "school_admin") return constants.SIDE_NAV_DATA_ADMIN;
  if (type == "super_admin") return constants.SIDE_NAV_DATA_SUPER_ADMIN;
  return constants.SIDE_NAV_DATA_TEACHERS;
};

const getInitalUserRoute = (type: string) => {
  if (type == "student") return routePaths.learn;
  if (type == "school_admin") return "/admin";
  if (type == "super_admin") return "/super-admin";
  return routePaths.mentorMyClass;
};

const AuthenticatedApp = () => {
  const navigate = useNavigate();
  const { dispatch } = configureStore();
  const userType: any = localStorage.getItem("USERTYPE");
  const [type, setType] = useState<any>(userType);
  const [data, setData] = useState(getSideNavData(type));
  const loaderStatus: any = useContext(StatusContext);
  const { userData } = useSelector((state: any) => state.userReducer.userData);
  const getUserDetails = async () => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";

    let payload: any = {
      url: `user/user_data/`,
      method: HTTPMethods.GET,
      headers: {},
    };

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl, true);
      if (data) {
        dispatch({
          type: constants.ADMINDATA,
          payload: {
            userData: data || {},
          },
        });
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });

      console.log(error);
    }
  };

  useEffect(() => {
    if (userType == "student" || userType == "teacher") {
      fetchPersonalInfo(loaderStatus, ApiEdpoints.STUDENT_INFO);
      fetchNotificationCount(loaderStatus, ApiEdpoints.NOTIFICATIONSCOUNT);
    }
    if (userType == "school_admin" || userType == "super_admin") {
      getUserDetails();
    }
    // type === "Student"
    // ? navigate(routePaths.learn)
    // : navigate(routePaths.mentorMyClass);
  }, []);

  const notificationCount = useSelector(
    (state: any) => state.userReducer.notificationCount
  );

  useEffect(() => {
    if (notificationCount) {
      const updateData = data.map((item) => {
        if (item.title === "Notifications") {
          return { ...item, count: notificationCount };
        } else {
          return item;
        }
      });
      setData(updateData);
    }
  }, [notificationCount]);

  return (
    <div className="mainWrapper">
      <div className="sideNavBarContainer">
        <SideNavBar data={data} setData={setData} handleExpand={() => {}} />
      </div>
      <div className="pageContent">
        <Routes>
          {componentRoutes.map((eachRoute: IRouteComponents) => (
            <Route
              path={eachRoute.path}
              element={eachRoute.element}
              key={eachRoute.path.split("/").join("-")}
            />
          ))}
          {/* /  route will navigate to student automatically */}
          {/* <Route path="*" element={userType === 'mentor' ? <Navigate to={routePaths.mentor}/> : <Navigate to={routePaths.dashboard} />} /> */}
          <Route
            path="*"
            element={<Navigate to={getInitalUserRoute(type)} />}
          />
        </Routes>
        <Footer />
      </div>
    </div>
  );
};
export default AuthenticatedApp;
