import React, { useState } from "react";

import closeBtn from "../../../assets/images/closeIcon.svg";

import studentImage from "../../../assets/images/student.png";

import styles from "../studentDetailsSideBar/studentDetailsSideBar.module.scss";
import Progressbar from "../../../common/progressbar/progressbar";
import ConfirmationPopup from "../confirmationPopup/confirmationPopup";

const StudentDetailsSideBar = ({
  studentData,
  parentsData,
  status,
  changeStatus,
  onDelete,
  onEdit,
}: any) => {
  const slideBarToggle = status; // set this to true to view this component on front-end

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  function deleteDetails() {
    onDelete(studentData.id, true);
    setShowDeletePopup(!showDeletePopup);
  }

  const [deleteContact, setDeleteContact] = useState(false);

  function deleteParentContact() {
    setDeleteContact(!deleteContact);
  }

  return (
    <>
      <div className={styles.student}>
        <div className={styles.photo}>
          <img src={studentImage} alt="" />
        </div>

        <div className={styles.card}>
          <div className={styles.head}>
            <h1>{studentData.name}</h1>
            <Progressbar noOflevels={5} progress={60} levelsVisible={false} />
          </div>
          <div className={styles.details}>
            <div className={styles.block}>
              <h1>{studentData.class}</h1>
              <p>Class</p>
            </div>
            <div className={styles.block}>
              <h1>{studentData.rollNo}</h1>
              <p>Roll No.</p>
            </div>
            <div className={styles.block}>
              <h1>{studentData.email}</h1>
              <p>Primary Email ID</p>
            </div>
            <div className={styles.block}>
              <h1>{studentData.contact}</h1>
              <p>Primary Mobile No.</p>
            </div>
            <div className={styles.block}>
              <h1>{studentData.gender}</h1>
              <p>Gender</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.parents}>
        <div className={styles.photo}></div>

        <div className={styles.card}>
          <div className={styles.head}>
            <h1>Parent/Guardian Details</h1>
          </div>
          <div className={styles.details}>
            <div className={styles.block}>
              <h1>{parentsData.name}</h1>
              <p>Name</p>
            </div>
            <div className={styles.block}>
              <h1>{parentsData.relationship}</h1>
              <p>Relationship</p>
            </div>
            <div className={styles.block}>
              <h1>{parentsData.email}</h1>
              <p>Email ID</p>
            </div>
            <div className={styles.block}>
              <h1>{parentsData.contact}</h1>
              <p>Mobile No.</p>
            </div>
          </div>
          <div className={styles.buttons}>
            <h2>Contact</h2>
            <div>
              <button
                onClick={() => {
                  setShowDeletePopup(true);
                }}
                className={styles.delete}
              >
                Delete
              </button>
              <button onClick={onEdit} className={styles.edit}>
                Edit details
              </button>
            </div>
          </div>
        </div>
      </div>
      {showDeletePopup && (
        <ConfirmationPopup
          title=" Are you sure you want to delete
                                         the student details
                                               permanently?"
          confirmText="Yes"
          declineText="No"
          onConfirm={deleteDetails}
          onDecline={() => {
            setShowDeletePopup(!showDeletePopup);
          }}
        />
      )}
    </>
  );
};

export default StudentDetailsSideBar;
