import React, { useState, useEffect, useContext } from "react";

import styles from "./notificationList.module.scss";
import { constants } from "../../constants/constants";
import FilterBar from "../../common/filterBar/filterBar";
import useSorting from "../../utils/useSorting";
import StatusContext from "../../context/loaderContext";
import useSortingByDate from "../../utils/useSortingByDate";
import { CustomPagination } from "../../common/pagination/customPagination";
import { formatDateWithTime } from "../../utils/dateFormatter";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../constants/routes";
import { makeAxiosRequest } from "aie_common";
import ApiEdpoints, { HTTPMethods } from "../../constants/api_endpoints";

interface INotificationListList {
  detailedViewRef: any;
  notificationData: object[];
}
const NotificationList = ({
  notificationData,
  detailedViewRef,
}: INotificationListList) => {
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const loaderStatus: any = useContext(StatusContext);
  const [dropdownArray, setDropdownArray] = React.useState<any>([]);
  const dataMassaging = (data: any) => {
    const updatedData = data.map((item: any) => {
      return {
        status: item.is_read ? "Read" : "Unread",
        created: item.created_at,
        id: item.id,
        message: item.message,
        user: item.user,
        ...item,
      };
    });

    return updatedData;
  };
  const markNotificationRead = async (notification: any) => {
    console.log(notification);

    if (notification.id) {
      try {
        const notificationRead: any = await makeAxiosRequest({
          url: `${ApiEdpoints.MARK_AS_READ_NOTIFICATIONS}`,
          data: {
            notification_id: notification.id,
          },
          method: HTTPMethods.PATCH,
          headers: {},
        });

        console.log(notificationRead);
        if (notificationRead.status) {
          if (
            notification.notification_type.type_of_notification === "Homework"
          ) {
            navigate(
              routePaths.homework +
                `?homeworkId=${notification.notification_type.id}`
            );
          }
          if (
            notification.notification_type.type_of_notification === "test paper"
          ) {
            navigate(
              routePaths.tests + `?testId=${notification.notification_type.id}`
            );
          }
        }
      } catch (error) {
        loaderStatus.setState({ show: false });
        console.log(error);
      }
    }
  };

  const {
    filteredTopics,
    page,
    paginationStart,
    paginationEnd,
    range,
    handlePaginationChange,
  } = useSorting(
    notificationData,
    selectedStatus,
    "",
    "notifications",
    dataMassaging,
    5,
    dropdownArray
  );

  const { sortedByDateArr, handleSorting } = useSortingByDate(filteredTopics);

  useEffect(() => {
    handleSorting("desc");
  }, []);

  return (
    <div className={styles.allAssignmentsMainContainer}>
      <div className={styles.assignmnetsTitleSearchbarContainer}>
        <div className={styles.assignmentHeaderTitle}>Notifications</div>
      </div>

      <div className={styles.separator}></div>
      <div className={styles.sortAndFilterContainer}>
        <div className={styles.filterMainContainer}>
          <div className={styles.statusContainer}>
            <FilterBar
              statuses={["All", "Read", "Unread"]}
              selectedStatus={(selectedStatuses: string[]) =>
                setSelectedStatus(selectedStatuses)
              }
              showSorting={false}
              innerDropdownNeeded={true}
            />
          </div>
        </div>
      </div>
      <div className={styles.testItemsContainer}>
        {sortedByDateArr.length > 0 &&
          sortedByDateArr
            ?.slice(paginationStart, paginationEnd)
            .map((item: any, ind: number) => {
              return (
                <div className={styles.notificationCardContainer}>
                  <div className={styles.notificationCardContent}>
                    <div className={styles.notificationCardIcon}>
                      <NotificationIcon />
                    </div>

                    <div className={styles.notificationCardMessage}>
                      <p>{item?.message || ""}</p>
                      {item?.created && (
                        <span>{formatDateWithTime(item?.created)}</span>
                      )}
                    </div>
                  </div>
                  <div className={styles.notificationCardAction}>
                    <button onClick={() => markNotificationRead(item)}>
                      View
                    </button>
                  </div>
                </div>
              );
            })}
        {filteredTopics.length === 0 && loaderStatus.show === false && (
          <div className={styles.noResultFound}>{constants.NO_DATA_FOUND}</div>
        )}
      </div>

      {filteredTopics?.length > 0 && (
        <CustomPagination
          detailedViewRef={detailedViewRef}
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(filteredTopics?.length / range)}
        />
      )}
    </div>
  );
};

export default React.memo(NotificationList);
const NotificationIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_15_22473)">
        <path
          d="M0 5.44C0 3.53582 0 2.58373 0.370578 1.85643C0.696547 1.21668 1.21668 0.696547 1.85643 0.370578C2.58373 0 3.53582 0 5.44 0H28.56C30.4642 0 31.4163 0 32.1436 0.370578C32.7833 0.696547 33.3035 1.21668 33.6294 1.85643C34 2.58373 34 3.53582 34 5.44V28.56C34 30.4642 34 31.4163 33.6294 32.1436C33.3035 32.7833 32.7833 33.3035 32.1436 33.6294C31.4163 34 30.4642 34 28.56 34H5.44C3.53582 34 2.58373 34 1.85643 33.6294C1.21668 33.3035 0.696547 32.7833 0.370578 32.1436C0 31.4163 0 30.4642 0 28.56V5.44Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_d_15_22473)">
        <path
          d="M9 8.65968C9 7.74253 9.68948 6.99902 10.54 6.99902H22.86C23.7105 6.99902 24.4 7.74253 24.4 8.65969V24.6984C24.4 25.6155 23.7105 26.359 22.86 26.359H10.54C9.68948 26.359 9 25.6155 9 24.6984V8.65968Z"
          fill="url(#paint0_linear_15_22473)"
        />
      </g>
      <path
        d="M17.8008 14.04H21.7608"
        stroke="white"
        strokeWidth="0.88"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8008 19.3193H21.7608"
        stroke="white"
        strokeWidth="0.88"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1606 12.7197L12.8126 14.9197L11.6406 13.8197"
        stroke="white"
        strokeWidth="0.88"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="13.1806" cy="19.54" r="1.54" fill="white" />
      <defs>
        <filter
          id="filter0_d_15_22473"
          x="0"
          y="0"
          width="35.36"
          height="35.36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.68" dy="0.68" />
          <feGaussianBlur stdDeviation="0.34" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_15_22473"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_15_22473"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_15_22473"
          x="7.9"
          y="5.89902"
          width="19.8004"
          height="23.7604"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1.1" dy="1.1" />
          <feGaussianBlur stdDeviation="1.1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.6512 0 0 0 0 0.66896 0 0 0 0 0.74 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_15_22473"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_15_22473"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_15_22473"
          x1="14.0129"
          y1="9.03805"
          x2="27.8404"
          y2="19.6936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B1B5FF" />
          <stop offset="0.965084" stop-color="#8A7AFE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
