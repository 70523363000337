import React, { useState } from "react";
import styles from "./header.module.scss";
import aiEducatorLogo from "../../assets/images/aiEducatorLogo.svg";
import expandIconForHeader from "../../assets/images/expandIconForHeader.svg";
import SideNavBar from "../sideNavBar/sideNavBar";
import { constants } from "../../constants/constants";

const Header = () => {
  const [showSideNav, setShowSideNav] = useState(false);
  const userType = localStorage.getItem("USERTYPE");
  const [type, setType] = useState<any>(userType);
  const [data, setData] = useState(
    type === "Student"
      ? constants.SIDE_NAV_DATA
      : constants.SIDE_NAV_DATA_TEACHERS
  );

  const handleExpand = () => {
    setShowSideNav(!showSideNav);
  };

  return (
    <>
      <div className={styles.headerContainer}>
        {!showSideNav ? (
          <div className={styles.header}>
            <div>
              <img src={aiEducatorLogo} />
            </div>
            <div>
              <img src={expandIconForHeader} onClick={handleExpand} />
            </div>
          </div>
        ) : (
          <>
            <SideNavBar
              showSideNav={showSideNav}
              handleExpand={handleExpand}
              data={data}
              setData={setData}
              showCollapseIcon={false}
            />
          </>
        )}
      </div>{" "}
    </>
  );
};

export default Header;
