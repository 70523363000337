import React, { useState, useEffect, useContext, useRef } from "react";
import { makeAxiosRequest } from "aie_common";
import { constants } from "../../../constants/constants";
import styles from "./exercises.module.scss";
import StatusContext from "../../../context/loaderContext";
import ApiEdpoints, { HTTPMethods } from "../../../constants/api_endpoints";
import Breadcrumb from "../../../common/breadcrumb/breadcrumb";
import ExerciseTopicList from "./exerciseTopicList";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const ExerciseTopics = () => {
  const detailedViewRef = useRef(null);
  const [assignmentsData, setAssignmentsData] = useState<any>([]);
  const loaderStatus: any = useContext(StatusContext);
  const { topicId } = useParams();
  const courseId = useSelector(
    (state: any) => state.userReducer?.registeredCourseId
  );

  const fetchAssignmentsList = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      // url: `ChapterTopicsOverview/?chapter_id=${topicId}`,
      url: `${ApiEdpoints.LIST_OF_ASSIGNMENTS}?class_id=${courseId}&chapter_id=${topicId}&key=1`,
      data: {},

      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);
      if (response && response.status === 200) {
        const assignmentsDataRes = response?.data?.data.filter(
          (item: any) => item.id == topicId
        );

        setAssignmentsData(assignmentsDataRes);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    if (topicId) {
      fetchAssignmentsList();
    }
  }, []);

  return (
    <div className={styles.myAssignmentsMainContainer} ref={detailedViewRef}>
      <Breadcrumb
        breadcrumbDetails={[
          {
            label: "Home",
            link: "/home",
            selected: false,
          },
          {
            label: "Practice",
            link: "/practice/exercises",
            selected: false,
          },
          {
            label: "Exercises",
            link: "/practice/exercises",
            selected: true,
          },
          {
            label: assignmentsData[0]?.name || "",
            link: `/practice/exercises/${assignmentsData[0]?.id || ""}`,
            selected: true,
          },
        ]}
      />
      <ExerciseTopicList
        detailedViewRef={detailedViewRef}
        assignmentsData={assignmentsData}
        patternOptions={[]}
      />
    </div>
  );
};

export default React.memo(ExerciseTopics);
