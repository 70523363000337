import { makeAxiosRequest } from "aie_common";
import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import chapterImage from "../../../../../assets/images/chapterImage.svg";
import checkHover from "../../../../../assets/images/checkhover.svg";
import noDataAvatar from "../../../../../assets/images/ThinkingNoData.svg";
import ChapterCard from "../../../../../common/chapterCard/chapterCard";
import ClassPerformanceChart from "../../../../../common/classPerformanceChart/classPerformanceChart";
import Dropdown from "../../../../../common/dropdown/dropdown";
import GoBackPage from "../../../../../common/goBackPage/goBackPage";
import MultiSelectDropdown from "../../../../../common/multiSelectDropdown/multiSelectDropdown";
import ApiEdpoints, {
  HTTPMethods,
} from "../../../../../constants/api_endpoints";
import { constants } from "../../../../../constants/constants";
import { routePaths } from "../../../../../constants/routes";
import StatusContext from "../../../../../context/loaderContext";
import { getAnalyticsChartConfig } from "../../../../../utils/analyticsGraphConfig";
import sortStudentProgressData from "../../../../../utils/sortStudentProgressData";
import { useStudentListDataWithLevel } from "../../../../../utils/studentListDataWithLevel";
import { romanToNumeral } from "../../../../../utils/teacherPersonalMassagedData";
import useChaptersToBeFocused from "../../../../../utils/useChaptersToBeFocused";
import { getUsernameInitials } from "../../../../../utils/usernameInitials";
import AnalyticsChart from "../../../../learn/components/analyticsChart/analyticsChart";
import styles from "./classAnalyticsPage.module.scss";

const ClassAnalyticsPage = () => {
  const [dropdownSelectedValueWithId, setDropdownSelectedValueWithId] =
    useState<any>();

  const [indexLevel, setIndexLevel] = useState(5);
  const levelsData = [
    {
      level: 5,
      numOfStudents: 0,
      students: [],
    },
    {
      level: 4,
      numOfStudents: 0,
      students: [],
    },
    {
      level: 3,
      numOfStudents: 0,
      students: [],
    },
    {
      level: 2,
      numOfStudents: 0,
      students: [],
    },
    {
      level: 1,
      numOfStudents: 0,
      students: [],
    },
  ];
  const [data, setData] = useState<any>({
    level: 1,
    numOfStudents: "01",
    students: [],
  });
  const [showHover, setShowHover] = useState(true);
  const navigate = useNavigate();
  const isSideNavOpen = useSelector((state: any) => state.tabReducer.tabOpen);
  const datesOptions: any = constants.FILTER_OPTIONS_ANALYTICS?.map(
    (x: any, ind: number) => ({ name: x, id: ind + 1 })
  );
  const [chartDatesOptions, setChartDatesOptions] = useState(datesOptions);
  const [filteredValues, updatedFilteredValues] = useState([]);
  const loaderStatus: any = useContext(StatusContext);
  const [chapterOptions, setChapterOptions] = useState<any>();
  const [chapterAnalyticsGraphData, setChapterAnalyticsGraphData] =
    useState<any>();
  const [
    chapterTopicsStudentProgressAnalytics,
    setChapterTopicsStudentProgressAnalytics,
  ] = useState<any>();
  const [
    chapterNameAndIdForAnalyticsChart,
    setChapterIdAndNameForAnalyticsChart,
  ] = useState<any>([]);
  const detailedViewRef: any = useRef(null);
  const [isAnalyticsChartForChapters, setAnalyticsChartForChapters] =
    useState(true);
  const [studentProgressAnalyticsChart, setStudentProgressAnalyticsChart] =
    useState<any>();
  const params = useParams();
  const [classId, setClassId] = useState<any>();
  const [selectedChapterIdOnBarClick, setSelectedChapterIdOnBarClick] =
    useState<any>();
  const {
    massagedStudentsDetailsList,
    noOfStudentsByLevel,
    studentsDetailsListTotalData,
  } = useStudentListDataWithLevel(classId);

  const { updatedChapterToBeFocused, fetchChaptersToBeFocused } =
    useChaptersToBeFocused();
  const [classList, setClassList] = useState<any>([]);
  const [standardDropdown, setStandardDropdown] = useState<any>([]);
  const [divisioDropdown, setDivisionDropdown] = useState<any>([]);
  const [dropdownData, setDropdownData] = useState<any>([]);
  const [studentBylevelArr, setStudentBylevelArr] = useState<any>([]);

  const handleChaptersChange = (selectedValues: any) => {
    if (selectedValues?.length === 1) {
      // Fetch  Study Progress each Chapter's Topics Data
      const chapterId = selectedValues[0].value;
      setSelectedChapterIdOnBarClick(chapterId);
      fetchTopicsProgressAnalyticsChartData(classId, chapterId, selectedValues);
    } else {
      //Set Study Progress Chapter Data configuration for Analytics Chart
      handleFilteredChaptersAnalyticsData(selectedValues);
    }
  };

  const fetchChapterList = async (classId: any) => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.GET_TOPICS}/?courseid=${classId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const chapterDetailsResponse: any = await makeAxiosRequest(payload);
      const courseTopics = chapterDetailsResponse?.data?.data || [];
      const chapterFilterOptionsData =
        courseTopics?.map((item: any) => {
          const chapterNumber = item?.name.split(".")[0];
          const chapterName = item?.name.split(".")[1];
          return {
            value: String(item?.id),
            label: `${
              Number(chapterNumber) < 10
                ? `Ch 0${chapterNumber.trim()} `
                : `Ch ${chapterNumber.trim()}`
            } | ${chapterName.trim()}`,
            ele: (
              <div>
                {Number(chapterNumber) < 10
                  ? `Ch 0${chapterNumber.trim()} `
                  : `Ch ${chapterNumber.trim()}`}
                <span className={styles.verticalLine}>| </span>
                {chapterName.trim()}
              </div>
            ),
          };
        }) || [];
      setChapterOptions(chapterFilterOptionsData);
      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const handleFilteredChaptersAnalyticsData = (
    chapterFilterValues: any,
    chapterTopicsStudentProgressAnalytics?: any
  ) => {
    if (chapterFilterValues?.length === 1) {
      // When single chapter is Selected .
      setAnalyticsChartForChapters(false);

      const chaptersTopicsAnalyticsData = chapterTopicsStudentProgressAnalytics;
      const ANALYTICS_GRAPH_DATA = getAnalyticsChartConfig(
        chapterTopicsStudentProgressAnalytics?.length > 4
          ? 4
          : chapterTopicsStudentProgressAnalytics?.length
      );
      // let competency: any = chaptersTopicsAnalyticsData?.map(
      //   (item: any) => item?.competency
      // );
      let mastery: any = chaptersTopicsAnalyticsData?.map(
        (item: any) => item?.mastery
      );
      ANALYTICS_GRAPH_DATA.datasets[0].data = mastery;
      // ANALYTICS_GRAPH_DATA.datasets[1].data = competency;
      setChapterAnalyticsGraphData({
        labels: chaptersTopicsAnalyticsData?.map(
          (item: any) => item?.topic_name
        ),
        datasets: [...ANALYTICS_GRAPH_DATA?.datasets],
      });
    } else {
      setAnalyticsChartForChapters(true);

      const filteredChapterAnalyticsData =
        studentProgressAnalyticsChart &&
        studentProgressAnalyticsChart.filter((chapter: any) => {
          return chapterFilterValues?.find((element: any) => {
            return element.value === String(chapter?.chapter_id);
          });
        });
      filteredChapterAnalyticsData &&
        setChapterIdAndNameForAnalyticsChart(
          filteredChapterAnalyticsData.map((chapter: any) => {
            return {
              chapterName: chapter?.chapter_name,
              chapterId: chapter?.chapter_id,
            };
          })
        );
      const ANALYTICS_GRAPH_DATA = getAnalyticsChartConfig(
        studentProgressAnalyticsChart?.length > 4
          ? 4
          : studentProgressAnalyticsChart?.length
      );
      // let competency: any = filteredChapterAnalyticsData?.map(
      //   (item: any) => item.competency
      // );
      let mastery: any = filteredChapterAnalyticsData?.map(
        (item: any) => item.mastery
      );
      ANALYTICS_GRAPH_DATA.datasets[0].data = mastery;
      // ANALYTICS_GRAPH_DATA.datasets[1].data = competency;
      filteredChapterAnalyticsData &&
        setChapterAnalyticsGraphData({
          labels: filteredChapterAnalyticsData.map(
            (item: any) => item?.chapter_name
          ),
          datasets: [...ANALYTICS_GRAPH_DATA?.datasets],
        });
    }
  };

  const handleAnalyticsChartNavigation = (index: number) => {
    let path: any = routePaths.mentorChapterProgress;
    path = path.replace(":classId", `${classId}`);
    path = path.replace(
      ":chapterId",
      `${chapterNameAndIdForAnalyticsChart[index].chapterId}`
    );
    navigate(path);
  };
  //
  const fetchProgressAnalyticsChartData = async (classId: number) => {
    // Fetch every Chapters Progress Analytics Data
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.PROGRESS_ANALYTICS}/?class_id=${classId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      loaderStatus.setState({ show: false });
      const sortedStudentProgressData = sortStudentProgressData(
        res?.data?.data
      );
      setStudentProgressAnalyticsChart(sortedStudentProgressData);
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const fetchClassList = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: ApiEdpoints.CLASS_LIST,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      loaderStatus.setState({ show: false });
      setClassList(res?.data?.data);
      setDropdownData([
        { data: res?.data?.data, defaultLabel: "All Standards" },
        {
          data: [
            {
              name: "Division A",
              id: "A",
            },
          ],
          defaultLabel: "All Divisions",
        },
      ]);
      return res;
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const fetchTopicsProgressAnalyticsChartData = async (
    classId: any,
    chapterId: number,
    chapterOptions: any
  ) => {
    // Fetch each Chapters Topics Analytics Graph Data
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.PROGRESS_ANALYTICS}/?class_id=${classId}&chapter_id=${chapterId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      loaderStatus.setState({ show: false });
      setChapterTopicsStudentProgressAnalytics(res?.data?.data);
      handleFilteredChaptersAnalyticsData(chapterOptions, res?.data?.data);
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const filterDataBasedOnLevel = (item: any) => {
    const arr: any = [];
    massagedStudentsDetailsList &&
      massagedStudentsDetailsList.length > 0 &&
      massagedStudentsDetailsList.filter((x: any) => {
        if (item?.level === x?.level) {
          const massagedData = {
            name: x?.name,
            profileImage: x?.photo,
            level: x?.level,
            rank: x?.rollNumber,
            userInitials: getUsernameInitials(x?.name),
          };
          arr.push(massagedData);
        }
      });
    setStudentBylevelArr(arr);
  };

  useEffect(() => {
    const initalStudentData =
      massagedStudentsDetailsList &&
      massagedStudentsDetailsList.filter((x: any) => x.level === 1);
    massagedStudentsDetailsList && filterDataBasedOnLevel(initalStudentData[0]);
  }, [massagedStudentsDetailsList]);

  useEffect(() => {
    if (studentBylevelArr.length > 0) {
      studentBylevelArr.map((x: any) => {
        levelsData?.map((y: any) => {
          if (x?.level === y?.level) {
            setData({
              ...data,
              students: studentBylevelArr,
            });
          }
        });
      });
    } else if (studentBylevelArr.length === 0) {
      setData({
        ...data,
        students: [],
      });
    }
  }, [studentBylevelArr]);

  useEffect(() => {
    dropdownSelectedValueWithId?.defaultLabel?.includes("Division")
      ? setDivisionDropdown(dropdownSelectedValueWithId)
      : setStandardDropdown(dropdownSelectedValueWithId);
  }, [dropdownSelectedValueWithId]);

  //TODO: use this useEffect when there are multiple value coming in the dropdown as of now there is only one coming
  // useEffect(() => {
  // TODO: REMOVE 25 - only used for testing as we only have one data with if 26 which is working.
  // standardDropdown?.id && window.history.replaceState(null, "", `./${25}`);
  // standardDropdown?.id && setClassId(25);
  // }, [standardDropdown]);

  useEffect(() => {
    if (classId) {
      fetchChapterList(classId);
      fetchProgressAnalyticsChartData(classId);
      fetchChaptersToBeFocused(classId);
    }
  }, [classId]);

  useEffect(() => {
    detailedViewRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [detailedViewRef]);

  useEffect(() => {
    //Set Study Progress Chapter Data configuration for Analytics Chart
    handleFilteredChaptersAnalyticsData(chapterOptions);
  }, [chapterOptions, studentProgressAnalyticsChart]);

  useEffect(() => {
    setClassId(Number(params?.classId));
    fetchClassList();
  }, []);

  // useEffect(() => {
  // TODO:if needed to make api call for division change here
  //   if (standardDropdown) {
  // apicall for division
  //   }
  // }, [standardDropdown]);

  return (
    <div className={styles.classAnalyticsContainer} ref={detailedViewRef}>
      <div className={styles.headerContainer}>
        <GoBackPage heading="Student Details" />
        <div className={styles.dropdownContainer}>
          {dropdownData && dropdownData.length > 0 && (
            <>
              <Dropdown
                options={dropdownData[0]?.data?.map((x: any, ind: number) => ({
                  name: `Standard ${romanToNumeral(x?.class)}`,
                  id: x?.class_id,
                }))}
                defaultLabel="Standard 8"
                selectedValue="Standard 8"
                minWidth="177px"
                setDropdownSelectedValueWithId={setDropdownSelectedValueWithId}
              />
              <Dropdown
                options={dropdownData[1]?.data?.map((x: any, ind: number) => ({
                  name: x?.name,
                  id: x?.id,
                }))}
                defaultLabel="Division A"
                selectedValue="Division A"
                minWidth="177px"
                setDropdownSelectedValueWithId={setDropdownSelectedValueWithId}
              />
            </>
          )}
        </div>
      </div>
      <div className={styles.dataContainer}>
        <div className={styles.mainBodyheading}>
          A detailed view of your class’s performance
        </div>
        <div className={styles.mainBodyContainer}>
          <div
            className={
              !isSideNavOpen
                ? styles.classPerformanceContainer
                : styles.classPerformanceContainerSideNav
            }
          >
            <div className={styles.mainChartContainer}>
              <div className={styles.totalStudentsHeading}>
                Total Students{" "}
                {massagedStudentsDetailsList &&
                massagedStudentsDetailsList?.length < 10
                  ? `0${massagedStudentsDetailsList?.length}`
                  : massagedStudentsDetailsList?.length}
              </div>
              <div className={styles.lowerChartContainer}>
                <div
                  className={
                    !isSideNavOpen
                      ? styles.sliceChartContainer
                      : styles.sliceChartContainerSideNav
                  }
                >
                  {noOfStudentsByLevel &&
                    noOfStudentsByLevel.length > 0 &&
                    noOfStudentsByLevel.map((x: any, index: number) => {
                      return (
                        <div className={`levelValue${index + 1}`}>{x}</div>
                      );
                    })}
                  <div className={styles.yAxisHeading}>Study level</div>
                  {levelsData &&
                    Array.isArray(levelsData) &&
                    levelsData.map((item: any, index: number) => {
                      return (
                        <>
                          <div
                            className={`${styles.chartSlice} ${
                              styles["chartSlice" + (index + 1)]
                            }  
                      }`}
                          >
                            <div
                              onClick={() => {
                                setIndexLevel(index + 1);
                                setData(item);
                                filterDataBasedOnLevel(item);
                              }}
                            >
                              <div
                                className={styles.slice}
                                style={{
                                  boxShadow: `${
                                    indexLevel === index + 1
                                      ? "inset 2px 3px 10px rgba(0, 0, 0, 0.8)"
                                      : "none"
                                  }`,
                                }}
                              ></div>
                              <p
                                className={styles.levelText}
                              >{`L${item?.level}`}</p>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
                {showHover && (
                  <>
                    <div
                      className={`${styles.chartHoverSmallActive} ${
                        styles["chartHoverSmallActive" + indexLevel]
                      }`}
                    >
                      <img src={checkHover} alt="" />
                    </div>
                    <div className={styles.hoverContainerBorder}></div>
                    <div className={styles.hoverContainer}>
                      {data && data?.students?.length ? (
                        <>
                          <p className={styles.hoverHeading}>
                            L{`${data?.level}`} Students:{" "}
                            {data?.students?.length}
                          </p>
                          <div className={styles.studentListContainer}>
                            {data?.students.map((item: any) => (
                              <div className={styles.studentDetailsBox}>
                                {item?.profileImage ? (
                                  <img src={item?.profileImage} alt="" />
                                ) : (
                                  <div className={styles.userIconContainer}>
                                    <div className={styles.userInitials}>
                                      {item?.userInitials}
                                    </div>
                                  </div>
                                )}
                                <div className={styles.rightContainer}>
                                  <div className={styles.studentNameheading}>
                                    {item?.name}
                                  </div>
                                  <div className={styles.studentDetails}>
                                    {`${item?.rank} `}|{` L${item?.level}`}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={styles.noDataContainer}>
                            <div className={styles.imgFlexContainer}>
                              <div className={styles.imgContainer}>
                                <img src={noDataAvatar} alt="thinking" />
                              </div>
                              <div className={styles.studentAvailableText}>
                                {constants.NOSTUDENT_AVAILABLE}
                              </div>
                              <div
                                className={styles.studentAvailableDes}
                              >{`${constants.NOSTUDENT_DEC}  Level ${data?.level}`}</div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            {updatedChapterToBeFocused &&
              Array.isArray(updatedChapterToBeFocused) &&
              updatedChapterToBeFocused.length > 0 && (
                <div>
                  <div className={styles.heading}>
                    Focus on these chapters to improve their performance
                  </div>
                  <div className={styles.chapterContainer}>
                    {updatedChapterToBeFocused.map(
                      (updatedChapterToBeFocused) => (
                        <ChapterCard
                          chapter={updatedChapterToBeFocused}
                          icon={chapterImage}
                        />
                      )
                    )}
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className={styles.chartContainer}>
          {chapterAnalyticsGraphData &&
          chapterAnalyticsGraphData?.labels?.length > 0 ? (
            <div className={styles.chartBox}>
              <>
                <div className={styles.headerContainer}>
                  <div className={styles.chartHeading}>
                    {constants.STUDY_PROGRESS}
                  </div>
                  <MultiSelectDropdown
                    minWidth="257px"
                    defaultLabel="All Chapters"
                    options={chapterOptions}
                    handleChange={handleChaptersChange}
                    allowSelectAll={true}
                  />
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  className={styles.studyProgressChart}
                >
                  <AnalyticsChart
                    height={296}
                    isClick={true}
                    onclickfunc={handleAnalyticsChartNavigation}
                    analyticsData={chapterAnalyticsGraphData}
                    axisLabels={{
                      xAxis: isAnalyticsChartForChapters
                        ? constants.CHAPTERS
                        : constants.TOPICS,
                      yAxis: "Levels",
                    }}
                  />
                </div>
              </>
            </div>
          ) : (
            ""
          )}
          <div className={styles.chartBox}>
            <div className={styles.headerContainerPerformance}>
              <div className={styles.chartHeading}>Performance</div>
            </div>
            <ClassPerformanceChart
              data={constants.studentPerformanceData}
              isLinePlot={true}
              showDropDownFilter={true}
              chartFilterWidth={"140px"}
              chartFilterOptions={chartDatesOptions}
              chartFilterDefaultLabel={"Select date"}
              filteredSelectedValues={updatedFilteredValues}
              comparisonsInX_Axis={6}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassAnalyticsPage;
