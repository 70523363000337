import React, { useContext, useEffect, useState } from "react";
import styles from "./superAdminSchool.module.scss";
import Dropdown from "../../../common/dropdown/dropdown";
import ClassesBlock from "../../../common/admin/classesBlock/classesBlock";
import ClassesBlockList from "../../../common/admin/classesBlockList/classesBlockList";
import { HTTPMethods, makeAxiosRequest } from "../../../utils/httpService";
import StatusContext from "../../../context/loaderContext";
const SuperAdminSchoolTab = () => {
  const loaderStatus: any = useContext(StatusContext);
  const [stateList, setStateList] = useState<any>([
    { name: "Select State", id: "" },
  ]);
  const [cityList, setCityList] = useState<any>([
    { name: "Select City", id: "" },
  ]);
  const [schoolList, setSchollList] = useState<any>([
    { name: "Select School", id: "" },
  ]);
  const [activeSchoolInfo, setActiveSchoolInfo] = useState({
    name: "",
    admin: "",
    state: "",
    city: "",
    data: [],
  });

  const getSchoolData = async (type: any, count?: any) => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: "super-admin/" + type + "/",
      method: HTTPMethods.GET,
      headers: {},
    };
    if (count) {
      payload.url = payload.url + count + "/";
    }

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        if (type == "states" && data.length) {
          setStateList(data);
        }
        if (type == "cities_in_state") {
          const citiiesInState = data.map((item: any) => {
            return { name: item.name, id: item.id };
          });
          setCityList(citiiesInState);
        }
      }
      if (type == "schools_in_city") {
        const scList = data.map((sc: any) => {
          return {
            name: sc.school_name,
            id: sc.id,
          };
        });
        if (scList.length) {
          setSchollList(scList);
        }
      }
      if (type == "school_classes_info") {
        const scData = data.map((item: any) => {
          return {
            class: item.class_name,
            totalStudent: item.student_count,
            sections: item.section,
          };
        });
        setActiveSchoolInfo((state) => {
          return {
            ...state,
            data: scData,
          };
        });
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });

      console.log(error);
    }
  };

  useEffect(() => {
    getSchoolData("states");
  }, []);

  return (
    <div className={styles.schoolTab}>
      <div className={styles.schoolTabDropdowns}>
        <Dropdown
          options={stateList}
          defaultLabel={"Select A Class"}
          minWidth={"225px"}
          overlayWidth={"230px"}
          id={"selectClass"}
          selectedValue={(item: string) => console.log("Test2")}
          setDropdownSelectedValueWithId={(item: any) => {
            getSchoolData("cities_in_state", item.id);
            setActiveSchoolInfo((state) => {
              return {
                ...state,
                state: item.name,
              };
            });
          }}
          isMenuItemTypeText={true}
        />
        <Dropdown
          options={cityList}
          defaultLabel={"Select A Class"}
          minWidth={"225px"}
          overlayWidth={"230px"}
          id={"selectClass"}
          selectedValue={(item: string) => console.log("Test2")}
          setDropdownSelectedValueWithId={(item: any) => {
            getSchoolData("schools_in_city", item.id);
            setActiveSchoolInfo((state) => {
              return {
                ...state,
                city: item.name,
              };
            });
          }}
          disabled={!(cityList.filter((item: any) => item.id).length > 0)}
          isMenuItemTypeText={true}
        />
        <Dropdown
          options={schoolList}
          defaultLabel={"Select A Class"}
          minWidth={"225px"}
          overlayWidth={"230px"}
          id={"selectClass"}
          selectedValue={(item: string) => console.log("Test2")}
          setDropdownSelectedValueWithId={(item: any) => {
            getSchoolData("school_classes_info", item.id);
            setActiveSchoolInfo((state) => {
              return {
                ...state,
                name: item.name,
              };
            });
          }}
          disabled={!(schoolList.filter((item: any) => item.id).length > 0)}
          isMenuItemTypeText={true}
        />
      </div>
      <div className={styles.schoolClassList}>
        {activeSchoolInfo.name ? (
          <>
            <h2>{activeSchoolInfo.name}</h2>
            {/* <p>School Admin : Chandra Kurhade</p> */}
            <p>
              {activeSchoolInfo.city}, {activeSchoolInfo.state}
            </p>
            <div className={styles.schoolClassListGrid}>
              {activeSchoolInfo.data.map((item: any) => {
                return (
                  <div key={item?.class}>
                    <ClassesBlockList listData={item} />
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <p style={{ textAlign: "center" }}>Select a School</p>
          </>
        )}
      </div>
    </div>
  );
};

export default SuperAdminSchoolTab;
