import React from 'react'
import styles from './questionViewAll.module.scss'
import arrowGreen from "../../../../../../assets/images/arrowGreen.svg";
import { QuestionAndAnswerPopUp } from '../../../../components/questionAndAnswerPopup/questionAndAnswerPopup';
import { constants } from '../../../../../../constants/constants';
import SubmitQuestionAndAnswerPopup from '../../../../components/questionAndAnswerPopup/submitQuestionAndAnswerPopup';
interface IQuestionViewAllProps {
    question: any,
    setQuestionAnswerPopup: any,
    questionAnswerPopup: any,
    activeQuestionIndex: number
}


export const QuestionViewAll = (props: IQuestionViewAllProps) => {
    const { question, setQuestionAnswerPopup, questionAnswerPopup, activeQuestionIndex } = props;

    return (
        <>
            <div className={styles.headerQuestionViewAll}>
                <div className={styles.questionText}>Questions</div>
                <div
                    onClick={() => setQuestionAnswerPopup(!questionAnswerPopup)}
                    className={styles.viewAll}>{constants.VIEW_ALL}<span >
                        <img className={styles.greenArrow} src={arrowGreen} alt='arrow' />
                    </span>
                </div>
                {
                    questionAnswerPopup && <QuestionAndAnswerPopUp title={"All Questions"} closePopUp={() => setQuestionAnswerPopup(!questionAnswerPopup)}
                        content={question} activeQuestionIndex={activeQuestionIndex} />
                }
            </div></>
    )
}
