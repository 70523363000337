import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { constants } from "../../../../../constants/constants";
import styles from "./chapterDetailsTopics.module.scss";
import topicIcon from "../../../../../assets/images/chapterDetailsTopicIcon.svg";
import topicPPTIcon from "../../../../../assets/images/ppt.svg";
import topicVideoIcon from "../../../../../assets/images/bigppt.svg";
import topicPatternIcon from "../../../../../assets/images/pattern.svg";
import assignmentIcon from "../../../../../assets/images/assignmentIcon.svg";
import questionsIcon from "../../../../../assets/images/mcqs.svg";
import Progressbar from "../../../../../common/progressbar/progressbar";
import { CustomPagination } from "../../../../../common/pagination/customPagination";
import assignment from "../../../../../assets/images/assignments.svg";
import markBlue from "../../../../../assets/images/marks.svg";
import timerBlue from "../../../../../assets/images/timer.svg";
import questionsIconBlue from "../../../../../assets/images/questionsIcon.svg";
import { routePaths } from "../../../../../constants/routes";
import SearchBar from "../../../../../common/searchBar/searchBar";
import { useDispatch, useSelector } from "react-redux";
import { ModalWrapperResult } from "../../../../../common/modalWrapper/modalWrapperResultAssignment/modalWrapperResult";
// import assignmentBlue from "../../../../../assets/images/assignmentsBlue.svg";
import submitedByIcon from "../../../../../assets/images/submitedByIcon.svg";
import { useSearch } from "../../../../../utils/searchBar";
import { SolveAssignmentQuestionListViewAll } from "../../../pages/solveAssignmentPage/solveAssignmentMetaData/solveAssignmentQuestionPopUp/questionListViewAll/solveAssignmentQuestionListViewAll";
import { useParams } from "react-router-dom";
import { Dialog, Switch } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
// import ModalBg from "../../../../assets/images/aiSupportModalBg.svg"
// import MascotRocket from "../../../../assets/images/mascotRocket.svg";
import ModalBg from "../../../../../assets/images/aiSupportModalBg.svg";
import MascotRocket from "../../../../../assets/images/mascotRocket.svg";
import styles2 from "./../../../../../common/cards/practiceTestChapterCard.module.scss";
import SolveExerciseButton from "../../../../../common/solveExerciseButton/solveExerciseButton";

const Topic = ({
  id,
  name,
  PPT,
  video,
  MCQ,
  Pattern,
  suggestion,
  type,
  handleOverlays,
  chapterNumber,
  topicId,
  topicSerialNumber,
  topic,
  assignmentLevelProp,
  progress,
  questionCount,
}: any) => {
  //! Assignment is id = 5 for ui
  const navigate = useNavigate();
  const params = useParams();
  const watchVideoOrPattern = (
    selectedType: string,
    id: number,
    event: any
  ) => {
    event.stopPropagation();
    const videoRoute = routePaths.learnVideoPlayer.replace(
      ":topicId",
      String(id)
    );
    const patternRoute = routePaths.patternByTopic.replace(
      ":topicId",
      String(id)
    );
    navigate(selectedType === constants.VIDEO ? videoRoute : patternRoute);
  };
  const [checkAiSupportModal, setCheckAiSupportModal] = useState(false);
  const [checkAiSupport, setCheckAiSupport] = useState(false);
  //!assignment Level
  const [showAssignmentLevelPopUp, setShowAssignmentLevelPopUp] =
    useState(false);
  const [assignmentLevel, setAssignmentLevel] = useState(
    assignmentLevelProp || 1
  );
  const [showTopicAssignmentPopUp, setShowTopicAssignmentPopUp] =
    useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <div
        className={styles.topicContainer}
        onClick={(event) => {
          if (type === "assignment") {
            // setAssignmentLevel(assignmentLevelProp);
            dispatch({
              type: "SET_ASSIGNMENT_LEVEL",
              payload: assignmentLevel,
            });
            // // setTimeout(() => {
            // //   setShowAssignmentLevelPopUp(true);
            // // }, 100);
          } else {
            watchVideoOrPattern(constants.VIDEO, topicId, event);
          }
        }}
      >
        <div className={styles.mainContainer}>
          <div
            className={`${styles.box} ${
              type === "assignment" ? styles.assignmentCard : ""
            }`}
          >
            <div className={styles.containerIcon}>
              <div className={styles.imageContainer}>
                <img
                  src={type ? assignment : topicIcon}
                  className={styles.imageSize}
                  alt="topicIcon"
                />
              </div>
              <div className={styles.topicText}>
                {constants.TOPIC} {chapterNumber}.{id}
              </div>
            </div>

            <div className={styles.containerDetails}>
              <div
                className={styles.topicTitle}
                title={
                  type === "assignment"
                    ? `${topic?.assignmentChapter} | ${topic?.name}`
                    : topic.name
                }
              >
                {type === "assignment"
                  ? `${topic?.assignmentChapter} | ${topic?.name}`
                  : topic.name}
              </div>
              {type !== "assignment" && (
                <div className={styles.progressbar}>
                  <Progressbar
                    progress={
                      topic.progress_stage ? topic.progress_stage * 20 : 0
                    }
                    noOflevels={5}
                    levelsVisible={false}
                  />
                </div>
              )}

              {type !== "assignment" && (
                <div className={styles.PPTVideoMCQPatternContainer}>
                  {PPT && (
                    <div
                      className={styles.PPT}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleOverlays("ppt", {
                          name: `${chapterNumber}.${id} ${name}`,
                          pptUrl: PPT,
                          topicSerialNumber: topicSerialNumber,
                        });
                      }}
                    >
                      <img src={topicPPTIcon} />
                      <div className={styles.PPTText}>{constants.PPT}</div>
                    </div>
                  )}
                  {video && (
                    <div className={styles.PPT}>
                      <img src={topicVideoIcon} />
                      <div
                        className={styles.videoText}
                        onClick={(event) =>
                          watchVideoOrPattern(constants.VIDEO, topicId, event)
                        }
                      >
                        {constants.VIDEO}
                      </div>
                    </div>
                  )}
                  {MCQ && (
                    <div
                      className={styles.PPT}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleOverlays("mcq", {
                          name,
                          id: topicId,
                          topicSerialNumber: topicSerialNumber,
                        });
                      }}
                    >
                      <img src={questionsIcon} alt="questionsIcon" />
                      <div className={styles.MCQText}>{constants.MCQ}</div>
                    </div>
                  )}
                  {/* {Pattern && (
                    <div className={styles.PPT}>
                      <img src={topicPatternIcon} />
                      <div
                        className={styles.PatternText}
                        onClick={(event) =>
                          watchVideoOrPattern(
                            constants.TOPIC_PATTERN,
                            topicId,
                            event
                          )
                        }
                      >
                        {constants.TOPIC_PATTERN}
                      </div>
                    </div>
                  )} */}
                  <div
                    className={styles.PPT}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <SolveExerciseButton
                      level={assignmentLevel}
                      id={topicId}
                      status={"pending"}
                    >
                      <>
                        <img src={topicPatternIcon} />
                        <div className={styles.MCQText}>
                          {constants.EXERCISE}
                        </div>
                      </>
                    </SolveExerciseButton>
                  </div>
                </div>
              )}

              {type === "assignment" && (
                <div className={styles.PPTItemAssignmentCardWrap}>
                  {/* {PPT && ( */}
                  <div className={styles.PPTAssignmentIconAndTxt}>
                    <img src={questionsIconBlue} />
                    <div className={styles.PPTTextAssignment}>
                      {`${questionCount} Questions`}
                    </div>
                  </div>
                  {/* )} */}
                  {topic?.assignmentAttemptDate && (
                    <div className={styles.PPTAssignmentIconAndTxt}>
                      <img src={submitedByIcon} />
                      <div className={styles.PPTTextAssignment}>
                        {`Last Attempted on ${topic?.assignmentAttemptDate}`}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {type === "assignment" && progress != 0 && (
            <div className={styles.progressContainer}>
              <div className={styles.progessBgm}>
                <div
                  className={`${styles.pendingProgressActive}`}
                  style={{ width: `${progress}px` }}
                ></div>
              </div>

              <div className={styles.progressText}>{`${progress}%`}</div>
            </div>
          )}

          {type === "assignment" && !progress && (
            <div className={styles.suggestionContainerMain}>
              <SolveExerciseButton
                level={assignmentLevel}
                id={topicId || params.chapterId}
                status={"pending"}
              >
                <button className={styles.assignmentSolveNow}>SOLVE NOW</button>
              </SolveExerciseButton>

              {/* {suggestion && (
              <div className={styles.suggestionContainer}>
                <div className={styles.suggestionTextKnowMoreContainer}>
                  <div className={styles.suggestionText}>
                    Use ‘Origin Number’ pattern to solve the pending assignment
                    and increase your chapter mastery by 15%
                  </div>
                  <div className={styles.knowMoreText}>
                    {constants.KNOW_MORE}
                  </div>
                </div>


              </div>
            )} */}
              {/* {
              suggestion && (
                <div className={styles.suggestionIcon}>
                  <img src={topicWritingAstronaut} className={styles.imageW} />

                </div>
              )
            } */}
            </div>
          )}
        </div>
      </div>

      {/* section for responsive design starts */}
      <div className={styles.topicContainerMobile}>
        <div className={styles.mainContainer}>
          <div className={styles.box}>
            <div className={styles.containerIcon}>
              <div className={styles.imageContainer}>
                <img
                  src={type ? assignment : topicIcon}
                  className={styles.imageSize}
                />
              </div>
              <div className={styles.topicText}>
                {/* {
                  id !== 4 ? `${constants.TOPIC} ${id}` : "Assignment Level 2"
                } */}
                {constants.TOPIC} {chapterNumber}.{id}
              </div>
            </div>
            <div className={styles.topicTitle}>{name}</div>
          </div>

          {/* {suggestion && (
            <div className={styles.suggestionContainer}>
              <div className={styles.suggestionText}>
                Use ‘Origin Number’ pattern to solve the pending assignment
                and increase your chapter mastery by 15%<span className={styles.knowMore}>
                  {constants.KNOW_MORE}
                </span>
              </div>




            </div>
          )} */}

          {type != "assignment" ? (
            <div className={styles.PPTVideoMCQPatternContainer}>
              {PPT && (
                <div className={styles.PPT}>
                  <img src={topicPPTIcon} />
                  <div className={styles.PPTText}>{constants.PPT}</div>
                </div>
              )}
              {video && (
                <div className={styles.PPT}>
                  <img src={topicVideoIcon} />
                  <div
                    className={styles.PPTText}
                    onClick={(event) =>
                      watchVideoOrPattern(constants.VIDEO, topicId, event)
                    }
                  >
                    {constants.VIDEO}
                  </div>
                </div>
              )}
              {MCQ && (
                <div className={styles.PPT}>
                  <img src={questionsIcon} />
                  <div className={styles.PPTText}>{constants.MCQ}</div>
                </div>
              )}
              {Pattern && (
                <div className={styles.PPT}>
                  <img src={topicPatternIcon} />
                  <div
                    className={styles.PPTText}
                    onClick={(event) =>
                      watchVideoOrPattern(
                        constants.TOPIC_PATTERN,
                        topicId,
                        event
                      )
                    }
                  >
                    {constants.TOPIC_PATTERN}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.PPTVideoMCQPatternContainerAssignment}>
              {PPT && (
                <div className={styles.PPTAssignment}>
                  <img src={questionsIconBlue} />
                  <div className={styles.PPTTextAssignment}>
                    {"15 Questions"}
                  </div>
                </div>
              )}
              {video && (
                <div className={styles.PPTAssignment}>
                  <img src={markBlue} />
                  <div className={styles.PPTTextAssignment}>{"50 Marks"}</div>
                </div>
              )}
              {MCQ && (
                <div className={styles.PPTAssignment}>
                  <img src={timerBlue} />
                  <div className={styles.PPTTextAssignment}>{"90 Min"}</div>
                </div>
              )}
            </div>
          )}
          {type == "assignment" ? (
            <div>
              <button
                className={styles.buttonStyle}
                onClick={(e) => {
                  // e.stopPropagation();
                  setShowAssignmentLevelPopUp(true);
                }}
              >
                SOLVE NOW
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Dialog
        open={checkAiSupportModal}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className={styles2.aiSupportModalWrapper}>
          <img src={ModalBg} alt="" />
          <div className={styles2.aiSupportModalContent}>
            <div className={styles2.aiSupportModalImage}>
              <img src={MascotRocket} alt="" />
            </div>
            <div className={styles2.aiSupportModalCheckbox}>
              <p>
                You are approaching the Genius Level! Time to master the
                concepts.
              </p>
              <div className={styles2.aiSupportToggle}>
                <p>
                  AI Support
                  <span>Toggle this for better support</span>
                </p>
                <Switch
                  checked={checkAiSupport}
                  onChange={() => {
                    setCheckAiSupport((state) => !state);
                  }}
                />
              </div>

              <button
                onClick={() => {
                  setShowTopicAssignmentPopUp(!showTopicAssignmentPopUp);
                  // setShowQuestionListPopUp(!showQuestionListPopUp);
                }}
              >
                SOLVE NOW
              </button>
            </div>
          </div>

          <button
            onClick={() => {
              setCheckAiSupportModal(false);
            }}
          >
            <CloseRounded />
          </button>
        </div>
      </Dialog>

      {showAssignmentLevelPopUp && (
        <SolveAssignmentQuestionListViewAll
          showTestOverlay={showAssignmentLevelPopUp}
          title={`Exercise ${assignmentLevel === 2 ? "Level 2" : "Level 3"} - ${
            topic.assignmentChapter
          } `}
          closePopUp={() =>
            setShowAssignmentLevelPopUp(!showAssignmentLevelPopUp)
          }
          assignmentLevel={assignmentLevel}
          topicIdProp={params.chapterId}
        />
      )}
      {showTopicAssignmentPopUp && (
        <SolveAssignmentQuestionListViewAll
          showTestOverlay={showTopicAssignmentPopUp}
          title={`Exercise ${chapterNumber}.${id}`}
          closePopUp={() => {
            setCheckAiSupportModal(true);
            setShowTopicAssignmentPopUp(!showTopicAssignmentPopUp);
          }}
          fromExercisePage={true}
          aiSupport={checkAiSupport}
          assignmentLevel={assignmentLevelProp}
          topicIdProp={topicId}
        />
      )}
    </>
  );
};

const ChapterDetailsTopics = ({
  topicsListData,
  handleOverlays,
  totalchapterDetails,
}: any) => {
  const [topicData, setTopicData] = useState([...topicsListData]);
  const chapterDetails = JSON.parse(JSON.stringify(totalchapterDetails));
  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationEnd, setPaginationEnd] = useState(10);
  const range = 10; //Number of Items displayed per page for Pagination

  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchedTopics, getSearchedData] = useSearch(topicData);

  useEffect(() => {
    getSearchedData(searchText, "name");
  }, [searchText]);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    const finalValue = Math.ceil(searchedTopics.length / range);
    if (finalValue === value) {
      setPaginationStart(range * (value - 1));
      setPaginationEnd(searchedTopics.length);
    } else {
      setPaginationStart(range * (value - 1));
      setPaginationEnd(range * value);
    }
  };
  const [isResultOverlay, setIsResultOverlay] = useState(false);
  const questionData = useSelector((state: any) => state.mcqQuestionReducer);

  useEffect(() => {
    if (questionData[0].open && !isResultOverlay) {
      setIsResultOverlay(true);
    }
  }, [questionData]);
  // const detailedViewRef = useRef(null);
  return (
    <>
      <div
        className={styles.chapterDetailsTopicsMainContainer}
        // ref={detailedViewRef}
      >
        <div className={styles.topicTitleSearchbarContainer}>
          <div className={styles.topicTitle}>{constants.TOPIC_TITLE}</div>

          <SearchBar searchText={searchText} setSearchText={setSearchText} />
        </div>

        <div className={styles.topicsContainer}>
          {searchedTopics?.length > 1 &&
            searchedTopics
              ?.slice(paginationStart, paginationEnd)
              .map(
                (topic: {
                  type: any;
                  id: any;
                  name: any;
                  ppt_url: any;
                  video_url: any;
                  topicNumber: number;
                  sl_no: number;
                  assignmentLevel?: number;
                  progress?: number;
                  questionCount?: number;
                }) => (
                  <Topic
                    key={`${topic.topicNumber} + ${topic.id}`}
                    id={topic.topicNumber}
                    name={topic.name}
                    PPT={topic.ppt_url}
                    video={topic.video_url}
                    MCQ={true}
                    Pattern={true}
                    suggestion={topic.topicNumber === 2 && true}
                    type={topic.type}
                    handleOverlays={handleOverlays}
                    chapterNumber={
                      chapterDetails?.chapter_data?.name?.split(".")[0]
                    }
                    assignmentLevelProp={topic?.assignmentLevel || 1}
                    topicId={topic.id}
                    topicSerialNumber={topic.sl_no}
                    topic={topic}
                    progress={topic.progress}
                    questionCount={topic.questionCount}
                  />
                )
              )}
        </div>
        {searchedTopics?.length === 0 && (
          <div className={styles.noDataFound}>{constants.NO_DATA_FOUND}</div>
        )}
        {searchedTopics?.length > range && (
          <CustomPagination
            // detailedViewRef={detailedViewRef}
            page={page}
            handlePaginationChage={handlePaginationChange}
            count={Math.ceil(searchedTopics?.length / range)}
          />
        )}
        {isResultOverlay && (
          <ModalWrapperResult
            openModal={isResultOverlay}
            setOpenModal={setIsResultOverlay}
            questionData={questionData}
            topicId={questionData[0].topicId}
          />
        )}
      </div>
    </>
  );
};

export default ChapterDetailsTopics;
