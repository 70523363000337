import React, { useState, useEffect, useContext } from "react";
import { constants } from "../../../constants/constants";
import styles from "./assignmentsList.module.scss";
import FilterBar from "../../../common/filterBar/filterBar";
import AssignmentCardItem from "./assignmentCardItem";
import { CustomPagination } from "../../../common/pagination/customPagination";
import SearchBar from "../../../common/searchBar/searchBar";
import useSorting from "../../../utils/useSorting";
import Dropdown from "../../../common/dropdown/dropdown";
import { formatDate } from "../../../utils/dateFormatter";
import useSortingByDate from "../../../utils/useSortingByDate";
import StatusContext from "../../../context/loaderContext";
import ExerciseCard from "../../../common/cards/exerciseCard";

interface IAssignmentsList {
  assignmentsData: object[];
  patternOptions: string[];
  detailedViewRef: any;
}
const AssignmentsList = ({
  assignmentsData,
  patternOptions,
  detailedViewRef,
}: IAssignmentsList) => {
  const [patternProps, setPatternProps] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const loaderStatus: any = useContext(StatusContext);

  const [chapterDropDownValueWithId, setChapterDropDownValueWithId] =
    useState<any>({});
  //using useeffect to update the data if not we're facing issue when we cleared catch
  function removeOutsideId(originalArray: any) {
    const newArray: any = [{ name: "All", id: 0 }];
    originalArray?.forEach((item: any, index: number) => {
      if (index === 0) return;
      const newItem = {
        name: item?.name?.name,
        id: item?.name?.id,
        chapterId: item?.name?.id,
      };
      newArray.push(newItem);
    });
    return newArray;
  }
  //1 CHECK
  const dataMassaging = (data: any) => {
    return data;
    const newArray: any = [];
    data?.forEach((item: any) => {
      item?.topicSubTopics?.forEach((subTopic: any) => {
        newArray.push({
          ...subTopic,
          lastAttempted: formatDate(subTopic?.last_attempt),
          chapterId: item?.id,
          level: 1,
          questionId: item?.id,
          status: subTopic?.progress < 100 ? "Pending" : "Completed",
        });
      });
      newArray.push({
        questionId: item?.id,
        name: item?.name?.replace(/^\d+\.\s/, ""),
        chapterIndex: item?.chapter_index,
        assignmentName: item?.level_2?.Assignment_name,
        lastAttempted: formatDate(item?.level_2?.last_attempted),
        progress: item?.level_2?.progress,
        assignmentLevel: 2,
        level: 2,
        numberOfQuestions: item?.level_2?.question_count,
        scoredMarks: item?.level_2?.scored_marks,
        assignmentTotalMarks: item?.level_2?.total_marks,
        status: item?.level_2?.progress < 100 ? "Pending" : "Completed",
      });
      newArray.push({
        questionId: item?.id,
        name: item?.name?.replace(/^\d+\.\s/, ""),
        chapterIndex: item?.chapter_index,
        assignmentName: item?.level_3?.Assignment_name,
        lastAttempted: formatDate(item?.level_3?.last_attempted),
        progress: item?.level_3?.progress,
        assignmentLevel: 3,
        level: 3,
        numberOfQuestions: item?.level_3?.question_count,
        scoredMarks: item?.level_3?.scored_marks,
        assignmentTotalMarks: item?.level_3?.total_marks,
        status: item?.level_3?.progress < 100 ? "Pending" : "Completed",
      });
    });
    return newArray;
  };
  const [searchText, setSearchText] = useState("");
  const [dropdownArray, setDropdownArray] = React.useState<any>([]);
  const {
    dropdownSelectedValueWithId,
    setDropdownSelectedValueWithId,
    filteredTopics,
    page,
    paginationStart,
    paginationEnd,
    range,
    handlePaginationChange,
  } = useSorting(
    assignmentsData,
    selectedStatus,
    searchText,
    "studentsMyAssignment", // search filter key
    dataMassaging,
    5, // range
    dropdownArray
  );

  useEffect(() => {
    if (dropdownSelectedValueWithId) {
      if (dropdownSelectedValueWithId?.defaultLabel === "All Questions") {
        setChapterDropDownValueWithId(dropdownSelectedValueWithId);
      }
    }
  }, [dropdownSelectedValueWithId]);

  useEffect(() => {
    let arr: any = [];
    if (patternProps && patternProps.length > 0) {
      patternProps.map((x: any) => {
        if (x?.defaultLabel === chapterDropDownValueWithId?.defaultLabel) {
          arr.push({ ...x, chapterDropDownValueWithId });
        }
      });
    }
    setDropdownArray(arr);
  }, [chapterDropDownValueWithId, patternOptions, patternProps]);
  const { sortedByDateArr, handleSorting } = useSortingByDate(filteredTopics);

  useEffect(() => {
    handleSorting("desc");
  }, [filteredTopics]);

  useEffect(() => {
    if (patternOptions && patternOptions.length > 0) {
      const abc = removeOutsideId(patternOptions);
      setPatternProps([{ data: abc, defaultLabel: "All Questions" }]);
    }
  }, [patternOptions]);

  return (
    <div className={styles.allAssignmentsMainContainer}>
      <div className={styles.assignmnetsTitleSearchbarContainer}>
        <div className={styles.assignmentHeaderTitle}>
          {constants.ALL_ASSIGNMNETS_TITLE}
        </div>
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
      </div>

      <div className={styles.separator}></div>
      <div className={styles.sortAndFilterContainer}>
        <div className={styles.filterMainContainer}>
          <div className={styles.statusContainer}>
            <FilterBar
              statuses={["All", "Pending", "Completed"]}
              selectedStatus={(selectedStatuses: string[]) =>
                setSelectedStatus(selectedStatuses)
              }
              showSorting={false}
              innerDropdownNeeded={true}
            />
          </div>
        </div>
        <div className={styles.filterMainSearchSort}>
          {patternProps && patternProps.length > 0 && (
            <div className={styles.chpatternsFilterContainer}>
              <Dropdown
                options={patternProps ? patternProps[0]?.data : []}
                defaultLabel={"All Questions"}
                minWidth={"255px"}
                selectedValue={(item: string) => console.log("")}
                setDropdownSelectedValueWithId={setDropdownSelectedValueWithId}
              />
            </div>
          )}
          <FilterBar
            showSorting={true}
            sortFunc={(sortType: string) => handleSorting(sortType)}
          />
        </div>
      </div>
      <div className={styles.assignmentsItemsContainer}>
        {sortedByDateArr.length > 0 &&
          sortedByDateArr
            ?.slice(paginationStart, paginationEnd)
            .map((item: any, ind: number) => (
              // <AssignmentCardItem item={item} key={`item_${item?.id}_${ind}`} />
              <ExerciseCard i={ind} data={item} />
            ))}
        {filteredTopics.length === 0 && loaderStatus.show === false && (
          <div className={styles.noResultFound}>{constants.NO_DATA_FOUND}</div>
        )}
      </div>

      {filteredTopics?.length > 0 && (
        <CustomPagination
          detailedViewRef={detailedViewRef}
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(filteredTopics?.length / range)}
        />
      )}
    </div>
  );
};

export default React.memo(AssignmentsList);
