import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styles from "./multiSelectDropdown.module.scss";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import checkedIcon from "../../assets/images/checkedIcon.svg";
import unCheckedIcon from "../../assets/images/unCheckedIcon.svg";

interface IMultiSelectDropdown {
  defaultLabel?: string;
  minWidth?: string;
  isDropdownOutlineRequired?: boolean;
  options: IOptions[];
  handleChange: any;
  allowSelectAll?: boolean;
}
interface IOptions {
  value: string;
  label: string;
  ele?: React.ReactNode;
}
const MultiSelectDropdown = ({
  defaultLabel,
  minWidth,
  isDropdownOutlineRequired = true,
  options = [],
  handleChange,
  allowSelectAll = false,
}: IMultiSelectDropdown) => {
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const selectAllKey = {
    value: "all",
    label: defaultLabel ? defaultLabel : "Select All",
  };

  const handleMultiSelect = (event: any) => {
    const value: any = [...event.target.value];
    if (
      value[value.length - 1].value === selectAllKey.value &&
      allowSelectAll
    ) {
      if (value.length !== 1) {
        const allOPtionsData =
          selectedValues.length === options?.length ? [options[0]] : options;
        setSelectedValues(allOPtionsData);
        handleChange(allOPtionsData);
        return;
      } else {
        const allOPtionsData =
          selectedValues.length === options?.length ? [] : options;
        setSelectedValues(allOPtionsData);
        handleChange(allOPtionsData);
        return;
      }
    }
    if (selectedValues?.length > 0) {
      let index = selectedValues?.findIndex(
        (x: any) => x.value === value[value.length - 1].value
      );

      let updatedValues = [...value];
      if (index !== -1) {
        updatedValues = value.filter(
          (x: any) => x.value !== value[value.length - 1].value
        );
      }

      setSelectedValues(updatedValues);
      handleChange(updatedValues);
    } else {
      setSelectedValues(value);
      handleChange(value);
    }
  };

  useEffect(() => {
    if (allowSelectAll) {
      setSelectedValues(options);
    }
  }, [options]);

  return (
    <FormControl
      className={styles.formControl}
      style={{
        minWidth: `${minWidth}`,
      }}
    >
      <Select
        style={{
          minWidth: `${minWidth}`,
          background:
            "linear-gradient(314.24deg, #F8FAFF 22.53%, #EBF1F4 88.9%)",
        }}
        multiple
        value={selectedValues}
        native={false}
        displayEmpty={true}
        onChange={(e) => handleMultiSelect(e)}
        renderValue={(value) => {
          const slicedSelectedName =
            value[0]?.label?.length > 15
              ? `${value[0]?.label?.slice(0, 15)}...`
              : value[0]?.label;
          let trimmedSelectedName = slicedSelectedName;
          if (value[0]?.value != "all") {
            trimmedSelectedName = `${slicedSelectedName} ${
              value?.length - 1 > 0 ? `+ ${value?.length - 1}` : ""
            }`;
          }
          return value?.length > 0 && value
            ? trimmedSelectedName
            : defaultLabel
            ? defaultLabel
            : "select option";
        }}
        MenuProps={
          {
            PaperProps: {
              style: {
                maxHeight: "400px",
                background:
                  "linear-gradient(314.24deg, #F8FAFF 22.53%, #EBF1F4 88.9%)",
                paddingTop: 0,
              } as React.CSSProperties,
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: { vertical: "top", horizontal: "center" },
          } as any
        }
        sx={
          isDropdownOutlineRequired
            ? {
                "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "8px 15px",
                    color: "#4B5468",
                    fontFamily: "Inter-Regular",
                    fontSize: "14px",
                    lineHeight: "17px",
                    display: "flex",
                    alignItems: "center",
                  },
                "& .MuiList-root": {
                  paddingTop: 0,
                },
                ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
                  {
                    minHeight: "1.76em",
                  },
              }
            : {
                "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "8px 15px",
                    color: "#4B5468",
                    fontFamily: "Inter-Regular",
                    fontSize: "14px",
                    lineHeight: "17px",
                    display: "flex",
                    alignItems: "center",
                  },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderStyle: "none",
                  borderWidth: "0px",
                },
                "& .MuiList-root": {
                  paddingTop: 0,
                },
              }
        }
      >
        {allowSelectAll && (
          <MenuItem
            key={selectAllKey.value}
            value={JSON.parse(JSON.stringify(selectAllKey))}
            style={{
              width: `${minWidth}`,
              padding: "10px 22px",
              marginTop: "7px",
              alignItems: "flex-start",
              gap: "8px",
            }}
            sx={{
              "& .MuiCheckbox-root": {
                padding: 0,
              },
            }}
          >
            <Checkbox
              checked={
                options?.length > 0 && selectedValues.length === options?.length
              }
              checkedIcon={<img src={checkedIcon} />}
              icon={<img src={unCheckedIcon} />}
              disabled={true}
            />
            <ListItemText
              primary={<span>{selectAllKey.label}</span>}
              primaryTypographyProps={{
                style: {
                  whiteSpace: "normal",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#4B5468",
                  fontFamily: "Inter-Regular",
                },
              }}
            />
          </MenuItem>
        )}
        {options?.map((item: IOptions, ind: number) => (
          <MenuItem
            disabled={
              selectedValues.length === 1 &&
              selectedValues[0].value == item.value
            }
            key={`${item.value}_${ind}`}
            value={JSON.parse(JSON.stringify(item))}
            style={{
              width: `${minWidth}`,
              padding: "10px 22px",
              marginTop: "14px",
              gap: "8px",
              alignItems: "flex-start",
            }}
            sx={{
              "& .MuiCheckbox-root": {
                padding: 0,
              },
            }}
          >
            <Checkbox
              checked={
                item.value === "all"
                  ? options?.length > 0 &&
                    selectedValues.length === options?.length
                  : selectedValues?.length > 0 &&
                    selectedValues.findIndex(
                      (x: IOptions) => x.value === item.value
                    ) !== -1
              }
              checkedIcon={<img src={checkedIcon} />}
              icon={<img src={unCheckedIcon} />}
            />
            <ListItemText
              primary={item.ele ?? item.label}
              primaryTypographyProps={{
                style: {
                  whiteSpace: "normal",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#4B5468",
                  fontFamily: "Inter-Regular",
                },
              }}
            />
          </MenuItem>
        ))}

        {options?.length === 0 && (
          <MenuItem
            key={""}
            value={""}
            style={{
              width: `${minWidth}`,
              padding: "10px 22px",
              marginTop: "7px",
              alignItems: "flex-start",
              gap: "8px",
            }}
            sx={{
              "& .MuiCheckbox-root": {
                padding: 0,
              },
            }}
          >
            <ListItemText
              primary={"No Options found"}
              primaryTypographyProps={{
                style: {
                  whiteSpace: "normal",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#4B5468",
                  fontFamily: "Inter-Regular",
                },
              }}
            />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default React.memo(MultiSelectDropdown);
