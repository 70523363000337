import React from "react";
import { dummyPatternData } from "../../../../constants/learnConstants";
import PatternCard from "./patternCard/patternCard";
import styles from "./patternListing.module.scss";

interface IPatternListing {
  patternList: object[];
  isChapterPatternList?: boolean;
  handlePatternVideoOverlay?: any;
  fromVideoPage?: boolean;
  getOverlayDetails?: any;
  getPatternDetailsId: any;
  fromPatternListPage?: boolean;
}

const PatternListing = ({
  patternList,
  handlePatternVideoOverlay,
  fromVideoPage,
  getOverlayDetails,
  fromPatternListPage = false
}: IPatternListing) => {
  return (
    <div className={styles.patternContainer}>
      {/* {isChapterPatternList ?
        <div className={styles.patternHeaderContainer}>
          <div className={styles.patternHeaderText}>
            Here are the key patterns for you to master this chapter
          </div>
          <div className={styles.mindMap}>
            <button className={styles.mindMapButton}>VIEW MIND MAP</button>
          </div>
        </div> :
        <div className={`${styles.topicListHeader} ${styles.inter20}`}>
          Here some patterns used in this topic
        </div>
      } */}
      <div className={!fromPatternListPage ? `${styles.patternCardContainer}` : `${styles.patternCardContainer} ${styles.patternListingPageStyles}`}>
        {patternList.map((item: any, index: number) => {
          return (
            <PatternCard
              patternItem={item}
              key={index}
              fromComponent={"patternListing"}
              handlePatternVideoOverlay={handlePatternVideoOverlay}
              fromVideoPage={fromVideoPage}
              getOverlayDetails={getOverlayDetails}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PatternListing;
