import React, { useEffect } from 'react'
import styles from './submitButtonWrapper.module.scss'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

interface ISubmitButtonWrapperProps {
    question: any,
    saveQuestion: any,
    submitButtonEnables?: any,
    navigateUrl: any,
    activeQuestionIndex?: any
    activeAssignmentType?: any
}
export const SubmitButtonWrapper = (
    props: ISubmitButtonWrapperProps
) => {
    const { saveQuestion, submitButtonEnables, question, navigateUrl, activeQuestionIndex, activeAssignmentType } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <div className={
            ((activeQuestionIndex !== question.length - 1) || (activeAssignmentType === "assignment" ? (question[activeQuestionIndex]?.selectedAnswer !== "") : (question[activeQuestionIndex]?.selectedAnswer == ""))
            )
                ? styles.submitButtonContainerDisabled : styles.submitButtonContainerEnable} >
            <button
                onClick={() => {
                    setTimeout(() => {
                        if (activeAssignmentType === "test") {
                            navigate(-1);
                        }
                    }, 2000);
                }}
                disabled={(activeQuestionIndex !== question.length - 1) ||
                    (activeAssignmentType === "assignment" ? (question[activeQuestionIndex]?.selectedAnswer !== "") : (question[activeQuestionIndex]?.selectedAnswer == ""))
                }
            >Submit</button>
        </div >
    )
}

