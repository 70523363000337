import { useState, useEffect, useContext } from "react";
import { makeAxiosRequest } from "aie_common";
import { useNavigate } from "react-router";
import backArrow from "../../../../assets/images/backArrowIcon.svg";
import contentIcon from "../../../../assets/images/contentIcon.svg";
import nextContentIcon from "../../../../assets/images/nextContentIcon.svg";
import prevTopic from "../../../../assets/images/prevTopicIcon.svg";
import ApiEdpoints, {
  HTTPMethods,
  queryConstants,
} from "../../../../constants/api_endpoints";
import TopicVideoPageContents from "./topicVideoPageContents";
import { routePaths } from "../../../../constants/routes";
import SideBar from "../../../../common/sidebar/sidebar";
import VideoNotesList from "../../components/videoNotesList/videoNotesList";
import StatusContext from "../../../../context/loaderContext";
import classes from "./topicVideoPage.module.scss";
import TopicVideoPageDescription from "./topicVideoPageDescription";
import PatternVideoOverlayContainer from "../../../../common/patternVideoOverlayContainer/patternVideoOverlayContainer";
import useOverlayDetails from "../../../../utils/patternDetailsApi";
import { ModalWrapperResult } from "../../../../common/modalWrapper/modalWrapperResultAssignment/modalWrapperResult";
import { SolveAssignmentQuestionListViewAll } from "../solveAssignmentPage/solveAssignmentMetaData/solveAssignmentQuestionPopUp/questionListViewAll/solveAssignmentQuestionListViewAll";
import { TestStartOverlay } from "../../../../common/testStartOverlay/testStartOverlay";
import { useSelector } from "react-redux";

export interface ISubTopicsListData {
  id: number;
  topicName: string;
  save: boolean;
  video_url: IVideoUrl[];
  video_or_text: string;
  courseid: number;
  topicid: number;
  ppt_url: IPptUrl;
  sl_no: number;
  name: string;
  details: any;
  likes_count: number;
  courseList: ICourseList;
  nextSubtopicId: number;
  UserEnrolledOrNot: boolean;
  mcqsPresent: boolean;
}

interface IVideoUrl {
  video_name: string;
}

interface IPptUrl {
  document: string;
  doc_type: string;
}

interface ICourseList {
  courseDetails: ICourseDetails;
  topics: ITopic[];
}

interface ICourseDetails {
  id: number;
  name: string;
  image_url: string;
  freeVideoID: any[];
  courseThumbnailId: any;
  CertificateorThumbnailID: any;
  lastActivity: ILastActivity;
  TotalCompletedList: ITotalCompletedList;
  courseProgress: number;
}

interface ILastActivity {
  status: string;
  description: string;
}

interface ITotalCompletedList {
  status: string;
  description: string;
}

interface ITopic {
  id: number;
  SubTopicslist: ISubTopicslist[];
  sl_no: number;
  name: string;
}

interface ISubTopicslist {
  id: number;
  sl_no: number;
  name: string;
}

const TopicVideoPage = () => {
  const [videoNotesOverlay, setVideoNotesOverlay] = useState(false);
  const [videoNotesOverlayContent, setVideoNotesOverlayContent] =
    useState("pattern");
  const { patternOverlayData, getOverlayDetails }: any = useOverlayDetails();
  const navigate = useNavigate();
  const [showOrHideContentsToggle, setShowOrHideContentsToggle] =
    useState(false);
  const [subTopicsListData, setSubTopicsListData] =
    useState<ISubTopicsListData>();
  const [patternsListTData, setPatternsListTData] = useState();
  const [showOrHideNotesToggle, setShowOrHideNotesToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  const loaderStatus: any = useContext(StatusContext);
  const paths = window.location?.pathname.split("/");
  const topicId = paths[paths?.length - 1];
  const [topicVideoId, setTopicVideoId] = useState<number>(+topicId || 0);
  const [chapterDetailData, setChapterDetailData] = useState<ITopic>();
  const [assignmentOverlayData, setAssignmentOverlayData] = useState({
    name: "",
    id: 0,
    index: "",
    topicSerialNumber: "",
  });
  const [testOverlayType, setTestOverlayType] = useState<"assignment" | "mcq">(
    "assignment"
  );
  const [showTestOverlay, setShowTestOverlay] = useState(false);
  const [showResultOverlay, setShowResultOverlay] = useState(false);
  const [isResultOverlay, setIsResultOverlay] = useState(false);
  const questionData = useSelector((state: any) => state.mcqQuestionReducer);
  useEffect(() => {
    if (questionData[0].open && !isResultOverlay) {
      setIsResultOverlay(true);
    }
  }, [questionData]);

  const getCoursesListBySubTopic = async () => {
    const subTopicsPayload = {
      url: `${ApiEdpoints.COURSELIST_BY_SUBTOPIC_ID}/?subtopicid=${topicVideoId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    const patternsPayload = {
      url: `${ApiEdpoints.FETCHING_PATTERNS}/?topic_id=${topicVideoId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      if (topicVideoId) {
        const subTopicResponse: any = await makeAxiosRequest(subTopicsPayload);
        const patternsListResponse: any = await makeAxiosRequest(
          patternsPayload
        );

        if (
          subTopicResponse &&
          subTopicResponse.status === 200 &&
          subTopicResponse?.data?.status === "success"
        ) {
          const subTopicList = subTopicResponse?.data?.data;
          setSubTopicsListData(subTopicList);

          //make api call and get videos data in a particular chapter for contents page
          getChapterTopics(subTopicList);
          // const chapterData = subTopicList?.courseList?.topics?.find(
          //   (x: ITopic) => x?.id === subTopicList?.topicid
          // );
          // // setChapterDetailData(chapterData);
        }
        if (
          patternsListResponse &&
          patternsListResponse.status === 200 &&
          patternsListResponse?.data?.status === "success"
        ) {
          setPatternsListTData(patternsListResponse?.data);
        }
        if (
          subTopicResponse &&
          subTopicResponse.status === 200 &&
          patternsListResponse &&
          patternsListResponse.status === 200
        ) {
          setLoading(false);
        } else setLoading(true);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    loaderStatus.setState({ show: loading });
  }, [loading]);

  const getChapterTopics = async (subTopicList: any) => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.CHAPTER_TOPICS_OVERVIEW}/?${queryConstants.CHAPTER_ID}=${subTopicList?.topicid}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const topicsResponse: any = await makeAxiosRequest(payload);
      if (topicsResponse && topicsResponse?.status === 200) {
        const topics_data = topicsResponse?.data?.topics_data || [];
        const chapterData = subTopicList?.courseList?.topics?.find(
          (x: ITopic) => x?.id === subTopicList?.topicid
        );

        chapterData?.SubTopicslist?.forEach((x: any, ind: number) => {
          const index = topics_data.findIndex((y: any) => y?.id === x?.id);
          chapterData.SubTopicslist[ind].subtopicStatus =
            topics_data[index]?.subtopicStatus || false;
        });

        setChapterDetailData(chapterData);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const changeVideoTopic = (event: any, changeType: string) => {
    const chapterId = subTopicsListData?.topicid;
    const chapterData = subTopicsListData?.courseList?.topics?.find(
      (x) => x?.id === chapterId
    );
    const currentVideoIndex: number | any =
      chapterData?.SubTopicslist?.findIndex((x) => x?.id === +topicVideoId);
    const newTopicVideoId: number | any =
      chapterData?.SubTopicslist[
        changeType === "next" ? currentVideoIndex + 1 : currentVideoIndex - 1
      ]?.id || 0;
    setTopicVideoId(newTopicVideoId);
    const routePath = routePaths.learnVideoPlayer?.replace(
      ":topicId",
      newTopicVideoId || 0
    );
    navigate(routePath);
    event.stopPropagation();
  };
  const showOrHideContentsToggleFn = () => {
    setShowOrHideContentsToggle(false);
  };
  const getSubTopicId = (id: number) => {
    setTopicVideoId(id);
  };
  const backArrowClick = () => {
    if (isResultOverlay) {
      navigate(routePaths.learn);
    } else navigate(-1);
  };
  useEffect(() => {
    getCoursesListBySubTopic();
  }, [topicVideoId]);

  const handlePatternVideoOverlay = (type: any) => {
    if (document.querySelector("video")) {
      document.querySelector("video")?.pause();
    }
    setVideoNotesOverlay(!videoNotesOverlay);
    setVideoNotesOverlayContent("pattern");
  };

  const handlePatternVideoOverlayContent = (
    resource: "viewDocument" | "pattern" | "patternMap"
  ) => {
    setVideoNotesOverlayContent(resource);
  };

  const handleMCQAssignmentOverlays = (type: string, item: any) => {
    if (type === "mcq") {
      setAssignmentOverlayData({
        name: item?.name,
        id: item?.id,
        index: item?.topicid,
        topicSerialNumber: item?.topicSerialNumber,
      });
      setShowTestOverlay(true);
      setTestOverlayType(type);
    } else if (type === "assignment") {
      setAssignmentOverlayData({
        name: item?.name,
        id: item?.id,
        index: item?.topicid,
        topicSerialNumber: item?.topicSerialNumber,
      });
      setShowResultOverlay(true);
      setTestOverlayType(type);
    } else {
      return;
    }
  };

  const handleMCQAssignmentOverlayClose = (type?: string) => {
    if (type === "mcq" || type === "assigment") setShowTestOverlay(false);
    else {
      return;
    }
  };
  const getTopicIndex = () => {
    let currentTopicIndex = chapterDetailData?.SubTopicslist.findIndex(
      (ele: any) => {
        return ele.id === subTopicsListData?.id;
      }
    );
    return currentTopicIndex ? currentTopicIndex + 1 : 1;
  };
  return (
    <div className={classes.topicVideoPageWrapper}>
      {/* Nav section */}

      <div className={classes.navSection} onClick={backArrowClick}>
        <div className={classes.arrowIcon}>
          <img src={backArrow} alt="back" />
        </div>
        <div className={`${classes.inter16} ${classes.navBackText}`}>
          {subTopicsListData?.topicName || ""}
        </div>
      </div>

      {/* Nav section */}

      {/* body section */}

      <div className={classes.bodyWrapper}>
        <div className={classes.topicVideoSectionHeader}>
          {subTopicsListData && (
            <div className={classes.videoDetailsSectionHeader}>
              <div className={classes.videoSectionNameAndBtns}>
                <div
                  className={`${classes.videoSectionName} ${classes.inter24}`}
                >
                  {subTopicsListData?.name}
                </div>

                <div className={classes.videoSectionHeaderButtonsResolution}>
                  <div
                    onClick={(e) => {
                      if (
                        chapterDetailData?.SubTopicslist?.findIndex(
                          (x) => +x?.id === +topicVideoId
                        ) !== 0
                      )
                        changeVideoTopic(e, "prev");
                    }}
                    className={`${classes.topicButton} ${
                      chapterDetailData?.SubTopicslist?.findIndex(
                        (x) => +x?.id === +topicVideoId
                      ) === 0 && classes.disabled
                    }`}
                  >
                    <img src={prevTopic} alt="prev topic" />
                  </div>
                  <div
                    onClick={(e) => {
                      const totalVideosLength: any =
                        chapterDetailData?.SubTopicslist?.length;
                      const currentVideoIndex: any =
                        chapterDetailData?.SubTopicslist?.findIndex(
                          (x) => +x?.id === +topicVideoId
                        );
                      if (currentVideoIndex < totalVideosLength - 1)
                        changeVideoTopic(e, "next");
                    }}
                    className={`${classes.topicButton} ${classes.nextButton}  ${
                      chapterDetailData &&
                      chapterDetailData?.SubTopicslist?.findIndex(
                        (x) => +x?.id === +topicVideoId
                      ) >=
                        chapterDetailData?.SubTopicslist?.length - 1 &&
                      classes.disabled
                    }`}
                  >
                    <img src={nextContentIcon} alt="prev topic" />
                  </div>
                </div>
              </div>

              <div className={classes.videoSectionHeaderActions}>
                <button
                  className={classes.commonButton}
                  type="button"
                  onClick={() => setShowOrHideContentsToggle(true)}
                >
                  <div className={classes.flex}>
                    <img
                      src={contentIcon}
                      alt="content"
                      className={`${classes.buttonIcon} ${classes.videoSectionHeaderActionsImg}`}
                    />
                    <div className={classes.videoSectionHeaderActionsTxt}>
                      CONTENT
                    </div>
                  </div>
                </button>
                {/* <button
                                    className={`${classes.commonButton} ${classes.notesButton}`}
                                    type="button"
                                    onClick={() => setShowOrHideNotesToggle(true)}
                                >
                                    <div className={classes.flex}>
                                        <img
                                            src={noteIcon}
                                            alt="notes"
                                            className={`${classes.buttonIcon} ${classes.videoSectionHeaderActionsImg}`}
                                        />
                                        <div className={classes.videoSectionHeaderActionsTxt}>
                                            NOTES | 22
                                        </div>
                                    </div>
                                </button> */}
                <div className={classes.videoSectionHeaderButtons}>
                  <div
                    onClick={(e) => {
                      if (
                        chapterDetailData?.SubTopicslist?.findIndex(
                          (x) => +x?.id === +topicVideoId
                        ) !== 0
                      )
                        changeVideoTopic(e, "prev");
                    }}
                    className={`${classes.topicButton} ${
                      chapterDetailData?.SubTopicslist?.findIndex(
                        (x) => +x?.id === +topicVideoId
                      ) === 0 && classes.disabled
                    }`}
                  >
                    <img src={prevTopic} alt="prev topic" />
                  </div>
                  <div
                    onClick={(e) => {
                      const totalVideosLength: any =
                        chapterDetailData?.SubTopicslist?.length;
                      const currentVideoIndex: any =
                        chapterDetailData?.SubTopicslist?.findIndex(
                          (x) => +x?.id === +topicVideoId
                        );
                      if (currentVideoIndex < totalVideosLength - 1)
                        changeVideoTopic(e, "next");
                    }}
                    className={`${classes.topicButton} ${classes.nextButton}  ${
                      chapterDetailData &&
                      chapterDetailData?.SubTopicslist?.findIndex(
                        (x) => +x?.id === +topicVideoId
                      ) >=
                        chapterDetailData?.SubTopicslist?.length - 1 &&
                      classes.disabled
                    }`}
                  >
                    <img src={nextContentIcon} alt="prev topic" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={classes.topicVideoBodyWrapper}>
          {showOrHideContentsToggle && (
            <div className={classes.contentsListWrapper}>
              <SideBar
                width="479px"
                content={
                  <TopicVideoPageContents
                    chapterName={subTopicsListData?.topicName.split(".")[1]}
                    getSubTopicId={getSubTopicId}
                    showOrHideContentsToggleFn={showOrHideContentsToggleFn}
                    subTopicsListData={
                      chapterDetailData?.SubTopicslist
                        ? chapterDetailData?.SubTopicslist
                        : []
                    }
                    chapterId={subTopicsListData?.topicid}
                    topicVideoId={topicVideoId}
                  />
                }
              />
            </div>
          )}
          {showOrHideNotesToggle && (
            <div>
              <SideBar
                width="479px"
                content={
                  <VideoNotesList
                    closeVideoNotesList={() => setShowOrHideNotesToggle(false)}
                  />
                }
              />
            </div>
          )}
          {subTopicsListData && (
            <TopicVideoPageDescription
              subTopicsListData={subTopicsListData}
              patternsListTData={patternsListTData}
              handlePatternVideoOverlay={handlePatternVideoOverlay}
              getOverlayDetails={getOverlayDetails}
              handleMCQAssignmentOverlays={handleMCQAssignmentOverlays}
            />
          )}
        </div>
      </div>
      {videoNotesOverlay && (
        <PatternVideoOverlayContainer
          videoUrl={patternOverlayData?.video}
          docName={patternOverlayData?.patternname}
          patternCardData={{
            descriptionText: patternOverlayData?.description,
            patternHeadingText: patternOverlayData?.patternname,
          }}
          handlePatternVideoOverlayContent={handlePatternVideoOverlayContent}
          handlePatternOverlay={handlePatternVideoOverlay}
          videoNotesOverlayContent={videoNotesOverlayContent}
          pptUrl={patternOverlayData?.ppt}
        />
      )}

      {showTestOverlay && (
        <SideBar
          content={
            <TestStartOverlay
              data={assignmentOverlayData}
              closeOverlay={handleMCQAssignmentOverlayClose}
              type={testOverlayType}
              topicId={topicId}
            />
          }
          width="620px"
        />
      )}
      {showResultOverlay && (
        <SolveAssignmentQuestionListViewAll
          showTestOverlay={showResultOverlay}
          title={`Exercise ${
            subTopicsListData?.topicName.split(".")[0]
          }.${getTopicIndex()}`}
          closePopUp={() => setShowResultOverlay(!showResultOverlay)}
        />
      )}
      {isResultOverlay && (
        <ModalWrapperResult
          openModal={isResultOverlay}
          setOpenModal={setIsResultOverlay}
          questionData={questionData}
          topicId={questionData[0]?.topicId}
        />
      )}
    </div>
  );
};

export default TopicVideoPage;
