import React, { useEffect, useState } from "react";
import styles from "./assignmentDescriptiveQuestionList.module.scss";
import { constants } from "../../constants/constants";
import SearchBar from "../searchBar/searchBar";
import GoBackPage from "../goBackPage/goBackPage";
import FilterBar from "../filterBar/filterBar";
import Dropdown from "../dropdown/dropdown";
import completedIcon from "../../assets/images/completedIcon.svg";
import studentSubmitByIcon from "../../assets/images/studentSubmitByIcon.svg";
import submitedByIcon from "../../assets/images/submitedByIcon.svg";
import minutesIcon from "../../assets/images/minutesIcon.svg";
import ChapterIconName from "../../modules/teacher/myClass/components/chapterIconName/chapterIconName";
import { useNavigate, useParams } from "react-router-dom";
import { makeAxiosRequest } from "aie_common";
import ApiEdpoints, { HTTPMethods } from "../../constants/api_endpoints";
import { QuestionAndOptionsElement } from "../questionsAndOptions/questionAndOptions";
import { CustomPagination } from "../pagination/customPagination";
import useSorting from "../../utils/useSorting";
import { calculateTimeTaken } from "../../utils/convertTime";
import { formatDate } from "../../utils/dateFormatter";
import { routePaths } from "../../constants/routes";
import { useDispatch } from "react-redux";
import useSortingByDate from "../../utils/useSortingByDate";

const AssignmentDescriptiveQuestionList = () => {
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState<any>([]);
  const [assignmentSubmittedByStudent, SetAssignmentSubmittedByStudent] =
    useState<any>([]);
  const [dropdownArray, setDropdownArray] = React.useState<any>([]);
  const [patternProps, setPatternProps] = useState<any[]>([]);

  const [chapterDropDownValueWithId, setChapterDropDownValueWithId] =
    useState<any>({});
  const [searchText, setSearchText] = useState("");
  const params = useParams();
  const dispatch = useDispatch();

  const fetchAssignmentDescriptiveQuestionsData = async (params: any) => {
    let payload = {
      url: `${ApiEdpoints.ASSIGNMENT_DESCRIPTIVE_QUESTIONS}/?class_id=${params.classId}&topic_id=${params.topicId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    if (params.chapterId) {
      payload.url = `${ApiEdpoints.ASSIGNMENT_DESCRIPTIVE_QUESTIONS}/?class_id=${params.classId}&chapter_id=${params.chapterId}&level=${params.level}`;
    }
    try {
      const assignmentsData: any = await makeAxiosRequest(payload);
      if (assignmentsData.status !== 200) {
        throw new Error("Error!");
      }
      SetAssignmentSubmittedByStudent(assignmentsData?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAssignmentDescriptiveQuestionsData(params);
  }, [params]);

  const dataMassaging = (data: any) => {
    return data?.map((item: any) => {
      return {
        timeTaken: calculateTimeTaken(item?.time_taken),
        submittedOn: formatDate(item?.submitted_on),
        studentName: item?.student_name ? item?.student_name : "N/A",
        status: item?.status,
        questionName: item?.question,
        questionId: item?.question_id,
        level: item?.level,
      };
    });
  };

  const navigateToReviewPage = (index: number, item: any) => {
    dispatch({
      type: "UPDATE_QUESTION_INDEX_MENTOR",
      payload: item?.questionId,
    });
    let path = `${routePaths.descriptiveQuestionReviewPage.replace(
      ":classId",
      params.classId ?? ""
    )}/?topicId=${params.topicId ?? ""}`;
    if (item.level > 1) {
      path = `${routePaths.descriptiveQuestionReviewPage.replace(
        ":classId",
        params.classId ?? ""
      )}/?chapterId=${params.chapterId ?? ""}&level=${params.level}`;
    }
    navigate(path);
  };

  const {
    dropdownQuestionsList,
    dropdownSelectedValueWithId,
    setDropdownSelectedValueWithId,
    filteredTopics,
    page,
    paginationStart,
    paginationEnd,
    handlePaginationChange,
    range,
    detailedViewRef,
  } = useSorting(
    assignmentSubmittedByStudent?.submission_list,
    selectedStatus,
    searchText,
    "questionName",
    dataMassaging,
    10,
    dropdownArray
  );

  useEffect(() => {
    if (dropdownSelectedValueWithId) {
      if (dropdownSelectedValueWithId?.defaultLabel === "All Questions") {
        setChapterDropDownValueWithId(dropdownSelectedValueWithId);
      }
    }
  }, [dropdownSelectedValueWithId]);
  useEffect(() => {
    let arr: any = [];
    if (dropdownQuestionsList) {
      setPatternProps(
        dropdownQuestionsList && dropdownQuestionsList
          ? [{ data: dropdownQuestionsList, defaultLabel: "All Questions" }]
          : []
      );
    }
    if (patternProps && patternProps.length > 0) {
      patternProps.map((x: any) => {
        if (x?.defaultLabel === chapterDropDownValueWithId?.defaultLabel) {
          arr.push({ ...x, chapterDropDownValueWithId });
        }
      });
    }
    setDropdownArray(arr);
  }, [chapterDropDownValueWithId, dropdownQuestionsList]);
  const { sortedByDateArr, handleSorting } = useSortingByDate(filteredTopics);
  useEffect(() => {
    handleSorting("desc");
  }, [filteredTopics]);
  return (
    <div
      className={styles.assignmnetsListingMainContainer}
      ref={detailedViewRef}
    >
      <GoBackPage
        heading={
          assignmentSubmittedByStudent.topic_index
            ? `${assignmentSubmittedByStudent.topic_index} ${assignmentSubmittedByStudent.assignment_name}`
            : `${assignmentSubmittedByStudent.assignment_name} - Assignment Level ${params.level}`
        }
      />
      <div className={styles.assignmentsListingSearchbarContainer}>
        <div className={styles.assignmentTitleData}>
          {constants.ASSIGNMENT_QUESTION_LIST}
        </div>
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
      </div>
      <div className={styles.separator}></div>
      <div className={styles.assignmnetsListingFilterWrap}>
        <div className={styles.statusAndChapterFilter}>
          <div className={styles.statusesFilter}>
            <FilterBar
              statuses={["All", "Pending", "Completed"]}
              selectedStatus={(selectedStatuses: string[]) =>
                setSelectedStatus(selectedStatuses.map((x) => x.toLowerCase()))
              }
              showSorting={false}
            />
          </div>
          {patternProps && patternProps.length > 0 && (
            <div className={styles.chpatternsFilterContainer}>
              <Dropdown
                options={patternProps ? patternProps[0]?.data : []}
                defaultLabel={"All Questions"}
                minWidth={"225px"}
                overlayWidth={"547px"}
                id={"questionList"}
                selectedValue={(item: string) => console.log("")}
                setDropdownSelectedValueWithId={setDropdownSelectedValueWithId}
              />
            </div>
          )}
        </div>
        <div>
          <FilterBar
            showSorting={true}
            sortFunc={(sortType: string) => handleSorting(sortType)}
          />
        </div>
      </div>
      <div className={styles.assignmentListingItemContainer}>
        {Array.isArray(sortedByDateArr) &&
          sortedByDateArr
            ?.slice(paginationStart, paginationEnd)
            .map((item: any, index: number) => (
              <>
                <div
                  key={index}
                  className={styles.assignmnetListItemWrapper}
                  onClick={() => {
                    navigateToReviewPage(index, item);
                  }}
                >
                  <div className={styles.iconAndActionbtns}>
                    <ChapterIconName icon={completedIcon} heading="Completed" />
                    <div className={styles.assignmentTitleAndActionbtnsWrap}>
                      <div className={styles.titlesAndDescriptionWrapper}>
                        <div className={styles.titleText}>
                          <QuestionAndOptionsElement
                            metaData={item?.questionName}
                            size="question"
                          />
                        </div>
                        <div className={styles.descriptionIconswrp}>
                          <div className={styles.descriptionIconAndText}>
                            <div className={styles.descriptionIcon}>
                              <img src={studentSubmitByIcon} alt="" />
                            </div>
                            <div className={styles.descriptionText}>
                              {item?.studentName}
                            </div>
                          </div>
                          <div className={styles.descriptionIconAndText}>
                            <div className={styles.descriptionIcon}>
                              <img src={submitedByIcon} alt="" />
                            </div>
                            <div className={styles.descriptionText}>
                              {`Submitted On ${item?.submittedOn}`}
                            </div>
                          </div>
                          <div className={styles.descriptionIconAndText}>
                            <div className={styles.descriptionIcon}>
                              <img src={minutesIcon} alt="" />
                            </div>
                            <div className={styles.descriptionText}>
                              {item?.timeTaken}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.reviewNowWrap}>
                        {item.status !== "completed" && (
                          <div className={styles.cancelButton}>Review Now</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.seperator}></div>
              </>
            ))}
      </div>
      {sortedByDateArr?.length > range && (
        <CustomPagination
          detailedViewRef={detailedViewRef}
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(sortedByDateArr?.length / range)}
        />
      )}

      {sortedByDateArr?.length === 0 && (
        <div className={styles.noDataFound}>No Data Found</div>
      )}
    </div>
  );
};

export default React.memo(AssignmentDescriptiveQuestionList);
