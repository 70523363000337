import React from "react";
import styles from "./averageLevelCard.module.scss";

const AverageLevelCard = ({ levelCardData }: any) => {
    return (
        <div className={styles.averageLevelCard}>
            <h1>Average Level</h1>
            <p>{levelCardData}</p>
        </div>
    );
};

export default AverageLevelCard;
