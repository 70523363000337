import React from "react";
import styles from "./dashboardCard.module.scss";
import Progressbar from "../../../common/progressbar/progressbar";
import astronautIcon from "../../../assets/images/astronautImage.svg";
import { constants } from "../../../constants/constants";

const DashboardCard = ({  setHideOverlay }: any) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.upperContainer}>
          <div className={styles.cardHeading}>Setup Study preferences</div>
          <div className={styles.cardDesc}>
            Let us know your preferences so that we can customise the learning
            plan for you.
          </div>
        </div>
        <div className={styles.lowerContainer}>
          <div className={styles.progressbarContainer}>
            <div className={styles.progressbar}>
              <Progressbar progress={60} isRocketVisible={false} />
            </div>
            <span> 40%</span>
          </div>
          <div>
            <button
              className={styles.cancelButton}
              onClick={() => setHideOverlay(true)}
            >
              {constants.START_SETUP}
            </button>
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <img src={astronautIcon} alt={astronautIcon} />
      </div>
    </div>
  );
};

export default DashboardCard;
