
import React from "react";
import styles from "./solveAssignmentQuestionList.module.scss";
import { useDispatch } from "react-redux";

export const SolveAssignmentQuestionList = ({
    type,
    item,
    setQuestion,
    questions,
    setActiveQuestionIndex,
    activeAssignmentType,
    activeQuestionIndex,
    setOptionClicked

}: any) => {
    const dispatch = useDispatch();
    const questionType = (type: any) => {
        switch (type) {
            case "Completed":
                return (
                    <div className={`${styles.questionNumber} ${styles.completed}
                    ${activeQuestionIndex === item - 1 ? styles.inProgress : ""}
                    `}
                        onClick={
                            () => {
                                !questions[activeQuestionIndex]?.isAnswered && setOptionClicked(true);
                                setTimeout(() => {
                                    dispatch({ type: "UPDATE_QUESTION_INDEX", payload: item - 1 })
                                }, 100);
                            }
                        }
                    >
                        {item}
                    </div>
                );

            case "Skipped":
                return (
                    <div className={`${styles.questionNumber} ${styles.skipped} ${activeQuestionIndex === item - 1 ? styles.inProgress : ""}`}
                        onClick={
                            () => {
                                !questions[activeQuestionIndex]?.isAnswered && setOptionClicked(true);

                                setTimeout(() => {
                                    dispatch({ type: "UPDATE_QUESTION_INDEX", payload: item - 1 })
                                }, 100);
                            }
                        }
                    >
                        {item}
                    </div>
                );

            case "Wrong":
                return (
                    <div className={`${styles.questionNumber} ${styles.wrong} ${activeQuestionIndex === item - 1 ? styles.inProgress : ""}`}
                        onClick={
                            () => {
                                !questions[activeQuestionIndex]?.isAnswered && setOptionClicked(true);

                                setTimeout(() => {
                                    dispatch({ type: "UPDATE_QUESTION_INDEX", payload: item - 1 })
                                }, 100);
                            }
                        }
                    >
                        {item}
                    </div>
                );
            case "InReview":
                return (
                    <div className={`${styles.questionNumber} ${styles.review} ${activeQuestionIndex === item - 1 ? styles.inProgress : ""}`}
                        onClick={
                            () => {
                                !questions[activeQuestionIndex]?.isAnswered && setOptionClicked(true);

                                setTimeout(() => {
                                    dispatch({ type: "UPDATE_QUESTION_INDEX", payload: item - 1 })
                                }, 100);
                            }
                        }
                    >
                        {item}
                    </div>
                );

            default:
                return (
                    <div className={`${styles.questionNumber}  ${activeQuestionIndex === item - 1 ? styles.inProgress : styles.pending}`}
                        onClick={
                            () => {
                                !questions[activeQuestionIndex]?.isAnswered && setOptionClicked(true);
                                setTimeout(() => {
                                    dispatch({ type: "UPDATE_QUESTION_INDEX", payload: item - 1 })
                                }, 100);
                                // dispatch({ type: "UPDATE_QUESTION_INDEX", payload: item - 1 })
                                // setActiveQuestionIndex(item - 1);
                                // setQuestion(
                                //     () => {
                                //         return questions.map((item1: any, index: any) => {
                                //             if (index === activeQuestionIndex + 1) {
                                //                 item1.visited = "In Progress"
                                //             }

                                //             return item1;


                                //         });
                                //     });
                            }
                        }


                    >
                        {item}
                    </div >
                );
        }
    };

    return questionType(type);
};

