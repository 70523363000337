import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import Auth from "./Auth";
import Header from "./common/header/header";
import Loader from "./common/loader/loader";
import StatusContext, { initValue } from "./context/loaderContext";
import { MathJaxContext } from "better-react-mathjax";

function App() {
  const [state, setState] = useState(initValue);

  return (
    <MathJaxContext version={2}>
      <div className="App">
        <StatusContext.Provider value={{ state, setState }}>
          <BrowserRouter basename="/">
            <div className="mainWrapper">
              {/* {isLoggedIn ? <div className="sideNavBarContainer"><SideNavBar handleExpand={() => { }} /></div> : ""} */}
              <Header />
              <div className="pageContent">
                <Auth />
              </div>
            </div>
          </BrowserRouter>
          <Loader />
        </StatusContext.Provider>
      </div>
    </MathJaxContext>
  );
}

export default App;
