import React from "react";

import styles from "./submitAndCancelButton.module.scss";

const SubmitAndCancelButton = ({ onCancel, onSubmit }: any) => {
  return (
    <div className={styles.submitCancelButton}>
      <button onClick={onCancel} className={styles.cancel} type="reset">
        Cancel
      </button>
      <button className={styles.submit} type="submit">
        Submit
      </button>
    </div>
  );
};

export default SubmitAndCancelButton;
