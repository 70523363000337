import React, { useContext, useEffect, useRef, useState } from "react";
import ApiEdpoints, { HTTPMethods } from "../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import styles from "./notifications.module.scss";
import StatusContext from "../../context/loaderContext";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import NotificationList from "./notificationList";

const Notifications = ({ from }: any) => {
  const [notificationData, setNotificationData] = useState<object[]>([]);
  const detailedViewRef = useRef(null);
  const loaderStatus: any = useContext(StatusContext);

  const fetchNotificationList = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.GET_NOTIFICATIONS}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);
      setNotificationData(response?.data?.data);
      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchNotificationList();
  }, []);
  return (
    <div className={styles.myAssignmentsMainContainer} ref={detailedViewRef}>
      <Breadcrumb
        breadcrumbDetails={[
          {
            label: "Home",
            link: from ? "/" + from : "/home",
            selected: false,
          },
          {
            label: "Notifications",
            link: "/notifications",
            selected: true,
          },
        ]}
      />
      <NotificationList
        detailedViewRef={detailedViewRef}
        notificationData={notificationData}
      />
    </div>
  );
};

export default Notifications;
