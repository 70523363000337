import React, { useState, useEffect, useContext } from "react";
import { constants } from "../../../constants/constants";
import styles from "./exerciseList.module.scss";
import FilterBar from "../../../common/filterBar/filterBar";
import { CustomPagination } from "../../../common/pagination/customPagination";
import SearchBar from "../../../common/searchBar/searchBar";
import useSorting from "../../../utils/useSorting";
import Dropdown from "../../../common/dropdown/dropdown";
import { formatDate } from "../../../utils/dateFormatter";
import useSortingByDate from "../../../utils/useSortingByDate";
import StatusContext from "../../../context/loaderContext";
import PracticeTestChapterCard from "../../../common/cards/practiceTestChapterCard";
import ExerciseCard from "../../../common/cards/exerciseCard";

interface IExerciseList {
  assignmentsData: any;
  patternOptions: string[];
  detailedViewRef: any;
}
const ITEMSPERPAGE = 12;
const ExerciseList = ({
  assignmentsData,
  patternOptions,
  detailedViewRef,
}: IExerciseList) => {
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const loaderStatus: any = useContext(StatusContext);

  const [chapterDropDownValueWithId, setChapterDropDownValueWithId] =
    useState<any>({});

  //1 CHECK
  const dataMassaging = (data: any) => {
    const updatedData = data.map((item: any) => {
      return {
        id: item.id,
        name: item.name,
        questionCount: item.numberOfQuestions,
        publication: "",
        status: item.progress == 100 ? "complete" : "pending",
        indexOfTopic: item.indexOfTopic,
        level: item.level,
      };
    });

    return updatedData;
  };
  const [searchText, setSearchText] = useState("");
  const [dropdownArray, setDropdownArray] = React.useState<any>([]);
  const {
    dropdownSelectedValueWithId,
    setDropdownSelectedValueWithId,
    filteredTopics,
    page,
    paginationStart,
    paginationEnd,
    range,
    handlePaginationChange,
  } = useSorting(
    assignmentsData[0]?.topicSubTopics || [],
    selectedStatus,
    searchText,
    "studentsMyAssignment", // search filter key
    dataMassaging,
    ITEMSPERPAGE, // range
    dropdownArray
  );

  useEffect(() => {
    if (dropdownSelectedValueWithId) {
      if (dropdownSelectedValueWithId?.defaultLabel === "All Questions") {
        setChapterDropDownValueWithId(dropdownSelectedValueWithId);
      }
    }
  }, [dropdownSelectedValueWithId]);

  const { sortedByDateArr, handleSorting } = useSortingByDate(filteredTopics);

  useEffect(() => {
    handleSorting("desc");
  }, [filteredTopics]);

  return (
    <div className={styles.allAssignmentsMainContainer}>
      <div className={styles.assignmnetsTitleSearchbarContainer}>
        <div className={styles.assignmentHeaderTitle}>Exercises</div>
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
      </div>
      {assignmentsData && !!assignmentsData.length && (
        <div className={styles.exerciseTopicCardContainer}>
          <ExerciseCard i={0} data={assignmentsData[0]} collapsable={false} />
        </div>
      )}

      <div className={styles.exerciseTopicItemsContainer}>
        {sortedByDateArr.length > 0 && (
          <>
            {sortedByDateArr
              ?.slice(paginationStart, paginationEnd)
              .map((item: any, ind: number) => (
                <PracticeTestChapterCard
                  data={item}
                  status={item.status}
                  key={ind + item.id}
                />
              ))}
            <PracticeTestChapterCard
              data={{
                id: assignmentsData[0]?.id,
                name: assignmentsData[0]?.level_2?.Assignment_name,
                questionCount: assignmentsData[0]?.level_2?.question_count,
                level: 2,
              }}
              status={"pending"}
            />
            <PracticeTestChapterCard
              data={{
                id: assignmentsData[0]?.id,
                name: assignmentsData[0]?.level_3?.Assignment_name,
                questionCount: assignmentsData[0]?.level_3?.question_count,
                level: 3,
              }}
              status={"pending"}
            />
          </>
        )}

        {filteredTopics.length === 0 && loaderStatus.show === false && (
          <div className={styles.noResultFound}>{constants.NO_DATA_FOUND}</div>
        )}
      </div>

      {filteredTopics?.length > ITEMSPERPAGE && (
        <CustomPagination
          detailedViewRef={detailedViewRef}
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(filteredTopics?.length / range)}
        />
      )}
    </div>
  );
};

export default React.memo(ExerciseList);
