// export const convertMathMLToText = (option: any) => {
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(option?.name, "text/html");
//   const textNodes: any = doc.querySelectorAll("mtext, mn, mi, mo");
//   if (textNodes.length === 0) return option?.name;
//   else {
//     let text = "";
//     for (let i = 0; i < textNodes.length; i++) {
//       text += `${textNodes[i].textContent.trim()} `;
//     }
//     return text;
//   }
// };

import parse from "html-react-parser";

export const convertMathMLToText = (option: any) => {
  const questionAndOptionsData: string | any = parse(option.name ?? "");

  return questionAndOptionsData[0]?.length > 45
    ? questionAndOptionsData[0]?.slice(0, 55) + "..."
    : questionAndOptionsData
};
