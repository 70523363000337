import { makeAxiosRequest } from "aie_common";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import ApiEdpoints, { HTTPMethods } from "../../constants/api_endpoints";
import { constants } from "../../constants/constants";
import styles from "./learnPage.module.scss";
import { CustomPagination } from "../../common/pagination/customPagination";
import { TestStartOverlay } from "../../common/testStartOverlay/testStartOverlay";
import SideBar from "../../common/sidebar/sidebar";
import ChapterCardNew from "./components/chapterCardNew/chapterCardNew";
import PPTOverlay from "../../common/pptOverlay/pptOverlay";
import StatusContext from "../../context/loaderContext";
import SearchBar from "../../common/searchBar/searchBar";
import { ModalWrapperResult } from "../../common/modalWrapper/modalWrapperResultAssignment/modalWrapperResult";
import StudyPreferences from "../dashboard/studyPreferences/studyPreferences";
import { SubmitPrefrencesModal } from "../dashboard/submitPrefrencesModal/submitPrefrencesModal";
import sittingReading from "../../assets/images/aiReadingImg.svg";
import holdingClock from "../../assets/images/holdingClock.svg";
import standingOnTheMoon from "../../assets/images/standingOnMoon.svg";
import standingOnCloud from "../../assets/images/standingOnCloud.svg";
import { useSearch } from "../../utils/searchBar";
import { Dialog } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

export const Learn = () => {
  const [courseTopics, setCourseTopics] = useState<any>([]);
  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationEnd, setPaginationEnd] = useState(5);
  const detailedViewRef: any = useRef(null);
  const courseId = useSelector(
    (state: any) => state.userReducer.registeredCourseId
  );
  const [learnBreadcrumbData, setLearnBreadcrumbData] = useState(
    constants.LEARN_BREADCRUMB_DATA
  );
  const footerImages = [
    {
      img: sittingReading,
      id: "sittingReading",
    },
    {
      img: holdingClock,
      id: "holdingClock",
    },
    {
      img: standingOnTheMoon,
      id: "standingOnTheMoon",
    },
    {
      img: standingOnCloud,
      id: "standingOnCloud",
    },
  ];
  const randomFooterImage = Math.floor(Math.random() * footerImages.length);
  const username = useSelector((state: any) => state.userReducer.username);
  const range = 5; //Number of Items displayed per page for Pagination
  const [showPPT, setShowPPT] = useState(false);
  const [showTestOverlay, setShowTestOverlay] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState({ name: "" });
  const [selectedTopic, setSelectedTopic] = useState({ name: "" });
  const [pptOverlayData, setPPTOverlayData] = useState({
    name: "",
    pptUrl: "",
  });
  const [assignmentOverlayData, setAssignmentOverlayData] = useState({
    name: "",
    id: 0,
    index: "",
    topicSerialNumber: "",
  });
  const [getActiveStep, setGetActiveStep] = useState(0);
  const [topicId, setTopicId] = useState(0);
  const loaderStatus: any = useContext(StatusContext);
  const [testOverlayType, setTestOverlayType] = useState<"assignment" | "mcq">(
    "assignment"
  );
  const [page, setPage] = useState(1);
  const [isResultOverlay, setIsResultOverlay] = useState(false);
  const [hideOnboarding, setHideOnboarding] = useState(false);

  const questionData = useSelector((state: any) => state.mcqQuestionReducer);
  useEffect(() => {
    if (questionData[0].open && !isResultOverlay) {
      setIsResultOverlay(true);
    }
  }, [questionData]);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    const finalValue = Math.ceil(courseTopics.length / range);

    if (finalValue === value) {
      setPaginationStart(range * (value - 1));
      setPaginationEnd(courseTopics.length);
    } else {
      setPaginationStart(range * (value - 1));
      setPaginationEnd(range * value);
    }
  };

  const fetchChapterList = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: ApiEdpoints.CHAPTER_LIST + `${courseId}/`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      loaderStatus.setState({ show: false });
      res.data.data.courseTopics = res.data.data.courseTopics.map(
        (item: any, index: number) => {
          return {
            ...item,
            index: index + 1,
          };
        }
      );
      setCourseTopics(res.data.data.courseTopics);
      return res;
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const handleOverlays = (type: string, item: any) => {
    if (type === "mcq") {
      setAssignmentOverlayData({
        name: item?.name,
        id: item?.id,
        index: item?.index,
        topicSerialNumber: item?.topicSerialNumber,
      });
      setShowTestOverlay(true);
      setTestOverlayType(type);
    } else if (type === "ppt") {
      setPPTOverlayData({
        name: item?.displayName,
        pptUrl: item?.pptUrl?.document,
      });
      setShowPPT(true);
    } else if (type === "assignment") {
      setAssignmentOverlayData({
        name: item?.name,
        id: item?.id,
        index: item?.index,
        topicSerialNumber: item?.topicSerialNumber,
      });
      setShowTestOverlay(true);
      setTestOverlayType(type);
    } else {
      return;
    }
  };

  const handleOverlayClose = (type?: string) => {
    if (type === "mcq" || type === "assigment") setShowTestOverlay(false);
    else if (type === "ppt") {
      setShowPPT(false);
    } else {
      return;
    }
  };
  const [hideOverlay, setHideOverlay] = useState(false);
  const PreferencesHandleOverlayClose = () => {
    setHideOverlay(false);
  };
  const isStudyPreferencesVisible = useSelector(
    (state: any) => state.studyPreferenceReducer.isVisible
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!courseId) return;
    setTimeout(() => {
      fetchChapterList();
    }, 50);
    dispatch({
      type: constants.LEARN,
      index: 0,
    });
  }, [courseId]);
  const { onBoardingStatus } = useSelector((state: any) => state.userReducer);
  const [openAccordion, setOpenAccordion] = useState(-1);

  useEffect(() => {
    onBoardingStatus &&
      onBoardingStatus !== "completed" &&
      dispatch({
        type: "visiblity",
      });
  }, [onBoardingStatus, dispatch]);
  const [openModal, setOpenModal] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [filteredTopics, getFilteredData] = useSearch(courseTopics);
  useEffect(() => {
    getFilteredData(searchText, "name");
  }, [searchText]);

  return (
    <>
      {/* {isStudyPreferencesVisible && ( */}
      {!hideOnboarding && (
        // <SideBar
        //   content={
        //     <>
        //       <StudyPreferences
        //         openModal={openModal}
        //         setOpenModal={setOpenModal}
        //         setGetActiveStep={setGetActiveStep}
        //       />
        //       {getActiveStep > 0 && (
        //         <div className={styles.footerWithImage}>
        //           <img
        //             src={footerImages[randomFooterImage].img}
        //             alt="footer"
        //             className={styles[footerImages[randomFooterImage].id]}
        //           />
        //           <div className={styles.verticalLine}></div>
        //         </div>
        //       )}
        //     </>
        //   }
        //   width="800px"
        // />

        <Dialog
          open={isStudyPreferencesVisible}
          fullScreen
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
        >
          <div className={styles.onboardingWrap}>
            <StudyPreferences
              openModal={openModal}
              setOpenModal={setOpenModal}
              setGetActiveStep={setGetActiveStep}
              closeModal={() => {
                  setHideOnboarding((state) => !state);
                }
              }
            />
            {getActiveStep > 0 && (
              <div className={styles.footerWithImage}>
                <img
                  src={footerImages[randomFooterImage].img}
                  alt="footer"
                  className={styles[footerImages[randomFooterImage].id]}
                />
              </div>
            )}
            <button
              onClick={() => {
                setHideOnboarding((state) => !state);
              }}
            >
              <CloseRounded />
            </button>
          </div>
        </Dialog>
      )}
      <div className={styles.learnMainContainer} ref={detailedViewRef}>
        <Breadcrumb breadcrumbDetails={learnBreadcrumbData} />
        <div className={styles.greetingsSearchBarContainer}>
          <div className={styles.greetings}>
            {constants.GREETINGS.replace("user", username)}
          </div>
          <SearchBar searchText={searchText} setSearchText={setSearchText} />
        </div>
        <div className={styles.chapterListContainer}>
          {filteredTopics?.length > 0 &&
            filteredTopics
              ?.slice(paginationStart, paginationEnd)
              .map((item: any, key: any) => {
                return (
                  <ChapterCardNew
                    topicId={topicId}
                    chapterItem={item}
                    chapterNumber={key}
                    page={page}
                    handleOverlays={handleOverlays}
                    count={range}
                    setTopicId={setTopicId}
                    openAccordion={openAccordion}
                    setOpenAccordion={setOpenAccordion}
                  />
                );
              })}
        </div>
        {filteredTopics?.length > range && (
          <CustomPagination
            detailedViewRef={detailedViewRef}
            page={page}
            handlePaginationChage={handlePaginationChange}
            count={Math.ceil(filteredTopics?.length / range)}
          />
        )}
        {filteredTopics?.length === 0 && loaderStatus.show === false && (
          <div className={styles.noDataFound}>{constants.NO_DATA_FOUND}</div>
        )}
        {/* ppt overlay */}
        {showPPT && (
          <PPTOverlay
            closeOverlay={handleOverlayClose}
            pptOverlayData={pptOverlayData}
          />
        )}

        {/* TEST overlay */}
        {showTestOverlay && (
          <SideBar
            content={
              <TestStartOverlay
                data={assignmentOverlayData}
                closeOverlay={handleOverlayClose}
                type={testOverlayType}
                topicId={topicId}
              />
            }
            width="620px"
          />
        )}
        {isResultOverlay && (
          <ModalWrapperResult
            openModal={isResultOverlay}
            setOpenModal={setIsResultOverlay}
            questionData={questionData}
            topicId={questionData[0]?.topicId}
          />
        )}
      </div>
      {openModal ? (
        <SubmitPrefrencesModal
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      ) : null}
    </>
  );
};
