import { useContext, useEffect, useState } from "react";
import styles from "./studyPreferences.module.scss";
import sittinInAirplaneIcon from "../../../assets/images/sittingInAirplane.svg";
import MascotVSign from "../../../assets/images/mascotVSign.svg";
import CancelIcon from "../../../assets/images/cancel.svg";
import { useDispatch } from "react-redux";

import McqRange from "../../../common/mcqRange/mcqRange";
import ApiEdpoints, { HTTPMethods } from "../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import StatusContext from "../../../context/loaderContext";
import MCQQuestion from "../components/mcqQuestion/mcqQuestion";
import { constants } from "../../../constants/constants";

export default function StudyPreferences({
  openModal,
  setOpenModal,
  setGetActiveStep,
  closeModal,
}: any) {
  const intialStep = [
    {
      label: "Study Preferences",
      heading: "We are glad to have you on AI Educator!",
      stepImage: sittinInAirplaneIcon,
      description: `Let’s get you started on an express math learning journey. Help us by setting up your study preferences and we will take care of the rest.`,
    },
  ];
  const countOfQuesPerPage = 2;
  const loaderStatus: any = useContext(StatusContext);

  const fetchQuestionsList = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: ApiEdpoints.ONBOARDING_QUESTIONS,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const { data }: any = await makeAxiosRequest(payload);
      loaderStatus.setState({ show: false });
      setSteps(data?.data);
      createSteps(data?.data);
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const [steps, setSteps] = useState<any>([]);
  const [answers, setAnswers] = useState<any>([]);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);

  function createSteps(questions: any) {
    const numSteps = Math.ceil(questions.length / countOfQuesPerPage);
    const newSteps = [];
    for (let i = 0; i < numSteps; i++) {
      const subArray = questions.slice(
        i * countOfQuesPerPage,
        i * countOfQuesPerPage + countOfQuesPerPage
      );
      const step = {
        label: `Step ${i + 1}`,
        questions: subArray,
      };
      newSteps.push(step);
    }
    setSteps([...intialStep, ...newSteps]);
  }

  useEffect(() => {
    fetchQuestionsList();
    // createSteps([]);
  }, []);

  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  {
    /* commented for future use  */
  }
  // const isStepOptional = (step: number) => {
  //   return step === 0 || step === 1;
  // };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep !== steps.length - 1) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else {
      return;
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const checkifAllQuestionsAnswered = () => {
    let isAllAnswered = true;
    if (steps.length && activeStep > 0 && steps[activeStep].questions) {
      steps[activeStep].questions.forEach((question: any) => {
        if (!question.answer) {
          isAllAnswered = false;
        }
      });
    }
    // setAllQuestionsAnswered(isAllAnswered);
    return isAllAnswered;
  };
  useEffect(() => {
    setAllQuestionsAnswered(checkifAllQuestionsAnswered());
  }, [steps, activeStep]);
  {
    /* commented for future use  */
  }
  const handleSkipAll = () => {
    const allSkipped = new Set<number>();
    allSkipped.delete(0);
    allSkipped.delete(1);
    setSkipped(allSkipped);
    setActiveStep(steps.length - 1);
  };
  function getAnswers() {
    if (steps.length && activeStep > 0 && steps[activeStep].questions) {
      steps[activeStep].questions.forEach((question: any) => {
        if (question.answer) {
          setAnswers((prevAnswers: any) => {
            const filteredAnswers = prevAnswers?.filter(
              (answer: any) => answer.id !== question.id
            );
            return [
              ...filteredAnswers,
              {
                id: question.id,
                answer: question.answer,
              },
            ];
          });
        }
      });
    }
  }

  useEffect(() => {
    getAnswers();
    setGetActiveStep(activeStep);
  }, [steps, activeStep]);

  const sendStudyPreferences = async () => {
    let payload = {
      url: ApiEdpoints.ONBOARDING_QUESTIONS,
      data: {
        answers: answers,
      },
      method: HTTPMethods.POST,
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const data = await makeAxiosRequest(payload);
      if (data.status === 200) {
        dispatch({ type: "hideVisiblity" });
        setOpenModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.studyPreferencesContainer}>
      {/* <div className={styles.headerContainer}>
        <div className={styles.headerHeading}>Study Preferences</div>
       <div
          className={styles.cancelContainer}
          onClick={() => dispatch({ type: "hideVisiblity" })}
        >
          <img src={CancelIcon} alt={CancelIcon} />
        </div>
      </div> */}
      <div
        className={`${styles.bodyContainer} ${
          activeStep === 0 ? styles.bodyContainerInitial : ""
        }`}
      >
        {activeStep === 0 && (
          <>
            <img src={MascotVSign} alt="" />
            <div className={styles.bodyHeading}>Welcome to AI Educator!</div>
            <div className={styles.bodyDesc}>
              We're excited to have you join our platform. Let's get started on
              your learning journey.
            </div>
          </>
        )}
        {steps[activeStep]?.questions && (
          <div className={styles.questionContainer}>
            {steps[activeStep].questions.map((item: any, index: number) => (
              <div className={styles.question}>
                <div className={styles.rangeContainer}>
                  {item.type_of_question === "mcq" && (
                    <MCQQuestion
                      key={item.question}
                      item={item}
                      type={"radio"}
                      steps={steps}
                      setSteps={setSteps}
                      currentValue={item?.answer}
                    />
                  )}
                  {item.type_of_question === "multi_select" && (
                    <MCQQuestion
                      key={item.question}
                      item={item}
                      type={"radio"}
                      steps={steps}
                      setSteps={setSteps}
                      currentValue={item?.answer}
                    />
                  )}
                  {item.type_of_question === "days" && (
                    <MCQQuestion
                      key={item.question}
                      item={item}
                      type={"radio"}
                      steps={steps}
                      setSteps={setSteps}
                      currentValue={item?.answer}
                    />
                  )}
                  {item.type_of_question === "time" && (
                    <MCQQuestion
                      key={item.question}
                      item={item}
                      type={"radio"}
                      steps={steps}
                      setSteps={setSteps}
                      currentValue={item?.answer}
                    />
                  )}
                  {item.type_of_question === "range" && (
                    <McqRange
                      key={item.question}
                      item={item}
                      minValue={0}
                      maxValue={item.max_range_or_no_of_options}
                      currentValue={item?.answer}
                      sliderValue={() => {}}
                      steps={steps}
                      setSteps={setSteps}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.footer}>
        {/* {activeStep > 0 && (
          <>
            <img
              src={footerImages[randomFooterImage].img}
              alt="footer"
              className={styles[footerImages[randomFooterImage].id]}
            />
            <div className={styles.verticalLine}></div>
          </>
        )} */}
        <div className={styles.buttonContainer}>
          {/* commented for future use  */}

          <button
            color="inherit"
            // onClick={}
            onClick={() => {
              handleSkipAll();
              closeModal();
            }}
            className={styles.skipAll}
          >
            Skip All
          </button>

          <div className={styles.rightButtonContainer}>
            {activeStep > 0 && (
              <button onClick={handleBack} className={styles.cancelButton}>
                PREVIOUS
              </button>
            )}
            <div
              className={`${styles.primaryButtonWrap} ${
                activeStep === 0
                  ? false
                  : !checkifAllQuestionsAnswered()
                  ? styles.primaryButtonWrapDisabled
                  : ""
              }`}
            >
              <button
                disabled={
                  activeStep === 0 ? false : !checkifAllQuestionsAnswered()
                }
                onClick={
                  activeStep === steps.length - 1
                    ? () => sendStudyPreferences()
                    : handleNext
                }
                className={styles.primaryButton}
              >
                {activeStep === steps.length - 1
                  ? constants.FINISH
                  : activeStep === 0
                  ? constants.NEXT
                  : constants.NEXT}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
