import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import ImageSidebar from './imageSidebar/imageSidebar'
import styles from "./uploadedFilesDisplayModal.module.scss";
import crossIcon from "../../../../../../../../../assets/images/cross.svg";

import leftArrow from "../../../../../../../../../assets/images/RoundedLeftArrow.svg";
import rightArrow from "../../../../../../../../../assets/images/RoundedRightArrow.svg";

interface ModalType {
    open: boolean,
    handleClose: any,
    activeFileIndex: number,
    uploadedFiles: any,
    setActiveFileIndex: any
}

const UploadedFilesDisplayModal = ({ open, handleClose, activeFileIndex, uploadedFiles, setActiveFileIndex }: ModalType) => {

    const activeFile = uploadedFiles[activeFileIndex]

    const prevSlide = () => {
        setActiveFileIndex((activeFileIndex: number) => activeFileIndex - 1);
    };

    const nextSlide = () => {
        setActiveFileIndex((activeFileIndex: number) => activeFileIndex + 1);
    };

    if (!open) return null;

    return (
        <Modal
            open={open}
            onClose={handleClose}
            className={styles.uploadedFilesDisplayModalContainer}
        >
            <Box className={styles.uploadedFilesDisplayModal}>
                <div className={styles.modalHeader} >
                    <p className={styles.modelHeading}>
                        Image Preview
                    </p>
                    <img
                        src={crossIcon}
                        alt={``}
                        className={styles.crossIcon}
                        onClick={handleClose}
                    />

                </div>
                <div className={styles.modalBody}>
                    <div className={styles.modelSidebar}>
                        <ImageSidebar
                            activeFileIndex={activeFileIndex}
                            uploadedFiles={uploadedFiles}
                            setActiveFileIndex={setActiveFileIndex}
                        />
                    </div>
                    <div className={styles.verticalBar} />
                    <div className={styles.imageFileContainer}>
                        <img src={activeFile.preview ?? activeFile} className={styles.imageFile} alt={``} />
                    </div>
                    <div className={styles.navigationButtonsContainer}>
                        <button disabled={activeFileIndex === 0} onClick={prevSlide} className={styles.buttonContainer}>
                            <img src={leftArrow} className={styles.sliderIcon} />
                        </button>
                        {`Page ${activeFileIndex + 1} of ${uploadedFiles.length}`}
                        <button disabled={activeFileIndex >= uploadedFiles.length - 1} onClick={nextSlide} className={styles.buttonContainer}>
                            <img src={rightArrow} className={styles.sliderIcon} />
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default UploadedFilesDisplayModal

