import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./classDeatilsAssignmentCard.module.scss";
import timerLoadingIcon from "../../../../../assets/images/timerLoadingIcon.svg";
import { constants } from "../../../../../constants/constants";
import studentSubmitByIcon from "../../../../../assets/images/studentSubmitByIcon.svg";
import questionsAssignment from "../../../../../assets/images/questionsAssignment.svg";
import submitedByIcon from "../../../../../assets/images/submitedByIcon.svg";
import completedIcon from "../../../../../assets/images/completedIcon.svg";
import { routePaths } from "../../../../../constants/routes";

const ClassDetailsAssignmentCard = ({ item }: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const navigateToAssignmentQuestionList = () => {
    let path = routePaths.assignmentQuestionList
      .replace(":classId", params.classId ?? "")
      .replace(":topicId", item.topic_id);
    if (item.level > 1) {
      path = routePaths.assignmentQuestionListLevel
        .replace(":classId", params.classId ?? "")
        .replace(":chapterId", item.chapter_id)
        .replace(":level", item.level);
    }
    navigate(path);
  };
  return (
    <div
      className={styles.classDetailsAssignmentCard}
      onClick={navigateToAssignmentQuestionList}
    >
      <div className={styles.iconStatusContainer}>
        <div className={styles.statusIcon}>
          <img
            src={
              item?.status?.toLowerCase() === "pending"
                ? timerLoadingIcon
                : completedIcon
            }
            className={styles.img}
            alt="icon"
          />
        </div>
        <div className={styles.status}>{item?.status}</div>
      </div>

      <div className={styles.titleDescriptionStatusContainer}>
        <div className={styles.titleDescriptionContainer}>
          <div className={styles.assignmentTitle}>
            <div className={styles.chapterNumber}> {item.assignment_name}</div>
            <div className={styles.seperator}></div>
            {item.level > 1 ? item.chapter_name : item.topic_name}
          </div>
          <div className={styles.assignmentDateContainer}>
            <div className={styles.assignmentIconAndText}>
              <img src={studentSubmitByIcon} />
              <div className={styles.assignmnetSubText}>
                {item?.number_of_students} {constants.STUDENTS}
              </div>
            </div>

            <div className={styles.assignmentIconAndText}>
              <img src={questionsAssignment} />
              <div className={styles.assignmnetSubText}>
                {item?.number_of_student_submissions} {constants.SUBMISSIONS}
              </div>
            </div>

            {item?.submittedOn && (
              <div className={styles.assignmentIconAndText}>
                <img src={submitedByIcon} />
                <div className={styles.assignmnetSubText}>
                  {constants.UPDATED_ON} {item?.submittedOn}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* pending submitted  */}
        {item?.status.toLowerCase() === "pending" && (
          <div className={styles.pendingContainer}>
            <div className={styles.pendingReviewNow}>
              {constants.REVIEW_NOW}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ClassDetailsAssignmentCard);
