import closeIconForNewNoteOverlay from "../../assets/images/closeIconForNewNoteOverlay.svg";
import styles from "./patternVideoOverlay.module.scss";
import ExampleAccordion from "./exampleAccordion/exampleAccordion";
import VideoPlayer from "../videoPlayerSection/videoPlayer";
import viewDocumentIcon from "../../assets/images/viewDocumentIcon.svg";
import { constants } from "../../constants/constants";

interface IPatternVideoOverlay {
  handlePatternOverlay: any;
  handlePatternVideoOverlayContent: any;
  patternCardData: {
    descriptionText: string;
    patternHeadingText: string;
  };
  videoUrl: string;
  patternExamples?: [];
}

interface IExampleMockDataItem {
  exampleName: string;
  ppt: string; //Change this as per API
}

const PatternVideoOverlay = ({
  handlePatternOverlay,
  handlePatternVideoOverlayContent,
  patternCardData,
  videoUrl,
  patternExamples,
}: IPatternVideoOverlay) => {
  return (
    <div className={styles.patternDetailsWrap}>
      <div className={styles.patternVideoOverlayHeader}>
        <div className={styles.headerText}>
          {patternCardData?.patternHeadingText}
        </div>
        <div>
          <img
            src={closeIconForNewNoteOverlay}
            className={styles.closeIcon}
            onClick={handlePatternOverlay}
            alt={closeIconForNewNoteOverlay}
          />
        </div>
      </div>
      <div className={styles.patternVideoOverlayContent}>
        <div className={styles.videoPlayerContainer}>
          <div className={styles.descriptionExampleContainer}>
            <div className={styles.subHeader}>{constants.DESCRIPTION}</div>
            <div className={styles.descriptionText}>
              {patternCardData?.descriptionText}
            </div>
          </div>
          <VideoPlayer videoUrl={videoUrl} />
          <div className={styles.videoPlayerBottomSection}>
            <div
              className={styles.videoDetailsContainer}
              onClick={() => {
                handlePatternVideoOverlayContent("viewDocument");
              }}
            >
              <div>
                <img src={viewDocumentIcon} alt={viewDocumentIcon} />
              </div>
              <div className={styles.videoDetailsText}>
                {constants.VIEW_DOCUMENT}
              </div>
            </div>
          </div>

          {patternExamples?.length && (
            <div className={styles.descriptionExampleContainer}>
              <div className={styles.subHeader}>{constants.EXAMPLES}</div>
              <div className={styles.accordionContainer}>
                {patternExamples &&
                  Array.isArray(patternExamples) &&
                  patternExamples.map(
                    (item: IExampleMockDataItem, index: number) => {
                      return (
                        <ExampleAccordion accordionItem={item} key={index} />
                      );
                    }
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.closeButtonContainer}>
        <button onClick={handlePatternOverlay}>{constants.CLOSE}</button>
      </div>
    </div>
  );
};

export default PatternVideoOverlay;
