import React from "react";
import styles from "./pptOverlay.module.scss";
import closeIcon from "../../assets/images/closeIcon.svg";
import SideBar from "../sidebar/sidebar";
import { PPTViewer } from "../pptViewer/pptViewer";
import { constants } from "../../constants/constants";

interface IPPTOverlay {
  closeOverlay: (type?: string | undefined) => void;
  pptOverlayData: {
    name: string;
    pptUrl: string;
  };
  handlePatternVideoOverlayContent?: any;
}

const PPTOverlay = ({
  closeOverlay,
  pptOverlayData,
  handlePatternVideoOverlayContent,
}: IPPTOverlay) => {
  return (
    <SideBar
      content={
        <div className={styles.pptWrapper}>
          <div className={styles.overlayHeaderStyles}>
            <div className={styles.headerSection}>
              <div className={`${styles.overlayHeaderText} ${styles.inter20}`}>
                {pptOverlayData?.name}
              </div>
              <div
                className={styles.closeIcon}
                onClick={() => closeOverlay("ppt")}
              >
                <img src={closeIcon} alt="close" />
              </div>
            </div>
          </div>
          <div className={styles.pptContainer}>
            <PPTViewer source={pptOverlayData?.pptUrl} />
            {handlePatternVideoOverlayContent && (
              <div className={styles.pptOverlayButtonsContainer}>
                <div className={styles.backToPattern}>
                  <button
                    onClick={() => handlePatternVideoOverlayContent("pattern")}
                  >
                    {constants.BACK_TO_PATTERN}
                  </button>
                </div>
                <div className={styles.close}>
                  <button onClick={() => closeOverlay()}>
                    {constants.CLOSE}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      }
      width="826px"
    />
  );
};

export default PPTOverlay;
