import React, { useEffect, useState } from "react";
import styles from "./testCard.module.scss";
import CompletedIcon from "../../assets/images/completedIcon.svg";
import SkippedIcon from "../../assets/images/skippedWorkIcon.svg";
import ReviewIcon from "../../assets/images/reviewWorkIcon.svg";
import PendingIcon from "../../assets/images/pendingWorkIcon.svg";
import DueTodayIcon from "../../assets/images/dueTodayIcon.svg";
import DueOnIcon from "../../assets/images/dueOnIcon.svg";
import TimerIcon from "../../assets/images/timerIcon.svg";
import QuestionIcon from "../../assets/images/questionCountIcon.svg";
import TickIcon from "../../assets/images/totalMarksIcon.svg";
import Mascot from "../../assets/images/mascotVSign.svg";
import CalenderIcon from "../../assets/images/resultDateIcon.svg";
import StarIcon from "../../assets/images/starIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { SolveAssignmentQuestionListViewAll } from "../../modules/learn/pages/solveAssignmentPage/solveAssignmentMetaData/solveAssignmentQuestionPopUp/questionListViewAll/solveAssignmentQuestionListViewAll";
import { useNavigate, useParams } from "react-router-dom";
import { routePaths } from "../../constants/routes";

interface ITestCard {
  status: "incomplete" | "skipped" | "review" | "complete" | string;
  data: any;
  isForReveiw?: boolean;
}

const getCardIcon = (status: any) => {
  if (status == "incomplete") return PendingIcon;
  if (status == "skipped") return SkippedIcon;
  if (status == "review" || "Review Pending") return ReviewIcon;
  if (status == "complete") return CompletedIcon;
};

const TestCard = ({ status, data, isForReveiw }: ITestCard) => {
  let { studentId } = useParams();
  const navigate = useNavigate();
  const [showQuestionListPopUp, setShowQuestionListPopUp] = useState(false);
  const questionData = useSelector(
    (state: any) => state.solveAssignmentReducer
  );
  const [acticveQuestionIndex, setActicveQuestionIndex] = useState(0);
  useEffect(() => {
    setActicveQuestionIndex(questionData.activeQuestionIndex);
  }, [questionData.activeQuestionIndex]);

  if (isForReveiw) {
    return (
      <div className={styles.homeworkReviewcard}>
        <div className={styles.homeworkReviewcard_icon}>
          <img src={getCardIcon(status)} alt="" />
          <span style={{ whiteSpace: "break-spaces", textAlign: "center" }}>
            {status}
          </span>
        </div>
        <div className={styles.homeworkReviewcard_card}>
          <p>{data?.topic?.chapter_name || data?.chapter_name}</p>
          <div className={styles.homeworkCardDetailItems}>
            <span>
              <QuestionIconSvg />
              {data.question_count} Questions
            </span>
            <span>
              <SubmitIcon />

              {`Submitted on ${data.submit_date}`}
            </span>
          </div>
        </div>
        <div className={styles.homeworkReviewcard_action}>
          <button
            onClick={() => {
              if (data.testpaper_id) {
                //dispatch({ type: "UPDATE_QUESTION_INDEX", payload: 0 });
                navigate(
                  routePaths.reviewTest
                    .replace(":id", `${data.testpaper_id}`)
                    .replace(":studentId", `${studentId}`)
                );
              }
            }}
          >
            REVIEW NOW
          </button>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className={styles.testCardContainer}>
        <div className={styles.testCard}>
          <div className={styles.testCardIcon}>
            <img src={getCardIcon(status)} alt="" />
            <span>{status}</span>
          </div>
          <div className={styles.testCardDetails}>
            <p>{data?.topic?.chapter_name || "1. Polynomials Basics"}</p>
            <div className={styles.testCardDetailItems}>
              {status != "complete" && (
                <span>
                  <img src={TimerIcon} alt="" />
                  {data.test_time + " Mins" || "30 Min"}
                </span>
              )}
              <span>
                <img src={QuestionIcon} alt="" />
                {data.question_count || 12} Questions
              </span>
              {status != "complete" && (
                <span>
                  <img src={TickIcon} alt="" />
                  24 Marks
                </span>
              )}
              <span>
                <img
                  src={status == "incomplete" ? DueTodayIcon : DueOnIcon}
                  alt=""
                />
                {/* if status is pending and due date is today show Due today at time
              else show Submit by (date) */}
                {/* {status == "incomplete" && "Due today 6pm"}

                {status == "review" && "Submitted on 12 Jul ‘23"}
                {status == "skipped" && "Submit by 10 Jul ‘23"}
                {status == "complete" && "Submitted on 12 Jul ‘23"} */}
                {data.submit_date && data.submit_date}
              </span>
            </div>
          </div>
          {status == "complete" && (
            <>
              <div className={styles.completedRemark}>
                <span>You have done better than 87% of your classmates.</span>
                <img src={Mascot} alt="" />
              </div>
              <button className={styles.retakeButton}>Retake</button>
            </>
          )}
          <div className={styles.testCardAction}>
            {status == "incomplete" && (
              <span className={styles.testCardActionRemark}>
                <img src={CalenderIcon} alt="" /> Result Date 24 Jul ‘23
              </span>
            )}
            {status == "complete" && (
              <span className={styles.testCardActionRemark}>
                <img src={StarIcon} alt="" /> 21/24 scored
              </span>
            )}
            <button
              onClick={() => {
                //   dispatch({ type: "SET_ASSIGNMENT_LEVEL", payload: 1 });
                //   setShowAssignmentListPopUp(true);
                setShowQuestionListPopUp(true);
              }}
            >
              {status == "incomplete" && "SOLVE NOW"}

              {status == "review" && "VIEW"}
              {status == "skipped" && "VIEW"}
              {status == "complete" && "VIEW RESULT"}
            </button>
          </div>
        </div>
        {status == "skipped" && (
          <div className={styles.testCardFootNote}>
            <p>
              <img src={DueTodayIcon} alt="" />
              You missed this Test. Please contact your faculty to retake
            </p>
          </div>
        )}
      </div>
      {
        showQuestionListPopUp && (
          <SolveAssignmentQuestionListViewAll
            title={"Test"}
            closePopUp={() => setShowQuestionListPopUp(!showQuestionListPopUp)}
            activeQuestionIndex={acticveQuestionIndex}
            setActiveQuestionIndex={setActicveQuestionIndex}
            assignmentLevel={1}
            topicIdProp={data.testpaper_id}
            fromAssignmentPage={true}
            isFromTestPage={true}
          />
        ) //topicIdProp={item.id}
      }
    </>
  );
};

export default TestCard;

const SubmitIcon = ({ hasColoredBackground }: any) => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0064 8.25033V4.50154C15.0064 3.67267 14.3345 3.00073 13.5056 3.00073H4.5008C3.67193 3.00073 3 3.67267 3 4.50154V13.4991C3 14.328 3.67193 14.9999 4.5008 14.9999H8.25321"
          stroke={hasColoredBackground ? "#fff" : "#6862E0"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.00195 1.5L6.00195 3.0008"
          stroke={hasColoredBackground ? "#fff" : "#6862E0"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.0049 1.5L12.0049 3.0008"
          stroke={hasColoredBackground ? "#fff" : "#6862E0"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 9.54545V12L13.6364 13.6364M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z"
          stroke={hasColoredBackground ? "#fff" : "#6862E0"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

const QuestionIconSvg = ({ hasColoredBackground }: any) => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
          stroke={hasColoredBackground ? "#fff" : "#6862E0"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 14C9.51777 14 9.9375 13.5803 9.9375 13.0625C9.9375 12.5447 9.51777 12.125 9 12.125C8.48223 12.125 8.0625 12.5447 8.0625 13.0625C8.0625 13.5803 8.48223 14 9 14Z"
          fill="white"
        />
        <path
          d="M9 10.25V9.625C9.43265 9.625 9.85558 9.49671 10.2153 9.25634C10.575 9.01597 10.8554 8.67433 11.021 8.27462C11.1866 7.87491 11.2299 7.43507 11.1455 7.01074C11.0611 6.58641 10.8527 6.19663 10.5468 5.89071C10.2409 5.58478 9.85109 5.37644 9.42676 5.29203C9.00243 5.20763 8.56259 5.25095 8.16288 5.41651C7.76317 5.58208 7.42153 5.86246 7.18116 6.22219C6.94079 6.58192 6.8125 7.00485 6.8125 7.4375"
          stroke={hasColoredBackground ? "#fff" : "#6862E0"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
