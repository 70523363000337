import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styles from './modalWrapperResultAssignment.module.scss';
import cancelIcon from '../../../assets/images/closeIcon.svg'
import rocketMan from '../../../assets/images/rocketMan.svg'
import { useNavigate } from 'react-router-dom';
import { routePaths } from '../../../constants/routes';
import { useDispatch } from 'react-redux';
import correctIcon from '../../../assets/images/mcqCorrectIcon.svg';
import incorrectIcon from '../../../assets/images/mcqIncorrectIcon.svg';
import notAnsweredIcon from '../../../assets/images/mcqNotAnsweredIcon.svg';

interface IModalWrapperResult {
    openModal: boolean;
    setOpenModal: any;
    questionData?: any;
    topicId: any;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: "none",
    boxShadow: 2,
    outline: 'none',
    bgcolor: 'white',
    width:400,
    display: "grid",
    borderRadius: "16px"
};
export const ModalWrapperResult = (
    props: IModalWrapperResult
) => {
    const {
        setOpenModal,
        questionData,
        topicId
    } = props;

    const navigate = useNavigate();

    const resultScoreArr = (questionData?.filter((item: any) => item.mcqResult === true));
    const resultScore = resultScoreArr?.reduce((a: any, b: any) => a + Number(b.marks_alloted), 0);
    const totalScore = questionData?.reduce((a: any, b: any) => a + Number(b.marks_alloted), 0);
    const dispatch = useDispatch();

    return (
        <div >
            <Modal
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div
                        className={styles.modalHeader}
                    >
                        {/* <div className={styles.container1}>
                            <div className={styles.modalContent}>
                                <img alt='rocketMan'
                                    className={styles.rocketManStyle}
                                    src={rocketMan} />
                            </div>
                        </div> */}

                        <div className={styles.resultSection}>
                            <div>
                                <div className={styles.scoreText}>
                                    <div>  You Scored {resultScore}/{totalScore}</div>
                                    <div className={styles.cancelIconContainer}> <img alt='cancleIcon'
                                        className={styles.cancelIcon}
                                        onClick={() => {
                                            dispatch({
                                                type: "UPDATE_MCQ", payload: [
                                                    {
                                                        "open": false,
                                                    },
                                                ]
                                            })
                                            setOpenModal(false)
                                        }
                                        }
                                        src={cancelIcon} />
                                    </div>
                                </div>
                                {/* <div className={styles.scoreTextContent}>Good Job! Your understanding of the topic has increased by 20%</div> */}
                                {/* <div className={styles.horizontaliLine}></div> */}
                                <div>
                                    <div className={styles.questionText}>{
                                        questionData?.filter((item: any) => item.visited === "Completed").length
                                    } of {questionData.length} Questions Attempted</div>
                                    <div className={styles.detailedQuestionNumbers}>
                                        <div className={styles.divisionNumber}>
                                            <div className={styles.icon}
                                            // className={`${styles.mcqResultCorrect} ${styles.legendIcon}`}
                                            >
                                                <img src={correctIcon} alt="correct" />
                                            </div>
                                            <div><span className={styles.number}>
                                                {
                                                    questionData?.filter((item: any) => item.mcqResult === true).length
                                                }
                                            </span>Correct</div>
                                        </div>
                                        <div className={styles.divisionNumber}>
                                            <div className={styles.icon}
                                            // className={`${styles.mcqResultIncorrect} ${styles.legendIcon}`}
                                            ><img src={incorrectIcon} alt="incorrect" /></div>
                                            <div><span className={styles.number}>{
                                                questionData?.filter((item: any) => item.mcqResult === false).length - (questionData?.filter((item: any) => item.visited !== "Completed").length)
                                            }</span>Incorrect</div>
                                        </div>
                                        <div className={styles.divisionNumber}>
                                            <div className={styles.icon}
                                            // className={`${styles.mcqResultNA} ${styles.legendIcon}`}
                                            ><img src={notAnsweredIcon} alt="notAnswered" /></div>
                                            <div><span className={styles.number}>{
                                                questionData?.filter((item: any) => item.visited !== "Completed").length
                                            }
                                            </span>N/A</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.questionDivisionGraph}>

                                    <div className={styles.correct} style={{
                                        width: `${(questionData?.filter((item: any) => item.mcqResult == true).length / questionData.length) * 100
                                            }%`
                                    }}></div>
                                    <div className={styles.incorrect} style={{
                                        width: `${(questionData?.filter((item: any) => item.mcqResult === false).length / questionData.length) * 100
                                            + (questionData?.filter((item: any) => item.mcqResult == true).length / questionData.length) * 100
                                            - (questionData?.filter((item: any) => item.visited !== "Completed").length / questionData.length) * 100

                                            }%`
                                    }}></div>
                                    <div className={styles.notAnswered} style={{
                                        width: `${(questionData?.filter((item: any) => item.visited !== "Completed").length / questionData.length) * 100

                                            }%`
                                    }}></div>
                                </div>
                            </div>
                            <div
                                className={styles.buttonContainer}
                            >
                                <button className={styles.cancelButton}
                                    onClick={() => {
                                        dispatch({
                                            type: "UPDATE_MCQ", payload: [
                                                {
                                                    "open": false,
                                                },
                                            ]
                                        })
                                        setOpenModal(false)
                                        navigate(`${routePaths.solveMCQ.replace(":testId", `${topicId}`)}?topic_slno=${questionData[0].topic_slno}`)
                                    }}
                                >RETAKE MCQ</button>
                                <div className={styles.primaryButtonWrap}> <button
                                    onClick={() => {
                                        dispatch({
                                            type: "UPDATE_MCQ", payload: [
                                                {
                                                    "open": false,
                                                },
                                            ]
                                        })
                                        setOpenModal(false)
                                        navigate(routePaths.MCQResult.replace(":topicId", `${questionData[0].topicId}`))
                                    }}
                                    className={styles.primaryButton}>VIEW FULL RESULT</button></div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div >
    )
}


