const ApiEdpoints = {
  LOGIN: "apiTokenAuth/",
  REGISTER: "registerSerializer/",
  STUDENT_INFO: "personalInformation/",
  NOTIFICATIONSCOUNT: "notifications/count/",
  CHAPTER_LIST: "courseTopicIndex/", // /courseTopicIndex/:id
  FETCHING_PATTERNS: "fetchingPatterns",
  CHAPTER_TOPICS_OVERVIEW: "ChapterTopicsOverview",
  CHAPTERS_WISE_DATA: "chapterWiseData",
  CHAPTERS_WISE_DATA_BY_ID: "questionslistDetails",
  COURSELIST_BY_SUBTOPIC_ID: "courselistDetails",
  ASSIGNMENT_MCQ_QUESTION: "GetAssignment/",
  ASSIGNMENT_MCQ_QUESTION_NEW: "newGetAssignment/",
  TEST_MCQ_QUESTION: "mcq/",
  ALL_TEST_CASE_VERIFYING: "alltestcases_verifying/",
  DYNAMIC_PSGA: "dynamic_psga/",
  ASSIGNMENT_SUBMISSION_DATA: "StudentsubmitAssignments",
  MCQ_RESULTS: "student_mcq_results",
  MIND_MAP: "mindMap",
  ONBOARDING_QUESTIONS: "onboarding_questions/",
  PATTERN_MASTERY: "patternsMastry",
  PATTERN_MASTERY_QUESTIONS_LIST: "patternBasedQuestion",
  FETCHING_PATTERNS_BY_CHAPTER_ID: "fetchingPatterns",
  PATTERNCARD: "patterndetails",
  ASSIGNMENT_DETAILS: "assignment_details",
  PATTERNS_IN_CHAPTER: "patterns_in_chapter",
  ANALYTICS_GRAPH: "student_analytics",
  LIST_OF_ASSIGNMENTS: "listofAssignments",
  ASSIGNMENT_QUESTION_LIST: "assignment_question_list",
  AllTESTCASES: "alltestcases_verifying/",
  ASSIGNED_QUESTION_LIST: "assigned_question_list",
  ASSIGNMENT_DESCRIPTIVE_QUESTIONS: "assignment_descriptive_questions",
  CLASS_LIST: "classList",
  STUDENT_LIST_BY_ID: "studentList",
  CHAPTER_WISE_PATTERNS: "chapterWisePatterns",
  CLASS_ANALYTICS: "class_analytics",
  PROGRESS_ANALYTICS: "progress_analytics",
  LIST_OF_QUESTIONS_MENTOR: "list_of_questions_mentor",
  GET_SUBTOPICS: "getSubTopics",
  STUDENT_LIST_WITH_ASSIGNMENT: "studentListWithAssignment",
  SUBMIT_MENTOR_RESPONSE: "MentorAddedReview/",
  PATTERNS_TO_FOCUS_ON: "patternsToFocusOn",
  GET_STUDENT_DETAILS_AND_PATTERNS_TO_FOCUS: "studentDetails",
  STUDENT_ANALYTICS: "studentAnalytics",
  ASSIGNMETS_SUBMITTED_BY_CLASS: "submitted_assignments_class/",
  TOPIC_TO_FOCUS_ON: "topictofocuson",
  GET_TOPICS: "getTopics",
  PATTERN_VIEW_COUNT: "patternViewCount/",
  GENERATETESTPAPER:"generatetestpaper",
  GET_NOTIFICATIONS:"notifications",
  MARK_AS_READ_NOTIFICATIONS:"notifications/mark-as-read/",

};

export const queryConstants = {
  CHAPTER_ID: "chapter_id",
};
export const enum HTTPMethods {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH"
}

export const baseUrl = "https://new-mathbked.aieducator.com/";
export default ApiEdpoints;
