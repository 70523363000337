import React, { useState, useEffect, useRef } from "react";
import classes from "./filterBar.module.scss";
import Dropdown from "../dropdown/dropdown";
import sortIcon from "../../assets/images/sortIcon.svg";
import checkIcon from "../../assets/images/checkIcon.svg";
import { constants } from "../../constants/constants";
import useOutsideAlerter from "../../utils/useOutsideAlerter";

/**
 * Usage
 * 
  const statuses = ["All", "Pending", "Skipped", "In Review", "Completed"];
  const patterns = ["pattern1", "pattern2", "pattern3", "pattern4"];
  const questions = ["question1", "question2", "question3", "question4"];

  const patternProps = [
    { data: patterns, defaultLabel: "All Patterns" },
    { data: questions, defaultLabel: "All Questions" },
  ];

  <FilterBar
    statuses={statuses}
    sortData={statuses}
    patternsData={patternProps}
  />
 */

const Patterns = ({
  patternsData,
  selectedValue,
  initialSelectedPatternsValues,
  setUpdatedSelectedPatternValues,
  setDropdownSelectedValueWithId,
}: any) => {
  const [pattern, setPattern] = useState("");

  const onSelect = (value: any) => {
    setPattern(value);
    selectedValue(value);
  };

  return (
    <>
      {patternsData?.map((pattern: any, index: number) => (
        <Dropdown
          minWidth={"177px"}
          key={index}
          options={pattern.data?.map((x: any) => x)}
          defaultLabel={pattern.defaultLabel}
          selectedValue={onSelect}
          patternIndex={index}
          initialSelectedPatternsValues={initialSelectedPatternsValues}
          setUpdatedSelectedPatternValues={setUpdatedSelectedPatternValues}
          setDropdownSelectedValueWithId={setDropdownSelectedValueWithId}
        />
      ))}
    </>
  );
};

/*
  asc - to set the ascending order of sort
  type of boolean
  if true - ascending order
  if false - descending order
*/
const Sort = ({ isSorting }: { isSorting: any }) => {
  const [sort, setSort] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const wrapperRef = useRef(null);
  const { showSortOverlay, setShowSortOverlay } = useOutsideAlerter(wrapperRef);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setShowSortOverlay(!showSortOverlay);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const handleSort = () => {
  //   let updatedSort = sort;
  //   if (updatedSort === "") {
  //     setSort("asc");
  //     updatedSort = "asc";
  //   } else if (updatedSort === "asc") {
  //     setSort("desc");
  //     updatedSort = "desc";
  //   } else {
  //     setSort("");
  //     updatedSort = "";
  //   }

  //   isSorting(updatedSort);
  // };
  const [sortStyle, setSortStyle] = useState(true);
  const handleSort = (sortType: string) => {
    setSortStyle(!sortStyle);
    setSort(sortType);
    isSorting(sortType);
  };
  return (
    // <button
    //   className={`${classes.sort} ${sort && classes.active})`}
    //   onClick={() => handleSort()}
    // >
    //   Sort
    // </button>
    <>
      <div
        className={classes.sortContainer}
        onClick={handleClick}
        ref={wrapperRef}
      >
        <div style={{ display: "flex" }}>
          <img src={sortIcon} className={classes.sortIcon} />
          <div className={classes.sortName}>Sort</div>
        </div>
        {showSortOverlay && (
          <div className={classes.overlayWrap}>
            <div className={classes.overlayTip}></div>
            <div className={classes.popover}>
              <div
                className={`${classes.sortText} ${classes.inter14}`}
                onClick={() => handleSort("asc")}
              >
                <div className={classes.tickIcon}>
                  {sort === "asc" && <img src={checkIcon} alt="tick" />}
                </div>
                <div
                  className={`${classes.sortTypeText} ${
                    sort === "asc" && classes.selected
                  }`}
                >
                  {constants.DATE_ASC}
                </div>
              </div>
              <div
                className={`${classes.sortText} ${classes.inter14}`}
                onClick={() => handleSort("desc")}
              >
                <div className={classes.tickIcon}>
                  {(sort === "desc" || sortStyle) && (
                    <img src={checkIcon} alt="tick" />
                  )}
                </div>
                <div
                  className={`${classes.sortTypeText} ${
                    (sort === "desc" || sortStyle) && classes.selected
                  }`}
                >
                  {constants.DATE_DSC}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <div className={classes.popover}>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
                padding: "20px",
                background:
                  "linear-gradient(102.61deg, #EBF1F4 8.17%, #F8FAFF 94.03%)",
                border: "1px solid #AEB6CA",
                boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.16)",

                "&::before": {
                  content: "''",
                  position: "absolute",
                  top: "0px",
                  left: "110px",
                  width: "0",
                  height: "0",
                  borderLeft: "18px solid transparent",
                  borderRight: "18px solid transparent",
                  borderBottom: "20px solid black",
                  background:
                    "linear-gradient(102.61deg, #EBF1F4 8.17%, #F8FAFF 94.03%)",
                  boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.16)",
                  //zIndex: "-1",
                },
              },
            }}
          >
            {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        {/* <div className={classes.mostRecentText}>{constants.MOST_RECENT}</div>
            <div className={classes.dateAscText}>{constants.DATE_ASC}</div>
            <div className={classes.dateDscText}>{constants.DATE_DSC}</div>
          </Popover>
        </div>  */}
      </div>
    </>
  );
};
interface IFilterBar {
  statuses?: any;
  selectedStatus?: any;
  sortFunc?: any;
  showSorting?: any;
  patternsData?: any;
  selectedPattern?: any;
  initialSelectedPatternsValues?: { index: number; value: string }[];
  setUpdatedSelectedPatternValues?: any;
  setDropdownSelectedValueWithId?: any;
  innerDropdownNeeded?: boolean;
  hideFilter?: boolean;
}
const FilterBar = ({
  statuses = [],
  selectedStatus,
  sortFunc,
  patternsData = [],
  selectedPattern,
  showSorting = true,
  initialSelectedPatternsValues = [],
  setUpdatedSelectedPatternValues,
  setDropdownSelectedValueWithId,
  innerDropdownNeeded = false,
  hideFilter = false,
}: IFilterBar) => {
  const defaultOne = "All";

  const [activeStatus, setActiveStatus] = useState([defaultOne]);
  const [content, setContent] = useState(activeStatus);
  const toggleStatus = (active: boolean, status: string) => {
    if (active) {
      setActiveStatus(
        activeStatus.filter((activeStatus) => activeStatus !== status)
      );
    } else {
      setActiveStatus((prevStatus) => [status]);
    }
  };

  const handleStatusClick = (status: string) => {
    if (status == "All") {
      setActiveStatus([status]);
      return;
    }
    if (activeStatus.length > 0 && activeStatus.length < statuses.length - 1) {
      if (status !== defaultOne) {
        setActiveStatus(activeStatus.filter((status) => status !== defaultOne));
      } else {
        setActiveStatus(activeStatus.filter((status) => status === defaultOne));
      }
      const active = activeStatus.includes(status);
      toggleStatus(active, status);
    } else if (
      status !== defaultOne &&
      !activeStatus.includes(defaultOne) &&
      activeStatus.length === statuses.length - 1
    ) {
      const active = activeStatus.includes(status);
      toggleStatus(active, status);
    }
  };

  const sortingData = (isSorting: any) => {
    if (sortFunc) sortFunc(isSorting);
  };

  const handleSelectedValue = (value: any) => {};

  const empty = activeStatus.length === 0;

  useEffect(() => {
    setActiveStatus([defaultOne]);
  }, [empty]);

  useEffect(() => {
    if (selectedStatus) selectedStatus(activeStatus);
    setContent([...activeStatus]);
  }, [activeStatus]);

  useEffect(() => {
    if (selectedStatus) selectedStatus(content);
  }, [content]);

  return (
    <>
      <div className={classes.filterBar}>
        {statuses?.length > 0 && (
          <div
            className={`${classes.status_container} ${
              hideFilter ? classes.hideStatusContainer : ""
            }`}
          >
            {statuses.map((status: any, ind: number) => (
              <button
                className={`
                ${classes.status_button}
                ${
                  activeStatus[activeStatus.indexOf(status)] === status &&
                  classes.active
                }`}
                onClick={() => handleStatusClick(status)}
                key={`${status}_${ind}`}
              >
                {status}
              </button>
            ))}
          </div>
        )}
        {patternsData.length > 0 && (
          <div className={classes.filterPatternsContainer}>
            <Patterns
              patternsData={patternsData}
              selectedValue={handleSelectedValue}
              initialSelectedPatternsValues={initialSelectedPatternsValues}
              setUpdatedSelectedPatternValues={setUpdatedSelectedPatternValues}
              setDropdownSelectedValueWithId={setDropdownSelectedValueWithId}
              innerDropdownNeeded={innerDropdownNeeded}
            />
          </div>
        )}
        {showSorting && <Sort isSorting={sortingData} />}
      </div>
    </>
  );
};

export default FilterBar;
