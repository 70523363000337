import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router";
import classes from './patternDetails.module.scss';
import backArrow from "../../../../../../assets/images/backArrowIcon.svg";
import prevTopic from "../../../../../../assets/images/prevTopicIcon.svg";
import nextContentIcon from "../../../../../../assets/images/nextContentIcon.svg";
import contentIcon from "../../../../../../assets/images/contentIcon.svg";
import PatternDetailsVideoDescription from './patternDetailsVideoDescription';
import mockData from './patternDetailsMock.json';
import { routePaths } from '../../../../../../constants/routes';
import SideBar from '../../../../../../common/sidebar/sidebar';
import PatternsDetailsContents from './patternsDetailsContents';
import { constants } from '../../../../../../constants/constants';

const PatternDetails = () => {
    const navigate = useNavigate();
    const [patternDetailsId, setPatternDetailsId] = useState(0);
    const [patternListData, setPatternListData] = useState<any>([]);
    const [patternListItem, setPatternListItem] = useState<any>();
    const [patternListItemIndex, setPatternListItemIndex] = useState<any>(-1);
    const [showOrHideContentsToggle, setShowOrHideContentsToggle] = useState(false);

    const getPatternsData = () => {
        const patternId = localStorage.getItem(constants.PATTERN_DETAILS_ID);
        try {
            if (patternId) {
                setPatternDetailsId(+patternId);
                //make api call
                setPatternListData(mockData);
                localStorage.removeItem(constants.PATTERN_DETAILS_ID)
            }
        }
        catch (err) {
            console.log('error', err);
        }
    }

    useEffect(() => {
        getPatternsData();
    }, []);

    useEffect(() => {
        const index = patternListData?.findIndex((x: any) => x.patternId === patternDetailsId);
        if (index !== -1) {
            setPatternListItem(patternListData[index]);
            setPatternListItemIndex(index)
        }
    }, [patternDetailsId]);

    const changePatternVideo = (event: any, changeType: string) => {
        const newPatternId = patternListData[changeType === 'next' ? patternListItemIndex + 1 : patternListItemIndex - 1].patternId;
        const routePath = routePaths.patternDetails?.replace(':patternId', newPatternId || 0);
        setPatternDetailsId(newPatternId);
        navigate(routePath);
        event.stopPropagation();
    }
    const showOrHideContentsToggleFn = () => {
        setShowOrHideContentsToggle(false);
    }

    return (
        <div className={classes.patternDetailsPageMainWrapper}>

            {/* Nav section */}

            <div className={classes.navSection}>
                <div className={classes.arrowIcon}><img src={backArrow} alt="back" /></div>
                <div className={`${classes.inter16} ${classes.navBackText}`}>Application of large number in real life</div>
            </div>

            {/* body section */}

            <div className={classes.patternDetailsBodyWrapper}>
                <div className={classes.patternVideoSectionHeader}>

                    {patternListItem &&
                        <div className={classes.videoDetailsSectionHeader}>
                            <div className={classes.videoSectionNameAndBtns}>
                                <div className={`${classes.videoSectionName} ${classes.inter24}`}>{patternListItem?.patternName}</div>

                                <div className={classes.videoSectionHeaderButtonsResolution}>
                                    <div className={`${classes.topicButton} ${patternListItemIndex === 0 && classes.disabled}`}
                                        onClick={e => {
                                            if (patternListItemIndex !== 0)
                                                changePatternVideo(e, 'prev')
                                        }}><img src={prevTopic} alt="prev topic" /></div>
                                    <div className={`${classes.topicButton} ${classes.nextButton} ${patternListItemIndex >= patternListData?.length - 1 && classes.disabled}`}
                                        onClick={e => {
                                            if (patternListItemIndex < patternListData?.length - 1)
                                                changePatternVideo(e, 'next')
                                        }} ><img src={nextContentIcon} alt="prev topic" /></div>
                                </div>
                            </div>

                            <div className={classes.videoSectionHeaderActions} >
                                <button className={`${classes.commonButton} ${classes.contentButton}`} type="button" onClick={() => setShowOrHideContentsToggle(true)}>
                                    <div className={classes.flex}>
                                        <img src={contentIcon} alt='content' className={`${classes.buttonIcon} ${classes.videoSectionHeaderActionsImg}`} />
                                        <div className={classes.videoSectionHeaderActionsTxt}>CONTENT</div>
                                    </div>
                                </button>


                                <div className={classes.videoSectionHeaderButtons}>
                                    <div className={`${classes.topicButton} ${patternListItemIndex === 0 && classes.disabled}`}
                                        onClick={e => {
                                            if (patternListItemIndex !== 0)
                                                changePatternVideo(e, 'prev')
                                        }}
                                    ><img src={prevTopic} alt="prev topic" /></div>
                                    <div className={`${classes.topicButton} ${classes.nextButton} ${patternListItemIndex >= patternListData?.length - 1 && classes.disabled} `}
                                        onClick={e => {
                                            if (patternListItemIndex < patternListData?.length - 1)
                                                changePatternVideo(e, 'next')
                                        }}
                                    ><img src={nextContentIcon} alt="prev topic" /></div>
                                </div>
                            </div>

                        </div>}
                </div>

                <div className={classes.patternVideoBodyWrapper}>
                    {showOrHideContentsToggle && <div className={classes.contentsListWrapper}>
                        <SideBar width='479px' content={<PatternsDetailsContents patternDetailsId={patternDetailsId} setPatternDetailsId={setPatternDetailsId} showOrHideContentsToggleFn={showOrHideContentsToggleFn} patternListData={patternListData} />} />
                    </div>}
                    {patternListItem && <PatternDetailsVideoDescription patternListItem={patternListItem} />}
                </div>
            </div>

        </div>
    )
}

export default React.memo(PatternDetails);