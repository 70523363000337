import React from 'react';
import { useNavigate } from "react-router";
import classes from './patternDetails.module.scss';
import closeMarkIcon from "../../../../../../assets/images/closeMarkIcon.svg";
import statusCorrectMarkIcon from "../../../../../../assets/images/statusCorrectMarkIcon.svg";
import activeVideoPlayIcon from "../../../../../../assets/images/activeVideoPlayIcon.svg";
import inActiveVideoPlayIcon from "../../../../../../assets/images/inActiveVideoPlayIcon.svg";
import { routePaths } from '../../../../../../constants/routes';
import closeIcon from "../../../../../../assets/images/closeIcon.svg";

interface IPatternsDetailsContents {
    patternDetailsId: number;
    setPatternDetailsId: any;
    showOrHideContentsToggleFn: any;
    patternListData: object[]
}
const PatternsDetailsContents = ({ patternDetailsId, setPatternDetailsId, showOrHideContentsToggleFn, patternListData }: IPatternsDetailsContents) => {
    const navigate = useNavigate();

    const selectedContent = (event: any, item: any) => {
        const routePath = routePaths.patternDetails?.replace(':patternId', item.patternId || 0);
        setPatternDetailsId(item.patternId);
        navigate(routePath);
        event.stopPropagation();
        showOrHideContentsToggleFn();
    }

    return (
        <div className={classes.patternVideoContentsContainer}>
            <div className={classes.overlayHeaderStyles}>
                <div className={classes.headerSection}>
                    <div className={`${classes.overlayHeaderText} ${classes.inter20}`}>Content</div>
                    <div className={classes.closeIcon} onClick={showOrHideContentsToggleFn}>
                        <img src={closeIcon} alt="close" /></div>
                </div>
            </div>
            <div className={classes.bodySection}>
                {patternListData?.map((item: any, ind: number) =>
                    <div className={`${classes.videoPageContentsList} ${item.patternId === patternDetailsId && classes.videoPageContentsListActive} ${item.patternId === patternDetailsId && ind === 0 && classes.videoPageContentsListfrstIdxActive}`} key={`${item?.name}_${item.patternId}_${ind}`} >
                        <div className={classes.videoContentsListItemIcon}> <img src={item.patternId === patternDetailsId ? activeVideoPlayIcon : inActiveVideoPlayIcon} alt='videoIcon' /> </div>
                        <div className={classes.videoPageContentName} onClick={(e) => selectedContent(e, item)} >{item?.patternName}</div>
                        <div><img src={statusCorrectMarkIcon} /></div>
                    </div>)}
            </div>
        </div>
    )
}

export default React.memo(PatternsDetailsContents);