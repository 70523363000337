import VideoPlayer from '../../../../common/videoPlayerSection/videoPlayer';
import PDFReader from '../../../../components/pdfViewer/PDFReader';
import classes from './topicPdfView.module.scss'
const TopicPdfView = () => {
    let url = "https://storage-aieducator.s3.ap-south-1.amazonaws.com/mnist/Articles/Class - 8, Ex - 1.2.mp4"
    return (
        <div>
            <iframe
    src={`https://view.officeapps.live.com/op/embed.aspx?src=${"https://storage-aieducator.s3.ap-south-1.amazonaws.com/mnist/Articles/Class 10 - ex -1.1.pptx"}`}
    width="100%"
    height="600px"
    frameBorder="0"
>
</iframe>
            {/* <PDFReader /> */}
        </div>
    )
};

export default TopicPdfView;