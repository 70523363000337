import React, { useState } from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import DashboardCard from "./dashboardCard/dashboardCard";
import styles from "./dashboardPage.module.scss";
import SideBar from "../../common/sidebar/sidebar";
import StudyPreferences from "./studyPreferences/studyPreferences";

export const Dashboard = () => {
  const studentDashboardBreadcrumb = [
    {
      label: "Dashboard",
      link: "/dashboard",
    },
  ];
  const [hideOverlay, setHideOverlay] = useState(false);
  const handleOverlayClose = (type: String) => {
    setHideOverlay(false);
  };
  return (
    <div className={styles.dashboardContainer}>
      <DashboardCard setHideOverlay={setHideOverlay} />
      {/* {hideOverlay && (
        <SideBar
          content={<StudyPreferences closeOverlay={handleOverlayClose} />}
          width="489px"
        />
      )} */}
    </div>
  );
};
