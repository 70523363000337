/*Break all the params down. We are using this function to get the chapter and topic Index in the topicVideo page
This function is a generic function which can be used to get params */

export function getParams(str: string) {
  if(!str) return ""
  var queryString = str || window.location.search || "";
  var keyValPairs: any = [];
  var params: any = {};
  queryString = queryString.replace(/.*?\?/, "");

  if (queryString.length) {
    keyValPairs = queryString.split("&");
    for (let pairNum in keyValPairs) {
      var key = keyValPairs[pairNum].split("=")[0];
      if (!key.length) continue;
      if (typeof params[key] === "undefined") params[key] = [];
      params[key].push(keyValPairs[pairNum].split("=")[1]);
    }
  }
  return params;
}
