import { useEffect, useState } from "react";

const usePagination = (array: any[], range: number, detailedViewRef: any) => {
  const [page, setPage] = useState(1);
  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationEnd, setPaginationEnd] = useState(range);
  useEffect(() => {
    detailedViewRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [detailedViewRef, page]);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    const finalValue = Math.ceil(array.length / range);
    if (finalValue === value) {
      setPaginationStart(range * (value - 1));
      setPaginationEnd(array.length);
    } else {
      setPaginationStart(range * (value - 1));
      setPaginationEnd(range * value);
    }
  };

  return {
    page,
    paginationStart,
    paginationEnd,
    handlePaginationChange,
    setPage,
    setPaginationStart,
    setPaginationEnd,
  };
};

export default usePagination;
