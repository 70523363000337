import { makeAxiosRequest } from "aie_common";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import closeIcon from "../../assets/images/closeIcon.svg";
import complexityIcon from "../../assets/images/complexityBarIcon.svg";
import questionIcon from "../../assets/images/questionsIcon.svg";
import thinkingMascot from "../../assets/images/ThinkingAvatar.svg";
import ApiEdpoints, { HTTPMethods } from "../../constants/api_endpoints";
import { constants } from "../../constants/constants";
import { routePaths } from "../../constants/routes";
import { formatDate } from "../../utils/dateFormatter";
import styles from "./testStartOverlay.module.scss";

interface ITestStartOverlay {
  data: IData;
  closeOverlay: any;
  type: "assignment" | "mcq";
  idQuestion?: string;
  topicId?: any;
}

interface IHistoryTableData {
  submission_date: string;
  timeSpent: number;
  marks_scored: number;
  total_marks: number;
}

interface IData {
  name: string;
  id: any;
  index: string;
  topicSerialNumber: string;
}

export const TestStartOverlay = (props: ITestStartOverlay) => {
  const navigate = useNavigate();
  const [testData, setTestData] = useState<any>({});
  const { type } = props;
  const solveAssignment = () => {
    if (type === "assignment") {
      let path = routePaths.solveAssignment.replace(
        ":topicId",
        `${props.topicId}`
      );
      //index=${props?.data?.index} - should come from api
      path = `${path}?&topic_slno=${props?.data?.topicSerialNumber}`;
      navigate(path);
    } else if (type === "mcq") {
      let path = routePaths.solveMCQ.replace(":testId", `${props.topicId}`);
      //index=${props?.data?.index} - should come from api
      path = `${path}?&topic_slno=${props?.data?.topicSerialNumber}`;
      navigate(path);
    } else {
      return;
    }
  };

  const fetchTestData = async (type: string, topicId: number) => {
    const payload = {
      url: `${ApiEdpoints.ASSIGNMENT_DETAILS}?topic_id=${topicId}&test_type=${type}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const testDetails: any = await makeAxiosRequest(payload);
      if (testDetails.status !== 200) {
        throw new Error("Error!");
      }
      setTestData({
        ...testDetails.data.data,
        assignment_history: testDetails?.data?.data?.assignment_history?.map(
          (assignment: IHistoryTableData) => {
            return {
              ...assignment,
              submission_date: formatDate(assignment.submission_date),
            };
          }
        ),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleTestResultHistory = (topicId:number) => {
    navigate(routePaths.MCQResult.replace(":topicId",`${topicId}`))
  }

  useEffect(() => {
    fetchTestData(props?.type, props?.data?.id);
  }, []);

  return (
    <section className={styles.assignmentStartWrap}>
      <div className={styles.overlayHeaderStyles}>
        <div className={styles.headerSection}>
          <div className={`${styles.overlayHeaderText} ${styles.inter20}`}>
            {props?.data?.name}
          </div>
          <div
            className={styles.closeIcon}
            onClick={() => props.closeOverlay("mcq")}
          >
            <img src={closeIcon} alt="close" />
          </div>
        </div>
        <div className={styles.subheaderSection}>
          <div className={styles.eachItem}>
            <div>
              <img src={questionIcon} alt="question" />
            </div>
            <div className={`${styles.label} ${styles.inter14}`}>
              {testData.questions_count} {constants.QUESTIONS}
            </div>
          </div>
          {props?.type === "assignment" && (
            <div className={styles.eachItem}>
              <div>
                <img src={complexityIcon} alt="icon" />
              </div>
              <div className={`${styles.label} ${styles.inter14}`}>
                {constants.INTERMEDIATE}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.bodySection}>
        {
          // Patterns Covered
          props?.type === "assignment" && (
            <div className={styles.coveredPatterns}>
              <div className={`${styles.headerSection} ${styles.inter20}`}>
                {constants.PATTERNS_COVERED}
              </div>
              <div className={styles.patternsList}>
                {testData?.patterns_list &&
                  Array.isArray(testData?.patterns_list) &&
                  testData?.patterns_list.map(
                    (patternListName: string, index: number) => {
                      return (
                        <div
                          className={`${styles.eachPattern} ${styles.inter14}`}
                          key={index}
                        >
                          {patternListName}
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          )
        }

        {/* Instructions section */}
        <div className={styles.instructionsSection}>
          <div className={`${styles.header} ${styles.inter16}`}>
            {constants.INSTRUCTIONS}
          </div>
          <div className={styles.instructions}>
            <ul>
              {props?.type === "assignment"
                ? constants.ASSIGNMENT_INSTRUCTIONS &&
                  Array.isArray(constants.ASSIGNMENT_INSTRUCTIONS) &&
                  constants.ASSIGNMENT_INSTRUCTIONS.map(
                    (instructions: string, index: number) => {
                      return <li key={index}>{instructions}</li>;
                    }
                  )
                : constants.MCQ_INSTRUCTIONS &&
                  Array.isArray(constants.MCQ_INSTRUCTIONS) &&
                  constants.MCQ_INSTRUCTIONS.map(
                    (instructions: string, index: number) => {
                      return <li key={index}>{instructions}</li>;
                    }
                  )}
            </ul>
            <div className={styles.iconSection}>
              <img src={thinkingMascot} alt="thinkingMascotIcon" />
            </div>
          </div>
        </div>

        {/* history section */}

        {testData?.assignment_history && (
          <div className={styles.historySection}>
            <div className={`${styles.header} ${styles.inter20}`}>
              {constants.HISTORY}
            </div>
            <div className={styles.tableSection}>
              <table>
                <thead>
                  <th className={styles.slNo}>{constants.NO}</th>
                  <th className={styles.date}>{constants.DATE}</th>
                  <th className={styles.time}>{constants.TIME_SPENT}</th>
                  <th className={styles.score}>{constants.SCORE}</th>
                  <th className={styles.nav}></th>
                </thead>
                <tbody>
                  {testData?.assignment_history &&
                    Array.isArray(testData?.assignment_history) &&
                    testData?.assignment_history.map(
                      (history: any, index: number) => {
                        return (
                          <tr className={styles.inter14}>
                            <td className={styles.inter14}>{index + 1}</td>
                            <td className={styles.inter14}>
                              {history?.submission_date}
                            </td>
                            <td className={styles.inter14}>
                              {`${(history?.timetaken / 60).toFixed(2)} min`}
                            </td>
                            <td className={styles.inter14}>
                              {Math.ceil(history?.marks_scored)}/
                              {history?.total_marks}
                            </td>
                            <td
                              className={`${styles.inter14} ${styles.actionText}`}
                              onClick = {()=>handleTestResultHistory(props.topicId)}
                            >
                              {constants.VIEW}
                            </td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {/* button section */}
      <div className={styles.buttonSection}>
        <div
          className={styles.cancelButton}
          onClick={() => props.closeOverlay("mcq")}
        >
          {constants.CANCEL}
        </div>

        <div className={`${styles.primaryButtonWrap} ${styles.submitButton}`}>
          <button
            type="submit"
            className={`${styles.primaryButton}`}
            onClick={() => solveAssignment()}
          >
            {constants.SOLVE_NOW}
          </button>
        </div>
      </div>
    </section>
  );
};
