import React from "react";
import parse from "html-react-parser";
import { MathJax } from "better-react-mathjax";

interface IQuestionAndOptionsElement {
  metaData: string;
  size?: string;
  optionLength?: boolean;
}
export const QuestionAndOptionsElement = (
  props: IQuestionAndOptionsElement
) => {
  const questionAndOptionsData: string | any = parse(props?.metaData ?? "");

  return (
    <div key={questionAndOptionsData}>
      <MathJax inline hideUntilTypeset={"first"}>
        {questionAndOptionsData}
      </MathJax>
    </div>
  );
};
