import React, { useState, useRef, useMemo, useEffect } from "react";
import classes from "./videoPlayer.module.scss";
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Grid";
import sampleSubtitles from "./sampleSubtitles.vtt";
import playVideoIcon from "../../assets/images/playVideoIcon.svg";
import backwardPlayIcon from "../../assets/images/backwardPlayIcon.svg";
import forwardVideoIcon from "../../assets/images/forwardVideoIcon.svg";
import videoVolumeIcon from "../../assets/images/videoVolumeIcon.svg";
import noVolumeIcon from "../../assets/images/noVolumeIcon.svg";
import videolangSelectionIcon from "../../assets/images/videolangSelectionIcon.svg";
import videoSubtitlesIcon from "../../assets/images/videoSubtitlesIcon.svg";
import videoFullScreenIcon from "../../assets/images/videoFullScreenIcon.svg";
import videoCollapseIcon from "../../assets/images/videoCollapseIcon.svg";
import videoNotesIcon from "../../assets/images/videoNotesIcon.svg";
import videoPauseIcon from "../../assets/images/videoPauseIcon.svg";
import subTitlesOnIcon from "../../assets/images/subTitlesOnIcon.svg";

const VideoPlayer = (props) => {
  const [progress, setProgress] = useState(0);
  const videoRef = useRef(null);
  const [isVolumeCicked, setIsVolumeClicked] = useState(false);
  const [volume, setVolume] = useState(75);
  const [showSubtitles, setShowSubtitles] = useState(false);
  const [languageToggle, setLanguageToggle] = useState(false);
  const [fullScreenOpted, setFullScreenOpted] = useState(false);
  const videoId = `videoPlayerWrapId${Math.random(100)}`;

  const handleVolumeChange = (event, value) => {
    let video = videoRef;
    video.current.volume = value / 100;
    setVolume(value);
  };

  const handleProgress = () => {
    const duration = videoRef.current.duration;
    const currentTime = videoRef.current.currentTime;
    const progress = (currentTime / duration) * 100;
    setProgress(progress);
  };
  const backwardOrForward = (value) => {
    if (languageToggle) setLanguageToggle(!languageToggle);
    if (isVolumeCicked) setIsVolumeClicked(!isVolumeCicked);

    let video = videoRef;
    const currentTime = Math.round(video.current.currentTime);
    video.current.currentTime = currentTime + value;
  };

  const pauseOrPlay = () => {
    if (languageToggle) setLanguageToggle(!languageToggle);
    if (isVolumeCicked) setIsVolumeClicked(!isVolumeCicked);

    let video = videoRef;
    if (video.current.paused) {
      video.current.play();
    } else {
      video.current.pause();
    }
  };
  const videoDuration = () => {
    let video = videoRef;
    const minutes = parseInt(video.current?.duration / 60, 10);
    const seconds = Math.round(video.current?.duration % 60);
    const hours = minutes / 60 || "00";

    const timeInHours =
      hours < 1 ? "00" : hours >= 1 && hours < 10 ? `0${hours}` : hours;
    const timeInMinutes =
      minutes < 1
        ? `00`
        : minutes >= 1 && minutes < 10
        ? `0${minutes}`
        : minutes;
    const timeInSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return isNaN(hours) || isNaN(minutes) || isNaN(seconds)
      ? ""
      : `${timeInHours}:${timeInMinutes}:${timeInSeconds} `;
  };

  const totalVideoTime = useMemo(() => videoDuration, [props.videoUrl]);

  const getRunningTime = () => {
    let video = videoRef;
    const minutes = parseInt(video.current?.currentTime / 60, 10);
    const seconds = Math.round(video.current?.currentTime % 60);
    const hours = minutes / 60 || "00";

    const timeInHours =
      hours < 1 ? "00" : hours >= 1 && hours < 10 ? `0${hours}` : hours;
    const timeInMinutes =
      minutes < 1
        ? `00`
        : minutes >= 1 && minutes < 10
        ? `0${minutes}`
        : minutes;
    const timeInSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return isNaN(hours) || isNaN(minutes) || isNaN(seconds)
      ? "00:00:00"
      : `${timeInHours}:${timeInMinutes}:${timeInSeconds} `;
  };

  const currentRunningTime = useMemo(
    () => getRunningTime,
    [props.videoUrl] || currentRunningTime
  );

  const handleOpenFullScreen = () => {
    setFullScreenOpted(true);
    const videoContainer = document.getElementById(videoId);
    if (videoContainer.requestFullscreen) {
      videoContainer.requestFullscreen();
    } else if (videoContainer.webkitRequestFullscreen) {
      /* Safari */
      videoContainer.webkitRequestFullscreen();
    } else if (videoContainer.msRequestFullscreen) {
      /* IE11 */
      videoContainer.msRequestFullscreen();
    }
  };

  const handleCloseFullScreen = () => {
    setFullScreenOpted(false);
    if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
  };
  const handleVideoPlayChange = (event, value) => {
    let video = videoRef;
    const updatedTime = (value * video.current.duration) / 100;
    video.current.currentTime = updatedTime;
  };
  const preventHorizontalKeyboardNavigation = (event) => {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  };
  //when in fullscreen hide and showing controls
  useEffect(() => {
    let timeout;
    let duration = 3000;
    if (fullScreenOpted) {
      document.addEventListener("mousemove", function () {
        //  Mouse is moving
        if (document.getElementById(videoId)) {
          document.getElementById(videoId).style.cursor = "default";
        }
        if (document.getElementById("videoControlsWrapper")) {
          document.getElementById("videoControlsWrapper").style.display =
            "flex";
        }
        clearTimeout(timeout);

        // Mouse is stopped
        timeout = setTimeout(function () {
          if (document.getElementById(videoId)) {
            document.getElementById(videoId).style.cursor = "none";
          }
          if (document.getElementById("videoControlsWrapper")) {
            document.getElementById("videoControlsWrapper").style.display =
              "none";
          }
        }, duration);
      });
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [fullScreenOpted]);

  useEffect(() => {
    const pauseVideoOnClickOutside = (e) => {
      const videoControls = document.querySelector("#videoControlsWrapper");
      if (e.target.id === "my_video" || videoControls.contains(e.target)) {
        return;
      } else {
        videoRef?.current?.pause();
      }
    };
    document.addEventListener("click", pauseVideoOnClickOutside);

    return () => {
      document.removeEventListener("click", pauseVideoOnClickOutside);
    };
  }, []);

  return (
    <>
      <div id={videoId} className={classes.videoPlayerWrapContainer}>
        {videoRef && videoRef?.current?.paused && (
          <div className={classes.videoPlayerWrapContainerIcon}>
            <img src={playVideoIcon} alt="Pause or Play" />
          </div>
        )}

        <video
          id="my_video"
          onClick={pauseOrPlay}
          onTimeUpdate={handleProgress}
          ref={videoRef}
          src={props.videoUrl}
          width="100%"
          height="100%"
          autoPlay={false}
        >
          <source src={props.videoUrl} type="video/mp4" />
          {showSubtitles && (
            <track
              default
              kind="captions"
              srclang="en"
              src={sampleSubtitles}
            ></track>
          )}
        </video>
        <div id="videoControlsWrapper" className={classes.videoControlsWrapper}>
          <div className={classes.videoControlTimerAndTime}>
            <div className={classes.videoCurrentTime}>
              {currentRunningTime()}
            </div>
            <div className={classes.videoControlTimer}>
              <Grid item xs>
                <Slider
                  sx={{
                    "& .MuiSlider-thumb": {
                      visibility: "hidden",
                    },
                    "& .MuiSlider-track": {
                      background:
                        "linear-gradient(270deg, #D2CBFE 34.06%, #6C57EF 100.77%) !important;",
                      color: "transparent",
                      border: "unset !important",
                    },
                    "& .MuiSlider-rail": {
                      background: "#686868 !important",
                    },
                  }}
                  value={
                    videoRef.current
                      ? (videoRef.current.currentTime /
                          videoRef.current.duration) *
                        100
                      : 0
                  }
                  onChange={handleVideoPlayChange}
                  aria-labelledby="volume-slider"
                />
              </Grid>
            </div>
            <div className={classes.videoToatlTime}>{totalVideoTime()}</div>
          </div>
          <div className={classes.videoPlaybackAndSubtitlesContainer}>
            <div className={classes.videoPlaybackBtnsWrapper}>
              <div className={classes.videoPlaybackBtn} onClick={pauseOrPlay}>
                <img
                  src={
                    videoRef && videoRef?.current?.paused
                      ? playVideoIcon
                      : videoPauseIcon
                  }
                  alt="Pause or Play"
                />
              </div>
              <div
                className={classes.videoPlaybackBtn}
                onClick={() => backwardOrForward(-10)}
              >
                <img src={backwardPlayIcon} alt="Backward" />
              </div>
              <div
                className={classes.videoPlaybackBtn}
                onClick={() => backwardOrForward(10)}
              >
                <img src={forwardVideoIcon} alt="Fast Forward" />
              </div>
            </div>
            <div className={classes.videoSubtitlesContainer}>
              <div className={classes.videoVolumeAndSlider}>
                {isVolumeCicked && (
                  <div className={classes.volumeSlider}>
                    <Grid item xs style={{ maxWidth: "100px", height: "83px" }}>
                      <Slider
                        value={volume}
                        onChange={handleVolumeChange}
                        sx={{
                          '& input[type="range"]': {
                            WebkitAppearance: "slider-vertical",
                          },
                        }}
                        orientation="vertical"
                        aria-label="Temperature"
                        valueLabelDisplay="auto"
                        onKeyDown={preventHorizontalKeyboardNavigation}
                      />
                    </Grid>
                  </div>
                )}
                <div
                  className={classes.videoVolumeBtn}
                  onClick={() => {
                    setIsVolumeClicked(!isVolumeCicked);
                    if (languageToggle) setLanguageToggle(!languageToggle);
                  }}
                >
                  <img
                    src={volume ? videoVolumeIcon : noVolumeIcon}
                    alt="volume"
                  />
                </div>
              </div>

              <div className={classes.fullScreenAndSubtitles}>
                <div className={classes.languageSelectionWrapper}>
                  <div
                    className={classes.videolanguageBtn}
                    onClick={() => {
                      setLanguageToggle(!languageToggle);
                      if (isVolumeCicked) setIsVolumeClicked(!isVolumeCicked);
                    }}
                  >
                    <img src={videolangSelectionIcon} alt="language" />
                  </div>

                  {languageToggle && (
                    <div className={classes.languageCard}>
                      <p>Hindi</p>
                      <p>English</p>
                    </div>
                  )}
                </div>
                <div
                  className={classes.videoSubtitlesBtns}
                  onClick={() => {
                    setShowSubtitles(!showSubtitles);
                    if (languageToggle) setLanguageToggle(!languageToggle);
                  }}
                >
                  <img
                    src={showSubtitles ? subTitlesOnIcon : videoSubtitlesIcon}
                    alt="subtitles"
                  />
                </div>
                {/* <div className={classes.videoSubtitlesBtns}><img src={videoNotesIcon} alt='Notes' /></div> */}
                <div
                  className={classes.videoSubtitlesBtns}
                  onClick={
                    fullScreenOpted
                      ? handleCloseFullScreen
                      : handleOpenFullScreen
                  }
                >
                  <img
                    src={
                      fullScreenOpted ? videoCollapseIcon : videoFullScreenIcon
                    }
                    alt="fullScreen"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoPlayer;
