import React from "react";
import styles from "./questionNavigationWrapper.module.scss";

interface IQuestionNavigationWrapper {
    activeQuestionIndex: any;
    setActiveQuestionIndex: any;
    saveQuestion: any;
    setQuestion: any;
    question: any;
    activeAssignmentType?: any;
    teachersAssignment?: any;
    setOptionClicked?: any;
}

export const QuestionNavigationWrapper = (
    props: IQuestionNavigationWrapper
) => {
    const {
        activeQuestionIndex,
        setActiveQuestionIndex,
        saveQuestion,
        setQuestion,
        question,
        activeAssignmentType,
        setOptionClicked,
    } = props;

    return (
        <>
            <div className={styles.questionNavigationWrapoperContainer}>
                {/* <div className={styles.raiseTextContainer}>
                    <span className={styles.havingText}>Having doubt in the question?</span>
                    <span className={styles.raiseAnIssueText}>Raise An Issue</span>
                </div> */}
                <div className={styles.headingAttemptQuestion}>
                    {(activeAssignmentType === "submittedMCQ") &&
                        <div className={styles.questionnavigationbutton}
                        >
                           <button
                            className={
                                activeQuestionIndex !== 0 ? styles.cancelButton : styles.prevButtonDisable}
                            onClick={() => {
                                return (
                                    (activeQuestionIndex + 1) > 1 && (
                                        // saveQuestion(),
                                        setActiveQuestionIndex(activeQuestionIndex - 1),
                                        setQuestion(
                                            () => {
                                                return question.map((item1: any) => {
                                                    if (item1.id === question[activeQuestionIndex - 1].id) {
                                                        item1.visited = "In Progress";
                                                        item1.open = true;
                                                    }
                                                    else if ((item1.id < activeQuestionIndex + 2 && item1.selectedAnswer !== "") || item1.uploadedImages?.length > 0) {
                                                        item1.visited = "Completed"
                                                    }
                                                    else if (item1.visited === "In Progress") {
                                                        item1.visited = ""
                                                    }
                                                    return item1
                                                })
                                            }
                                        )
                                    )
                                )
                            }}>
                                Previous
                            </button>
                        </div>
                    }
                   
                    <div  className={styles.questionnavigationbutton}>
                        <button className={(question.length - 1 !== activeQuestionIndex && (question[activeQuestionIndex]?.selectedAnswer != "" || activeAssignmentType !== "test"))
                            ? styles.prevNextButton : styles.nextButtonDisable}
                            onClick={() => {
                                return (activeQuestionIndex < (question.length - 1) && question.length - 1 !== activeQuestionIndex) && (
                                    activeAssignmentType == "test" && (
                                        setOptionClicked(true),
                                        saveQuestion(question)
                                    ),
                                    setActiveQuestionIndex(activeQuestionIndex + 1),
                                    setQuestion(
                                        () => {
                                            return question.map((item1: any) => {
                                                if (item1.id === question[activeQuestionIndex + 1].id) {
                                                    item1.visited = "In Progress"
                                                    item1.open = true;
                                                }
                                                else if ((item1.id < activeQuestionIndex + 2 && item1.selectedAnswer != "") || item1.uploadedImages?.length > 0) {
                                                    item1.visited = "Completed"
                                                }
                                                else if (item1.visited == "In Progress") {
                                                    item1.visited = ""
                                                }
                                                return item1
                                            })
                                        }
                                    )
                                )
                            }}
                            disabled={!(question.length - 1 !== activeQuestionIndex && (question[activeQuestionIndex]?.selectedAnswer != "" || activeAssignmentType !== "test"))}
                        >
                            Next
                        </button>
                    </div>
                    {/* <div>
                        <button
                         className={
                            activeQuestionIndex !== 0 ? styles.cancelButton : styles.prevButtonDisable
                             }
                                 onClick={() => {
                                 if (activeQuestionIndex > 0) {
                                     setActiveQuestionIndex(activeQuestionIndex - 1);
                                     setQuestion(() =>
                                     question.map((item1: any) => {
                                     if (item1.id === question[activeQuestionIndex - 1].id) {
                                     item1.visited = "In Progress";
                                     item1.open = true;
                                   } else if (
                                    (item1.id < activeQuestionIndex + 1 &&
                                     item1.selectedAnswer !== "") ||
                                    item1.uploadedImages?.length > 0
                                   ) {
                                     item1.visited = "Completed";
                                       } else if (item1.visited === "In Progress") {
                                       item1.visited = "";
                                       }
                                         return item1;
                                     })
                                  );
                                   }
                               }}
                               >
                               Previous
                            </button>
                         </div> */}
                         <div  className={styles.questionnavigationbutton}>
                            <button
                                className={
                                    activeQuestionIndex < question.length - 1 ? styles.prevNextButton : styles.nextButtonDisable
                                    }
                                     onClick={() => {
                                     if (activeQuestionIndex < question.length - 1) {
                                            setActiveQuestionIndex(activeQuestionIndex + 1);
                                             setQuestion(() =>
                                                question.map((item1: any, index: number) => {
                                                      if (index === activeQuestionIndex + 1) {
                                             item1.visited = "Skipped";
                                      }
                                        return item1;
                                     })
                                     );
                                     }
                                  }}
                                >
                                 Skip 
                             </button>
                         </div>
                </div>
            </div >
        </>
    )
}

