const initialState = {
  isVisible: false,
};

const studyPreferenceReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case "visiblity": {
      return {
        isVisible: true,
      };
    }
    case "hideVisiblity": {
      return {
        isVisible: false,
      };
    }
    default:
      return state;
  }
};

export default studyPreferenceReducer;
