import React from "react";
import styles from "./averageScoreCard.module.scss";

const AverageScoreCard = ({ scoreCardData }: any) => {
    return (
        <div className={styles.averageScoreCard}>
            <h1>Average Score</h1>
            <p>
                {scoreCardData.averageScore} <span>/10</span>
            </p>
        </div>
    );
};

export default AverageScoreCard;
