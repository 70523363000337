import React, { useEffect, useState } from "react";
import styles from "./videoNotesOverlay.module.scss";
import closeIconForNewNoteOverlay from "../../../../assets/images/closeIconForNewNoteOverlay.svg";
import Dropdown from "../../../../common/dropdown/dropdown";
import { TextField } from "@mui/material";

const VideoNotesOverlay = ({ onNewNoteOverlayClose }: any) => {
  const [videoNotesChapter, setVideoNotesChapter] = useState([]);
  const [chapterTopics, setChapterTopics] = useState([]);

  // TODO: Commented the UX Integration for the Overlay

 

  return (
    <>
      <div className={styles.videoOverlayHeader}>
        <div className={styles.newNoteText}>New Note</div>
        <div className={styles.closeIconContainer}>
          <img
            src={closeIconForNewNoteOverlay}
            onClick={onNewNoteOverlayClose}
          />
        </div>
      </div>
      <div className={styles.newNoteMainContainer}>
        <div className={styles.selectContainer}>
          <div className={styles.label}>Select a Chapter</div>
          <Dropdown
            options={videoNotesChapter}
            defaultLabel={"Select a Chapter"}
            selectedValue={"selectedValue"}
            minWidth={"177px"}
          />
        </div>
        <div className={styles.selectContainer}>
          <div className={styles.label}>Select a Topic (Optional)</div>
          <Dropdown
            options={chapterTopics}
            defaultLabel={"Select a Topic"}
            selectedValue={"selectedValue"}
            minWidth={"177px"}
          />
        </div>
        <div className={styles.selectContainer}>
          <div className={styles.label}>What's your question?</div>
          <div>
            <TextField
              multiline
              rows={2}
              // maxRows={3}
              className={styles.textField}
              sx={{
                "& .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root": {
                  minHeight: "93px",
                },
              }}
            />
          </div>
        </div>
        <div className={styles.submitCancelButtonContainer}>
          <div className={styles.newNotecancelButton}>
            <button onClick={onNewNoteOverlayClose}>CANCEL</button>
          </div>
          <div className={styles.newNoteSubmitButton}>
            <button>SUBMIT</button>
          </div>
        </div>
      </div>
    </>
  );
};
export default VideoNotesOverlay;
