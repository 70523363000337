import React, { useState, ChangeEventHandler, useCallback } from "react";
import Dropzone from "react-dropzone";
import { v4 } from "uuid";
import { Alert } from "@mui/material";

import styles from "./bulkUpload.module.scss";
import { Link } from "react-router-dom";

const ASSIGNMENT_MAX_FILE_SIZE = 100000000;
const ASSIGNMENT_MAX_FILES_ALLOWED = 1;

const FILE_UPLOAD_ERROR_TYPES = {
  MAX_FILE_SIZE: "FILE_SIZE",
  MAX_NO_OF_FILES: "MAX_NO_OF_FILES",
};

interface FILE_ERROR_TYPE {
  errorType: string;
  errorMessage: string;
}

const BulkUpload = ({
  onUpdateInputFiles,
  uploadedFilesCount,
  file,
  sampleBulkUploadTemplate,
}: any) => {
  const [fileUploadError, setFileUploadError] =
    useState<FILE_ERROR_TYPE | null>(null);

  const handleCloseSnackbar = () => {
    setFileUploadError(null);
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    handleCloseSnackbar();

    if (uploadedFilesCount >= ASSIGNMENT_MAX_FILES_ALLOWED) {
      setFileUploadError({
        errorType: FILE_UPLOAD_ERROR_TYPES.MAX_NO_OF_FILES,
        errorMessage: `Maximum of ${ASSIGNMENT_MAX_FILES_ALLOWED} files are allowed`,
      });
      return;
    }

    const file = acceptedFiles[0] || "";
    if (file == "") return;

    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = (event: any) => {
        // acceptedFiles = event.target.result;
        // const binaryStr = reader.result;
      };
      // reader.readAsDataURL(file);
    });

    acceptedFiles.forEach((acceptedFile: any) => {
      acceptedFile.id = v4();
      acceptedFile.preview = URL.createObjectURL(acceptedFile);
    });

    onUpdateInputFiles(acceptedFiles);
  }, []);

  const onDropRejected = (event: any) => {
    setFileUploadError({
      errorType: FILE_UPLOAD_ERROR_TYPES.MAX_FILE_SIZE,
      errorMessage: "The file size should be below 100MB",
    });
  };

  return (
    <>
      <p style={{ textAlign: "center" }}>or</p>
      <div className={styles.bulkUploadMainContainer}>
        <div className={styles.bulkUploadHeadingContainer}>
          <p className={styles.bulkUploadHeading}>Bulk Upload Data</p>
          <p className={styles.bulkUploadSubHeading}>
            <Link
              to={sampleBulkUploadTemplate?.url}
              target="_blank"
              download={sampleBulkUploadTemplate?.filename}
            >
              Download
            </Link>
            template to upload the data
          </p>
        </div>
        <div className={styles.bulkUploadContainer}>
          <Dropzone
            onDrop={onDrop}
            maxSize={ASSIGNMENT_MAX_FILE_SIZE}
            onDropRejected={onDropRejected}
            multiple={false}
            // accept={{
            //   "excel/xlxs": [".xlxs", ".doc"],
            // }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className={styles.bulkUploadContent}>
                  {file.length ? (
                    <div className={styles.filePreview}>
                      <p>Your file is successfully uploaded.</p>
                      {file.map((f: any) => {
                        return (
                          <span>
                            <FileIcon /> {f.path}
                          </span>
                        );
                      })}
                      <span>Change File</span>
                    </div>
                  ) : (
                    <div className={styles.selectFileButton}>Upload File</div>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        </div>
        {fileUploadError && (
          <div className={styles.alertContainer}>
            <Alert severity="warning" onClose={handleCloseSnackbar}>
              {" "}
              {fileUploadError.errorMessage}{" "}
            </Alert>
          </div>
        )}
      </div>
    </>
  );
};

export default BulkUpload;

const FileIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6865 11.5123L6.38477 10.0498V20.8566C6.38486 20.974 6.40809 21.0902 6.4531 21.1986C6.49812 21.307 6.56406 21.4055 6.64714 21.4885C6.73023 21.5714 6.82884 21.6372 6.93734 21.682C7.04584 21.7269 7.16211 21.7499 7.27952 21.7498H21.6045C21.722 21.7501 21.8385 21.7272 21.9472 21.6825C22.0558 21.6377 22.1546 21.572 22.2379 21.489C22.3211 21.4061 22.3872 21.3075 22.4323 21.199C22.4774 21.0904 22.5007 20.9741 22.5008 20.8566V16.8748L14.6865 11.5123Z"
        fill="#185C37"
      />
      <path
        d="M14.6865 2.25H7.27952C7.16211 2.2499 7.04584 2.27293 6.93734 2.31776C6.82884 2.3626 6.73023 2.42837 6.64714 2.51132C6.56406 2.59426 6.49812 2.69276 6.4531 2.80119C6.40809 2.90962 6.38486 3.02585 6.38477 3.14325V7.125L14.6865 12L19.0823 13.4625L22.5008 12V7.125L14.6865 2.25Z"
        fill="#21A366"
      />
      <path d="M6.38477 7.125H14.6865V12H6.38477V7.125Z" fill="#107C41" />
      <path
        opacity="0.1"
        d="M12.3263 6.1499H6.38477V18.3374H12.3263C12.563 18.3362 12.7898 18.2418 12.9574 18.0746C13.125 17.9074 13.22 17.6809 13.2218 17.4442V7.04315C13.22 6.80641 13.125 6.57989 12.9574 6.4127C12.7898 6.2455 12.563 6.15108 12.3263 6.1499Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.838 6.6377H6.38477V18.8252H11.838C12.0748 18.824 12.3015 18.7296 12.4691 18.5624C12.6368 18.3952 12.7317 18.1687 12.7335 17.9319V7.53095C12.7317 7.2942 12.6368 7.06769 12.4691 6.90049C12.3015 6.73329 12.0748 6.63887 11.838 6.6377Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.838 6.6377H6.38477V17.8502H11.838C12.0748 17.849 12.3015 17.7546 12.4691 17.5874C12.6368 17.4202 12.7317 17.1937 12.7335 16.9569V7.53095C12.7317 7.2942 12.6368 7.06769 12.4691 6.90049C12.3015 6.73329 12.0748 6.63887 11.838 6.6377Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.3498 6.6377H6.38477V17.8502H11.3498C11.5865 17.849 11.8133 17.7546 11.9809 17.5874C12.1485 17.4202 12.2435 17.1937 12.2453 16.9569V7.53095C12.2435 7.2942 12.1485 7.06769 11.9809 6.90049C11.8133 6.73329 11.5865 6.63887 11.3498 6.6377Z"
        fill="black"
      />
      <path
        d="M2.3955 6.6377H11.349C11.5862 6.6375 11.8137 6.73147 11.9816 6.89897C12.1496 7.06646 12.2441 7.29377 12.2445 7.53095V16.4694C12.2441 16.7066 12.1496 16.9339 11.9816 17.1014C11.8137 17.2689 11.5862 17.3629 11.349 17.3627H2.3955C2.27804 17.3629 2.16168 17.3399 2.05309 17.2951C1.9445 17.2504 1.84581 17.1846 1.76264 17.1016C1.67948 17.0187 1.61348 16.9202 1.56841 16.8117C1.52334 16.7032 1.5001 16.5869 1.5 16.4694V7.53095C1.5001 7.41348 1.52334 7.29719 1.56841 7.18871C1.61348 7.08023 1.67948 6.9817 1.76264 6.89875C1.84581 6.81579 1.9445 6.75004 2.05309 6.70525C2.16168 6.66045 2.27804 6.6375 2.3955 6.6377Z"
        fill="url(#paint0_linear_2446_2959)"
      />
      <path
        d="M4.27539 14.9047L6.15864 11.9917L4.43364 9.09521H5.81889L6.76014 10.95C6.84714 11.1255 6.91014 11.256 6.93864 11.343H6.95139C7.01289 11.202 7.07814 11.0662 7.14639 10.9335L8.15289 9.09821H9.42789L7.65864 11.9782L9.47289 14.907H8.11614L7.02864 12.8737C6.97815 12.7863 6.93524 12.6947 6.90039 12.6H6.88239C6.85068 12.6923 6.8084 12.7806 6.75639 12.8632L5.63664 14.9047H4.27539Z"
        fill="white"
      />
      <path
        d="M21.6043 2.25H14.6855V7.125H22.4998V3.14325C22.4997 3.02579 22.4765 2.90949 22.4314 2.80102C22.3863 2.69254 22.3203 2.59401 22.2372 2.51105C22.154 2.4281 22.0553 2.36234 21.9467 2.31755C21.8381 2.27276 21.7218 2.2498 21.6043 2.25Z"
        fill="#33C481"
      />
      <path d="M14.6855 12H22.4998V16.875H14.6855V12Z" fill="#107C41" />
      <defs>
        <linearGradient
          id="paint0_linear_2446_2959"
          x1="3.3705"
          y1="5.9357"
          x2="10.374"
          y2="18.0647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18884F" />
          <stop offset="0.5" stopColor="#117E43" />
          <stop offset="1" stopColor="#0B6631" />
        </linearGradient>
      </defs>
    </svg>
  );
};
