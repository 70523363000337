import React from "react";
import styles from "./studentDetailsCard.module.scss";

const StudentDetailsCard = ({ cardData }: any) => {
  return (
    <div className={styles.studentDetailsCard}>
      <div className={styles.c1}>
        <h1>No. of Students</h1>
        <p>{cardData.studentCount}</p>
      </div>
      {(cardData.testAttempted || cardData.testSubmitted) && (
        <>
          <div className={styles.c2}>
            {cardData.testAttempted && (
              <div className={styles.block}>
                <h1>Tests Attempted</h1>
                <p>{cardData.testAttempted}</p>
              </div>
            )}
            {cardData.testSubmitted && (
              <div className={styles.block}>
                <h1>Tests Submitted</h1>
                <p>{cardData.testSubmitted}</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default StudentDetailsCard;
