import React, { useContext, useEffect, useState } from 'react'
import styles from "./classDetailsHeadingCard.module.scss";
import student from "../../../../../assets/images/studentHead.svg";
import StudentPerformanceIconBox from './StudentPerformanceIconBox/StudentPerformanceIconBox';
import assignmentIcon from "../../../../../assets/images/studentAssignIcon.svg";
import pushingBox from "../../../../../assets/images/PushingSandclock.svg"
import headingMsg from "../../../../../assets/images/headingMessage.svg";
import StatusContext from '../../../../../context/loaderContext';
import { useNavigate, useParams } from 'react-router-dom';
import ApiEdpoints, { HTTPMethods } from '../../../../../constants/api_endpoints';
import { makeAxiosRequest } from 'aie_common';
import { updateClassList } from '../../../../../utils/teacherPersonalMassagedData';


const ClassDetailsHeadingCard = (props: any) => {

  const [pendingReviewdata, setPendingReviewdata] = useState([
  {
    "value": "04",
    "title": "Exercises",
    "icon": assignmentIcon
  },
    // {
    //   "value": "04",
    //   "title": "Questions",
    //   "icon": headingMsg
    // }
  ])
  const loaderStatus: any = useContext(StatusContext);
  const [classDetails, setClassDetails] = useState([{
    divisionClass: '08',
    division: 'Div A',
    noOfStudents: 6,
    pendingReview: 150,
    id: 0
  }]);

  const fetchClassDetails = async (classId: any) => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: ApiEdpoints.CLASS_LIST + `?class_id=${classId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      loaderStatus.setState({ show: false });
      setClassDetails(updateClassList(res?.data?.data));
      setPendingReviewdata(pendingReviewdata.map((ele: any) => { return { ...ele, value: res?.data?.data[0].pending_assignments } }
      ));
      return res;
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
  }
  };

  useEffect(() => {
    fetchClassDetails(props.id)
  }, [props.id]);


  return (
    <div className={styles.headingMainContainer}>
      <div className={styles.headingMainWrap}>
        <div className={styles.headingDataContainer}>
          <div className={styles.headingTopContainer}>
            <div className={styles.review}>Pending Review</div>
            <div className={styles.reviewInfoContainer}>
              <div className={styles.details}>Class {classDetails[0].divisionClass}</div>
              <div className={styles.details}>|</div>
              <div className={styles.details}>{classDetails[0].division}</div>
              <div className={styles.details}>|</div>
              <div className={styles.imgContainer}>
                <img src={student} alt="head" />
                <div className={styles.details}>{classDetails[0].noOfStudents} students</div>
              </div>


            </div>

          </div>
          <div className={styles.horizontalLine}></div>
          <div className={styles.bottomContainer}>
            {pendingReviewdata.map((each: any, ind: number) => (
              <StudentPerformanceIconBox performance={each} key={`StudentPerformanceIconBox_${ind}`} />
            ))}
          </div>
        </div>
        <div className={styles.headingImg}>
          <img src={pushingBox} />
        </div>
      </div>
    </div>
  )
}

export default ClassDetailsHeadingCard