import { constants } from "../../constants/constants";

const initialState = {
    loggedIn: false,
};

const loginReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case constants.LOGIN:
      return { loggedIn: true };

    case constants.LOGOUT:
      return { loggedIn: false };
    default:
      return state;
  }
};

export default loginReducer;