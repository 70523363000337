import React, { useEffect } from "react";
import styles from "./myStatsChaptersCard.module.scss";
import chapterIcon from "../../../../../assets/images/chapterImage.svg";
import question from "../../../../../assets/images/questionsAssignment.svg";
import aiHelp from "../../../../../assets/images/aiHelp.svg";
import { constants } from "../../../../../constants/constants";
import { levelPercentage } from "../../../../../utils/masteryLevel";

const MyStatsChaptersCard = ({
  data,
  handleChapterCard,
  chapterNumber,
}: any) => {
  const [masteryLevel, setMasteryLevel] = React.useState("L1");

  useEffect(() => {
    if (data?.competence_level?.mastery_stage) {
      setMasteryLevel(
        `L${data?.competence_level?.mastery_stage.split("_")[2]}`
      );
    }
  }, [data?.competence_level]);

  return (
    <div
      className={styles.myStatChapterCardMainContainer}
      onClick={() => handleChapterCard(data)}
    >
      <div className={styles.myStatImageContainer}>
        <div className={styles.statusIcon}>
          <img src={chapterIcon} />
        </div>
        <div className={styles.status}>{`Chapter ${chapterNumber}`}</div>
      </div>
      <div className={styles.myStatDataContainer}>
        <div className={styles.myStatChapterDataContainer}>
          <div className={styles.statsQuestionName}>{data.name}</div>
          <div className={styles.myStatsChapterData}>
            <div>
              <img src={question} />
              <div className={styles.attemptedQuestions}>
                <span>
                  {data.attempted_questions}/{data.total_questions}
                </span>
                {constants.questionsAttempted}
              </div>
            </div>
            <div>
              <img src={aiHelp} />
              <div className={styles.attemptedQuestions}>
                <span>{data.Ai_Count}</span> {constants.aiHelpUsed}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.myStatPatternCardProgressMain}>
          <div>
            <div className={styles.myStatPatternCardProgressNameContainer}>
              <div className={styles.myStatPatternCardProgressText}>
                {constants.mastery}
              </div>
              <div className={styles.myStatPatternCardProgressText}>
                {masteryLevel}
              </div>
            </div>
            <div className={styles.myStatPatternCardProgressWrap}>
              <div
                className={styles.myStatPatternCardProgressMastery}
                style={{ width: levelPercentage(masteryLevel) }}
              ></div>
            </div>
          </div>
          <div>
            {/* <div className={styles.myStatPatternCardProgressNameContainer}>
              <div className={styles.myStatPatternCardProgressText}>
                {constants.competency}
              </div>
              <div className={styles.myStatPatternCardProgressText}>
                {masteryLevel}
              </div>
            </div> */}
            {/* <div className={styles.myStatPatternCardProgressWrap}>
              <div
                className={styles.myStatPatternCardProgressCompetency}
                style={{ width: levelPercentage(masteryLevel) }}
              ></div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MyStatsChaptersCard);
