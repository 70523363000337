import React from "react";
import styles from "./averageCGPA.module.scss";

const AverageCGPA = ({ avgCGPA }: any) => {
    return (
        <div className={styles.avgCGPA}>
            <h1>Average CGPA</h1>

            <div className={styles.number}>
                <span> {avgCGPA.cgpa} </span>/10
            </div>
            <p>Class 6 - Class 10</p>
        </div>
    );
};

export default AverageCGPA;
