import React, { useContext, useEffect, useState } from "react";
import styles from "./adminClasses.module.scss";

import {
  Alert,
  FormControlLabel,
  IconButton,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Snackbar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import optionButton from "../../../assets/images/optionButton.svg";
import AdminSideBar from "../../../common/admin/adminSideBar/adminSideBar";
import ConfirmationPopup from "../../../common/admin/confirmationPopup/confirmationPopup";
import RenderForm from "../../../common/admin/renderForm/renderForm";
import SubmitAndCancelButton from "../../../common/admin/submitAndCancelButton/submitAndCancelButton";
import { HTTPMethods, makeAxiosRequest } from "../../../utils/httpService";
import StatusContext from "../../../context/loaderContext";
import Breadcrumb from "../../../common/breadcrumb/breadcrumb";
import SearchBar from "../../../common/searchBar/searchBar";
import TablePaginationActions, {
  StyledTableCell,
  StyledTableRow,
} from "../../../common/admin/tablePagination/TablePagination";
import { useLocation } from "react-router-dom";
import {
  adminStudentAddFormTemplate,
  adminStudentEditFormTemplate,
} from "../adminStudents/adminFormTemplates";
import StudentDetailsSideBar from "../../../common/admin/studentDetailsSideBar/studentDetailsSideBar";
import TeachersDetailsCard from "../../../common/admin/teachersDetailsCard/teachersDetailsCard";
import StudentDetailsCard from "../../../common/admin/studentDetailsCard/studentDetailsCard";
import AverageLevelCard from "../../../common/admin/averageLevelCard/averageLevelCard";
import Curriculum from "../../../common/admin/curriculum/curriculum";

// !popoverIcon

import view from "../../../assets/images/view.svg";
import edit from "../../../assets/images/edit.svg";
import contact from "../../../assets/images/contact.svg";
import inactive from "../../../assets/images/inactive.svg";
import deleteBtn from "../../../assets/images/delete.svg";
import { useSelector } from "react-redux";

function createData(
  status?: any,
  rollNo?: any,
  studentName?: any,
  level?: any,
  email?: any,
  phone?: any,
  batch?: any,
  id?: any
) {
  return {
    status,
    rollNo,
    studentName,
    level,
    email,
    phone,
    batch,
    id,
  };
}

const AdminClassDetails = ({ selectedClass }: any) => {
  const loaderStatus: any = useContext(StatusContext);
  const [adminList, setAdminList] = useState<any>([]);
  const location = useLocation();
  const classInfo = location?.pathname?.split("/").pop();
  const schoolInfo = location?.pathname?.split("/").slice(-2)[0];
  const [selectedSection, setSelectedSection] = useState<any>(-1);

  const [activeTableContext, setActiveTableContext] = useState<any>(null);
  const [defaultStudentEditValues, setDefaultStudentEditValues] =
    useState<any>(null);
  const [viewAdminDetailSidebar, setViewAdminDetailSidebar] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showAddStudent, setShowAddStudent] = useState(false);
  const [showEditStudentDetails, setShowEditStudentDetails] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationData, setNotificationData] = useState<any>({
    type: "",
    message: ">",
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const sectionMap: any = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function deleteDetails(id?: any, isInternalPopUp?: any) {
    if (!id) {
      !isInternalPopUp && setShowDeletePopup(!showDeletePopup);
      return;
    }

    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: `user/student/${id}/`,
      method: HTTPMethods.DELETE,
      headers: {},
    };
    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);

      setShowNotification(true);
      setNotificationData({
        type: "success",
        message: "Deleted Student Successfully",
      });
      getClassDetails();
      setViewAdminDetailSidebar(false);
      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      setShowNotification(true);
      setNotificationData({
        type: "error",
        message: "Error while deleting. Please call support",
      });
      console.log(error);
    }
    !isInternalPopUp && setShowDeletePopup(!showDeletePopup);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setAnchorEl(event.currentTarget);
    setActiveTableContext(id);
    setDefaultStudentEditValues({
      firstName: id?.user?.first_name || "",
      lastName: id?.user?.last_name || "",
      gender: id?.gender,
      rollNumber: id?.roll_no,
      class: id?.class_info,
      phone: id?.contact_number || "",
      section: id?.section,
      pfirstName: id?.parent_name || "",
      plastName: id?.parent_name || "",
      pprimaryEmail: id?.parent_email || "",
      pprimaryPhone: id?.parent_contact_number || "",
      relationship: id?.relation,
      id: id?.id || null,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    //setActiveTableContext(null);
  };

  const open = Boolean(anchorEl);

  const getClassDetails = async () => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: `school-admin/students_in_class/${schoolInfo}/${classInfo}`,
      method: HTTPMethods.GET,
      headers: {},
    };

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        const list = data.map((item: any) => {
          return {
            ...item,
            ...createData(
              item.status ? "Active" : "Pending",
              item.roll_no,
              item.student_name,
              item.level || "-",
              item.student_email || "-",
              item.student_phone || "-",
              item.batch,
              item.id
            ),
          };
        });
        setAdminList(list);
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });

      console.log(error);
    }
  };
  const [sampleSheetUrl, setSampleSheetUrl] = useState<any>({});
  const getSampleFileUrl = async () => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: "user/get_student_bulk_upload_excel_format/",
      method: HTTPMethods.GET,
      headers: {},
      responseType: "arraybuffer",
    };

    try {
      const data: any = await makeAxiosRequest(payload, baseUrl);
      // Create a Blob URL for the Excel file
      const blob = new Blob([data.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const blobURL = URL.createObjectURL(blob);
      setSampleSheetUrl({ url: blobURL, filename: "student_details.xlsx" });

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      const errorObject: any = error;

      let errorMessage = "Validation Error:\n";

      if (typeof errorObject == "object") {
        Object.keys(errorObject)?.forEach((field) => {
          if (Array.isArray(errorObject[field])) {
            errorMessage += `${field}: ${errorObject[field].join(", ")}\n\n`;
          } else {
            errorMessage += `${field}: ${errorObject[field]}\n\n`;
          }
        });
      } else {
        errorMessage = "Something went wrong";
      }

      setShowNotification(true);
      setNotificationData({
        type: "error",
        message: errorMessage || "Something went wrong",
      });
    }
  };

  useEffect(() => {
    getClassDetails();
    getSampleFileUrl();
  }, []);

  const userData = useSelector((state: any) => state.userReducer);

  const handleAddEditStudentDetails = async (data: any, type?: any) => {
    if (data.type == "EDIT" && !data.id) {
      return;
    }
    let formData;
    if (type == "FILE") {
      const file = data[0];

      formData = new FormData();
      formData.append("student_details", file);
    }

    let params = {
      user: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.primaryEmail,
      },
      gender: data.gender,
      roll_no: data.rollNumber,
      parent_name: data.pfirstName + " " + data.plastName,
      parent_email: data.pprimaryEmail,
      parent_contact_number: data.pprimaryPhone,
      batch: activeTableContext?.batch,
      contact_number: activeTableContext?.contact_number || data.phone,
      school: Number(schoolInfo),
      class_info: data.class || classInfo,
      section:
        data.section || selectedSection !== -1
          ? sectionMap[selectedSection]
          : 1,
    };

    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url:
        data.type === "ADD" ? "user/student/" : "user/student/" + data.id + "/",
      data: params,
      method: data.type === "ADD" ? HTTPMethods.POST : HTTPMethods.PUT,
      headers: {},
    };
    if (type == "FILE") {
      payload.url = `user/upload_students_in_bulk/${schoolInfo}/${classInfo}/${
        sectionMap[selectedSection] || 1
      }/`;
      payload.data = formData;
      payload.method = HTTPMethods.POST;
    }

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        setShowNotification(true);
        setNotificationData({
          type: "success",
          message: "Student details successsfully updated",
        });

        setShowAddStudent(false);
        setShowEditStudentDetails(false);
        getClassDetails();
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      const errorObject: any = error;

      let errorMessage = "Validation Error:\n";

      if (typeof errorObject == "object") {
        Object.keys(errorObject)?.forEach((field) => {
          if (Array.isArray(errorObject[field])) {
            errorMessage += `${field}: ${errorObject[field].join(", ")}\n\n`;
          } else {
            errorMessage += `${field}: ${errorObject[field]}\n\n`;
          }
        });
      } else {
        errorMessage = "Something went wrong";
      }
      setShowAddStudent(false);
      setShowEditStudentDetails(false);

      setShowNotification(true);
      setNotificationData({
        type: "error",
        message: errorMessage || "Something went wrong",
      });
      console.log(error);
    }
  };

  const slideBarStudentData = {
    name: activeTableContext?.studentName,
    class: `Std ${activeTableContext?.class_info || ""} Section ${
      activeTableContext?.section || ""
    }`,
    rollNo: activeTableContext?.roll_no || "",
    email: activeTableContext?.user?.email || "",
    contact: activeTableContext?.contact_number || "",
    gender: activeTableContext?.gender || "",
    photo: activeTableContext?.phone || "",
    id: activeTableContext?.id,
  };

  const slideBarParentsData = {
    name: activeTableContext?.parent_name,
    relationship: activeTableContext?.relation,
    email: activeTableContext?.parent_contact_number,
  };

  const sections = [
    {
      id: "A",
      name: "A",
    },
    {
      id: "B",
      name: "B",
    },
    {
      id: "C",
      name: "C",
    },
    {
      id: "D",
      name: "D",
    },
  ];

  return (
    <>
      <div className={styles.learnMainContainer}>
        <div className={styles.greetingsSearchBarContainer}>
          <div className={styles.greetings}>
            <Breadcrumb
              breadcrumbDetails={[
                {
                  label: "Home",
                  link: "/admin",
                  selected: false,
                },
                {
                  label: "Classes",
                  link: "/admin/classes",
                  selected: false,
                },
                {
                  label: `Class ${classInfo}`,
                  link: location.pathname,
                  selected: true,
                },
              ]}
            />
          </div>
          <SearchBar searchText={""} setSearchText={() => {}} />
        </div>

        <div className={styles.classDetails}>
          <h2>Class {classInfo}</h2>

          <div className={styles.classDetails_sections}>
            <RadioGroup
              row
              value={selectedClass}
              onChange={(e) => {
                setSelectedSection(e.target.value);
              }}
            >
              {!!sections.length &&
                sections.map((option: any) => {
                  return (
                    <FormControlLabel
                      value={option.id}
                      control={<Radio className={styles.radioEle} />}
                      label={"Section " + option.name}
                    />
                  );
                })}
            </RadioGroup>
          </div>
          <div className={styles.classDetails_info}>
            <TeachersDetailsCard
              teachersName={{
                classTeacher: "Gatik More",
                mathsTeacher: " Mahant Kapoor",
              }}
            />
            <StudentDetailsCard
              cardData={{
                studentCount: "50",
                // testAttempted: "90%",
                // testSubmitted: "96%"
              }}
            />
            <Curriculum
              subHeadingOne="AI Generated Homework"
              subHeadingTwo="Homework Submitted"
              dataOne="400"
              dataTwo="366"
            />
            <Curriculum
              subHeadingOne="Test Assigned"
              subHeadingTwo="Test Submitted"
              dataOne="400"
              dataTwo="366"
            />
            <AverageLevelCard levelCardData={"L3"} />
          </div>
        </div>
        <div className={styles.adminTableWrap}>
          <TableContainer component={Paper}>
            <h2>
              Students{" "}
              <button
                onClick={() => {
                  setShowAddStudent(true);
                }}
              >
                Add NEW Student
              </button>
            </h2>
            <Table sx={{ minWidth: 700 }} aria-label="admin table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <span style={{ whiteSpace: "nowrap" }}>Status</span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span style={{ whiteSpace: "nowrap" }}>Roll No</span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span style={{ whiteSpace: "nowrap" }}>Student</span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span style={{ whiteSpace: "nowrap" }}>Level</span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span style={{ whiteSpace: "nowrap" }}>Email</span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span style={{ whiteSpace: "nowrap" }}>Mobile No.</span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span style={{ whiteSpace: "nowrap" }}>Batch</span>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? adminList?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : adminList
                )
                  .filter((id: any) => {
                    if (selectedSection === -1) {
                      return id;
                    }
                    return id.section_name == selectedSection;
                  })
                  .map((row: any) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        <span
                          className={`${styles.studentStatus}
                                    ${
                                      row.status.toLowerCase() === "active"
                                        ? styles.active
                                        : ""
                                    }
                                    ${
                                      row.status.toLowerCase() === "inactive"
                                        ? styles.inactive
                                        : ""
                                    }
                                    ${
                                      row.status.toLowerCase() === "pending"
                                        ? styles.pending
                                        : ""
                                    }
                                `}
                        >
                          <DotSvg />
                          {row.status}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <span
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row.rollNo}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.studentName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <span>{row.level}</span>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.email}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <span className={styles.mathTeacher}>{row.phone}</span>
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        <span className={styles.buttonContainer}>
                          <span
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {row.batch}
                          </span>
                          <span className={styles.optionsButton}></span>
                          <IconButton onClick={(e) => handleClick(e, row)}>
                            <img src={optionButton} alt="" />
                          </IconButton>
                        </span>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                count={adminList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                sx={{
                  "& .MuiTablePagination-spacer": {
                    flex: "unset !important",
                  },
                  "& p": {
                    marginBottom: 0,
                  },
                }}
              />
            </Table>
          </TableContainer>
        </div>
        <Popover
          id={open ? "simple-popover" : undefined}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className={styles.popoverWrap}>
            <button
              onClick={() => {
                setViewAdminDetailSidebar(true);
                handleClose();
              }}
            >
              <img src={view} alt="" />
              View
            </button>
            <button
              onClick={() => {
                setShowEditStudentDetails(true);
                handleClose();
              }}
            >
              <img src={edit} alt="" />
              Edit
            </button>
            <button
              onClick={() => {
                setViewAdminDetailSidebar(true);
                handleClose();
              }}
            >
              <img src={contact} alt="" />
              Contact
            </button>
            <button
              onClick={() => {
                setViewAdminDetailSidebar(true);
                handleClose();
              }}
            >
              <img src={inactive} alt="" />
              Mark Inactive
            </button>
            <button
              onClick={() => {
                deleteDetails();
                handleClose();
              }}
            >
              <img src={deleteBtn} alt="" />
              Delete
            </button>
          </div>
        </Popover>
        <Snackbar
          open={showNotification}
          autoHideDuration={6000}
          onClose={() => {
            setShowNotification(false);
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={notificationData.type}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {notificationData.message}
          </Alert>
        </Snackbar>
        {showAddStudent && (
          <AdminSideBar
            changeStatus={() => {
              setShowAddStudent(false);
            }}
            status={true}
            title={"Add Student"}
            children={
              <>
                <RenderForm
                  formData={adminStudentAddFormTemplate}
                  defaultValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    rollNumber: "",
                    gender: "",
                    pfirstName: "",
                    plastName: "",
                    pprimaryEmail: "",
                    pprimaryPhone: "",
                    relationship: "",
                    type: "ADD",
                  }}
                  onSubmit={handleAddEditStudentDetails}
                  hasBulkUpload
                  sampleBulkUploadTemplate={sampleSheetUrl}
                  submitControls={
                    <SubmitAndCancelButton
                      onCancel={() => {
                        setShowAddStudent(false);
                      }}
                    />
                  }
                />
              </>
            }
          />
        )}
        {viewAdminDetailSidebar && (
          <AdminSideBar
            changeStatus={() => {
              setViewAdminDetailSidebar(false);
            }}
            status={true}
            title={"Admin Details"}
            children={
              <StudentDetailsSideBar
                studentData={slideBarStudentData}
                parentsData={slideBarParentsData}
                onDelete={deleteDetails}
                onEdit={() => {
                  setShowEditStudentDetails(true);
                }}
              />
            }
          />
        )}
        {showEditStudentDetails && (
          <AdminSideBar
            changeStatus={() => {
              setShowEditStudentDetails(false);
            }}
            status={true}
            title={"Edit Student Details"}
            children={
              <>
                <RenderForm
                  formData={adminStudentEditFormTemplate}
                  defaultValues={defaultStudentEditValues}
                  onSubmit={handleAddEditStudentDetails}
                  submitControls={
                    <SubmitAndCancelButton
                      onCancel={() => {
                        setShowEditStudentDetails(false);
                      }}
                    />
                  }
                />
              </>
            }
          />
        )}

        {showDeletePopup && (
          <ConfirmationPopup
            title=" Are you sure you want to delete
                                         student
                                               permanently? "
            confirmText="Yes"
            declineText="No"
            maxWidth="540px"
            onConfirm={() => {
              deleteDetails(activeTableContext?.id);
            }}
            onDecline={() => {
              deleteDetails();
            }}
          />
        )}
      </div>
    </>
  );
};

export default AdminClassDetails;

const DotSvg = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6.5C1 3.18629 3.68629 0.5 7 0.5C10.3137 0.5 13 3.18629 13 6.5C13 9.81371 10.3137 12.5 7 12.5C3.68629 12.5 1 9.81371 1 6.5Z"
        fill="url(#paint0_linear_2403_18166)"
        stroke="#109289"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2403_18166"
          x1="3.25"
          y1="3.25"
          x2="14"
          y2="9.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="" />
          <stop offset="1" stopColor="" />
        </linearGradient>
      </defs>
    </svg>
  );
};
