import { useState } from "react";
import ApiEdpoints, { HTTPMethods } from "../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";

interface OverlayDetails {
  // define your type for overlay details here
}

const useOverlayDetails = () => {
  const [patternOverlayData, setPatternOverlayData] =
    useState<OverlayDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const getOverlayDetails = async (patternDetailsId: string) => {
    setIsLoading(true);
    const payload: any = {
      url: `${ApiEdpoints.PATTERNCARD}/?id=${patternDetailsId}`,
      method: HTTPMethods.GET,
    };

    try {
      const { data } = await makeAxiosRequest(payload);
      setPatternOverlayData(data?.Pattern_details);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  return { patternOverlayData, isLoading, error, getOverlayDetails };
};

export default useOverlayDetails;
