import React from "react";
import styles from "./studentsLevel.module.scss";

const StudentsLevel = ({ levels }: any) => {
    return (
        <div className={styles.studentLevel}>
            <h1>Students Level</h1>

            <div className={styles.number}>
                <div className={styles.block}>
                    <h1>L1-</h1>
                    <p>{levels.l1}</p>
                </div>
                <div className={styles.block}>
                    <h1>L2-</h1>
                    <p>{levels.l2}</p>
                </div>
                <div className={styles.block}>
                    <h1>L3-</h1>
                    <p>{levels.l3}</p>
                </div>
                <div className={styles.block}>
                    <h1>L4-</h1>
                    <p>{levels.l4}</p>
                </div>
                <div className={styles.block}>
                    <h1>L5-</h1>
                    <p>{levels.l5}</p>
                </div>
            </div>
            <p>Class 6 - Class 10</p>
        </div>
    );
};

export default StudentsLevel;
