import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import styles from "./customPagination.module.scss";
import paginationChevronLeft from "../../assets/images/paginationChevronLeft.svg";
import paginationChevronRight from "../../assets/images/paginationChevronRight.svg";
import { useEffect } from "react";

export const CustomPagination = ({
  page,
  handlePaginationChage,
  count,
  detailedViewRef,
}: any) => {
  useEffect(() => {
    detailedViewRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [detailedViewRef, page, handlePaginationChage, count]);

  return (
    <div className={styles.paginationContainer}>
      <Pagination
        defaultPage={0}
        siblingCount={0}
        count={count}
        classes={{ ul: styles.ul }}
        sx={{
          " .MuiPagination-ul ": {
            padding: "12px 0px",
            borderRadius: "10px",
          },
          "	.Mui-selected": {
            background:
              "linear-gradient(104.37deg, #EBF1F4 13.82%, #F8FAFF 89.02%) !important",
            color: "#6862E0",
            fontFamily: "Inter-Medium !important",
            border: "1px solid #6862E0",
            borderRadius: "4px",
            boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.16)",
          },
          " .MuiPaginationItem-root:hover": {
            background: "white",
          },
          " .MuiTouchRipple-root": {
            display: "none",
          },
          "& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root": {
            fontFamily: "Inter-Regular",
            lineHeight: "17px",
            margin: "0px !important",
            color: "#4B5468",
            padding: "0px !important",
          },

          "@media (max-width:420px)": {
            "& .css-1v2lvtn-MuiPaginationItem-root": {
              margin: "0px !important",
              padding: "0px !important",
            },
          },

          "@media (max-width:768px)": {
            "& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
              {
                cursor: "pointer",
                height: "28px",
                width: "28px",
              },
          },
        }}
        renderItem={(item) => (
          <PaginationItem
            components={{
              next: (props) => (
                <>
                  <div className={styles.paginationTextNext}>Next</div>
                  <button {...props} className={styles.arrowRight}>
                    <img src={paginationChevronRight} />
                  </button>
                </>
              ),
              previous: (props) => (
                <>
                  <button {...props} className={styles.arrowLeft}>
                    <img src={paginationChevronLeft} />
                  </button>
                  <div className={styles.paginationTextPrevious}>Previous</div>
                </>
              ),
            }}
            {...item}
          />
        )}
        page={page}
        onChange={handlePaginationChage}
      />
    </div>
  );
};
