const capitalizeAfterSpace = (str: string) => {
  var words = str.split(" ");

  var capitalizedWords = words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  var capitalizedString = capitalizedWords.join(" ");

  return capitalizedString;
};

export default capitalizeAfterSpace;
