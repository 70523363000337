import ReviewAnswerStyle from "./questionSection.module.scss";
import React, { useEffect } from "react";
import { Question } from "./question/question";
import { QuestionNavigationWrapper } from "./questionNavigationWrapper/questionNavigationWrapper";
import { QuestionOption } from "./questionOptions/questionOption";
import { SolveAssignmentNavigation } from "../../solveAssignmentPage/solveAssignmentNavigation/solveAssignmentNavigation";

interface IQuestionSectionProps {
  question: any;
  activeQuestionIndex: any;
  setQuestion: any;
  setActiveQuestionIndex: any;
  saveQuestion: any;
  activeAssignmentType?: any;
  setOptionClicked?: any;
  activeScreen?: any;
  setActiveScreen?: any;
  fromExercisePage?: boolean;
  type?: string;
}

export const QuestionSection = (props: IQuestionSectionProps) => {
  const {
    question,
    activeQuestionIndex,
    setQuestion,
    setActiveQuestionIndex,
    saveQuestion,
    activeAssignmentType,
    setOptionClicked,
    activeScreen,
    setActiveScreen,
    fromExercisePage,
    type,
  } = props;

  useEffect(() => {
    setQuestion(props.question);
  }, [props.question]);

  return (
    <div className={ReviewAnswerStyle.mainContainer}>
      {activeAssignmentType === "assignment" &&
        activeScreen === "exerciseReport" && (
          <div className={ReviewAnswerStyle.reportCardNavigation}>
            Question
            <SolveAssignmentNavigation
              activeQuestionIndex={activeQuestionIndex}
              setActiveQuestionIndex={setActiveQuestionIndex}
              question={question}
              setQuestion={setQuestion}
              saveQuestion={saveQuestion}
              activeAssignmentType={activeAssignmentType}
              activeScreen={activeScreen}
              setActiveScreen={setActiveScreen}
              setOptionClicked={setOptionClicked}
              fromExercisePage={fromExercisePage}
              type={type}
            />
          </div>
        )}
      <div className={ReviewAnswerStyle.container2}>
        <Question
          question={question}
          activeQuestionIndex={activeQuestionIndex}
        />
        <div className={ReviewAnswerStyle.questionBorder}></div>

        <div className={ReviewAnswerStyle.optionsContainer}>
          <QuestionOption
            question={question}
            activeQuestionIndex={activeQuestionIndex}
            setQuestion={setQuestion}
            activeAssignmentType={activeAssignmentType}
            setOptionClicked={setOptionClicked}
            activeScreen={
              activeScreen === "exerciseReport" ? "result" : activeScreen
            }
            saveQuestion={saveQuestion}
          />
        </div>
      </div>

      {activeAssignmentType === "assignment" &&
        activeScreen !== "exerciseReport" && (
          <SolveAssignmentNavigation
            activeQuestionIndex={activeQuestionIndex}
            setActiveQuestionIndex={setActiveQuestionIndex}
            question={question}
            setQuestion={setQuestion}
            saveQuestion={saveQuestion}
            activeAssignmentType={activeAssignmentType}
            activeScreen={activeScreen}
            setActiveScreen={setActiveScreen}
            setOptionClicked={setOptionClicked}
            fromExercisePage={fromExercisePage}
            type={type}
          />
        )}

      {activeAssignmentType !== "assignment" && (
        <QuestionNavigationWrapper
          activeQuestionIndex={activeQuestionIndex}
          setActiveQuestionIndex={setActiveQuestionIndex}
          question={question}
          setQuestion={setQuestion}
          saveQuestion={saveQuestion}
          activeAssignmentType={activeAssignmentType}
          setOptionClicked={setOptionClicked}
        />
      )}
    </div>
  );
};
