import { useEffect, useState } from "react";

export const useSearch = (arr: any) => {
  const [filteredTopics, setFilteredTopics] = useState(arr);
  useEffect(() => {
    setFilteredTopics(arr);
  }, [arr]);

  /**
   * Search functionality for class Details - Assignments page to search with more than one attribute.
   * @param item assigment
   * @param searchVal
   * @returns
   */
  const searchClassDetailsAssignments = (item: any, searchVal: string) => {
    searchVal = searchVal.trim().toLowerCase();
    return item?.assignment_name?.toLowerCase().includes(searchVal) ||
      item?.topic_name
      ? item?.topic_name?.toLowerCase().includes(searchVal)
      : item?.chapter_name?.toLowerCase().includes(searchVal);
  };

  const searchStudentsMyAssignment = (item: any, searchVal: string) => {
    const assignmentName = item?.indexOfTopic
      ? (item?.assignmentName).slice(0, 10) + " " + item?.indexOfTopic
      : item?.assignmentName;
    searchVal = searchVal.toLowerCase();
    return (
      assignmentName?.toLowerCase().includes(searchVal) ||
      (item?.name
        ? item?.name?.toLowerCase().includes(searchVal)
        : item?.chapter_Name?.toLowerCase().includes(searchVal))
    );
  };

  const getFilteredData = (searchVal: string, nameChecker: string) => {
    if (searchVal.length > 0) {
      const filteredData = arr.filter((item: any) => {
        switch (nameChecker) {
          case "patternName":
            return item?.patternName
              ?.toLowerCase()
              .includes(searchVal.trim().toLowerCase());
          case "name":
            return item?.name
              ?.toLowerCase()
              .includes(searchVal.trim().toLowerCase());
          case "subTopic":
            return item?.subTopic?.name
              ?.toLowerCase()
              .includes(searchVal.trim().toLowerCase());
          case "questionName":
            return item?.questionName
              ?.toLowerCase()
              .includes(searchVal.trim().toLowerCase());
          case "divisionClass":
            return ("class " + item?.divisionClass.toString())
              .toLowerCase()
              ?.toLowerCase()
              .includes(searchVal.trim().toLowerCase());
          case "classDetailsAssignments":
            return searchClassDetailsAssignments(item, searchVal);
          case "studentsMyAssignment":
            return searchStudentsMyAssignment(item, searchVal);
          default:
            return item?.name
              ?.toLowerCase()
              .includes(searchVal.trim().toLowerCase());
        }
      });
      setFilteredTopics(filteredData);
    } else {
      setFilteredTopics(arr);
    }
  };

  return [filteredTopics, getFilteredData];
};
