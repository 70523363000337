import React from "react";
import styles from "./confirmationPopup.module.scss";

interface IConfirmationPopup {
  title?: string;
  confirmText: string;
  declineText: string;
  onConfirm: any;
  onDecline: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  maxWidth?: any;
}

const ConfirmationPopup = ({
  title = "Delete?",
  confirmText = "yes",
  declineText,
  onConfirm,
  onDecline,
  maxWidth,
}: IConfirmationPopup) => {
  return (
    <div className={styles.confirmationPopup} style={{ maxWidth: maxWidth }}>
      <h1>{title}</h1>
      <div className={styles.buttons}>
        <button onClick={onConfirm} className={styles.no}>
          {confirmText}
        </button>
        <button onClick={onDecline} className={styles.yes}>
          {declineText}
        </button>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
