import React from "react";
import styles from "./divisionDetails.module.scss";
import divisionCardClassIcon from "../../../../../assets/images/divisionCard.svg";
import borderIcon from "../../../../../assets/images/border.svg";
import ChapterIconName from "../chapterIconName/chapterIconName";

interface IDivision {
  divisionClass: string;
  division: string;
  noOfStudents: string | number;
  handlePattern: any;
  pendingReview: IPendingReview[];
  id: number;
}
interface IPendingReview {
  icon: string;
  value: number;
  text: string;
}
const DivisionDetails = ({
  divisionClass,
  division,
  pendingReview,
  noOfStudents,
  handlePattern,
  id,
}: IDivision) => {
  return (
    <div
      className={styles.divisionCardWrapper}
      onClick={() => handlePattern({ id: id })}
    >
      <ChapterIconName
        icon={divisionCardClassIcon}
        heading={`Class ${divisionClass}`}
      />
      <div className={styles.divisionDetailsContainerRight}>
        <div className={styles.upperContainer}>
          <div className={`${styles.divisionHeading} ${styles.borderCheck}`}>
            {division}
          </div>
          <img src={borderIcon} alt={borderIcon} />
          <div
            className={`${styles.divisionHeading} ${styles.padding}`}
          >{`${noOfStudents} Students`}</div>
        </div>
        <div className={styles.lowerContainer}>
          <div className={styles.pendingReviewContainer}>
            <div className={styles.pendingReviewHeading}>Pending review:</div>
            {pendingReview &&
              Array.isArray(pendingReview) &&
              pendingReview.map(({ text, value, icon }: IPendingReview, index: any) => (
                <div className={styles.pendingReviewDetails} key={index}>
                  <img src={icon} alt={text} />
                  <div
                    className={styles.divisionItem}
                  >{`${value} ${text}`}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DivisionDetails;
