import React from 'react'
import { DescriptiveReviewQuestion } from './descriptiveReviewQuestion/descriptiveReviewQuestion';

interface IReviewOptions {
    question: any;
    activeQuestionIndex: any;
    optionType: string;
    userType?: any;
}
export const ReviewOptions = (
    props: IReviewOptions
) => {
    const {
        question,
        activeQuestionIndex,
        optionType,
        userType
    } = props;
    return (
        <div>
            <DescriptiveReviewQuestion question={question} activeQuestionIndex={activeQuestionIndex} type={optionType} userType={userType} />
        </div>
    )
}

