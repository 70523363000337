import React, { useState } from "react";
import styles from "./superAdminSchool.module.scss";
import Breadcrumb from "../../../common/breadcrumb/breadcrumb";
import SearchBar from "../../../common/searchBar/searchBar";
import SuperAdminAdminTab from "./superAdminAdminTab";
import SuperAdminSchoolTab from "./superAdminSchoolTab";
const SuperAdminSchool = () => {
  const [activeTab, setActiveTab] = useState<any>("SCHOOLS");
  return (
    <div className={styles.learnMainContainer}>
      <div className={styles.greetingsSearchBarContainer}>
        <div className={styles.greetings}>
          <Breadcrumb
            breadcrumbDetails={[
              {
                label: "Home",
                link: "/super-admin",
                selected: false,
              },
              {
                label: "Schools/Admins",
                link: "/super-admin/schools",
                selected: true,
              },
            ]}
          />
        </div>
        <SearchBar searchText={""} setSearchText={() => {}} />
      </div>
      <div className={styles.tabBtnWrapper}>
        <button
          className={activeTab === "SCHOOLS" ? styles.activeTab : ""}
          onClick={() => {
            setActiveTab("SCHOOLS");
          }}
        >
          Schools
        </button>
        <button
          className={activeTab === "ADMINS" ? styles.activeTab : ""}
          onClick={() => {
            setActiveTab("ADMINS");
          }}
        >
          Admin
        </button>
      </div>
      <>
        {activeTab === "SCHOOLS" && <SuperAdminSchoolTab />}
        {activeTab === "ADMINS" && <SuperAdminAdminTab />}
      </>
    </div>
  );
};

export default SuperAdminSchool;
