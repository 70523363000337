import React from "react";
import styles from "./goBackPage.module.scss";
import backArrow from "../../assets/images/backArrowIcon.svg";
import { useNavigate } from "react-router-dom";

interface IGoBackPageProps {
  heading: string;
}
const GoBackPage = ({ heading }: IGoBackPageProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles.navSection} onClick={() =>
     navigate(-1)}>
      <div className={styles.arrowIcon} >
        <img src={backArrow} alt="back" />
      </div>
      <div className={`${styles.inter16} ${styles.navBackText}`}>{heading}</div>
    </div>
  );
};

export default GoBackPage;
