import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import chapterImage from "../../../../../../assets/images/chapterImage.svg";
import ChapterCard from "../../../../../../common/chapterCard/chapterCard";
import useChaptersToBeFocused from "../../../../../../utils/useChaptersToBeFocused";
import styles from "./studentChapters.module.scss";

const StudentChapters = () => {
  const params = useParams();
  const [classId, setClassId] = useState<any>();
  const {
    updatedChapterToBeFocused: updatedChapterWisePatterns,
    fetchChaptersToBeFocused: fetchChapterPatternsNeedToFocus,
  } = useChaptersToBeFocused();

  useEffect(() => {
    classId && fetchChapterPatternsNeedToFocus(classId);
  }, [classId]);

  useEffect(() => {
    setClassId(Number(params?.classId));
  }, []);

  return (
    <div className={styles.studentChapterContainer}>
      {updatedChapterWisePatterns &&
        Array.isArray(updatedChapterWisePatterns) &&
        updatedChapterWisePatterns
          .slice(0, 3)
          .map((chapter: any, index) => (
            <ChapterCard chapter={chapter} icon={chapterImage} key={index} />
          ))}
    </div>
  );
};

export default StudentChapters;
