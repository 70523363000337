import React from "react";
import Dialog from "@mui/material/Dialog";
import classes from "./questionAndAnswerPopup.module.scss";
import correctIcon from "../../../../assets/images/correctSubmit.svg";
import qATimerIcon from "../../../../assets/images/qATimerIcon.svg";
import qAStarIcon from "../../../../assets/images/qAStarIcon.svg";
import wrongIcon from "../../../../assets/images/wrongIcon.svg";
import exclamatoryIcon from "../../../../assets/images/exclamatoryIcon.svg";
import closeIcon from "../../../../assets/images/closeIcon.svg";
import { useSelector } from "react-redux";
import { QuestionAndOptionsElement } from "../../../../common/questionsAndOptions/questionAndOptions";
interface IPopUp {
  title: string;
  content: object[];
  closePopUp: any;
  activeQuestionIndex: number;
}
const SubmitQuestionAndAnswerPopup = ({
  title,
  content,
  closePopUp,
  activeQuestionIndex
}: IPopUp) => {
  const calculateTime = (time: number) => {
    const min = Math.floor(time / 60);
    const sec = time % 60;
    return `${min} min ${sec} sec`;
  };
  return (
    <>
      <Dialog
        open={true}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className={classes.popUpContainer}>
          <div className={classes.popUpHeaderWrapper}>
            <div className={classes.popUpHeaderText}>{title} </div>
            <div className={classes.popUpHeaderClose} onClick={closePopUp}>
              <img src={closeIcon} alt="close" />
            </div>
          </div>
          <div className={classes.popUpCardContainer}>
            {content?.map((item: any, ind) => (
              <div
                className={`${classes.popUpCardItem} ${
                  ind === activeQuestionIndex ? classes.popUpCardItemActive : ""
                }`}
                key={`${item.question}_${ind}`}
              >
                <div className={classes.questionAndImg}>
                  <div className={classes.questionImg}>
                    <img
                      src={
                        item.ans_submit !== "None" && item.ans_submit !== "" ?
                          (item.iscorrect ? correctIcon : wrongIcon) : exclamatoryIcon
                      }
                      alt="status icon"
                    />
                  </div>
                  <div className={classes.questionNumAndText}>
                    <div className={classes.questionNum}>{`Q${ind + 1}.`}</div>
                    <div className={`${classes.questionText} ${classes.questionTextMath}`}>
                      <QuestionAndOptionsElement
                        metaData={item?.question}
                        size="mcqOption"
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.questionTimerAndRating}>
                  <div className={classes.TimerImgAndTime}>
                    <div className={classes.TimerImg}>
                      <img src={qATimerIcon} alt="status icon" />
                    </div>
                    <div className={classes.TimerTime}>
                      {calculateTime(item.timetaken || 0)}
                    </div>
                  </div>
                  <div className={classes.questionRatingAndText}>
                    <div className={classes.questionRating}>
                      <img src={qAStarIcon} alt="status icon" />
                    </div>
                    <div className={classes.questionRatingText}>
                      {item.iscorrect ? item.score : 0}/{item.marks_alloted}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default React.memo(SubmitQuestionAndAnswerPopup);
