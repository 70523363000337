import { Link } from "react-router-dom";
import styles from "./breadcrumb.module.scss";
import chevronRightBreadcrumb from "../../assets/images/chevronRightBreadcrumb.svg";

const Breadcrumb = ({ breadcrumbDetails }: any) => {
  return (
    <div className={styles.breadcrumbContainer}>
      {breadcrumbDetails.map((item: any, index: number) => {
        return (
          <div key={index} className={styles.breadcrumb}>
            <div>
              <Link
                to={item.link || "#"}
                className={item.selected ? styles.selectedLink : styles.link}
              >
                {item.label}
              </Link>
            </div>
            {breadcrumbDetails.length - 1 !== index ? (
              <img
                src={chevronRightBreadcrumb}
                className={styles.chevronRightBreadcrumb}
                alt=""
              />
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
