import React, { useEffect, useState } from "react";
import styles from "./submittedOptions.module.scss";
import { QuestionAndOptionsElement } from "../../../../../../../common/questionsAndOptions/questionAndOptions";
import selectedSubmit from "../../../../../../../assets/images/selectedSubmit.svg";
import disabled from "../../../../../../../assets/images/disable.svg";
import correctSubmit from "../../../../../../../assets/images/correctSubmit.svg";
import wrongSubmit from "../../../../../../../assets/images/wrongSubmit.svg";
import unselected from "../../../../../../../assets/images/unChecked.svg";
import { DescriptiveReviewQuestion } from "../reviewOption/descriptiveReviewQuestion/descriptiveReviewQuestion";
import { BasicModal } from "../../../../../../../common/modalWrapper/modalWrapper";
interface IAssignmentOptions {
  question: any;
  activeQuestionIndex: any;
  optionType: any;
}

export const SubmittedOptions = (props: IAssignmentOptions) => {
  const { question, activeQuestionIndex, optionType } = props;
  const [questionData, setQuestionData] = useState<any>(question);
  //! No Need of this useEffect only for development
  //TODO:  API Issue
  useEffect(() => {
    setQuestionData((questionData: any) =>
      questionData.map((item: any, index: any) => {
        if (index === activeQuestionIndex) {
          return {
            ...item,
            selectedAnswer: Number(item.ans_submit),
          };
        }
        return item;
      })
    );
  }, [question]);

  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      {optionType === "option" ? (
        <div className={styles.reviewOptionContainer}>
          {question[activeQuestionIndex].Answers.map(
            (item: any, index: any) => {
              return (
                <div
                  className={
                    item.ansimage !== null
                      ? styles.containerOld
                      : styles.containerNew
                  }
                  key={index}
                >
                  <div
                    key={index}
                    className={`${styles.optionInside} ${
                      question[activeQuestionIndex].selectedAnswer === item.id
                        ? item.correct
                          ? styles.examSubmitCorrectAnswer
                          : styles.examSubmitWrongAnswer
                        : styles.optionInside2
                    }
                                        ${
                                          item.correct &&
                                          styles.examSubmitCorrectAnswer
                                        }
                                             ${
                                               !question[activeQuestionIndex]
                                                 .selectedAnswer &&
                                               item.correct &&
                                               styles.examSubmitCorrectAnswer
                                             }`}
                  >
                    <div className={styles.containerOption}>
                      <div className={styles.optionInsideUnSelect}>
                        <div className={styles.optionIcon}>
                          {question[activeQuestionIndex].selectedAnswer !==
                            "" ||
                          question[activeQuestionIndex].totalTime === 0 ? (
                            <img
                              className={`${styles.radioButtonStyle} ${styles.disabled}`}
                              src={
                                question[activeQuestionIndex].selectedAnswer ===
                                item.id
                                  ? selectedSubmit
                                  : unselected
                              }
                              alt="selected"
                            />
                          ) : (
                            <img
                              className={`${styles.radioButtonStyle} ${styles.disabled}`}
                              src={disabled}
                              alt="selected"
                            />
                          )}
                        </div>
                        <div>
                          <label className={styles.labelText}>
                            <QuestionAndOptionsElement
                              metaData={item.ans}
                              size="mcqOption"
                            />
                          </label>
                        </div>
                      </div>
                      <div className={styles.containerSelectImage}>
                        {question[activeQuestionIndex].selectedAnswer ? (
                          <>
                            {question[activeQuestionIndex].correctAnswer ==
                              item.id ||
                            question[activeQuestionIndex].selectedAnswer ==
                              item.id ? (
                              <img
                                className={styles.correctImageStyle}
                                src={
                                  question[activeQuestionIndex]
                                    .selectedAnswer == item.id && item.correct
                                    ? correctSubmit
                                    : wrongSubmit
                                }
                                alt="selected"
                              />
                            ) : (
                              <>
                                {item.correct && (
                                  <img
                                    className={styles.correctImageStyle}
                                    src={correctSubmit}
                                    alt="selected"
                                  />
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {item.correct && (
                              <img
                                className={styles.correctImageStyle}
                                src={item.correct ? correctSubmit : wrongSubmit}
                                alt="selected"
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    {item.ansimage ? (
                      <div className={styles.containerPopUp}>
                        <div className={styles.optionImageOnHoverContainer}>
                          <div className={styles.optionImageContainer}>
                            <img
                              src={item.ansimage}
                              alt="rectangle"
                              onClick={() => {
                                return setOpenModal(true);
                              }}
                            />
                            {openModal ? (
                              <BasicModal
                                openModal={openModal}
                                setOpenModal={setOpenModal}
                                question={
                                  question[activeQuestionIndex].Answers[index]
                                }
                                index={index}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            }
          )}
        </div>
      ) : (
        <DescriptiveReviewQuestion
          question={question}
          activeQuestionIndex={activeQuestionIndex}
          type={"result"}
        />
      )}{" "}
    </>
  );
};
