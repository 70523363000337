import React from 'react'
import styles from './submissionDetails.module.scss'
import taskStatus from '../../../../../../assets/images/taskStatus.svg'

interface ISubmissionDetailsProps {
    question: any,
    activeQuestionIndex: any
}

export const SubmissionDetails = (
    props: ISubmissionDetailsProps
) => {
    const {
        question,
        activeQuestionIndex
    } = props;

    const calculateTime = (time: any) => {
        let minutes = Math.floor(time / 60);
        let seconds = time % 60;
        return `${minutes} mins ${seconds} secs`;
    }

    return (
        <div className={styles.submissionDetailsStyle}>
            <div>
                <div className={styles.submissionText}> Submission Details    </div>
                {calculateTime(question[activeQuestionIndex].totalTime || 0)}
                <div className={styles.timetext}></div>
                <div className={styles.timeTakenText}>Time Taken</div>
            </div>
            <img src={taskStatus} alt="taskStatus" />
        </div>
    )
}

