import React, { useState, useEffect, useContext } from "react";
import { constants } from "../../../constants/constants";
import styles from "./assignmentsList.module.scss";
import FilterBar from "../../../common/filterBar/filterBar";

import { CustomPagination } from "../../../common/pagination/customPagination";
import SearchBar from "../../../common/searchBar/searchBar";
import useSorting from "../../../utils/useSorting";
import useSortingByDate from "../../../utils/useSortingByDate";
import StatusContext from "../../../context/loaderContext";
import HomeworkCard from "../../../common/cards/homeworkCard";
import { useLocation, useParams } from "react-router-dom";
import StudentAssignmentCardItem from "../../teacher/myClass/components/studentDetailsAssignments/components/studentAssignmentCardItem/studentAssignmentCardItem";
import { calculateTimeTaken } from "../../../utils/convertTime";
import { formatDate } from "../../../utils/dateFormatter";
import { MenuItem, Select } from "@mui/material";

interface IHomeworkList {
  assignmentsData: object[];
  detailedViewRef: any;
  istabbedPage?: boolean;
  isForReveiw?: boolean;
  homeworkDates?: any;
  selectedHomeworkDate?: any;
  setSelectedHomeworkDate?: any;
}
const HomeworkList = ({
  assignmentsData,
  detailedViewRef,
  istabbedPage,
  isForReveiw,
  homeworkDates,
  selectedHomeworkDate,
  setSelectedHomeworkDate,
}: IHomeworkList) => {
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const loaderStatus: any = useContext(StatusContext);
  const { chapterId } = useParams();
  const homeworkIdFromNotification: any = useLocation();

  const dataMassaging = (data: any) => {
    const updatedData = data.map((item: any, ind: any) => {
      if (isForReveiw) {
        return {
          id: item.chapter_id,
          homeworkTitle: item.chapter_name,
          name: item.chapter_name,
          numberOfQuestion: item.question_count,
          dueDate: item.date,
          status:
            item.status_of_homework?.status == "incomplete"
              ? "Review Pending"
              : "Completed",
          topicId: item.topic_id,
          progress: item.status_of_homework?.percentage,
          homeworkId: item?.homework_id,
        };
      } else {
        return {
          id: item?.chapter?.chapterid,
          homeworkTitle: item?.chapter?.chaptername,
          name: item?.chapter?.chaptername,
          numberOfQuestion: item.question_count,
          dueDate: item.date,
          status: item.status_of_homework?.status,
          topicId: item?.chapter?.chapterid,
          progress: item.status_of_homework?.percentage,
          homeworkId: item?.homework_id,
        };
      }
    });

    if (chapterId && istabbedPage) {
      return updatedData.filter((item: any) => chapterId == item.id);
    }
    return updatedData;
  };
  const [searchText, setSearchText] = useState("");
  const [dropdownArray, setDropdownArray] = React.useState<any>([]);
  const {
    dropdownSelectedValueWithId,
    setDropdownSelectedValueWithId,
    filteredTopics,
    page,
    paginationStart,
    paginationEnd,
    range,
    handlePaginationChange,
  } = useSorting(
    assignmentsData,
    selectedStatus,
    searchText,
    "studentsHomework",
    dataMassaging,
    10, // range
    dropdownArray
  );

  const { sortedByDateArr, handleSorting } = useSortingByDate(filteredTopics);

  useEffect(() => {
    handleSorting("desc");
  }, [filteredTopics]);

  return (
    <div className={styles.allAssignmentsMainContainer}>
      <div className={styles.assignmnetsTitleSearchbarContainer}>
        <div className={styles.assignmentHeaderTitle}>
          {istabbedPage
            ? "Here are all the homeoworks related to this chapter"
            : "Homework"}
        </div>
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
      </div>

      <div className={styles.separator}></div>
      <div className={styles.sortAndFilterContainer}>
        <div className={styles.filterMainContainer}>
          <div className={styles.statusContainer}>
            <FilterBar
              statuses={
                isForReveiw
                  ? ["All", "Review Pending", "Completed"]
                  : ["All", "pending", "incomplete", "completed"]
              }
              selectedStatus={(selectedStatuses: string[]) =>
                setSelectedStatus(selectedStatuses)
              }
              showSorting={false}
              innerDropdownNeeded={true}
            />
          </div>
        </div>
        <div style={{ maxWidth: "100%" }}>
          <Select
            defaultValue={selectedHomeworkDate || ""}
            value={selectedHomeworkDate}
            onChange={(e) => {
              setSelectedHomeworkDate(e.target.value);
            }}
            placeholder={"Test Dates"}
            style={{
              width: "100%",
              background: "#EDF2FA",
            }}
            sx={{
              "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                {
                  color: "#4B5468",
                  fontFamily: "Inter-Regular",
                  fontSize: "14px",
                  lineHeight: "17px",
                  minHeight: "unset",
                  alignItems: "center",
                  padding: "10px 12px 9px 12px",
                  overflow: "hidden",
                },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#D4DCE8 !important",
              },
              "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                { cursor: "not-allowed" },
              "& .MuiInputLabel-shrink": {
                border: "1px solid",
              },
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {!!homeworkDates.length &&
              homeworkDates.map((value: any, index: any) => (
                <MenuItem key={value + index} value={value || ""}>
                  {value || ""}
                </MenuItem>
              ))}
          </Select>
        </div>
      </div>
      <div
        className={`${styles.homeworkItemsContainer} ${
          isForReveiw ? styles.homeworkItemReview : ""
        }`}
      >
        {sortedByDateArr.length > 0 &&
          sortedByDateArr
            ?.slice(paginationStart, paginationEnd)
            .map((item: any, ind: number) => (
              <HomeworkCard
                key={ind}
                isForReveiw={isForReveiw}
                data={item}
                status={item.status}
                fromNotificationId={homeworkIdFromNotification.search.replace(
                  "?homeworkId=",
                  ""
                )}
              />
            ))}
        {filteredTopics.length === 0 && loaderStatus.show === false && (
          <div className={styles.noResultFound}>{constants.NO_DATA_FOUND}</div>
        )}
      </div>

      {filteredTopics?.length > 0 && (
        <CustomPagination
          detailedViewRef={detailedViewRef}
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(filteredTopics?.length / range)}
        />
      )}
    </div>
  );
};

export default React.memo(HomeworkList);
