import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styles from "./classDetailsStudentCard.module.scss";
import Progressbar from "../../../../../common/progressbar/progressbar";
import studentTopicsIcon from "../../../../../assets/images/studentTopicsIcon.svg";
import assignmentsCompIcon from "../../../../../assets/images/assignmentsCompIcon.svg";
import starOutlineIcon from "../../../../../assets/images/starOutlineIcon.svg";
import { constants } from "../../../../../constants/constants";
import { routePaths } from "../../../../../constants/routes";
import { getUsernameInitials } from "../../../../../utils/usernameInitials";
import { useParams } from "react-router-dom";

const ClassDetailsStudentCard = ({
  item,
  studentsDetailsListTotalData,
}: any) => {
  const [studentCardData, setStudentCardData] = useState<any>({});
  const navigate = useNavigate();
  const [classId, setClassId] = useState("");
  const params = useParams();
  const handleStudentDetails = () => {
    navigate(
      routePaths?.studentDetails
        .replace(":classId", classId)
        .replace(":studentId", item?.id)
    );
  };

  const updateStudentDetailsForStrudentCard = (item: any) => {
    setStudentCardData({
      ...item,
      ["userInitials"]: getUsernameInitials(item?.name),
    });
  };
  useEffect(() => {
    setClassId(params.classId ?? "");
    updateStudentDetailsForStrudentCard(item);
  }, []);

  return (
    <div
      className={styles.studentTopicsCardContainer}
      onClick={handleStudentDetails}
    >
      <div className={styles.studentImg}>
        {item?.photo ? (
          <>
            {/* REPLACE THE STUDENT PHOTO WHEN IT WILL COME FROM API */}
            <div className={styles.userIconContainer}>
              <img src={item?.photo} alt="" className={styles.image} />
            </div>
          </>
        ) : (
          <>
            <div className={styles.userIconContainer}>
              <div className={styles.userInitials}>
                {studentCardData?.userInitials}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.studentNameAndDetailsWrapper}>
        <div className={styles.studentNameRollNumber}>
          <div className={styles.studentnameRoll}>
            {studentCardData?.rollNumber}
          </div>
          <div className={styles.separator}>|</div>
          <div className={styles.studentnameRoll}>{studentCardData?.name}</div>
        </div>
        <div className={styles.studentProgressImg}>
          {/* // this line ? */}
          <Progressbar progress={studentCardData.level * 20} noOflevels={5} levelsVisible={false} />
        </div>
        <div className={styles.studentDetailsContainer}>
          <div className={styles.studentTopics}>
            <div className={styles.studentTopicImg}>
              <img src={studentTopicsIcon} />
            </div>
            <div className={styles.studentTopicText}>
              {`${studentCardData?.SubtopicAttempted}/${studentsDetailsListTotalData?.total_topics}
               ${constants.TOPICS_COMPLETED}`}
            </div>
          </div>

          {/* hiding the test and homework sections */}
          {/* <div className={styles.studentTopics}>
            <div className={styles.studentTopicImg}>
              <img src={studentTopicsIcon} />
            </div>
            <div className={styles.studentTopicText}>
              {studentCardData?.testsCompleted} {constants.TESTS_COMPLETED}
            </div>
          </div> */}

          <div className={styles.studentTopics}>
            <div className={styles.studentTopicImg}>
              <img src={assignmentsCompIcon} />
            </div>
            <div className={styles.studentTopicText}>
              {`${studentCardData?.attemptedAssignments}/${studentsDetailsListTotalData?.total_assignmets} `}
              {constants.ASSIGNMENTS_COMPLETED}
            </div>
          </div>

          <div className={styles.studentTopics}>
            <div className={styles.studentTopicImg}>
              <img src={starOutlineIcon} />
            </div>
            <div className={styles.studentTopicText}>
              {constants.AVERAGE_SCORE} {studentCardData?.avgScore}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ClassDetailsStudentCard);
