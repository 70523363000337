import React, { useState, useEffect, useContext, useRef } from "react";
import { makeAxiosRequest } from "aie_common";
import styles from "./chapterDetails.module.scss";
import Breadcrumb from "../../../../common/breadcrumb/breadcrumb";
import { constants } from "../../../../constants/constants";
import Tab from "../../../../common/tab/tab";
import OverviewSection from "../../components/chapterDetails/overviewSection/overview";
import ChapterDetailsAssessments from "../../components/chapterDetails/ChapterDetailsAssessments/chapterDetailsAssessments";
import ApiEdpoints, {
  HTTPMethods,
  queryConstants,
} from "../../../../constants/api_endpoints";
import ChapterDetailsPatterns from "../../components/chapterDetails/ChapterDetailspatterns/chapterDetailsPatterns";
import ChapterDetailsHomework from "../../components/chapterDetails/chapterDetailsHomework/chapterDetailsHomework";
import ChapterDetailsTopics from "../../components/chapterDetails/ChapterDetailsTopics/chapterDetailsTopics";
import PPTOverlay from "../../../../common/pptOverlay/pptOverlay";
import SideBar from "../../../../common/sidebar/sidebar";
import { TestStartOverlay } from "../../../../common/testStartOverlay/testStartOverlay";
import StatusContext from "../../../../context/loaderContext";
import { IPayload } from "./chapterDetailsInterface";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../utils/dateFormatter";
import { useParams } from "react-router-dom";

const ChapterDetails = () => {
  const { chapterId } = useParams();

  const [isLearningStarted, setIsLearningStarted] = useState(false);
  const [chaptersOverViewTData, setChaptersOverViewTData] = useState<any>();
  const [chapterTopicsData, setChapterTopicsData] = useState<any>([]);
  const [patternsTData, setPatternsTData] = useState<any>();
  const [indexToViewAllTabsNumber, setIndexToViewAllTabsNumber] = useState(0);
  const [showPPT, setShowPPT] = useState(false);
  const [testOverlayType, setTestOverlayType] = useState<"assignment" | "mcq">(
    "assignment"
  );
  const [showTestOverlay, setShowTestOverlay] = useState(false);
  const [pptOverlayData, setPPTOverlayData] = useState({
    name: "",
    pptUrl: "",
  });
  const [assignmentOverlayData, setAssignmentOverlayData] = useState({
    name: "",
    id: null,
    index: "",
    topicSerialNumber: "",
  });
  let [breadcrumbData, setBreadcrumbData] = useState(
    constants.CHAPTER_DETAILS_BREADCRUMB_DATA
  );
  const [listOfAssignmentsWithLevelsData, setListOfAssignmentsWithLevelsData] =
    useState<any>([]);
  const loaderStatus: any = useContext(StatusContext);
  const selectedIndexData = useSelector(
    (state: any) => state.selectedTabReducer
  );
  const chapterDetailsRef: any = useRef(null);

  const ChapterDetailsReturningTitles = [
    constants.OVERVIEW,
    constants.TOPICS,
    // constants.PATTERNS,
    constants.TESTS, //NOT MVP TASK
    constants.HOMEWORK,
  ];
  const ChapterDetailsNotStartedTitles = [
    constants.OVERVIEW,
    constants.TOPICS,
    // constants.PATTERNS,
    constants.TESTS, //NOT MVP TASK
    constants.HOMEWORK,
  ];
  const getIndexToViewAllTabsData = (index: number) => {
    setIndexToViewAllTabsNumber(index);
  };

  const [topicId, setTopicId] = useState(0);
  const handleOverlays = (type: string, item: any) => {
    setTopicId(item?.id);
    if (type === "mcq") {
      setAssignmentOverlayData({
        name: item?.name,
        id: item?.id,
        index: item?.index,
        topicSerialNumber: item?.topicSerialNumber,
      });
      setShowTestOverlay(true);
      setTestOverlayType(type);
    } else if (type === "ppt") {
      setPPTOverlayData({ name: item?.name, pptUrl: item?.pptUrl?.document });
      setShowPPT(true);
    } else if (type === "assignment") {
      setAssignmentOverlayData({
        name: item?.name,
        id: item?.id,
        index: item?.index,
        topicSerialNumber: item?.topicSerialNumber,
      });
      setShowTestOverlay(true);
      setTestOverlayType(type);
    } else {
      return;
    }
  };

  const handleOverlayClose = (type?: string) => {
    if (type === "mcq" || type === "assigment") setShowTestOverlay(false);
    else if (type === "ppt") {
      setShowPPT(false);
    } else {
      return;
    }
  };
  const [videoNotesOverlay, setVideoNotesOverlay] = useState(false);
  const [videoNotesOverlayContent, setVideoNotesOverlayContent] =
    useState("pattern");
  const handlePatternVideoOverlay = (type: any) => {
    setVideoNotesOverlay(!videoNotesOverlay);
    setVideoNotesOverlayContent("pattern");
  };
  const [finalChapterTopicsData, setFinalChapterTopicsData] = useState<any>([]);
  const ChapterDetailsReturningContents = [
    <OverviewSection
      indexToViewAllTabsData={getIndexToViewAllTabsData}
      chaptersOverViewTData={chaptersOverViewTData}
      patternsTData={patternsTData}
      chapterTopicsCompleted={true}
    />,
    <ChapterDetailsTopics
      topicsListData={finalChapterTopicsData}
      handleOverlays={handleOverlays}
      totalchapterDetails={chaptersOverViewTData}
    />,
    // <ChapterDetailsPatterns
    //   handlePatternVideoOverlay={handlePatternVideoOverlay}
    //   patternsTData={patternsTData}
    // />,
    <ChapterDetailsAssessments />,
    <ChapterDetailsHomework />,
  ];
  const ChapterDetailsNotStartedContents = [
    <OverviewSection
      indexToViewAllTabsData={getIndexToViewAllTabsData}
      chaptersOverViewTData={chaptersOverViewTData}
      patternsTData={patternsTData}
      chapterTopicsCompleted={false}
    />,
    <ChapterDetailsTopics
      topicsListData={finalChapterTopicsData}
      handleOverlays={handleOverlays}
      totalchapterDetails={chaptersOverViewTData}
    />,
    // <ChapterDetailsPatterns
    //   handlePatternVideoOverlay={handlePatternVideoOverlay}
    //   patternsTData={patternsTData}
    // />,
    <ChapterDetailsAssessments />,
    <ChapterDetailsHomework />,
  ];

  interface IGetPaylod {
    methodType: string;
    methodApi: string;
    methodQueryParam?: string;
    methodQueryParamValue?: number;
  }

  const getPaylod = (
    methodType: string,
    methodApi: string,
    methodQueryParam = "" as string,
    methodQueryParamValue = 0 as number
  ) => {
    const payload: IPayload = {
      method: methodType,
      url:
        methodQueryParam && methodQueryParamValue
          ? `${methodApi}/?${methodQueryParam}=${methodQueryParamValue}`
          : `${methodApi}`,
      data: {},
      headers: {},
    };
    return payload;
  };

  const getChaptersOverview = async (chapterId: any) => {
    loaderStatus.setState({ show: true });
    const chaptersOverviewPayload = getPaylod(
      HTTPMethods.GET,
      ApiEdpoints.CHAPTER_TOPICS_OVERVIEW,
      queryConstants.CHAPTER_ID,
      chapterId
    );
    const patternsPayload = getPaylod(
      HTTPMethods.GET,
      ApiEdpoints.FETCHING_PATTERNS,
      queryConstants.CHAPTER_ID,
      chapterId
    );

    try {
      const chaptersOverviewResponse: any = await makeAxiosRequest(
        chaptersOverviewPayload
      );
      const patternsResponse: any = await makeAxiosRequest(patternsPayload);

      loaderStatus.setState({ show: false });
      if (
        chaptersOverviewResponse &&
        chaptersOverviewResponse?.status === 200
      ) {
        let topics_data = chaptersOverviewResponse?.data.topics_data;
        let chapterData = chaptersOverviewResponse?.data.chapter_data;

        let chapterNumber = chapterData.name.split(".")[0];
        breadcrumbData[breadcrumbData.length - 1].label = `Chapter ${
          chapterNumber > 9 ? chapterNumber : "0" + chapterNumber
        }`;
        setBreadcrumbData(breadcrumbData);
        const topicsListData = topics_data.map((topic: any, index: number) => {
          return {
            topicNumber: index + 1,
            ...topic,
          };
        });
        setChapterTopicsData(topicsListData);
        setChaptersOverViewTData(chaptersOverviewResponse?.data);
      }
      if (patternsResponse && patternsResponse?.status === 200) {
        setPatternsTData(patternsResponse?.data);
      }

      if (
        chaptersOverviewResponse &&
        chaptersOverviewResponse?.status === 200 &&
        patternsResponse &&
        patternsResponse?.status === 200
      ) {
        setIsLearningStarted(
          chaptersOverviewResponse?.data.chapter_data.completedTopics > 0
        );
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    const paths = window.location?.pathname.split("/");
    const id = Number(paths[paths?.length - 1]);
    getChaptersOverview(id);
    selectedIndexData && setIndexToViewAllTabsNumber(selectedIndexData.learn);
  }, []);

  useEffect(() => {
    chapterDetailsRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [indexToViewAllTabsNumber]);
  const listOfAssignmentsWithLevels = async () => {
    const payload = {
      url: `${ApiEdpoints.LIST_OF_ASSIGNMENTS}?chapter_id=${chapterId}`,
      method: HTTPMethods.GET,
      data: {},
    };
    try {
      const response = await makeAxiosRequest(payload);

      if (response?.status === 200) {
        setListOfAssignmentsWithLevelsData(response?.data?.data[0]);
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };
  useEffect(() => {
    listOfAssignmentsWithLevels();
  }, [chapterTopicsData]);
  useEffect(() => {
    if (
      listOfAssignmentsWithLevelsData?.level_2 ||
      listOfAssignmentsWithLevelsData?.level_3
    ) {
      setFinalChapterTopicsData([
        ...chapterTopicsData,
        {
          assignmentChapter: listOfAssignmentsWithLevelsData?.name.replace(
            /^\d+\.\s/,
            ""
          ),
          chapterIndex: listOfAssignmentsWithLevelsData?.chapter_index,
          name: listOfAssignmentsWithLevelsData?.level_2?.Assignment_name,
          assignmentAttemptDate: formatDate(
            listOfAssignmentsWithLevelsData?.level_2?.last_attempted
          ),
          type: "assignment",
          progress: listOfAssignmentsWithLevelsData?.level_2?.progress,
          questionCount:
            listOfAssignmentsWithLevelsData?.level_2?.question_count,
          scoredMarks: listOfAssignmentsWithLevelsData?.level_2?.scored_marks,
          totalMarks: listOfAssignmentsWithLevelsData?.level_2?.total_marks,
          topicNumber: chapterTopicsData.length + 1,
          assignmentLevel: 2,
        },
        {
          assignmentChapter: listOfAssignmentsWithLevelsData?.name.replace(
            /^\d+\.\s/,
            ""
          ),
          chapterIndex: listOfAssignmentsWithLevelsData?.chapter_index,
          name: listOfAssignmentsWithLevelsData?.level_3?.Assignment_name,
          assignmentAttemptDate: formatDate(
            listOfAssignmentsWithLevelsData?.level_3?.last_attempted
          ),
          type: "assignment",
          progress: listOfAssignmentsWithLevelsData?.level_3?.progress,
          questionCount:
            listOfAssignmentsWithLevelsData?.level_3?.question_count,
          scoredMarks: listOfAssignmentsWithLevelsData?.level_3?.scored_marks,
          totalMarks: listOfAssignmentsWithLevelsData?.level_3?.total_marks,
          topicNumber: chapterTopicsData.length + 2,
          assignmentLevel: 3,
        },
      ]);
    }
  }, [chapterTopicsData, listOfAssignmentsWithLevelsData]);
  return (
    <>
      <div
        className={styles.chapterDetailsMainContainer}
        ref={chapterDetailsRef}
      >
        {chaptersOverViewTData && patternsTData && (
          <>
            <Breadcrumb
              breadcrumbDetails={constants.CHAPTER_DETAILS_BREADCRUMB_DATA}
            />
            <Tab
              indexToViewAllTabsNumber={indexToViewAllTabsNumber}
              setIndexToViewAllTabsNumber={setIndexToViewAllTabsNumber}
              titles={
                isLearningStarted
                  ? ChapterDetailsReturningTitles
                  : ChapterDetailsNotStartedTitles
              }
              contents={
                isLearningStarted
                  ? ChapterDetailsReturningContents
                  : ChapterDetailsNotStartedContents
              }
              id={constants.LEARN}
            />
          </>
        )}
        {showPPT && (
          <PPTOverlay
            closeOverlay={handleOverlayClose}
            pptOverlayData={pptOverlayData}
          />
        )}
        {showTestOverlay && (
          <SideBar
            content={
              <TestStartOverlay
                data={assignmentOverlayData}
                closeOverlay={handleOverlayClose}
                topicId={topicId}
                type={testOverlayType}
              />
            }
            width="620px"
          />
        )}
      </div>
    </>
  );
};

export default ChapterDetails;
