export const getUsernameInitials = (user: string) => {
  return (
    user &&
    user
      .split(" ")
      .map((user) => user[0])
      .join("")
      .toUpperCase()
      .slice(0, 2)
  );
};
