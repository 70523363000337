const initialState = [
  {
    "id": 1,
    "question": "",
    "Answers": [],
    "selectedAnswer": "",
    "correctAnswer": "",
    "visited": "",
    "score": "02",
    "img": "",
    "totalTime": 25,
    "open": false,
    "subTopics": "",
    "topicNumber": "",
    "Questionid": 1,
    "code": 1,
    "descriptive": false,
    "tag": "",
    "uploadedImages": [],
    "topicId": "",
    "topic_slno": ""
  },
];

const mcqQuestionReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case "UPDATE_MCQ":
      return [...action.payload];
    default:
      return state;
  }
};
export default mcqQuestionReducer;
