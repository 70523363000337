import { makeAxiosRequest } from "aie_common";
import { useState } from "react";
import ApiEdpoints, {
  HTTPMethods,
  queryConstants,
} from "../constants/api_endpoints";
import { getAnalyticsChartConfig } from "./analyticsGraphConfig";

export const useAnalyticsGraphData = () => {
  const [graphData, setGraphData] = useState<any>();

  const fetchAnalyticsChartData = async (chapterId: any) => {
    const payload = {
      url:
        ApiEdpoints.ANALYTICS_GRAPH +
        `/?${queryConstants.CHAPTER_ID}=${chapterId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      const ANALYTICS_GRAPH_DATA = getAnalyticsChartConfig(
        res?.data?.data.length >= 4 ? 4 : res.data?.data.length
      );
      let competency = res?.data?.data?.map(
        (item: any) => item.competency / 20
      );
      let mastery = res?.data?.data?.map(
        (item: any) => item.learning_stage / 20
      );
      ANALYTICS_GRAPH_DATA.datasets[0].data = mastery;
      // ANALYTICS_GRAPH_DATA.datasets[1].data = competency;
      setGraphData({
        labels: res?.data?.data.map((item: any) => item.topic_name),
        datasets: [...ANALYTICS_GRAPH_DATA.datasets],
      });
      return "success";
    } catch (error) {
      console.log(error);
    }
  };

  return {
    graphData,
    fetchAnalyticsChartData,
  };
};
