import React, { useContext, useEffect, useState } from "react";
import styles from "./descriptiveQuestionReviewPage.module.scss";
import GoBackPage from "../../../common/goBackPage/goBackPage";
import DescriptiveQuestion from "../../learn/pages/assignmentPage/questionSection/questionOptions/assignmentOptions/descriptiveQuestion/descriptiveQuestion";
import PatternCardCarousal from "../../learn/pages/assignmentPage/assignmentResultWrapper/resultSection/patternCardCarousal/patternCardCarousal";
import { Question } from "../../learn/pages/assignmentPage/questionSection/question/question";
import { QuestionOption } from "../../learn/pages/assignmentPage/questionSection/questionOptions/questionOption";
import { DescriptiveQuestionSubmissionModal } from "./descriptiveQuestionSubmissionModal/descriptiveQuestionSubmissionModal";
import StarIcon from "../../../assets/images/star.svg";
import { constants } from "../../../constants/constants";
import ApiEdpoints, { HTTPMethods } from "../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import { QuestionNavigationWrapper } from "./questionNavigationWrapper/questionNavigationWrapper";
import { formatDateWithTime } from "../../../utils/dateFormatter";
import { calculateTimeTaken } from "../../../utils/convertTime";
import { convertToFormData } from "../../../utils/createFormData";
import StatusContext from "../../../context/loaderContext";
import { getUsernameInitials } from "../../../utils/usernameInitials";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

interface ITopicInfo {
  assignment_name: string;
  chapter_index: string;
  submission_list: any[];
  topic_id: number;
  topic_index: number;
  topic_name: string;
  assignment_index: string;
}

interface IStudentDetails {
  roll_number: number;
  student_DP: string;
  student_level: number;
  student_name: string;
}

const DescriptiveQuestionReviewPage = () => {
  const [question, setQuestion]: any = React.useState([]);

  const [marks, setMarks] = useState<number>();
  const [marksValid, setMarksValid] = useState<string>("");
  const [topicInfo, setTopicInfo] = React.useState<ITopicInfo>();
  const [mentorResponseSaved, setMentorResponseSaved] = useState(false);
  const [saveQuestionResponse, setSaveQuestionResponse] = useState(false);
  const loaderStatus: any = useContext(StatusContext);
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [topicId, setTopicId] = useState(searchParams.get("topicId"));
  const [chapterId, setChapterId] = useState(searchParams.get("chapterId"));
  const [level, setLevel] = useState(searchParams.get("level"));
  const [studentId, setStudentId] = useState(searchParams.get("studentId"));
  const [studentDetails, setStudentDetails] = useState<IStudentDetails>();
  const [patternCards, setPatternCards] = useState<any>();
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<any>(0);
  const [openModal, setOpenModal] = useState(false);
  const [userType, setUserType] = useState<string>(
    localStorage.getItem("USERTYPE") || ""
  );
  const questionData = useSelector(
    (state: any) => state.solveAssignmentReducer
  );
  const topicSubmitList = topicId || chapterId;

  const fetchAssignmentDescriptiveQuestionsData = async (params: any) => {
    let url = topicId
      ? `${ApiEdpoints.ASSIGNMENT_DESCRIPTIVE_QUESTIONS}/?class_id=${params.classId}&topic_id=${topicId}`
      : `${ApiEdpoints.ASSIGNED_QUESTION_LIST}/?student_id=${studentId}`;

    if (chapterId) {
      url = `${ApiEdpoints.ASSIGNMENT_DESCRIPTIVE_QUESTIONS}/?class_id=${params.classId}&chapter_id=${chapterId}&level=${level}`;
    }

    const payload = {
      url: url,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      !loaderStatus.show && loaderStatus.setState({ show: true });
      const assignmentsData: any = await makeAxiosRequest(payload);
      if (assignmentsData.status !== 200) {
        throw new Error("Error!");
      }
      let data = assignmentsData?.data?.data?.submission_list;
      if (!topicId) {
        let obj = {
          roll_number: assignmentsData?.data?.data.roll_number,
          student_DP: assignmentsData?.data?.data.student_DP,
          student_level: assignmentsData?.data?.data.student_level,
          student_name: assignmentsData?.data?.data.student_name,
        };
        setStudentDetails(obj);
      }
      data = data.map((ele: any, index: number) => {
        let item = {
          ...ele,
          id: index + 1,
          descriptive: true,
          student_comment: ele.student_comments || "",
          mentor_comment: ele.mentor_comments,
          solutions:
            typeof ele.student_images == "string"
              ? ele.student_images
                ? JSON.parse(ele.student_images.toString())
                : []
              : ele.student_images,
          mentor_images:
            typeof ele.mentor_images == "string"
              ? ele.mentor_images
                ? JSON.parse(ele.mentor_images.toString())
                : []
              : ele.mentor_images,
          status: ele.status, //@TODO: remove this condition later.
          // marks_scored: '' || 0
        };
        delete item.mentor_comments;
        delete item.student_comments;
        delete item.student_images;
        return item;
      });
      setQuestion(data);
      const patternData = data?.map((item: any, index: any) => {
        return {
          id: index,
          patterns: [],
          question: "",
        };
      });
      setPatternCards(patternData);
      setTopicInfo(assignmentsData?.data?.data);
      loaderStatus.setState({ show: false });
    } catch (err) {
      loaderStatus.setState({ show: false });
      console.log(err);
    }
  };

  const onMarksInput = (event: any) => {
    let value = event.target.value;
    typeof Number(value) != "number" ||
    value > question[activeQuestionIndex]?.marks_alloted ||
    value < 0
      ? setMarksValid("Invalid input")
      : setMarksValid("");
    setMarks(value);
  };

  useEffect(() => {
    setQuestion(() => {
      return question?.map((item: any) => {
        if (item.id === question[activeQuestionIndex]?.id) {
          item.marks_scored = marks;
        }
        return item;
      });
    });
  }, [marks]);

  useEffect(() => {
    if (saveQuestionResponse) {
      saveMentorResponse();
    }
  }, [saveQuestionResponse]);

  const saveMentorResponse = async () => {
    const dataObject = {
      assignment_id: question[activeQuestionIndex].assignment_id,
      comment: String(question[activeQuestionIndex].mentor_comments || ""),
      score: String(question[activeQuestionIndex].marks_scored),
    };

    let formData = convertToFormData(dataObject);
    if (question[activeQuestionIndex]?.uploadedImages?.length) {
      question[activeQuestionIndex].uploadedImages.forEach((element: any) => {
        formData.append("mentor_image", element);
      });
    }

    let payload = {
      url: ApiEdpoints.SUBMIT_MENTOR_RESPONSE,
      data: formData,
      method: HTTPMethods.POST,
      headers: {
        "Content-Type": "multipart/form-data", // Set the Content-Type header to multipart/form-data
      },
    };

    try {
      const res: any = await makeAxiosRequest(payload);
      setQuestion(() => {
        return question?.map((item: any) => {
          if (item.id === question[activeQuestionIndex].id) {
            item.status = "completed";
            item.mentor_images = question[activeQuestionIndex].uploadedImages;
          }
          return item;
        });
      });
      setMentorResponseSaved(true);
      setSaveQuestionResponse(false);
    } catch (error) {
      setSaveQuestionResponse(false);
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAssignmentDescriptiveQuestionsData(params);
  }, [topicId, studentId]);

  useEffect(() => {
    setMarks(question[activeQuestionIndex]?.marks_scored);
  }, [activeQuestionIndex]);

  useEffect(() => {
    let selectedQuestionId = questionData?.activeQuestionIndexMentor;
    let index = question?.findIndex(
      (ele: any) => ele.question_id === selectedQuestionId
    );
    setActiveQuestionIndex(index || 0);
  }, [questionData]);

  return (
    <div className={styles.mainContainer}>
      {openModal ? (
        <DescriptiveQuestionSubmissionModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          setSaveQuestionResponse={setSaveQuestionResponse}
          name={question[activeQuestionIndex]?.student_name}
          correctQuestions={marks}
          totalQuestions={question[activeQuestionIndex]?.marks_alloted}
        />
      ) : null}
      {question && question.length ? (
        <>
          <GoBackPage
            heading={
              topicSubmitList
                ? level
                  ? `${topicInfo?.assignment_name} - Assignment Level ${level}`
                  : `${topicInfo?.topic_index} ${topicInfo?.topic_name}`
                : "Student Details"
            }
          />
          <div className={styles.bodyContainer}>
            <div className={styles.leftContainer}>
              <div className={styles.leftUpperContainer}>
                <div className={styles.reviewQuestionContainer}>
                  <Question
                    question={question}
                    activeQuestionIndex={activeQuestionIndex}
                  />
                  <div className={styles.questionBorder}></div>
                  {!mentorResponseSaved &&
                    question != null &&
                    question[activeQuestionIndex]?.status !== "completed" && (
                      <div className={styles.optionsContainer}>
                        <QuestionOption
                          userType={userType}
                          question={question}
                          activeQuestionIndex={activeQuestionIndex}
                          setQuestion={setQuestion}
                          activeAssignmentType={"reviewAssignment"}
                          setOptionClicked={""}
                        />
                      </div>
                    )}

                  {/* we have to change the condition once we have the api call available */}
                  {question != null &&
                  question[activeQuestionIndex]?.status !== "completed" ? (
                    <div className={styles.leftDownContainer}>
                      <div className={styles.marksBox}>
                        <label>{constants.MARKS_SCORED_BY_STUDENT}</label>
                        <div className={styles.inputBoxWrap}>
                          <div className={styles.inputBox}>
                            <input
                              type="number"
                              value={marks}
                              onChange={onMarksInput}
                              min={0}
                              max={10}
                            />
                            <span>
                              of {question[activeQuestionIndex]?.marks_alloted}
                            </span>
                          </div>
                          {marksValid !== "" && (
                            <div className={styles.errorMessage}>
                              {marksValid}
                            </div>
                          )}
                        </div>
                      </div>
                      <DescriptiveQuestion
                        question={question}
                        activeQuestionIndex={activeQuestionIndex}
                        setQuestion={setQuestion}
                        userType={userType}
                      />
                    </div>
                  ) : (
                    <div className={styles.optionsContainer}>
                      <QuestionOption
                        userType={userType}
                        question={question}
                        activeQuestionIndex={activeQuestionIndex}
                        setQuestion={setQuestion}
                        activeScreen={"result"}
                        setOptionClicked={""}
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* we have to change the condition once we have the api call available */}
              <QuestionNavigationWrapper
                activeQuestionIndex={activeQuestionIndex}
                setActiveQuestionIndex={setActiveQuestionIndex}
                question={question}
                setQuestion={setQuestion}
                saveQuestion={""}
                activeAssignmentType={"reviewAssignment"}
                teachersAssignment={true}
                setOpenModal={setOpenModal}
                validInput={marksValid}
              />
            </div>

            {/* result section */}
            <div className={styles.rightContainer}>
              <div className={styles.studentDetailsBox}>
                {(
                  topicSubmitList
                    ? question[activeQuestionIndex]?.student_DP
                    : studentDetails?.student_DP
                ) ? (
                  // eslint-disable-next-line jsx-a11y/img-redundant-alt
                  <img
                    src={
                      topicSubmitList
                        ? question[activeQuestionIndex]?.student_DP
                        : studentDetails?.student_DP
                    }
                    alt="image"
                  />
                ) : (
                  <div className={styles.userIconContainer}>
                    <div className={styles.userInitials}>
                      <div className={styles.initials}>
                        {getUsernameInitials(
                          topicSubmitList
                            ? question[activeQuestionIndex]?.student_name
                            : studentDetails?.student_name
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className={styles.studentBox}>
                  <div className={styles.studentNameheading}>
                    {topicSubmitList
                      ? question[activeQuestionIndex]?.student_name
                      : studentDetails?.student_name}
                  </div>
                  <div className={styles.studentDetails}>
                    {topicSubmitList
                      ? question[activeQuestionIndex]?.roll_number
                      : studentDetails?.roll_number}
                    |
                    {` L${
                      topicSubmitList
                        ? question[activeQuestionIndex]?.student_level
                        : studentDetails?.student_level
                    }`}
                  </div>
                </div>
              </div>
              <div className={styles.testDetailsContainer}>
                {question != null &&
                question[activeQuestionIndex]?.status === "completed" ? (
                  <>
                    <div className={styles.scoreContainer}>
                      <div className={styles.leftScoreContainer}>
                        <img src={StarIcon} alt="star" />
                      </div>
                      <div className={styles.rightScoreContainer}>
                        <div className={styles.upperRightScoreContainer}>
                          <div className={styles.attemptedQuestions}>
                            {question[activeQuestionIndex]?.marks_scored}/
                            {question[activeQuestionIndex]?.marks_alloted}
                          </div>
                        </div>
                        <div className={styles.scoreHeading}>
                          {constants.SCORE}
                        </div>
                      </div>
                    </div>
                    <div className={styles.verticalLine}></div>
                  </>
                ) : null}
                <div className={styles.timesContainer}>
                  <div className={styles.testDetailsLeftContainer}>
                    <div className={styles.heading}>
                      {question
                        ? formatDateWithTime(
                            question[activeQuestionIndex]?.submitted_on
                          )
                        : ""}
                    </div>
                    <div className={styles.headingName}>
                      {constants.ATTEMPTED_DATE}
                    </div>
                  </div>
                  <div className={styles.testDetailsRightContainer}>
                    <div className={styles.heading}>
                      {calculateTimeTaken(
                        question[activeQuestionIndex]?.time_taken
                      )}
                    </div>
                    <div className={styles.headingName}>
                      {constants.TIME_TAKEN}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.patternContainer}>
                {/* <div className={styles.patternContainerHeading}>
                  Patterns used in this question
                </div>
                <PatternCardCarousal
                  question={question}
                  setQuestion={setQuestion}
                  activeQuestionIndex={activeQuestionIndex}
                  tag={question[activeQuestionIndex]?.question_tag}
                  userType={userType}
                  setPatternCards={setPatternCards}
                  patternCards={patternCards}
                /> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default DescriptiveQuestionReviewPage;
