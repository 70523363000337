import { useContext, useEffect, useState } from "react";
import StatusContext from "../context/loaderContext";
import ApiEdpoints, { HTTPMethods } from "../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import { useParams } from "react-router-dom";
import { set } from "react-hook-form";

export const useStudentListDataWithLevel = (classId?: number | string) => {
  const params = useParams();
  const [studentsDetailsListTotalData, setStudentsDetailsListTotalData] =
    useState<any>([]);
  const [massagedStudentsDetailsList, setMassagedStudentsDetailsList] =
    useState<any>([]);
  const [noOfStudentsByLevel, setNoOfStudentsByLevel] = useState<any>([]);

  const loaderStatus: any = useContext(StatusContext);

  const fetchStudentsDetailsList = async (classId: any) => {
    const payload = {
      url: `${ApiEdpoints.STUDENT_LIST_WITH_ASSIGNMENT}/?class_id=${
        classId || params.classId
      }`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      loaderStatus.setState({ show: true });
      const response: any = await makeAxiosRequest(payload);
      if (response?.status === 200) {
        loaderStatus.setState({ show: false });
        setStudentsDetailsListTotalData(response?.data?.data);
      }
    } catch (err) {
      loaderStatus.setState({ show: false });
      console.log(err);
    }
  };
  const dataMassaging = (data: any) => {
    return data?.map((item: any) => {
      return {
        name: item?.username,
        id: item?.userid,
        rollNumber: item?.rollnumber,
        photo: item?.photo,
        level: item?.level,
        avgScore: item?.avg_score ? item?.avg_score.toFixed(2) : 0,
        attemptedAssignments: item?.attempted_assignments,
        SubtopicAttempted: item?.Subtopic_attempted,
      };
    });
  };

  useEffect(() => {
    fetchStudentsDetailsList(classId);
  }, [classId, params.classId]);

  useEffect(() => {
    setMassagedStudentsDetailsList(
      dataMassaging(studentsDetailsListTotalData?.student_detils)
    );
    if (
      studentsDetailsListTotalData &&
      studentsDetailsListTotalData?.student_detils &&
      studentsDetailsListTotalData?.student_detils.length > 0
    ) {
      const data = filterObjectsByLevel(
        studentsDetailsListTotalData?.student_detils
      );
      setNoOfStudentsByLevel(data);
    }
  }, [studentsDetailsListTotalData]);

  return {
    massagedStudentsDetailsList,
    studentsDetailsListTotalData,
    noOfStudentsByLevel,
  };
};

function filterObjectsByLevel(objects: any) {
  const filteredArrays: any = [[], [], [], [], []]; // Create five empty arrays

  objects.forEach((object: any) => {
    // Access the level property of each object
    const level = object.level;

    // Add the object to the corresponding filtered array based on its level
    switch (level) {
      case 1:
        filteredArrays[0].push(object);
        break;
      case 2:
        filteredArrays[1].push(object);
        break;
      case 3:
        filteredArrays[2].push(object);
        break;
      case 4:
        filteredArrays[3].push(object);
        break;
      case 5:
        filteredArrays[4].push(object);
        break;
      default:
        break;
    }
  });
  const lengths = filteredArrays.map((array: any) => array.length); // Get the lengths of the filtered arrays
  return lengths;
}
