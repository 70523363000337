import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { routePaths } from "../../../../../../constants/routes";
import styles from "./time.module.scss";
import assignmentTimerSectionClock from "../../../../../../assets/images/assignmentTimerSectionClock.svg";
import { useDispatch, useSelector } from "react-redux";
import solveAssignmentReducer from "../../../../../../store/reducers/solveAssingmentReducer";

export const Time = (props: any) => {
  const {
    activeQuestionIndex,
    question,
    setSubmintingAssignment,
    setQuestion,
    setActiveQuestionIndex,
    saveQuestion,
    activeAssignmentType,
    optionClicked,
    setOptionClicked,
    isDataUpdated,
  } = props;

  const [timeSec, setTimeSec] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const navigate = useNavigate();
  const { testId } = useParams();

  useEffect(() => {
    if (activeAssignmentType === "test") {
      const interval = setInterval(() => {
        if (timeSec === 0) {
          if (activeQuestionIndex !== question.length && timeSec === 0) {
            saveQuestion(question);
            // setQuestion(question.map((item: any, index: number) => {
            //     if (index === activeQuestionIndex) {
            //         item.totalTime = timeSec;
            //         item.visited = item.selectedAnswer === "" ? "" : "Completed";
            //         item.open = true;
            //     }
            //     return item;
            // }))
            // saveQuestion();
            setActiveQuestionIndex(activeQuestionIndex + 1);
          }
        } else {
          setTimeSec(timeSec - 1);
        }
      }, 1000);
      if (
        activeQuestionIndex == question.length - 1 &&
        activeQuestionIndex != 0 &&
        timeSec === 0
      ) {
        setSubmintingAssignment(true);
        setTimeout(() => {
          dispatch({ type: "UPDATE_QUESTION", payload: question });
          dispatch({ type: "UPDATE_MCQ", payload: question });
          setSubmintingAssignment(false);
          navigate(-1);
        }, 5000);
      }
      return () => clearInterval(interval);
    } else {
      const interval = setInterval(() => {
        setTimeSec(timeSec + 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timeSec]);
  const [isUpdateTime, setIsUpdateTime] = useState(false);
  useEffect(() => {
    if (activeAssignmentType === "test") {
      setTimeSec(question[activeQuestionIndex]?.totalTime);
      if (optionClicked) {
        setQuestion(
          question.map((item: any, index: number) => {
            if (index === activeQuestionIndex) {
              item.totalTime = timeSec;
              item.visited = item.selectedAnswer === "" ? "" : "Completed";
              item.open = true;
            }
            return item;
          })
        );
      }
      setOptionClicked(false);
    } else if (activeAssignmentType === "assignment") {
      if (!optionClicked) {
        setTimeSec(question[activeQuestionIndex]?.totalTime);
      }
      if (optionClicked) {
        setQuestion(
          question.map((item: any, index: number) => {
            if (index === activeQuestionIndex) {
              item.totalTime = timeSec;
              item.visited =
                item.descriptive && item.uploadedImages.length > 0 ? "" : "";
              item.open = true;
            }
            return item;
          })
        );
        // dispatch({ type: "UPDATE_QUESTION", payload: question })
      }
      setOptionClicked(false);
    }
  }, [activeQuestionIndex, optionClicked, isUpdateTime]);

  const dispatch = useDispatch();

  useEffect(() => {
    setMin(Math.floor(timeSec / 60) || 0);
    setSec(timeSec % 60 || 0);
  }, [timeSec]);

  return (
    <div className={styles.timerSectionContainer}>
      <div className={styles.countDownTimer}>
        <div>
          <img
            src={assignmentTimerSectionClock}
            alt="assignmentTimerSectionClock"
          />
        </div>
        <div className={styles.timeRemainingSection}>
          <div className={styles.timeLeft} id="timeLeft">
            {(Math.floor(Math.log10(min)) < 1
              ? "0" + String(min)
              : String(min)) || 0}
            :
            {Math.floor(Math.log10(sec)) < 1
              ? "0" + String(sec)
              : String(sec) || 0}
          </div>
          <div className={styles.timeRemainingText}>
            {" "}
            {activeAssignmentType === "test" ? "Time Left" : "Time Spent"}
          </div>
        </div>
      </div>
    </div>
  );
};
