import React from "react";
import styles from "./questionList.module.scss";

export const QuestionList = ({
  type,
  item,
  setQuestion,
  questions,
  setActiveQuestionIndex,
  activeAssignmentType,

}: any) => {
  const questionType = (type: any) => {
    switch (type) {
      case "Completed":
        return (
          <div className={`${styles.questionNumber} ${styles.completed}`}

            onClick={
              () => {
                if (activeAssignmentType !== "test") {
                  setActiveQuestionIndex(item - 1);
                  // setQuestion(
                  //   () => {
                  //     return questions.map((item1: any) => {
                  //       if (item1.id === item) {
                  //         item1.visited = "In Progress"
                  //       }
                  //       else if (item1.selectedAnswer !== "" || item1.uploadedImages.length > 0) {
                  //         item1.visited = "Completed"
                  //       }
                  //       else if (item1.visited === "In Progress") {
                  //         item1.visited = ""
                  //       }
                  //       return item1;
                  //     });
                  //   });
                }
              }
            }
          >
            {item}
          </div>
        );

      case "Wrong":
        return (
          <div className={`${styles.questionNumber} ${styles.wrong}`}
            onClick={
              () => {
                if (activeAssignmentType !== "test") {
                  setActiveQuestionIndex(item - 1);
                  // setQuestion(
                  //   () => {
                  //     return questions.map((item1: any) => {
                  //       if (item1.id === item) {
                  //         item1.visited = "In Progress"
                  //       }
                  //       else if (item1.selectedAnswer !== "" || item1.uploadedImages.length > 0) {
                  //         item1.visited = "Completed"
                  //       }
                  //       else if (item1.visited === "In Progress") {
                  //         item1.visited = ""
                  //       }
                  //       return item1;
                  //     });
                  //   });
                }
              }
            }
          >
            {item}
          </div>
        );
      case "Review":
        return (
          <div className={`${styles.questionNumber} ${styles.review}`}
            onClick={
              () => {
                if (activeAssignmentType !== "test") {
                  setActiveQuestionIndex(item - 1);
                  // setQuestion(
                  //   () => {
                  //     return questions.map((item1: any) => {
                  //       if (item1.id === item) {
                  //         item1.visited = "In Progress"
                  //       }
                  //       else if (item1.selectedAnswer !== "" || item1.uploadedImages.length > 0) {
                  //         item1.visited = "Completed"
                  //       }
                  //       else if (item1.visited === "In Progress") {
                  //         item1.visited = ""
                  //       }
                  //       return item1;
                  //     });
                  //   });
                }
              }
            }
          >
            {item}
          </div>
        );

      case "Skipped":
        return (
          <div className={`${styles.questionNumber} ${styles.skipped}`}
            onClick={
              () => {
                if (activeAssignmentType !== "test") {
                  setActiveQuestionIndex(item - 1);
                  // setQuestion(
                  //   () => {
                  //     return questions.map((item1: any) => {
                  //       if (item1.id === item) {
                  //         item1.visited = "In Progress"
                  //       }
                  //       else if (item1.selectedAnswer !== "" || item1.uploadedImages.length > 0) {
                  //         item1.visited = "Completed"
                  //       }
                  //       else if (item1.visited === "In Progress") {
                  //         item1.visited = ""
                  //       }
                  //       return item1;
                  //     });
                  //   });
                }
              }
            }
          >
            {item}
          </div>
        );

      case "In Progress":
        return (
          <div className={`${styles.questionNumber} ${styles.inProgress}`}
            onClick={
              () => {
                if (activeAssignmentType !== "test") {
                  setActiveQuestionIndex(item - 1);
                  // setQuestion(
                  //   () => {
                  //     return questions.map((item1: any) => {
                  //       if (item1.id === item) {
                  //         item1.visited = "In Progress"
                  //       }
                  //       else if (item1.selectedAnswer !== "" || item1.uploadedImages.length > 0) {
                  //         item1.visited = "Completed"
                  //       }
                  //       else if (item1.visited === "In Progress") {
                  //         item1.visited = ""
                  //       }
                  //       return item1;
                  //     });
                  //   });
                }
              }
            }
          >
            {item}
          </div>
        );

      default:
        return (
          <div className={`${styles.questionNumber} ${styles.pending}`}
            onClick={
              () => {
                if (activeAssignmentType !== "test") {
                  setActiveQuestionIndex(item - 1);
                  // setQuestion(
                  //   () => {
                  //     return questions.map((item1: any) => {
                  //       if (item1.id === item) {
                  //         item1.visited = "In Progress"
                  //       }
                  //       else if (item1.selectedAnswer !== "" || item1.uploadedImages.length > 0) {
                  //         item1.visited = "Completed"
                  //       }
                  //       else if (item1.visited === "In Progress") {
                  //         item1.visited = ""
                  //       }
                  //       return item1;
                  //     });
                  //   });
                }
              }
            }
          >
            {item}
          </div>
        );
    }
  };

  return questionType(type);
};

