import React, { useEffect, useState } from "react";
import styles from "./practiceTestChapterCard.module.scss";
import CompletedIcon from "../../assets/images/completedIcon.svg";
import PendingIcon from "../../assets/images/pendingWorkIcon.svg";
import Decors from "../../assets/images/homeWorkCardDecorations.svg";
import DecorsLight from "../../assets/images/homeworkCardDecorationLight.svg";
import MascotFlag from "../../assets/images/mascotFlag.svg";
import MascotReading from "../../assets/images/mascotReading.svg";
import ModalBg from "../../assets/images/aiSupportModalBg.svg";
import MascotRocket from "../../assets/images/mascotRocket.svg";
import { SolveAssignmentQuestionListViewAll } from "../../modules/learn/pages/solveAssignmentPage/solveAssignmentMetaData/solveAssignmentQuestionPopUp/questionListViewAll/solveAssignmentQuestionListViewAll";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Switch } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { routePaths } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import SolveExerciseButton from "../solveExerciseButton/solveExerciseButton";

interface IPracticeTestChapterCard {
  status: "pending" | "complete";
  data: any;
}

const getCardIcon = (status: any) => {
  if (status == "pending") return PendingIcon;
  if (status == "complete") return CompletedIcon;
};

const PracticeTestChapterCard = ({
  status,
  data,
}: IPracticeTestChapterCard) => {
  const navigate = useNavigate();
  const [showQuestionListPopUp, setShowQuestionListPopUp] = useState(false);
  const [checkAiSupportModal, setCheckAiSupportModal] = useState(false);
  const [checkAiSupport, setCheckAiSupport] = useState(false);
  const questionData = useSelector(
    (state: any) => state.solveAssignmentReducer
  );
  const [acticveQuestionIndex, setActicveQuestionIndex] = useState(0);
  useEffect(() => {
    setActicveQuestionIndex(questionData.activeQuestionIndex);
  }, [questionData.activeQuestionIndex]);
  const dispatch = useDispatch();
  return (
    <div className={`${styles.practiceTestChapterCardContainer} `}>
      <img src={status == "pending" ? Decors : DecorsLight} alt="" />
      <img src={status == "complete" ? MascotFlag : MascotReading} alt="" />
      <div className={styles.practiceTestChapterCard}>
        <div className={styles.practiceTestChapterCardIcon}>
          <img src={getCardIcon(status)} alt="" />
          <span>{status}</span>
        </div>
        <div className={styles.practiceTestChapterCardDetails}>
          <p>
            {data?.indexOfTopic} {data?.name}
          </p>
          <div className={styles.practiceTestChapterCardDetailItems}>
            <span>
              <QuestionIcon hasColoredBackground={false} />
              {data?.questionCount} Questions
            </span>
            {data?.publication && (
              <span>
                <BookIcon hasColoredBackground={false} />
                {data?.publication}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className={styles.practiceTestChapterCardAction}>
        <SolveExerciseButton status={status} level={data?.level} id={data?.id}>
          <button>
            {status == "pending" && "SOLVE NOW"}
            {status == "complete" && "RETAKE"}
          </button>
        </SolveExerciseButton>
      </div>
    </div>
  );
};

export default PracticeTestChapterCard;

const QuestionIcon = ({ hasColoredBackground }: any) => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
          stroke={hasColoredBackground ? "#fff" : "#6862E0"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 14C9.51777 14 9.9375 13.5803 9.9375 13.0625C9.9375 12.5447 9.51777 12.125 9 12.125C8.48223 12.125 8.0625 12.5447 8.0625 13.0625C8.0625 13.5803 8.48223 14 9 14Z"
          fill="white"
        />
        <path
          d="M9 10.25V9.625C9.43265 9.625 9.85558 9.49671 10.2153 9.25634C10.575 9.01597 10.8554 8.67433 11.021 8.27462C11.1866 7.87491 11.2299 7.43507 11.1455 7.01074C11.0611 6.58641 10.8527 6.19663 10.5468 5.89071C10.2409 5.58478 9.85109 5.37644 9.42676 5.29203C9.00243 5.20763 8.56259 5.25095 8.16288 5.41651C7.76317 5.58208 7.42153 5.86246 7.18116 6.22219C6.94079 6.58192 6.8125 7.00485 6.8125 7.4375"
          stroke={hasColoredBackground ? "#fff" : "#6862E0"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

const BookIcon = ({ hasColoredBackground }: any) => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.1875 6.75C6.1875 6.60082 6.24676 6.45774 6.35225 6.35225C6.45774 6.24676 6.60082 6.1875 6.75 6.1875H11.25C11.3992 6.1875 11.5423 6.24676 11.6477 6.35225C11.7532 6.45774 11.8125 6.60082 11.8125 6.75C11.8125 6.89918 11.7532 7.04226 11.6477 7.14775C11.5423 7.25324 11.3992 7.3125 11.25 7.3125H6.75C6.60082 7.3125 6.45774 7.25324 6.35225 7.14775C6.24676 7.04226 6.1875 6.89918 6.1875 6.75ZM6.75 9.5625H11.25C11.3992 9.5625 11.5423 9.50324 11.6477 9.39775C11.7532 9.29226 11.8125 9.14918 11.8125 9C11.8125 8.85082 11.7532 8.70774 11.6477 8.60225C11.5423 8.49676 11.3992 8.4375 11.25 8.4375H6.75C6.60082 8.4375 6.45774 8.49676 6.35225 8.60225C6.24676 8.70774 6.1875 8.85082 6.1875 9C6.1875 9.14918 6.24676 9.29226 6.35225 9.39775C6.45774 9.50324 6.60082 9.5625 6.75 9.5625ZM9 10.6875H6.75C6.60082 10.6875 6.45774 10.7468 6.35225 10.8523C6.24676 10.9577 6.1875 11.1008 6.1875 11.25C6.1875 11.3992 6.24676 11.5423 6.35225 11.6477C6.45774 11.7532 6.60082 11.8125 6.75 11.8125H9C9.14918 11.8125 9.29226 11.7532 9.39775 11.6477C9.50324 11.5423 9.5625 11.3992 9.5625 11.25C9.5625 11.1008 9.50324 10.9577 9.39775 10.8523C9.29226 10.7468 9.14918 10.6875 9 10.6875ZM15.75 3.375V11.0173C15.7505 11.1651 15.7216 11.3115 15.665 11.448C15.6083 11.5845 15.5252 11.7084 15.4202 11.8125L11.8125 15.4202C11.7084 15.5252 11.5845 15.6083 11.448 15.665C11.3115 15.7216 11.1651 15.7505 11.0173 15.75H3.375C3.07663 15.75 2.79048 15.6315 2.5795 15.4205C2.36853 15.2095 2.25 14.9234 2.25 14.625V3.375C2.25 3.07663 2.36853 2.79048 2.5795 2.5795C2.79048 2.36853 3.07663 2.25 3.375 2.25H14.625C14.9234 2.25 15.2095 2.36853 15.4205 2.5795C15.6315 2.79048 15.75 3.07663 15.75 3.375ZM3.375 14.625H10.6875V11.25C10.6875 11.1008 10.7468 10.9577 10.8523 10.8523C10.9577 10.7468 11.1008 10.6875 11.25 10.6875H14.625V3.375H3.375V14.625ZM11.8125 11.8125V13.8305L13.8298 11.8125H11.8125Z"
          fill="#6862E0"
        />
      </svg>
    </>
  );
};
