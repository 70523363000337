import classes from "./sidebar.module.scss";

const SideBar = (props: { content: any; width: string }) => {
    return (
        <div className={classes.sidebarOverlay}>
            <div
                className={classes.sidebarWrapper}
                style={{ width: props.width }}
            >
                <div className={classes.sidebarMain}>
                    <div className={classes.sidebarContent}>
                        {props.content}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideBar;
