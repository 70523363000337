import rectangleQuestion from "../../assets/images/rectangleQuestion.svg";
const initialState = {
  assigmentLevel: 1,
  activeQuestionIndex: 0,
  activeScreen:"",
  question: [
    {
      id: 1,
      question: "What is Your Name?",
      Answers: [
        {
          id: 1,
          ans: "A",
          ansimage: rectangleQuestion,
        },
        {
          id: 2,
          ans: "B",
          ansimage: rectangleQuestion,
        },
        {
          id: 3,
          ans: "C",
          ansimage: rectangleQuestion,
        },
        {
          id: 4,
          ans: "D",
          ansimage: rectangleQuestion,
        },
      ],
      isCorrect: true,
      selectedAnswer: "",
      correctAnswer: "",
      visited: "",
      score: "02",
      img: rectangleQuestion,
      totalTime: 0,
      open: false,
      subTopics: "Kuldeep Soni Assignment",
      topicNumber: "",
      Questionid: 1,
      code: 1,
      descriptive: false,
      tag: "mc10011q0101",
      student_comment: "",
      mentor_comments: "",
      uploadedImages: [],
      topicId: "805",
      isAnswered: false,
    },
    {
      id: 2,
      question: "What is Your ?",
      Answers: [
        {
          id: 1,
          ans: "E",
          ansimage: "",
        },
        {
          id: 2,
          ans: "F",
          ansimage: "",
        },
        {
          id: 3,
          ans: "G",
          ansimage: "",
        },
        {
          id: 4,
          ans: "H",
          ansimage: "",
        },
      ],
      isCorrect: false,
      selectedAnswer: "",
      correctAnswer: "",
      visited: "",
      score: "02",
      img: "",
      totalTime: 0,
      open: false,
      subTopics: "",
      topicNumber: "",
      Questionid: 1,
      code: 1,
      descriptive: false,
      tag: "mc10011q0102",
      mentor_comments: "",
      mentor_image_uploads: [],
      uploadedImages: [],

      topicId: "805",
      student_comment: "",
      isAnswered: false,
    },
    {
      id: 3,
      question: "What is ?",
      Answers: [],
      solutions: [
        {
          id: 1,
          ans_records: [],
        },
      ],
      isCorrect: false,
      selectedAnswer: "",
      correctAnswer: "",
      visited: "",
      score: "02",
      img: "",
      totalTime: 0,
      open: false,
      subTopics: "",
      topicNumber: "",
      Questionid: 1,
      code: 1,
      descriptive: true,
      student_comment: "",
      tag: "mc10011q0103",
      uploadedImages: [],
      mentor_comments: "",
      topicId: "805",
      isAnswered: false,
    },
    {
      id: 4,
      question: "What nn  is ?",
      Answers: [],
      isCorrect: true,
      selectedAnswer: "",
      correctAnswer: "",
      visited: "Complete",
      score: "02",
      img: "",
      totalTime: 0,
      open: false,
      subTopics: "",
      topicNumber: "",
      Questionid: 1,
      code: 1,
      descriptive: true,
      tag: "mc10011q0102",
      uploadedImages: [],
      topicId: "805",
      student_comment: "kjnjkjygbjuhmgu",
      isAnswered: false,
      mentor_comments: "",
      solutions: [
        {
          id: 1,
          ans_records: [],
        },
      ],
    },
    {
      id: 5,
      question: "What ?",
      Answers: [
        {
          id: 1,
          ans: "M",
          ansimage: "",
        },
        {
          id: 2,
          ans: "N",
          ansimage: "",
        },
        {
          id: 3,
          ans: "O",
          ansimage: "",
        },
        {
          id: 4,
          ans: "P",
          ansimage: "",
        },
      ],
      isCorrect: true,
      selectedAnswer: "",
      mentor_comments: "",
      correctAnswer: "",
      visited: "",
      score: "02",
      img: "",
      totalTime: 0,
      open: false,
      subTopics: "",
      topicNumber: "",
      Questionid: 1,
      code: 1,
      student_comment: "",
      descriptive: false,
      tag: "mc10011q0102",
      uploadedImages: [],
      topicId: "805",
      isAnswered: false,
    },
    // {
    //     id: 1,
    //     question: "What is Your Name?",
    //     Answers: [
    //       {
    //         id: 1,
    //         ans: "A",
    //         ansimage: "",
    //       },
    //       {
    //         id: 2,
    //         ans: "B",
    //         ansimage: "",
    //       },
    //       {
    //         id: 3,
    //         ans: "C",
    //         ansimage: "",
    //       },
    //       {
    //         id: 4,
    //         ans: "D",
    //         ansimage: "",
    //       },
    //     ],
    //     solutions: [
    //       {
    //         id: 1,
    //         ans_records: [starIcon, starIcon, starIcon],
    //       },
    //     ],
    //     isCorrect: true,
    //     selectedAnswer: "",
    //     correctAnswer: "",
    //     visited: "In Progress",
    //     score: "02",
    //     img: "",
    //     totalTime: 25,
    //     open: false,
    //     subTopics: "Kuldee",
    //     topicNumber: "",
    //     Questionid: 1,
    //     code: 2,
    //     descriptive: false,
    //     tag: "",
    //     uploadedImages: [],
    //     topicId: "805",
    //     isAnswered: false,
    //   },
  ],
  isRetake:false
};

const solveAssignmentReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case "UPDATE":
      return [...action.payload];
    case "UPDATE_QUESTION_INDEX":
      return { ...state, activeQuestionIndex: action.payload };
      case "ENABLE_RETAKE":
        return { ...state, isRetake: action.payload };
    case "UPDATE_IS_SELECTION_OR_RESULT":
      return { ...state, activeScreen: action.payload };
    case "UPDATE_QUESTION":
      return { ...state, question: action.payload };
    case "UPDATE_ASSIGNMENT_LEVEL":
      return { ...state, assigmentLevel: action.payload };
    case "UPDATE_QUESTION_INDEX_MENTOR":
      return { ...state, activeQuestionIndexMentor: action.payload };
    default:
      return state;
  }
};
export default solveAssignmentReducer;
