import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import classes from "./solveAssignmentQuestionListViewAll.module.scss";
import correctIcon from "../../../../../../../assets/images/correctSubmit.svg";
import qATimerIcon from "../../../../../../../assets/images/qATimerIcon.svg";
import qAStarIcon from "../../../../../../../assets/images/qAStarIcon.svg";
import wrongIcon from "../../../../../../../assets/images/wrongIcon.svg";
import pandingIcon from "../../../../../../../assets/images/pendingIcon.svg";
import closeIcon from "../../../../../../../assets/images/closeIcon.svg";
import questionIcon from "../../../../../../../assets/images/questionIcon.svg";
import submittedIcon from "../../../../../../../assets/images/submittedIcon.svg";
import Progressbar from "../../../../../../../common/progressbar/progressbar";
import disabled from "../../../../../../../assets/images/notSelectable.svg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routePaths } from "../../../../../../../constants/routes";
import { HTTPMethods } from "../../../../../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import { QuestionAndOptionsElement } from "../../../../../../../common/questionsAndOptions/questionAndOptions";
import { formatDate } from "../../../../../../../utils/dateFormatter";

interface IPopUp {
  title?: string;
  content?: object[];
  showTestOverlay?: boolean;
  closePopUp?: any;
  activeQuestionIndex?: number;
  setActiveQuestionIndex?: any;
  assignmentLevel?: any;
  topicIdProp?: any;
  fromAssignmentPage?: boolean;
  isFromTestPage?: boolean;
  isFromHomeworkPage?: boolean;
  fromExercisePage?: boolean;
  aiSupport?: boolean;
  isRetakeAllowed?: boolean;
  fromLearnPage?: boolean;
  fromViewAll?: boolean;
}
export const SolveAssignmentQuestionListViewAll = ({
  title,
  content,
  closePopUp,
  setActiveQuestionIndex,
  showTestOverlay = true,
  assignmentLevel = 1,
  topicIdProp,
  fromAssignmentPage = false,
  isFromTestPage = false,
  isFromHomeworkPage = false,
  fromExercisePage = false,
  aiSupport = false,
  isRetakeAllowed = false,
  fromLearnPage = false,
  fromViewAll = false,
}: IPopUp) => {
  const dispatch = useDispatch();
  const calculateTime = (time: number) => {
    const min = Math.floor(time / 60);
    const sec = time % 60;
    return `${min} min ${sec} sec`;
  };
  const { topicId } = useParams();
  const fetchMCQTest = async () => {
    const payload = {
      url:
        assignmentLevel < 2
          ? `assignment_question_list/?topic_id=${topicIdProp || topicId}`
          : `assignment_question_list/?chapter_id=${topicIdProp}&level=${assignmentLevel}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    if (isFromTestPage) {
      payload.url = `test_all_questions/?testpaper_id=${topicIdProp}`;
    }

    if (isFromHomeworkPage) {
      payload.url = `GetAssignment/0/0/?key=homework&chapter_id=186&date=2023-09-10&&qid=null`;
      payload.url = `homework_all_questions/?homework_id=${topicIdProp}`;
    }
    try {
      const res: any = await makeAxiosRequest(payload);
      const newData = {
        id: 1,
        topic_id: res.data.data.topic_id,
        assignment_name: res.data.data.assignment_name,
        last_attempt: res.data.data.last_attempt,
        student_progress: res.data.data.student_progress,
        total_questions: res.data.data.total_questions,
        retake_option: res.data.data.retake_option,
        question: res?.data?.data?.questions.map((item: any) => {
          return {
            question_id: item.question_id,
            question_text: item.question_text,
            question_type: item.question_type || "MCQ",
            time_taken: item.timetaken,
            question_status:
              item.status === "wrong"
                ? "Wrong"
                : item.status === "correct"
                ? "Completed"
                : item.status === "in_review"
                ? "InReview"
                : "",
            question_score: item.marks_alloted,
            question_scored: item.marks_scored,
            selected_answer_id: item.answer_id,
            question_img: item.question_img,
            uploaded_images_by_student: item.ans_img,
            student_comment: item.student_comment,
            mentor_comments: item.mentor_comments,
            mentor_image: item.mentor_image,
            totalTime: item.timetaken,
          };
        }),
      };

      setQuestion(newData);
      dispatch({ type: "UPDATE_QUESTION", payload: newData });
    } catch (error) {
      console.log(error);
    }
  };
  const [isAPi, setIsApi] = React.useState(true);

  useEffect(() => {
    isAPi && fetchMCQTest();
    isAPi && setIsApi(false);
  }, []);
  const questionData = useSelector(
    (state: any) => state.solveAssignmentReducer
  );
  const [question, setQuestion] = React.useState<any>({});
  const [activeQuestionIndexNew, setActiveQuestionIndexNew] =
    React.useState<any>(null);
  useEffect(() => {
    if (activeQuestionIndexNew != null) {
      dispatch({
        type: "UPDATE_QUESTION_INDEX",
        payload: activeQuestionIndexNew,
      });
      dispatch({ type: "UPDATE_ASSIGNMENT_LEVEL", payload: assignmentLevel });
      let path: any = fromAssignmentPage
        ? assignmentLevel > 1
          ? routePaths.solveAssignmentLevel
          : routePaths.solveAssignmentFromAssignment
        : assignmentLevel > 1
        ? routePaths.solveAssignmentLevel
        : routePaths.solveAssignment;
      if (assignmentLevel < 1) {
        if (fromExercisePage) {
          path = routePaths.solveExercise;
          if (fromLearnPage) {
            path = routePaths.solveExerciseFromLearn;
          }
          if (aiSupport) {
            path = path + `/?aisupport=${aiSupport}`;
          }
        }
        if (isFromTestPage) {
          dispatch({
            type: "UPDATE_QUESTION_INDEX",
            payload: 0,
          });
          path = routePaths.solveTest;
        }

        navigate(
          path
            .replace(":topicId", `${topicIdProp || topicId}`)
            .replace(":level", `${assignmentLevel}`)
        );
      } else {
        let path: any = routePaths.solveExerciseLevel;
        if (aiSupport) {
          path = path + `/?aisupport=${aiSupport}`;
        }
        dispatch({
          type: "UPDATE_ASSIGNMENT_LEVEL",
          payload: assignmentLevel,
        });
        if (isFromHomeworkPage) {
          navigate(
            routePaths.solveHomework.replace(":topicId", `${topicIdProp}`)
          );
        } else if (isFromTestPage) {
            navigate(
              routePaths.solveTest.replace(":topicId", `${topicIdProp}`)
            );} else{
          navigate(
            path
              .replace(":topicId", `${topicIdProp}`)
              .replace(":level", `${assignmentLevel}`)
          );
        }
      }

      closePopUp();
    }
  }, [activeQuestionIndexNew]);
  const navigate = useNavigate();
  const [indexActive, setIndexActive] = useState();
  useEffect(() => {
    setIndexActive(questionData.activeQuestionIndex);
  }, [questionData]);
  const location = useLocation();
  const handleRetake = () => {
    dispatch({ type: "ENABLE_RETAKE", payload: true });
    dispatch({ type: "UPDATE_QUESTION_INDEX", payload: 0 });
    closePopUp();
    let path;
    if (fromExercisePage) {
      path = routePaths.solveExercise;
      if (fromLearnPage) {
        path = routePaths.solveExerciseFromLearn;
      }
      if (aiSupport) {
        path = path + `/?aisupport=${aiSupport}`;
      }
    }

    location.state = {
      retake: true,
    };
    if (path) {
      navigate(path.replace(":topicId", `${topicIdProp || topicId}`), {
        state: { timeStamp: Date.now() },
      });
    }
  };

  return (
    <>
      <Dialog
        open={true}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className={classes.popUpContainer}>
          <div className={classes.popUpHeaderWrapper}>
            <div className={classes.popUpHeaderText}>{title} </div>
            <div className={classes.popUpHeaderClose} onClick={closePopUp}>
              <img src={closeIcon} alt="close" />
            </div>
          </div>
          <div className={classes.containerBox}>
            <div className={classes.boxContainer}>
              <div className={classes.containerQuestion}>
                <img src={questionIcon} alt="question" />
                <div className={classes.content}>
                  <div className={classes.texthead}>
                    {question?.total_questions || 0}
                  </div>
                  <div className={classes.textContent}> Total Questions</div>
                </div>
              </div>
              <div className={classes.containerQuestion}>
                <img src={submittedIcon} alt="question" />
                <div className={classes.content}>
                  <div className={classes.texthead}>
                    {formatDate(question?.last_attempt || (new Date() as any))}
                  </div>
                  <div className={classes.textContent}>Last Attempted</div>
                </div>
              </div>
            </div>
            <div className={classes.progressContainer}>
              <div>
                <div className={classes.texthead}> Your Progress</div>
                <div className={classes.progressbar}>
                  <Progressbar
                    progress={question.student_progress || 0}
                    isRocketVisible={false}
                    width={"215px"}
                  />
                  <div> {question.student_progress || 0}%</div>
                </div>
              </div>
              {question.retake_option && (
                <button onClick={handleRetake}>Retake</button>
              )}
            </div>
          </div>
          <div className={classes.answerBelowText}>
            {question.question?.length > 0 && (
              <p>Answer below questions correctly to complete the assignment</p>
            )}
          </div>
          <div className={classes.popUpCardContainer}>
            {question.question?.map((item: any, index: any) => (
              <div
                onClick={() => {
                  if (showTestOverlay) {
                    setActiveQuestionIndexNew(index);
                    dispatch({ type: "UPDATE_QUESTION_INDEX", payload: index });
                  } else {
                    setActiveQuestionIndex(index);
                    dispatch({ type: "UPDATE_QUESTION_INDEX", payload: index });
                    if (assignmentLevel < 1) {
                      dispatch({
                        type: "UPDATE_ASSIGNMENT_LEVEL",
                        payload: assignmentLevel,
                      });
                      let path: any = routePaths.solveAssignmentLevel;

                      if (fromExercisePage) {
                        path = routePaths.solveExercise;
                        if (fromLearnPage) {
                          path = routePaths.solveExerciseFromLearn;
                        }
                        if (aiSupport) {
                          path = path + `/?aisupport=${aiSupport}`;
                        }
                      }
                      if (isFromTestPage) {
                        dispatch({
                          type: "UPDATE_QUESTION_INDEX",
                          payload: 0,
                        });
                        path = routePaths.solveTest;
                      }
                      navigate(
                        path
                          .replace(":topicId", `${topicIdProp}`)
                          .replace(":level", `${assignmentLevel}`)
                      );
                    } else {
                      dispatch({
                        type: "UPDATE_ASSIGNMENT_LEVEL",
                        payload: assignmentLevel,
                      });
                      let path: any = routePaths.solveExerciseLevel;
                      if (aiSupport) {
                        path = path + `/?aisupport=${aiSupport}`;
                      }
                      if (isFromHomeworkPage) {
                        navigate(
                          routePaths.solveHomework.replace(
                            ":topicId",
                            `${topicIdProp}`
                          )
                        );
                      } else {
                        navigate(
                          path
                            .replace(":topicId", `${topicIdProp}`)
                            .replace(":level", `${assignmentLevel}`)
                        );
                      }
                    }
                    closePopUp();
                  }
                }}
                className={`${classes.popUpCardItem} ${
                  index === indexActive && !showTestOverlay
                    ? classes.popUpCardItemActive
                    : ""
                }`}
                key={`${item.question}_${index}`}
                //style={{ cursor: `${isRetakeAllowed ? "auto" : "pointer"}` }}
              >
                <div className={classes.questionAndImg}>
                  <div
                    className={`${classes.questionImg} ${
                      item.question_status == "" && classes.disabled
                    }`}
                  >
                    <img
                      src={
                        item.question_status === "Completed"
                          ? correctIcon
                          : item.question_status === "Wrong"
                          ? wrongIcon
                          : item.question_status === "InReview"
                          ? pandingIcon
                          : disabled
                      }
                      alt="status icon"
                    />
                  </div>

                  <div className={classes.questionNumAndText}>
                    <div className={classes.questionNum}>{`Q${
                      index + 1
                    }.`}</div>
                    <div className={classes.questionText}>
                      <QuestionAndOptionsElement
                        metaData={item.question_text}
                      />
                    </div>
                  </div>
                </div>

                <div className={classes.questionTimerAndRating}>
                  <div className={classes.TimerImgAndTime}>
                    <div className={classes.TimerImg}>
                      <img src={qATimerIcon} alt="status icon" />
                    </div>
                    <div className={classes.TimerTime}>
                      {item.question_status == ""
                        ? "-"
                        : calculateTime(item.time_taken || 0)}
                    </div>
                  </div>
                  <div className={classes.questionRatingAndText}>
                    <div className={classes.questionRating}>
                      <img src={qAStarIcon} alt="status icon" />
                    </div>
                    <div className={classes.questionRatingText}>
                      {item.question_status == "InReview" ||
                      item.question_status == ""
                        ? "-"
                        : `${item.question_scored}/${item.question_score}`}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    </>
  );
};
