enum LevelPercentages {
  L0 = "0%",
  L1 = "20%",
  L2 = "40%",
  L3 = "60%",
  L4 = "80%",
  L5 = "100%",
}

export const levelPercentage = (level: string) => {
  switch (level) {
    case "L1":
      return LevelPercentages.L1;
    case "L2":
      return LevelPercentages.L2;
    case "L3":
      return LevelPercentages.L3;
    case "L4":
      return LevelPercentages.L4;
    case "L5":
      return LevelPercentages.L5;
    default:
      return LevelPercentages.L0;
  }
};

enum masteryTypes {
  L0 = "Weak",
  L1 = "Weak",
  L2 = "Weak",
  L3 = "Weak",
  L4 = "Strong",
  L5 = "Strong",
}
export const getMasterType = (level: string) => {
  switch (level) {
    case "L1":
      return masteryTypes.L1;
    case "L2":
      return masteryTypes.L2;
    case "L3":
      return masteryTypes.L3;
    case "L4":
      return masteryTypes.L4;
    case "L5":
      return masteryTypes.L5;
    default:
      return masteryTypes.L0;
  }
};

export const getLevel = (level: number) => {
  switch (level) {
    case 20:
      return "L1";
    case 40:
      return "L2";
    case 60:
      return "L3";
    case 80:
      return "L4";
    case 100:
      return "L5";
    default:
      return "L0";
  }
};

export const getLevelText = (level: number) => {
  switch (level) {
    case 20:
      return "Level 1";
    case 40:
      return "Level 2";
    case 60:
      return "Level 3";
    case 80:
      return "Level 4";
    case 100:
      return "Level 5";
    default:
      return "Level 0";
  }
};

export const getLevelFromNumber = (number: number) => {
  switch (number) {
    case 1:
      return "L1";
    case 2:
      return "L2";
    case 3:
      return "L3";
    case 4:
      return "L4";
    case 5:
      return "L5";
    default:
      return "L0";
  }
};
