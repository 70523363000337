import React, { useContext, useEffect, useState } from "react";
import styles from "./studentActivity.module.scss";
import sideArrow from "../../../../../assets/images/atvSideArrow.svg";
import studentPic from "../../../../../assets/images/studentAtvProfile.svg";
import { constants } from "../../../../../constants/constants";
import { getUsernameInitials } from "../../../../../utils/usernameInitials";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { routePaths } from "../../../../../constants/routes";
import StatusContext from "../../../../../context/loaderContext";
import ApiEdpoints, {
  HTTPMethods,
} from "../../../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import { updatedStudentsDetailsThatNeedsAttention } from "../../../../../utils/teacherPersonalMassagedData";

interface IRowData {
  rollNo: string;
  level: string;
  studentName: string;
  activity: string;
  photo: any;
  userInitials?: string;
}

const StudentActivity = (props: any) => {
  const isTabOpen = useSelector((state: any) => state.tabReducer.tabOpen);
  const [studentRowData, setStudentRowData] = useState<IRowData[]>(
    constants.ROWDATA
  );
  const loaderStatus: any = useContext(StatusContext);
  const [studentToBeFocusedData, setStudentToBeFocusedData] = useState([]);
  const [classId, setClassId] = useState<number>();
  const navigate = useNavigate();
  const params = useParams();

  const updateUserInitialsForPhoto = (studentRowData: any) => {
    setStudentRowData(
      studentRowData.map((rowData: IRowData) => {
        return {
          ...rowData,
          ["userInitials"]: getUsernameInitials(rowData.studentName),
        };
      })
    );
  };

  const viewAllStudents = () => {
    props.setSelectedIndexData(1);
  };

  const viewStudentDetails = (studentData: any) => {
    navigate(routePaths?.studentDetails.replace(":studentId", studentData.id).replace(':classId', classId ? classId.toString() : ''));
  };

  const fetchStudentsNeedtoBeFocusedData = async (classId: any) => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: ApiEdpoints.STUDENT_LIST_BY_ID + `?class_id=${classId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      loaderStatus.setState({ show: false });
      setStudentToBeFocusedData(res?.data?.data);
      return res;
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    classId && fetchStudentsNeedtoBeFocusedData(classId);
  }, [classId]);

  useEffect(() => {
    setClassId(Number(params?.classId));
  }, []);
  useEffect(() => {
    let updatedStudentDetails = updatedStudentsDetailsThatNeedsAttention(
      studentToBeFocusedData
    );

    updateUserInitialsForPhoto(updatedStudentDetails);
  }, [studentToBeFocusedData]);

  return (
    <div className={styles.atvMainContainer}>
      <div className={styles.atvMainHeadingContainer}>
        <div className={styles.atvMainHeading}></div>
        <div className={styles.studentText}>
          {constants.STUDENTS_YOU_NEED_TO_FOCUS_ON}
        </div>
        <div className={styles.viewAllContainer} onClick={viewAllStudents}>
          <div className={styles.viewAll}>View All</div>
          <div>
            <img src={sideArrow} alt="side arrow" />
          </div>
        </div>
      </div>
      <div
        className={
          isTabOpen
            ? styles.atvDataMainContainer
            : styles.atvDataMainContainerSideNav
        }
      >
        <div className={styles.atvTableHeadRow}>
          {constants.ROWHEADS.map((each) => (
            <div className={styles.atvCellHead}>{each}</div>
          ))}
        </div>
        <div>
          {studentRowData.map((each: IRowData) => (
            <>
              <div className={styles.atvHorizontalLine}></div>
              <div className={styles.atvTableCellRowContainer}>
                <div className={`${styles.atvTableCellRow} ${styles.atvCell1}`}>
                  {each.rollNo}
                </div>
                <div className={`${styles.atvTableCellRow} ${styles.atvCell2}`}>
                  {each.level}
                </div>
                <div className={`${styles.atvTableCellRow} ${styles.atvCell3}`}>
                  {each.photo ? (
                    <>
                      {/* REPLACE THE STUDENT PHOTO WHEN IT WILL COME FROM API */}
                      <div className={styles.userIconContainer}>
                        <img src={studentPic} alt="" className={styles.image} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.userIconContainer}>
                        <div className={styles.userInitials}>
                          {each?.userInitials}
                        </div>
                      </div>
                    </>
                  )}
                  <div className={styles.studentName}>{each.studentName}</div>
                </div>
                <div className={`${styles.atvTableCellRow} ${styles.atvCell4}`}>
                  {each.activity}
                </div>
                <div
                  className={styles.atvViewDetails}
                  onClick={() => { viewStudentDetails(each) }}
                >
                  View Details
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StudentActivity;
