import React, { useState, useEffect, useContext, useRef } from "react";
import { makeAxiosRequest } from "aie_common";
import styles from "../../components/myStatsPatternAndQuestion/myStatsPatternAndQuestion.module.scss";
import Breadcrumb from "../../../../common/breadcrumb/breadcrumb";
import MyStatsQuestionCard from "../myStatsQuestionCard/myStatsQuestionCard";
import { CustomPagination } from "../../../../common/pagination/customPagination";
import AnalyticsChart from "../../../learn/components/analyticsChart/analyticsChart";
import classes from "./myStatsChapterAndQuestion.module.scss";
import { constants } from "../../../../constants/constants";
import FilterBar from "../../../../common/filterBar/filterBar";
import StatusContext from "../../../../context/loaderContext";
import ApiEdpoints, {
  HTTPMethods,
  queryConstants,
} from "../../../../constants/api_endpoints";
import ChapterProgressCard from "../../../../common/chapterProgressCard/chapterProgressCard";
import { useParams } from "react-router-dom";
import { useAnalyticsGraphData } from "../../../../utils/analyticsGraphApi";
import useSorting from "../../../../utils/useSorting";
import { calculateTimeTaken } from "../../../../utils/convertTime";

const MyStatsChapterAndQuestion = () => {
  const [initialSelectedPatternsValues, setUpdatedSelectedPatternValues] =
    useState<{ index: number; value: string; id: any }[]>([]);
  const [chapterQuestionsData, setChapterQuestionsData] = useState<object[]>(
    []
  );
  const { graphData, fetchAnalyticsChartData } = useAnalyticsGraphData();
  const [patternOptions, setPatternOptions] = useState<any>();
  const loaderStatus: any = useContext(StatusContext);
  const [selectedStatus, setSelectedStatus] = useState<any>([]);
  const [dropdownArray, setDropdownArray] = React.useState([]);
  const [chapterDropDownValueWithId, setChapterDropDownValueWithId] =
    React.useState<any>({});
  const [levelDropDownValueWithId, setLevelDropDownValueWithId] =
    React.useState<any>({});
  const [fetchedDropdownTopics, setFetchedDropdownTopics] = useState<any>([]);
  const [chapterListDetails, setChapterListDetails] = useState<any>([]);

  const { statsId } = useParams();
  const [selectedChapterCard, setSelectedChapterCard] = useState<any>();
  const [chapterId, setChapterId] = useState<any>();
  const [statsBreadcrumbData, setStatsBreadcrumbData] = useState(
    constants.CHAPTER_STATS_BREADCRUMB_DATA
  );
  const fetchCurrentChapterDetails = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.CHAPTERS_WISE_DATA}/?chapter_id=${statsId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const { data }: any = await makeAxiosRequest(payload);
      // spelling mistake of sucess from backend
      if (data?.status === "sucess") {
        let obj = data?.data[0];
        let updatedObj = {
          aiCount: obj.Ai_Count,
          attemptedQuestions: obj.attempted_questions,
          competenceLevel: {
            masteryStage:
              obj.competence_level.mastery_stage?.replace(/\D/g, "") || 0,
          },
          totalQuestions: obj.total_questions,
          name: obj.name,
          id: obj.id,
        };
        setSelectedChapterCard(updatedObj);
        setChapterId(data?.data[0]?.id);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const fetchChapterQuestionList = async (id: any) => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.CHAPTERS_WISE_DATA_BY_ID}/${id}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const { data }: any = await makeAxiosRequest(payload);
      if (data?.status === "success") {
        setChapterListDetails(data?.question_list);
        if (data?.question_list?.length > 0) {
          selectedChapterCard &&
            setSelectedChapterCard({
              ...selectedChapterCard,
              total_questions_list: data?.question_list,
            });
        }
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const getChapterTopics = async (chapterId: any) => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.CHAPTER_TOPICS_OVERVIEW}/?${queryConstants.CHAPTER_ID}=${chapterId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const topicsResponse: any = await makeAxiosRequest(payload);
      if (topicsResponse && topicsResponse?.status === 200) {
        let topics_data = topicsResponse?.data.topics_data;
        setFetchedDropdownTopics(topics_data);
        const topicsData = topics_data
          ? topics_data?.reduce(
            (arr: [{}], obj: any) => {
              arr.push({ name: obj.name, id: obj.id });
              return arr;
            },
            [{ name: "All Topics", id: 0 }]
          )
          : [];

        const levelsData = [
          { name: "All Levels", id: 0 },
          { name: "Easy", id: 1 },
          { name: "Medium", id: 2 },
          { name: "Hard", id: 3 },
        ];
        const patterns = [
          { data: topicsData, defaultLabel: "All Topics" },
          { data: levelsData, defaultLabel: "All Levels" },
        ];
        setPatternOptions(patterns);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    if (statsId) {
      fetchCurrentChapterDetails();
    }
  }, [statsId]);

  useEffect(() => {
    if (chapterId) {
      fetchChapterQuestionList(chapterId);
      fetchAnalyticsChartData(chapterId);
      getChapterTopics(chapterId);
    }
  }, [chapterId]);

  const setUpdatesSelectedPatterns = (updatedArrValues: any) => {
    setUpdatedSelectedPatternValues(updatedArrValues);
  };

  useEffect(() => {
    const patternPropsData = selectedChapterCard?.total_questions_list
      ? selectedChapterCard?.total_questions_list?.map((x: any, i: number) => ({
        index: i,
        value: "",
        id: "",
      }))
      : [];
    setUpdatedSelectedPatternValues(patternPropsData);
    setChapterQuestionsData(
      selectedChapterCard?.total_questions_list
        ? [...selectedChapterCard?.total_questions_list]
        : []
    );
  }, [statsId, chapterListDetails, selectedChapterCard]);

  const dataMassaging = (data: any) => {
    const dataMassaged = data?.map((x: any, i: number) => {
      return {
        index: i,
        aiHelpCount:
          x?.AI_help_used_count.length > 0 ? x?.AI_help_used_count : 0,
        AttemptedCount: x?.Attempted_count,
        Capability: x?.Capability,
        id: x?.id,
        level: x?.level,
        patternsIncluded:
          x?.pattens_included.length > 0 ? x?.pattens_included : [],
        question: x?.question,
        questionImg: x?.question_img,
        solvedCount: x?.solved_count,
        subtopicId: x?.subtopic_id,
        time_taken: calculateTimeTaken(x?.time_taken),
        timeGiven: calculateTimeTaken(x?.timegiven),
        topicId: x?.topic_id,
        isDiagram: x?.isDiagram ? x?.isDiagram : false,
        topicNumber: x?.topicNumber ? x?.topicNumber : false,
        questionId: x?.subtopic_id,
      };
    });
    return dataMassaged;
  };

  const {
    dropdownSelectedValueWithId,
    setDropdownSelectedValueWithId,
    filteredTopics,
    page,
    paginationStart,
    paginationEnd,
    range,
    handlePaginationChange,
    detailedViewRef,
  }: any = useSorting(
    chapterListDetails,
    selectedStatus,
    "",
    "", // search filter key
    dataMassaging,
    8, // range,
    dropdownArray
  );

  useEffect(() => {
    if (dropdownSelectedValueWithId) {
      if (dropdownSelectedValueWithId?.defaultLabel === "All Topics") {
        setChapterDropDownValueWithId(dropdownSelectedValueWithId);
      } else if (dropdownSelectedValueWithId?.defaultLabel === "All Levels") {
        setLevelDropDownValueWithId(dropdownSelectedValueWithId);
      }
    }
  }, [dropdownSelectedValueWithId]);
  useEffect(() => {
    let arr: any = [];
    if (patternOptions && patternOptions.length > 0) {
      patternOptions.map((x: any) => {
        if (x?.defaultLabel === chapterDropDownValueWithId?.defaultLabel) {
          arr.push({ ...x, chapterDropDownValueWithId });
        } else if (x?.defaultLabel === levelDropDownValueWithId?.defaultLabel) {
          arr.push({ ...x, levelDropDownValueWithId });
        }
      });
    }
    setDropdownArray(arr);
  }, [chapterDropDownValueWithId, levelDropDownValueWithId, patternOptions]);

  useEffect(() => {
    detailedViewRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [page]);

  return (
    <div className={styles.myStatsPatternAndQuestionsMainWrap}>
      <Breadcrumb breadcrumbDetails={statsBreadcrumbData} />

      {selectedChapterCard && (
        <ChapterProgressCard chapterProgressDetails={selectedChapterCard} />
      )}
      {graphData?.labels?.length != 0 && (
        <>
          <div className={classes.SubTopicTex}>
            {constants.subTopicProgress}
          </div>
          <div>
            {graphData && (
              <AnalyticsChart
                analyticsData={graphData}
                axisLabels={{
                  xAxis: constants.TOPICS,
                  yAxis: constants.LEVELS,
                }}
              />
            )}
          </div>
        </>
      )}
      <div className={styles.filtersAndTitleTextContainer}>
        <div className={styles.patternTitleText}>
          {constants.myChapterQuestionText}
        </div>
        <div ref={detailedViewRef}>
          <FilterBar
            showSorting={false}
            patternsData={patternOptions ? patternOptions : []}
            initialSelectedPatternsValues={initialSelectedPatternsValues}
            selectedStatus={(selectedStatuses: string[]) =>
              setSelectedStatus(selectedStatuses)
            }
            setUpdatedSelectedPatternValues={setUpdatesSelectedPatterns}
            setDropdownSelectedValueWithId={setDropdownSelectedValueWithId}
          />
        </div>
      </div>
      {
        <div className={styles.patternQuestionsMainWrapper}>
          {filteredTopics?.length > 0 &&
            filteredTopics
              ?.slice(paginationStart, paginationEnd)
              ?.map((each: any, index: number) => (
                <div
                  className={styles.myStatsQuestionCardWrap}
                  key={`chapterQuestion_${index}`}
                >
                  <MyStatsQuestionCard
                    data={each}
                    statsCoveredName={"Patterns"}
                  />
                </div>
              ))}
          {filteredTopics?.length === 0 && loaderStatus.show === false && constants.NO_DATA_FOUND}
        </div>
      }
      {filteredTopics?.length > 0 && (
        <CustomPagination
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(filteredTopics?.length / range)}
        />
      )}
    </div>
  );
};

export default React.memo(MyStatsChapterAndQuestion);
