import React from "react";
import styles from "./totalEnrollment.module.scss";

const TotalEnrollment = ({ totalEnrollment, heading }: any) => {
  return (
    <div className={styles.totalEnrollment}>
      <h1>{heading ? heading : "Total Enrollment"}</h1>

      <div className={styles.number}>
        <span>{totalEnrollment.total} </span>
      </div>
      <p>Class 6 - Class 10</p>
    </div>
  );
};

export default TotalEnrollment;
