import React, { useState, useEffect, useContext } from "react";

import styles from "./assignmentsList.module.scss";
import { constants } from "../../constants/constants";
import FilterBar from "../../common/filterBar/filterBar";
import useSorting from "../../utils/useSorting";
import StatusContext from "../../context/loaderContext";
import useSortingByDate from "../../utils/useSortingByDate";
import { CustomPagination } from "../../common/pagination/customPagination";
import SearchBar from "../../common/searchBar/searchBar";
import TestCard from "../../common/cards/testCard";
import { useParams } from "react-router-dom";
import Dropdown from "../../common/dropdown/dropdown";
import { MenuItem, Select } from "@mui/material";

interface IAssignmentsList {
  assignmentsData: object[];
  detailedViewRef: any;
  istabbedPage?: boolean;
  isForReveiw?: boolean;
  testDates?: any;
  selectedTestDate?: any;
  setSelectedTestDate?: any;
}
const TestList = ({
  assignmentsData,
  detailedViewRef,
  istabbedPage,
  isForReveiw,
  testDates,
  selectedTestDate,
  setSelectedTestDate,
}: IAssignmentsList) => {
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const loaderStatus: any = useContext(StatusContext);

  const { chapterId } = useParams();

  const dataMassaging = (data: any) => {
    const newArr = data.map((item: any) => {
      if (isForReveiw) {
        return {
          ...item,
          name: item?.topic?.chapter_name || item.chapter_name,
          status:
            item.status_of_test.status == "incomplete"
              ? "Review Pending"
              : "Completed",
        };
      }
      return {
        ...item,
        name: item?.topic?.chapter_name || item.chapter_name,
        status: item.status_of_test.status,
      };
    });
    if (chapterId && istabbedPage) {
      return newArr.filter((item: any) => chapterId == item.topic.chapter_id);
    }

    return newArr;
  };
  const [searchText, setSearchText] = useState("");
  const [dropdownArray, setDropdownArray] = React.useState<any>([]);
  const {
    dropdownSelectedValueWithId,
    setDropdownSelectedValueWithId,
    filteredTopics,
    page,
    paginationStart,
    paginationEnd,
    range,
    handlePaginationChange,
  } = useSorting(
    assignmentsData,
    selectedStatus,
    searchText,
    "studentsTest", // search filter key
    dataMassaging,
    5, // range
    dropdownArray
  );

  const { sortedByDateArr, handleSorting } = useSortingByDate(filteredTopics);

  useEffect(() => {
    handleSorting("desc");
  }, [filteredTopics]);

  return (
    <div className={`${styles.allAssignmentsMainContainer}`}>
      <div className={styles.assignmnetsTitleSearchbarContainer}>
        <div className={styles.assignmentHeaderTitle}>
          {istabbedPage
            ? "Here are all the tests related to this chapter"
            : "My Tests"}
        </div>
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
      </div>

      <div className={styles.separator}></div>
      <div className={styles.sortAndFilterContainer}>
        <div className={styles.filterMainContainer}>
          <div className={styles.statusContainer}>
            <FilterBar
              statuses={
                isForReveiw
                  ? ["All", "Review Pending", "Completed"]
                  : ["All", "incomplete", "review", "skipped", "complete"]
              }
              selectedStatus={(selectedStatuses: string[]) =>
                setSelectedStatus(selectedStatuses)
              }
              showSorting={false}
              innerDropdownNeeded={true}
            />
          </div>
        </div>
        <div style={{ maxWidth: "100%" }}>
          <Select
            defaultValue={selectedTestDate || ""}
            value={selectedTestDate}
            onChange={(e) => {
              setSelectedTestDate(e.target.value);
            }}
            placeholder={"Test Dates"}
            style={{
              width: "100%",
              background: "#EDF2FA",
            }}
            sx={{
              "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                {
                  color: "#4B5468",
                  fontFamily: "Inter-Regular",
                  fontSize: "14px",
                  lineHeight: "17px",
                  minHeight: "unset",
                  alignItems: "center",
                  padding: "10px 12px 9px 12px",
                  overflow: "hidden",
                },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#D4DCE8 !important",
              },
              "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                { cursor: "not-allowed" },
              "& .MuiInputLabel-shrink": {
                border: "1px solid",
              },
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {!!testDates.length &&
              testDates.map((value: any, index: any) => (
                <MenuItem key={value + index} value={value || ""}>
                  {value || ""}
                </MenuItem>
              ))}
          </Select>
        </div>
      </div>
      <div className={styles.testItemsContainer}>
        {sortedByDateArr.length > 0 &&
          sortedByDateArr
            ?.slice(paginationStart, paginationEnd)
            .map((item: any, ind: number) => {
              return (
                <TestCard
                  data={item}
                  status={item.status}
                  key={`item_${item?.id}_${ind}`}
                  isForReveiw={isForReveiw}
                />
              );
            })}
        {filteredTopics.length === 0 && loaderStatus.show === false && (
          <div className={styles.noResultFound}>{constants.NO_DATA_FOUND}</div>
        )}
      </div>

      {filteredTopics?.length > 0 && (
        <CustomPagination
          detailedViewRef={detailedViewRef}
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(filteredTopics?.length / range)}
        />
      )}
    </div>
  );
};

export default React.memo(TestList);
