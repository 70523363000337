import { makeAxiosRequest } from "aie_common";
import { useContext, useEffect, useState } from "react";
import ApiEdpoints, { HTTPMethods } from "../constants/api_endpoints";
import StatusContext from "../context/loaderContext";
import { getUpdatedChapterWisePatterns } from "./teacherPersonalMassagedData";

//This hook is used for both Chapters to be focused and Patterns to be focused.
const useChaptersToBeFocused = () => {
  const [chapterToBeFocused, setChapterToBeFocused] = useState<any>([]);
  const loaderStatus: any = useContext(StatusContext);
  const [updatedChapterToBeFocused, setUpdatedChapterToBeFocused] =
    useState<any>([]);

  const fetchChaptersToBeFocused = async (courseId: any) => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.CHAPTER_WISE_PATTERNS}/?course_id=${courseId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      if (res) {
        loaderStatus.setState({ show: false });
        setChapterToBeFocused(res?.data?.data);
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    setUpdatedChapterToBeFocused(
      getUpdatedChapterWisePatterns(chapterToBeFocused)
    );
  }, [chapterToBeFocused]);

  return {
    updatedChapterToBeFocused,
    fetchChaptersToBeFocused,
  };
};

export default useChaptersToBeFocused;
