import React from "react";
import styles from "./chapterIconName.module.scss";

interface IChapterIconNameProps {
  icon: string;
  heading: string | number;
}
const ChapterIconName = ({ icon, heading }: IChapterIconNameProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.classImage}>
        <img src={icon} alt={icon} />
      </div>
      <div className={styles.divisionCardNumber}>
        <div className={`${styles.divisionClass} ${styles.inter14}`}>
          {heading}
        </div>
      </div>
    </div>
  );
};

export default ChapterIconName;
