import { useState, useEffect } from "react";

const useSortingByDate = (filteredTopics: any[]) => {
  const [sortedByDateArr, setSortedByDateArr] = useState<any[]>(filteredTopics);
  const [sortedByDate, setSortedByDate] = useState<boolean>(true);

  const handleSorting = (sortType: string) => {
    setSortedByDate(!sortedByDate);
    const sortDirection = sortType === "asc" ? 1 : -1;
    const sortedTopics = filteredTopics.sort((a: any, b: any) => {
      const dateOne = new Date(
        (a?.lastAttempted || a?.submittedOn)?.replace(/'(\d{2})/, "20$1")
      );
      const dateTwo = new Date(
        (b?.lastAttempted || b?.submittedOn)?.replace(/'(\d{2})/, "20$1")
      );
      return (
        sortDirection * dateOne.getTime() - sortDirection * dateTwo.getTime()
      );
    });
    setSortedByDateArr(sortedTopics);
  };

  useEffect(() => {
    if (!sortedByDate) {
      setSortedByDateArr(filteredTopics);
    }
  }, [filteredTopics, sortedByDate]);

  return { sortedByDateArr, handleSorting };
};

export default useSortingByDate;
