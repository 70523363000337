const initialState = {
  tabOpen: true,
};

const tabReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case "OPEN":
      return { tabOpen: true };

    case "CLOSE":
      return { tabOpen: false };
    default:
      return state;
  }
};

export default tabReducer;
