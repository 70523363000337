import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./solveAssignmentMetaData.module.scss";
import { Time } from "../../assignmentPage/questionMetaDataSection/time/time";
import Mascot from "../../../../../assets/images/mascotVSign.svg";
import { SuggestionSelection } from "./suggestion/suggestionSelection";
import { SuggestionResultCorrect } from "./suggestion/suggestionResultCorrect";
import { SuggestionReview } from "./suggestion/suggestionReview";
import { SuggestionResultOptionWrong } from "./suggestion/suggestionResultOptionWrong";
import { ScoreBoard } from "./scoreBoard/scoreBoard";
import { SolveAssignmentQuestionList } from "./solveAssignmentQuestionList/solveAssignmentQuestionList";
import { SubmissionDetails } from "./submissionDetails/submissionDetails";
import { PatternCards } from "../../assignmentPage/questionMetaDataSection/patternCards/patternCards";
import PatternCardCarousal from "../../assignmentPage/assignmentResultWrapper/resultSection/patternCardCarousal/patternCardCarousal";
import { SolveAssignmentQuestionPopUp } from "./solveAssignmentQuestionPopUp/solveAssignmentQuestionPopUp";
import { useSelector } from "react-redux";
import PatternCard from "../../../components/patternListing/patternCard/patternCard";
import { CloseRounded } from "@mui/icons-material";
import { Grid } from "@mui/material";

interface ISolveAssignmentMetaDataProps {
  question: any;
  activeQuestionIndex: any;
  setQuestion: any;
  setActiveQuestionIndex: any;
  saveQuestion: any;
  activeAssignmentType?: any;
  optionClicked?: any;
  setOptionClicked?: any;
  activeScreen?: any;
  isDataUpdated?: any;
  setPatternCards?: any;
  patternCards?: any;
  aiSupport?: boolean;
  fromExercisePage?: boolean;
}

export const SolveAssignmentMetaData = (
  props: ISolveAssignmentMetaDataProps
) => {
  const {
    question,
    activeQuestionIndex,
    setQuestion,
    setActiveQuestionIndex,
    saveQuestion,
    activeAssignmentType,
    optionClicked,
    setOptionClicked,
    activeScreen,
    isDataUpdated,
    setPatternCards,
    patternCards,
    aiSupport,
    fromExercisePage,
  } = props;

  const [questionAnswerPopup, setQuestionAnswerPopup] = useState(false);
  const [showAiSupport, setShowAiSupport] = useState(false);

  return (
    <div className={styles.timerSectionContainer}>
      <div className={styles.timerSectionInsideContainer}>
        {activeScreen === "result" && (
          <>
            {question[activeQuestionIndex].descriptive ? (
              question[activeQuestionIndex].visited === "InReview" ? (
                <SubmissionDetails
                  question={question}
                  activeQuestionIndex={activeQuestionIndex}
                />
              ) : (
                <ScoreBoard
                  question={question}
                  activeQuestionIndex={activeQuestionIndex}
                />
              )
            ) : (
              <ScoreBoard
                question={question}
                activeQuestionIndex={activeQuestionIndex}
              />
            )}
          </>
        )}
        <div className={styles.timerSectionInside}>
          <div>
            <div style={{ paddingTop: "20px" }}>
              <SolveAssignmentQuestionPopUp
                question={question}
                activeQuestionIndex={activeQuestionIndex}
                questionAnswerPopup={questionAnswerPopup}
                setQuestionAnswerPopup={setQuestionAnswerPopup}
                setActiveQuestionIndex={setActiveQuestionIndex}
                fromExercisePage={fromExercisePage}
                aiSupport={aiSupport}
              />
            </div>
            <div className={styles.questionNumberContainer}>
              {question.map((item: any, index: any) => {
                return (
                  <div key={index}>
                    <SolveAssignmentQuestionList
                      key={index}
                      questions={question}
                      setQuestion={setQuestion}
                      type={item.visited}
                      item={index + 1}
                      activeQuestionIndex={activeQuestionIndex}
                      setActiveQuestionIndex={setActiveQuestionIndex}
                      activeScreen={activeScreen}
                      setOptionClicked={setOptionClicked}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.timerSectionInsideTime}>
            {aiSupport && (
              <button
                onClick={() => {
                  setShowAiSupport(true);
                }}
                className={styles.aiSupportBtn}
              >
                AI Support
              </button>
            )}
            {activeScreen === "selection" && (
              <Time
                question={question}
                activeQuestionIndex={activeQuestionIndex}
                setQuestion={setQuestion}
                setActiveQuestionIndex={setActiveQuestionIndex}
                saveQuestion={saveQuestion}
                activeAssignmentType={activeAssignmentType}
                optionClicked={optionClicked}
                setOptionClicked={setOptionClicked}
                isDataUpdated={isDataUpdated}
              />
            )}
          </div>
        </div>
        {aiSupport && showAiSupport && (
          <div className={styles.aiSupportcardWrap}>
            <div className={styles.aiSupportcard}>
              <button
                onClick={() => {
                  setShowAiSupport(false);
                }}
              >
                <CloseRounded />
              </button>
              <p>AI Support</p>
              <p>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <span>Great job! Keep up the good work.</span>
                    <span>
                      Remember to read each question carefully and try to
                      eliminate any obviously wrong answers. If you're unsure of
                      the answer, make an educated guess and move on. You got
                      this!
                    </span>
                  </Grid>
                  <Grid item xs={3}>
                    <img src={Mascot} alt="" />
                  </Grid>
                </Grid>
              </p>
              <p>Concepts you need to focus on</p>
              <PatternCardCarousal
                tag={question[activeQuestionIndex]?.tag}
                activeQuestionIndex={activeQuestionIndex}
                question={question}
                setQuestion={setQuestion}
                setPatternCards={setPatternCards}
                patternCards={patternCards}
              />
            </div>
          </div>
        )}

        {/* {activeScreen === "result" && (
          <>
            {question[activeQuestionIndex].descriptive ? (
              question[activeQuestionIndex].visited === "InReview" ? (
                <SuggestionReview />
              ) : question[activeQuestionIndex].isCorrect ? (
                <SuggestionResultCorrect />
              ) : (
                <SuggestionResultOptionWrong />
              )
            ) : question[activeQuestionIndex].isCorrect ? (
              <SuggestionResultCorrect />
            ) : (
              <SuggestionResultOptionWrong />
            )}
          </>
        )} */}
        {/* {activeScreen === "selection" && <SuggestionSelection />} */}
        {/* {
                    activeScreen === "selection" && <PatternCards
                        tag={question[activeQuestionIndex]?.tag}
                        activeQuestionIndex={activeQuestionIndex}
                        question={question}
                        activeAssignmentType={activeAssignmentType}
                        patternCardsNew = {patternCards}

                    />
                } */}
        {/* {
                    activeScreen === "result" &&
                    <>
                        <PatternCardCarousal
                            tag={question[activeQuestionIndex]?.tag}
                            activeQuestionIndex={activeQuestionIndex}
                            question={question}
                            setQuestion={setQuestion}
                            setPatternCards={setPatternCards}
                            patternCards={patternCards}
                        />
                    </>

                } */}
      </div>
    </div>
  );
};
