import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { makeAxiosRequest } from "aie_common";
import styles from "./myAssignments.module.scss";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import StatusContext from "../../context/loaderContext";
import { HTTPMethods } from "../../constants/api_endpoints";
import HomeworkList from "./assignmentsList/homeworkList";
import { useParams } from "react-router-dom";

const MyHomework = ({ istabbedPage = false, isForReveiw = false }: any) => {
  const detailedViewRef = useRef(null);
  const [assignmentsData, setAssignmentsData] = useState<object[]>([]);
  const [homeworkDates, setHomeworkDates] = useState<any>([]);
  const [selectedHomeworkDate, setSelectedHomeworkDate] = useState<any>("");
  const params = useParams();
  const courseId = useSelector(
    (state: any) => state.userReducer?.registeredCourseId
  );
  const loaderStatus: any = useContext(StatusContext);

  const fetchAssignmentsList = async () => {
    if (!courseId) {
      return;
    }
    loaderStatus.setState({ show: true });

    const payload = {
      url: `studenthomework/?course_id=${courseId}`,
      data: {},

      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);

      if (response && response.status === 200) {
        const assignmentsDataRes = response?.data?.data;
        setAssignmentsData(assignmentsDataRes);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const fetchHomeworkDate = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `get_homework_dates/?user_id=${params.studentId}&course_id=${courseId}`,
      data: {},

      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);
      if (response && response.status === 200) {
        if (response.data.homework_dates.length) {
          setHomeworkDates(response.data.homework_dates);
          setSelectedHomeworkDate(response.data.homework_dates[0]);
        }
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const fetchHomeworkData = async (date: any) => {
    if (!params.studentId || !date || !courseId) {
      return;
    }
    loaderStatus.setState({ show: true });
    const payload = {
      url: `homeworkcorrection/?student_id=${params.studentId}&date=${date}&course_id=${courseId}`,
      data: {},

      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);
      if (response && response.status === 200) {
        setAssignmentsData(response.data.results.data);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchHomeworkData(selectedHomeworkDate);
  }, [selectedHomeworkDate]);

  useEffect(() => {
    if (isForReveiw) {
      fetchHomeworkDate();
    } else {
      fetchAssignmentsList();
    }
  }, []);

  return (
    <div
      className={`${styles.myAssignmentsMainContainer} ${
        istabbedPage ? styles.allAssignmentsMainContainerTabbed : ""
      }`}
      ref={detailedViewRef}
    >
      {!istabbedPage && (
        <Breadcrumb
          breadcrumbDetails={[
            {
              label: "Home",
              link: "/home",
              selected: false,
            },
            {
              label: "Practice",
              link: "/practice/homeworks",
              selected: false,
            },
            {
              label: "Homework",
              link: "/practice/homeworks",
              selected: true,
            },
          ]}
        />
      )}
      <HomeworkList
        detailedViewRef={detailedViewRef}
        assignmentsData={assignmentsData}
        istabbedPage={istabbedPage}
        isForReveiw={isForReveiw}
        homeworkDates={homeworkDates}
        selectedHomeworkDate={selectedHomeworkDate}
        setSelectedHomeworkDate={setSelectedHomeworkDate}
      />
    </div>
  );
};

export default React.memo(MyHomework);
