import React from "react";
import styles from "./curriculum.module.scss";

const Curriculum = ({
  curriculum,
  heading,
  classInfo,
  subHeadingOne,
  subHeadingTwo,
  dataOne,
  dataTwo,
}: any) => {
  return (
    <div className={styles.curriculum}>
      {heading && <h1>{heading}</h1>}

      <div className={styles.number}>
        <div className={styles.block}>
          <h1>{subHeadingOne}</h1>
          <p>{dataOne}</p>
        </div>
        <div className={styles.block}>
          <h1>{subHeadingTwo}</h1>
          <p>{dataTwo}</p>
        </div>
      </div>
      {classInfo && <p>Class 6 - Class 10</p>}
    </div>
  );
};

export default Curriculum;
