import React from "react";
import PatternVideoOverlay from "../patternVideoOverlay/patternVideoOverlay";
import PPTOverlay from "../pptOverlay/pptOverlay";
import SideBar from "../sidebar/sidebar";
import styles from "./patternVideoOverlayContainer.module.scss";

interface IPatternVideoOverlay {
  videoNotesOverlayContent?: string;
  docName: string;
  handlePatternOverlay?: any;
  pptUrl: string;
  handlePatternVideoOverlayContent: any;
  patternCardData: {
    descriptionText: string;
    patternHeadingText : string;
  };
  videoUrl: string;
  patternExamples?: [];
}

const PatternVideoOverlayContainer = ({
  docName,
  handlePatternOverlay,
  pptUrl,
  videoNotesOverlayContent,
  handlePatternVideoOverlayContent,
  patternCardData,
  videoUrl,
  patternExamples,
}: IPatternVideoOverlay) => {

  return (
    <>
      <SideBar
        content={
          // resource: "viewDocument" | "pattern" | "patternMap"
          videoNotesOverlayContent === "viewDocument" ? (
            <div className={styles.patternVideoPPTOverlay}>
              <PPTOverlay
                pptOverlayData={{
                  name: docName,
                  pptUrl,
                }}
                closeOverlay={handlePatternOverlay}
                handlePatternVideoOverlayContent={
                  handlePatternVideoOverlayContent
                }
              />
            </div>
          ) : videoNotesOverlayContent === "pattern" ? (
            <PatternVideoOverlay
              patternExamples={patternExamples}
              videoUrl={videoUrl}
              handlePatternOverlay={handlePatternOverlay}
              handlePatternVideoOverlayContent={
                handlePatternVideoOverlayContent
              }
              patternCardData={patternCardData}
            />
          ) : videoNotesOverlayContent === "patternMap" ? (
            <></>
          ) : (
            <PatternVideoOverlay
              videoUrl={videoUrl}
              handlePatternOverlay={handlePatternOverlay}
              handlePatternVideoOverlayContent={
                handlePatternVideoOverlayContent
              }
              patternCardData={patternCardData}
            />
          )
        }
        width="826px"
      />
    </>
  );
};

export default PatternVideoOverlayContainer;
