export const formatDate = (dateString: string) => {
    const months = [
      "Jan", "Feb", "Mar",
      "Apr", "May", "Jun",
      "Jul", "Aug", "Sep",
      "Oct", "Nov", "Dec"
    ];
    
    const date = new Date(dateString);
    const year = date.getFullYear().toString().substr(-2);
    const month = months[date.getMonth()];
    const day = date.getDate().toString().padStart(2, '0');
    
    return `${day} ${month}' ${year}`;
  }

export const formatDateWithTime = (dateString: string) => {
  let date = new Date(dateString);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear().toString().substr(-2)}, ${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
}