import React, { useState } from "react";
import styles from "./teachersDetailsSideBar.module.scss";
import studentImage from "../../../assets/images/student.png";

import ConfirmationPopup from "../confirmationPopup/confirmationPopup";

const TeachersDetailsSideBar = ({
  classTeacher,
  mathsTeacher,
  status,
  changeStatus,
  onTeacherEdit,
  onTeacherDelete,
  onMathTeacherEdit,
  onMathTeacherDelete,
}: any) => {
  // const slideBarToggle = status; // set this to true to view this component on front-end

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  function deleteDetails() {
    setShowDeletePopup(!showDeletePopup);
  }

  let [toggle, setToggle] = useState(1);

  function toggleTab(id: any) {
    setToggle(id);
    setShowDeletePopup(false);
  }

  return (
    <>
      <div className={styles.tabs}>
        {mathsTeacher && (
          <>
            <div
              className={` ${styles.tab_1} ${
                toggle === 1 ? styles.active : ""
              } `}
              onClick={() => {
                toggleTab(1);
              }}
            >
              <h1>Assigned Teacher Details</h1>
            </div>
            <div
              className={`${styles.tab_2} ${
                toggle === 2 ? styles.active : ""
              } `}
              onClick={() => {
                toggleTab(2);
              }}
            >
              <h1>Maths Teacher Details</h1>
            </div>
          </>
        )}
      </div>
      <div className={styles.content}>
        <div
          className={`${styles.teacher}  ${toggle === 1 ? styles.active : ""} `}
        >
          <div className={styles.photo}>
            <img src={studentImage} alt="" />
          </div>

          <div className={styles.card}>
            <div className={styles.head}>
              <h1>{classTeacher.name}</h1>
            </div>
            <div className={styles.details}>
              <div className={styles.block}>
                <h1>{classTeacher.class}</h1>
                <p>Class</p>
              </div>
              <div className={styles.block}>
                <h1>{classTeacher.email}</h1>
                <p>Primary Email ID</p>
              </div>
              <div className={styles.block}>
                <h1>{classTeacher.contact}</h1>
                <p>Primary Mobile No.</p>
              </div>
              <div className={styles.block}>
                <h1>{classTeacher.gender}</h1>
                <p>Gender</p>
              </div>
              <div className={styles.block}>
                <h1>{classTeacher.teachersRoll}</h1>
                <p>Teacher Role</p>
              </div>
            </div>
            <div className={styles.buttons}>
              <h2>Contact</h2>
              <div>
                <button
                  onClick={() => setShowDeletePopup(true)}
                  className={styles.delete}
                >
                  Delete
                </button>
                <button onClick={onTeacherEdit} className={styles.edit}>
                  Edit details
                </button>
              </div>
            </div>
          </div>
          {showDeletePopup && (
            <ConfirmationPopup
              title=" Are you sure you want to delete
                                         the teacher details
                                               permanently?"
              confirmText="Yes"
              declineText="No"
              onConfirm={() => {
                onTeacherDelete();
                setShowDeletePopup(false);
              }}
              onDecline={deleteDetails}
            />
          )}
        </div>
        {mathsTeacher && (
          <div
            className={`${styles.teacher}  ${
              toggle === 2 ? styles.active : ""
            } `}
          >
            <div className={styles.photo}>
              <img src={studentImage} alt="" />
            </div>

            <div className={styles.card}>
              <div className={styles.head}>
                <h1>{mathsTeacher.name}</h1>
              </div>
              <div className={styles.details}>
                <div className={styles.block}>
                  <h1>{mathsTeacher.class}</h1>
                  <p>Class</p>
                </div>
                <div className={styles.block}>
                  <h1>{mathsTeacher.email}</h1>
                  <p>Primary Email ID</p>
                </div>
                <div className={styles.block}>
                  <h1>{mathsTeacher.contact}</h1>
                  <p>Primary Mobile No.</p>
                </div>
                <div className={styles.block}>
                  <h1>{mathsTeacher.gender}</h1>
                  <p>Gender</p>
                </div>
                <div className={styles.block}>
                  <h1>{mathsTeacher.teachersRoll}</h1>
                  <p>Teacher Role</p>
                </div>
              </div>
              <div className={styles.buttons}>
                <h2>Contact</h2>
                <div>
                  <button
                    onClick={() => setShowDeletePopup(true)}
                    className={styles.delete}
                  >
                    Delete
                  </button>
                  <button onClick={onMathTeacherEdit} className={styles.edit}>
                    Edit details
                  </button>
                </div>
              </div>
            </div>
            {showDeletePopup && (
              <ConfirmationPopup
                title=" Are you sure you want to delete
                                         the math teacher details
                                               permanently?"
                confirmText="Yes"
                declineText="No"
                onConfirm={() => {
                  onMathTeacherDelete();
                  setShowDeletePopup(false);
                }}
                onDecline={deleteDetails}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TeachersDetailsSideBar;
