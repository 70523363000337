import greenRightArrowIcon from "../../../../../../assets/images/greenRightArrowIcon.svg";
import mcqResultsQuestionIcon from "../../../../../../assets/images/mcqResultsQuestionIcon.svg";
import mcqResultsStarIcon from "../../../../../../assets/images/mcqResultsStarIcon.svg";
import styles from "./resultSection.module.scss";
import React, { useState } from 'react';
import SubmitQuestionAndAnswerPopup from "../../../../components/questionAndAnswerPopup/submitQuestionAndAnswerPopup";
import correctIcon from '../../../../../../assets/images/mcqCorrectIcon.svg';
import incorrectIcon from '../../../../../../assets/images/mcqIncorrectIcon.svg';
import notAnsweredIcon from '../../../../../../assets/images/mcqNotAnsweredIcon.svg';


const ResultSection = ({ question, activeQuestionIndex, resultData }: any) => {

  const [questionAnswerPopup, setQuestionAnswerPopup] = useState(false);
  const date = new Date(question[activeQuestionIndex].last_visited_time);

  const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear().toString().substr(-2)}, ${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;

  const val = (question.filter((item: any) => item.iscorrect === false).length / question.length) * 100
    + (question.filter((item: any) => item.iscorrect).length / question.length) * 100
    - (question.filter((item: any) => item.selectedAnswer === "" || item.ans_submit === "None").length / question.length) * 100;

  const calculateTime = (time: any) => {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;
    return `${minutes} mins ${seconds} secs`;
  }

  const dates = new Date();
  return (
    <div className={styles.mcqSidebarContainer}>
      <div className={styles.header}>
        <div className={styles.starIcon}>
          <img src={mcqResultsStarIcon}
            alt="star icon"
          />
        </div>
        <div className={`${styles.scoreText} ${styles.inter20}`}><span className={styles.yourScore}>You Scored </span>
          <span className={styles.inter36}>{resultData.totalScore}/{resultData.totalMarks}</span></div>
      </div>
      <div className={styles.detailedScore}>
        {/* <div className={styles.attempts}>Attempt {resultData.attemptNumber}</div> */}
        <div className={styles.attemptDateTimeContainer}>
          <div className={styles.infoWrap}>
            <div className={styles.dateTime}>{formattedDate}</div>
            <div className={styles.dateTimeSubText}>Attempt Date</div>
          </div>
          <div className={styles.infoWrap}>
            <div className={styles.dateTime}>
              {calculateTime(question[activeQuestionIndex].timetaken || 0)}
            </div>
            <div className={styles.dateTimeSubText}>Time Taken</div>
          </div>
        </div>
        <div className={styles.questionsAttempted}>
          <div className={styles.questionMarkIcon}>
            <img src={mcqResultsQuestionIcon}
              alt="question icon"
            />
          </div>
          <div className={styles.questionAttemptedByTotal}>
            {question.filter((item: any) => item.selectedAnswer !== "" && item.ans_submit !== "None").length} / {question.length} Questions Attempted
          </div>
        </div>
        <div className={styles.questionDivisionGraph}>
          <div className={styles.correct} style={{
            width:
              `${(question.filter((item: any) => item.iscorrect).length / question.length) * 100}%`
          }}></div>
          <div className={styles.incorrect} style={{
            width:
              `${val}%`
          }}></div>
          <div className={styles.notAnswered} style={{
            width:
              `${(question.filter((item: any) => item.selectedAnswer === "" || item.ans_submit === "None").length / question.length) * 100}%`
          }}></div>
        </div>
        <div className={styles.detailedQuestionNumbers}>
          <div className={styles.divisionNumber}>
            <div className={styles.icon}
            // className={`${styles.mcqResultCorrect} ${styles.legendIcon}`}
            >
              <img src={correctIcon} alt="correct" />
            </div>
            <div><span className={styles.number}>
              {question.filter((item: any) => item.iscorrect).length}
            </span>Correct</div>
          </div>
          <div className={styles.divisionNumber}>
            <div className={styles.icon}
            //  className={`${styles.mcqResultIncorrect} ${styles.legendIcon}`}
            >
              <img src={incorrectIcon} alt="incorrect" />
            </div>
            <div><span className={styles.number}>{
              question.filter((item: any) => item.iscorrect === false && (item.ans_submit !== "None")).length
            }
            </span>Incorrect</div>
          </div>
          <div className={styles.divisionNumber}>
            <div className={styles.icon}
            // className={`${styles.mcqResultNA} ${styles.legendIcon}`}
            >
              <img src={notAnsweredIcon} alt="notAnswered" />
            </div>
            <div><span className={styles.number}>
              {question.filter((item: any) => item.ans_submit === "None").length}
            </span>N/A</div>
          </div>
        </div>
        <div
          onClick={() => setQuestionAnswerPopup(!questionAnswerPopup)}
          className={styles.viewAllContainer}>
          <div className={styles.viewAll}>View all</div>
          <div>
            <img src={greenRightArrowIcon}
              alt="right arrow icon"
            />
          </div>
        </div>
      </div>
      {
        questionAnswerPopup && <SubmitQuestionAndAnswerPopup title={"All Questions"} closePopUp={() => setQuestionAnswerPopup(!questionAnswerPopup)}
          content={question} activeQuestionIndex={activeQuestionIndex} />
      }
    </div>
  );
};
export default ResultSection;


