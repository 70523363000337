import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import aiEducatorLogo from "../../assets/images/aiEducatorLogo1.svg";
import collapsedAIEducatorLogo from "../../assets/images/collapsedAIEducatorLogo.svg";
import crossIconForSideNavBar from "../../assets/images/crossIconForSideNavBar.svg";
import sideNavCollapseIcon from "../../assets/images/sideNavCollapseIcon.svg";
import userIcon from "../../assets/images/userIcon.svg";
import configureStore from "../../store";
import { getUsernameInitials } from "../../utils/usernameInitials";
import SideNavElement from "../sideNavElement/sideNavElement";
import styles from "./sideNavBar.module.scss";

interface IShowSideNavBar {
  showSideNav: boolean;
  handleExpand: () => void;
}

const SideNavBar = ({
  showSideNav,
  handleExpand,
  data,
  setData,
  showCollapseIcon = true,
}: any) => {
  const isTabOpen = useSelector((state: any) => state.tabReducer.tabOpen);
  const [usernameInitials, setUsernameInitials] = useState("");
  const { dispatch } = configureStore();
  const [isExpanded, seIsExpanded] = useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(
    localStorage.getItem("USERTYPE") === "Student" ? 1 : 0
  );
  const { username, classname, photo } = useSelector(
    (state: any) => state.userReducer
  );

  const userData = useSelector((state: any) => state.userReducer);

  const sidebarUserName =
    userData?.userData?.userData?.user?.first_name +
      " " +
      userData?.userData?.userData?.user?.last_name || "";

  const getuserType = (type: any) => {
    if (type == "student") return "Student";
    if (type == "school_admin") return "School Admin";
    if (type == "super_admin") return "Super Admin";

    return "Teacher";
  };

  const handleCollapse = () => {
    seIsExpanded(!isExpanded);
    if (isTabOpen) {
      dispatch({ type: "CLOSE" });
    } else {
      dispatch({ type: "OPEN" });
    }
  };

  useEffect(() => {
    setUsernameInitials(getUsernameInitials(username));
  }, [username]);

  return (
    <div
      className={`${styles.parent} ${
        !isExpanded ? styles.collapsedParent : ""
      }`}
    >
      <div className={styles.aiEducatorLogoContainer}>
        {isExpanded ? (
          <div className={styles.aiEducatorLogo}>
            <div>
              {" "}
              <img src={aiEducatorLogo} className={styles.icon} />
            </div>
            {showSideNav && (
              <div>
                <img src={crossIconForSideNavBar} onClick={handleExpand} />
              </div>
            )}
          </div>
        ) : (
          <div className={styles.aiEducatorCollapsed}>
            <img src={collapsedAIEducatorLogo} className={styles.icon} />
          </div>
        )}
      </div>
      {data?.map((each: any, index: number) => (
        <SideNavElement
          key={index}
          title={each.title}
          activeImage={each.activeImage}
          image={each.image}
          count={each.count}
          path={each.path}
          setSelectedIndex={setSelectedIndex}
          index={index}
          selectedIndex={selectedIndex}
          isExpanded={isExpanded}
          handleExpand={handleExpand}
          children={each.children}
        />
      ))}
      {showCollapseIcon && (
        <div className={styles.collapseExpandContainer}>
          <div className={styles.divider}></div>
          <div className={styles.collapseIcon} onClick={handleCollapse}>
            <img
              src={sideNavCollapseIcon}
              alt=""
              className={isExpanded ? styles.icon : styles.expandIcon}
            />
          </div>
        </div>
      )}

      <div
        className={`${styles.userData} ${
          !isExpanded ? styles.userDataCollapsed : ""
        }`}
      >
        <div className={styles.userIcon}>
          {photo ? (
            <>
              <div className={styles.userIconContainer}>
                <img src={photo} alt="" className={styles.image} />
              </div>
            </>
          ) : (
            <>
              <div className={styles.userIconContainer}>
                <div className={styles.userInitials}>
                  <div className={styles.initials}>{usernameInitials}</div>
                </div>
              </div>
            </>
          )}
        </div>
        {isExpanded && (
          <div
            className={`${styles.userDetails} ${
              !isExpanded ? styles.userDetailsCollapsed : ""
            }`}
          >
            <div className={styles.username}>
              {username || sidebarUserName || ""}
            </div>
            <div className={styles.userClass}>
              {getuserType(localStorage.getItem("USERTYPE"))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideNavBar;
