import { useState, useEffect, useRef } from "react";
import { useSearch } from "./searchBar";
import usePagination from "./customPagination";

//   useSorting can be use for the type of sortting
//   1. All, Pending, Completed, In Progress
//   2. Search by Value
//   3. Dropdown selection

const useSorting = (
  apiResponse?: any,
  selectedStatus?: any,
  searchText?: string,
  searchType?: string,
  dataMassaging?: any,
  range?: any,
  dropdownArray?: any
) => {
  const [itemsList, setItemsList] = useState([]);
  const [dropdownQuestionsList, setDropdownQuestionsList] = useState<any>([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [dropdownSelectedValueWithId, setDropdownSelectedValueWithId] =
    useState<any>({});
  const [sortedFilteredData, setSortedFilteredData] = useState<any>([]);
  const [filteredTopics, getFilteredData] = useSearch(sortedFilteredData);
  const [
    filterUsingAllPendingCompletedInProgress,
    setFilterUsingAllPendingCompletedInProgress,
  ] = useState<any>([]);
  const isStatusSelected =
    selectedStatus.length > 0
      ? selectedStatus?.every((x: any) => x?.toLowerCase() !== "all")
      : false;

  const detailedViewRef = useRef(null);
  const {
    page,
    paginationStart,
    paginationEnd,
    handlePaginationChange,
    setPaginationStart,
    setPaginationEnd,
    setPage,
  } = usePagination(sortedFilteredData, range, detailedViewRef);
  const dropdownQuestion = () => {
    const arr = [{ name: "All Questions", id: 0 }];
    itemsList &&
      itemsList.map((item: any) => {
        arr.push({
          name: item?.questionName,
          id: item?.questionId,
        });
        setDropdownQuestionsList(arr);
      });
  };

  useEffect(() => {
    if (apiResponse) {
      const data = dataMassaging(apiResponse);
      setItemsList(data);
    }
  }, [apiResponse]);

  useEffect(() => {
    dropdownQuestion();
  }, [itemsList]);
  useEffect(() => {
    const filteredAssignments =
      Array.isArray(itemsList) &&
      (itemsList.length > 0 || isStatusSelected) &&
      itemsList.filter((x: any) => {
        const dropdownMatch = isStatusSelected
          ? selectedStatus?.includes(x?.status)
          : true;
        return dropdownMatch;
      });
    setFilterUsingAllPendingCompletedInProgress(filteredAssignments);
  }, [itemsList, selectedStatus, isStatusSelected]);

  useEffect(() => {
    const applyFilter = () => {
      let filteredAssignments =
        Array.isArray(filterUsingAllPendingCompletedInProgress) &&
        (filterUsingAllPendingCompletedInProgress.length > 0 || itemsList)
          ? filterUsingAllPendingCompletedInProgress.filter((x) => {
              const dropdownMatch = dropdownArray.every((y: any) => {
                if (
                  !y?.chapterDropDownValueWithId?.id &&
                  !y?.levelDropDownValueWithId?.id
                ) {
                  return true; // Matches all items
                } else if (!y?.chapterDropDownValueWithId?.id) {
                  return (
                    x?.level === y?.levelDropDownValueWithId?.id ||
                    y?.levelDropDownValueWithId?.id === 0
                  );
                  // Matches "All Chapters" and specific level or all levels
                } else if (!y?.levelDropDownValueWithId?.id) {
                  return (
                    x?.questionId === y?.chapterDropDownValueWithId?.id ||
                    y?.chapterDropDownValueWithId?.id === 0
                  );
                  // Matches specific chapter and "All Levels" or all chapters
                } else {
                  return (
                    x?.questionId === y?.chapterDropDownValueWithId?.id && // Matches specific chapter
                    x?.level === y?.levelDropDownValueWithId?.id // Matches specific level
                  );
                }
              });
              return dropdownMatch;
            })
          : itemsList;

      const finalFilteration = dropdownArray.reduce((result: any, x: any) => {
        if (x?.correctionDropDownValueWithId?.id === 1) {
          return result.sort((a: any, b: any) => a.solvedCount - b.solvedCount);
        } else if (x?.correctionDropDownValueWithId?.id === 2) {
          return result.sort((a: any, b: any) => b.solvedCount - a.solvedCount);
        } else {
          return result;
        }
      }, filteredAssignments);

      setSortedFilteredData(finalFilteration);
    };

    applyFilter();
  }, [
    itemsList,
    selectedStatus,
    dropdownArray,
    isStatusSelected,
    dropdownSelectedValueWithId,
    filterUsingAllPendingCompletedInProgress,
  ]);

  useEffect(() => {
    if (searchText || searchText === "") {
      getFilteredData(searchText, searchType);
    }
  }, [searchText, searchType]);

  const setUpdatesSelectedValues = (updatedSelectedValues: any) => {
    setSelectedValues(updatedSelectedValues);
  };

  useEffect(() => {
    setPaginationStart(0);
    setPaginationEnd(range);
    setPage(1);
  }, [dropdownSelectedValueWithId]);

  return {
    itemsList,
    dropdownQuestionsList,
    selectedValues,
    dropdownSelectedValueWithId,
    setDropdownSelectedValueWithId,
    sortedFilteredData,
    filteredTopics,
    page,
    paginationStart,
    paginationEnd,
    handlePaginationChange,
    setUpdatesSelectedValues,
    range,
    detailedViewRef,
    isStatusSelected,
    getFilteredData,
  };
};

export default useSorting;
