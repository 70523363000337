import React, { useEffect, useState } from "react";
import styles from "./upcomingTest.module.scss";
import Calender from "../../../../../assets/images/resultCalender.svg";
import CreateHomeworkTest from "../../../myClass/pages/createHomeworkTest/createHomeworkTest";

const DUMMY_DATA = [
  { name: "Polynomial and Real numbers test", date: "10 Mar’23" },
  { name: "Weekly test 02", date: "14 Mar’23" },
  { name: "Polynomial and Real numbers test", date: "18 Mar’23" },
  { name: "Trigonometric and geometry equations", date: "24 Mar’23" },
];

const UpcomingTest = () => {
  const [tests, setTests] = useState<any>([]);
  useEffect(() => {
    setTests(DUMMY_DATA);
  }, []);
  return (
    <div className={styles.container}>
      <div>Upcoming Test</div>
      <div>
        <ul>
          <li>
            <span>Tests</span>
            <span>Date</span>
          </li>
          {tests.map((test: any) => {
            return (
              <li>
                <span>{test.name}</span>
                <span>
                  <strong>On</strong>
                  <img src={Calender} alt="" /> {test.date}
                </span>
              </li>
            );
          })}
        </ul>
        <div>
          <CreateHomeworkTest onlyTest={true} />
        </div>
      </div>
    </div>
  );
};

export default UpcomingTest;
