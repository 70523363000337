import React from "react";
import Dialog from "@mui/material/Dialog";
import classes from "./questionAndAnswerPopup.module.scss";
import correctIcon from "../../../../assets/images/correctIcon.svg";
import closeIcon from "../../../../assets/images/closeIcon.svg";
import unSelect from "../../../../assets/images/disabled.svg";
import { QuestionAndOptionsElement } from "../../../../common/questionsAndOptions/questionAndOptions";
interface IPopUp {
  title: string;
  content: object[];
  closePopUp: any;
  activeQuestionIndex: number;
}
export const QuestionAndAnswerPopUp = ({
  title,
  content,
  closePopUp,
  activeQuestionIndex,
}: IPopUp) => {
  return (
    <>
      <Dialog
        open={true}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className={classes.popUpContainer}>
          <div className={classes.popUpHeaderWrapper}>
            <div className={classes.popUpHeaderText}>{title} </div>
            <div className={classes.popUpHeaderClose} onClick={closePopUp}>
              <img src={closeIcon} alt="close" />
            </div>
          </div>
          <div className={classes.popUpCardContainer}>
            {content &&
              content?.map((item: any, ind) => (
                <div
                  className={`${classes.popUpCardItem} ${
                    ind === activeQuestionIndex
                      ? classes.popUpCardItemActive
                      : ""
                  }`}
                  key={`${item.question}_${ind}`}
                >
                  <div className={classes.questionAndImg}>
                    {/* <div className={classes.questionImg}>
                      <img
                        src={
                          item.selectedAnswer !== "" ||
                          item.uploadedImages.length
                            ? correctIcon
                            : unSelect
                        }
                        alt="status icon"
                      />
                    </div> */}
                    <div className={classes.questionNumAndText}>
                      <div className={classes.questionNum}>{`Q${
                        ind + 1
                      }.`}</div>
                      <div className={classes.questionText}>
                        <QuestionAndOptionsElement
                          metaData={item?.question}
                          size="mcqOption"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Dialog>
    </>
  );
};
