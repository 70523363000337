import React, { useState, ChangeEventHandler, useCallback } from 'react'
import Dropzone from "react-dropzone";
import { v4 } from "uuid";
import { Alert } from "@mui/material";

import fileUploadIcon from "../../../../../../../../../assets/images/fileUploadIcon.svg";
import styles from "./questionFileInput.module.scss";
import UploadReference from '../uploadReference/uploadReference';

const ASSIGNMENT_MAX_FILE_SIZE = 10000000;
const ASSIGNMENT_MAX_FILES_ALLOWED = 10;

const FILE_UPLOAD_ERROR_TYPES = {
  MAX_FILE_SIZE: "FILE_SIZE",
  MAX_NO_OF_FILES: "MAX_NO_OF_FILES"
}

interface FILE_ERROR_TYPE {
  errorType: string,
  errorMessage: string
}

const QuestionFileInput = ({ onUpdateInputFiles, uploadedFilesCount }: any) => {

  const [fileUploadError, setFileUploadError] = useState<FILE_ERROR_TYPE | null>(null);
  const [showUploadRefOverlay, setShowUploadRefOverlay] = useState(false);

  const handleCloseSnackbar = () => {
    setFileUploadError(null);
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    handleCloseSnackbar();

    if (uploadedFilesCount >= ASSIGNMENT_MAX_FILES_ALLOWED) {
      setFileUploadError({
        errorType: FILE_UPLOAD_ERROR_TYPES.MAX_NO_OF_FILES,
        errorMessage: `Maximum of ${ASSIGNMENT_MAX_FILES_ALLOWED} files are allowed`
      });
      return
    }

    const file = acceptedFiles[0] || "";
    if (file == "") return;

    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = (event: any) => {
        // acceptedFiles = event.target.result;
        // const binaryStr = reader.result;
      };
      // reader.readAsDataURL(file);
    });

    acceptedFiles.forEach((acceptedFile:any) => {
      acceptedFile.id = v4()
      acceptedFile.preview = URL.createObjectURL(acceptedFile)
    });

    onUpdateInputFiles(acceptedFiles)
  }, []);

  const onDropRejected = (event: any) => {
    setFileUploadError({
      errorType: FILE_UPLOAD_ERROR_TYPES.MAX_FILE_SIZE,
      errorMessage: "The file size should be below 10MB"
    });
  }

  return (
    <div className={styles.questionFileInputMainContainer}>
      <div className={styles.questionFileInputHeadingContainer}>
        <p className={styles.questionFileInputHeading}>
          Upload your answer sheets
        </p>
        <div className={styles.RefLink} onClick={() => { setShowUploadRefOverlay(!showUploadRefOverlay) }}>View Reference</div>
      </div>
      <div className={styles.questionFileInputContainer}>
        <Dropzone
          onDrop={onDrop}
          maxSize={ASSIGNMENT_MAX_FILE_SIZE}
          onDropRejected={onDropRejected}
          multiple={true}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className={styles.questionFileInputContent}>
                <img src={fileUploadIcon} />
                <div className={styles.selectFileTextContainer}>
                  <p className={styles.selectFileText}>
                    Select a file or drag and drop here
                  </p>
                  <p className={styles.fileTypeText}>
                    JPG, PNG, file size no more than 10&nbsp;MB
                  </p>
                </div>
                <div className={styles.selectFileButton}>Select File</div>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
      {
        fileUploadError &&
        <div className={styles.alertContainer}>
          <Alert severity="warning" onClose={handleCloseSnackbar}> {fileUploadError.errorMessage} </Alert>
        </div>
      }
      {
        showUploadRefOverlay &&
        <UploadReference data={{ name: 'Answer sheet upload format' }} closeOverlay={() => { setShowUploadRefOverlay(!showUploadRefOverlay) }} />
      }
    </div>
  )
}

export default QuestionFileInput