export const adminStudentEditFormTemplate = [
  {
    groupName: "Student Details",
    fields: [
      [
        {
          label: "First Name",
          placeholder: "First Name",
          type: "text",
          name: "firstName",
          id: "firstName",
          required: true,
          rules: [],
        },
        {
          label: "Last Name",
          placeholder: "Last Name",
          type: "text",
          name: "lastName",
          id: "lastName",
          required: true,
          rules: [],
        },
      ],
      [
        {
          label: "Gender",
          placeholder: "Gender",
          type: "radio",
          name: "gender",
          id: "gender",
          options: [
            {
              name: "Male",
              value: "male",
              id: "1",
            },
            {
              name: "Female",
              value: "female",
              id: "2",
            },
            {
              name: "others",
              value: "other",
              id: "3",
            },
          ],
          required: true,
          rules: [],
        },

        {
          label: "Roll Number",
          placeholder: "Roll Number",
          type: "number",
          name: "rollNumber",
          id: "rollNumber",
          required: true,
          rules: [],
        },
      ],

      [
        {
          label: "Select Class",
          placeholder: "Select Class",
          type: "select",
          name: "class",
          id: "class",
          options: [
            {
              name: "10",
              value: "10",
              id: "10",
            },
            {
              name: "9",
              value: "9",
              id: "9",
            },
            {
              name: "8",
              value: "8",
              id: "8",
            },
            {
              name: "7",
              value: "7",
              id: "7",
            },
          ],
          required: true,
          rules: [],
        },
        {
          label: "Select Section",
          placeholder: "Select Section",
          type: "select",
          name: "section",
          id: "section",
          options: [
            {
              name: "A",
              value: "A",
              id: "1",
            },
            {
              name: "B",
              value: "B",
              id: "2",
            },
            {
              name: "C",
              value: "C",
              id: "3",
            },
            {
              name: "D",
              value: "D",
              id: "4",
            },
            {
              name: "E",
              value: "E",
              id: "5",
            },
          ],
          required: true,
          rules: [],
        },
      ],
    ],
  },
  {
    groupName: "Parent/Guardian Details",
    fields: [
      [
        {
          label: "First Name",
          placeholder: "First Name",
          type: "text",
          name: "pfirstName",
          id: "pfirstName",
          required: true,
          rules: [],
        },
        {
          label: "Last Name",
          placeholder: "Last Name",
          type: "text",
          name: "plastName",
          id: "plastName",
          required: true,
          rules: [],
        },
      ],
      [
        {
          label: "Primary Email ID*",
          placeholder: "Email",
          type: "email",
          required: true,
          rules: [],
          name: "pprimaryEmail",
          id: "pprimaryEmail",
        },

        {
          label: "Primary Phone Number*",
          placeholder: "Phone",
          type: "tel",
          required: true,
          rules: [],
          name: "pprimaryPhone",
          id: "pprimaryPhone",
        },
      ],
      [
        {
          label: "Relationship",
          placeholder: "Relationship",
          type: "radio",
          name: "relationship",
          id: "relationship",
          options: [
            {
              name: "Father",
              value: "Father",
              id: "1",
            },
            {
              name: "Mother",
              value: "Mother",
              id: "2",
            },
            {
              name: "Others",
              value: "Other",
              id: "3",
            },
          ],
          required: true,
          rules: [],
        },
      ],
    ],
  },
];

export const editTeacherFormTemplate = [
  {
    groupName: "Teacher Details",
    fields: [
      [
        {
          label: "First Name",
          placeholder: "First Name",
          type: "text",
          required: true,
          rules: [],
          name: "firstName",
          id: "firstName",
        },

        {
          label: "Last Name",
          placeholder: "Last Name",
          type: "text",
          required: true,
          rules: [],
          name: "lastName",
          id: "lastName",
        },
      ],
      [
        {
          label: "Primary Email ID*",
          placeholder: "Email",
          type: "email",
          required: true,
          rules: [],
          name: "primaryEmail",
          id: "primaryEmail",
        },

        {
          label: "Primary Phone Number*",
          placeholder: "Phone",
          type: "tel",
          required: true,
          rules: [],
          name: "primaryPhone",
          id: "primaryPhone",
        },
      ],
      [
        {
          label: "Gender",
          placeholder: "Gender",
          type: "radio",
          name: "gender",
          id: "gender",
          options: [
            {
              name: "Male",
              value: "male",
              id: "male",
            },
            {
              name: "Female",
              value: "female",
              id: "female",
            },
            {
              name: "others",
              value: "others",
              id: "others",
            },
          ],
          required: true,
          rules: [],
        },
      ],
      [
        {
          label: "Select Class",
          placeholder: "Select Class",
          type: "select",
          name: "class",
          id: "class",
          options: [
            {
              name: "10",
              value: "10",
              id: "10",
            },
            {
              name: "9",
              value: "9",
              id: "9",
            },
            {
              name: "8",
              value: "8",
              id: "8",
            },
            {
              name: "7",
              value: "7",
              id: "7",
            },
          ],
          required: true,
          rules: [],
        },
        {
          label: "Select Section",
          placeholder: "Select Section",
          type: "select",
          name: "section",
          id: "section",
          options: [
            {
              name: "A",
              value: "A",
              id: "1",
            },
            {
              name: "B",
              value: "B",
              id: "2",
            },
            {
              name: "C",
              value: "C",
              id: "3",
            },
            {
              name: "D",
              value: "D",
              id: "4",
            },
            {
              name: "E",
              value: "E",
              id: "5",
            },
          ],
          required: true,
          rules: [],
        },
      ],
    ],
  },
];

export const editMathTeacherFormTemplate = [
  {
    groupName: "Class Teacher Details",
    fields: [
      [
        {
          label: "First Name",
          placeholder: "First Name",
          type: "text",
          required: true,
          rules: [],
          name: "mfirstName",
          id: "mfirstName",
        },

        {
          label: "Last Name",
          placeholder: "Last Name",
          type: "text",
          required: true,
          rules: [],
          name: "mlastName",
          id: "mlastName",
        },
      ],
      [
        {
          label: "Primary Email ID*",
          placeholder: "Email",
          type: "email",
          required: true,
          rules: [],
          name: "mprimaryEmail",
          id: "mprimaryEmail",
        },

        {
          label: "Primary Phone Number*",
          placeholder: "Phone",
          type: "tel",
          required: true,
          rules: [],
          name: "mprimaryPhone",
          id: "mprimaryPhone",
        },
      ],
      [
        {
          label: "Gender",
          placeholder: "Gender",
          type: "radio",
          name: "mgender",
          id: "mgender",
          options: [
            {
              name: "Male",
              value: "Male",
              id: "1",
            },
            {
              name: "Female",
              value: "Female",
              id: "2",
            },
            {
              name: "others",
              value: "others",
              id: "3",
            },
          ],
          required: true,
          rules: [],
        },
        {
          label: "Teacher Role",
          placeholder: "Teacher Role",
          type: "select",
          name: "mteacherRole",
          id: "mteacherRole",
          options: [
            {
              name: "Class Teacher",
              value: "Class Teacher",
              id: "Class Teacher",
            },
            {
              name: "Math Teacher",
              value: "Math Teacher",
              id: "Math Teacher",
            },
          ],
          required: true,
          rules: [],
        },
      ],
      [
        {
          label: "Select Class",
          placeholder: "Select Class",
          type: "select",
          name: "mclass",
          id: "mclass",
          options: [
            {
              name: "10",
              value: "10",
              id: "10",
            },
            {
              name: "9",
              value: "9",
              id: "9",
            },
            {
              name: "8",
              value: "8",
              id: "8",
            },
            {
              name: "7",
              value: "7",
              id: "7",
            },
          ],
          required: true,
          rules: [],
        },
        {
          label: "Select Section",
          placeholder: "Select Section",
          type: "select",
          name: "msection",
          id: "msection",
          options: [
            {
              name: "A",
              value: "A",
              id: "1",
            },
            {
              name: "B",
              value: "B",
              id: "2",
            },
            {
              name: "C",
              value: "C",
              id: "3",
            },
            {
              name: "D",
              value: "D",
              id: "4",
            },
            {
              name: "E",
              value: "E",
              id: "5",
            },
          ],
          required: true,
          rules: [],
        },
      ],
    ],
  },
];

export const addTeacherFormTemplate = [
  {
    groupName: "Teacher Details",
    fields: [
      [
        {
          label: "First Name",
          placeholder: "First Name",
          type: "text",
          required: true,
          rules: [],
          name: "firstName",
          id: "firstName",
        },

        {
          label: "Last Name",
          placeholder: "Last Name",
          type: "text",
          required: true,
          rules: [],
          name: "lastName",
          id: "lastName",
        },
      ],
      [
        {
          label: "Primary Email ID*",
          placeholder: "Email",
          type: "email",
          required: true,
          rules: [],
          name: "primaryEmail",
          id: "primaryEmail",
        },

        {
          label: "Primary Phone Number*",
          placeholder: "Phone",
          type: "tel",
          required: true,
          rules: [],
          name: "primaryPhone",
          id: "primaryPhone",
        },
      ],
      [
        {
          label: "Gender",
          placeholder: "Gender",
          type: "radio",
          name: "gender",
          id: "gender",
          options: [
            {
              name: "Male",
              value: "male",
              id: "male",
            },
            {
              name: "Female",
              value: "female",
              id: "female",
            },
            {
              name: "others",
              value: "others",
              id: "others",
            },
          ],
          required: true,
          rules: [],
        },
      ],

      [
        {
          label: "Select Class",
          placeholder: "Select Class",
          type: "select",
          name: "class",
          id: "class",
          options: [
            {
              name: "10",
              value: "10",
              id: "10",
            },
            {
              name: "9",
              value: "9",
              id: "9",
            },
            {
              name: "8",
              value: "8",
              id: "8",
            },
            {
              name: "7",
              value: "7",
              id: "7",
            },
          ],
          required: true,
          rules: [],
        },
        {
          label: "Select Section",
          placeholder: "Select Section",
          type: "select",
          name: "section",
          id: "section",
          options: [
            {
              name: "A",
              value: "A",
              id: "1",
            },
            {
              name: "B",
              value: "B",
              id: "2",
            },
            {
              name: "C",
              value: "C",
              id: "3",
            },
            {
              name: "D",
              value: "D",
              id: "4",
            },
            {
              name: "E",
              value: "E",
              id: "5",
            },
          ],
          required: true,
          rules: [],
        },
      ],
    ],
  },
];
export const addClassFormTemplate = (
  classOption,
  sectionOption,
  teacherOption
) => [
  {
    groupName: "Fill the following details to create a new Class",
    fields: [
      [
        {
          label: "Select the class standard",
          placeholder: "Select the class standard",
          type: "radio",
          name: "classStandard",
          id: "classStandard",
          options: classOption || [
            {
              name: "6th",
              value: 6,
              id: 6,
            },
            {
              name: "7th",
              value: 7,
              id: 7,
            },
            {
              name: "8th",
              value: 8,
              id: 8,
            },
            {
              name: "9th",
              value: 9,
              id: 9,
            },
            {
              name: "10th",
              value: 10,
              id: 10,
            },
            {
              name: "11th",
              value: 11,
              id: 11,
            },
            {
              name: "12th",
              value: 12,
              id: 12,
            },
          ],
          required: true,
          rules: [],
        },
      ],
      [
        {
          label: "Select Section",
          placeholder: "Select Section",
          type: "select",
          name: "section",
          id: "section",
          options: sectionOption || [
            {
              name: "A",
              value: "A",
              id: 1,
            },
            {
              name: "B",
              value: "B",
              id: 2,
            },
            {
              name: "C",
              value: "C",
              id: 3,
            },
            {
              name: "D",
              value: "D",
              id: 4,
            },
            {
              name: "E",
              value: "E",
              id: 5,
            },
          ],
          required: true,
          rules: [],
        },
        {
          label: "Batch",
          placeholder: "Batch",
          type: "select",
          name: "batch",
          id: "batch",
          options: [
            {
              name: "2022-23",
              value: "2022-23",
              id: 1,
            },
            {
              name: "2023-24",
              value: "2023-24",
              id: 2,
            },
            {
              name: "2024-25",
              value: "2024-25",
              id: 3,
            },
          ],
          required: true,
          rules: [],
        },
      ],

      [
        {
          label: "Assign Teacher",
          placeholder: "Assign Teacher",
          type: "select",
          name: "teacher",
          id: "teacher",
          options: teacherOption || [
            {
              name: "Jane Smith",
              value: "Jane Smith",
              id: 2,
            },
            {
              name: "Alice Johnson",
              value: "Alice Johnson",
              id: 3,
            },
          ],
          required: true,
          rules: [],
        },
      ],
    ],
  },
];
