import React, { useEffect, useState } from "react";
import styles from "./classDetailsStudent.module.scss";
import SearchBar from "../../../../../common/searchBar/searchBar";
import ClassDetailsStudentCard from "../../components/classDetailsStudentCard/classDetailsStudentCard";
import usePagination from "../../../../../utils/customPagination";
import { constants } from "../../../../../constants/constants";
import { CustomPagination } from "../../../../../common/pagination/customPagination";
import { useSearch } from "../../../../../utils/searchBar";
import { useStudentListDataWithLevel } from "../../../../../utils/studentListDataWithLevel";

const ClassDetailsStudent = ({ detailedViewRef }: any) => {
  const { massagedStudentsDetailsList, studentsDetailsListTotalData } =
    useStudentListDataWithLevel();
  const range = 5;
  const { page, paginationStart, paginationEnd, handlePaginationChange } =
    usePagination(massagedStudentsDetailsList, range, detailedViewRef);
  const [searchText, setSearchText] = useState("");
  const [filteredTopics, getSearchedData] = useSearch(
    massagedStudentsDetailsList
  );

  useEffect(() => {
    getSearchedData(searchText, "name");
  }, [searchText]);

  return (
    <div className={styles.studentTopicsContainer}>
      <div className={styles.HeaderAndSearchWrap}>
        <div className={styles.topicsHeaderText}>
          {constants.CLASS_STUDENTS_TITLE}
        </div>
        <div>
          <SearchBar searchText={searchText} setSearchText={setSearchText} />
        </div>
      </div>
      {filteredTopics &&
        filteredTopics
          ?.slice(paginationStart, paginationEnd)
          .map((item: any, ind: number) => (
            <ClassDetailsStudentCard
              item={item}
              key={`${item.name}_${ind}`}
              studentsDetailsListTotalData={studentsDetailsListTotalData}
            />
          ))}
      {filteredTopics?.length > 0 && (
        <CustomPagination
          detailedViewRef={detailedViewRef}
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(filteredTopics?.length / range)}
        />
      )}
    </div>
  );
};

export default ClassDetailsStudent;
