import Dropdown from "../../../../../common/dropdown/dropdown";
import GoBackPage from "../../../../../common/goBackPage/goBackPage";
import { CustomPagination } from "../../../../../common/pagination/customPagination";
import AnalyticsChart from "../../../../learn/components/analyticsChart/analyticsChart";
import MyStatsQuestionCard from "../../../../myStats/components/myStatsQuestionCard/myStatsQuestionCard";
import ChapterProgressCard from "../../../../../common/chapterProgressCard/chapterProgressCard";
import styles from "./chapterProgressPage.module.scss";
import { useContext, useEffect, useRef, useState } from "react";
import StatusContext from "../../../../../context/loaderContext";
import { makeAxiosRequest } from "aie_common/dist/httpService/httpService";
import ApiEdpoints, {
  HTTPMethods,
} from "../../../../../constants/api_endpoints";
import { getUpdatedChapterProgressCardData } from "../../../../../utils/teacherPersonalMassagedData";
import { useParams } from "react-router-dom";
import { getAnalyticsChartConfig } from "../../../../../utils/analyticsGraphConfig";
import { calculateTimeTaken } from "../../../../../utils/convertTime";
import useSorting from "../../../../../utils/useSorting";

interface IQuestion {
  question: string;
  pattens_included: string[];
  AI_help_used_count: string[] | number[];
  Attempted_count: number;
  Capability: number;
  id: number;
  level: number;
  question_id: number;
  question_img: null;
  solved_count: number;
  subtopic_id: number;
  time_taken: number;
  timegiven: number;
  topic_id: number;
}

const ChapterProgressPage = () => {
  const loaderStatus: any = useContext(StatusContext);
  const [chapterProgressCardData, setChapterProgressCardData] = useState<any>(
    []
  );
  const [updatedChapterProgressCardData, setUpdatedChapterProgressCardData] =
    useState<any>({});
  const [topicProgressData, setTopicProgressData] = useState([]);
  const [topicProgressAnalyticsGraphData, setTopicProgressAnalyticsGraphData] =
    useState<any>([]);
  const [questionsForClassProgress, setQuestionsForClassProgress] =
    useState<any>([]);
  const { classId, chapterId } = useParams();
  const chapterProgressRef: any = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState<any>([]);
  const [dropdownArray, setDropdownArray] = useState([]);
  const [chapterDropDownValueWithId, setChapterDropDownValueWithId] =
    useState<any>({});
  const [levelDropDownValueWithId, setLevelDropDownValueWithId] = useState<any>(
    {}
  );
  const [dropdownTopics, setDropdownTopics] = useState<any>([]);
  const [totalDropdownContainer, setTotalDropdownContainer] = useState<any>([]);
  const [finalFilteration, setFinalFilteration] = useState<any>([]);
  const [correctionDropDownValueWithId, setCorrectionDropDownValueWithId] =
    useState<any>({});
  const params = useParams();
  const fetchChapterProgressCardData = async (
    classId: string,
    chapterId: string
  ) => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.CLASS_ANALYTICS}/?class_id=${classId}&chapter_id=${chapterId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      setChapterProgressCardData(res?.data?.data);
      loaderStatus.setState({ show: false });
      return res;
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const fetchTopicProgressAnalyticsChart = async (
    classId: any,
    chapterId: any
  ) => {
    // Fetch each Chapters Topics Analytics Graph Data
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.PROGRESS_ANALYTICS}/?class_id=${classId}&chapter_id=${chapterId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      loaderStatus.setState({ show: false });
      setTopicProgressData(res?.data?.data);
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const updatetopicProgressDataForChart = () => {
    const chaptersTopicsAnalyticsData = topicProgressData;
    const ANALYTICS_GRAPH_DATA = getAnalyticsChartConfig(
      topicProgressData?.length > 4 ? 4 : topicProgressData?.length
    );
    let competency: any = chaptersTopicsAnalyticsData?.map(
      (item: any) => item?.competency
    );
    let mastery: any = chaptersTopicsAnalyticsData?.map(
      (item: any) => item?.mastery
    );
    ANALYTICS_GRAPH_DATA.datasets[0].data = mastery;
    ANALYTICS_GRAPH_DATA.datasets[1].data = competency;
    setTopicProgressAnalyticsGraphData({
      labels: chaptersTopicsAnalyticsData?.map((item: any) => item?.topic_name),
      datasets: [...ANALYTICS_GRAPH_DATA?.datasets],
      axisLabels: {
        xAxis: "Topics",
        yAxis: "Levels",
      },
    });
  };
  const fetchQuestionsForClassProgress = async (
    classId: string,
    chapterId: string
  ) => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.LIST_OF_QUESTIONS_MENTOR}/?class_id=${classId}&chapter_id=${chapterId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      loaderStatus.setState({ show: false });
      setQuestionsForClassProgress(res?.data?.data);
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };
  const fetchTopicsDropDown = async (topicId: string | number) => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.GET_SUBTOPICS}/?topicid=${topicId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      loaderStatus.setState({ show: false });
      const topicsData =
        res?.data?.data?.length > 0
          ? res?.data?.data.reduce(
              (arr: [{}], obj: any) => {
                arr.push({ name: obj.name, id: obj.id });
                return arr;
              },
              [{ name: "All Topics", id: 0 }]
            )
          : [];

      const levelsData = [
        { name: "All Levels", id: 0 },
        { name: "Easy", id: 1 },
        { name: "Medium", id: 2 },
        { name: "Hard", id: 3 },
      ];
      const correction = [
        { name: "All Questions", id: 0 },
        { name: "Most Incorrect", id: 1 },
        { name: "Most Correct", id: 2 },
      ];
      const totalData = [
        { data: topicsData, defaultLabel: "All Topics" },
        { data: levelsData, defaultLabel: "All Levels" },
        { data: correction, defaultLabel: "All Questions" },
      ];
      setDropdownTopics(totalData);
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTopicsDropDown(params?.chapterId ?? "");
  }, []);

  useEffect(() => {
    classId && chapterId && fetchQuestionsForClassProgress(classId, chapterId);
  }, []);

  useEffect(() => {
    classId && chapterId && fetchChapterProgressCardData(classId, chapterId);
    classId &&
      chapterId &&
      fetchTopicProgressAnalyticsChart(classId, chapterId);
  }, []);

  useEffect(() => {
    if (chapterProgressCardData.length !== 0) {
      setUpdatedChapterProgressCardData(
        getUpdatedChapterProgressCardData(chapterProgressCardData[0])
      );
    }
  }, [chapterProgressCardData]);

  useEffect(() => {
    topicProgressData.length && updatetopicProgressDataForChart(); //Get configuration Data
  }, [topicProgressData]);

  useEffect(() => {
    chapterProgressRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [chapterProgressRef]);

  const dataMassagingForQuestionsForClassProgress = () => {
    const questionsForClassProgressData = questionsForClassProgress?.map(
      (x: any, i: number) => {
        return {
          index: i,
          aiHelpCount: x?.AI_help_used ? x?.AI_help_used : 0,
          AttemptedCount: x?.attempt_count,
          Capability: x?.capability,
          difficultyLevel: x?.difficulty_level,
          id: x?.id,
          level: x?.difficulty_level === null ? 0 : x?.difficulty_level,
          patternsIncluded: x?.patterns.length > 0 ? x?.patterns : [],
          questionImg: x?.question_img,
          question: x?.question_text,
          solvedCount: x?.solved_count,
          timeGiven: calculateTimeTaken(x?.timegiven),
          time_taken: calculateTimeTaken(x?.timetaken),
          topicId: x?.topic_id ? x?.topic_id : 0,
          questionId: x?.topic_id,
        };
      }
    );
    return questionsForClassProgressData;
  };

  const {
    dropdownQuestionsList,
    dropdownSelectedValueWithId,
    setDropdownSelectedValueWithId,
    filteredTopics,
    page,
    paginationStart,
    paginationEnd,
    handlePaginationChange,
    range,
  } = useSorting(
    questionsForClassProgress,
    selectedStatus,
    "",
    "",
    dataMassagingForQuestionsForClassProgress,
    5,
    dropdownArray
  );

  useEffect(() => {
    if (dropdownSelectedValueWithId) {
      if (dropdownSelectedValueWithId?.defaultLabel === "All Topics") {
        setChapterDropDownValueWithId(dropdownSelectedValueWithId);
      } else if (dropdownSelectedValueWithId?.defaultLabel === "All Levels") {
        setLevelDropDownValueWithId(dropdownSelectedValueWithId);
      } else if (
        dropdownSelectedValueWithId?.defaultLabel === "All Questions"
      ) {
        setCorrectionDropDownValueWithId(dropdownSelectedValueWithId);
      }
    }
  }, [dropdownSelectedValueWithId]);
  useEffect(() => {
    let arr: any = [];
    if (dropdownTopics) {
      setTotalDropdownContainer(dropdownTopics ? dropdownTopics : []);
    }
    if (totalDropdownContainer && totalDropdownContainer.length > 0) {
      totalDropdownContainer.map((x: any) => {
        if (x?.defaultLabel === chapterDropDownValueWithId?.defaultLabel) {
          arr.push({ ...x, chapterDropDownValueWithId });
        } else if (x?.defaultLabel === levelDropDownValueWithId?.defaultLabel) {
          arr.push({ ...x, levelDropDownValueWithId });
        } else if (
          x?.defaultLabel === correctionDropDownValueWithId?.defaultLabel
        ) {
          arr.push({ ...x, correctionDropDownValueWithId });
        }
      });
    }
    setDropdownArray(arr);
  }, [
    chapterDropDownValueWithId,
    levelDropDownValueWithId,
    dropdownTopics,
    correctionDropDownValueWithId,
  ]);
  return (
    <div className={styles.container}>
      <GoBackPage heading="Chapter Progress" />

      {chapterProgressCardData.length !== 0 && (
        <ChapterProgressCard
          chapterProgressDetails={updatedChapterProgressCardData}
        />
      )}
      {topicProgressData.length !== 0 && (
        <div className={styles.chartBox}>
          <div className={styles.headerContainerProgress}>
            <div className={styles.chartHeading}>Topic Progress</div>
          </div>
          <AnalyticsChart
            height={311}
            analyticsData={topicProgressAnalyticsGraphData}
            axisLabels={topicProgressAnalyticsGraphData.axisLabels}
          />
        </div>
      )}

      <div className={styles.headerContainer} ref={chapterProgressRef}>
        <div className={styles.chartHeading}>
          All the questions for this chapter and your class’s progress on them
        </div>
        {dropdownTopics && dropdownTopics.length > 0 && (
          <div className={styles.dropdownContainer}>
            <Dropdown
              options={dropdownTopics ? dropdownTopics[0]?.data : []}
              defaultLabel="All Topics"
              minWidth="177px"
              selectedValue="All Topics"
              setDropdownSelectedValueWithId={setDropdownSelectedValueWithId}
            />
            <Dropdown
              options={dropdownTopics ? dropdownTopics[1]?.data : []}
              defaultLabel="All Levels"
              minWidth="177px"
              selectedValue="All Levels"
              setDropdownSelectedValueWithId={setDropdownSelectedValueWithId}
            />
            <Dropdown
              options={dropdownTopics ? dropdownTopics[2]?.data : []}
              defaultLabel="All Questions"
              selectedValue="All Questions"
              minWidth="177px"
              setDropdownSelectedValueWithId={setDropdownSelectedValueWithId}
            />
          </div>
        )}
      </div>
      <div className={styles.chapterCardsContainer}>
        {filteredTopics &&
          filteredTopics?.length > 0 &&
          filteredTopics
            ?.slice(paginationStart, paginationEnd)
            ?.map((each: IQuestion, index: number) => (
              <MyStatsQuestionCard
                data={each}
                statsCoveredName="Patterns"
                index={index + 1}
              />
            ))}
        {filteredTopics?.length === 0 && "No Data found"}
      </div>
      {filteredTopics.length > 0 && (
        <CustomPagination
          detailedViewRef={chapterProgressRef}
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(filteredTopics?.length / range)}
        />
      )}
    </div>
  );
};

export default ChapterProgressPage;
