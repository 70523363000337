import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import cancelIcon from '../../assets/images/cross.svg';
import styles from './modalWrapper.module.scss';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 826,
    height: 500,
    bgcolor: 'red',
    border: "none",
    boxShadow: 2,
    padding: "20px 30px",
    background: "linear-gradient(104.87deg, #F9FBFD 11.37%, #F8FAFF 87.96%)",
    borderRadius: "16px"
};

export const BasicModal = (props: any) => {
    const { openModal, setOpenModal, question } = props;
    const [open, setOpen] = React.useState(openModal);
    const handleClose = () => {
        setOpen(false);
        setOpenModal(false);
    };
    return (
        <div >
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={styles.container1}>
                        <div
                            className={styles.textStyles}
                        > {question.id}. {question.ans} </div>
                        <img onClick={handleClose} src={cancelIcon} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className={styles.verticleLine}></div>
                    <div className={styles.imageContainer}>
                        <img className={styles.imageStyles} src={question.ansimage} alt="questionImage" />
                    </div>
                </Box>
            </Modal>
        </div >
    );
}
