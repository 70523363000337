import React, { useState, useLayoutEffect, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import StudentDetailsAndPerformance from "./components/studentDetailsAndPerformance/studentDetailsAndPerformance";
import styles from "./studentDetailsOverview.module.scss";
import PatternListing from "../../../../learn/components/patternListing/patternListing";
import carryingBooksAvatar from "../../../../../assets/images/carryingBooksAvatar.svg";
import AnalyticsChart from "../../../../learn/components/analyticsChart/analyticsChart";
import StudentSubmissionCards from "./components/studentSubmissionCards/studentSubmissionCards";
import { constants } from "../../../../../constants/constants";
import StatusContext from "../../../../../context/loaderContext";
import arrowGreen from "../../../../../assets/images/arrowGreen.svg";
import ApiEdpoints, {
  HTTPMethods,
} from "../../../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import { useParams } from "react-router-dom";
import PatternVideoOverlayContainer from "../../../../../common/patternVideoOverlayContainer/patternVideoOverlayContainer";
import useOverlayDetails from "../../../../../utils/patternDetailsApi";
import { getAnalyticsChartConfig } from "../../../../../utils/analyticsGraphConfig";

const labels = [
  "Test 1.1",
  "Test 1.2",
  "Test 1.3",
  "Test 1.4",
  "Test 1.5",
  "Test 1.6",
  "Test 1.7",
  "Test 1.8",
  "Test 1.9",
];
const analyticsData = {
  labels,
  datasets: [
    {
      label: "",
      data: [1, 3, 4, 2, 4, 5, 4, 3, 5],
      backgroundColor: "#9AAAFF",
      stack: "Stack 1",
      borderRadius: 4,
      barPercentage: 0.4,
      categoryPercentage: 0.4,
    },
  ],
};

const StudentDetailsOverView = ({
  getIndexToViewAllAssignments,
  recentSubmissions,
}: any) => {
  const { classId, studentId } = useParams();

  const loaderStatus: any = useContext(StatusContext);
  const [screenWidth, setScreenWidth] = useState(0);
  const dates = constants.GRAPH_TIME_FILTER_OPTIONS.map(
    (x: any, ind: number) => ({ name: x, id: ind + 1 })
  );
  const [studentDetailsWithPatterns, setStudentDetailsWithPatterns] =
    useState<any>([]);
  const [chartDatesOptions, setChartDatesOptions] = useState(dates);
  const [filteredValues, updatedFilteredValues] = useState([]);
  const [patternToFocus, setPatternToFocus] = useState<any>([]);
  const isTabOpen = useSelector(
    (state: any) => state?.tabReducer?.tabOpen ?? true
  );
  const [videoNotesOverlay, setVideoNotesOverlay] = useState(false);
  const { patternOverlayData, getOverlayDetails }: any = useOverlayDetails();
  const [videoNotesOverlayContent, setVideoNotesOverlayContent] =
    useState("pattern");
  const handlePatternVideoOverlayContent = (
    resource: "viewDocument" | "pattern" | "patternMap"
  ) => {
    setVideoNotesOverlayContent(resource);
  };
  const [patternDetailsId, setPatternDetailsId] = useState(0);
  const handlePatternVideoOverlay = (type: any) => {
    setVideoNotesOverlay(!videoNotesOverlay);
    setVideoNotesOverlayContent("pattern");
  };
  const [studentStudyProgressData, setStudentStudyProgressData] = useState<any>(
    []
  );
  const [updatedStudentAnalyticsData, setUpdatedStudentAnalyticsData] =
    useState<any>([]);

  useLayoutEffect(() => {
    function updateSize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  const fetchStudentDetailsAndPatternsToFocus = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.GET_STUDENT_DETAILS_AND_PATTERNS_TO_FOCUS}?class_id=${classId}&student_id=${studentId}`,
      data: {},
      method: HTTPMethods.GET,
    };
    try {
      const response = await makeAxiosRequest(payload);
      if (response.status === 200) {
        loaderStatus.setState({ show: false });
        setStudentDetailsWithPatterns({
          studentName:
            response?.data?.data[0]?.userDetails[0]?.username ?? "N/A",
          rollNumber:
            response?.data?.data[0]?.userDetails[0]?.rollnumber ?? "N/A",
          photo: response?.data?.data[0]?.userDetails[0]?.photo ?? "",
          phoneNumber:
            response?.data?.data[0]?.userDetails[0]?.phone_number ?? "N/A",
          emailId: response?.data?.data[0]?.userDetails[0]?.email ?? "N/A",
          topicsCompleted: response?.data?.data[0]?.topics_completed ?? 0,
          assignmentCompleted:
            response?.data?.data[0]?.Assignments_completed ?? 0,
          averageScore: response?.data?.data[0]?.Avg_score ?? 0,
          totalStudyTime: response?.data?.data[0]?.total_study_time ?? 0,
          progressLevel: response?.data?.data[0]?.progress_level * 20 ?? 0,
          patternDetails: response?.data?.data[0]?.pattern_details ?? [],
        });
      }
    } catch (error) {
      console.log("error", error);
      loaderStatus.setState({ show: false });
    }
  };

  const fetchStudentDetailsStudyProgressData = async (
    classId: any,
    studentId: any
  ) => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.STUDENT_ANALYTICS}?class_id=${classId}&student_id=${studentId}`,
      data: {},
      method: HTTPMethods.GET,
    };
    try {
      const response = await makeAxiosRequest(payload);
      setStudentStudyProgressData(response?.data?.data);
    } catch (error) {
      console.log("error", error);
      loaderStatus.setState({ show: false });
    }
  };
  useEffect(() => {
    fetchStudentDetailsAndPatternsToFocus();
    classId &&
      studentId &&
      fetchStudentDetailsStudyProgressData(classId, studentId);
  }, [classId, studentId]);

  const dataMassaging = (data: any) => {
    const patternTobeFocused = data?.map((item: any) => {
      return {
        id: item?.id,
        level:
          item?.level && item?.level === 1
            ? "Primary"
            : item?.level === 2
            ? "Secondary"
            : item?.level === 3
            ? "Tertiary"
            : "",
        patternName: item?.name,
        patternDescription: item?.description,
        patternStrength: item?.pattern_mastery,
      };
    });
    return patternTobeFocused;
  };

  const massageStudentProgressAnalyticsData = (
    studentStudyProgressData: any
  ) => {
    const studyProgressGraphData = getAnalyticsChartConfig(
      studentStudyProgressData?.length > 4
        ? 4
        : studentStudyProgressData?.length
    );
    // TODO: mastery is not coming so need to fix it when it comes

    let mastery: any = studentStudyProgressData?.map((item: any) => 2);
    studyProgressGraphData.datasets[0].data = mastery;
    setUpdatedStudentAnalyticsData({
      labels: studentStudyProgressData.map((item: any) => item?.chapter_name),
      datasets: [...studyProgressGraphData?.datasets],
    });
  };

  useEffect(() => {
    if (
      studentDetailsWithPatterns &&
      studentDetailsWithPatterns?.patternDetails &&
      studentDetailsWithPatterns?.patternDetails?.length > 0
    ) {
      const data = dataMassaging(studentDetailsWithPatterns?.patternDetails);
      setPatternToFocus(data);
    }
  }, [studentDetailsWithPatterns]);

  useEffect(() => {
    massageStudentProgressAnalyticsData(studentStudyProgressData);
  }, [studentStudyProgressData]);

  return (
    <div className={styles.studentDetailsContainer}>
      <StudentDetailsAndPerformance studentData={studentDetailsWithPatterns} />
      <div>
        {recentSubmissions && recentSubmissions.length > 0 && (
          <>
            <div className={styles.recentSubmissionsWrap}>
              <div className={styles.recentSubmissionText}>
                {constants.RECENT_SUBMISSIONS}
              </div>
              <div
                className={styles.viewAllWrap}
                onClick={() => getIndexToViewAllAssignments(1)}
              >
                <div className={styles.viewAllText}>{constants.VIEW_ALL}</div>
                <div>
                  <img src={arrowGreen} />
                </div>
              </div>
            </div>

            {recentSubmissions.slice(0, 2)?.map((item: any, ind: number) => (
              <StudentSubmissionCards
                item={item}
                key={`sudentsubmissioncards_${ind}`}
              />
            ))}
          </>
        )}

        {patternToFocus && patternToFocus.length > 0 && (
          <div className={styles.patternsContainer}>
            <div className={styles.patternTitle}>
              {constants.PATTERNS_TO_FOCUS}
            </div>
            <div className={styles.patternsCardsContainer}>
              <div>
                <PatternListing
                  handlePatternVideoOverlay={handlePatternVideoOverlay}
                  getOverlayDetails={getOverlayDetails}
                  patternList={patternToFocus}
                  getPatternDetailsId={setPatternDetailsId}
                  fromPatternListPage={true}
                />
              </div>
              {!isTabOpen && screenWidth === 1440 && (
                <div className={styles.carryingBooksAvatarIcon}>
                  <img src={carryingBooksAvatar} alt="carryingBooksAvatar" />
                </div>
              )}
            </div>
          </div>
        )}

        <div className={styles.testPerformanceContainer}>
          <div
            className={styles.textPerfomanceText}
            style={{
              paddingTop: recentSubmissions.length === 0 ? "30px" : "",
            }}
          >
            {constants.TEST_PERFORMANCE}
          </div>
          <div>
            <AnalyticsChart
              axisLabels={{
                xAxis: "Tests",
                yAxis: "Average Score",
              }}
              height={298}
              analyticsData={analyticsData}
              showDropDownFilter={true}
              chartFilterWidth={"150px"}
              chartFilterOptions={chartDatesOptions}
              chartFilterDefaultLabel={"Select date"}
              filteredSelectedValues={updatedFilteredValues}
              isTestPerformanceChart={true}
              maxItems={10}
              type={"tests"}
            />
          </div>
        </div>
      </div>

      <div className={styles.studyProgress}>
        <div className={styles.studyProgressText}>
          {constants.STUDY_PROGRESS}
        </div>
        <div>
          <AnalyticsChart
            axisLabels={{
              xAxis: "Chapters",
              yAxis: "Levels",
            }}
            analyticsData={updatedStudentAnalyticsData}
          />
        </div>
      </div>
      {videoNotesOverlay && (
        <PatternVideoOverlayContainer
          videoUrl={patternOverlayData?.video}
          docName={patternOverlayData?.patternname}
          patternCardData={{
            descriptionText: patternOverlayData?.description,
            patternHeadingText: patternOverlayData?.patternname,
          }}
          handlePatternVideoOverlayContent={handlePatternVideoOverlayContent}
          handlePatternOverlay={handlePatternVideoOverlay}
          videoNotesOverlayContent={videoNotesOverlayContent}
          pptUrl={patternOverlayData?.ppt}
          patternExamples={patternOverlayData?.pattern_examples}
        />
      )}
    </div>
  );
};

export default StudentDetailsOverView;
