import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useContext } from 'react';
import StatusContext from '../../context/loaderContext';

const Loader = () => {

    const { state }: any = useContext(StatusContext);
    return (
        <div>
            {state?.show && <Box sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: 1000,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0,0,0,.3)',
            }}>
                <CircularProgress />
            </Box>}
        </div>
    )
};

export default Loader;