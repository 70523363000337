import React from "react";
import styles from "./teachersDetailsCard.module.scss";
import trash from "../../../assets/images/Trash.svg";
import pencil from "../../../assets/images/Pencil.svg";

const TeachersDetailsCard = ({ teachersName }: any) => {
    return (
        <div className={styles.teachersDetailsCard}>
            <div className={styles.c1}>
                <div className={styles.block}>
                    <h1>
                        Class Teacher
                        <span>
                            <img
                                src={pencil}
                                alt=""
                            />
                            <a href="">Edit</a>
                        </span>
                    </h1>
                    <p>{teachersName.classTeacher}</p>
                </div>
                <div className={styles.block}>
                    <h1>
                        Maths Teacher
                        <span>
                            <img
                                src={pencil}
                                alt=""
                            />
                            <a href="">Edit</a>
                        </span>
                    </h1>
                    <p>{teachersName.mathsTeacher}</p>
                </div>
            </div>
            <div className={styles.c2}>
                <a href="">
                    <img
                        src={trash}
                        alt=""
                    />
                    Remove Section
                </a>
            </div>
        </div>
    );
};

export default TeachersDetailsCard;
