import React from "react";
import style from "./classesBlockList.module.scss";

const ClassesBlockList = ({ listData }: any) => {
  return (
    <div className={style.list}>
      <div className={style.c1}>
        <h1>Class</h1>
        <p>{listData.class}</p>
      </div>
      <div className={style.c2}>
        <h1>Total Students</h1>
        <p>{listData.totalStudent}</p>
      </div>
      <div className={style.c3}>
        <h1>Sections</h1>
        <div className={style.block}>
          <div className={style.section}>
            {listData.sections ? (
              listData.sections.map((section: string) => {
                return <p key={section}>{section}</p>;
              })
            ) : (
              <>
                <p>A</p>
                <p>B</p>
                <p>C</p>
                <p>D</p>
                <p>E</p>
              </>
            )}
          </div>
          {listData.url && (
            <div className="link">
              <a href={listData.url}>View All</a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClassesBlockList;
