import { constants } from "../../constants/constants";
const contentsMockData = [
    {
        contentName: 'introduction to numbers',
        contentId: 1,
        url: "https://storage-aieducator.s3.ap-south-1.amazonaws.com/mnist/Articles/Class - 8, Ex - 1.2.mp4",
        documentUrl: '',
        mcqs: [
            {
                question: 'what is python',
                answer: 'a',
                options: ['backend', 'frontwnd', 'devops']
            }
        ],
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
        patterns: [
            { skill: 'weakkkk', patternId: 23, pattern: 'new pattern dataa', src: 'sdsd', description: 'to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more ' },
            { skill: 'ok', patternId: 24995, pattern: '22222 pattern dataa', src: 'ggg', description: 'piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more ' },
        ]
    },
    {
        contentName: 'knowing number and key thearoms printing and typesetting industry',
        contentId: 2,
        url: "https://storage-aieducator.s3.ap-south-1.amazonaws.com/mnist/Articles/Class 10 - EX 1.1.mp4",
        documentUrl: '',
        mcqs: [
            {
                question: 'what is javaa',
                answer: 'a',
                options: ['backend', 'frontwnd', 'devops']
            }
        ],
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
        patterns: [
            { skill: 'weakkkk', patternId: 2495, pattern: 'dataa rofessor at Hampden-S', src: 'uuuu', description: 'Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more ' },
            { skill: 'strong', patternId: 2067, pattern: 'Hampden-Sydney Colleg ', src: 'biibb', description: 'professor at Hampden-Sydney College in Virginia, looked up one of the more ' },
        ]
    },
    {
        contentName: 'real number and their impact',
        contentId: 3,
        url: "https://storage-aieducator.s3.ap-south-1.amazonaws.com/mnist/Articles/Class - 8, Ex - 1.2.mp4",
        documentUrl: '',
        mcqs: [
            {
                question: 'what is phpp',
                answer: 'a',
                options: ['backend', 'frontwnd', 'devops']
            }
        ],
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
        patterns: [
            { skill: 'stromg', patternId: 2113, pattern: ' It has roots in a piece', src: 'ddd', description: ' not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more ' },
            { skill: 'ok', patternId: 2367, pattern: 'looked up one of the', src: 'aaa', description: 'at Hampden-Sydney College in Virginia, looked up one of the more ' },
        ]
    },
]
const initialState = {
    selectedContent: contentsMockData[1],
    contentsData: contentsMockData
};
const topicVideoPageContentsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case constants.SELECTED_VIDEO_TOPIC_CONTENT:
            state = {
                ...state,
                selectedContent: action.payload
            }
            break;

        default: state = { ...state }
            break;
    }
    return state;
}
export default topicVideoPageContentsReducer;