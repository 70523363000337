import React, { useState, useEffect, useContext } from "react";
import styles from "./solveAssignmentPage.module.scss";
import { makeAxiosRequest } from "aie_common";
import ApiEdpoints, { HTTPMethods } from "../../../../constants/api_endpoints";
import arrowback from "../../../../assets/images/arrowback.svg";
import StatusContext from "../../../../context/loaderContext";
import { QuestionSection } from "../assignmentPage/questionSection/questionSection";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SolveAssignmentMetaData } from "./solveAssignmentMetaData/solveAssignmentMetaData";
import Breadcrumb from "../../../../common/breadcrumb/breadcrumb";

const SolveExercisePage = ({ type, breadcrumbList }: any) => {
  const { topicId } = useParams();
  let { level } = useParams() || 1;
  const ai = useLocation();
  const showAiSupport = ai.search.split("=")[1] === "true" ? true : false;

  const [assignmentLev, setAssignmentLev] = useState<any>(
    level === undefined ? 1 : level
  );
  useEffect(() => {
    dispatch({
      type: "UPDATE_ASSIGNMENT_LEVEL",
      payload: level === undefined ? 1 : level,
    });
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const activeAssignmentType = "assignment";
  const [isDataUpdated, setIsDataUpdated] = useState(false);

  const [initialApiCall, setInitialApiCall] = useState(true);
  const [initialApiCalls, setInitialApiCalls] = useState(true);
  const loaderStatus: any = useContext(StatusContext);
  const [optionClicked, setOptionClicked] = useState(false);

  const [question, setQuestion] = useState<any>([]);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<any>(0);
  const questionData = useSelector(
    (state: any) => state.solveAssignmentReducer
  );

  useEffect(() => {
    return () => {
      dispatch({ type: "ENABLE_RETAKE", payload: false });
    };
  }, [dispatch]);

  useEffect(() => {
    setInitialApiCall(true);
  }, [location.state]);

  useEffect(() => {
    setActiveQuestionIndex(questionData.activeQuestionIndex || 0);
  }, [questionData]);

  const [activeScreen, setActiveScreen] = React.useState(
    question && question[activeQuestionIndex]?.isAnswered
      ? "selection"
      : "result"
  );
  // const [activeScreen, setActiveScreen] = React.useState("selection");

  const [assignmentList, setAssignmentList] = useState<any>([]);
  //Api call to fetch assignment questions
  const fetchAssignmentQuestions = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url:
        assignmentLev < 2
          ? ApiEdpoints.ASSIGNMENT_MCQ_QUESTION +
            `${topicId}/${activeQuestionIndex}`
          : ApiEdpoints.ASSIGNMENT_MCQ_QUESTION_NEW +
            `${topicId}/${assignmentLev}/${activeQuestionIndex}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      loaderStatus.setState({ show: false });

      const newData = question?.map((item: any, index: any) => {
        if (item?.Questionid === res?.data?.data?.Questionid) {
          const finalAnswer =
            res?.data?.data?.Answers?.filter(
              (each: any) => each?.id === Number(item?.answer_id)
            ) || [];
          return {
            id: index + 1,
            question: res?.data?.data?.Question,
            Answers: res?.data?.data?.Answers,
            isCorrect:
              item.status === "wrong"
                ? false
                : item.status === "correct"
                ? true
                : item.status === "in_review"
                ? false
                : false,
            selectedAnswer: item?.selectedAnswer
              ? item?.selectedAnswer
              : finalAnswer[0]?.ans || "",
            correctAnswer: "",
            visited:
              item.status === "wrong"
                ? "Wrong"
                : item.status === "correct"
                ? "Completed"
                : item.status === "in_review"
                ? "InReview"
                : "",
            score: item.marks_alloted,
            marks_scored: item.marks_scored,
            questionImg: res.data.data.Question_img,
            totalTime: item.totalTime || 0,
            open: false,
            subTopics: res.data.data.subtopic_Name,
            topicNumber: res.data.data.topicNumber,
            Questionid: item.Questionid,
            code: item.answer_id,
            descriptive: res.data.data.descriptive,
            tag: res.data.data.tag,
            uploadedImages: [],
            mcqResult: item.status === "in_review" ? false : true,
            isAnswered:
              item.status === "wrong"
                ? true
                : item.status === "correct"
                ? true
                : item.status === "in_review"
                ? true
                : false,
            student_comment: item.student_comments || "",
            mentor_comments: item.mentor_comments || "",
            subtopicNumber: res.data.data.subtopicNumber,
            topic_name: res.data.data.topic_name,
            solutions: item?.ans_img.length > 0 ? JSON.parse(item.ans_img) : [],
          };
        } else {
          if (item.Questionid && initialApiCalls) {
            setInitialApiCalls(false);
            return {
              id: index + 1,
              question: item.question_text,
              Answers: [],
              isCorrect:
                item.status === "wrong"
                  ? false
                  : item.status === "correct"
                  ? true
                  : item.statuss === "in_review"
                  ? false
                  : false,
              selectedAnswer: "",
              correctAnswer: "",
              visited:
                item.status === "wrong"
                  ? "Wrong"
                  : item.status === "correct"
                  ? "Completed"
                  : item.status === "in_review"
                  ? "InReview"
                  : "",
              score: item.marks_alloted,
              questionImg: res.data.data.Question_img,
              totalTime: item.totalTime || 0,
              open:
                item.answer_id !== null || item?.ans_img.length > 0
                  ? true
                  : false,
              subTopics: res.data.data.subtopic_Name,
              topic_name: res.data.data.topic_name,
              topicNumber: res.data.data.topicNumber,
              Questionid: item.Questionid,
              code: item.answer_id || "",
              descriptive: item.question_type === "descriptive" ? true : false,
              tag: "",
              uploadedImages: [],
              mcqResult: item.status === "in_review" ? false : true,
              isAnswered:
                item.status === "wrong"
                  ? true
                  : item.status === "correct"
                  ? true
                  : item.status === "in_review"
                  ? true
                  : false,
              student_comment: item.student_comments || "",
              mentor_comments: item.mentor_comments || "",
              subtopicNumber: res.data.data.subtopicNumber,
              ...item,
              solutions:
                item?.ans_img.length > 0 ? JSON.parse(item.ans_img) : [],
            };
          } else {
            return {
              ...item,
            };
          }
        }
      });
      setActiveScreen(
        newData[activeQuestionIndex]?.isAnswered ? "result" : "selection"
      );
      setQuestion(newData);
      dispatch({ type: "UPDATE_QUESTION", payload: newData });
      setIsDataUpdated(!true);
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAssignmentList = async () => {
    const payload = {
      url:
        assignmentLev < 2
          ? `assignment_question_list/?topic_id=${topicId}`
          : `assignment_question_list/?chapter_id=${topicId}&level=${assignmentLev}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    if (questionData?.isRetake) {
      payload.url = `assignment_question_list/?topic_id=${topicId}&is_retake=true`;
    }
    try {
      const res: any = await makeAxiosRequest(payload);
      if (!res.data.data) return;
      setAssignmentList(res.data.data.questions);
      let newData = res.data.data.questions?.map((item: any, index: any) => {
        return {
          ...item,
          Answers: [],
          Questionid: item.question_id,
          uploadedImages: [],
          descriptive: item.question_type === "descriptive" ? true : false,
          totalTime: item.timetaken || 0,
        };
      });
      // if (questionData?.isRetake) {
      //   newData = res.data.data.questions?.map((item: any, index: any) => {
      //     return {
      //       ...item,
      //       Answers: [],
      //       ans_img: [],
      //       answer_id: "",
      //       Questionid: item.question_id,
      //       uploadedImages: [],
      //       marks_scored: 0,
      //       mentor_comments: "",
      //       mentor_image: [],
      //       status: "",
      //       student_comments: "",
      //       descriptive: item.question_type === "descriptive" ? true : false,
      //       totalTime: item.timetaken || 0,
      //     };
      //   });
      // }

      setQuestion(newData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (question)
      setActiveScreen(
        question[activeQuestionIndex]?.isAnswered ||
          question[activeQuestionIndex]?.solutions?.length > 0
          ? "result"
          : "selection"
      );
  }, [activeQuestionIndex]);

  useEffect(() => {
    if (initialApiCall) {
      fetchAssignmentList();
      setInitialApiCall(false);
    } else if (question && question[activeQuestionIndex]?.descriptive) {
      if (
        question[activeQuestionIndex]?.uploadedImages.length < 1 &&
        question[activeQuestionIndex]?.student_comment !== ""
      ) {
        fetchAssignmentQuestions();
      }
    } else if (question && question[activeQuestionIndex]?.Answers.length < 1) {
      fetchAssignmentQuestions();
    }
  }, [activeQuestionIndex, assignmentList, initialApiCall]);

  const saveQuestion = async () => {
    //Need Save Api
  };

  //! pattern card
  const [patternCards, setPatternCards] = useState<any>();
  useEffect(() => {
    const patternData = assignmentList?.map((item: any, index: any) => {
      return {
        id: index,
        patterns: [],
        question: "",
      };
    });
    setPatternCards(patternData);
  }, [assignmentList]);

  return (
    <section className={styles.assignmentWrapper}>
      {question && question.length != 0 ? (
        <>
          <div className={styles.headingStyle}>
            <div onClick={() => navigate(-1)}>
              <img className={styles.arrowStyle} alt="arrow" src={arrowback} />
            </div>

            {type ? (
              <>
                <Breadcrumb
                  breadcrumbDetails={[
                    ...breadcrumbList,
                    {
                      label: question[activeQuestionIndex]?.topic_name || "",

                      selected: true,
                    },
                  ]}
                />
              </>
            ) : (
              <>
                {" "}
                {questionData.assigmentLevel > 1
                  ? `${question[activeQuestionIndex]?.topic_name} - Assignment Level ${questionData.assigmentLevel}`
                  : `Assignment ${question[activeQuestionIndex]?.topicNumber}.${question[activeQuestionIndex]?.subtopicNumber}`}
              </>
            )}
          </div>
          <div className={styles.assignmentPageContainer}>
            <SolveAssignmentMetaData
              question={question}
              activeQuestionIndex={activeQuestionIndex}
              setQuestion={setQuestion}
              setActiveQuestionIndex={setActiveQuestionIndex}
              saveQuestion={saveQuestion}
              activeAssignmentType={activeAssignmentType}
              setOptionClicked={setOptionClicked}
              optionClicked={optionClicked}
              activeScreen={activeScreen}
              isDataUpdated={isDataUpdated}
              setPatternCards={setPatternCards}
              patternCards={patternCards}
              aiSupport={showAiSupport}
              fromExercisePage={true}
            />
            <QuestionSection
              question={question}
              setQuestion={setQuestion}
              activeQuestionIndex={activeQuestionIndex}
              setActiveQuestionIndex={setActiveQuestionIndex}
              saveQuestion={() => saveQuestion()}
              activeAssignmentType={activeAssignmentType}
              activeScreen={activeScreen}
              setActiveScreen={setActiveScreen}
              setOptionClicked={setOptionClicked}
              fromExercisePage={true}
            />
          </div>
        </>
      ) : (
        <div className={styles.noDataFound}>
          No Questions Data in this assignment.
        </div>
      )}
    </section>
  );
};

export default SolveExercisePage;
