import { useState } from "react";
import backArrow from "../../../../assets/images/backArrowIcon.svg";
import deleteIcon from "../../../../assets/images/deleteIcon.svg";
import editIcon from "../../../../assets/images/editIcon.svg";
import searchIcon from "../../../../assets/images/searchIcon.svg";
import myNotesImage from "../../../../assets/images/myNotesImage.svg";
import playIcon from "../../../../assets/images/playIcon.svg";
import toggle from "../../../../assets/images/toggle.svg";
import Dropdown from "../../../../common/dropdown/dropdown";
import SideBar from "../../../../common/sidebar/sidebar";
import { constants } from "../../../../constants/constants";
import VideoNotesOverlay from "../../components/videoNotesOverlay/videoNotesOverlay";
import styles from "./myNotes.module.scss";
import { useNavigate } from "react-router-dom";

const MyNotes = () => {
  const navigate = useNavigate();
  const [showNewnNote, setShowNewnNote] = useState(false);

  const handleNewNoteOverlay = () => {
    setShowNewnNote((prev: any) => !prev);
  };

  const handleBackNavigation = () => {
    navigate("/learn/topic/805");
  };

  const data = [
    {
      id: 1,
      title: "Knowing our numbers and key theorems",
      subHeading: "1.3 Real numbers and their impact",
      videoClip: "1:50",
      notes:
        "Numbers are basically the arithmetic values. Numbers generally convey the magnitude of everything that is around us. Ascending Order: If numbers are arranged from the smallest to the greatest. Descending Order: If numbers are arranged from the greatest to the smallest number. Numbers are basically the arithmetic values. Numbers generally convey the magnitude of everything that is around us. Ascending Order: If numbers are arranged from the smallest to the greatest. Descending Order: If numbers are arranged from the greatest to the smallest number.",
    },
    {
      id: 2,
      title: "Knowing our numbers and key theorems",
      subHeading: "1.3 Real numbers and their impact",
      videoClip: "1:50",
      notes:
        "Numbers are basically the arithmetic values. Numbers generally convey the magnitude of everything that is around us. Ascending Order: If numbers are arranged from the smallest to the greatest. Descending Order: If numbers are arranged from the greatest to the smallest number. Numbers are basically the arithmetic values. Numbers generally convey the magnitude of everything that is around us. Ascending Order: If numbers are arranged from the smallest to the greatest. Descending Order: If numbers are arranged from the greatest to the smallest number.",
    },
    {
      id: 3,
      title: "Knowing our numbers and key theorems",
      subHeading: "1.3 Real numbers and their impact",
      videoClip: "1:50",
      notes:
        "Numbers are basically the arithmetic values. Numbers generally convey the magnitude of everything that is around us. Ascending Order: If numbers are arranged from the smallest to the greatest. Descending Order: If numbers are arranged from the greatest to the smallest number. Numbers are basically the arithmetic values. Numbers generally convey the magnitude of everything that is around us. Ascending Order: If numbers are arranged from the smallest to the greatest. Descending Order: If numbers are arranged from the greatest to the smallest number.",
    },
  ];

  return (
    <div className={styles.myNotesContainer}>
      <div className={styles.questionAndAnswersPageNav}>
        <div className={styles.navSection}>
          <div className={styles.arrowIcon} onClick={handleBackNavigation}>
            <img src={backArrow} alt="back" />
          </div>
          <div className={styles.navName}>My Notes</div>
        </div>
        <div className={styles.navSectionTextAndSearch}>
          <div className={styles.navBackText}>
            Here are all the notes captured by you
          </div>
          <div className={styles.navSectionSearch}>
            <img src={searchIcon} />
            <input placeholder="Looking for something" />
          </div>
        </div>
      </div>
      <div className={styles.newNotesHeaderContainer}>
        <div className={styles.allChaptersDropdown}>
          <Dropdown
            options={["All Chapters", "wde", "wedqwd"].map(
              (x: any, ind: number) => ({ name: x, id: ind + 1 })
            )}
            defaultLabel={"hello"}
            selectedValue={"hello"}
            minWidth={"177px"}
          />
          <Dropdown
            options={["All Patterns"].map((x: any, ind: number) => ({
              name: x,
              id: ind + 1,
            }))}
            defaultLabel={"hello"}
            selectedValue={"hello"}
            minWidth={"177px"}
          />
          {
            // !  sort icon need to ADD here
          }
        </div>
        <div className={styles.sm}>
          <div>
            <img src={toggle} />
          </div>
          <div> Show only my questions</div>
          <div>|</div>
          <div>
            <button
              className={styles.newNoteSubmitButton}
              onClick={handleNewNoteOverlay}
            >
              ADD NEW NOTES
            </button>
          </div>
        </div>
      </div>

      {data.map((item) => (
        <div className={styles.container}>
          <div className={styles.first}>
            <div className={styles.container_inside}>
              <div>
                <img className={styles.image_style} src={myNotesImage} />
              </div>
              <div className={styles.heading_container}>
                <div className={styles.chapterTitle}>{item.title}</div>
                <div className={styles.chapterTitle_subHeading}>
                  {item.subHeading}
                </div>
                <div className={styles.iconVideoClipTimer}>
                  <div className={styles.playIcon}>
                    <img src={playIcon} />
                  </div>
                  <div className={styles.textTimer}>
                    {constants.PLAY_VIDEO_CLIP} {item.videoClip}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.editDeleteContainer}>
              <div className={styles.editIconText}>
                <div className={styles.editIcon}>
                  <img src={editIcon} />
                </div>
                <div className={styles.editText}>
                  {constants.EDIT_YOUR_NOTE}
                </div>
              </div>

              <div className={styles.editDeleteSeparator}></div>

              <div className={styles.deleteIconText}>
                <div className={styles.deleteIcon}>
                  <img src={deleteIcon} />
                </div>
                <div className={styles.deleteText}>{constants.DELETE}</div>
              </div>
            </div>
          </div>
          <div className={styles.notesText}>{item.notes}</div>
        </div>
      ))}
      {showNewnNote && (
        <SideBar
          content={
            <VideoNotesOverlay onNewNoteOverlayClose={handleNewNoteOverlay} />
          }
          width="442px"
        />
      )}
    </div>
  );
};

export default MyNotes;
