import styles from "./questionTextInput.module.scss";
import React, { ChangeEventHandler } from 'react'

const QuestionTextInput = (props: {inputText : string, onChangeInputText: ChangeEventHandler<HTMLInputElement>}) => {
  const { inputText, onChangeInputText } = props;
  return (
   <div className={styles.questionTextInputContainer}>
    <p className={styles.questionTextInputHeading}>Please write your comments below and upload the solved answer sheets</p>
      <textarea className={styles.questionTextInput} value={inputText} onChange={(event: any) => { onChangeInputText(event) }} />
   </div>
  )
}

QuestionTextInput.defaultProps = {
  inputText: "",
  onChangeInputText: () => {console.log("QuestionTextInput Component onInputChange")}
}

export default QuestionTextInput