import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./classDetailsAssignment.module.scss";
import SearchBar from "../../../../../common/searchBar/searchBar";
import { constants } from "../../../../../constants/constants";
import FilterBar from "../../../../../common/filterBar/filterBar";
import ClassDetailsAssignmentCard from "../../components/classDetailsAssignmentCard/classDetailsAssignmentCard";
import usePagination from "../../../../../utils/customPagination";
import { CustomPagination } from "../../../../../common/pagination/customPagination";
import { routePaths } from "../../../../../constants/routes";
import { useParams } from "react-router-dom";
import ApiEdpoints, {
  HTTPMethods,
} from "../../../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import StatusContext from "../../../../../context/loaderContext";
import { formatDate } from "../../../../../utils/dateFormatter";
import useSortingByDate from "../../../../../utils/useSortingByDate";
import { useSearch } from "../../../../../utils/searchBar";

interface IAssignmentItem {
  topic_id: number;
  topic_name: string;
  topic_index: number;
  chapter_id: number;
  chapter_name: string;
  chapter_index: number;
  assignment_name: string;
  number_of_students: number;
  number_of_student_submissions: number;
  updated_on: string;
  status: string;
  level: number;
}

const ClassDetailsAssignment = () => {
  const [filtersData, setfiltersData] = useState<string[]>([]);
  const [sortData, setSortData] = useState<string>("");
  const [filteredTopicsData, setFilteredTopicsData] = useState<any[]>([]);
  const range = 5;
  const detailedViewRef = useRef(null);
  const params = useParams();
  const [assignmentList, setAssignmentList] = useState<IAssignmentItem[]>([]);
  const loaderStatus: any = useContext(StatusContext);
  const status: any = {
    in_review: "Pending",
    completed: "Completed",
  };

  const initialAllData = filtersData?.every((x) => x?.toLowerCase() === "all");
  useEffect(() => {
    const filteredAssignmentsData =
      filtersData?.length && !initialAllData
        ? assignmentList?.filter((item) => filtersData.includes(item?.status))
        : assignmentList;
    setFilteredTopicsData(filteredAssignmentsData);
  }, [filtersData, assignmentList, initialAllData]);

  const { page, paginationStart, paginationEnd, handlePaginationChange } =
    usePagination(filteredTopicsData, range, detailedViewRef);

  const getAssignmentsSubmittedByClass = async (classId: string) => {
    if (!classId) return;

    loaderStatus.setState({ show: true });
    let payload = {
      url: `${ApiEdpoints.ASSIGNMETS_SUBMITTED_BY_CLASS}?class_id=${classId}`,
      method: HTTPMethods.GET,
      data: {},
    };

    try {
      const res: any = await makeAxiosRequest(payload);

      let data = res?.data?.data.map((element: IAssignmentItem) => {
        return {
          ...element,
          name: element?.topic_name,
          submittedOn: element?.updated_on
            ? formatDate(element.updated_on)
            : "",
          status: element.status ? status[element.status] : "",
        };
      });
      data.sort((a: any, b: any) => {
        return a.status === "Pending" ? -1 : 1;
      });
      setAssignmentList(data);
      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    getAssignmentsSubmittedByClass(params.classId ?? "");
  }, []);

  const [searchText, setSearchText] = useState("");

  const [filteredTopics, getFilteredData] = useSearch(filteredTopicsData);
  const { sortedByDateArr, handleSorting } = useSortingByDate(filteredTopics);
  useEffect(() => {
    handleSorting("desc");
  }, [filteredTopicsData, filteredTopics]);
  useEffect(() => {
    getFilteredData(searchText, "classDetailsAssignments");
  }, [searchText]);

  return (
    <div className={styles.chapterDetailsHomeworkMainContainer}>
      <div className={styles.homeworkTitleSearchbarContainer}>
        <div className={styles.homeworkTitle}>
          {constants.CLASS_ASSIGNMENT_TITLE}
        </div>
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
      </div>
      <div className={styles.separator}></div>
      <div className={styles.assignmnetsFilterWrap}>
        <FilterBar
          hideFilter={true}
          statuses={["All", "Pending", "Completed"]}
          selectedStatus={(selectedStatuses: string[]) =>
            setfiltersData(selectedStatuses)
          }
          sortFunc={handleSorting}
          patternsData={[]}
        />
      </div>
      <div className={styles.assignmentCardContainer}>
        {sortedByDateArr
          ?.slice(paginationStart, paginationEnd)
          ?.map((item: any, ind: number) => (
            <ClassDetailsAssignmentCard
              item={item}
              key={`${item?.name}_${ind}`}
            />
          ))}
      </div>
      {sortedByDateArr?.length === 0 && (
        <div className={styles.noDataFound}>No Data Found</div>
      )}
      <div className={styles.paginationContainer}>
        {sortedByDateArr?.length > 0 && (
          <CustomPagination
            detailedViewRef={detailedViewRef}
            page={page}
            handlePaginationChage={handlePaginationChange}
            count={Math.ceil(sortedByDateArr?.length / range)}
          />
        )}
      </div>
    </div>
  );
};

export default ClassDetailsAssignment;
