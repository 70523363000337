import React, { useState } from "react";
import styles from "./mcqQuestion.module.scss";

interface IOption {
  option: string;
  isChecked: boolean;
}

interface IStep {
  type: "radio" | "secondary";
  steps: any;
  setSteps: React.Dispatch<React.SetStateAction<any>>;
  item: any;
  currentValue: string;
}

const MCQQuestion = ({ item, type, setSteps, steps, currentValue }: IStep) => {
  const [options, setOptions] = useState<IOption[]>(
    item?.options?.map((option: string) => ({
      option,
      isChecked: option === currentValue,
    })) || []
  );

  const handleOptionChange = async (option: string, id: any) => {
    const newOptions = options.map((optionItem) => ({
      ...optionItem,
      isChecked: optionItem.option === option,
    }));
    setOptions(newOptions);

    const newSteps = steps?.map((step: any) => {
      step.questions &&
        step?.questions.map((question: any) => {
          if (question.id === id) {
            question.answer = option.toString();
          }
          return question;
        });
      return step;
    });
    setSteps(newSteps);
  };

  return (
    <div className={styles.container}>
      <div className={styles.question}>{item?.question}</div>
      <div
        className={`${styles.radioBox} ${type === "secondary" ? styles.radioBoxSecondary : ""
          }`}
      >
        {options.map(({ option, isChecked }, index: any) => (
          <div className={styles.radioContainer} key={option}>
            <input
              type="radio"
              id={item?.question + index}
              name={option}
              value={option}
              hidden
              className={styles.input}
              checked={isChecked}
              onChange={() => handleOptionChange(option, item.id)}
            />
            <label
              className={`${styles.label} ${isChecked ? `${styles.activeLabel}` : ""
                } ${type === "radio" && styles.radioLabel}`}
              htmlFor={item?.question + index}
            >
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MCQQuestion;
