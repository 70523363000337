import React from "react";
import styles from "./videoNotesList.module.scss";
import { constants } from "../../../../constants/constants";
import closeIcon from "../../../../assets/images/closeIcon.svg";
import videoThumnail1 from "../../../../assets/images/videoThumbnail1.svg";
import videoThumnail2 from "../../../../assets/images/videoThumbnail2.svg";
import playIcon from "../../../../assets/images/playIcon.svg";
import cancelIcon from "../../../../assets/images/cancelIcon.svg";
import saveIcon from "../../../../assets/images/saveIcon.svg";
import editIcon from "../../../../assets/images/editIcon.svg";
import deleteIcon from "../../../../assets/images/deleteIcon.svg";
import { useNavigate } from "react-router-dom";
interface IVideoNotesList {
  closeVideoNotesList: any;
}
const VideoNotesList = ({ closeVideoNotesList }: IVideoNotesList) => {
  const navigate = useNavigate();
  const viewAllNotesHandler = () => {
    navigate("/learn/myNotes");
  };
  return (
    <>
      <div className={styles.overlayContainer}>
        <div className={styles.stickyHeader}>
          <div className={styles.notesTitleViewAllCloseIconContainer}>
            <div className={styles.notesTitle}>{constants.NOTESTITLE} 02</div>

            <div className={styles.viewAllCloseIconcontainer}>
              <div className={styles.viewAll} onClick={viewAllNotesHandler}>
                {constants.VIEWALL}
              </div>
              <div className={styles.closeIcon} onClick={closeVideoNotesList}>
                <img src={closeIcon} />
              </div>
            </div>
          </div>

          <div className={styles.AddNewNotes}>{constants.ADD_NEW_NOTES}</div>
        </div>

        <div className={styles.scrollContainer}>
          <div className={styles.videoClipContainer}>
            <div className={styles.thumbnail}>
              <img src={videoThumnail1} />
            </div>

            <div className={styles.iconVideoClipTimer}>
              <div className={styles.playIcon}>
                <img src={playIcon} />
              </div>
              <div className={styles.textTimer}>
                {constants.PLAY_VIDEO_CLIP} 1:50
              </div>
            </div>

            <div className={styles.chapterTitle}>
              1.3 Real numbers and their impact
            </div>
          </div>

          <div className={styles.notesContainer}>
            <div className={styles.AddYourNote}>{constants.ADD_YOUR_NOTE}</div>

            <textarea className={styles.notesArea}>
              {constants.WRITE_HERE}
            </textarea>

            <div className={styles.cancelSaveContainer}>
              <div className={styles.cancelIconText}>
                <div className={styles.cancelIcon}>
                  <img src={cancelIcon} />
                </div>
                <div className={styles.cancelText}>{constants.CANCEL}</div>
              </div>
              <div className={styles.saveIconText}>
                <div className={styles.saveIcon}>
                  <img src={saveIcon} />
                </div>
                <div className={styles.saveText}>{constants.SAVE}</div>
              </div>
            </div>
          </div>

          <div className={styles.separator}></div>

          <div className={styles.videoClipContainer}>
            <div className={styles.thumbnail}>
              <img src={videoThumnail2} />
            </div>

            <div className={styles.iconVideoClipTimer}>
              <div className={styles.playIcon}>
                <img src={playIcon} />
              </div>
              <div className={styles.textTimer}>
                {constants.PLAY_VIDEO_CLIP} 1:50
              </div>
            </div>

            <div className={styles.chapterTitle}>
              1.3 Real numbers and their impact
            </div>
          </div>

          <div className={styles.notesText}>
            Numbers are basically the arithmetic values. Numbers generally
            convey the magnitude of everything that is around us. Ascending
            Order: If numbers are arranged from the smallest to the greatest.
            Descending Order: If numbers are arranged from the greatest to the
            smallest number.
          </div>

          <div className={styles.editDeleteContainer}>
            <div className={styles.editIconText}>
              <div className={styles.editIcon}>
                <img src={editIcon} />
              </div>
              <div className={styles.editText}>{constants.EDIT_YOUR_NOTE}</div>
            </div>

            <div className={styles.editDeleteSeparator}></div>

            <div className={styles.deleteIconText}>
              <div className={styles.deleteIcon}>
                <img src={deleteIcon} />
              </div>
              <div className={styles.deleteText}>{constants.DELETE}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoNotesList;
