import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import classes from "./topicVideoPage.module.scss";
import activeVideoPlayIcon from "../../../../assets/images/activeVideoPlayIcon.svg";
import closeMarkIcon from "../../../../assets/images/closeMarkIcon.svg";
import inActiveVideoPlayIcon from "../../../../assets/images/inActiveVideoPlayIcon.svg";
import statusCorrectMarkIcon from "../../../../assets/images/statusCorrectMarkIcon.svg";
import { routePaths } from "../../../../constants/routes";
import StatusContext from "../../../../context/loaderContext";
import closeIcon from "../../../../assets/images/closeIcon.svg";
import contentAssesmentIcon from "../../../../assets/images/contentAssesmentIcon.svg";
import { SolveAssignmentQuestionListViewAll } from "../solveAssignmentPage/solveAssignmentMetaData/solveAssignmentQuestionPopUp/questionListViewAll/solveAssignmentQuestionListViewAll";
import { useSelector } from "react-redux";

const TopicVideoPageContents = ({
  getSubTopicId,
  showOrHideContentsToggleFn,
  subTopicsListData,
  topicVideoId,
  topicId,
  chapterName,
  chapterId,
}: any) => {
  const navigate = useNavigate();
  const [subTopicId, setSubTopicId] = useState(topicVideoId || 0);
  const [chapterTotalVideosList, setChapterTotalVideosList] =
    useState(subTopicsListData);

  useEffect(() => {
    const assignmnetsList = [
      {
        id: subTopicsListData[0].topicid,
        name: "Assignmnet Level-2",
        level: 2,
      },
      {
        id: subTopicsListData[0].topicid,
        name: "Assignmnet Level-3",
        level: 3,
      },
    ];
    const totalList = subTopicsListData.concat(assignmnetsList);
    setChapterTotalVideosList(totalList);
  }, [subTopicsListData]);
  const selectedContent = (event: any, item: any) => {
    setSubTopicId(item.id);
    const routePath = routePaths.learnVideoPlayer?.replace(":topicId", item.id);
    getSubTopicId(item.id);
    navigate(routePath);
    event.stopPropagation();
    showOrHideContentsToggleFn();
  };

  const [showQuestionListPopUp, setShowQuestionListPopUp] = useState(false);
  const questionData = useSelector(
    (state: any) => state.solveAssignmentReducer
  );
  const [acticveQuestionIndex, setActicveQuestionIndex] = useState(0);
  useEffect(() => {
    setActicveQuestionIndex(questionData.activeQuestionIndex);
  }, [questionData.activeQuestionIndex]);
  const [courseId, setCourseId] = useState<any>();
  const [assignmentLevel, setAssignmentLevel] = useState<any>(1);
  return (
    <div className={classes.topicVideoContentsContainer}>
      <div className={classes.overlayHeaderStyles}>
        <div className={classes.headerSection}>
          <div className={`${classes.overlayHeaderText} ${classes.inter20}`}>
            Content
          </div>
          <div
            className={classes.closeIcon}
            onClick={showOrHideContentsToggleFn}
          >
            {" "}
            <img src={closeIcon} alt="close" />
          </div>
        </div>
      </div>
      <div className={classes.bodySection}>
        {chapterTotalVideosList?.map((item: any, ind: number) => (
          <div
            className={`${classes.videoPageContentsList} ${
              item?.id === subTopicId && classes.videoPageContentsListActive
            } ${
              item?.id === subTopicId &&
              ind === 0 &&
              classes.videoPageContentsListfrstIdxActive
            }`}
            key={`${item?.name}_${item?.id}_${ind}`}
          >
            <div className={classes.videoContentsListItemIcon}>
              {" "}
              <img
                src={
                  chapterTotalVideosList.length - 1 === ind ||
                  chapterTotalVideosList.length - 2 === ind
                    ? contentAssesmentIcon
                    : item?.id === subTopicId
                    ? activeVideoPlayIcon
                    : inActiveVideoPlayIcon
                }
                alt="videoIcon"
              />{" "}
            </div>
            <div
              className={classes.videoPageContentName}
              onClick={(e) => {
                if (item && item.level == 2) {
                  setAssignmentLevel(2);
                  setCourseId(chapterId);
                  setShowQuestionListPopUp(true);
                } else if (item && item.level == 3) {
                  setAssignmentLevel(3);
                  setCourseId(chapterId);
                  setShowQuestionListPopUp(true);
                } else selectedContent(e, item);
              }}
            >
              {item?.name}
            </div>

            {item?.subtopicStatus && (
              <div>
                <img src={statusCorrectMarkIcon} />
              </div>
            )}
          </div>
        ))}
      </div>
      {showQuestionListPopUp && (
        <SolveAssignmentQuestionListViewAll
          title={`Exercise ${assignmentLevel} - ${chapterName}`}
          closePopUp={() => setShowQuestionListPopUp(!showQuestionListPopUp)}
          activeQuestionIndex={acticveQuestionIndex}
          setActiveQuestionIndex={setActicveQuestionIndex}
          topicIdProp={topicId || courseId}
          assignmentLevel={assignmentLevel}
        />
      )}
    </div>
  );
};

export default React.memo(TopicVideoPageContents);
