import React from "react";
import styles from "./studentCardPersonalDetailsBox.module.scss";

interface IStudentCardPersonalDetailsIconBox {
  icon: any;
  title: string;
}

const StudentCardPersonalDetailsIconBox = ({
  icon,
  title,
}: IStudentCardPersonalDetailsIconBox) => {
  return (
    <div className={styles.studentPersonalDetails}>
      <div className={styles.iconContainer}>
        <img src={icon} />
      </div>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default StudentCardPersonalDetailsIconBox;
