import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { authenticationRoutes, IRouteComponents } from "./constants/routes";

const UnAuthenticatedApp = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/login" />} />
      {authenticationRoutes.map((eachRoute: IRouteComponents) => (
        <Route
          path={eachRoute.path}
          element={eachRoute.element}
          key={eachRoute.path.split("/").join("-")}
        />
      ))}
    </Routes>
  );
};

export default UnAuthenticatedApp;