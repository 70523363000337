import React, { useRef, useState } from "react";
import classes from "./questionAndAnswersPage.module.scss";
import backArrow from "../../../../assets/images/backArrowIcon.svg";
import searchIcon from "../../../../assets/images/searchIcon.svg";
import sortIcon from "../../../../assets/images/sortIcon.svg";
import questionCardImg from "../../../../assets/images/questionCardImg.svg";
import deleteIcon from "../../../../assets/images/deleteIcon.svg";
import profileIcon from "../../../../assets/images/profileIcon.svg";
import likeIcon from "../../../../assets/images/likeIcon.svg";
import dislikeIcon from "../../../../assets/images/dislikeIcon.svg";
import Dropdown from "../../../../common/dropdown/dropdown";
import { CustomPagination } from "../../../../common/pagination/customPagination";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import SideBar from "../../../../common/sidebar/sidebar";
import NewQuestionOverlay from "../../components/newQuestionOverlay/newQuestionOverlay";
import DeleteQuestionModal from "./deleteQuestionModal/deleteQuestionModal";
import { useNavigate } from "react-router-dom";

const QuestionAndAnswersPage = () => {
  const [isAskQuestionOverlayOpen, setIsAskQuestionOverlayOpen] =
    useState(false);
  const [deleteQuestionModalOpen, setDeleteQuestionModalOpen] = useState(false);
  const navigate = useNavigate();

  const newQuestionOverlayHandler = () => {
    setIsAskQuestionOverlayOpen((prev) => !prev);
  };

  const deleteQuestionHandler = () => {
    setDeleteQuestionModalOpen((prev) => !prev);
  };

  const handleBackNavigation = () => {
    navigate("/learn/topic/805");
  };

  const data = [
    {
      question:
        "Can you please explain this closure properties for the question below?",
      questionImg: questionCardImg,
      studentUserDetails: { name: "you", time: "24 Dec 2022, 6:00 pm" },
      facultyDetails: {
        name: "Manoj Kumar (Faculty)",
        time: "26 jan 2022, 6:00 pm",
        comment:
          "The problem can be solved by using Rational Number Concept of closure. You first need to divide the equation in 2 parts and then combine them in the final step",
      },
    },
    {
      question:
        "How do we implement the concept of integration in a broader range of solutions?",
      questionImg: "",
      studentUserDetails: {
        name: "Adhvaitha Krishnan",
        time: "24 Dec 2022, 6:00 pm",
      },
      facultyDetails: {
        name: "Manoj Kumar (Faculty)",
        time: "26 jan 2022, 6:00 pm",
        comment:
          "The problem can be solved by using Rational Number Concept of closure. You first need to divide the equation in 2 parts and then combine them in the final step",
      },
    },
    {
      question:
        "How do we implement the concept of integration in a broader range of solutions?",
      questionImg: "",
      studentUserDetails: { name: "manoj", time: "24 Dec 2022, 6:00 pm" },
      facultyDetails: null,
    },
    {
      question:
        "How do we implement the concept of integration in a broader range of solutions?",
      questionImg: "",
      studentUserDetails: { name: "lenovo", time: "24 Dec 2022, 6:00 pm" },
      facultyDetails: null,
    },
  ];
  const detailedViewRef = useRef(null);
  return (
    <div
      className={classes.questionAndAnswersPageWrapper}
      ref={detailedViewRef}
    >
      <div className={classes.questionAndAnswersPageNav}>
        <div className={classes.navSection}>
          <div className={classes.arrowIcon} onClick={handleBackNavigation}>
            <img src={backArrow} alt="back" />
          </div>
          <div className={classes.navName}>Q & A Hub</div>
        </div>
        <div className={classes.navSectionTextAndSearch}>
          <div className={classes.navBackText}>
            Here are all the questions raised by you and your classmates
          </div>
          <div className={classes.navSectionSearch}>
            <img src={searchIcon} />
            <input placeholder="Looking for something" />
          </div>
        </div>
      </div>
      <div className={classes.questionAndAnswersPageBodyWrapper}>
        <div className={classes.questionAndAnswersPageBodyHeader}>
          <div className={classes.bodyFiltersSection}>
            <div className={classes.bodyFilters}>
              <div className={classes.dropDownFilter}>
                <Dropdown
                  options={["All Chapters", "wde", "wedqwd"].map(
                    (x: any, ind: number) => ({ name: x, id: ind + 1 })
                  )}
                  defaultLabel={"hello"}
                  selectedValue={"hello"}
                  minWidth={"177px"}
                />
                <Dropdown
                  options={["All Patterns"].map((x: any, ind: number) => ({
                    name: x,
                    id: ind + 1,
                  }))}
                  defaultLabel={"hello"}
                  selectedValue={"hello"}
                  minWidth={"177px"}
                />
              </div>
            </div>
            <div className={classes.bodyFiltersSort}>
              <img src={sortIcon} />
              <div className={classes.bodyFiltersSortText}>Sort</div>
            </div>
          </div>
          <div className={classes.askQuestionSection}>
            <div className={classes.switchWrapper}>
              <div className={classes.switchIcon}>
                <AntSwitch
                  defaultChecked
                  inputProps={{ "aria-label": "ant design" }}
                />
              </div>
              <div className={classes.switchText}>Show only my questions</div>
            </div>
            <div className={classes.askQuestionBtnWrapper}>
              <button
                className={classes.askQuestionBtn}
                onClick={newQuestionOverlayHandler}
              >
                ask a question
              </button>
            </div>
          </div>
        </div>
        {data?.map((item, ind) => (
          <div
            key={`${item.question}_${ind}`}
            className={classes.questionAndAnswersCardContainer}
          >
            <div className={classes.questionHeading}>{item?.question}</div>
            {item.questionImg && (
              <div className={classes.questionImage}>
                <img src={questionCardImg} alt="question image" />
              </div>
            )}

            <div className={classes.profileDetailsSection}>
              <div className={classes.profileAndTimeSection}>
                <div className={classes.profileImgandText}>
                  <div className={classes.profileImg}>
                    <img src={profileIcon} />
                  </div>
                  <div className={classes.profileText}>
                    {item?.studentUserDetails?.name}
                  </div>
                </div>
                <div className={classes.profileTime}>
                  {item?.studentUserDetails?.time}
                </div>
              </div>

              <div className={classes.profileActionsSection}>
                {item?.studentUserDetails?.name === "you" && (
                  <div
                    className={classes.deleteImgAndText}
                    onClick={deleteQuestionHandler}
                  >
                    <div className={classes.deleteImg}>
                      <img src={deleteIcon} alt="delete icon" />
                    </div>
                    <div className={classes.deleteText}>Delete</div>
                  </div>
                )}
                <div className={classes.upVoteSection}>
                  <div className={classes.upVoteText}>Upvote</div>
                  <div className={classes.likeAndDislikeSection}>
                    <img src={likeIcon} alt="like" />
                    <div className={classes.upVoteCount}>12</div>
                    <img src={dislikeIcon} alt="dis-like" />
                  </div>
                </div>
              </div>
            </div>

            {item?.facultyDetails && (
              <div className={classes.questionAndAnswerCommentsContainer}>
                <div className={classes.commentsCardItem}>
                  <p className={classes.commentText}>
                    <span className={classes.commentTextUserId}>@Hemanth</span>
                    {item?.facultyDetails?.comment}
                  </p>
                  <div className={classes.commentedFacultySection}>
                    <div className={classes.commentedFacultyImgandText}>
                      <div className={classes.commentedFacultyImg}>
                        <img src={profileIcon} />
                      </div>
                      <div className={classes.commentedFacultyText}>
                        {item?.facultyDetails?.name}
                      </div>
                    </div>
                    <div className={classes.commentedFacultyTime}>
                      {item?.facultyDetails?.time}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
        {data?.length && (
          <div className={classes.questionAndAnswerpagination}>
            <CustomPagination
              detailedViewRef={detailedViewRef}
              page={1}
              handlePaginationChage={() => console.log("handle pagination")}
              count={Math.ceil(data?.length / 2)}
            />
          </div>
        )}
      </div>
      {isAskQuestionOverlayOpen && (
        <SideBar
          content={
            <NewQuestionOverlay
              newQuestionOverlayHandler={newQuestionOverlayHandler}
            />
          }
          width="442px"
        />
      )}
      {deleteQuestionModalOpen && (
        <DeleteQuestionModal
          deleteQuestionHandler={deleteQuestionHandler}
          deleteQuestionModalOpen={deleteQuestionModalOpen}
        />
      )}
    </div>
  );
};

export default React.memo(QuestionAndAnswersPage);

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#6862E0",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
