import React, { useContext, useEffect, useState } from "react";
import styles from "./adminClasses.module.scss";
import StatusContext from "../../../context/loaderContext";
import { HTTPMethods, makeAxiosRequest } from "../../../utils/httpService";
import ClassesBlockList from "../../../common/admin/classesBlockList/classesBlockList";
import Dropdown from "../../../common/dropdown/dropdown";
import { useNavigate } from "react-router-dom";
import AdminSideBar from "../../../common/admin/adminSideBar/adminSideBar";
import RenderForm from "../../../common/admin/renderForm/renderForm";
import {
  addClassFormTemplate,
  addTeacherFormTemplate,
} from "../adminTeachers/adminFormTemplates";
import SubmitAndCancelButton from "../../../common/admin/submitAndCancelButton/submitAndCancelButton";
import { Alert, Snackbar } from "@mui/material";
import { useSelector } from "react-redux";

const AdminClassList = () => {
  const loaderStatus: any = useContext(StatusContext);
  const navigate = useNavigate();
  const [selectedClass, setSelectedClass] = useState(-1);
  const [showAddClass, setShowAddClass] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationData, setNotificationData] = useState<any>({
    type: "",
    message: ">",
  });
  const [activeSchoolInfo, setActiveSchoolInfo] = useState({
    name: "",
    admin: "",
    state: "",
    city: "",
    data: [],
  });
  const [sampleSheetUrl, setSampleSheetUrl] = useState("");
  const { userData } = useSelector((state: any) => state.userReducer.userData);

  const getSchoolData = async (type: any, count?: any) => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: "super-admin/" + type + "/",
      method: HTTPMethods.GET,
      headers: {},
    };
    if (count) {
      payload.url = payload.url + count + "/";
    }

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (type == "school_classes_info") {
        const scData = data.map((item: any) => {
          return {
            class: item.class_name,
            totalStudent: item.student_count,
            sections: item.section,
          };
        });
        setActiveSchoolInfo((state) => {
          return {
            ...state,
            data: scData,
          };
        });
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });

      console.log(error);
    }
  };

  useEffect(() => {
    if (userData?.id) {
      getSchoolData("school_classes_info", userData.id);
      getClassesSection("class");
      getClassesSection("section");
      getClassesSection("teachers_in_school");
      getSampleFileUrl();
    }
  }, [userData?.id]);

  const handleCreateClass = async (data: any, type?: any) => {
    let params = {
      teacher: data.teacher,
      class_info: Number(data.classStandard),
      section: data.section,
    };
    let formData;
    if (type == "FILE") {
      const file = data[0];

      formData = new FormData();
      formData.append("student_details", file);
    }

    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url:
        type === "FILE"
          ? "user/upload_students_in_bulk/1/1/1/"
          : "school-admin/teacher_assignment/",
      data: type === "FILE" ? formData : params,
      method: HTTPMethods.POST,
      headers: {},
    };

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data) {
        setShowNotification(true);
        setNotificationData({
          type: "success",
          message: "New Class added.",
        });
        setShowAddClass(false);
        getSchoolData("school_classes_info", userData?.id);
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      const errorObject: any = error;

      let errorMessage = "Validation Error:\n";

      if (typeof errorObject == "object") {
        Object.keys(errorObject)?.forEach((field) => {
          if (Array.isArray(errorObject[field])) {
            errorMessage += `${field}: ${errorObject[field].join(", ")}\n\n`;
          } else {
            errorMessage += `${field}: ${errorObject[field]}\n\n`;
          }
        });
      } else {
        errorMessage = "Something went wrong";
      }

      setShowNotification(true);
      setNotificationData({
        type: "error",
        message: errorMessage || "Something went wrong",
      });
    }
  };
  const getSampleFileUrl = async () => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: "user/get_student_bulk_upload_excel_format/",
      method: HTTPMethods.GET,
      headers: {},
    };

    try {
      const data: any = await makeAxiosRequest(payload, baseUrl);
      // Create a Blob URL for the Excel file
      const blob = new Blob([data.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const blobURL = URL.createObjectURL(blob);
      setSampleSheetUrl(blobURL);

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      const errorObject: any = error;

      let errorMessage = "Validation Error:\n";

      if (typeof errorObject == "object") {
        Object.keys(errorObject)?.forEach((field) => {
          if (Array.isArray(errorObject[field])) {
            errorMessage += `${field}: ${errorObject[field].join(", ")}\n\n`;
          } else {
            errorMessage += `${field}: ${errorObject[field]}\n\n`;
          }
        });
      } else {
        errorMessage = "Something went wrong";
      }

      setShowNotification(true);
      setNotificationData({
        type: "error",
        message: errorMessage || "Something went wrong",
      });
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClose = () => {
    setAnchorEl(null);
    //setActiveTableContext(null);
  };
  const [studentClasses, setStudentClasses] = useState<any>([
    { name: "All Classes", id: -1 },
  ]);
  const [studentSection, setStudentSection] = useState<any>([]);
  const [availableTeacher, setAvailableTeacher] = useState<any>([]);

  const getClassesSection = async (type: any) => {
    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    let payload: any = {
      url: `school-admin/${type}/`,
      method: HTTPMethods.GET,
      headers: {},
    };
    if (type === "teachers_in_school") {
      payload.url = payload.url + userData.id + "/";
    }

    try {
      const { data }: any = await makeAxiosRequest(payload, baseUrl);
      if (data.length) {
        if (type == "section") {
          const sectionList = data.map((s: any) => {
            return {
              name: s.name,
              value: s.name,
              id: s.id,
            };
          });
          setStudentSection(sectionList);
        }
        if (type == "class") {
          const classList = data
            .map((item: any) => {
              return { name: String(item.name), id: item.code };
            })
            .sort((a: any, b: any) => {
              return parseInt(a.id) - parseInt(b.id);
            });
          setStudentClasses([{ name: "All Classes", id: -1 }, ...classList]);
        }
        if (type === "teachers_in_school") {
          const teacherList = data.map((t: any) => {
            return {
              name: t.user.first_name + " " + t.user.last_name,
              value: t.user.first_name + " " + t.user.last_name,
              id: t.id,
            };
          });

          setAvailableTeacher(teacherList);
        }
        //  setSelectedClass(classList[0].id);
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });

      console.log(error);
    }
  };

  return (
    <div className={styles.schoolTab}>
      <div className={styles.schoolTabDropdowns}>
        <Dropdown
          options={studentClasses}
          defaultLabel={"Select A Class"}
          minWidth={"225px"}
          overlayWidth={"230px"}
          id={"selectClass"}
          selectedValue={(item: string) => console.log("Test2")}
          isMenuItemTypeText={true}
          setDropdownSelectedValueWithId={(item: any) => {
            setSelectedClass(item.id);
          }}
        />
        <button
          onClick={() => {
            setShowAddClass(true);
          }}
        >
          Create new class
        </button>
      </div>
      <div className={styles.schoolClassList}>
        <div className={styles.schoolClassListGrid}>
          {!!activeSchoolInfo?.data?.length &&
            activeSchoolInfo.data
              .filter((cl: any) => {
                if (selectedClass === -1) {
                  return cl;
                } else {
                  return Number(cl.class) === Number(selectedClass);
                }
              })
              .map((item: any) => {
                return (
                  <div
                    key={item?.class}
                    onClick={() => {
                      navigate(`/admin/classes/${userData.id}/${item.class}`);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <ClassesBlockList listData={item} />
                  </div>
                );
              })}
        </div>
      </div>
      {showAddClass && (
        <AdminSideBar
          changeStatus={() => {
            setShowAddClass(false);
          }}
          status={true}
          title={"Create New Class"}
          children={
            <>
              <RenderForm
                formData={addClassFormTemplate(
                  studentClasses
                    .filter((i: any) => i.id !== -1)
                    .map((s: any) => {
                      return {
                        name: s.name,
                        value: s.name,
                        id: s.code,
                      };
                    }),
                  studentSection,
                  availableTeacher
                )}
                defaultValues={{
                  classStandard: "",
                  section: "",
                  batch: "",
                  teacher: "",
                }}
                // hasBulkUpload
                // sampleBulkUploadTemplate={sampleSheetUrl}
                onSubmit={handleCreateClass}
                submitControls={
                  <SubmitAndCancelButton
                    onCancel={() => {
                      setShowAddClass(false);
                    }}
                  />
                }
              />
            </>
          }
        />
      )}
      <Snackbar
        open={showNotification}
        autoHideDuration={6000}
        onClose={() => {
          setShowNotification(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={notificationData.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {notificationData.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AdminClassList;
