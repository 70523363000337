import React from "react";
import styles from "./chapterCard.module.scss";
import ChapterIconName from "../../modules/teacher/myClass/components/chapterIconName/chapterIconName";

interface IChapterCard {
  icon: any;
  chapter: {
    chapterDetails: {
      chapterName: string;
      chapterNumber: string | number;
    };
    chips: string[];
  };
}
const ChapterCard = ({ chapter, icon }: IChapterCard) => {
  const { chapterDetails, chips } = chapter;
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <ChapterIconName heading={chapterDetails?.chapterNumber} icon={icon} />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.rightUpperContainer}>
          <div className={styles.heading}>{chapterDetails?.chapterName}</div>
        </div>
        <div className={styles.rightLowerContainer}>
          {chips &&
            Array.isArray(chips) &&
            chips.map((chip) => (
              <div className={styles.chipContainer} key={chip}>
                {chip}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ChapterCard;
