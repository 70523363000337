import React, { useState } from "react";
import { Time } from "./time/time";
import styles from "./questionMetaDataSection.module.scss";
import { QuestionList } from "./questionList/questionList";
import arrowGreen from "../../../../../assets/images/arrowGreen.svg";
import { SuggestionOption } from "./suggestion/suggestionOption";
import { SubmitButtonWrapper } from "../component/submitButtonWrapper/submitButtonWrapper";
import { routePaths } from "../../../../../constants/routes";
import { SuggestionDescriptive } from "./suggestion/suggestionDescriptive";
import { PatternCards } from "./patternCards/patternCards";
import { QuestionAndAnswerPopUp } from "../../../components/questionAndAnswerPopup/questionAndAnswerPopup";
import { QuestionViewAll } from "./questionViewAll/questionViewAll";
import { ModalWrapperSubmitAssignment } from "../../../../../common/modalWrapper/modalWrapperSubmitAssignment";
import { useParams } from "react-router-dom";

interface IQuestionMetaDataSectionProps {
  question: any;
  activeQuestionIndex: any;
  setQuestion: any;
  setActiveQuestionIndex: any;
  saveQuestion: any;
  activeAssignmentType?: any;
  optionClicked?: any;
  activeScreen?: any;
  setOptionClicked?: any;
}

export const QuestionMetaDataSection = (
  props: IQuestionMetaDataSectionProps
) => {
  const {
    question,
    activeQuestionIndex,
    setQuestion,
    setActiveQuestionIndex,
    saveQuestion,
    activeAssignmentType,
    optionClicked,
    activeScreen,
    setOptionClicked,
  } = props;

  const [questionAnswerPopup, setQuestionAnswerPopup] = useState(false);
  const [submintingAssignment, setSubmintingAssignment] = useState(false);
  const { topicId, testId } = useParams();
  return (
    <div className={styles.timerSectionContainer}>
      <Time
        question={question}
        activeQuestionIndex={activeQuestionIndex}
        setQuestion={setQuestion}
        setSubmintingAssignment={setSubmintingAssignment}
        setActiveQuestionIndex={setActiveQuestionIndex}
        saveQuestion={saveQuestion}
        activeAssignmentType={activeAssignmentType}
        optionClicked={optionClicked}
        setOptionClicked={setOptionClicked}
      />
      <div className={styles.questionNumberSection}>
        <QuestionViewAll
          question={question}
          setQuestionAnswerPopup={setQuestionAnswerPopup}
          questionAnswerPopup={questionAnswerPopup}
          activeQuestionIndex={activeQuestionIndex}
        />
        <div className={styles.questionNumberContainer}>
          {question.map((item: any, index: number) => {
            return (
              <div key={index}>
                <QuestionList
                  key={index}
                  questions={question}
                  setQuestion={setQuestion}
                  type={item.visited}
                  item={index + 1}
                  activeQuestionIndex={activeQuestionIndex}
                  setActiveQuestionIndex={setActiveQuestionIndex}
                  
                  activeScreen={activeScreen}
                  
                  setOptionClicked={setOptionClicked}
                
                  
                />
              </div>
            );
          })}
        </div>
      </div>
      {/* {
                question[activeQuestionIndex]?.descriptive === true
                    ? <> <SuggestionDescriptive />
                        <PatternCards
                            tag={question[activeQuestionIndex]?.tag}
                            activeQuestionIndex={activeQuestionIndex}
                            question={question}
                            activeAssignmentType={activeAssignmentType}
                        />
                    </>
                    : <> <SuggestionOption />
                        <PatternCards
                            tag={question[activeQuestionIndex]?.tag}
                            activeQuestionIndex={activeQuestionIndex}
                            question={question}
                            activeAssignmentType={activeAssignmentType}
                        /></>

            } */}
      {activeAssignmentType !== "assignment" && (
        <SubmitButtonWrapper
          submitButtonEnables={
            question.filter(
              (question: any) =>
                question.selectedAnswer !== "" || question.totalTime == 0
            ).length == question.length
          }
          question={question}
          saveQuestion={saveQuestion}
          navigateUrl={
            activeAssignmentType === "test"
              ? routePaths.MCQResult.replace(":topicId", `${testId}`)
              : routePaths.learnVideoPlayer.replace(":topicId", `${topicId}`)
          }
          activeQuestionIndex={activeQuestionIndex}
          activeAssignmentType={activeAssignmentType}
        />
      )}
      {/* {activeAssignmentType !== "assignment" && question.filter(
  (question: any) => question.selectedAnswer !== "" || question.totalTime == 0
).length === question.length && (
  <SubmitButtonWrapper
    submitButtonEnables={true} // You can set this to true since the condition is met
    question={question}
    saveQuestion={saveQuestion}
    navigateUrl={
      activeAssignmentType === "test"
        ? routePaths.MCQResult.replace(":topicId", `${testId}`)
        : routePaths.learnVideoPlayer.replace(":topicId", `${topicId}`)
    }
    activeQuestionIndex={activeQuestionIndex}
    activeAssignmentType={activeAssignmentType}
  />
)} */}


      {submintingAssignment && (
        <ModalWrapperSubmitAssignment
          activeQuestionType={activeAssignmentType}
        />
      )}
    </div>
  );
};
