import React from "react";
import style from "./classesBlock.module.scss";
import ClassesBlockList from "../classesBlockList/classesBlockList";
import { Link } from "react-router-dom";

const ClassesBlock = ({ listData, link }: any) => {
  const classData = listData;

  return (
    <div className={style.classBlock}>
      <div className={style.head}>
        <h1>Classes</h1>
        {/* <Link href="">View all classes</Link> */}
        {link && <Link to={link}>View all classes</Link>}
      </div>
      <div className={style.container}>
        {classData.map((data: any, index: any) => (
          <ClassesBlockList key={index} listData={data} />
        ))}
      </div>
    </div>
  );
};

export default ClassesBlock;
