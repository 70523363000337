import React from "react";
import classes from "./footer.module.scss";
import { constants } from "../../constants/constants";
import { useSelector } from "react-redux";

const Footer = () => {
    const isTabOpen = useSelector((state: any) => state.tabReducer.tabOpen);
    return (
        <div className={classes.footer}>
            <div className={`${classes.copyright} ${classes.inter16} ${classes.footerText} ${!isTabOpen ?  classes.sideNav : ""}`}>
            {constants.COPYRIGHT}
            </div>
            <div className={classes.linksSection}>
                <div className={`${classes.inter16} ${classes.footerText}`}>{constants.TERMSCONDITIONS}</div>
                <div className={`${classes.inter16} ${classes.footerText}`}>{constants.PRIVACYPOLICY}</div>
                <div className={`${classes.inter16} ${classes.footerText}`}>{constants.FAQ}</div>
            </div>
        </div>
    )
}

export default Footer;