import React, { useState } from "react";
import styles from "./superAdminSchool.module.scss";
import studentImage from "../../../assets/images/student.png";
import ConfirmationPopup from "../../../common/admin/confirmationPopup/confirmationPopup";

const SuperAdminDetailSidebar = ({ viewData, onEdit, onDelete }: any) => {
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  function deleteDetails() {
    onDelete(viewData.id, true);
    setShowDeletePopup(!showDeletePopup);
  }

  return (
    <div className={styles.superAdminDetailSidebar}>
      <div className={styles.content}>
        <div className={`${styles.teacher} ${styles.active} `}>
          <div className={styles.photo}>
            <img src={studentImage} alt="" />
          </div>

          <div className={styles.card}>
            <div className={styles.head}>
              <h1>{viewData.name}</h1>
            </div>
            <div className={styles.details}>
              <div className={styles.block}>
                <h1>{viewData.school}</h1>
                <p>School</p>
              </div>
              <div className={styles.block}>
                <h1>{viewData.email}</h1>
                <p>Primary Email ID</p>
              </div>
              <div className={styles.block}>
                <h1>{viewData.contact}</h1>
                <p>Primary Mobile No.</p>
              </div>
              <div className={styles.block}>
                <h1>{viewData.gender}</h1>
                <p>Gender</p>
              </div>
              <div className={styles.block}>
                <h1>{viewData.region}</h1>
                <p>Region</p>
              </div>
            </div>
            <div className={styles.buttons}>
              <h2>Contact</h2>
              <div>
                <button
                  onClick={() => {
                    setShowDeletePopup(true);
                  }}
                  className={styles.delete}
                >
                  Delete
                </button>
                <button className={styles.edit} onClick={onEdit}>
                  Edit details
                </button>
              </div>
            </div>
          </div>
          {showDeletePopup && (
            <ConfirmationPopup
              title=" Are you sure you want to delete
                                         the student details
                                               permanently?"
              confirmText="Yes"
              declineText="No"
              onConfirm={deleteDetails}
              onDecline={() => {
                deleteDetails();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SuperAdminDetailSidebar;
