import React from "react";
import stars from "../../../assets/images/stars.svg";
import clouds from "../../../assets/images/clouds.svg";

import styles from "./dashboardStatsCard.module.scss";

const DashboardStatsCard = (props: any) => {
  // console.log(props);

  return (
    <div className={styles.dashboardStatsBlock}>
      <div className={styles.c1}>
        <h1>{props.dataObj.label1}</h1>
        <p>{props.dataObj.count1}</p>
        <img className={styles.stars} src={stars} alt="" />
      </div>

      <div
        className={`${styles.c2} ${
          props.dataObj.label2 || props.dataObj.label3 ? "bordered" : ""
        }`}
      >
        <img className={styles.clouds} src={clouds} alt="" />
        {props.dataObj.label2 && (
          <div className="block1">
            <h1>{props.dataObj.label2}</h1>
            <p>{props.dataObj.count2}</p>
          </div>
        )}
        {props.dataObj.label3 && (
          <div className="block2">
            <h1>{props.dataObj.label3}</h1>
            <p>{props.dataObj.count3}</p>
          </div>
        )}
        {props.dataObj.url && (
          <a target="_blank" href={props.dataObj.url} rel="noopener noreferrer">
            View Report
          </a>
        )}
      </div>
    </div>
  );
};

export default DashboardStatsCard;
