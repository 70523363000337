import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import styles from "./dropdown.module.scss";
import { convertMathMLToText } from "../../utils/convertMathToString";
import { QuestionAndOptionsElement } from "../questionsAndOptions/questionAndOptions";

interface IDropdown {
  options: any;
  defaultLabel: string;
  selectedValue?: any;
  minWidth?: string;
  patternIndex?: number;
  initialSelectedPatternsValues?: { index: number; value: string }[];
  setUpdatedSelectedPatternValues?: any;
  isDropdownOutlineRequired?: boolean;
  setDropdownSelectedValueWithId?: any;
  dropdownWithId?: boolean;
  overlayWidth?: string;
  id?: string;
  isMenuItemTypeText?: boolean;
  disabled?: boolean;
}

const Dropdown = ({
  options,
  defaultLabel,
  minWidth,
  isDropdownOutlineRequired = true,
  setDropdownSelectedValueWithId,
  overlayWidth,
  id,
  isMenuItemTypeText,
  disabled,
}: IDropdown) => {
  const [value, setValue] = useState(options[0]?.name);
  const handleChange = (event: any, id: any) => {
    if (options?.length) {
      options?.map((option: any, ind: number) => {
        if (option?.id === id) {
          setValue(event?.target?.title);
          setDropdownSelectedValueWithId({
            defaultLabel,
            name: event?.target?.title,
            id: option?.id,
          });
        }
      });
    }
  };

  return (
    <FormControl
      className={styles.formControl}
      style={{ minWidth: `${window.innerWidth > 920 ? minWidth : "100%"}` }}
    >
      <Select
        disabled={disabled}
        renderValue={(value) => (value ? value : options[0]?.name)}
        value={value}
        title={value}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        style={{ maxWidth: `${window.innerWidth > 920 ? minWidth : "100%"}` }}
        className={
          isDropdownOutlineRequired
            ? styles.select
            : styles.selectWithOutBorders
        }
        MenuProps={
          {
            PaperProps: {
              style: {
                width: overlayWidth,
                maxHeight: "400px",
              } as React.CSSProperties,
            },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: { vertical: "top", horizontal: "center" },
          } as any
        }
        sx={
          isDropdownOutlineRequired
            ? {
                "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    color: "#4B5468",
                    fontFamily: "Inter-Regular",
                    fontSize: "14px",
                    lineHeight: "17px",
                    minHeight: "unset",
                    alignItems: "center",
                    padding: "10px 12px 9px 12px",
                    display: id === "questionList" ? "flex" : "block",
                    overflow: "hidden",
                    maxWidth: id === "questionList" ? "140px" : "initial",
                  },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "unset !important",
                },
              }
            : {
                "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "8px 15px",
                    color: "#4B5468",
                    fontFamily: "Inter-Regular",
                    fontSize: "14px",
                    lineHeight: "17px",
                    display: "flex",
                    alignItems: "center",
                  },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderStyle: "none",
                  borderWidth: "0px",
                },
              }
        }
      >
        {options?.map((option: any, ind: number) => (
          <MenuItem
            value={
              isMenuItemTypeText ? option?.name : convertMathMLToText(option)
            }
            key={`menuItem_${ind}`}
            onClick={(e) => handleChange(e, option?.id)}
            title={
              isMenuItemTypeText
                ? option.name
                : convertMathMLToText(option.name)
            }
          >
            {isMenuItemTypeText ? (
              option?.name
            ) : (
              <QuestionAndOptionsElement
                metaData={option?.name}
                optionLength={true}
              />
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
