import { makeAxiosRequest } from "aie_common";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomPagination } from "../../../../../common/pagination/customPagination";
import SearchBar from "../../../../../common/searchBar/searchBar";
import ApiEdpoints, {
  HTTPMethods,
} from "../../../../../constants/api_endpoints";
import { constants } from "../../../../../constants/constants";
import { routePaths } from "../../../../../constants/routes";
import StatusContext from "../../../../../context/loaderContext";
import { updateClassList } from "../../../../../utils/teacherPersonalMassagedData";
import usePagination from "../../../../../utils/customPagination";
import DivisionDetails from "../../components/divisionDetails/divisionDetails";

import styles from "./classListingPage.module.scss";
import { useSearch } from "../../../../../utils/searchBar";
import CreateHomeworkTest from "../createHomeworkTest/createHomeworkTest";

export const ClassListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const range = 5; //Number of Items displayed per page for Pagination
  const detailedViewRef = useRef(null);
  const { page, paginationStart, paginationEnd, handlePaginationChange } =
    usePagination(constants.DIVISIONS_DATA, range, detailedViewRef);
  const [classList, setClassList] = useState<any>([]);
  const [assignmentList, setAssignmentList] = useState<any>([]);
  const [updatedClassList, setUpdatedClassList] = useState<any>([]);
  const username = useSelector((state: any) => state.userReducer.username);
  const loaderStatus: any = useContext(StatusContext);

  const handlePattern = (item: any) => {
    navigate(routePaths.mentorMyClassDetails.replace(":classId", item.id));
  };

  const fetchClassList = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: ApiEdpoints.CLASS_LIST,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);
      loaderStatus.setState({ show: false });
      setClassList(res?.data?.data);
      setAssignmentList(res?.data?.data);
      return res;
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    setUpdatedClassList(updateClassList(classList));
  }, [classList]);

  useEffect(() => {
    dispatch({
      type: constants.MYCLASS,
      index: 0,
    });
    fetchClassList();
  }, []);

  const [searchText, setSearchText] = useState("");
  const [filteredTopics, getSearchedData] = useSearch(updatedClassList);

  useEffect(() => {
    getSearchedData(searchText, "divisionClass");
  }, [searchText]);
  return (
    <div className={styles.mainContainer} ref={detailedViewRef}>
      <div className={styles.headingContainer}>
        <div className={styles.greetings}>
          {constants.MENTOR_GREETINGS.replace("user", username)}
        </div>
        <div className={styles.searchContainer}>
          <SearchBar searchText={searchText} setSearchText={setSearchText} />
        </div>
      </div>

      <CreateHomeworkTest />

      {filteredTopics &&
        Array.isArray(filteredTopics) &&
        filteredTopics
          ?.slice(paginationStart, paginationEnd)
          .map((division, index) => (
            <DivisionDetails
              {...division}
              key={index}
              handlePattern={handlePattern}
            />
          ))}
      {filteredTopics?.length === 0 && loaderStatus.show === false && (
        <div className={styles.noDataFound}>{constants.NO_DATA_FOUND}</div>
      )}
      {filteredTopics?.length > range && (
        <CustomPagination
          detailedViewRef={detailedViewRef}
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(filteredTopics?.length / range)}
        />
      )}
    </div>
  );
};

export default ClassListing;
