import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import chapterCardChevronBottom from "../../../../assets/images/chapterCardChevronBottom.svg";
import chapterImage from "../../../../assets/images/chapterImage.svg";
import greenRightArrowIcon from "../../../../assets/images/greenRightArrowIcon.svg";
import MascotWriting from "../../../../assets/images/mascotWriting.svg";
import topicIcon from "../../../../assets/images/topicIcon.svg";
import assignmentIcon from "../../../../assets/images/test.svg";
import topicsMCQIcon from "../../../../assets/images/topicsMCQIcon.svg";
import topicsPatternIcon from "../../../../assets/images/topicsPatternIcon.svg";
import topicsPPTIcon from "../../../../assets/images/topicsPPTIcon.svg";
import Progressbar from "../../../../common/progressbar/progressbar";
import { constants } from "../../../../constants/constants";
import { routePaths } from "../../../../constants/routes";
import styles from "./chapterCardNew.module.scss";
import topicsVideoIcon from "../../../../assets/images/topicsVideoIcon.svg";
import topicsAssignmentIcon from "../../../../assets/images/topicsAssignmentIcon.svg";
import { SolveAssignmentQuestionListViewAll } from "../../pages/solveAssignmentPage/solveAssignmentMetaData/solveAssignmentQuestionPopUp/questionListViewAll/solveAssignmentQuestionListViewAll";
import { useDispatch, useSelector } from "react-redux";
import ApiEdpoints, { HTTPMethods } from "../../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import { Dialog, Switch } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import ModalBg from "../../../../assets/images/aiSupportModalBg.svg";
import MascotRocket from "../../../../assets/images/mascotRocket.svg";
import styles1 from "./../../../../common/cards/practiceTestChapterCard.module.scss";
import SolveExerciseButton from "../../../../common/solveExerciseButton/solveExerciseButton";

interface IChapterCardNew {
  chapterItem: any;
  page: number;
  chapterNumber: number;
  handleOverlays: any;
  count: number;
  setTopicId?: any;
  openAccordion: number;
  setOpenAccordion: any;
  topicId?: any;
}

interface IchapterTopics {
  name: string;
  icon: string;
  type?: string;
  isPPTAvailable: boolean;
  isMCQAvailable: boolean;
  isPatternAvailable: boolean;
}

const ChapterCardNew = ({
  chapterItem,
  chapterNumber,
  page,
  handleOverlays,
  setTopicId,
  count,
  openAccordion,
  setOpenAccordion,
  topicId,
}: IChapterCardNew) => {
  const [chapterTopics, setChapterTopics] = useState<IchapterTopics[]>([
    /*NOT MVP TASK*/
    /* {
      name: "Get Started",
      type: "pre-assessment",
      icon: topicsGetStartedIcon,
      isPPTAvailable: false,
      isMCQAvailable: false,
      isPatternAvailable: false,
    },*/
  ]);
  const [isChapterOpen, setIsChapterOpen] = useState("");
  const [topicIndex, setTopicIndex] = useState("");
  // const [isHomeworkGiven, setIsHomeworkGiven] = useState(false); // NOT MVP TASK
  const [isSuggestionGiven, setIsSuggestionGiven] = useState(true);
  const [showQuestionListPopUp, setShowQuestionListPopUp] = useState(false);
  const [acticveQuestionIndex, setActicveQuestionIndex] = useState(0);
  const [checkAiSupportModal, setCheckAiSupportModal] = useState(false);
  const [checkAiSupport, setCheckAiSupport] = useState(false);
  const dispatch = useDispatch();
  const registeredCourseId = useSelector(
    (state: any) => state.userReducer.registeredCourseId
  );
  const handleTopicsOfChapter = (chapterItem: any) => {
    setChapterTopics([]);
    chapterItem?.topicSubTopics?.map((item: any, index: number) => {
      const {
        assignementstatus,
        mcqstatus,
        pattern_status,
        ppt_url,
        id,
        sl_no,
      } = item;
      // Here we have all the data of a Topic and if we want any data from the API we can modify this section
      setChapterTopics((prev: any) => [
        ...prev,
        {
          displayName: `${chapterItem.index}.${index + 1} ${item.name}`,
          icon: topicsVideoIcon,
          isPPTAvailable: ppt_url ? true : false,
          isMCQAvailable: mcqstatus,
          isPatternAvailable: pattern_status,
          id: id,
          pptUrl: ppt_url,
          name: `MCQ ${chapterNumber + count * (page - 1) + 1}.${index + 1}`,
          index: `${chapterItem.index}.${index + 1}`,
          topicSerialNumber: sl_no,
        },
      ]);

      if (assignementstatus) {
        setChapterTopics((prev: any) => [
          ...prev,
          {
            displayName: `${item.name} Assignment`,
            type: "assignment",
            icon: topicsAssignmentIcon,
            isPPTAvailable: false,
            isMCQAvailable: false,
            isPatternAvailable: false,
            name: `Assignment ${chapterNumber + count * (page - 1) + 1}.${
              index + 1
            }`,
            id: id,
            index: `${chapterNumber + count * (page - 1) + 1}.${index + 1}`,
            topicSerialNumber: sl_no,
          },
        ]);
      }
    });
  };
  useEffect(() => {
    // if (chapterItem.index === 1) chapterItem.student_progress = 100;
    handleTopicsOfChapter(chapterItem);
  }, [chapterItem]);

  const navigate = useNavigate();

  const handleViewTopicsAccordionClick = (event: any) => {
    openAccordion === chapterNumber
      ? setOpenAccordion(-1)
      : setOpenAccordion(chapterNumber);
    event.stopPropagation();
  };

  const cardClick = (event: any) => {
    navigate(routePaths.chapterDetails.replace(":chapterId", chapterItem.id));
  };

  const topicClick = (event: any, topicdData: any) => {
    event.stopPropagation();
    if (
      !(
        topicdData.type === "pre-assessment" || topicdData.type === "assignment"
      )
    ) {
      navigate(routePaths.learnVideoPlayer.replace(":topicId", topicdData.id));
    }
  };

  const questionData = useSelector(
    (state: any) => state.solveAssignmentReducer
  );

  useEffect(() => {
    setActicveQuestionIndex(questionData.activeQuestionIndex);
  }, [questionData.activeQuestionIndex]);

  const viewAllHandler = (topicdData: any) => {
    navigate(routePaths.chapterDetails.replace(":chapterId", chapterItem.id));
    dispatch({
      type: constants.LEARN,
      index: 1,
    });
  };

  const handleViewPatternMap = async (topicId: number, courseId: number) => {
    const payload = {
      url: ApiEdpoints.MIND_MAP + `?course_id=${courseId}&topic_id=${topicId}`,
      data: {},
      method: HTTPMethods.GET,
    };
    try {
      const res = await makeAxiosRequest(payload);
      if (res.status === 200) {
        const patternMapUrl = res?.data?.data[0]?.mindmap;
        window.open(patternMapUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.chapterCardWrapper} role="button">
      <div className={styles.chapterCardContentsWrapper} onClick={cardClick}>
        <div className={styles.chapterDetailsAndImageContainer}>
          <div className={styles.chapterCardNumberImage}>
            <div className={styles.chapterCardImage}>
              <img src={chapterImage} />
            </div>
            <div className={`${styles.chapterNumber} ${styles.inter14}`}>
              {`${constants.CHAPTER} ${chapterItem.index}`}
            </div>
          </div>
          <div className={styles.chapterNameResponsive}>{chapterItem.name}</div>
        </div>
        <div className={styles.cardContentSuggestionsAndHomework}>
          <div className={styles.cardContentAndSuggestions}>
            <div className={styles.chapterCardContent}>
              <div className={styles.chapterName}>{chapterItem.name}</div>
              {!!chapterItem.student_progress &&
                chapterItem.student_progress !== 0 && (
                  <div className={styles.progressBarContainer}>
                    <Progressbar
                      progress={
                        chapterItem.student_progress
                          ? chapterItem.student_progress
                          : 0
                      }
                      noOflevels={5}
                      levelsVisible={false}
                    />
                  </div>
                )}
              <div className={styles.assignmentTopicsContainer}>
                {/* NOT MVP TASK */}
                {/* <div className={styles.assignmentAndTopic}>
                  <div className={styles.iconContainer}>
                    <img
                      src={chapterCardAssignmentIcon}
                      className={styles.assignmentIcon}
                    />
                  </div>
                  <div> 0 {constants.TESTS}</div>
                </div> */}
                <div className={styles.assignmentAndTopic}>
                  <div className={styles.iconContainer}>
                    <img
                      src={assignmentIcon}
                      className={styles.topicIcon}
                      alt="topicIcon"
                    />
                  </div>
                  <div className={styles.topic}>
                    {chapterItem?.topicSubTopics?.length + 2}{" "}
                    {constants.ASSIGNMENTS}
                  </div>
                  <div className={styles.iconContainer}>
                    <img
                      src={topicIcon}
                      className={styles.topicIcon}
                      alt="topicIcon"
                    />
                  </div>
                  <div className={styles.topic}>
                    {chapterItem?.topicSubTopics?.length} {constants.TOPICS}
                  </div>
                </div>
              </div>
            </div>
            {chapterItem.student_progress === 100 && (
              <div className={styles.chapterCardCompletedMessage}>
                <p>
                  You understand all concepts of this chapter. You are just
                  getting started.Keep going!
                </p>
                <img src={MascotWriting} alt="" />
              </div>
            )}

            <div className={styles.chapterCardAbout}>
              {chapterItem.student_progress &&
              chapterItem.student_progress !== 0 ? (
                <div className={styles.suggestionContainer}>
                  {/* NOT MVP TASK */}
                  {/* {isSuggestionGiven && (
                    <>
                      <div className={styles.suggestionAndKnowMore}>
                        <div className={styles.suggestionText}>
                          <p>
                            Use 'Origin Number' pattern to solve the pending
                            assignment and increase your chapter mastery by 15%
                            <span
                              className={`${styles.knowMoreResponsive} ${styles.inter14Button}`}
                            >
                              {constants.KNOW_MORE}
                            </span>
                          </p>
                        </div>
                        <div
                          className={`${styles.knowMore} ${styles.inter14Button}`}
                        >
                          {constants.KNOW_MORE}
                        </div>
                      </div>

                      <div className={styles.robotIconContainer}>
                        <img
                          src={chapterListingRobotIcon}
                          className={styles.robotIcon}
                        />
                      </div>
                    </>
                  )} */}

                  <div className={styles.topicsContainer}>
                    <div
                      className={styles.topicsAccordionOpen}
                      onClick={handleViewTopicsAccordionClick}
                      role="button"
                    >
                      <div
                        className={`${styles.viewTopics} ${styles.inter14Button}`}
                      >
                        {constants.VIEW_TOPICS}
                      </div>
                      <div
                        className={
                          openAccordion === chapterNumber
                            ? styles.rotatedIcon
                            : styles.icon
                        }
                      >
                        <img src={chapterCardChevronBottom} />
                      </div>
                    </div>
                    <div>
                      <button
                        className={styles.viewSummary}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleViewPatternMap(
                            Number(chapterItem.id),
                            registeredCourseId
                          );
                        }}
                      >
                        View Summary
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`${styles.getStartedButtonContainer} ${styles.inter16}`}
                >
                  <button className={styles.getStartedButton}>
                    {constants.GET_STARTED_CHAPTER_LISTING}
                  </button>
                </div>
              )}
            </div>
          </div>
          {/* NOT MVP TASK */}
          {/* {isHomeworkGiven && isChapterStarted && (
            <div className={styles.homeworkContainer}>
              <div className={styles.homeworkIconContainer}>
                <img src={homeworkIcon} />
              </div>
              <div className={`${styles.homework} ${styles.inter14}`}>
                {constants.NEW_HOMEWORK_BY_FACULTY}
              </div>
              <div className={`${styles.solveNow} ${styles.inter14Button}`}>
                {constants.SOLVE_NOW}
              </div>
            </div>
          )} */}
        </div>
      </div>
      {openAccordion === chapterNumber &&
        chapterItem.student_progress &&
        chapterItem.student_progress !== 0 && (
          <div className={styles.accordionContainer}>
            {chapterTopics
              .filter((item) => item.type !== "assignment")
              .slice(0, 3)
              ?.map((item: any, key: any) => (
                <div
                  className={styles.accordionItem}
                  key={key}
                  onClick={(event) => {
                    topicClick(event, item);
                  }}
                  role="button"
                >
                  <div className={styles.topicAndIconContainer}>
                    <div className={styles.topicsIcon}>
                      <img src={item.icon} />
                    </div>
                    <div
                      onClick={() => {
                        if (item?.type === "assignment") {
                          setTopicIndex(item.index);
                          dispatch({
                            type: "UPDATE_ASSIGNMENT_LEVEL",
                            payload: 1,
                          });
                          setTopicId(item.id);
                          setTimeout(() => {
                            setShowQuestionListPopUp(true);
                          }, 100);
                        }
                      }}
                      className={styles.accordionItemName}
                    >
                      {item?.displayName}
                    </div>
                  </div>
                  <div className={styles.resourceContainer}>
                    <div
                      className={
                        item?.isPPTAvailable
                          ? styles.resource
                          : styles.resourceHidden
                      }
                      role="button"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleOverlays("ppt", item);
                      }}
                    >
                      <div className={styles.resourceItem}>
                        <img src={topicsPPTIcon} />
                      </div>
                      <div className={styles.resourceText}>{constants.PPT}</div>
                    </div>
                    <div
                      className={
                        item?.isMCQAvailable
                          ? styles.resource
                          : styles.resourceHidden
                      }
                      onClick={(event) => {
                        setTopicId(item.id);
                        event.stopPropagation();
                        handleOverlays("mcq", item);
                      }}
                    >
                      <div className={styles.resourceItem}>
                        <img src={topicsMCQIcon} />
                      </div>
                      <div className={styles.resourceText}>{constants.MCQ}</div>
                    </div>

                    <div
                      className={
                        item?.isPatternAvailable
                          ? styles.resource
                          : styles.resourceHidden
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <div className={styles.resourceText}>
                        <SolveExerciseButton
                          level={item.level}
                          id={item.id}
                          status={item.status || "pending"}
                        >
                          <>
                            <div className={styles.resourceItem}>
                              <img src={topicsPatternIcon} />
                            </div>
                            {constants.EXERCISE}
                          </>
                        </SolveExerciseButton>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <div className={styles.viewAllContainer} onClick={viewAllHandler}>
              <div className={styles.inter14Button}>{constants.VIEW_ALL}</div>
              <div>
                <img src={greenRightArrowIcon} />
              </div>
            </div>
          </div>
        )}
      {showQuestionListPopUp && (
        <SolveAssignmentQuestionListViewAll
          title={`Exercise ${topicIndex}`}
          closePopUp={() => {
            setCheckAiSupportModal(true);
            setShowQuestionListPopUp(!showQuestionListPopUp);
          }}
          activeQuestionIndex={acticveQuestionIndex}
          setActiveQuestionIndex={setActicveQuestionIndex}
          topicIdProp={topicId}
          fromExercisePage={true}
          aiSupport={checkAiSupport}
        />
      )}

      <Dialog
        open={checkAiSupportModal}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className={styles1.aiSupportModalWrapper}>
          <img src={ModalBg} alt="" />
          <div className={styles1.aiSupportModalContent}>
            <div className={styles1.aiSupportModalImage}>
              <img src={MascotRocket} alt="" />
            </div>
            <div className={styles1.aiSupportModalCheckbox}>
              <p>
                You are approaching the Genius Level! Time to master the
                concepts.
              </p>
              <div className={styles1.aiSupportToggle}>
                <p>
                  AI Support
                  <span>Toggle this for better support</span>
                </p>
                <Switch
                  checked={checkAiSupport}
                  onChange={() => {
                    setCheckAiSupport((state) => !state);
                  }}
                />
              </div>

              <button
                onClick={() => {
                  setShowQuestionListPopUp(!showQuestionListPopUp);
                }}
              >
                SOLVE NOW
              </button>
            </div>
          </div>

          <button
            onClick={() => {
              setCheckAiSupportModal(false);
            }}
          >
            <CloseRounded />
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default ChapterCardNew;
