import * as React from "react";
import { useEffect, useState } from "react";
import styles from "./linearProgressBar.module.scss";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import linearProgressCrossIcon from "../../../../../assets/images/linearProgressCrossIcon.svg";
import linearProgressDeleteIcon from "../../../../../assets/images/linearProgressDeleteIcon.svg";

export default function LinearProgressBar({
  uploadedFile,
  index,
  uploadedFileDeleteHandler,
}: any) {
  const [uploadeFileSize, setUploadedFileSize] = useState();
  const [progress, setProgress] = React.useState(0);
  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number; uploadeFileSize: any }
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            width: "100%",
            marginRight: "21px",
            maxWidth: "196px",
            "& .MuiLinearProgress-root": {
              borderRadius: "5px",
            },
            "& .css-5xe99f-MuiLinearProgress-bar1": {
              background:
                "linear-gradient(313.52deg, #2937A7 17.3%, #BAA6FF 92.18%)",
            },
            "& .css-eglki6-MuiLinearProgress-root ": {
              background: "#D0D8EF",
            },
          }}
        >
          <LinearProgress variant="determinate" {...props} />
        </Box>

        <div className={styles.progressText}>
          {progress === 100 ? (
            <>{props.uploadeFileSize}MB</>
          ) : (
            <div>{`${Math.round(progress)}%`}</div>
          )}
        </div>

        <div className={styles.crossDelete}>
          {progress === 100 ? (
            <img
              src={linearProgressDeleteIcon}
              onClick={() => uploadedFileDeleteHandler(index)}
            />
          ) : (
            <img
              src={linearProgressCrossIcon}
              onClick={() => uploadedFileDeleteHandler(index)}
            />
          )}
        </div>
      </Box>
    );
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress: any) =>
        prevProgress >= 100 ? 100 : prevProgress + 1
      );
    }, 10);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setUploadedFileSize(uploadedFile?.fileSize);
  }, [uploadedFile]);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel
        value={progress}
        uploadeFileSize={uploadeFileSize}
      />
    </Box>
  );
}
