import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import styles from "./mcqRange.module.scss";

const SliderApp = styled(Slider)(({ theme }) => ({
  height: 11,
  padding: "15px 0",
  "& .MuiSlider-thumb.MuiSlider-thumbSizeMedium": {
    height: 30,
    width: 30,
    background: "linear-gradient(313.52deg, #2937a7 17.3%, #baa6ff 92.18%)",
    left: "3%",
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 14,
    fontWeight: 500,
    top: 30,
    backgroundColor: "unset",
    color: "#ffffff",
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    background: "linear-gradient(313.52deg, #2937a7 17.3%, #baa6ff 92.18%)",
  },
  "& .MuiSlider-rail": {
    background: "#d0d8ef",
    height: "10px",
    borderRadius: "60px",
    boxShadow: "inset 1px 1px 6px rgba(0, 0, 0, 0.2)",
  },
  "& .css-up29m9": {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#4B5468",
    marginLeft: "-5px",
  },
}));

const LimitContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "-10px",
}));

const LimitValue = styled("div")(({ theme }) => ({
  display: "flex",
  fontSize: "16px",
  fontWeight: "500",
  color: "#4B5468",
}));

const formatNumber = (number: number) => {
  return (number < 10 ? "0" : "") + number;
};

interface McqRangeProps {
  minValue: number;
  currentValue: number;
  maxValue: number;
  sliderValue: (value: number) => void;
  item: any;
  steps: any;
  setSteps: React.Dispatch<React.SetStateAction<any[]>>;
}

const McqRange: React.FC<McqRangeProps> = ({
  minValue,
  currentValue = 1,
  maxValue,
  sliderValue,
  item,
  steps,
  setSteps,
}) => {
  const [value, setValue] = useState<number>(currentValue);

  const handleChange = (event: Event, newValue: number | number[]) => {
    sliderValue(newValue as number);
    setValue(newValue as number);
    const newSteps = steps?.map((step: any) => {
      step.questions &&
        step?.questions.map((question: any) => {
          if (question.id === item.id) {
            question.answer = newValue.toString();
          }
          return question;
        });
      return step;
    });
    setSteps(newSteps);
  };

  return (
    <div className={styles.container}>
      <div className={styles.question}>{item.question}</div>
      <div className={styles.rangeSliderContainer}>
        <SliderApp
          valueLabelDisplay="on"
          max={maxValue}
          value={value}
          valueLabelFormat={formatNumber(value)}
          onChange={handleChange}
        />
        <LimitContainer>
          <LimitValue>{minValue}</LimitValue>
          <LimitValue>{maxValue}</LimitValue>
        </LimitContainer>
      </div>
    </div>
  );
};

export default McqRange;
