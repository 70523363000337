import classes from "./pptViewer.module.scss";

export const PPTViewer = ({ source }: any) => {
  const onDocumentLoadSuccess = () => {
    setTimeout(() => {
      let frame: any = window.frames;
      if (
        frame["pptView"]?.document?.getElementsByClassName(
          "cui-toolbar-button-left"
        )
      ) {
        frame["pptView"].document.getElementsByClassName(
          "cui-toolbar-button-left"
        ).style.display = "none";
      }

      var cssLink = document.createElement("link");
      cssLink.href = "./pptViewer.module.scss";
      cssLink.rel = "stylesheet";
      cssLink.type = "text/css";
      frame["pptView"]?.length &&
        frame["pptView"][0]?.document?.head?.appendChild(cssLink);
    }, 500);
  };

  return (
    <div className={classes.pptWrapper}>
      <iframe
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${source}`}
        id="pptView"
        frameBorder="0"
        onLoad={onDocumentLoadSuccess}
        loading="eager"
        className={classes.pptContent}
      ></iframe>
    </div>
  );
};
