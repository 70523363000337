import React, { useState } from "react";
import UploadedFilesDisplayModal from "../../assignmentOptions/descriptiveQuestion/uploadedFilesDisplayModal/uploadedFilesDisplayModal";
import classes from "./descriptiveReviewQuestion.module.scss";

export const DescriptiveReviewQuestion = ({
  question,
  activeQuestionIndex,
  type,
  userType = "student",
}: any) => {
  const [isStudentImagesPopUp, setIsStudentImagesPopUp] = useState(false);
  const [isTeacherImagesPopUp, setIsTeacherImagesPopUp] = useState(false);
  const [activeFileIndex, setActiveFileIndex] = React.useState(0);
  const handleCloseModalStudent = () => {
    setIsStudentImagesPopUp(false);
  };
  const handleCloseModalTeacher = () => {
    setIsTeacherImagesPopUp(false);
  };
  let solutions = question[activeQuestionIndex]?.solutions;
  if (
    !question[activeQuestionIndex]?.solutions?.length &&
    question[activeQuestionIndex]?.uploadedImages?.length
  ) {
    solutions = question[activeQuestionIndex].uploadedImages.map(
      (item: any) => {
        return item.preview;
      }
    );
  }
  return (
    <div className={classes.container}>
      {(question[activeQuestionIndex]?.student_comment ||
        (solutions && solutions.length !== 0)) && (
        <div className={classes.assignmentUploadContainer}>
          {question[activeQuestionIndex]?.student_comment && (
            <>
              <div className={classes.textComment}>
                {" "}
                {userType === "mentor" ? "Student Comments" : "Your Comments"}
              </div>
              <div className={classes.textCommentDesc}>
                {" "}
                {question[activeQuestionIndex]?.student_comment}
              </div>
            </>
          )}
          {solutions && solutions?.length !== 0 && (
            <>
              <div className={classes.yourUpload}>
                {" "}
                {userType === "mentor"
                  ? "Student's uploads"
                  : "Your Uploads"}{" "}
              </div>
              <div className={classes.containerOption}>
                {solutions &&
                  solutions?.map((item: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className={classes.options}
                        onClick={() => {
                          setIsStudentImagesPopUp(!isStudentImagesPopUp);
                          setActiveFileIndex(index);
                        }}
                      >
                        <div className={classes.pageIndex}>
                          Page {index + 1 > 9 ? "" : "0"}
                          {index + 1}
                        </div>
                        <div className={classes.uploadedImage}>
                          <img src={item} alt="upload" />
                        </div>
                        {/* {
                    isStudentImagesPopUp &&
                    <UploadedFilesDisplayModal
                      key={index}
                      open={isStudentImagesPopUp}
                      handleClose={handleCloseModalStudent}
                      activeFileIndex={activeFileIndex}
                      uploadedFiles={question[activeQuestionIndex].uploadedImages}
                      setActiveFileIndex={setActiveFileIndex} />
                  } */}
                      </div>
                    );
                  })}
              </div>
              <UploadedFilesDisplayModal
                key={1}
                open={isStudentImagesPopUp}
                handleClose={handleCloseModalStudent}
                activeFileIndex={activeFileIndex}
                setActiveFileIndex={setActiveFileIndex}
                uploadedFiles={solutions}
              />
            </>
          )}
        </div>
      )}
      {type === "result" &&
        (question[activeQuestionIndex]?.mentor_comments ||
          (question[activeQuestionIndex]?.mentor_images &&
            question[activeQuestionIndex]?.mentor_images.length !== 0)) && (
          <div
            className={`${classes.facultySection} ${classes.assignmentUploadContainer}`}
          >
            {question[activeQuestionIndex]?.mentor_comments && (
              <>
                <div className={classes.textComment}>
                  {userType === "mentor" ? "Your Comments" : "Faculty Comments"}
                </div>
                <div className={classes.textCommentDesc}>
                  {question[activeQuestionIndex]?.mentor_comments}
                </div>
              </>
            )}
            {question[activeQuestionIndex]?.mentor_images &&
              question[activeQuestionIndex]?.mentor_images.length !== 0 && (
                <>
                  <div className={classes.yourUpload}>
                    {userType === "mentor" ? "Your Uploads" : "Faculty Uploads"}
                  </div>
                  <div className={classes.containerOption}>
                    {question[activeQuestionIndex]?.mentor_images?.length !=
                      0 &&
                      question[activeQuestionIndex]?.mentor_images?.map(
                        (item: any, index: any) => {
                          return (
                            <div
                              key={index}
                              className={classes.options}
                              onClick={() => {
                                setIsTeacherImagesPopUp(!isTeacherImagesPopUp);
                                setActiveFileIndex(index);
                              }}
                            >
                              <div className={classes.pageIndex}>
                                Page {index + 1 > 9 ? "" : "0"}
                                {index + 1}
                              </div>
                              <div className={classes.uploadedImage}>
                                <img src={item.preview ?? item} alt="upload" />
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                  <UploadedFilesDisplayModal
                    key={1}
                    open={isTeacherImagesPopUp}
                    handleClose={handleCloseModalTeacher}
                    activeFileIndex={activeFileIndex}
                    setActiveFileIndex={setActiveFileIndex}
                    uploadedFiles={question[activeQuestionIndex]?.mentor_images}
                  />
                </>
              )}
          </div>
        )}
    </div>
  );
};
