import React, { useRef, useState } from "react";
import styles from "./myStats.module.scss";
import { constants } from "../../constants/constants";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import Tab from "../../common/tab/tab";
import MyStatsPattern from "./components/myStatsPattern/myStatsPattern";
import MyStatsChapters from "./components/myStatsChapters/myStatsChapters";
import { useSelector } from "react-redux";

const MyStats = () => {
  const detailedViewRef = useRef(null);
  const selectedIndexData = useSelector(
    (state: any) => state.selectedTabReducer
  );
  const [indexToViewAllTabsNumber, setIndexToViewAllTabsNumber] = useState(
    selectedIndexData.stats
  );
  const myStatTitles = [constants.PATTERNS, constants.CHAPTERS];
  const myStatContents = [
    <MyStatsPattern detailedViewRef={detailedViewRef} />,
    <MyStatsChapters detailedViewRef={detailedViewRef} />,
  ];

  return (
    <div className={styles.myStatsMainContainer} ref={detailedViewRef}>
      <Breadcrumb
        breadcrumbDetails={constants.MYSTATS_DETAILS_BREADCRUMB_DATA}
      />
      <Tab
        indexToViewAllTabsNumber={indexToViewAllTabsNumber}
        setIndexToViewAllTabsNumber={setIndexToViewAllTabsNumber}
        titles={myStatTitles}
        contents={myStatContents}
        id={constants.STATS}
      />
    </div>
  );
};

export default MyStats;
