import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";

export const enum HTTPMethods { POST = 'POST', GET = "GET", PUT = "PUT", DELETE = "DELETE" }
interface RequestPayload {
    method: string;
    url: string;
    data: any;
    headers?: any;
}
export const makeAxiosRequest = async (payload: RequestPayload,baseUrl:string = 'https://new-mathbked.aieducator.com/', bearer?:boolean) => {

    payload.url = `${baseUrl}${payload.url}`;
    payload.headers = {
        'Content-Type': 'application/json',
    }

    // add token to header if the user is authenticated.
    let token: string | null = '';
    try {
        token = localStorage.getItem('token');
        AsyncStorage.getItem('token').then(res => {
            token = res;
        });
    } catch (error) {
        console.log(error);
    }

    if (token) payload.headers['Authorization'] = `${bearer ? "Bearer":"token"} ${token}`

    try {
        const res = await axios(payload);
        return res;
    } catch (err: any) {
        if (err.response) {
            /*
              The request was made and the server responded with a status code
              that falls out of the range of 2xx
            */
            throw err.response.data;
        } else if (err.request) {
            // Client never received a response, or request never left
            throw err.request;
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(`Error: ${err.message}`);
        }
    }
}
