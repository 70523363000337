import React from "react";
import styles from "./studentSubmissionCards.module.scss";
import timerLoadingIcon from "../../../../../../../assets/images/timerLoadingIcon.svg";
import CardDetailsIconBox from "../../../../../../../common/cardDetailsIconBox/cardDetailsIconBox";
import questionsIcon from "../../../../../../../assets/images/questionsIcon.svg";
import submitedByIcon from "../../../../../../../assets/images/submitedByIcon.svg";
import timerIcon from "../../../../../../../assets/images/timerIcon.svg";

import { constants } from "../../../../../../../constants/constants";
import { QuestionAndOptionsElement } from "../../../../../../../common/questionsAndOptions/questionAndOptions";

const StudentSubmissionCards = ({ item }: any) => {
  const getFirstLetterCaps = (name: string) => {
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  return (
    <div className={styles.studentSubmissionCardsContainer}>
      <div className={styles.studentSubmissionCardItemWrap}>
        <div className={styles.iconStatusContainer}>
          <div className={styles.statusIcon}>
            <img src={timerLoadingIcon} className={styles.img} />
          </div>
          <div className={styles.status}>
            {getFirstLetterCaps(item?.status)}
          </div>
        </div>
        <div className={styles.titleDescriptionStatusContainer}>
          <div className={styles.titleDescriptionContainer}>
            <div className={styles.assignmentTitle}>
              <QuestionAndOptionsElement
                metaData={item?.question}
                size="question"
              />
            </div>
            <div className={styles.questionsDateContainer}>
              <div className={styles.questionDateComponentWrapper}>
                <CardDetailsIconBox
                  icon={questionsIcon}
                  text={getFirstLetterCaps(item?.assignmentIndex)}
                  gapInBetween={"6px"}
                />
              </div>
              <div className={styles.questionDateComponentWrapper}>
                <CardDetailsIconBox
                  icon={submitedByIcon}
                  text={`Submitted on ${item?.submittedOn}`}
                  gapInBetween={"6px"}
                />
              </div>
              <div className={styles.questionDateComponentWrapper}>
                <CardDetailsIconBox
                  icon={timerIcon}
                  text={`Time Taken ${item?.timeTaken}`}
                  gapInBetween={"6px"}
                />
              </div>
            </div>
          </div>

          <div className={styles.reviewNowContainer}>
            <div className={styles.reviewNowText}>{constants.REVIEW_NOW}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(StudentSubmissionCards);
