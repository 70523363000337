import React from "react";
import closeBtn from "../../../assets/images/closeIcon.svg";
import styles from "./adminSideBar.module.scss";
// import styles from "../../admin/teachersDetailsSideBar/teachersDetailsSideBar.module.scss";

const AdminSideBar = ({ changeStatus, status, children, title }: any) => {
    return (
        <>
            {status && (
                <div className={`${styles.teachersDetailsOverlay} `}>
                    <div className={styles.container}>
                        <div className={styles.head}>
                            <h1>{title}</h1>
                            <img
                                onClick={changeStatus}
                                src={closeBtn}
                                alt=""
                            />
                        </div>
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};

export default AdminSideBar;
