import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { makeAxiosRequest } from "aie_common";
import Breadcrumb from "../../../../common/breadcrumb/breadcrumb";
import MyStatsQuestionCard from "../myStatsQuestionCard/myStatsQuestionCard";
import styles from "./myStatsPatternAndQuestion.module.scss";
import FilterBar from "../../../../common/filterBar/filterBar";
import { CustomPagination } from "../../../../common/pagination/customPagination";
import strongImg from "../../../../assets/images/strongHeading.svg";
import question from "../../../../assets/images/strongQuestion.svg";
import aiHelpIcon from "../../../../assets/images/strongAiHelp.svg";
import aiReadingBook from "../../../../assets/images/aiReadingImg.svg";
import weakMasteryIcon from "../../../../assets/images/weakMasteryIcon.svg";
import { constants } from "../../../../constants/constants";
import StatusContext from "../../../../context/loaderContext";
import ApiEdpoints, { HTTPMethods } from "../../../../constants/api_endpoints";
import { getMasterType, levelPercentage } from "../../../../utils/masteryLevel";
import { useParams } from "react-router-dom";
import useSorting from "../../../../utils/useSorting";
import { calculateTimeTaken } from "../../../../utils/convertTime";

const MyStatsPatternAndQuestion = () => {
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [patternQuestionData, setPatternQuestionsData] = useState<object[]>([]);
  const [initialSelectedPatternsValues, setUpdatedSelectedPatternValues] =
    useState<{ index: number; value: string; id: any }[]>([]);
  const [chapterDetailstData, setChapterDetailstData] = useState<any>();
  const [dropdownArray, setDropdownArray] = React.useState([]);
  const [chapterDropDownValueWithId, setChapterDropDownValueWithId] =
    React.useState<any>({});
  const [levelDropDownValueWithId, setLevelDropDownValueWithId] =
    React.useState<any>({});
  const [patternOptions, setPatternOptions] = useState<any>();
  const loaderStatus: any = useContext(StatusContext);
  const registeredCourseId = useSelector(
    (state: any) => state.userReducer?.registeredCourseId
  );

  const [selectedPatternCard, setSelectedPatternCard] = useState<any>();
  const { statsId } = useParams();
  const [patternId, setPatternId] = useState<any>(statsId && statsId);

  const fetchChaptersList = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.CHAPTER_LIST}${registeredCourseId}/`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);

      if (
        response &&
        response.status === 200 &&
        response?.data?.status === "Sucess"
      ) {
        const levelsData = [
          { name: "All Levels", id: 0 },
          { name: "Easy", id: 1 },
          { name: "Medium", id: 2 },
          { name: "Hard", id: 3 },
        ];

        const chapterDetailsDataRes = response?.data?.data;
        setChapterDetailstData(chapterDetailsDataRes);

        const chaptersNamesData = chapterDetailsDataRes
          ? chapterDetailsDataRes?.courseTopics?.reduce(
              (arr: [{}], obj: any) => {
                arr.push({ name: obj?.name, id: obj?.id });
                return arr;
              },
              [{ name: "All Chapters", id: 0 }]
            )
          : [];

        const patterns = [
          { data: chaptersNamesData, defaultLabel: "All Chapters" },
          { data: levelsData, defaultLabel: "All Levels" },
        ];
        setPatternOptions(patterns);
        const patternPropsData = patterns
          ? patterns?.map((x: any, i: number) => ({
              index: i,
              value: "",
              id: "",
            }))
          : [];
        setUpdatedSelectedPatternValues(patternPropsData);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const fetchCurrentPattern = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.PATTERN_MASTERY}?id=${patternId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);

      if (
        response &&
        response.status === 200 &&
        response?.data?.status === "success"
      ) {
        setSelectedPatternCard(response?.data?.data);
        setPatternId(response?.data?.data?.pattern_id);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const currentPatternQuestions = async (id: any) => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.PATTERN_MASTERY_QUESTIONS_LIST}/${id}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const { data }: any = await makeAxiosRequest(payload);
      if (data && data?.status === "success") {
        selectedPatternCard &&
          setSelectedPatternCard({
            ...selectedPatternCard,
            patternQuestions: data?.question_list,
          });
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    if (registeredCourseId) fetchChaptersList();
  }, [registeredCourseId]);

  useEffect(() => {
    fetchCurrentPattern();
  }, []);
  useEffect(() => {
    if (patternId) {
      currentPatternQuestions(patternId);
    }
  }, [patternId]);

  const provideClassName = (type: string) => {
    switch (type) {
      case "Primary":
        return styles.myStatPatternHeadingCardDifficultyContainerPrimary;
      case "Secondary":
        return styles.myStatPatternHeadingCardDifficultyContainerSecondary;
      case "Tertiary":
        return styles.myStatPatternHeadingCardDifficultyContainerTertiary;
      default:
        return styles.myStatPatternHeadingCardDifficultyContainerPrimary;
    }
  };
  const setUpdatesSelectedPatterns = (updatedArrValues: any) => {
    setUpdatedSelectedPatternValues(updatedArrValues);
  };
  useEffect(() => {
    const patternPropsData = selectedPatternCard?.patternQuestions
      ? selectedPatternCard?.patternQuestions?.map((x: any, i: number) => ({
          index: i,
          value: "",
          id: "",
        }))
      : [];
    setUpdatedSelectedPatternValues(patternPropsData);
    setPatternQuestionsData(
      selectedPatternCard?.patternQuestions
        ? [...selectedPatternCard?.patternQuestions]
        : []
    );
  }, [selectedPatternCard]);

  const [masteryLevel, setMasteryLevel] = React.useState("L1");

  useEffect(() => {
    if (selectedPatternCard?.mastery_stage) {
      setMasteryLevel(
        selectedPatternCard?.mastery_stage
          ? `${selectedPatternCard?.mastery_stage.split("_")[2]}`
          : masteryLevel
      );
    }
  }, [selectedPatternCard]);

  const dataMassaging = (data: any) => {
    const dataMassaged = data?.map((x: any, i: number) => {
      return {
        index: i,
        aiHelpCount:
          x?.AI_help_used_count.length > 0 ? x?.AI_help_used_count : 0,
        AttemptedCount: x?.Attempted_count,
        Capability: x?.Capability,
        id: x?.id,
        level: x?.level,
        patternsIncluded:
          x?.pattens_included.length > 0 ? x?.pattens_included : [],
        question: x?.question,
        questionImg: x?.question_img,
        solvedCount: x?.solved_count,
        subtopicId: x?.subtopic_id,
        time_taken: calculateTimeTaken(x?.time_taken),
        timeGiven: calculateTimeTaken(x?.timegiven),
        topicId: x?.topic_id,
        isDiagram: x?.isDiagram ? x?.isDiagram : false,
        topicNumber: x?.topicNumber ? x?.topicNumber : false,
        questionId: x?.topic_id,
      };
    });
    return dataMassaged;
  };

  const {
    dropdownSelectedValueWithId,
    setDropdownSelectedValueWithId,
    filteredTopics,
    page,
    paginationStart,
    paginationEnd,
    range,
    handlePaginationChange,
    detailedViewRef,
    isStatusSelected,
  }: any = useSorting(
    selectedPatternCard?.patternQuestions,
    selectedStatus,
    "",
    "", // search filter key
    dataMassaging,
    8, // range,
    dropdownArray
  );

  useEffect(() => {
    detailedViewRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    if (dropdownSelectedValueWithId) {
      if (dropdownSelectedValueWithId?.defaultLabel === "All Chapters") {
        setChapterDropDownValueWithId(dropdownSelectedValueWithId);
      } else if (dropdownSelectedValueWithId?.defaultLabel === "All Levels") {
        setLevelDropDownValueWithId(dropdownSelectedValueWithId);
      }
    }
  }, [dropdownSelectedValueWithId]);
  useEffect(() => {
    let arr: any = [];
    if (patternOptions && patternOptions.length > 0) {
      patternOptions.map((x: any) => {
        if (x?.defaultLabel === chapterDropDownValueWithId?.defaultLabel) {
          arr.push({ ...x, chapterDropDownValueWithId });
        } else if (x?.defaultLabel === levelDropDownValueWithId?.defaultLabel) {
          arr.push({ ...x, levelDropDownValueWithId });
        }
      });
    }
    setDropdownArray(arr);
  }, [chapterDropDownValueWithId, levelDropDownValueWithId, patternOptions]);

  return (
    <>
      <div className={styles.myStatsPatternAndQuestionsMainWrap}>
        <Breadcrumb
          breadcrumbDetails={[
            {
              label: "Home",
              link: "/home",
              selected: false,
            },
            {
              label: "My Stats",
              link: "/my-stats",
              selected: false,
            },
            {
              label: "Pattern Stats",
              link: "",
              selected: true,
            },
          ]}
        />
        {selectedPatternCard && (
          <>
            <div className={styles.questionHeadingMainContainer}>
              <div className={styles.questionHeadingDataContainer}>
                <div className={styles.questionHeadDifficultyContainer}>
                  <div className={styles.myStatPatternName}>
                    {selectedPatternCard?.pattern_name}
                  </div>
                  <div
                    className={provideClassName(
                      selectedPatternCard?.patternImportance
                    )}
                  >
                    <div
                      className={styles.myStatPatternHeadingCardDifficultyName}
                    >
                      {selectedPatternCard?.patternImportance}
                    </div>
                  </div>
                </div>
                <div className={styles.questionHeadHorizontalLine}></div>
                <div className={styles.questionDetailsContainer}>
                  <div className={styles.questionHeadMasteryContaner}>
                    <div className={styles.img}>
                      <img
                        alt=""
                        src={
                          getMasterType(masteryLevel) === "Strong"
                            ? strongImg
                            : weakMasteryIcon
                        }
                      />
                    </div>
                    <div>
                      <div className={styles.questionHeadMasteryName}>
                        {getMasterType(masteryLevel)}
                      </div>
                      <div className={styles.questionHeadMasteryNameLow}>
                        {constants.masteryIsText}
                      </div>
                    </div>
                  </div>
                  <div className={styles.questionHeadMasteryContaner}>
                    <div className={styles.img}>
                      <img src={question} alt="" />
                    </div>
                    <div>
                      <div className={styles.questionHeadMasteryName}>
                        {selectedPatternCard?.attempted}/
                        {selectedPatternCard?.no_of_questions}
                      </div>
                      <div className={styles.questionHeadMasteryNameLow}>
                        {constants.questionsAttempted}
                      </div>
                    </div>
                  </div>
                  <div className={styles.questionHeadMasteryContaner}>
                    <div className={styles.img}>
                      <img src={aiHelpIcon} alt="" />
                    </div>
                    <div>
                      <div className={styles.questionHeadMasteryName}>
                        {selectedPatternCard?.AI_count}
                      </div>
                      <div className={styles.questionHeadMasteryNameLow}>
                        {constants.aiHelpUsed}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.imageProgressContainer}>
                <div className={styles.questionHeadingProgressContainer}>
                  <div
                    className={styles.myStatPatternCardProgressNameContainer}
                  >
                    <div className={styles.myStatPatternCardProgressText}>
                      {constants.mastery}
                    </div>
                    <div className={styles.myStatPatternCardProgressText}>
                      {masteryLevel}
                    </div>
                  </div>
                  <div className={styles.myStatPatternCardProgressWrap}>
                    <div
                      className={styles.myStatPatternCardProgressMastery}
                      style={{ width: levelPercentage(masteryLevel) }}
                    ></div>
                  </div>
                  {/* <div
                    className={styles.myStatPatternCardProgressNameContainer}
                  >
                    <div className={styles.myStatPatternCardProgressText}>
                      {constants.competency}
                    </div>
                    <div className={styles.myStatPatternCardProgressText}>
                      {`L${selectedPatternCard?.competency < 1
                        ? 1
                        : selectedPatternCard?.competency
                        }`}
                    </div>
                  </div> */}
                  {/* <div className={styles.myStatPatternCardProgressWrap}>
                    <div
                      className={styles.myStatPatternCardProgressCompetency}
                      style={{
                        width: levelPercentage(
                          `L${selectedPatternCard?.competency}`
                        ),
                      }}
                    ></div>
                  </div> */}
                </div>
                <div className={styles.questionHeadingImageContainer}>
                  <img src={aiReadingBook} alt="" />
                </div>
              </div>
            </div>
            <div className={styles.filtersAndTitleTextContainer}>
              <div className={styles.patternTitleText}>
                {constants.myPatternsQuestionsText}
              </div>
              <div ref={detailedViewRef}>
                <FilterBar
                  showSorting={false}
                  patternsData={patternOptions ? patternOptions : []}
                  initialSelectedPatternsValues={initialSelectedPatternsValues}
                  selectedStatus={(selectedStatuses: string[]) =>
                    setSelectedStatus(selectedStatuses)
                  }
                  setUpdatedSelectedPatternValues={setUpdatesSelectedPatterns}
                  setDropdownSelectedValueWithId={
                    setDropdownSelectedValueWithId
                  }
                />
              </div>
            </div>
          </>
        )}
        {
          <div className={styles.patternQuestionsMainWrapper}>
            {filteredTopics?.length > 0 &&
              filteredTopics
                ?.slice(paginationStart, paginationEnd)
                ?.map((each: any, ind: number) => (
                  <div
                    className={styles.myStatsQuestionCardWrap}
                    key={`patternQuestion_${ind}`}
                  >
                    <MyStatsQuestionCard
                      data={each}
                      statsCoveredName={"Chapter"}
                    />
                  </div>
                ))}
            {filteredTopics?.length === 0 && "No Data Found"}
          </div>
        }
        {filteredTopics?.length > 0 && (
          <CustomPagination
            page={page}
            handlePaginationChage={handlePaginationChange}
            count={Math.ceil(filteredTopics?.length / range)}
          />
        )}
      </div>
    </>
  );
};
export default React.memo(MyStatsPatternAndQuestion);
