import { useState, useCallback, useContext } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import configureStore from "../../store/index";
import { useForm } from "react-hook-form";
import classes from "./login.module.scss";
import mailIcon from "../../assets/images/mailIcon.svg";
import lockIcon from "../../assets/images/lockIcon.svg";
import logo from "../../assets/images/logoLogin.svg";
import { constants } from "../../constants/constants";
import { routePaths } from "../../constants/routes";
import planetIcon from "../../assets/images/vectary-texture-icon.svg";
import StatusContext from "../../context/loaderContext";
import openEye from "../../assets/images/openEye.svg";
import closeEye from "../../assets/images/closeEye.svg";
import { HTTPMethods, makeAxiosRequest } from "../../utils/httpService";

const Login = () => {
  const { dispatch } = configureStore();
  const navigate = useNavigate();
  const loaderStatus: any = useContext(StatusContext);
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [isError, setIsError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const submit = useCallback(async (formData: any) => {
    // setIsError(false);
    // const validateEmaill = validateEmail(formData.username);
    // const validatePasswordl = validatePassword(formData.password);

    // if (!(validateEmaill && validatePasswordl)) {
    //   return;
    // }

    loaderStatus.setState({ show: true });
    let baseUrl = "https://api.aieducator.com/";
    const payload = {
      //url: ApiEdpoints.LOGIN,
      url: "user/login/",
      data: {
        username: formData.username,
        password: formData.password,
      },
      method: HTTPMethods.POST,
    };

    try {
      const res = await makeAxiosRequest(payload, baseUrl);
      loaderStatus.setState({ show: false });
      if (res) {
        localStorage.setItem(constants.LOGGEDIN, "1");
        localStorage.setItem(constants.USERTYPE, res?.data?.user_type || "");
        localStorage.setItem(constants.TOKEN, res?.data?.token);
        dispatch({
          type: constants.ADMINDATA,
          payload: {
            userData: res?.data?.user_data || {},
          },
        });
        dispatch({ type: constants.LOGIN });
        navigate(`${routePaths.learn}`);
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      setIsError(true);
      console.log(error);
    }
  }, []);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: { username: "", password: "" },
  });

  const validateEmail = (value: string) => {
    //const regEx =
    // /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regEx = /^[a-zA-Z0-9_-]{3,16}$/;
    let status = true;
    setValidEmail(status);
    return status;
  };

  const validatePassword = (value: string) => {
    var regex = /\s/;
    let status = regex.test(value);
    setValidPassword(!status);
    return !status;
  };

  const onChangeField = useCallback(
    (name: any) => (event: any) => {
      //setIsError(false);
      setValue(name, event.target.value);
    },
    []
  );

  return (
    <>
      <section className={classes.loginWrapper}>
        <div className={classes.planetIcon}>
          <img src={planetIcon} alt="icon" />
        </div>
        <div className={classes.logoSection}>
          <div className={classes.logoWrap}>
            <img src={logo} alt="logo" height="60px" width="300px" />
          </div>
        </div>
        <div className={`${classes.title} ${classes.inter46}`}>
          Empowering Schools, Enabling Students
        </div>
        <div className={classes.formSection}>
          <form className={classes.loginform}>
            <div className={`${classes.inter32} ${classes.loginHeader}`}>
              Login
            </div>
            <div className={`${classes.inter16} ${classes.loginSubHeader}`}>
              To keep connected with us please login!
            </div>
            <div
              className={`${classes.inputField} ${
                isError || !validEmail ? classes.borderRed : null
              }`}
            >
              <img className={classes.icon} src={mailIcon} alt="mail" />
              <input
                className={classes.inter16}
                {...register("username", { required: constants.required })}
                placeholder="Email Id"
                onChange={onChangeField("username")}
              />
            </div>
            {errors.username?.message ? (
              <div className={classes.errorMessage}>
                {errors.username?.message.toString()}
              </div>
            ) : (
              ""
            )}
            {!validEmail && !errors.username?.message && (
              <div className={classes.errorMessage}>{"Invalid Email Id"}</div>
            )}
            <div
              className={`${classes.inputField} ${classes.password} ${
                isError || !validPassword ? classes.borderRed : null
              }`}
            >
              <img className={classes.icon} src={lockIcon} alt="lock" />
              <input
                className={`${classes.inter16} `}
                {...register("password", { required: constants.required })}
                placeholder="Password"
                onChange={onChangeField("password")}
                type={showPassword ? "text" : "password"}
              />
              <img
                className={`${classes.icon} ${classes.eyeIcon}`}
                src={showPassword ? closeEye : openEye}
                alt="lock"
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: "pointer" }}
              />
            </div>
            {!validPassword && !errors.password?.message && (
              <div className={classes.errorMessage}>{"Invalid Password"}</div>
            )}

            {errors.password?.message ? (
              <div className={classes.errorMessage}>
                {errors.password?.message?.toString()}
              </div>
            ) : (
              ""
            )}
            {isError && (
              <div className={classes.errorMessage}>
                {"Invalid Email Id or Password"}
              </div>
            )}
            <div className={`${classes.forgotPassword} ${classes.inter16}`}>
              Forgot password ?
            </div>
            <div
              className={`${classes.primaryButtonWrap} ${classes.submitButton}`}
            >
              <button
                type="submit"
                // disabled={!isValid}
                className={`${classes.primaryButton} ${classes.loginButton}`}
                onClick={handleSubmit(submit)}
              >
                LOGIN
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  authState: state.authState,
});

export default connect(mapStateToProps)(Login);
