import React from "react";
import styles from "./myStatsPatternCard.module.scss";
import chapterCardShapes from "../../../../../assets/images/myStatPatrernImg.svg";
import strongImg from "../../../../../assets/images/strong.svg";
import question from "../../../../../assets/images/questionsAssignment.svg";
import aiHelpIcon from "../../../../../assets/images/aiHelp.svg";
import { constants } from "../../../../../constants/constants";
import { QuestionAndOptionsElement } from "../../../../../common/questionsAndOptions/questionAndOptions";
import { getLevel } from "../../../../../utils/masteryLevel";

const MyStatsPatternCard = (props: any) => {
  const provideClassName = (type: string) => {
    switch (type) {
      case "Primary":
        return styles.myStatPatternCardDifficultyContainerPrimary;
      case "Secondary":
        return styles.myStatPatternCardDifficultyContainerSecondary;
      case "Tertiary":
        return styles.myStatPatternCardDifficultyContainerTertiary;
      default:
        return styles.myStatPatternCardDifficultyContainerPrimary;
    }
  };

  return (
    <div
      className={styles.myStatPatternCardContainer}
      onClick={() => props.handlePattern(props.data)}
    >
      <div className={styles.myStatPatternCardImgContiner}>
        <div className={styles.myStatImageContainer}>
          <div>
            <img src={chapterCardShapes} alt="img" />
          </div>
        </div>
      </div>
      <div className={styles.myStatPatternCardDataContainer}>
        <div className={styles.myStatPatternCardTopDataContainer}>
          <div
            className={provideClassName(
              props.data.patternImportance?.charAt(0).toUpperCase() +
                props.data.patternImportance.slice(1)
            )}
          >
            <div className={styles.myStatPatternCardDifficultyName}>
              {props.data.patternImportance?.charAt(0).toUpperCase() +
                props.data.patternImportance.slice(1)}
            </div>
          </div>
          <div className={styles.myStatPatternCardDifficultyType}>
            <QuestionAndOptionsElement
              metaData={props.data.patternName}
              size="pattern"
            />
          </div>
        </div>

        <div className={styles.myStatPatternCardHorizontalLine}></div>
        <div className={styles.myStatPatternCardBottomDataContainer}>
          <div className={styles.myStatPatternCardMasteryContainer}>
            <div>
              <img src={strongImg} alt="strong" />
            </div>
            <div className={styles.myStatPatternCardMasteryDes}>
              {constants.masteryIsTextStats} is &nbsp;
              <span className={styles.mystatsBold}>
                {props.data.mastery ? "Strong" : "Weak"}
              </span>
            </div>
          </div>
          <div className={styles.myStatPatternCardMasteryContainer}>
            <div>
              <img src={question} alt="question" />
            </div>
            <div className={styles.myStatPatternCardMasteryDes}>
              <span className={styles.mystatsBold}>
                {props.data.questionsAttemptedCount < 10
                  ? `0${props.data.questionsAttemptedCount}`
                  : props.data.questionsAttemptedCount}
                /
                {props.data.questionCount < 10
                  ? `0${props.data.questionCount}`
                  : props.data.questionCount}
              </span>{" "}
              {constants.questionsAttempted}
            </div>
          </div>

          <div className={styles.myStatPatternCardMasteryContainer}>
            <div>
              <img src={aiHelpIcon} alt="ai help" />
            </div>
            <div className={styles.myStatPatternCardMasteryDes}>
              <span className={styles.mystatsBold}>
                {props.data.aiHelpCount < 10
                  ? `0${props.data.aiHelpCount}`
                  : props.data.aiHelpCount}
              </span>
              {constants.aiHelpUsed}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.myStatPatternCardProgressMain}>
        <div>
          <div className={styles.myStatPatternCardProgressNameContainer}>
            <div className={styles.myStatPatternCardProgressText}>
              {constants.mastery}
            </div>
            <div className={styles.myStatPatternCardProgressText}>
              {getLevel(
                props?.data?.mastery > 100 ? 100 : props?.data?.mastery
              )}
            </div>
          </div>
          <div className={styles.myStatPatternCardProgressWrap}>
            <div
              className={styles.myStatPatternCardProgressMastery}
              style={{
                width: `${
                  props?.data?.mastery === 0
                    ? "0"
                    : props?.data?.mastery > 100
                    ? "100"
                    : props?.data?.mastery
                }%`,
              }}
            ></div>
          </div>
        </div>
        <div>
          {/* <div className={styles.myStatPatternCardProgressNameContainer}>
            <div className={styles.myStatPatternCardProgressText}>
              {constants.competency}
            </div>
            <div className={styles.myStatPatternCardProgressText}>
              {getLevel(
                props?.data?.competency > 100 ? 100 : props?.data?.competency
              )}
            </div>
          </div> */}
          {/* <div className={styles.myStatPatternCardProgressWrap}>
            <div
              className={styles.myStatPatternCardProgressCompetency}
              style={{
                width: `${props?.data?.competency === 0
                  ? "20"
                  : props?.data?.competency > 100
                    ? "100"
                    : props?.data?.competency
                  }%`,
              }}
            ></div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MyStatsPatternCard;
