import { makeAxiosRequest } from "aie_common";
import { HTTPMethods } from "../constants/api_endpoints";
import { constants } from "../constants/constants";
import configureStore from "../store";

const { dispatch } = configureStore();

export const fetchPersonalInfo = async (
  loaderStatus: any,
  apiEndpoint: string
) => {
  const payload = {
    url: apiEndpoint,
    data: {},
    method: HTTPMethods.GET,
    headers: {},
  };
  try {
    const res: any = await makeAxiosRequest(payload);
    const username = res?.data?.data?.username;
    const classname = res?.data?.registeredCourses[0]?.course__name;
    const registeredCourseId = res?.data?.registeredCourses[0]?.course__id;
    const photo = res?.data?.data?.photo;
    const onBoardingStatus = res?.data?.onboarding_status;
    const studentSection = res?.data?.registeredCourses[0]?.section__name
    //Storing the username and registeredCourseId in the redux store.
    dispatch({
      type: constants.ADDUSER,
      payload: {
        username,
        registeredCourseId,
        classname,
        photo,
        onBoardingStatus,
        studentSection
      },
    });
  } catch (error) {
    loaderStatus.setState({ show: false });
    console.log(error);
  }
};

export const fetchNotificationCount = async (
  loaderStatus: any,
  apiEndpoint: string
) => {
  const payload = {
    url: apiEndpoint,
    data: {},
    method: HTTPMethods.GET,
    headers: {},
  };
  try {
    const res: any = await makeAxiosRequest(payload);
    dispatch({
      type: constants.NOTIFICATION,
      payload: {
        notificationCount:res?.data?.count
      },
    });
 
  } catch (error) {
    loaderStatus.setState({ show: false });
    console.log(error);
  }
};