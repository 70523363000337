import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import styles from "./myStatsChapters.module.scss";
import { constants } from "../../../../constants/constants";
import { CustomPagination } from "../../../../common/pagination/customPagination";
import MyStatsChaptersCard from "./myStatsChaptersCard/myStatsChaptersCard";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../../../constants/routes";
import FilterBar from "../../../../common/filterBar/filterBar";
import SearchBar from "../../../../common/searchBar/searchBar";
import ApiEdpoints, { HTTPMethods } from "../../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import StatusContext from "../../../../context/loaderContext";
import { useSelector } from "react-redux";
import { useSearch } from "../../../../utils/searchBar";

const MyStatsChapters = ({ detailedViewRef }: any) => {
  const [page, setPage] = useState(1);
  const range = 8;
  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationEnd, setPaginationEnd] = useState(range);
  const navigate = useNavigate();
  const [initialSelectedPatternsValues, setUpdatedSelectedPatternValues] =
    useState<{ index: number; value: string }[]>([]);

  const [chapterList, setChapterList] = useState<any>([]);

  const loaderStatus: any = useContext(StatusContext);

  const { registeredCourseId } = useSelector((state: any) => state.userReducer);

  const fetchChapterList = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.CHAPTERS_WISE_DATA}?course_id=${registeredCourseId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const { data }: any = await makeAxiosRequest(payload);
      // spelling mistake of sucess from backend
      if (data?.status === "sucess") {
        setChapterList(data?.data);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchChapterList();
  }, []);

  const levelsData = [
    "All",
    "Level 1",
    "Level 2",
    "Level 3",
    "Level 4",
    "Level 5",
  ];

  const patterns = [{ data: levelsData, defaultLabel: "All level" }];

  useEffect(() => {
    const patternPropsData = patterns
      ? patterns?.map((x: any, i: number) => ({
          index: i,
          value: "",
        }))
      : [];
    setUpdatedSelectedPatternValues(patternPropsData);
  }, [patterns?.length]);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    const finalValue = Math.ceil(chapterList.length / range);

    if (finalValue === value) {
      setPaginationStart(range * (value - 1));
      setPaginationEnd(chapterList.length);
    } else {
      setPaginationStart(range * (value - 1));
      setPaginationEnd(range * value);
    }
  };

  const handleChapterCard = async (item: any) => {
    navigate(routePaths.chapterStats.replace(":statsId", item.id));
  };

  const setUpdatesSelectedPatterns = (updatedArrValues: any) => {
    setUpdatedSelectedPatternValues(updatedArrValues);
  };

  const isPatternsSelected = initialSelectedPatternsValues?.some(
    (x: any) => x?.value
  );

  const [searchText, setSearchText] = useState("");
  const [searchedChapter, getSearchedData] = useSearch(chapterList);

  useEffect(() => {
    getSearchedData(searchText, "name");
  }, [searchText]);
  return (
    <div className={styles.myStatsChapterContainer}>
      <div className={styles.headingWrapper}>
        <div className={styles.myStatsTopHeadingText}>
          {constants.myStatsChapterHeadingTest}
        </div>
        <div className={styles.filterAndSearchContainer}>
          <div className={styles.filterContainer}>
            {/* <FilterBar
              showSorting={false}
              patternsData={patterns ? patterns : []}
              initialSelectedPatternsValues={initialSelectedPatternsValues}
              setUpdatedSelectedPatternValues={setUpdatesSelectedPatterns}
            /> */}
          </div>
          <SearchBar searchText={searchText} setSearchText={setSearchText} />
        </div>
      </div>

      {searchedChapter?.length > 0 &&
        searchedChapter
          ?.slice(paginationStart, paginationEnd)
          .map((each: any, index: number) => (
            <div
              className={styles.myStatsChaptersCardWrapper}
              key={`chapterCard_${index}`}
            >
              <MyStatsChaptersCard
                data={each}
                handleChapterCard={handleChapterCard}
                chapterNumber={each?.name?.split(" ")[0].split(".")[0]}
              />
            </div>
          ))}
      {searchedChapter?.length === 0 && (
        <div className={styles.noDataFound}>{constants.NO_DATA_FOUND}</div>
      )}

      {searchedChapter.length > 0 && (
        <CustomPagination
          detailedViewRef={detailedViewRef}
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(chapterList?.length / range)}
        />
      )}
    </div>
  );
};

export default React.memo(MyStatsChapters);
