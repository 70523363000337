import { constants } from "../../constants/constants";

const initialState = {
  username: "",
  registeredCourseId: "",
  notificationCount:0,
  studentSection:"",
  userData:{},
};

const userReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case constants.ADDUSER: {
      return {
        username: action.payload.username,
        registeredCourseId: action.payload.registeredCourseId,
        classname: action.payload.classname,
        photo: action.payload.photo,
        onBoardingStatus: action.payload.onBoardingStatus,
        studentSection: action.payload.studentSection,
      };
    }
    case constants.NOTIFICATION: {
      return {
        ...state,
        notificationCount:action.payload.notificationCount
      };
    }
    case constants.ADMINDATA: {
      return {
        ...state,
        userData:action.payload
      };
    }
    
    default:
      return state;
  }
};
export default userReducer;
