import emailIcon from "../../../../../../../assets/images/emailIcon.svg";
import personalDetailsSeparator from "../../../../../../../assets/images/personalDetailsSeparator.svg";
import phoneIcon from "../../../../../../../assets/images/phoneIcon.svg";
import studentDetailsAndPerformance from "../../../../../../../assets/images/studentDetailsAndPerformance.svg";
import Progressbar from "../../../../../../../common/progressbar/progressbar";
import StudentCardPersonalDetailsIconBox from "../studentCardPersonalDetailsIconBox/studentCardPersonalDetailsIconBox";
import styles from "./studentDetailsAndPerformance.module.scss";
import testCompleted from "../../../../../../../assets/images/testCompleted.svg";
import scoreStar from "../../../../../../../assets/images/scoreStar.svg";
import assignmentCompleted from "../../../../../../../assets/images/assignmentCompleted.svg";
import topicsCompleted from "../../../../../../../assets/images/topicsCompleted.svg";
import studentPerformanceTimer from "../../../../../../../assets/images/studentPerformanceTimer.svg";
import StudentPerformanceIconBox from "../studentPerformanceIconBox/studentPerformanceIconBox";
import { useEffect, useState } from "react";
import { getUsernameInitials } from "../../../../../../../utils/usernameInitials";
import { useSelector } from "react-redux";

interface IStudentDataAndPerformace {
  studentData: IStudentData;
}

interface IStudentData {
  //change based on API
  studentName: string;
  rollNumber: string | number;
  emailId: string;
  phoneNumber: string;
  levelReached: number;
  topicsCompleted: number;
  assignmentCompleted: number;
  testCompleted: number;
  averageScore: number;
  totalStudyTime: string | number;
  photo: any;
  userInitials?: string;
}

const STUDENT_PERFORMANCE = [
  {
    icon: topicsCompleted,
    value: "04",
    title: "Topics Completed",
    type: "topic",
  },
  {
    icon: assignmentCompleted,
    value: "14",
    title: "Exercises Completed",
    type: "assignment",
  },
  //{/* hiding the test and homework sections */}
  //{
  //   icon: testCompleted,
  //   value: "18",
  //   title: "Tests Completed",
  // },
  {
    icon: scoreStar,
    value: "65%",
    title: "Avg. Score",
    type: "score",
  },
  {
    icon: studentPerformanceTimer,
    value: "120",
    title: "Total Study Time",
    type: "time",
  },
];

const StudentDetailsAndPerformance = ({
  studentData,
}: IStudentDataAndPerformace) => {
  const [studentDetails, setStudentDetails] = useState<any>([]);
  const updateStudentDetails = (item: any) => {
    setStudentDetails({
      ...item,
      ["userInitials"]: getUsernameInitials(item?.studentName),
    });
  };

  useEffect(() => {
    updateStudentDetails(studentData);
  }, [studentData]);
  const isSideNavOpen = useSelector((state: any) => state.tabReducer.tabOpen);
  const [studentPerformance, setStudentPerformance] =
    useState<any>(STUDENT_PERFORMANCE);
  useEffect(() => {
    studentPerformance.map((item: any) => {
      if (item.type === "topic") {
        item.value = studentDetails?.topicsCompleted;
      } else if (item.type === "assignment") {
        item.value = studentDetails?.assignmentCompleted;
      } else if (item.type === "score") {
        item.value = studentDetails?.averageScore;
      } else if (item.type === "time") {
        item.value = studentDetails?.totalStudyTime / 3600;
      }
    });
    setStudentPerformance(studentPerformance);
  }, [studentDetails]);

  return (
    <div className={styles.studentDetailsAndPerformanceContainer}>
      <div className={styles.container}>
        <div className={styles.detailsAndProgressBar}>
          <div className={styles.studentIconAndPersonalDetailsContainer}>
            <div className={styles.studentImage}>
              {studentDetails?.photo ? (
                <>
                  {/* REPLACE THE STUDENT PHOTO WHEN IT WILL COME FROM API */}
                  <div className={styles.userIconContainer}>
                    <img
                      src={studentDetails?.photo}
                      alt=""
                      className={styles.image}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.userIconContainer}>
                    <div className={styles.userInitials}>
                      {studentDetails?.userInitials}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={styles.personalDetailsAndName}>
              <div className={styles.studentName}>
                {studentDetails?.studentName}
              </div>
              <div className={styles.personalDetails}>
                <div className={styles.rollNumber}>
                  {studentDetails?.rollNumber}
                </div>
                <div className={styles.separator}>
                  <img src={personalDetailsSeparator} />
                </div>
                <div className={styles.phoneNumberEmail}>
                  <StudentCardPersonalDetailsIconBox
                    icon={emailIcon}
                    title={studentDetails?.emailId}
                  />
                  <StudentCardPersonalDetailsIconBox
                    icon={phoneIcon}
                    title={studentDetails?.phoneNumber}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.progressBarContainer}>
            <Progressbar
              progress={studentDetails?.progressLevel}
              noOflevels={5}
              levelsVisible={false}
              enableProgressBGColor={true}
            />
          </div>
        </div>
        <div className={styles.horizontalLine}></div>
        <div
          className={
            isSideNavOpen
              ? styles.studentPerformanceContainer
              : styles.studentPerformanceContainerSideNav
          }
        >
          {studentPerformance &&
            Array.isArray(studentPerformance) &&
            studentPerformance.length &&
            studentPerformance.map((performance, ind: number) => {
              return (
                <StudentPerformanceIconBox
                  performance={performance}
                  key={`studentPerformanceIconBox_${ind}`}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default StudentDetailsAndPerformance;
