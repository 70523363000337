import React from 'react';
import styles from './cardDetailsIconBox.module.scss';

interface ICardDetailsIconBox {
    icon: string;
    text: string;
    gapInBetween: string;
}
const CardDetailsIconBox = ({ icon, text, gapInBetween }: ICardDetailsIconBox) => {
    return (
        <>
            <div className={styles.CardIconAndTextConatiner}>
                <div><img src={icon} alt='icon' /></div>
                <div className={styles.cardText} style={{ marginLeft: gapInBetween }}>
                    {text}
                </div>
            </div>
        </>
    )
}
export default React.memo(CardDetailsIconBox);
