import React, { useState } from 'react'

import UploadedFilesDisplayModal from "../uploadedFilesDisplayModal/uploadedFilesDisplayModal"

import styles from "./questionUploadedFiles.module.scss";
import deleteIcon from "../../../../../../../../../assets/images/deleteIcon.svg";
import reorderIcon from "../../../../../../../../../assets/images/hand.svg";
import previewIcon from "../../../../../../../../../assets/images/previewIcon.svg";

const QuestionUploadedFiles = (props: any) => {
  const { uploadedFiles, onDeleteFile, handleDrop, handleDrag } = props;
  const [open, setOpen] = useState(false);
  const [activeFileIndex, setActiveFileIndex] = React.useState(0);

  const handleOpenModal = (index: number) => {
    setOpen(true);
    setActiveFileIndex(index);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <div className={styles.questionUploadedFilesContainer} id={"droppable"}>
      {uploadedFiles.length > 0 && <div className={styles.questionUploadedFilesHeading}>Your Uploads (click and drag to re arrange the images.)</div>}

      <div className={styles.uploadedFilesContainer}>
        {uploadedFiles.map((item: any, index: number) => {
          return (
            <div
              className={styles.fileContainer}
              key={index}
              draggable={true}
              id={item.id}
              onDragOver={(ev) => ev.preventDefault()}
              onDrop={handleDrop}
              onDragStart={handleDrag}>

              <div className={styles.fileContainerHeader}>
                <div className={styles.fileName}> Page {index + 1}</div>

                <div className={styles.fileButtonsContainer}>
                  <div className={styles.deleteButton} onClick={() => onDeleteFile(index)}>
                    <img src={deleteIcon} className={styles.fileIcon} /> Delete
                  </div>
                  <div className={styles.verticalDivider}></div>
                  <div className={styles.reorderButton}>
                    <img src={reorderIcon} className={styles.fileIcon} /> Reorder
                  </div>
                </div>
              </div>


              <div className={styles.fileContainerBody}>
                {/* <img src={item.preview} className={styles.imageFile} alt="" /> */}

                <div
                  className={styles.imageFile}
                  style={{ backgroundImage: `url(${item.preview})` }}
                >
                  <div className={styles.imageFooter}>
                    <img src={previewIcon} className={styles.filePreviewIcon} onClick={() => handleOpenModal(index)} />
                  </div>
                </div>

                <UploadedFilesDisplayModal
                  key={index}
                  open={open}
                  handleClose={handleCloseModal}
                  activeFileIndex={activeFileIndex}
                  setActiveFileIndex={setActiveFileIndex}
                  uploadedFiles={uploadedFiles}
                />

              </div>
            </div>
          );
        })}
      </div>

    </div>
  )
}

export default QuestionUploadedFiles