import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { constants } from "../../constants/constants";
import configureStore from "../../store";
import classes from "./sideNavElement.module.scss";
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@mui/icons-material";

interface ISideNavElement {
  title: string;
  image: string;
  activeImage: string;
  count?: number;
  path: string;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  selectedIndex: number;
  isExpanded: boolean;
  handleExpand: () => void;
  children?: any;
}

const SideNavElement = (props: ISideNavElement) => {
  const navigate = useNavigate();
  const { dispatch } = configureStore();
  const { pathname } = useLocation();
  const [selectedChildIndex, setSelectedChildIndex] = useState(0);
  const [collapseChildren, setCollapseChildren] = useState(false);

  const handleClick = (n: number, childIndex: number | null) => {
    if (props.path == "logout") {
      dispatch({ type: constants.LOGOUT });
      localStorage.clear();
      navigate("login");
    }
    props.setSelectedIndex(n);
    props.handleExpand();
    if (props.children && childIndex) {
      setSelectedChildIndex(childIndex);
      navigate(props.children[childIndex].path);
    } else {
      navigate(props.path);
    }
  };

  useEffect(() => {
    if (window.location.href.includes(props.path)) {
      props.setSelectedIndex(props.index);
    }
  }, [pathname]);

  useEffect(() => {
    if (props.selectedIndex == props.index) {
      setCollapseChildren(false);
    } else {
      setCollapseChildren(true);
    }
  }, [props.index, props.selectedIndex]);

  return (
    <div>
      <div
        className={
          props.selectedIndex == props.index
            ? props.isExpanded
              ? `${classes.checkedParent}`
              : `${classes.checkedParent} ${classes.collapsedNav}`
            : props.isExpanded
            ? `${classes.parent}`
            : `${classes.parent} ${classes.collapsedNavParent}`
        }
        style={{
          borderBottomLeftRadius: `${
            props.children && !collapseChildren ? 0 : "10px"
          }`,
        }}
        onClick={() => {
          handleClick(props.index, null);
        }}
      >
        <div className={classes.sideNavIcon}>
          <img
            src={
              props.selectedIndex === props.index
                ? props.activeImage
                : props.image
            }
            alt="nav icon"
          />
        </div>
        <div className={`${classes.title} ${classes.inter16}`}>
          {props.isExpanded && <span>{props.title}</span>}
        </div>

        {props.isExpanded && (
          <div className={classes.countContainer}>
            {props.count ? (
              <div className={classes.count}>{props.count}</div>
            ) : (
              <></>
            )}
          </div>
        )}
        {props.children && props.isExpanded && (
          <div
            onClick={(e) => {
              setCollapseChildren((state) => !state);
            }}
          >
            {props.selectedIndex != props.index ? (
              <KeyboardArrowDownRounded />
            ) : (
              <>
                {!collapseChildren ? (
                  <KeyboardArrowUpRounded />
                ) : (
                  <KeyboardArrowDownRounded />
                )}
              </>
            )}
          </div>
        )}
      </div>
      {props.selectedIndex == props.index &&
        !collapseChildren &&
        props?.children?.length && (
          <ul
            className={classes.childNavContainer}
            style={{ paddingLeft: `${!props.isExpanded ? "8px" : "40px"}` }}
          >
            {props.children.map((child: any, index: number) => {
              return (
                <>
                  <li
                    className={
                      window.location.href.includes(child.path)
                        ? classes.active
                        : ""
                    }
                    onClick={() => {
                      handleClick(props.index, index);
                    }}
                  >
                    <span>
                      <span>
                        <img
                          src={
                            child.selectedIndex === child.index
                              ? child.activeImage
                              : child.image
                          }
                          alt="nav icon"
                        />
                      </span>
                      <span className={`${classes.title} ${classes.inter16}`}>
                        {props.isExpanded && <span>{child.title}</span>}
                      </span>
                    </span>
                  </li>
                </>
              );
            })}
          </ul>
        )}
    </div>
  );
};

export default SideNavElement;
