import testIcon from "../assets/images/test.svg";
import { getLevelFromNumber } from "./masteryLevel";

export const updateClassList = (classList: any) => {
  let updatedList = classList?.map((classDetail: any, index: number) => {
    return {
      id: classDetail?.class_id,
      divisionClass: romanToNumeral(classDetail?.class),
      division: "Division A",
      noOfStudents: classDetail?.students_count,
      pendingReview: [
        {
          icon: testIcon,
          value: classDetail?.pending_assignments,
          text: "Exercises",
        },
        // {
        //   icon: homeWork,
        //   value: 20,
        //   text: "Homeworks",
        // },
        // {
        //   icon: queriesIcon,
        //   value: 10,
        //   text: "Queries",
        // },
      ],
    };
  });

  return updatedList;
};

export const romanToNumeral = (romanNumber: string) => {  
  let finalNumeralString = romanNumber ? romanNumber.split("-")[0].trim() : 'I';
  var res = 0;
  function valueOfRomanSymbol(r: any) {
    if (r == "I") return 1;
    if (r == "V") return 5;
    if (r == "X") return 10;
    if (r == "L") return 50;
    if (r == "C") return 100;
    if (r == "D") return 500;
    if (r == "M") return 1000;
    return -1;
  }
  for (let i = 0; i < finalNumeralString.length; i++) {
    var s1 = valueOfRomanSymbol(finalNumeralString.charAt(i));
    if (i + 1 < finalNumeralString.length) {
      var s2 = valueOfRomanSymbol(finalNumeralString.charAt(i + 1));
      if (s1 >= s2) {
        res = res + s1;
      } else {
        res = res + s2 - s1;
        i++;
      }
    } else {
      res = res + s1;
    }
  }
  return res;
};

export const updatedStudentsDetailsThatNeedsAttention = (
  studentToBeFocusedData: any
) => {
  return (
    studentToBeFocusedData &&
    Array.isArray(studentToBeFocusedData) &&
    studentToBeFocusedData.map((student: any) => {
      return {
        rollNo: student?.student_rollnumber,
        level: getLevelFromNumber(student?.level),
        studentName: student?.student_name,
        activity: "Missed 04/04 Tests",
        photo: student?.photo,
        id: student?.student_id,
      };
    })
  );
};

export const getUpdatedChapterWisePatterns = (chapterWisePatterns: any) => {
  return (
    chapterWisePatterns &&
    Array.isArray(chapterWisePatterns) &&
    chapterWisePatterns.map((chapterPattern: any, index) => {
      return {
        id: chapterPattern?.id,
        chapterDetails: {
          chapterNumber: `Chapter ${index + 1}`,
          chapterName: chapterPattern?.chapter_name?.split(".")[1],
        },
        chips: chapterPattern?.list_of_patterns,
      };
    })
  );
};

export const getUpdatedChapterProgressCardData = (chapterProgressData: any) => {
  return {
    aiCount: chapterProgressData?.AI_used_count,
    attemptedQuestions: chapterProgressData?.questions_attempted,
    totalQuestions: chapterProgressData?.questions,
    competenceLevel: {
      competencyInPercentage: chapterProgressData?.mastery, //TODO: WHEN API WILL BE FIXED
      masteryStage: chapterProgressData?.mastery,
    },
    name: chapterProgressData?.chapter_name,
  };
};

export const getUpdatedStudentTopics = (topicsData: any) => {
  return (
    topicsData &&
    Array.isArray(topicsData) &&
    topicsData.map((topic: any, index) => {
      return {
        id: topic?.id,
        chapterDetails: {
          chapterNumber: `Topic ${index + 1}`,
          chapterName: topic?.topic_name,
        },
        chips: topic?.list_of_patterns,
      };
    })
  );
};
