import React, { useEffect, useState } from "react";
import styles from "./solveExerciseButton.module.scss";
import ModalBg from "../../assets/images/aiSupportModalBg.svg";
import MascotRocket from "../../assets/images/mascotRocket.svg";
import { SolveAssignmentQuestionListViewAll } from "../../modules/learn/pages/solveAssignmentPage/solveAssignmentMetaData/solveAssignmentQuestionPopUp/questionListViewAll/solveAssignmentQuestionListViewAll";
import { useSelector } from "react-redux";
import { Dialog, Switch } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

interface ISolveExerciseButton {
  status: "pending" | "complete";
  level: number;
  id: number;
  children: any;
}

const SolveExerciseButton = ({
  status,
  level,
  id,
  children,
}: ISolveExerciseButton) => {
  const [showQuestionListPopUp, setShowQuestionListPopUp] = useState(false);
  const [checkAiSupportModal, setCheckAiSupportModal] = useState(false);
  const [checkAiSupport, setCheckAiSupport] = useState(false);
  const questionData = useSelector(
    (state: any) => state.solveAssignmentReducer
  );
  const [acticveQuestionIndex, setActicveQuestionIndex] = useState(0);
  useEffect(() => {
    setActicveQuestionIndex(questionData.activeQuestionIndex);
  }, [questionData.activeQuestionIndex]);
  return (
    <>
      <div
        className={styles.solveExerciseButtonAction}
        onClick={(e) => {
          e.stopPropagation();
          const videoref: any = document?.querySelector("#my_video");
          if (videoref) {
            videoref.pause();
          }
          setCheckAiSupportModal(true);
        }}
      >
        {children}
      </div>
      {showQuestionListPopUp && (
        <SolveAssignmentQuestionListViewAll
          title={"Exercise"}
          closePopUp={() => {
            setCheckAiSupportModal(false);
            setShowQuestionListPopUp(!showQuestionListPopUp);
          }}
          activeQuestionIndex={acticveQuestionIndex}
          setActiveQuestionIndex={setActicveQuestionIndex}
          assignmentLevel={level}
          topicIdProp={id}
          fromExercisePage={true}
          aiSupport={checkAiSupport}
          isRetakeAllowed={true}
        />
      )}

      <Dialog
        open={checkAiSupportModal}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className={styles.aiSupportModalWrapper}>
          <img src={ModalBg} alt="" />
          <div className={styles.aiSupportModalContent}>
            <div className={styles.aiSupportModalImage}>
              <img src={MascotRocket} alt="" />
            </div>
            <div className={styles.aiSupportModalCheckbox}>
              <p>
                You are approaching the Genius Level! Time to master the
                concepts.
              </p>
              <div className={styles.aiSupportToggle}>
                <p>
                  AI Support
                  <span>Toggle this for better support</span>
                </p>
                <Switch
                  checked={checkAiSupport}
                  onChange={() => {
                    setCheckAiSupport((state) => !state);
                  }}
                />
              </div>
              <button
                onClick={() => {
                  setShowQuestionListPopUp(!showQuestionListPopUp);
                }}
              >
                {status == "pending" && "SOLVE NOW"}
                {status == "complete" && "RETAKE"}
              </button>
            </div>
          </div>

          <button
            onClick={() => {
              setCheckAiSupportModal(false);
            }}
          >
            <CloseRounded />
          </button>
        </div>
      </Dialog>
    </>
  );
};

export default SolveExerciseButton;
