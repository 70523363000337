import React from "react";
import styles from "./statsBlock.module.scss";

const StatsBlock = ({ stats }: any) => {
    // console.log(stats);

    return (
        <div className={styles.statsBlock}>
            <div className={styles.c1}>
                <h1>{stats.label1}</h1>
                <h2>{stats.num1}</h2>
                <p>Class 6 - Class 10</p>
            </div>
            <div className={styles.c2}>
                <div className={styles.block}>
                    <h1>{stats.label2}</h1>
                    <p>{stats.num2}</p>
                </div>
                <div className={styles.block}>
                    <h1>{stats.label3}</h1>
                    <p>{stats.num3}</p>
                </div>
            </div>
        </div>
    );
};

export default StatsBlock;
