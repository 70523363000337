import React, { useState } from "react";
import styles from "./createHomeworkTest.module.scss";
import { Dialog } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import ClassIcon from "../../../../../assets/images/presentation.svg";
import StudentIcon from "../../../../../assets/images/bookreading.svg";
import CreateHomeworkTestDataSelectionModal from "./createHomeworkTestDataSelectionModal";

const CreateHomeworkTest = ({ onlyTest }: any) => {
  const [createModal, setCreateModal] = useState(false);
  const [createModalType, setCreateModalType] = useState("");
  const [createState, setCreateState] = useState("selection");
  const handleCreateModalOpen = (type: any) => {
    setCreateModalType(type);
    setCreateModal(true);
  };

  return (
    <>
      <div className={styles.createHomeworkTestButtons}>
        {!onlyTest && (
          <button onClick={() => handleCreateModalOpen("Homework")}>
            Create Homework
          </button>
        )}

        <button
          onClick={() => {
            setCreateModalType("Test");
            setCreateModal(true);
            setCreateState("classHomeworkSelected");
          }}
        >
          Create test
        </button>
      </div>
      <Dialog
        open={createModal}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className={styles.createModalWrap}>
          {createState == "selection" && (
            <div className={styles.createSelection}>
              <p>Create Homework for</p>
              <div className={styles.createSelectionRadio}>
                <button
                  onClick={() => {
                    setCreateState("classHomeworkSelected");
                  }}
                >
                  <img src={ClassIcon} alt="" /> Class
                  <span>
                    <Ring />
                  </span>
                </button>
                <button
                  onClick={() => {
                    setCreateState("studentHomeworkSelected");
                  }}
                >
                  <img src={StudentIcon} alt="" /> Student
                  <span>
                    <Ring />
                  </span>
                </button>
              </div>
            </div>
          )}

          {createState == "classHomeworkSelected" && (
            <CreateHomeworkTestDataSelectionModal
              type={createModalType}
              modalFor="Class"
            />
          )}
          {createState == "studentHomeworkSelected" && (
            <CreateHomeworkTestDataSelectionModal
              type={createModalType}
              modalFor="Student"
            />
          )}

          <button
            onClick={() => {
              setCreateModal(false);
              setCreateState("selection");
            }}
          >
            <CloseRounded />
          </button>
        </div>
      </Dialog>
    </>
  );
};

export default CreateHomeworkTest;

const Ring = () => {
  return (
    <svg viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_201_13200)">
        <path
          d="M4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16Z"
          fill="url(#paint0_linear_201_13200)"
        />
        <path
          d="M4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16Z"
          stroke="white"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_201_13200"
          x="0.75"
          y="0.75"
          width="35.5"
          height="35.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.5" dy="2.5" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.6512 0 0 0 0 0.66896 0 0 0 0 0.74 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_201_13200"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_201_13200"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_201_13200"
          x1="23.7781"
          y1="28"
          x2="3.31896"
          y2="8.57191"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0994981" stop-color="#2937A7" />
          <stop offset="1" stop-color="#BAA6FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
