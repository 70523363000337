import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/scss/pagination";

// import required modules
import { EffectFade, Navigation, Pagination } from "swiper";
import PatternCard from "../../../../../components/patternListing/patternCard/patternCard";
import ApiEdpoints, {
  HTTPMethods,
} from "../../../../../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import PatternVideoOverlayContainer from "../../../../../../../common/patternVideoOverlayContainer/patternVideoOverlayContainer";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import StatusContext from "../../../../../../../context/loaderContext";
import { DataArrayTwoTone } from "@mui/icons-material";
interface IPatternCardCarousalProps {
  tag?: string;
  question: any;
  setQuestion: any;
  activeQuestionIndex: number;
  setPatternCards?: any;
  patternCards?: any;
  userType?: string;
}
interface Pattern {
  name: string;
  pattern_name: string;
}

interface QuestionData {
  question: {
    questionid: number;
  };
  patterns: Array<{ [patternKey: string]: Pattern }>;
}
const PatternCardCarousal = (props: IPatternCardCarousalProps) => {
  const {
    tag,
    setQuestion,
    activeQuestionIndex,
    setPatternCards,
    patternCards,
    userType = "student",
    question,
  } = props;
  const [data, setData] = useState<any>([
    {
      patterns: [],
      question: "",
    },
  ]);
  const [indexdPatternCards, setIndexdPatternCards] = useState([""]);
  const [patternVideo, setPatternVideo] = useState<any>([]);
  const [courseId, setCourseId] = useState(
    useSelector((state: any) => state.userReducer.registeredCourseId)
  );
  const params = useParams();
  const loaderStatus: any = useContext(StatusContext);
  const fetchPatternCards = async () => {
    if (!tag) return;
    loaderStatus.setState({ show: true });
    const payloads = {
      url: ApiEdpoints.DYNAMIC_PSGA + `${tag}/${tag}/${courseId}/`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res = await makeAxiosRequest(payloads);
      const data = res.data.data.map((item: any) => {
        return {
          patterns: item.patterns,
          question: item.question,
        };
      });

      setPatternCards((prev: any) => {
        return prev.map((item: any, index: any) => {
          if (index === activeQuestionIndex) {
            return {
              id: index,
              patterns: data[0].patterns,
              question: data[0].question,
            };
          } else {
            return item;
          }
        });
      });

      setData(data);
      const newData = res.data.data[0].patterns.map((item: any) => {
        return Object.keys(item)[0];
      });
      setQuestion((prev: any) => {
        return prev.map((item: any, index: any) => {
          if (index === activeQuestionIndex) {
            return {
              ...item,
              patterns: newData,
            };
          } else {
            return item;
          }
        });
      });
      setIndexdPatternCards(newData);
      loaderStatus.setState({ show: false });
      return res;
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const fetchViewCount = async () => {
    const item: QuestionData = data[0];
    const { question, patterns } = item;

    // Assuming there's only one pattern in the patterns array
    const patternKey = Object.keys(patterns[0])[0];
    const patternData = patterns[0][patternKey];

    // Create a single object with the required fields
    const postData = {
      name: patternData.name,
      pattern_name: patternData.pattern_name,
      questionid: question.questionid,
    };
    const payloads = {
      url: ApiEdpoints.PATTERN_VIEW_COUNT,
      data: {
        ...postData,
      },
      method: HTTPMethods.POST,
    };
    try {
      const res = await makeAxiosRequest(payloads);
      
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    userType === "mentor" && setCourseId(params.classId);
    if (
      patternCards &&
      patternCards[activeQuestionIndex]?.patterns?.length < 1
    ) {
      fetchPatternCards();
    } else if (patternCards) {
      setData([
        {
          index: activeQuestionIndex,
          patterns: patternCards[activeQuestionIndex]?.patterns,
          question: patternCards[activeQuestionIndex]?.question,
        },
      ]);
      const newData = patternCards[activeQuestionIndex]?.patterns.map(
        (item: any) => {
          return Object.keys(item)[0];
        }
      );
      setIndexdPatternCards(newData);
    }
  }, [activeQuestionIndex, tag, patternCards]);

  useEffect(() => {
    const newData = indexdPatternCards?.map((item: any, index: any) => {
      return {
        background:
          "linear-gradient(304.25deg, #5A66C8 12.81%, #896AF3 109.93%);",
        level: "Primary",
        patternName:
          (data[0]?.patterns[index] &&
            data[0]?.patterns[index][`${item}`]?.name) ||
          "",
          patternColor:
          (data[0]?.patterns[index]?.[`${item}`]?.pattern_color),
        patternDescription:
          (data[0]?.patterns[index] &&
            data[0]?.patterns[index][`${item}`]?.description) ||
          "",
        patternStrength:
          (data[0]?.patterns[index] &&
            data[0]?.patterns[index][`${item}`]?.pattern_mastery) ||
          0,
      };
    });

    setPatternVideo(newData);
  }, [indexdPatternCards]);
  const [videoNotesOverlayContent, setVideoNotesOverlayContent] =
    useState("pattern");

  const [patternVideoOverlay, setPatternVideoOverlay] = useState(false);
  const handlePatternVideoOverlay = (type?: string, patternCardData?: any) => {
    setPatternVideoOverlay((prev) => !prev);
    setVideoNotesOverlayContent("pattern");
  };
  const handlePatternVideoOverlayContent = (
    resource: "viewDocument" | "pattern" | "patternMap"
  ) => {
    setVideoNotesOverlayContent(resource);
  };
  const [patternCardData, setPatternCardData] = useState({
    descriptionText: "",
    patternHeadingText: "",
    patternColor:false,
    
  });
  const [indexnew, setIndexnew] = useState(0);

  const handle = (index: any) => {
    setIndexnew(index);
    setPatternCardData({
      descriptionText:
        data[0]?.patterns[index] &&
        data[0]?.patterns[index][`${indexdPatternCards[index]}`]?.description,
      patternHeadingText:
        data[0]?.patterns[index] &&
        data[0]?.patterns[index][`${indexdPatternCards[index]}`]?.name,
        patternColor:
          data[0]?.patterns[index] &&
            data[0]?.patterns[index][`${indexdPatternCards[index]}`]?.pattern_color
       
    });
    setPatternVideoOverlay(!patternVideoOverlay);
  };
// slide number for patterns 

  return (
    <>
      <Swiper
        spaceBetween={30}
        pagination={{
          clickable: true,
          // renderBullet: (index, className) => {
          //   return `<span class="${className}">${patternVideo.length > 1 ? index + 1 + ' / ' + patternVideo.length : '1 / 1'}</span>`;
          // },
        }}
        navigation={true}
        modules={[EffectFade, Navigation, Pagination]}
      >
        {patternVideo?.map((item: any, index: any) => {
          return (
            <div onClick={() => handle(index)} key={"carousal" + index}>
              <SwiperSlide>
              <div onClick={() => fetchViewCount()}>
                <PatternCard
                  patternItem={item}
                  fromComponent={"carousal"}
                  handle={() => handle(index)}
                />
                </div>
              </SwiperSlide>
            </div>
          );
        })}
      </Swiper>

      {patternVideoOverlay ? (
        <PatternVideoOverlayContainer
          docName="View Document"
          pptUrl={
            data[0]?.patterns[indexnew] &&
            data[0]?.patterns[indexnew][`${indexdPatternCards[indexnew]}`]
              ?.pattern_ppt
          }
          handlePatternOverlay={handlePatternVideoOverlay}
          handlePatternVideoOverlayContent={handlePatternVideoOverlayContent}
          videoNotesOverlayContent={videoNotesOverlayContent}
          patternCardData={patternCardData}
          patternExamples={
            data[0]?.patterns[indexnew] &&
            data[0]?.patterns[indexnew][`${indexdPatternCards[indexnew]}`]
              ?.examples
          }
          videoUrl={
            data[0]?.patterns[indexnew] &&
            data[0]?.patterns[indexnew][`${indexdPatternCards[indexnew]}`]
              ?.pattern_video
          }
        />
      ) : null}
    </>
  );
};

export default PatternCardCarousal;
