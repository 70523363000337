import React, { useState } from "react";
import styles from "./options.module.scss";
import selected from "../../../../../../../../assets/images/selectedOption.svg";
import unselected from "../../../../../../../../assets/images/unChecked.svg";
import { QuestionAndOptionsElement } from "../../../../../../../../common/questionsAndOptions/questionAndOptions";
import { BasicModal } from "../../../../../../../../common/modalWrapper/modalWrapper";

interface IAssignmentOptions {
  question: any;
  setQuestion: any;
  activeQuestionIndex: any;
  setOptionClicked?: any;
  activeAssigmentType?: any;
  saveQuestion?: any;
}

export const Options = (props: IAssignmentOptions) => {
  const {
    question,
    setQuestion,
    activeQuestionIndex,
    setOptionClicked,
    activeAssigmentType,
    saveQuestion,
  } = props;

  const [openModal, setOpenModal] = useState(false);

  const handleClick = (item: any) => {
    if (activeAssigmentType === "test") {
      setOptionClicked(true);
    }
    setQuestion(() => {
      return question?.map((item1: any) => {
        if (question.length - 1 == activeQuestionIndex) {
          setTimeout(() => {
            saveQuestion && saveQuestion(question);
          }, 1000);
        }
        if (
          item1.id === question[activeQuestionIndex].id &&
          activeAssigmentType === "test"
        ) {
          item1.selectedAnswer = item.id;
          item1.code = item.id;
          item1.visited = item.id ? "Completed" : "In Progress";
        } else if (
          item1.id === question[activeQuestionIndex].id &&
          activeAssigmentType === "assignment"
        ) {
          item1.selectedAnswer = item.ans || item.ansimage;
          item1.code = item.id;
        }

        return item1;
      });
    });
  };

  return (
    <>
      <div className={styles.assignmentOptionContainer}>
        {question[activeQuestionIndex]?.Answers?.map(
          (item: any, index: any) => {
            return (
              <div
                key={index}
                onClick={() => handleClick(item)}
                className={
                  item.ansimage === ""
                    ? styles.optionContainerWithOutImage
                    : styles.optionContainerWithImage
                }
              >
                <div
                  className={`${
                    question[activeQuestionIndex].selectedAnswer == item.id &&
                    styles.selectedOption
                  }`}
                >
                  <img
                    style={{ width: "24px" }}
                    src={
                      question[activeQuestionIndex].code == item.id
                        ? selected
                        : unselected
                    }
                    alt="selected"
                  />
                </div>
                <div className={styles.optionContainer}>
                  <label>
                    <QuestionAndOptionsElement
                      metaData={item.ans}
                      size="mcqOption"
                    />
                  </label>
                  {item.ansimage && (
                    <div className={styles.containerPopUp}>
                      <div className={styles.optionImageOnHoverContainer}>
                        <div className={styles.optionImageContainer}>
                          <img
                            src={
                              question[activeQuestionIndex].Answers[index]
                                .ansimage
                            }
                            alt="rectangle"
                            onClick={(e) => {
                              e.stopPropagation();
                              return setOpenModal(true);
                            }}
                          />
                        </div>
                        {openModal ? (
                          <BasicModal
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            question={
                              question[activeQuestionIndex].Answers[index]
                            }
                            index={index}
                          />
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
    </>
  );
};
