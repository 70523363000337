import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { makeAxiosRequest } from "aie_common";
import styles from "./myAssignments.module.scss";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { constants } from "../../constants/constants";
import AssignmentsList from "./assignmentsList/assignmentsList";
import StatusContext from "../../context/loaderContext";
import ApiEdpoints, { HTTPMethods } from "../../constants/api_endpoints";

const MyAssignments = () => {
  const detailedViewRef = useRef(null);
  const [assignmentsData, setAssignmentsData] = useState<object[]>([]);
  const courseId = useSelector(
    (state: any) => state.userReducer?.registeredCourseId
  );
  const loaderStatus: any = useContext(StatusContext);
  const [chapterDetailsData, setChapterDetailsData] = useState<any>();
  const [patternOptions, setPatternOptions] = useState<any>([]);
  const fetchChaptersList = async () => {
    const payload = {
      url: `${ApiEdpoints.CHAPTER_LIST}${courseId}/`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);
      if (
        response &&
        response.status === 200 &&
        response?.data?.status === "Sucess"
      ) {
        const chapterDetailsDataRes = response?.data?.data;
        setChapterDetailsData(chapterDetailsDataRes);

        let chaptersNamesData = chapterDetailsDataRes
          ? chapterDetailsDataRes?.courseTopics?.reduce(
            (arr: any, obj: any) => {
              arr.push({ name: obj?.name, id: obj?.id });
              return arr;
            },
            ["All"]
          )
          : [];
        chaptersNamesData = chaptersNamesData.map(
          (each: string, index: number) => {
            return {
              name: each,
              id: index,
            };
          }
        );
        setPatternOptions(chaptersNamesData);
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };
  const fetchAssignmentsList = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.LIST_OF_ASSIGNMENTS}?class_id=${26}`,
      data: {},

      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);
      if (response && response.status === 200) {
        const assignmentsDataRes = response?.data?.data;
        setAssignmentsData(assignmentsDataRes);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    if (courseId) fetchChaptersList();
  }, [courseId]);

  // not integrated with ui but the api call is working fine but data is coming as empty array
  useEffect(() => {
    fetchAssignmentsList();
  }, []);

  return (
    <div className={styles.myAssignmentsMainContainer} ref={detailedViewRef}>
      <Breadcrumb
        breadcrumbDetails={constants.MYASSIGNMENTS_DETAILS_BREADCRUMB_DATA}
      />
      <AssignmentsList
        detailedViewRef={detailedViewRef}
        assignmentsData={assignmentsData}
        patternOptions={patternOptions}
      />
    </div>
  );
};

export default React.memo(MyAssignments);
