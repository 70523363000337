
import styles from './scoreBoard.module.scss'
import mcqResultsStarIcon from '../../../../../../assets/images/mcqResultsStarIcon.svg'

interface ScoreBoardProps {
    question: any;
    activeQuestionIndex: number;
}
export const ScoreBoard = (
    props: ScoreBoardProps
) => {
    const {
        question,
        activeQuestionIndex
    } = props;
    const calculateTime = (time: any) => {
        let minutes = Math.floor(time / 60);
        let seconds = time % 60;
        return `${minutes} mins ${seconds} secs`;
    }

    const calculateScore = () => {        
       if (question[activeQuestionIndex].descriptive === true) {
         // return `${question[activeQuestionIndex].marks_scored} /${question[activeQuestionIndex].score}`
          return `${question[activeQuestionIndex].isCorrect ? question[activeQuestionIndex].marks_scored : "00"}/${question[activeQuestionIndex].score}` 
        }   
        else
          return `${question[activeQuestionIndex].isCorrect ? question[activeQuestionIndex].score : "00"}/${question[activeQuestionIndex].score}`
    
}
    return (
        <div className={styles.scoreBoardContainer} >
            <div className={styles.scoreContainer}>
                <img src={mcqResultsStarIcon} alt="mcqResultsStarIcon" />
                <div className={""}>
                    <div className={styles.scoreText}>
                        {calculateScore()}
                    </div>
                    <div className={styles.yoreScore}>Your Score</div>
                </div>
            </div>
            <div className={styles.dateContainer}>
                <div className={styles.dateText}>
                    {
                        calculateTime(question[activeQuestionIndex].totalTime)
                    }
                </div>
                <div className={styles.timeTaken}> Time Taken</div>
            </div>
        </div>
    )
}

