import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import cancelIcon from "../..//../../assets/images/cross.svg";
import sendIcon from "../../../../assets/images/send.svg";
import styles from "./descriptiveQuestionSubmissionModal.module.scss";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 513,
  bgcolor: "red",
  border: "none",
  boxShadow: 2,
  background: "linear-gradient(104.87deg, #F9FBFD 11.37%, #F8FAFF 87.96%)",
  borderRadius: "16px",
  outline: "none",
};

export const DescriptiveQuestionSubmissionModal = (props: any) => {
  const { openModal, setOpenModal, name, correctQuestions, totalQuestions, setSaveQuestionResponse } =
    props;
  const [open, setOpen] = React.useState(openModal);
  const handleClose = () => {
    setOpen(false);
    setOpenModal(false);
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.container}>
            <div className={styles.headerContainer}>
              <div className={styles.heading}>Publish Result</div>
              <img
                onClick={handleClose}
                src={cancelIcon}
                style={{ cursor: "pointer" }}
                alt={cancelIcon}
              />
            </div>
            <div className={styles.verticleLine}></div>
            <div className={styles.bodyContainer}>
              <div className={styles.leftContainer}>
                <img src={sendIcon} alt="send" />
              </div>
              <div className={styles.rightContainer}>
                <div className={styles.desc}>
                  {name} has scored {correctQuestions}/{totalQuestions} marks in
                  this <br /> Exercise. Do you want publish this result?
                </div>
                <div className={styles.btnContainer}>
                  <button onClick={handleClose} className={styles.cancelButton}>
                    No
                  </button>
                  <button
                    onClick={() => { handleClose(); setSaveQuestionResponse(true); }}
                    className={styles.primaryButton}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
