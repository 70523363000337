import React from "react";
import styles from "./adminClasses.module.scss";
import SearchBar from "../../../common/searchBar/searchBar";
import Breadcrumb from "../../../common/breadcrumb/breadcrumb";
import AdminClassList from "./AdminClassList";

const AdminClasses = () => {
  return (
    <div className={styles.learnMainContainer}>
      <div className={styles.greetingsSearchBarContainer}>
        <div className={styles.greetings}>
          <Breadcrumb
            breadcrumbDetails={[
              {
                label: "Home",
                link: "/admin",
                selected: false,
              },
              {
                label: "Classes",
                link: "admin/classes",
                selected: true,
              },
            ]}
          />
        </div>
        <SearchBar searchText={""} setSearchText={() => {}} />
      </div>
      <AdminClassList />
    </div>
  );
};

export default AdminClasses;
