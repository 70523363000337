import React, { useState } from "react";
import styles from "./TwoTabsSideBar.module.scss";
import {
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField
} from "@mui/material";

const TwoTabsSideBar = ({ tab1Label, tab2Label, tab1, tab2 }: any) => {
    let [toggle, setToggle] = useState(1);

    function toggleTab(id: any) {
        setToggle(id);
    }

    return (
        <div className="container">
            <div className={styles.formContainer}>
                <div className={styles.tabs}>
                    <div
                        className={` ${styles.tab_1} ${
                            toggle === 1 ? styles.active : ""
                        } `}
                        onClick={() => {
                            toggleTab(1);
                        }}
                    >
                        <h1>{tab1Label}</h1>
                    </div>
                    <div
                        className={` ${styles.tab_2} ${
                            toggle === 2 ? styles.active : ""
                        } `}
                        onClick={() => {
                            toggleTab(2);
                        }}
                    >
                        <h1>{tab2Label}</h1>
                    </div>
                </div>

                <div className={styles.content}>
                    <div
                        className={` ${styles.tabContent} ${
                            toggle === 1 ? styles.active : ""
                        } `}
                    >
                        {tab1}
                    </div>

                    <div
                        className={` ${styles.tabContent} ${
                            toggle === 2 ? styles.active : ""
                        } `}
                    >
                        {tab2}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TwoTabsSideBar;
