import React, { useState, useEffect } from "react";
import styles from "./solveMcq.module.scss";
import { QuestionSection } from "./questionSection/questionSection";
import ApiEdpoints, { HTTPMethods } from "../../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import { QuestionMetaDataSection } from "./questionMetaDataSection/questionMetaDataSection";
import arrowback from "../../../../assets/images/arrowback.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getParams } from "../../../../utils/getParams";

export const SolveMCQ = () => {
  const { testId } = useParams();
  const activeAssignmentType = "test";
  const [initialApiCall, setInitialApiCall] = useState(true);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<any>(0);
  const [optionClicked, setOptionClicked] = useState(false);
  const courseIdNew = useSelector(
    (state: any) => state.userReducer.registeredCourseId
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [topicSerialNumber, setTopicSerialNumber] = useState(0);
  const dispatch = useDispatch();
  const [question, setQuestion] = useState([
    {
      id: 1,
      question: "",
      Answers: [],
      selectedAnswer: "",
      correctAnswer: "",
      visited: "",
      marks_alloted: "0",
      img: "",
      totalTime: 30,
      open: false,
      subTopics: "",
      topicNumber: "",
      Questionid: 1,
      code: 1,
      descriptive: false,
      tag: "",
      uploadedImages: [],
      mcqResult: false,
      topicId: `${testId}`,
      topic_slno: topicSerialNumber,
      timeTaken: 0,
      timeAlloted: 0,
    },
  ]);
  const fetchMCQTest = async () => {
    const payload = {
      url:
        ApiEdpoints.TEST_MCQ_QUESTION +
        `?courseid=${courseIdNew}&topic_slno=${topicSerialNumber}&test_slno=${
          Number(topicSerialNumber) + 1
        }&count=` +
        `${activeQuestionIndex}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res: any = await makeAxiosRequest(payload);

      if (initialApiCall) {
        setInitialApiCall(false);
        const newData = {
          id: res.data.data.count,
          question: res.data.data.Question,
          Answers: res.data.data.Answers.map((item: any) => {
            return {
              ...item,
              ansimage: item.ans_img ? item.ans_img : "",
            };
          }),
          selectedAnswer: "",
          correctAnswer: res.data.data.answer_Description,
          visited: "In Progress",
          marks_alloted: res.data.data.marks,
          questionImg: res.data.data.Question_img,
          img: "",
          totalTime: res.data.data.timegiven,
          open: true,
          subTopics: res.data.data.subtopic_name,
          topicNumber: res.data.data.topic_index,
          Questionid: res.data.data.Question_id,
          code: 1,
          descriptive: res.data.data.descriptive,
          tag: res.data.data.tag,
          uploadedImages: [],
          mcqResult: false,
          topicId: `${testId}`,
          topic_slno: topicSerialNumber,
          timeTaken: 0,
          timeAlloted: res.data.data.timegiven,
        };
        let arr = [newData];
        for (let i = 1; i < res.data.data.document.length; i++) {
          const newDatas = {
            id: i + 1,
            question: res.data.data.document[i].question,
            Answers: [],
            selectedAnswer: "",
            correctAnswer: res.data.data.answer_Description,
            visited: "",
            marks_alloted: res.data.data.marks,
            questionImg: res.data.data.Question_img,
            img: "",
            totalTime: res.data.data.timegiven,
            open: false,
            subTopics: res.data.data.subtopic_name,
            topicNumber: res.data.data.topic_index,
            Questionid: res.data.data.Question_id,
            code: 1,
            descriptive: res.data.data.descriptive,
            tag: res.data.data.tag,
            uploadedImages: [],
            mcqResult: false,
            topicId: `${testId}`,
            topic_slno: topicSerialNumber,
            timeTaken: 0,
            timeAlloted: res.data.data.timegiven,
          };
          arr.push(newDatas);
        }
        setQuestion(arr);
      } else {
        const newData = {
          id: res.data.data.count,
          question: res.data.data.Question,
          Answers: res.data.data.Answers.map((item: any) => {
            return {
              ...item,
              ansimage: item.ans_img ? item.ans_img : "",
            };
          }),
          selectedAnswer: "",
          correctAnswer: res.data.data.answer_Description,
          visited: "In Progress",
          marks_alloted: res.data.data.marks,
          img: "",
          questionImg: res.data.data.Question_img,
          totalTime: res.data.data.timegiven,
          open: true,
          Questionid: res.data.data.Question_id,
          code: 1,
          descriptive: res.data.data.descriptive,
          tag: res.data.data.tag,
          uploadedImages: [],
          mcqResult: false,
          topicId: `${testId}`,
          topic_slno: topicSerialNumber,
          timeTaken: 0,
          timeAlloted: res.data.data.timegiven,
        };
        setQuestion((prev: any) => {
          return prev.map((item: any, index: any) => {
            if (index === activeQuestionIndex) {
              return {
                ...item,
                ...newData,
              };
            } else {
              return item;
            }
          });
        });
      }
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  const saveQuestion = async (question: any) => {
    if (!question[activeQuestionIndex].selectedAnswer) {
      question[activeQuestionIndex].visited = "Skipped";
    }

    let payload = {
      url: ApiEdpoints.TEST_MCQ_QUESTION,
      data: {},
      method: HTTPMethods.POST,
      headers: {},
    };
    if (!question[activeQuestionIndex].descriptive) {
      console.log(question[activeQuestionIndex].totalTime);
      payload = {
        url: ApiEdpoints.TEST_MCQ_QUESTION,
        data: {
          question_id: question[activeQuestionIndex].Questionid,
          ans_id: question[activeQuestionIndex].code,
          ans_submit: question[activeQuestionIndex].selectedAnswer
            ? question[activeQuestionIndex].selectedAnswer
            : null,
          time:
            Number(
              document.querySelector("#timeLeft")?.innerHTML.split(":")[1]
            ) || 15,
          courseid: courseIdNew,
          topic_slno: Number(topicSerialNumber),
          test_slno: Number(topicSerialNumber) + 1,
          count: 1,
          descriptive: false,
        },
        method: HTTPMethods.POST,
        headers: {},
      };
    } else {
      payload = {
        url: ApiEdpoints.TEST_MCQ_QUESTION,
        data: {
          id: question[activeQuestionIndex].Questionid,
          code: question[activeQuestionIndex].code,
          answer: null,
          time:
            Number(
              document.querySelector("#timeLeft")?.innerHTML.split(":")[1]
            ) || 15,
          course: courseIdNew,
          previous: false,
          descriptive: question[activeQuestionIndex].descriptive,
          ans_image: question[activeQuestionIndex].uploadedImages,
        },
        method: HTTPMethods.POST,
        headers: {},
      };
    }
    try {
      const res = await makeAxiosRequest(payload);
      setQuestion((prev: any) => {
        return prev.map((item: any, index: any) => {
          if (index === activeQuestionIndex) {
            return {
              ...item,
              mcqResult: res.data.correct_or_not,
              visited: item.visited === "Skipped" ? "Skipped" : "Completed",
            };
          } else {
            return item;
          }
        });
      });
      // dispatch({ type: "UPDATE_MCQ", payload: question })
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    dispatch({ type: "UPDATE_MCQ", payload: question });
  }, [question]);

  useEffect(() => {
    if (courseIdNew && topicSerialNumber) {
      fetchMCQTest();
      dispatch({
        type: "UPDATE",
        payload: [{ ...question, topic_slno: topicSerialNumber }],
      });
    }
  }, [activeQuestionIndex, courseIdNew, topicSerialNumber]);
  const navigate = useNavigate();

  const chapterTopicIndex = () => {
    const url = window.location.href;
    const paramVal = getParams(url);
    setCurrentIndex((paramVal?.index && paramVal?.index[0]) ?? "");
    setTopicSerialNumber(
      (paramVal?.topic_slno && paramVal?.topic_slno[0]) ?? ""
    );
    if (!paramVal?.topic_slno) {
      navigate(-1);
    }
  };

  useEffect(() => {
    chapterTopicIndex();
  }, []);

  return (
    <>
      <div className={styles.mcqWrap}>
        <div className={styles.headingStyle} onClick={() => navigate(-1)}>
          <img className={styles.arrowStyle} src={arrowback} />
          {question[0]?.topicNumber} {question[0]?.subTopics} MCQs
        </div>
        <div className={styles.assignmentPageContainer}>
          <QuestionMetaDataSection
            question={question}
            activeQuestionIndex={activeQuestionIndex}
            setQuestion={setQuestion}
            setActiveQuestionIndex={setActiveQuestionIndex}
            saveQuestion={saveQuestion}
            activeAssignmentType={activeAssignmentType}
            optionClicked={optionClicked}
            setOptionClicked={setOptionClicked}
          />
          <QuestionSection
            question={question}
            setQuestion={setQuestion}
            activeQuestionIndex={activeQuestionIndex}
            setActiveQuestionIndex={setActiveQuestionIndex}
            saveQuestion={saveQuestion}
            activeAssignmentType={activeAssignmentType}
            setOptionClicked={() => setOptionClicked}
          />
        </div>
      </div>
    </>
  );
};
