import React, { useState } from "react";
import styles from "./myWork.module.scss";
import { constants } from "../../constants/constants";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import Tab from "../../common/tab/tab";

const MyWork = () => {
  const [indexToViewAllTabsNumber, setIndexToViewAllTabsNumber] = useState(0);

  const myWorkTitles = [
    constants.PENDING,
    constants.INREVIEW,
    constants.SKIPPED,
    constants.COMPLETED,
  ];
  const myWorkContents = ["Test1", "Test2", "Test3", "Test4"];

  return (
    <div className={styles.myWorkMainContainer}>
      <Breadcrumb
        breadcrumbDetails={constants.MYWORK_DETAILS_BREADCRUMB_DATA}
      />
      <Tab
        indexToViewAllTabsNumber={indexToViewAllTabsNumber}
        setIndexToViewAllTabsNumber={setIndexToViewAllTabsNumber}
        titles={myWorkTitles}
        contents={myWorkContents}
      />
    </div>
  );
};

export default MyWork;
