import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { makeAxiosRequest } from "aie_common";
import { constants } from "../../../../constants/constants";
import MyStatsPatternCard from "./myStatsPatternCard/myStatsPatternCard";
import styles from "./myStatsPattern.module.scss";
import FilterBar from "../../../../common/filterBar/filterBar";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../../../constants/routes";
import { CustomPagination } from "../../../../common/pagination/customPagination";
import SearchBar from "../../../../common/searchBar/searchBar";
import StatusContext from "../../../../context/loaderContext";
import ApiEdpoints, { HTTPMethods } from "../../../../constants/api_endpoints";
import useSorting from "../../../../utils/useSorting";

const MyStatsPattern = ({ detailedViewRef }: any) => {
  const navigate = useNavigate();
  const [initialSelectedPatternsValues, setUpdatedSelectedPatternValues] =
    useState<{ index: number; value: string; id: any }[]>([]);
  const [chapterDetailstData, setChapterDetailstData] = useState<any>();
  const [patternOptions, setPatternOptions] = useState<any>();
  const [chapterIdForPattern, setChapterIdForPattern] = useState<any>();
  const [patternLsitByChapter, setPatternLsitByChapter] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);

  const loaderStatus: any = useContext(StatusContext);
  const registeredCourseId = useSelector(
    (state: any) => state.userReducer?.registeredCourseId
  );

  const fetchDropdownChaptersList = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.CHAPTER_LIST}${registeredCourseId}/`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);

      if (
        response &&
        response.status === 200 &&
        response?.data?.status === "Sucess"
      ) {
        const levelsData = [
          { name: "All", id: "All" },
          { name: "Easy", id: "Level 1" },
          { name: "Medium", id: "Level 2" },
          { name: "Hard", id: "Level 3" },
        ];

        const chapterDetailsDataRes = response?.data?.data;
        setChapterDetailstData(chapterDetailsDataRes);
        setChapterIdForPattern(response?.data?.data?.courseTopics[0]?.id);
        const chaptersNamesData = chapterDetailsDataRes
          ? chapterDetailsDataRes?.courseTopics?.reduce(
            (arr: [{}], obj: any, i: number) => {
              const chapterNumberAndName = obj?.name?.split(".") || "";
              arr.push({
                name: `Ch${chapterNumberAndName[0]} | ${chapterNumberAndName[1]}`,
                id: obj?.id,
              });
              return arr;
            },
            []
          )
          : [];

        const patterns = [
          { data: chaptersNamesData, defaultLabel: "All Chapters" },
          { data: levelsData, defaultLabel: "All Levels" },
        ];
        setPatternOptions(patterns);
        //setting first chapter as defualt as of now for chapters only
        const patternPropsData = patterns
          ? patterns?.map((x: any, i: number) => {
            return {
              index: i,
              value: i === 0 ? x?.data[0]?.name : "",
              id: i === 0 ? x?.data[0]?.id : "",
            };
          })
          : [];
        setUpdatedSelectedPatternValues(patternPropsData);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const fetchPatternsList = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.PATTERNS_IN_CHAPTER}/?chapter_id=${chapterIdForPattern}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const { data }: any = await makeAxiosRequest(payload);
      if (data?.status === "success") {
        const patternData = data?.data;
        setPatternLsitByChapter(patternData);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    setChapterIdForPattern(initialSelectedPatternsValues[0]?.id);
  }, [initialSelectedPatternsValues]);

  useEffect(() => {
    if (chapterIdForPattern) fetchPatternsList();
  }, [chapterIdForPattern]);

  useEffect(() => {
    if (registeredCourseId) fetchDropdownChaptersList();
  }, [registeredCourseId]);

  const handlePattern = (item: any) => {
    navigate(routePaths.patternStats.replace(":statsId", item.patternId));
  };

  const handleChaptersDropdown = (updatedArrValues: any) => {
    setUpdatedSelectedPatternValues(updatedArrValues);
    const updatedChapterId = updatedArrValues[0]?.id;
    setChapterIdForPattern(updatedChapterId);
  };

  const [searchText, setSearchText] = useState("");
  const dataMassaging = (data: any) => {
    const dataMassaged = data?.map((x: any, i: number) => {
      return {
        index: i,
        aiHelpCount: x?.ai_help_count,
        competency: x?.competency,
        mastery: x?.mastery,
        patternCode: x?.pattern_code,
        patternId: x?.pattern_id,
        patternImportance: x?.pattern_importance,
        patternName: x?.pattern_name,
        questionCount: x?.question_count,
        questionsAttemptedCount: x?.questions_attempted_count,
        questionId: chapterIdForPattern,
      };
    });
    return dataMassaged;
  };
  const [dropdownArray, setDropdownArray] = React.useState<any>([]);

  const {
    dropdownSelectedValueWithId,
    setDropdownSelectedValueWithId,
    filteredTopics,
    page,
    paginationStart,
    paginationEnd,
    range,
    handlePaginationChange,
  } = useSorting(
    patternLsitByChapter,
    selectedStatus,
    searchText,
    "patternName", // search filter key
    dataMassaging,
    5, // range
    dropdownArray
  );

  useEffect(() => {
    if (dropdownSelectedValueWithId) {
      if (dropdownSelectedValueWithId?.defaultLabel === "All Chapters") {
        setDropdownArray([dropdownSelectedValueWithId]);
      }
    }
  }, [dropdownSelectedValueWithId]);

  useEffect(() => {
    if (dropdownSelectedValueWithId) {
      setChapterIdForPattern(dropdownSelectedValueWithId?.id);
    }
    if (chapterIdForPattern) fetchPatternsList();
  }, [chapterIdForPattern, dropdownSelectedValueWithId]);

  return (
    <>
      <div className={styles.myStatspatternContainer}>
        <div className={styles.myStatsTopHeadingText}>
          {constants.myStatsHeadingText}
        </div>
        <div className={styles.filterAndSearchContainer}>
          <div className={styles.filterContainer}>
            <FilterBar
              showSorting={false}
              patternsData={
                patternOptions && patternOptions[0] ? [patternOptions[0]] : []
              }
              selectedStatus={(selectedStatuses: string[]) =>
                setSelectedStatus(selectedStatuses)
              }
              setDropdownSelectedValueWithId={setDropdownSelectedValueWithId}
              initialSelectedPatternsValues={initialSelectedPatternsValues}
              setUpdatedSelectedPatternValues={handleChaptersDropdown}
            />
          </div>
          <SearchBar searchText={searchText} setSearchText={setSearchText} />
        </div>

        {filteredTopics?.length > 0 &&
          filteredTopics
            ?.slice(paginationStart, paginationEnd)
            .map((each: any, ind: number) => (
              <div
                className={styles.myStatsPatternCardWrapper}
                key={`patternCard_${ind}`}
              >
                <MyStatsPatternCard data={each} handlePattern={handlePattern} />
              </div>
            ))}
        {filteredTopics?.length === 0 && loaderStatus.show === false && (
          <div className={styles.noDataFound}>No Data Found</div>
        )}

        {filteredTopics.length > 0 && (
          <CustomPagination
            detailedViewRef={detailedViewRef}
            page={page}
            handlePaginationChage={handlePaginationChange}
            count={Math.ceil(filteredTopics?.length / range)}
          />
        )}
      </div>
    </>
  );
};

export default MyStatsPattern;
