import React from 'react'

import styles from "./imageSidebar.module.scss";


interface ImageSidebarPropsType {
  activeFileIndex: number,
  uploadedFiles: any,
  setActiveFileIndex: any
}

const ImageSidebar = ({activeFileIndex, uploadedFiles, setActiveFileIndex}: ImageSidebarPropsType) => {
  
  return (<div className={styles.imageSidebarContainer}>
    
    <div className={styles.imagesContainer}>
      {
        uploadedFiles.map((eachFile: any, index: number) => (
          <div 
            className={styles.image} 
            onClick={() => setActiveFileIndex(index)}
          >
            <img style={{
            border: `2px solid ${index === activeFileIndex ? '#6862E0': 'white'}`
            }} src={eachFile.preview ?? eachFile} alt="" />
          </div>
        ))
      }
    </div>
  </div>
  )
}

export default ImageSidebar