import React, { useContext, useEffect, useState } from "react";
import styles from "./createHomeworkTest.module.scss";
import ClassIcon from "../../../../../assets/images/presentation.svg";
import StudentIcon from "../../../../../assets/images/bookreading.svg";
import ClassPIcon from "../../../../../assets/images/presentationPurple.svg";
import StudentPIcon from "../../../../../assets/images/bookreadingPurple.svg";
import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Dropdown from "../../../../../common/dropdown/dropdown";
import { routePaths } from "../../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import StatusContext from "../../../../../context/loaderContext";
import { HTTPMethods } from "../../../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import { useDispatch } from "react-redux";

const selectOptions = [
  {
    label: "Class",
    initalValue: "",
    currentValue: "",
    options: [],
  },
  {
    label: "Section",
    initalValue: "",
    currentValue: "",
    options: [],
  },
  {
    label: "Chapter",
    initalValue: "",
    currentValue: "",
    options: [],
  },
  {
    label: "Topic",
    initalValue: "",
    currentValue: "",
    options: [],
  },
  {
    label: "Number of Questions",
    initalValue: "",
    currentValue: "",
    options: [
      {
        value: 5,
        name: "5",
      },
      {
        value: 10,
        name: "10",
      },
      {
        value: 15,
        name: "15",
      },
      {
        value: 20,
        name: "20",
      },
    ],
  },
];

const CreateHomeworkTestDataSelectionModal = (props: any) => {
  const loaderStatus: any = useContext(StatusContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type, modalFor, callback, selectOptionProp } = props;

  const [classAndSectionData, setClassAndSectionData] = useState<any>([]);
  const [aiSupport, setAiSupport] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any>(selectOptions);
  const fetchClassAndSectionData = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: "section_wise_students",
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const { data }: any = await makeAxiosRequest(payload);

      if (data.status === "success") {
        setClassAndSectionData(data.data);
        const updatedClassOptions = data.data.map((item: any) => {
          return {
            value: item.course_id,
            name: item.course_name,
          };
        });

        setSelectedValues((state: any) => [
          { ...state[0], options: updatedClassOptions },
          ...state.slice(1),
        ]);
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  const fetchTopicList = async (id: number) => {
    if (!id) return;
    loaderStatus.setState({ show: true });
    const payload = {
      url: `getSubTopicsForSuggestion/?chapter_id=${id}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const { data }: any = await makeAxiosRequest(payload);
      if (data.status === "sucesss") {
        const updatedTopicOptions = data.data.map((item: any) => {
          return {
            value: item.id,
            name: item.name,
          };
        });
        if (modalFor == "Student") {
          setSelectedValues((state: any) => [
            ...state.slice(0, 4),
            { ...state[4], options: updatedTopicOptions },
            ...state.slice(5),
          ]);
        } else {
          setSelectedValues((state: any) => [
            ...state.slice(0, 3),
            { ...state[3], options: updatedTopicOptions },
            ...state.slice(4),
          ]);
        }
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };
  const fetchChapterList = async (id: number) => {
    if (!id) return;
    loaderStatus.setState({ show: true });
    const payload = {
      url: `getTopicsForSuggestion/?courseid=${id}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const { data }: any = await makeAxiosRequest(payload);
      if (data.status === "sucesss") {
        const updatedChapterOptions = data.data.map((item: any) => {
          return {
            value: item.id,
            name: item.name,
          };
        });
        if (modalFor == "Student") {
          setSelectedValues((state: any) => [
            ...state.slice(0, 3),
            { ...state[3], options: updatedChapterOptions },
            ...state.slice(4),
          ]);
        } else {
          setSelectedValues((state: any) => [
            ...state.slice(0, 2),
            { ...state[2], options: updatedChapterOptions },
            ...state.slice(3),
          ]);
        }
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      modalFor === "Student" &&
      selectedValues.findIndex((_: any) => _.label == "Select a Student") === -1
    ) {
      const indexToInsert = 2;
      const elementToAdd = {
        label: "Select a Student",
        options: [],
        initalValue: "",
        currentValue: "",
      };

      const newArray = [
        ...selectedValues.slice(0, indexToInsert),
        elementToAdd,
        ...selectedValues.slice(indexToInsert),
      ];

      setSelectedValues(newArray);
    } else {
      setSelectedValues((state: any) =>
        state.filter((_: any, index: any) => _.label !== "Select a Student")
      );
    }
  }, []);
  const fetchMentorRelatedStudent = async (section: string) => {
    if (!section) return;
    loaderStatus.setState({ show: true });
    const payload = {
      url: `mentor_related_students/?class_id=${selectedValues[0].currentValue}&section=${section}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const { data }: any = await makeAxiosRequest(payload);
      if (data.status === "success") {
        const updatedMentorOptions = data.data.map((item: any) => {
          return {
            value: item.rollnumber,
            name: item.username,
          };
        });
        console.log(updatedMentorOptions);

        setSelectedValues((state: any) => [
          ...state.slice(0, 2),
          { ...state[2], options: updatedMentorOptions },
          ...state.slice(3),
        ]);
      }

      loaderStatus.setState({ show: false });
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchClassAndSectionData();
  }, []);

  const handleSelectChange = (index: any, value: any, name: any) => {
    const newSelectedValues = [...selectedValues];
    newSelectedValues[index].currentValue = value;

    // Clear the values of the selects that come after the current select
    for (let i = index + 1; i < newSelectedValues.length; i++) {
      newSelectedValues[i].currentValue = "";
    }
    setSelectedValues(newSelectedValues);

    if (name == "Class") {
      const updatedSectionOptions = classAndSectionData
        .filter((i: any) => {
          return i.course_id === value;
        })[0]
        .section_wise_date.map((item: any) => {
          return {
            value: item.name,
            name: item.name,
          };
        });

      setSelectedValues((state: any) => [
        ...state.slice(0, 1),
        {
          ...state[1],
          options: updatedSectionOptions,
        },
        ...state.slice(2),
      ]);

      fetchChapterList(value);
    }

    if (name == "Chapter") {
      fetchTopicList(value);
    }

    if (name == "Section" && modalFor == "Student") {
      fetchMentorRelatedStudent(value);
    }
  };

  const handleCheckboxChange = () => {
    setAiSupport(!aiSupport);
  };

  const allItemsHaveCurrentValue = () => {
    return selectedValues.every((item: any) => item.currentValue !== "");
  };

  return (
    <div className={styles.classHomework}>
      <p>
        <img src={ClassPIcon} alt="" /> {modalFor}
      </p>
      <div className={styles.classHomeworkForm}>
        <div>
          {selectedValues.map((option: any, index: any) => (
            <FormControl
              key={index}
              size="small"
              sx={{
                "& .MuiInputLabel-shrink": {
                  backgroundImage:
                    "linear-gradient(to bottom, transparent 50%, #EDF2FA 50%);",
                  padding: "0 4px",
                  color: "#6650f1",
                },
              }}
            >
              <InputLabel>{option.label}</InputLabel>
              <Select
                defaultValue={selectedValues[index].initalValue}
                value={selectedValues[index].currentValue}
                onChange={(e) =>
                  handleSelectChange(
                    index,
                    e.target.value,
                    selectedValues[index].label
                  )
                }
                disabled={
                  index === 0 ? false : !selectedValues[index - 1].currentValue
                }
                placeholder={option.label}
                style={{
                  width: "100%",
                  background: "#EDF2FA",
                }}
                sx={{
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      color: "#4B5468",
                      fontFamily: "Inter-Regular",
                      fontSize: "14px",
                      lineHeight: "17px",
                      minHeight: "unset",
                      alignItems: "center",
                      padding: "10px 12px 9px 12px",
                      overflow: "hidden",
                    },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#D4DCE8 !important",
                  },
                  "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                    { cursor: "not-allowed" },
                  "& .MuiInputLabel-shrink": {
                    border: "1px solid",
                  },
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {!!option.options.length &&
                  option.options.map((value: any, index: any) => (
                    <MenuItem
                      key={value?.value + index}
                      value={value?.value || ""}
                    >
                      {value?.name || ""}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ))}

          <div className={styles.classHomeworkFormItem}>
            <Checkbox
              checked={aiSupport} // Use the state variable for the checkbox
              onChange={handleCheckboxChange} // Handle checkbox change
              sx={{
                color: "#D9D9D9",
                "&.Mui-checked": {
                  color: "#109289",
                },
              }}
            ></Checkbox>
            <span>Allow students to use AI support</span>
          </div>

          <div className={styles.classHomeworkFormItem}>
            <button
              disabled={!allItemsHaveCurrentValue()}
              onClick={() => {
                let payload: any = {
                  class: selectedValues[0].options.filter(
                    (i: any) => i.value === selectedValues[0].currentValue
                  )[0],
                  section: selectedValues[1].options.filter(
                    (i: any) => i.value === selectedValues[1].currentValue
                  )[0],
                  chapterName: selectedValues[2].options.filter(
                    (i: any) => i.value === selectedValues[2].currentValue
                  )[0],
                  topicName: selectedValues[3].options.filter(
                    (i: any) => i.value === selectedValues[3].currentValue
                  )[0],
                  questionCount: selectedValues[4].options.filter(
                    (i: any) => i.value === selectedValues[4].currentValue
                  )[0],
                  aiSupport: aiSupport,
                };
                if (modalFor == "Student") {
                  payload = {
                    class: selectedValues[0].options.filter(
                      (i: any) => i.value === selectedValues[0].currentValue
                    )[0],
                    section: selectedValues[1].options.filter(
                      (i: any) => i.value === selectedValues[1].currentValue
                    )[0],
                    studentId: selectedValues[2].options.filter(
                      (i: any) => i.value === selectedValues[2].currentValue
                    )[0],
                    chapterName: selectedValues[3].options.filter(
                      (i: any) => i.value === selectedValues[3].currentValue
                    )[0],
                    topicName: selectedValues[4].options.filter(
                      (i: any) => i.value === selectedValues[4].currentValue
                    )[0],
                    questionCount: selectedValues[5].options.filter(
                      (i: any) => i.value === selectedValues[5].currentValue
                    )[0],
                    aiSupport: aiSupport,
                  };
                }

                dispatch({
                  type: "UPDATE_CREATE_DATA",
                  payload: payload,
                });
                let path = "";
                if (type == "Homework") {
                  path = routePaths.mentorCreateHomeworkTest
                    .replace(":type", "homework")
                    .replace(":for", modalFor.toLowerCase());
                }
                if (type == "Test") {
                  path = routePaths.mentorCreateHomeworkTest
                    .replace(":type", "test")
                    .replace(":for", "class");
                }

                if (path) {
                  navigate(path);
                  callback && callback();
                }
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateHomeworkTestDataSelectionModal;
