import React from "react";
import DashboardNotificationList from "../dashboardNotificationList/dashboardNotificationList";
import style from "./dashboardNotification.module.scss";
import { Link } from "react-router-dom";

const DashboardNotification = ({ data, link, status }: any) => {
  let notifications = data || [
    {
      notification:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempora.",
    },
    {
      notification:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempora dsadsadsdasdsa.",
    },
    {
      notification:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempora dsadsdsadsaadsdasdsa.",
    },
    {
      notification:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum ex tempor dasdas a dsadsadsdasdsa.",
    },
  ];

  return (
    <div className={style.dashboardNotification}>
      <h1>Notifications </h1>
      <ul>
        {notifications.length > 0 &&
          notifications.map((data: any, index: any) => (
            <DashboardNotificationList key={index} listObj={data} />
          ))}
      </ul>
      {link && <Link to={link}>View All</Link>}
      {/* <a href="#">View All</a> */}
    </div>
  );
};

export default DashboardNotification;
