import React, { useEffect, useRef, useState } from "react";
import styles from "./myClassDetails.module.scss";
import { constants } from "../../../constants/constants";
import Breadcrumb from "../../../common/breadcrumb/breadcrumb";
import TabSection from "../../../common/tab/tab";
import ClassDetailsOverview from "./pages/classDetailsOverview/classDetailsOverview";
import ClassDetailsStudent from "./pages/classDetailsStudent/classDetailsStudent";
import ClassDetailsAssignment from "./pages/classDetailsAssignment/classDetailsAssignment";
import { useSelector } from "react-redux";

const MyClassDetails = () => {
  const detailedViewRef = useRef(null);
  const [indexToViewAllTabsNumber, setIndexToViewAllTabsNumber] = useState(0);
  const classDetailsTitles = [
    constants.OVERVIEW,
    constants.STUDENTS,
    // constants.EXERCISES,
  ];
  const selectedIndexData = useSelector(
    (state: any) => state.selectedTabReducer
  );
  const classDetailsContents = [
    <ClassDetailsOverview setSelectedIndexData={setIndexToViewAllTabsNumber} />,
    <ClassDetailsStudent detailedViewRef={detailedViewRef} />,
    <ClassDetailsAssignment />,
  ];

  const myClassRef: any = useRef(null);

  useEffect(() => {
    selectedIndexData &&
      setIndexToViewAllTabsNumber(selectedIndexData[constants.MYCLASS]);
  }, []);

  useEffect(() => {
    myClassRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [myClassRef]);

  return (
    <div className={styles.myStatsMainContainer} ref={myClassRef}>
      <Breadcrumb breadcrumbDetails={constants.CLASS_DETAILS_BREADCRUMB_DATA} />
      <TabSection
        indexToViewAllTabsNumber={indexToViewAllTabsNumber}
        setIndexToViewAllTabsNumber={setIndexToViewAllTabsNumber}
        titles={classDetailsTitles}
        contents={classDetailsContents}
        id={constants.MYCLASS}
      />
    </div>
  );
};

export default MyClassDetails;
