import { makeAxiosRequest } from "aie_common/dist/httpService/httpService";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HTTPMethods } from "../../../../../constants/api_endpoints";
import classes from "./reviewHomeworkTest.module.scss";
import { QuestionAndOptionsElement } from "../../../../../common/questionsAndOptions/questionAndOptions";
import correctIcon from "../../../../../assets/images/correctSubmit.svg";
import wrongIcon from "../../../../../assets/images/wrongIcon.svg";
import pendingIcon from "../../../../../assets/images/pendingIcon.svg";
import disabled from "../../../../../assets/images/notSelectable.svg";
import mcqIcon from "../../../../../assets/images/mcqsIcon.svg";
import descriptiveIcon from "../../../../../assets/images/descriptiveIcon.svg";
import Breadcrumb from "../../../../../common/breadcrumb/breadcrumb";
import { Dialog } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { routePaths } from "../../../../../constants/routes";
import { useDispatch } from "react-redux";

const ReviewHomeworkTest = ({ type }: any) => {
  let { id, studentId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [instruction, setInstruction] = useState(true);
  const [question, setQuestion] = useState<any>([]);

  const fetchAssignmentList = async () => {
    const payload = {
      url: "",
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    if (type == "Homework") {
      payload.url = `get_homework_questionsAnswer/?homework_id=${id}&student_id=${studentId}`;
    }
    if (type == "Test") {
      payload.url = `get_testpaper_questionsAnswer/?testpaper_id=${id}&student_id=${studentId}`;
    }
    //    payload.url = `get_homework_dates/?user_id=3055&course_id=26`;

    try {
      const res: any = await makeAxiosRequest(payload);
      if (!res.data.data) return;
      let results = res.data.data.student_test_data;
      if (type == "Homework") {
        results = res.data.data.student_homework_data;
      }
      if ((res.data.data.homework_id = "499")) {
        results[3].descriptive = true;
      }

      const newData = results?.map((item: any, index: any) => {
        return {
          ...item,
          Answers: [],
          question_text: item.question,
          Questionid: item.mocktest_id,
          uploadedImages: [],
          descriptive: item.descriptive,
          totalTime: item.timetaken || 0,
        };
      });
      setQuestion(newData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAssignmentList();
  }, []);

  // const navigateToReviewPage = (index: number, item: any) => {
  //   dispatch({
  //     type: "UPDATE_QUESTION_INDEX_MENTOR",
  //     payload: item?.questionId,
  //   });
  //   let path = `${routePaths.descriptiveQuestionReviewPage.replace(
  //     ":classId",
  //     params.classId ?? ""
  //   )}/?topicId=${params.topicId ?? ""}`;
  //   if (item.level > 1) {
  //     path = `${routePaths.descriptiveQuestionReviewPage.replace(
  //       ":classId",
  //       params.classId ?? ""
  //     )}/?chapterId=${params.chapterId ?? ""}&level=${params.level}`;
  //   }
  //   navigate(path);
  // };

  return (
    <div className={classes.myAssignmentsMainContainer}>
      <Breadcrumb
        breadcrumbDetails={[
          {
            label: "My Class",
            link: "/mentor/my-class",
            selected: false,
          },
        ]}
      />
      <div className={classes.popUpCardContainer}>
        <div className={classes.heading}>
          {type} <span>{question?.length} Questions</span>{" "}
        </div>
        {question?.map((item: any, index: any) => (
          <div
            onClick={() => {}}
            className={`${classes.popUpCardItem} `}
            key={`${item.question}_${index}`}
            //style={{ cursor: `${isRetakeAllowed ? "auto" : "pointer"}` }}
          >
            <div className={classes.questionAndImg}>
              <div
                className={`${classes.questionImg} ${
                  item.question_status == "" && classes.disabled
                }`}
              >
                {!item.descriptive && item.student_submitted_answer.correct && (
                  <img src={correctIcon} alt="" />
                )}
                {!item.descriptive &&
                  !item.student_submitted_answer.correct && (
                    <img src={wrongIcon} alt="" />
                  )}
                {item.descriptive &&
                  (item.verified === "In_Review" || !item.verified) && (
                    <img src={pendingIcon} alt="" />
                  )}
                {item.descriptive && item.verified === "Reviewed" && (
                  <img src={correctIcon} alt="" />
                )}
              </div>

              <div className={classes.questionNumAndText}>
                <div className={classes.questionNum}>{`Q${index + 1}.`}</div>
                <div className={classes.questionText}>
                  <div>
                    <QuestionAndOptionsElement metaData={item.question_text} />
                  </div>
                </div>
                <div className={classes.questionEvaluate}>
                  <span>
                    <img
                      src={item.descriptive ? descriptiveIcon : mcqIcon}
                      alt=""
                    />{" "}
                    {item.descriptive ? "Descriptive" : "MCQs"}
                  </span>
                  {
                    <button
                      style={{
                        border: `${
                          item.descriptive
                            ? "1.5px solid #109289"
                            : "1.5px solid transparent"
                        }
                        `,
                      }}
                      onClick={() => {
                        dispatch({ type: "SET_ASSIGNMENT_LEVEL", payload: 1 });
                        if (id) {
                          let path: any = routePaths.reviewHomeworkQuestion;

                          if (type == "Test") {
                            path = routePaths.reviewTestQuestion;
                          }
                          path = path + `?question=${index}`;
                          navigate(
                            path
                              .replace(":id", `${id}`)
                              .replace(":studentId", `${studentId}`)
                          );
                        }
                      }}
                    >
                      {item.descriptive ? "EVALUATE" : "Comment"}
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Dialog
        open={instruction}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className={classes.instructionWrap}>
          <div className={classes.instructionHeader}>
            <h3>Instructions</h3>
            <button
              onClick={() => {
                setInstruction(false);
              }}
            >
              <CloseRounded />
            </button>
          </div>
          <div className={classes.instructionBody}>
            <ul>
              <li>
                <QuestionMark /> Descriptive questions must be evaluated.
              </li>
              <li>
                <QuestionMark /> MCQs are already evaluated by the AI. However,
                you can add additional comments(if required).
              </li>
            </ul>
            <div className={classes.instructionType}>
              <span>
                <img src={descriptiveIcon} alt="" />
                Descriptive
              </span>

              <span>
                <img src={mcqIcon} alt="" />
                MCQs
              </span>
            </div>
            <div className={classes.instructionAction}>
              <span>
                <img src={pendingIcon} alt="" />
                Evaluation required
              </span>

              <span>
                <img src={correctIcon} alt="" />
                Correct answer
              </span>
              <span>
                <img src={wrongIcon} alt="" />
                Wrong answer
              </span>
            </div>
            <div className={classes.instructionButtons}>
              <button
                onClick={() => {
                  setInstruction(false);
                }}
              >
                Close
              </button>
              <button
                onClick={() => {
                  setInstruction(false);
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ReviewHomeworkTest;

const DescriptiveIcon = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4 15.8801L9.12003 16.5201L9.52003 14.2001L17.12 5.68007C17.6 5.16007 18.4 5.12007 18.92 5.56007C19.44 6.04007 19.48 6.84007 19.04 7.36007L11.4 15.8801Z"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.08 13.5197L11.96 15.1997"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.88 12.64L12.76 14.32"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.8 8.67988L15.92 6.99988"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.92 9.67963L15.04 7.99963"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.12 10.5598V18.0798C16.12 18.7598 15.6 19.2798 14.92 19.2798H1.88002C1.24002 19.2798 0.680023 18.7598 0.680023 18.0798V1.91985C0.680023 1.27985 1.20002 0.719849 1.88002 0.719849H14.92C15.56 0.719849 16.12 1.23985 16.12 1.91985V6.79985"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.80002 10H11.16"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.67999 10H3.11999"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.71997 12.3202H8.99997"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.71997 14.6H7.51997"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.12 16.9198H14.12"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.71997 16.9198H7.83997"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2 3.11975H14.12"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.08 3.11975H10.6"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4 5.40002H14.12"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8 7.72009H14.12"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.95997 7.19975C8.87997 7.55975 8.75997 7.87975 8.55997 8.19975L9.07997 8.87975L8.47997 9.47975L7.79997 8.95975C7.51997 9.15975 7.15997 9.27975 6.79997 9.35975L6.67997 10.1998H5.83997L5.71997 9.35975C5.35997 9.27975 5.03997 9.15975 4.71997 8.95975L4.03997 9.47975L3.43997 8.87975L3.95997 8.19975C3.75997 7.91975 3.63997 7.55975 3.55997 7.19975L2.71997 7.07975V6.23975L3.55997 6.11975C3.63997 5.75975 3.75997 5.43975 3.95997 5.11975L3.43997 4.43975L4.03997 3.83975L4.71997 4.35975C4.99997 4.15975 5.35997 4.03975 5.71997 3.95975L5.83997 3.11975H6.67997L6.79997 3.95975C7.15997 4.03975 7.47997 4.15975 7.79997 4.35975L8.47997 3.83975L9.07997 4.43975L8.55997 5.11975C8.75997 5.39975 8.87997 5.75975 8.95997 6.11975L9.79997 6.23975V7.07975L8.95997 7.19975Z"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.23998 7.55997C6.74808 7.55997 7.15998 7.14807 7.15998 6.63997C7.15998 6.13187 6.74808 5.71997 6.23998 5.71997C5.73187 5.71997 5.31998 6.13187 5.31998 6.63997C5.31998 7.14807 5.73187 7.55997 6.23998 7.55997Z"
          stroke="#10928A"
          strokeWidth="0.7125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
const QuestionMark = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
        stroke="#6862E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 17.668C11.6904 17.668 12.25 17.1083 12.25 16.418C12.25 15.7276 11.6904 15.168 11 15.168C10.3096 15.168 9.75 15.7276 9.75 16.418C9.75 17.1083 10.3096 17.668 11 17.668Z"
        fill="#6862E0"
      />
      <path
        d="M11.0026 12.6667V11.8333C11.5795 11.8333 12.1434 11.6623 12.623 11.3418C13.1027 11.0213 13.4765 10.5658 13.6973 10.0328C13.918 9.49988 13.9758 8.91343 13.8632 8.34765C13.7507 7.78188 13.4729 7.26218 13.065 6.85427C12.6571 6.44637 12.1374 6.16858 11.5716 6.05604C11.0058 5.9435 10.4194 6.00126 9.88644 6.22202C9.35349 6.44277 8.89797 6.81661 8.57748 7.29625C8.257 7.7759 8.08594 8.33981 8.08594 8.91667"
        stroke="#6862E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
