import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./tab.module.scss";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface ITabSection {
  indexToViewAllTabsNumber?: number;
  setIndexToViewAllTabsNumber?: any;
  titles: string[];
  contents: any[];
  tabClass?: string;
  id?: string
}

export default function TabSection({
  indexToViewAllTabsNumber,
  setIndexToViewAllTabsNumber,
  titles,
  contents,
  tabClass,
  id
}: ITabSection) {
  const isTabOpen = useSelector((state: any) => state.tabReducer.tabOpen);
  const [value, setValue] = React.useState(0);

  const ref = useRef<any>(null);

  const [width, setWidth] = useState(0);
  const dispatch = useDispatch();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    // if (indexToViewAllTabsNumber) {
    setIndexToViewAllTabsNumber(newValue);
    id &&
      dispatch({
        type: id, index: newValue,
      })
    // };
  };

  useLayoutEffect(() => {
    setWidth(ref.current.clientWidth);
  }, [isTabOpen]);

  useEffect(() => {
    function handleWindowResize() {
      setWidth(ref.current.clientWidth);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isTabOpen]);

  useEffect(() => {
    setValue(indexToViewAllTabsNumber || 0);
  }, [indexToViewAllTabsNumber]);

  return (
    <Box className={styles.tabContainer}>
      <Box
        className={`${styles.tabData} ${tabClass ?? styles.showTabOnRight} `}
        ref={ref}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            "& .MuiTabPanel-root": {
              padding: "0px !important",
              alignItems: "center",
            },
            "& .MuiButtonBase-root": {
              textTransform: "none",
              border: "1px solid #AEB6CA",
              borderRadius: "10px",
              padding: "11px 16px !important",
              marginRight: "10px",
              fontSize: "16px",
              lineHeight: "19px",
              minHeight: "unset",
              color: "#4D5362",
              fontFamily: "Inter-Regular"

            },
            "& .MuiButtonBase-root:last-child": {
              marginRight: "0",
            },
            "&.MuiTabs-scrollButtonsDesktop": {
              display: "flex",

              "&.Mui-disabled:first-child": {
                visibility: "hidden",
              },
            },
            "& .css-jpln7h-MuiTabs-scroller": {
              minHeight: "54px",
            },
            "& .MuiTabs-indicator": {
              display: "none",
            },
            "& .MuiTab-root.Mui-selected": {
              border: "1.5px solid #6862E0",
              borderRadius: "10px",
              boxShadow: " 2px 2px 12px rgba(104, 98, 224, 0.26)",
              color: "#6862E0",
              backgroundColor: "white !important",
              background:
                "linear-gradient(104.37deg, #EBF1F4 13.82%, #F8FAFF 89.02%)",
            },
            "& .MuiTabScrollButton-horizontal": {
              height: "41px",
              display: "flex",
              opacity: "1 !important",
              borderRadius: "4px",

              background:
                "linear-gradient(330deg, #5A66C8 8.2%, #896AF3 80.47%);",
              boxShadow: "2px 2px 6px rgba(105, 117, 151, 0.5)",
              color: "white",
              marginRight: "18px",
            },
            "& .MuiTabScrollButton-horizontal:last-child": {
              marginLeft: "20px",
              marginRight: 0,
            },

            "& . .css-heg063-MuiTabs-flexContainer": {
              width: "585px",
            },

            "@media (max-width:600px)": {
              "& .MuiTabScrollButton-horizontal": {
                display: "flex",
              },
            },

            "@media (max-width:425px)": {
              "& .MuiButtonBase-root": {
                fontSize: "14px",
                padding: "8px 19px !important",
                maxHeight: "32px",
              },
              "& .MuiTabPanel-root": {
                borderRadius: "6px",
              },
              "& .MuiTabScrollButton-horizontal svg": {
                fontSize: "21.5px",
              },
              "& .MuiTabScrollButton-horizontal": {
                height: "30px",
              },
            },
          }}
        >
          {titles &&
            Array.isArray(titles) &&
            titles.map((tabLabel: any, index: any) => {
              return <Tab label={tabLabel} {...a11yProps(index)} key={`tabTitle_${index}`} />;
            })}
        </Tabs>
      </Box>

      {contents.map((tabItem: any, index: any) => {
        return (
          <TabPanel value={value} index={index} key={`tabPanel_${index}`}>
            {tabItem}
          </TabPanel>
        );
      })}
    </Box>
  );
}
