import styles from "./reviewResultSection.module.scss";
import React, { useState, useEffect } from 'react';
import assignmentTimerSectionClock from "../../../../../../assets/images/taskStatus.svg";
import timerSectionRobotIcon from "../../../../../../assets/images/timerSectionRobotIcon.svg";
import patternCardImage from "../../../../../../assets/images/patternCardImage.svg";
import PatternVideoOverlayContainer from "../../../../../../common/patternVideoOverlayContainer/patternVideoOverlayContainer";
import ApiEdpoints, { HTTPMethods } from '../../../../../../constants/api_endpoints';
import { makeAxiosRequest } from "aie_common";
import { useSelector } from "react-redux";

const ReviewResultSection = ({ question, activeQuestionIndex, resultData }: any) => {

  const [patternCards, setPatternCards] = React.useState<any>([{
    patterns: [],
    question: "",
  }]);
  const [indexdPatternCards, setIndexdPatternCards] = React.useState<any>([]);
  const [patternVideoOverlay, setPatternVideoOverlay] = useState(false);
  const [videoNotesOverlayContent, setVideoNotesOverlayContent] = useState("pattern");
  const [patternCardData, setPatternCardData] = useState({
    descriptionText: "",
    patternHeadingText : ""

  });
  const id = useSelector((state: any) => state.userReducer.registeredCourseId);

  const fetchPatternCards = async () => {
    const payloads = {
      url: ApiEdpoints.DYNAMIC_PSGA + `${question[activeQuestionIndex]?.question_tag}/${question[activeQuestionIndex].question_tag}/${id}/`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const res = await makeAxiosRequest(payloads);
      const data = res.data.data.map((item: any) => {
        return {
          patterns: item.patterns,
          question: item.question,
        };
      });
      setPatternCards(data);
      setIndexdPatternCards(Object.keys(res.data.pattern_color))

      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const handlePatternVideoOverlay = () => {
    setPatternVideoOverlay(prev => !prev);
    setVideoNotesOverlayContent("pattern");
  };

  const handlePatternVideoOverlayContent = (
    resource: "viewDocument" | "pattern" | "patternMap"
  ) => {
    setVideoNotesOverlayContent(resource);
  };

  useEffect(() => {
    fetchPatternCards();
  }, [activeQuestionIndex]);
  const [indexnew, setIndexnew] = useState(0);

  return (
    <div className={styles.reviewSectionContainer} >
      <div className={styles.headerContainer}>
        <div className={styles.headerContentBox} >
          <div className={`${styles.textSubmission} ${styles.inter20}`}>Submission Details</div>
          <div className={styles.imageIcon}><img src={assignmentTimerSectionClock} /> </div>
        </div>
        <div className={styles.headerDateContainer}>
          <div className={styles.seperation}>
            <div className={`${styles.textDate} ${styles.inter16}`}>13 Feb ‘23, 03:44 PM</div>
            <div className={`${styles.dateSection} ${styles.inter14}`}>Attempt Date </div>
          </div>
          <div className={styles.verticleLine}>|</div>
          <div className={styles.seperation}>
            <div className={`${styles.textDate} ${styles.inter16}`}>52 min 30 sec</div>
            <div className={`${styles.dateSection} ${styles.inter14}`}>Time Taken </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.horizontalLine}></div> */}
      <div className={styles.containerGreatJob}>
        <div className={styles.containerContent}>
          <div className={`${styles.textGreatJob} ${styles.inter16}`}>Great job! Keep up the good work.</div>
          <div className={`${styles.textGreatJobContent} ${styles.inter14}`}>You used AI Assistance for 03 Questions</div>
        </div>
        <div className={styles.robotContainer}>
          <div><img src={timerSectionRobotIcon} className={styles.robotImage} /></div>
        </div>
      </div>
      {/* <div className={styles.horizontalLine}></div> */}
      <div className={styles.containerPattern}>
        <div className={`${styles.textPattern} ${styles.inter20}`}>Patterns</div>
        <div className={`${styles.patternContent} ${styles.inter14}`}>Here are the key patterns that you need to know to for this question</div>
        {
          indexdPatternCards.length != 0 &&
          indexdPatternCards.map((item: any, index: any) => {
            return (
              <div className={styles.constainerCard} key={index} >
                <div className={styles.containerCardContent}
                  onClick={() => (
                    setIndexnew(index),
                    setPatternCardData({
                      descriptionText: patternCards[0]?.patterns[index][`${indexdPatternCards[index]}`]?.description,
                      patternHeadingText : patternCards[0]?.patterns[index][`${indexdPatternCards[index]}`]?.name,

                    }),
                    setPatternVideoOverlay(!patternVideoOverlay)
                  )
                  }>
                  <div className={styles.imageBox}> <img className={styles.imageSize} src={patternCardImage} /></div>
                  <div className={styles.textCard}>
                    {

                      patternCards[0]?.patterns[index][`${indexdPatternCards[index]}`]?.pattern_id

                    }
                    <div className={styles.textVer}>|</div>
                    <div className={styles.text}>{patternCards[0]?.patterns[index][`${item}`].name}</div>
                  </div>
                </div>
                <div className={styles.buttonStyle}>
                  <button >Primary</button>
                </div>
                {
                  patternVideoOverlay ? <PatternVideoOverlayContainer
                    docName="View Document"
                    pptUrl={patternCards[0]?.patterns[indexnew][`${indexdPatternCards[indexnew]}`]?.pattern_ppt}
                    handlePatternOverlay={handlePatternVideoOverlay}
                    handlePatternVideoOverlayContent={handlePatternVideoOverlayContent}
                    videoNotesOverlayContent={videoNotesOverlayContent}
                    patternCardData={patternCardData}
                    patternExamples={patternCards[0]?.patterns[indexnew][`${indexdPatternCards[indexnew]}`]?.examples}
                    videoUrl={patternCards[0]?.patterns[indexnew][`${indexdPatternCards[indexnew]}`]?.pattern_video}
                  /> : null
                }
              </div >
            )
          })
        }
      </div>
    </div>
  );
};
export default ReviewResultSection;
